import PropTypes from 'prop-types';
import React from 'react';

import { ICONS } from '../../../lib/icons';
import { classes } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import ExternalResourceLink from './ExternalResourceLink';

export default class ImagePopoutLink extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    imageId: PropTypes.string,
  };

  handleClick = () => {
    const imageSpec = this.container.client.getImageDataSpec(this.props.imageId, {});
    const imageUrl = this.container.urlResolver.resolve(imageSpec.endpoint, imageSpec.query);

    // For now, just open in new tab. At some point we can try doing some kind of viewer
    window.open(imageUrl, '_blank');
  };

  render() {
    const { className, imageId, children } = this.props;
    if (!imageId) {
      return null;
    }

    return (
      <ExternalResourceLink
        icon={ICONS.image}
        className={classes('ImagePopoutLink', className)}
        onClick={this.handleClick}
      >
        {children}
      </ExternalResourceLink>
    );
  }
}
