import PropTypes from 'prop-types';
import React from 'react';

import { COUNTRIES } from '../../../lib/countries';
import CountryFlag from './CountryFlag';

const CountryLabel = ({ isoCode, label, large }) => {
  if (!isoCode) {
    return null;
  }

  if (label === true) {
    label = COUNTRIES[isoCode].label;
  }

  return (
    <span className="CountryLabel text-nowrap">
      <CountryFlag isoCode={isoCode} large={large} />
      {label && <span className="ml-1">{label}</span>}
    </span>
  );
};

CountryLabel.sessionInfoFormatter = (/** XcSessionInfo */ si) => (
  <CountryLabel isoCode={si.country_iso_code} label={si.country_name} />
);

CountryLabel.securityEventInfoFormatter = (/** XcSecurityEventInfo */ sei) => (
  <CountryLabel isoCode={sei.country_iso_code} label={sei.country_name} />
);

CountryLabel.phoneVerificationInfoFormatter = (/** XcPhoneVerificationInfo */ pvi) => (
  <CountryLabel isoCode={pvi.gl_country_iso_code} label={pvi.gl_country_name} />
);

CountryLabel.propTypes = {
  isoCode: PropTypes.any,
  large: PropTypes.bool,
  label: PropTypes.any,
};

export default CountryLabel;
