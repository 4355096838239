import PropTypes from 'prop-types';
import React from 'react';

import { SOF_ORIGINS } from '../../../lib/backend';
import { SOF_ORIGIN_LABELS } from '../../../lib/consts';
import Select from '../../widgets/interactive/Select';

const OPTIONS = Object.values(SOF_ORIGINS).map(value => ({
  value,
  label: SOF_ORIGIN_LABELS[value],
}));

const SOFOriginPicker = (/** SOFOriginPicker */ props) => {
  const { onChange, selected, ...passProps } = props;
  return (
    <Select
      {...passProps}
      options={OPTIONS}
      onChange={option => {
        if (onChange) {
          onChange(option ? option.value : null);
        }
      }}
      value={OPTIONS.find(option => option.value === selected)}
    />
  );
};

SOFOriginPicker.propTypes = /** @lends {SOFOriginPicker.prototype} */ {
  className: PropTypes.string,
  selected: PropTypes.string,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

export default SOFOriginPicker;
