/**
 * Service which can resolve backend (or any other URL) URL if given an endpoint and query object
 */
export class UrlResolver {
  constructor(baseUrl) {
    if (baseUrl[baseUrl.length - 1] === '/') {
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    this._baseUrl = baseUrl;
  }

  resolve(endpoint, query) {
    let url = this._baseUrl + endpoint;

    if (query) {
      const params = [];
      for (const key in query) {
        if (query.hasOwnProperty(key) && query[key] !== undefined) {
          params.push(encodeURIComponent(key) + '=' + encodeURIComponent(String(query[key])));
        }
      }
      url += '?' + params.join('&');
    }

    return url;
  }
}
