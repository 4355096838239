import './CriteriaNationalityPicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { classNamer, classes } from '../../../lib/tools';
import CountryPicker from '../../widgets/interactive/CountryPicker';

const cn = classNamer('CriteriaNationalityPicker');

const CriteriaNationalityPicker = ({
  criteria,
  className,
  label = 'Nationalities:',
  nationalitiesField = 'nationalities',
  inputId = cn('id'),
  history,
  placeholder = 'All nationalities',
  ...rest
}) => {
  const handleChange = nationalities => {
    const nextCriteria = { ...criteria };
    if (nationalities && nationalities.length) {
      nextCriteria[nationalitiesField] = nationalities;
    } else {
      delete nextCriteria[nationalitiesField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <CountryPicker
        isMulti
        selected={criteria[nationalitiesField]}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

CriteriaNationalityPicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  nationalitiesField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
};

export default withRouter(CriteriaNationalityPicker);
