import './TrollboxMessagesPage.css';

import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import {
  ADMIN_SOCKET_EVENTS,
  ADMIN_SOCKET_SECTORS,
  SORT_DIRECTIONS,
  TROLLBOX_MESSAGE_FLAGS,
  TROLLBOX_ROOMS,
} from '../../lib/backend';
import { FLAVORS, HOUR, TROLLBOX_MESSAGE_INFO_KEYS } from '../../lib/consts';
import { COUNTRIES } from '../../lib/countries';
import { Criteria } from '../../lib/criterias';
import { AppIcon, ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { abbreviated, classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import CriteriaToggler from '../widgets/criteria/CriteriaToggler';
import IconButton from '../widgets/interactive/IconButton';
import Select from '../widgets/interactive/Select';
import Toggler from '../widgets/interactive/Toggler';
import CountryFlag from '../widgets/presentational/CountryFlag';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import TrollboxBanWidget from './widgets/TrollboxBanWidget';
import TrollboxPostMessageWidget from './widgets/TrollboxPostMessageWidget';

// *********************************************************************************************************************

const BASE_CRITERIA = {
  room: TROLLBOX_ROOMS.en,
  sort_field: TROLLBOX_MESSAGE_INFO_KEYS.timestamp,
  sort_direction: SORT_DIRECTIONS.desc,
};

const ROOM_OPTIONS = [
  new Toggler.Option(TROLLBOX_ROOMS.en, 'English'),
  new Toggler.Option(TROLLBOX_ROOMS.sr, 'Serbian'),
];

const FLAG_OPTIONS = Object.keys(TROLLBOX_MESSAGE_FLAGS).reduce((res, key) => {
  res[key] = { value: key, label: key };
  return res;
}, {});

const TROLLBOX_ROOM_FLAGS = {
  [TROLLBOX_ROOMS.en]: COUNTRIES.GB.value,
  [TROLLBOX_ROOMS.sr]: COUNTRIES.RS.value,
};

// *********************************************************************************************************************

const RoomLabel = ({ room }) => {
  const isoCode = TROLLBOX_ROOM_FLAGS[room];
  return (
    <span>
      <CountryFlag isoCode={isoCode} />
      <span className="ml-1">{room}</span>
    </span>
  );
};

const FlagLabel = ({ flag }) => (
  <span>
    {flag && <AppIcon className="text-danger" icon={ICONS.bad} />}
    <span className="ml-1">{flag}</span>
  </span>
);

const Message = ({ message }) => (
  <span
    style={{
      display: 'inline-block',
      maxWidth: '500px',
      wordBreak: 'break-word',
    }}
  >
    {message}
  </span>
);

const COLUMNS = [
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.id, 'ID', mi => abbreviated(mi.id)),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.message, 'Censored', item => (
    <Message message={item.message} />
  )),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.original_message, 'Original', item => (
    <Message message={item.original_message} />
  )),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.room, 'Room', RoomLabel),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.flag, 'Flag', FlagLabel),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.user_id, 'Id'),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.nickname, 'Nickname'),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.role, 'Role'),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.trollbox_banned_at, 'Banned'),
  new DataTableColumn(TROLLBOX_MESSAGE_INFO_KEYS.timestamp, 'Time'),
];

const GROUPS = [new DataTableGroup('Author', 4, 7)];

const cn = classNamer('TrollboxMessagesPage');

const UserLabel = ({ user_id, nickname }) => {
  return (
    <span>
      <strong>{nickname}</strong> (#
      {user_id})
    </span>
  );
};

UserLabel.formatter = message => <UserLabel {...message} />;

const MessageLabel = ({ nickname, timestamp, message }) => {
  const date = new Date(timestamp);
  const dateString = `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCSeconds()}`;
  return (
    <span>
      [{dateString}] <strong>{nickname}: </strong>
      <span style={{ display: 'inline-block', maxWidth: '350px', wordBreak: 'break-word' }}>
        {message}
      </span>
    </span>
  );
};

MessageLabel.formatter = message => <MessageLabel {...message} />;

class TrollboxMessagesPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],

      banning: null,
      reason: '',
      duration: '',
      removingBan: null,

      flagging: null,
      removingFlag: null,
      flag: TROLLBOX_MESSAGE_FLAGS.spam,

      message: '',

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidMount() {
    this.loadData();
    this.subscribeToRoom();
  }

  componentDidUpdate() {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
          this.unsubscribeFromRoom();
          this.subscribeToRoom();
        }
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromRoom();
  }

  loadData() {
    this.promiseOrToast(this.container.client.getTrollbox(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  subscribeToRoom = () => {
    this.container.socketManager.enterSector(
      ADMIN_SOCKET_SECTORS.trollbox_admin,
      this.state.criteria.room
    );
    this.container.socketManager.onSectorEvent(
      ADMIN_SOCKET_SECTORS.trollbox_admin,
      ADMIN_SOCKET_EVENTS.broadcast_message_admin,
      message => {
        if (!this.state.criteria.page || this.state.criteria.page === '1') {
          this.setState(state => ({
            data: { ...state.data, items: [message, ...state.data.items.slice(0, -1)] },
          }));
        }
      }
    );
  };

  unsubscribeFromRoom = () => {
    this.container.socketManager.exitSector(ADMIN_SOCKET_SECTORS.trollbox_admin);
    this.container.socketManager.offSectorEvent(
      ADMIN_SOCKET_SECTORS.trollbox_admin,
      ADMIN_SOCKET_EVENTS.broadcast_message_admin
    );
  };

  postMessage = () => {
    return this.promiseOrToast(
      this.container.client.postTrollbox(this.state.criteria.room, { message: this.state.message })
    ).then(() => {
      toast.success('Posted message');
      this.setState({ message: '' });
    });
  };

  putFlagSelectedMessages = () => {
    this.setState({
      flagging: this.state.selection.filter(index => Boolean(!this.state.data.items[index].flag)),
    });
  };

  confirmBan = selection => {
    const { reason, duration } = this.state;
    const ids = selection.map(index => this.state.data.items[index].user_id);
    const payload = {
      user_ids: ids,
      reason,
    };

    if (duration) {
      payload.expires_at = new Date(Date.now() + duration * HOUR).toISOString();
    }
    return this.promiseOrToast(this.container.client.putTrollboxBan(payload)).then(() => {
      toast.success(ids.length > 1 ? `Banned ${ids.length} users` : `Banned user ${ids[0]}`);
      this.setState({ banReason: '', banDuration: '' });
      this.loadData();
    });
  };

  confirmRemoveBan = selection => {
    const ids = selection.map(index => this.state.data.items[index].user_id);
    return this.promiseOrToast(this.container.client.putTrollboxRemoveBan({ user_ids: ids })).then(
      () => {
        toast.success(
          ids.length > 1 ? `Removed ban for ${ids.length} users` : `Removed ban for user ${ids[0]}`
        );
        this.loadData();
      }
    );
  };

  confirmFlag = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    return this.promiseOrToast(
      this.container.client.putTrollboxFlag({
        message_ids: ids,
        flag: this.state.flag,
      })
    ).then(() => {
      toast.success(
        ids.length > 1 ? `Flagged ${ids.length} messages` : `Flagged message ${ids[0]}`
      );
      this.loadData();
    });
  };

  removeFlagSelectedMessages = () => {
    this.setState({
      removingFlag: this.state.selection.filter(index =>
        Boolean(this.state.data.items[index].flag)
      ),
    });
  };

  confirmRemoveFlag = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    return this.promiseOrToast(
      this.container.client.putTrollboxRemoveFlag({ message_ids: ids })
    ).then(() => {
      toast.success(
        ids.length > 1
          ? `Removed flag for ${ids.length} messages`
          : `Removed flag for message ${ids[0]}`
      );
      this.loadData();
    });
  };

  renderBanWidget = () => (
    <TrollboxBanWidget
      state={this.boundState}
      banningKey="banning"
      removingBanKey="removingBan"
      reasonKey="reason"
      durationKey="duration"
    />
  );

  renderFlagForm = () => {
    const flaggedMessages = this.state.selection.filter(index => this.state.data.items[index].flag);

    return (
      <Expander title="Flag message" memoryKey={cn('flag')}>
        <div className={cn('flag-form')}>
          <Select
            isSearchable={false}
            options={Object.values(FLAG_OPTIONS)}
            onChange={option => this.setState({ flag: option.value })}
            value={FLAG_OPTIONS[this.state.flag]}
            placeholder="Options"
          />
          <div className="d-flex mt-3">
            <IconButton
              title="Flag"
              onClick={this.putFlagSelectedMessages}
              color={FLAVORS.danger}
              icon={ICONS.flag_message}
              className="flex-fill ml-1"
              disabled={!(this.state.selection.length - flaggedMessages.length) || !this.state.flag}
            >
              Flag
            </IconButton>
            <IconButton
              title="Remove flag"
              onClick={this.removeFlagSelectedMessages}
              color={FLAVORS.primary}
              className="flex-fill ml-1"
              disabled={!flaggedMessages.length}
            >
              Remove
            </IconButton>
          </div>
        </div>
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    return (
      <div>
        {this.renderBanWidget()}
        {this.renderFlagForm()}
      </div>
    );
  };

  renderSidebarMany = selection => {
    return (
      <div>
        {this.renderBanWidget()}
        {this.renderFlagForm()}
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.trollbox.subsectionAtRoute(routes.TROLLBOX_MESSAGES)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <div>
              <TrollboxPostMessageWidget
                state={this.boundState}
                messageKey="message"
                postMessage={this.postMessage}
              />
              <SelectionSidebar
                selection={this.state.selection}
                renderOne={this.renderSidebarOne}
                renderMany={this.renderSidebarMany}
              />
            </div>
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaToggler
                    label="Room:"
                    options={ROOM_OPTIONS}
                    criteria={this.state.criteria}
                    field="room"
                  />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmBan}
          typeSingular="user"
          actionText="put ban for"
          buttonActionText="Ban"
          actionKey="banning"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmRemoveBan}
          typeSingular="user"
          actionText="remove ban for"
          buttonActionText="Remove"
          actionKey="removingBan"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={MessageLabel.formatter}
          onConfirmed={this.confirmFlag}
          typeSingular="message"
          actionText="flag "
          buttonActionText="Flag"
          actionKey="flagging"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={MessageLabel.formatter}
          onConfirmed={this.confirmRemoveFlag}
          typeSingular="message"
          actionText="remove flag for"
          buttonActionText="Remove"
          actionKey="removingFlag"
        />
      </PageLayout>
    );
  }
}

export default withRouter(TrollboxMessagesPage);
