import './NumericalValue.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../../lib/tools';

const formatter = (maximumFractionDigits, fixed) =>
  Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: fixed ? maximumFractionDigits : undefined,
  });

const NumericalValue = ({ className, value, suffix, digits = 20, fixed = false }) => {
  if (value === null || value === undefined) {
    return null;
  }

  let formattedValue = formatter(digits, fixed).format(value);
  if (suffix) {
    formattedValue += ' ' + suffix;
  }

  return <span className={classes('NumericalValue', className)}>{formattedValue}</span>;
};

NumericalValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
  digits: PropTypes.number,
  fixed: PropTypes.bool,
};

export default NumericalValue;
