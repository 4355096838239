import React from 'react';

import ErrorBox from '../widgets/presentational/ErrorBox';
import ConnectedComponent from './ConnectedComponent';

export default class ErrorBoundary extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', error => {
      this._handleError(error);
    });
  }

  componentDidCatch(error) {
    this._handleError(error);
  }

  _handleError(error) {
    this.setState({ error });

    if (error.code === 401) {
      this.container.auth.logOut();
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorBox error={this.state.error} />;
    }

    return this.props.children;
  }
}
