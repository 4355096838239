import PropTypes from 'prop-types';
import React from 'react';

import CriteriaToggler from '../widgets/criteria/CriteriaToggler';
import Toggler from '../widgets/interactive/Toggler';

const VIEWS = {
  effective: 'effective',
  configured: 'configured',
};

const VIEW_OPTIONS = [
  new Toggler.Option(VIEWS.effective, 'Effective'),
  new Toggler.Option(VIEWS.configured, 'Configured'),
];

const ConfigViewToggler = ({ criteria, field = 'view', label = 'Show:' }) => {
  return <CriteriaToggler label={label} options={VIEW_OPTIONS} criteria={criteria} field={field} />;
};

ConfigViewToggler.propTypes = {
  criteria: PropTypes.object,
  field: PropTypes.string,
  label: PropTypes.any,
};

ConfigViewToggler.VIEWS = VIEWS;

export default ConfigViewToggler;
