import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import TrollboxMessagesPage from './TrollboxMessagesPage';
import TrollboxProfanityPage from './TrollboxProfanityPage';
import TrollboxUsersPage from './TrollboxUsersPage';

class TrollboxRouter extends ConnectedComponent {
  render() {
    return (
      <div className="TrollboxRouter">
        <Breadcrumbed link={SECTIONS.trollbox}>
          <SubPager links={SECTIONS.trollbox.subsections} />
          <Switch>
            <Route
              path={routes.TROLLBOX}
              render={() => <StickyRedirect routes={SECTIONS.trollbox.subsections} />}
              exact
            />
            <Route path={routes.TROLLBOX_USERS} component={TrollboxUsersPage} exact />
            <Route path={routes.TROLLBOX_MESSAGES} component={TrollboxMessagesPage} exact />
            <Route path={routes.TROLLBOX_PROFANITY} component={TrollboxProfanityPage} exact />
            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default TrollboxRouter;
