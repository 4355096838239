import './WalletInternalsPage.css';

import React from 'react';

import { FLAVORS, WALLET_INFO_INSTRUMENT_KEYS, WALLET_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import IconButton from '../widgets/interactive/IconButton';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const cn = classNamer('WalletInternalsPage');

const FIXED_COLUMNS = [
  new DataTableColumn(WALLET_INFO_KEYS.user_id, 'ID'),
  new DataTableColumn(WALLET_INFO_KEYS.user_email, 'User email'),
  new DataTableColumn(WALLET_INFO_KEYS.loaded, 'Loaded', null, false),

  new DataTableColumn(WALLET_INFO_KEYS.snapshotted_at, 'Timestamp'),
  new DataTableColumn(WALLET_INFO_KEYS.last_trade_id, 'Trade id'),
  new DataTableColumn(WALLET_INFO_KEYS.last_transfer_id, 'Transfer id'),

  new DataTableColumn(WALLET_INFO_KEYS.audited_at, 'Timestamp'),
  new DataTableColumn(WALLET_INFO_KEYS.audit_clear, 'Clear'),
];

const FIXED_GROUPS = [new DataTableGroup('Snapshot', 3, 5), new DataTableGroup('Audit', 6, 7)];

const FIXED_HIGHLIGHTS = [3, 4, 5, 6, 7];

const makeDataTableConfig = instrumentList => {
  let index = FIXED_COLUMNS.length;
  const columns = [...FIXED_COLUMNS];
  const groups = [...FIXED_GROUPS];

  instrumentList.forEach(instrument => {
    groups.push(new DataTableGroup(instrument, index, index + 5));
    columns.push(
      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.total}`,
        'Total',
        null,
        false
      ),
      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.reserved}`,
        'Reserved',
        null,
        false
      ),
      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.available}`,
        'Available',
        null,
        false
      ),

      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.expected}`,
        'Expected',
        null,
        false
      ),
      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.discrepancy}`,
        'Discrepancy',
        null,
        false
      ),
      new DataTableColumn(
        `instruments.${instrument}.${WALLET_INFO_INSTRUMENT_KEYS.clear}`,
        'Clear',
        null,
        false
      )
    );
    index += 6;
  });

  return { columns, groups };
};

class WalletInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],

      criteria: Criteria.fromLocation(this.props.location),
    };

    this.tableConfig = makeDataTableConfig(this.container.settings.instrument_list);
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getWallets(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  onLoadClick = (user_ids, reload) => {
    return this.promiseOrToast(
      this.container.client.putWalletsLoad({
        user_ids,
        reload,
      })
    ).then(() => {
      this.loadData();
    });
  };

  onAuditClick = user_ids => {
    return this.promiseOrToast(
      this.container.client.putWalletsAudit({
        user_ids,
      })
    ).then(() => {
      this.loadData();
    });
  };

  onDeleteSnapshotsClick = user_ids => {
    return this.promiseOrToast(
      this.container.client.deleteWalletsSnapshots({
        user_ids,
      })
    ).then(() => {
      this.loadData();
    });
  };

  renderGlobalActions = () => {
    return (
      <Expander title="Global actions" memoryKey={cn('wallets-global-actions')}>
        <div className="d-flex">
          <IconButton
            className="flex-fill"
            type="button"
            onClick={() => this.onLoadClick(null, false)}
          >
            Load all
          </IconButton>
          <IconButton
            className="flex-fill ml-1"
            type="button"
            onClick={() => this.onLoadClick(null, true)}
          >
            Reload all
          </IconButton>
        </div>
        <div className="d-flex mt-1">
          <IconButton
            className="flex-fill"
            type="button"
            color={FLAVORS.primary}
            onClick={() => this.onAuditClick(null)}
          >
            Audit all
          </IconButton>
        </div>
        <div className="d-flex mt-1">
          <IconButton
            className="flex-fill"
            type="button"
            color={FLAVORS.danger}
            icon={ICONS.delete}
            onClick={() => this.onDeleteSnapshotsClick(null)}
          >
            Delete all snapshots
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderSidebar = () => {
    const selectedUserIds = this.state.selection.map(index => this.state.data.items[index].user_id);
    const selectedUserIdsWithSnapshots = this.state.selection
      .filter(index => this.state.data.items[index][WALLET_INFO_KEYS.snapshotted_at])
      .map(index => this.state.data.items[index].user_id);

    return (
      <div>
        {this.renderGlobalActions()}
        <Expander title="Selection actions" memoryKey={cn('wallets-selection-actions')}>
          <div className="d-flex">
            <IconButton
              className="flex-fill"
              type="button"
              onClick={() => this.onLoadClick(selectedUserIds, false)}
            >
              Load selected
            </IconButton>
            <IconButton
              className="flex-fill ml-1"
              type="button"
              onClick={() => this.onLoadClick(selectedUserIds, true)}
            >
              Reload selected
            </IconButton>
          </div>
          <div className="d-flex mt-1">
            <IconButton
              className="flex-fill"
              type="button"
              color={FLAVORS.primary}
              onClick={() => this.onAuditClick(selectedUserIds)}
            >
              Audit selected
            </IconButton>
          </div>
          <div className="d-flex mt-1">
            <IconButton
              className="flex-fill"
              type="button"
              color={FLAVORS.danger}
              icon={ICONS.delete}
              disabled={!selectedUserIdsWithSnapshots.length}
              onClick={() => this.onDeleteSnapshotsClick(selectedUserIdsWithSnapshots)}
            >
              Delete selected snapshots
            </IconButton>
          </div>
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_WALLETS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderNone={this.renderGlobalActions}
              renderOne={this.renderSidebar}
              renderMany={this.renderSidebar}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                {...this.tableConfig}
                highlight={FIXED_HIGHLIGHTS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default WalletInternalsPage;
