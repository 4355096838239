import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

export const ManagedSellOrderStatus = props => {
  /** @type {XcManagedSellOrder} */
  const order = props.order;

  if (order.cancelled_at) {
    return (
      <IconLabel flavor={FLAVORS.warning} icon={ICONS.warn}>
        Cancelled
      </IconLabel>
    );
  }

  if (order.completed_at) {
    if (order.failure_reason) {
      return <IconLabel flavor={FLAVORS.danger}>Failed</IconLabel>;
    }

    return (
      <IconLabel flavor={FLAVORS.success} icon={ICONS.good}>
        Completed
      </IconLabel>
    );
  }

  if (order.reverse_interchange_id) {
    return (
      <IconLabel flavor={FLAVORS.danger} icon={ICONS.reset}>
        Refunded
      </IconLabel>
    );
  }

  if (order.taken_at && !order.suspended_at) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.play}>
        Processing
      </IconLabel>
    );
  }

  if (order.suspended_at) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.pause}>
        On hold
      </IconLabel>
    );
  }

  return (
    <IconLabel flavor={FLAVORS.info} icon={ICONS.pause}>
      Pending
    </IconLabel>
  );
};
