import './IconButton.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { AppIcon, ICONS, ICON_LIST } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('IconButton');

const IconButton = ({ className, icon, iconRight, loading, children, ...rest }) => {
  let iconClass = '';
  if (React.Children.count(children) > 0) {
    iconClass = iconRight ? 'ml-2' : 'mr-2';
  }

  className = classes(cn(), className);

  if (loading && icon) {
    // If loading, replace icon with a loader icon, and make the button disabled
    icon = ICONS.loader;
    rest.disabled = true;
  }

  if ((rest.href || rest.to) && rest.disabled && rest.title) {
    // If link is disabled, it doesn't show a title on hover. This sucks, so let's do something to fix it
    rest.tabIndex = -1;
    rest.ariaDisabled = true;
    className = classes(className, cn('disabled'));
    rest.onClick = e => {
      e.preventDefault();
    };
  }

  const iconElement = icon ? <AppIcon icon={icon} className={iconClass} spin={loading} /> : null;

  return (
    <Button className={className} {...rest}>
      {!iconRight && iconElement}
      {children}
      {iconRight && iconElement}
    </Button>
  );
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(ICON_LIST),
  iconRight: PropTypes.bool,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  'aria-label': PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
  cssModule: PropTypes.object,
  close: PropTypes.bool,
};

export default IconButton;
