import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';

import { XcInstrumentConfigPayload } from '../../lib/backend';
import { INSTRUMENT_CONFIG_KEYS, defaultInstrumentConfig } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import ConfigDisabledIndicator from '../configs/ConfigDisabledIndicator';
import ConfigViewToggler from '../configs/ConfigViewToggler';
import InstrumentConfigEditor from '../configs/InstrumentConfigEditor';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import ExpanderAddOn from '../layout/ExpanderAddOn';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import EnumSelect from '../widgets/interactive/EnumSelect';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const BASE_CRITERIA = {
  view: ConfigViewToggler.VIEWS.effective,
};

const FIXED_COLUMNS = [new DataTableColumn('id', 'ID'), new DataTableColumn('email', 'Email')];

const makeDataTableConfig = (view, instrumentList) => {
  let index = FIXED_COLUMNS.length;
  const columns = [
    ...FIXED_COLUMNS,
    ConfigDisabledIndicator.dataTableColumn(
      `global.${view}`,
      INSTRUMENT_CONFIG_KEYS.disable_deposits,
      'Dep'
    ),
    ConfigDisabledIndicator.dataTableColumn(
      `global.${view}`,
      INSTRUMENT_CONFIG_KEYS.disable_withdrawals,
      'Wth'
    ),
  ];
  const groups = [new DataTableGroup('General', index, index + 1)];
  const highlight = [2, 3];
  index += 2;

  instrumentList.forEach(instrument => {
    groups.push(new DataTableGroup(instrument, index, index + 1));
    columns.push(
      ConfigDisabledIndicator.dataTableColumn(
        `individual.${instrument}.${view}`,
        INSTRUMENT_CONFIG_KEYS.disable_deposits,
        'Dep'
      ),
      ConfigDisabledIndicator.dataTableColumn(
        `individual.${instrument}.${view}`,
        INSTRUMENT_CONFIG_KEYS.disable_withdrawals,
        'Wth'
      )
    );
    index += 2;
  });

  return { columns, groups, highlight };
};

const cn = classNamer('UserInstrumentConfigsPage');

class UserInstrumentConfigsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],

      editInstrument: this.container.settings.instrument_list[0],

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  getDataTableConfig = createSelector(
    state => state.criteria.view,
    () => this.container.settings.instrument_list,
    makeDataTableConfig
  );

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria, BASE_CRITERIA)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getConfigInstrumentsUsers(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  updateConfig = (instruments, userIds, value, successMessage) => {
    const payload = new XcInstrumentConfigPayload({
      ...value,
      user_ids: userIds || null,
      instruments: instruments || null,
    });
    return this.promiseOrToast(this.container.client.putConfigInstrument(payload))
      .then(() => {
        toast.success(successMessage);
      })
      .finally(() => {
        this.loadData();
      });
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcUserConfigInfo} */
    const uci = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Set general" memoryKey={cn('edit-global-one')}>
          <InstrumentConfigEditor
            data={uci.global.configured || defaultInstrumentConfig}
            withDisabling
            callback={value =>
              this.updateConfig(
                null,
                [uci.id],
                value,
                `Updated general instrument config for "${uci.email}"`
              )
            }
          />
        </Expander>

        <Expander title="Set for instrument" memoryKey={cn('edit-individual-one')}>
          <ExpanderAddOn>
            <Bind state={this.boundState}>
              <EnumSelect
                values={this.container.settings.instruments}
                name="editInstrument"
                id="editInstrument"
              />
            </Bind>
          </ExpanderAddOn>

          <InstrumentConfigEditor
            data={uci.individual[this.state.editInstrument].configured || defaultInstrumentConfig}
            withDisabling
            callback={value =>
              this.updateConfig(
                [this.state.editInstrument],
                [uci.id],
                value,
                `Updated ${this.state.editInstrument} config for "${uci.email}"`
              )
            }
          />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = selection => {
    const userIds = selection.map(index => this.state.data.items[index].id);
    const count = userIds.length;
    return (
      <div>
        <Expander title={`Set gobal (${count} users)`} memoryKey={cn('edit-global-many')}>
          <InstrumentConfigEditor
            data={null}
            withDisabling
            callback={value =>
              this.updateConfig(
                null,
                userIds,
                value,
                `Updated global instrument config for ${count} users`
              )
            }
          />
        </Expander>

        <Expander
          title={`Set for instrument (${count} users)`}
          memoryKey={cn('edit-individual-many')}
        >
          <ExpanderAddOn>
            <Bind state={this.boundState}>
              <EnumSelect
                values={this.container.settings.instruments}
                name="editInstrument"
                id="editInstrument"
              />
            </Bind>
          </ExpanderAddOn>

          <InstrumentConfigEditor
            data={null}
            withDisabling
            callback={value =>
              this.updateConfig(
                [this.state.editInstrument],
                userIds,
                value,
                `Updated ${this.state.editInstrument} config for ${count} users`
              )
            }
          />
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_INSTRUMENT_CONFIGS)} />
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <ConfigViewToggler criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                {...this.getDataTableConfig(this.state)}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(UserInstrumentConfigsPage);
