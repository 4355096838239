import './CustomersPage.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';

import {
  ADMIN_PERMISSIONS,
  GENDERS,
  KYC_TRACKS,
  SORT_DIRECTIONS,
  XcForceSetPhoneVerifiedPayload,
} from '../../lib/backend';
import {
  CUSTOMER_INFO_KEYS,
  FLAVORS,
  KYC_LEVEL2_METHOD_NAMES,
  SOF_ORIGIN_LABELS,
  SOF_SALARY_RANGE_LABELS,
} from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import {
  customerAddress,
  customerHasRequestedKYC2ImageVerification,
  customerHasRequestedKYC2LetterVerification,
  customerHasScheduledKYC2InPersonVerification,
  customerIsKYCLocked,
  customerName,
  customerProviderUrl,
  customerScreeningProviderUrl,
  customerVerificationProviderUrl,
  customerWaitingForFailedMicropaymentAuditResponse,
  customerWaitingForMicropaymentAudit,
  customerWaitingForScreeningRelease,
  customerWaitingForScreeningVerdict,
  customerWaitingForVerificationVerdict,
  customersClassifyKYCRequestStates,
} from '../../lib/selectors';
import { abbreviated, classNamer, classes, nestPrefixes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Expander from '../layout/Expander';
import ExpanderGroup from '../layout/ExpanderGroup';
import GridLayout from '../layout/GridLayout';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundConfirmationModal from '../widgets/bound/BoundConfirmationModal';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import CriteriaToggler from '../widgets/criteria/CriteriaToggler';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import Toggler from '../widgets/interactive/Toggler';
import BadgeList from '../widgets/presentational/BadgeList';
import ConstrainedMultilineText from '../widgets/presentational/ConstrainedMultilineText';
import CountryLabel from '../widgets/presentational/CountryLabel';
import IconLabel from '../widgets/presentational/IconLabel';
import ImagePopoutLink from '../widgets/presentational/ImagePopoutLink';
import KYCLevelIcon from '../widgets/presentational/KYCLevelIcon';
import NestedExpandableObjectInfo from '../widgets/presentational/NestedExpandableObjectInfo';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import PhoneNumberLink from '../widgets/presentational/PhoneNumberLink';
import Time from '../widgets/presentational/Time';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import { MicropaymentEditPageNavigationState } from './MicropaymentEditPage';
import BoundMicropaymentAuditModal from './widgets/BoundMicropaymentAuditModal';
import CriteriaKYCAdminActionPicker from './widgets/CriteriaKYCAdminActionPicker';
import CriteriaNationalityPicker from './widgets/CriteriaNationalityPicker';
import CustomerKYC1Outcome from './widgets/CustomerKYC1Outcome';
import CustomerKYC2Outcome from './widgets/CustomerKYC2Outcome';
import CustomerKYCLetterStatus from './widgets/CustomerKYCLetterStatus';
import CustomerKYCRequestStatus from './widgets/CustomerKYCRequestStatus';
import CustomerKYCScreeningStatus from './widgets/CustomerKYCScreeningStatus';
import CustomerVerificationStatus from './widgets/CustomerVerificationStatus';
import MicropaymentAuditStatus from './widgets/MicropaymentAuditStatus';
import MicropaymentDocumentList from './widgets/MicropaymentDocumentList';
import MicropaymentOutcome from './widgets/MicropaymentOutcome';
import ResetKYCLevelModal from './widgets/ResetKYCLevelModal';
import UserLabel from './widgets/UserLabel';

const cn = classNamer('CustomersPage');

// *********************************************************************************************************************

const TRANSIENT_FIELDS = ['view'];

const VIEWS = {
  records: 'records',
  kyc1: 'kyc1',
  kyc2: 'kyc2',
  kyc3: 'kyc3',
};

const BASE_CRITERIA = {
  view: VIEWS.records,
  sort_field: CUSTOMER_INFO_KEYS.updated_at,
  sort_direction: SORT_DIRECTIONS.desc,
  nationalities: undefined,
  admin_action: undefined,
};

// *********************************************************************************************************************

const providerLink = (swdId, url) => {
  if (!swdId || !url) {
    return null;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {abbreviated(swdId)}
    </a>
  );
};

const formatKYCLockVerb = locked => {
  return locked ? (
    <IconLabel flavor={FLAVORS.danger} className="font-weight-bold">
      LOCKED
    </IconLabel>
  ) : (
    <IconLabel flavor={FLAVORS.success} className="font-weight-bold">
      ALLOWED
    </IconLabel>
  );
};

// *********************************************************************************************************************

class CustomersPage extends ConnectedComponent {
  static propTypes = {
    kycTrack: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],
      overridingDocumentVerification: null,
      resettingKYC: null,
      refusingKYC: null,
      refusingKYCReason: '',
      lockingKYC: null,
      lockingKYCReason: '',
      lockingKYCActive: false,
      unlockingKYC: null,
      /** @type {XcCustomerInfo} */
      auditingCustomer: null,
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
      updatingIds: {},
      settingPhoneVerifiedSelection: null,
      settingPhoneVerifiedValue: null,
    };
  }

  get kycTrack() {
    return this.props.kycTrack || KYC_TRACKS.base;
  }

  get isVQF() {
    return this.kycTrack === KYC_TRACKS.vqf;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria, TRANSIENT_FIELDS)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(
      this.container.client.getCustomersInfos({
        ...this.state.criteria,
        kyc_track: this.kycTrack,
      })
    ).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  reloadCustomer(userId) {
    return this.promiseOrToast(
      this.container.client.getCustomersInfo(userId, this.kycTrack),
      true
    ).then(
      /** XcCustomerInfo */ ci => {
        // Replace the customer record in state.data.items
        const items = this.state.data.items.map(
          /** XcCustomerInfo */ existingCi => {
            return existingCi.user_id === ci.user_id ? ci : existingCi;
          }
        );
        this.setState({
          data: {
            ...this.state.data,
            items,
          },
        });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * @param {XcCustomerInfo} ci
   */
  customerIsBeingUpdated(ci) {
    return !!this.state.updatingIds[ci.user_id];
  }

  /**
   * @param {XcCustomerInfo} ci
   * @param {function(XcCustomerInfo)} action
   */
  executeBackgroundUpdateForCustomer = (ci, action) => {
    const userId = ci.user_id;

    this.setState({
      updatingIds: {
        ...this.state.updatingIds,
        [userId]: true,
      },
    });

    return this.promiseOrToast(
      action(ci).finally(() => {
        // Prevent jitter with timeout
        setTimeout(() => {
          this.setState({
            updatingIds: {
              ...this.state.updatingIds,
              [userId]: false,
            },
          });
        }, 1);
      }),
      true
    ).then(res => {
      this.reloadCustomer(userId);
      return res;
    });
  };

  /**
   * @param {number[]} selection
   * @param {function(XcCustomerInfo)} action
   */
  executeBackgroundKYCRequestUpdateForSingleSelection = (selection, action) => {
    /** @type {XcCustomerInfo} */
    const ci = this.state.data.items[selection[0]];
    if (!ci) {
      return;
    }

    return this.executeBackgroundUpdateForCustomer(ci, action).then((
      /* XcCustomerInfo */ customer
    ) => {
      const rejectedLevel =
        customer.kyc2_granted === false ? 2 : customer.kyc1_granted === false ? 1 : null;
      if (rejectedLevel) {
        toast.warn(
          `KYC ${rejectedLevel} request for ${customerName(ci)} (${
            ci.user_id
          }) has been rejected: ${customer.kyc1_failure_message || customer.kyc2_failure_message}.`
        );
      } else {
        (customer.kyc_admin_action ? toast.warn : toast.success)(
          `KYC ${ci.kyc_level_granted} request for ${customerName(ci)} (${
            ci.user_id
          }) has been granted.`
        );
      }
    });
  };

  // *******************************************************************************************************************

  /**
   * @param {MouseEvent<HTMLLinkElement>} e
   * @param  {XcCustomerInfo} ci
   */
  handleAddMicropaymentClick = (e, ci) => {
    // Do not actually go to the link directly
    e.preventDefault();

    // Instead, go to the link with some state
    this.container.history.push({
      pathname: routes.VQF_CUSTOMERS_MICROPAYMENTS_NEW,
      state: new MicropaymentEditPageNavigationState(ci.user_id),
    });
  };

  // *******************************************************************************************************************

  resetKYCForCustomer = (customer, targetLevel) => {
    this.setState({
      resettingKYC: {
        customer,
        targetLevel,
      },
    });
  };

  confirmResetKYC = () => {
    const { customer, targetLevel } = this.state.resettingKYC;
    return this.executeBackgroundUpdateForCustomer(customer, () => {
      return this.container.client.putKycResetToLevel({
        target_level: targetLevel,
        user_ids: [customer.user_id],
        target_track: this.kycTrack,
      });
    }).then(() => {
      toast.success(
        `Customer ${customerName(customer)} has been reset to KYC level ${targetLevel}`
      );
    });
  };

  // *******************************************************************************************************************

  releaseScreeningForSelectedUser = () => {
    return this.executeBackgroundKYCRequestUpdateForSingleSelection(this.state.selection, ci => {
      return this.container.client.putKycLevel1ReleaseScreening(ci.user_id);
    });
  };

  // *******************************************************************************************************************

  overrideDocumentVerificationForSelectedUser = withConfirmation => {
    if (withConfirmation) {
      // Show confirm dialog first
      this.setState({
        overridingDocumentVerification: this.state.selection,
      });
    } else {
      // Call the backend immediately
      return this.executeOverrideDocumentVerification(this.state.selection);
    }
  };

  executeOverrideDocumentVerification = selection => {
    return this.executeBackgroundKYCRequestUpdateForSingleSelection(selection, ci => {
      return this.container.client.putKycLevel1OverrideVerification(ci.user_id);
    });
  };

  // *******************************************************************************************************************

  toggleKYC2LetterSentStateForSelectedUser = () => {
    /** @type {XcCustomerInfo} */
    const ci = this.state.data.items[this.state.selection[0]];
    if (!ci) {
      return;
    }

    const payload = {
      sent: !ci.kyc2_letter_sent_at,
    };

    this.promiseOrToast(this.container.client.putKycLevel2Letter(ci.user_id, payload)).then(() => {
      toast.success(`KYC2 letter status for ${customerName(ci)} (${ci.user_id}) has been updated`);

      this.reloadCustomer(ci.user_id);
    });
  };

  // *******************************************************************************************************************

  grantKYCForSelectedUsers = () => {
    const userIds = this.state.selection.map(index => this.state.data.items[index].user_id);

    this.promiseOrToast(
      this.container.client.putKycResolve({
        user_ids: userIds,
        grant: true,
      })
    ).then(() => {
      toast.success(
        userIds.length > 1
          ? `KYC request has been granted for ${userIds.length} customers`
          : `KYC request has been granted for customer ${userIds[0]}`
      );
      this.loadData();
    });
  };

  // *******************************************************************************************************************

  performMicropaymentAuditForCustomer = ci => {
    this.setState({
      auditingCustomer: ci,
    });
  };

  /**
   * @param {XcKYCMicropaymentAuditResultPayload} payload
   */
  handleMicropaymentAuditCallback = payload => {
    const ci = this.state.auditingCustomer;

    if (!payload || !ci) {
      // Auditor has cancelled out of the modal
      return;
    }

    this.promiseOrToast(this.container.client.putKycLevel3AuditResult(ci.user_id, payload)).then(
      () => {
        toast.success(
          `Micropayment audit ${ci.kyc3_micropayment_id}-${
            ci.micropayment_audit_id
          } for ${customerName(ci)} (${ci.user_id}) has been submitted successfully`
        );
        this.reloadCustomer(ci.user_id);
      }
    );
  };

  launchMicropaymentAuditForSelectedUser = () => {
    /** @type {XcCustomerInfo} */
    const ci = this.state.data.items[this.state.selection[0]];
    if (!ci) {
      return;
    }

    this.promiseOrToast(this.container.client.putKycLevel3LaunchAudit(ci.user_id)).then(() => {
      toast.success(
        `New audit has been launched for micropayment ${ci.kyc3_micropayment_id} by ${customerName(
          ci
        )}`
      );
      this.reloadCustomer(ci.user_id);
    });
  };

  // *******************************************************************************************************************

  refuseKYCForSelectedUsers = () => {
    this.setState({
      refusingKYC: this.state.selection,
    });
  };

  confirmRefuseKYC = selection => {
    const userIds = selection.map(index => this.state.data.items[index].user_id);
    this.promiseOrToast(
      this.container.client.putKycResolve({
        user_ids: userIds,
        grant: false,
        message: this.state.refusingKYCReason,
      })
    ).then(() => {
      toast.success(
        userIds.length > 1
          ? `KYC request has been refused for ${userIds.length} customers`
          : `KYC request has been refused for customer ${userIds[0]}`
      );
      this.loadData();
    });
  };

  // *******************************************************************************************************************

  lockKYCForSelectedUsers = fromAnActiveRequest => {
    this.setState({
      lockingKYC: this.state.selection,
      lockingKYCActive: !!fromAnActiveRequest,
    });
  };

  confirmLockKYC = selection => {
    const userIds = selection.map(index => this.state.data.items[index].user_id);
    this.promiseOrToast(
      this.container.client.putKycSetLocked({
        user_ids: userIds,
        locked: true,
        message: this.state.lockingKYCReason,
      })
    ).then(() => {
      toast.success(
        userIds.length > 1
          ? `KYC requests have been locked for ${userIds.length} customers`
          : `KYC requests have been locked for customer ${userIds[0]}`
      );
      this.loadData();
    });
  };

  // *******************************************************************************************************************

  unlockKYCForSelectedUsers = () => {
    this.setState({
      unlockingKYC: this.state.selection,
    });
  };

  confirmUnlockKYC = selection => {
    const userIds = selection.map(index => this.state.data.items[index].user_id);
    this.promiseOrToast(
      this.container.client.putKycSetLocked({
        user_ids: userIds,
        locked: false,
      })
    ).then(() => {
      toast.success(
        userIds.length > 1
          ? `KYC requests have been unlocked for ${userIds.length} customers`
          : `KYC requests have been unlocked for customer ${userIds[0]}`
      );
      this.loadData();
    });
  };

  // *******************************************************************************************************************

  setPhoneVerifiedForSelectedUsers = verified => {
    this.setState({
      settingPhoneVerifiedSelection: this.state.selection.filter(
        index =>
          (verified && !this.state.data.items[index].phone_verified) ||
          (!verified && !!this.state.data.items[index].phone_verified)
      ),
      settingPhoneVerifiedValue: verified,
    });
  };

  confirmSetPhoneVerified = selection => {
    const payload = new XcForceSetPhoneVerifiedPayload({
      user_ids: selection.map(index => this.state.data.items[index].user_id),
      verified: this.state.settingPhoneVerifiedValue,
    });

    return this.promiseOrToast(this.container.client.putPhoneVerificationForceVerify(payload)).then(
      () => {
        const action = payload.verified ? 'verified' : 'unverified';
        toast.success(
          payload.user_ids.length > 1
            ? `${payload.user_ids.length} customer phone numbers marked as ${action}`
            : `Customer ${payload.user_ids[0]}'s phone number marked as ${action}`
        );
        this.loadData();
      }
    );
  };

  // *******************************************************************************************************************

  getDataTableColumnsAndGroups = createSelector(
    kycTrack => kycTrack,
    (_, view) => view,
    (kycTrack, view) => {
      const groups = [];
      const columns = [
        new DataTableColumn(CUSTOMER_INFO_KEYS.user_id, 'ID'),
        new DataTableColumn(CUSTOMER_INFO_KEYS.email, 'Email'),
        kycTrack !== KYC_TRACKS.vqf &&
          new DataTableColumn(
            CUSTOMER_INFO_KEYS.kyc_level_granted,
            { tooltip: 'BASE KYC Level', title: 'B' },
            (/** XcCustomerInfo */ ci) => (
              <div className="text-center h4">
                <KYCLevelIcon level={ci.kyc_level_granted} />
              </div>
            )
          ),
        new DataTableColumn(
          CUSTOMER_INFO_KEYS.kyc_level_granted_vqf,
          { tooltip: 'VQF KYC Level', title: 'V' },
          (/** XcCustomerInfo */ ci) => (
            <div className="text-center h4">
              <KYCLevelIcon level={ci.kyc_level_granted_vqf} />
            </div>
          )
        ),
        new DataTableColumn(
          [
            CUSTOMER_INFO_KEYS.kyc_admin_action,
            CUSTOMER_INFO_KEYS.kyc_locked_at,
            CUSTOMER_INFO_KEYS.kyc_level_requested,
            CUSTOMER_INFO_KEYS.kyc2_method,
            CUSTOMER_INFO_KEYS.kyc_level_granted,
            CUSTOMER_INFO_KEYS.kyc2_processing_started_at,
          ],
          'Status',
          (/** XcCustomerInfo */ ci, _, updatingIds) => {
            return (
              <CustomerKYCRequestStatus
                customer={ci}
                kycTrack={this.kycTrack}
                updating={updatingIds[ci.user_id]}
              />
            );
          }
        ),
      ].filter(Boolean);

      switch (view) {
        case VIEWS.records: {
          columns.push(
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.first_name,
                CUSTOMER_INFO_KEYS.middle_name,
                CUSTOMER_INFO_KEYS.last_name,
              ],
              'Name',
              ci => customerName(ci)
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.nationality, 'Nationality', ci => (
              <CountryLabel isoCode={ci.nationality} label />
            )),
            new DataTableColumn(
              [CUSTOMER_INFO_KEYS.phone_verified, CUSTOMER_INFO_KEYS.phone],
              'Phone',
              /** XcCustomerInfo */ ci => (
                <PhoneNumberLink phone={ci.phone} verified={!!ci.phone_verified} />
              )
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.residence_address, 'Address', ci =>
              customerAddress(ci)
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.residence_country, 'Residence', ci => (
              <CountryLabel isoCode={ci.residence_country} label />
            )),
            new DataTableColumn(
              CUSTOMER_INFO_KEYS.gender,
              { title: 'M/F', tooltip: 'Gender (M/F/X)' },
              /** XcCustomerInfo */ ci => (
                <div className="text-center">
                  {ci.gender === GENDERS.male
                    ? 'M'
                    : ci.gender === GENDERS.female
                    ? 'F'
                    : ci.gender === GENDERS.other
                    ? 'X'
                    : ''}
                </div>
              )
            ),
            new DataTableColumn(
              CUSTOMER_INFO_KEYS.dob,
              'Birth date',
              /** XcCustomerInfo */ ci => <Time value={ci.dob} format={Time.FORMATS.date} utc />
            ),
            new DataTableColumn(
              CUSTOMER_INFO_KEYS.swiftdil_id,
              'SWD',
              /** XcCustomerInfo */ ci => {
                if (ci.swiftdil_id) {
                  return providerLink(ci.swiftdil_id, customerProviderUrl(ci));
                }
              }
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.created_at, 'Created'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.updated_at, 'Updated')
          );
          break;
        }

        case VIEWS.kyc1: {
          groups.push(
            new DataTableGroup('Document verification', columns.length, columns.length + 3),
            new DataTableGroup('Screening', columns.length + 4, columns.length + 5)
          );
          columns.push(
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.verification_nv_scan_reference,
                CUSTOMER_INFO_KEYS.verification_swiftdil_id,
              ],
              'Provider ID',
              /** XcCustomerInfo */ ci =>
                providerLink(
                  ci.verification_nv_scan_reference || ci.verification_swiftdil_id,
                  customerVerificationProviderUrl(ci)
                )
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.verification_document_type, 'Type'),
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.verification_image_front_id,
                CUSTOMER_INFO_KEYS.verification_image_back_id,
                CUSTOMER_INFO_KEYS.verification_image_face_id,
              ],
              'Images',
              /** XcCustomerInfo */ ci => {
                return (
                  <span className="text-nowrap">
                    <ImagePopoutLink imageId={ci.verification_image_front_id}>
                      Front
                    </ImagePopoutLink>
                    <ImagePopoutLink imageId={ci.verification_image_back_id}>Back</ImagePopoutLink>
                    <ImagePopoutLink imageId={ci.verification_image_face_id}>Face</ImagePopoutLink>
                  </span>
                );
              }
            ),
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.verification_document_status,
                CUSTOMER_INFO_KEYS.verification_reject_document_reason,
                CUSTOMER_INFO_KEYS.verification_overridden_at,
                CUSTOMER_INFO_KEYS.verification_completed_at,
              ],
              'Status',
              ci => <CustomerVerificationStatus customer={ci} />
            ),

            new DataTableColumn(
              CUSTOMER_INFO_KEYS.kyc1_screening_id,
              'Provider ID',
              /** XcCustomerInfo */ ci =>
                providerLink(
                  ci.screening_ca_search_reference || ci.screening_swiftdil_id,
                  customerScreeningProviderUrl(ci)
                )
            ),
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.screening_examined_at,
                CUSTOMER_INFO_KEYS.screening_executed_at,
              ].join(','),
              'Status',
              ci => <CustomerKYCScreeningStatus customer={ci} />
            ),

            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc1_created_at, 'Requested'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc1_completed_at, 'Completed'),
            new DataTableColumn(
              [CUSTOMER_INFO_KEYS.kyc1_failure_message, CUSTOMER_INFO_KEYS.kyc1_completed_at],
              'KYC1 outcome',
              (/** XcCustomerInfo */ ci, _, updatingIds) => {
                return <CustomerKYC1Outcome customer={ci} updating={updatingIds[ci.user_id]} />;
              }
            )
          );
          break;
        }

        case VIEWS.kyc2: {
          groups.push(
            new DataTableGroup('Document', columns.length + 1, columns.length + 1),
            new DataTableGroup('Letter', columns.length + 2, columns.length + 3)
          );
          columns.push(
            new DataTableColumn(
              CUSTOMER_INFO_KEYS.kyc2_method,
              'Method',
              /** XcCustomerInfo */ ci =>
                ci.kyc2_method ? (
                  <IconLabel>{KYC_LEVEL2_METHOD_NAMES[ci.kyc2_method]}</IconLabel>
                ) : null
            ),

            new DataTableColumn(
              CUSTOMER_INFO_KEYS.kyc2_image_id,
              'Images',
              /** XcCustomerInfo */ ci => {
                return (
                  <span className="text-nowrap">
                    <ImagePopoutLink imageId={ci.kyc2_image_id}>Selfie</ImagePopoutLink>
                  </span>
                );
              }
            ),

            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc2_code, 'Code'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc2_letter_sent_at, 'Taken?', ci => (
              <CustomerKYCLetterStatus customer={ci} />
            )),

            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc2_created_at, 'Requested'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.kyc2_completed_at, 'Completed'),
            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.kyc2_failure_message,
                CUSTOMER_INFO_KEYS.kyc2_completed_at,
                CUSTOMER_INFO_KEYS.kyc_admin_action,
              ],
              'KYC2 outcome',
              ci => <CustomerKYC2Outcome customer={ci} />
            )
          );
          break;
        }

        case VIEWS.kyc3: {
          groups.push(new DataTableGroup('Micropayment', columns.length, columns.length + 5));
          columns.push(
            new DataTableColumn(
              CUSTOMER_INFO_KEYS.micropayment_transaction_id,
              'Transaction ID',
              /** XcCustomerInfo */ ci => abbreviated(ci.micropayment_transaction_id)
            ),
            new DataTableColumn(CUSTOMER_INFO_KEYS.micropayment_full_name, 'Sender name'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.micropayment_swift, 'Swift'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.micropayment_iban, 'IBAN'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.micropayment_reference_number, 'Ref. number'),
            new DataTableColumn(CUSTOMER_INFO_KEYS.micropayment_payment_date, 'Date'),

            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.audit_result,
                CUSTOMER_INFO_KEYS.audit_result_notes,
                CUSTOMER_INFO_KEYS.audit_requested_at,
                CUSTOMER_INFO_KEYS.micropayment_updated_at,
              ],
              'Audit status',
              MicropaymentAuditStatus.customerInfoFormatter
            ),

            new DataTableColumn(
              [
                CUSTOMER_INFO_KEYS.micropayment_processed_at,
                CUSTOMER_INFO_KEYS.micropayment_failure_message,
              ],
              'KYC3 outcome',
              MicropaymentOutcome.customerInfoFormatter
            )
          );
          break;
        }
      }

      return [columns, groups];
    }
  );

  // *******************************************************************************************************************

  renderResetKYCLevelButton = (/** XcCustomerInfo */ customer, targetLevel) => {
    let title, icon;
    if (targetLevel > customer.kyc_level_granted) {
      title = 'Upgrade level to ' + targetLevel;
      icon = ICONS.upgrade;
    } else if (targetLevel < customer.kyc_level_granted) {
      title = 'Downgrade level to ' + targetLevel;
      icon = ICONS.downgrade;
    } else {
      title = 'Reset state at level ' + targetLevel;
      icon = ICONS.reset;
    }

    return (
      <IconButton
        icon={icon}
        color={FLAVORS.danger}
        onClick={() => this.resetKYCForCustomer(customer, targetLevel)}
        title={title}
      >
        {targetLevel}
      </IconButton>
    );
  };

  renderActionResetKYCLevelSingle = (/** XcCustomerInfo */ customer) => {
    if (this.customerIsBeingUpdated(customer)) {
      return null;
    }

    return (
      <Expander title={`Reset ${this.isVQF ? 'VQF' : ''} KYC`} memoryKey={cn('kyc-reset')} danger>
        <p>
          Reset KYC state of <UserLabel id={customer.user_id} email={customer.email} /> to a certain
          level, potentially overriding verifications and cleaning up superfluous data. The nuclear
          option.
        </p>
        {this.isVQF && customer.kyc_level_granted_vqf <= 0 && (
          <p>
            <strong>NOTE:</strong> If you grant this customer <strong>VQF KYC</strong>, they will be
            automatically granted <strong>base KYC 2</strong> as well!
          </p>
        )}
        {!this.isVQF && customer.kyc_level_granted_vqf > 0 && (
          <p>
            <strong>NOTE:</strong> If you downgrade this customer's <strong>base KYC</strong>, they
            will also lose their <strong>VQF KYC</strong> as well!
          </p>
        )}
        <p>
          Current level:{' '}
          <strong>
            {this.isVQF ? customer.kyc_level_granted_vqf : customer.kyc_level_granted}
          </strong>
        </p>
        <GridLayout autoCols unresponsive gap={1}>
          {this.renderResetKYCLevelButton(customer, 0)}
          {this.renderResetKYCLevelButton(customer, 1)}
          {this.renderResetKYCLevelButton(customer, 2)}
          {this.isVQF && this.renderResetKYCLevelButton(customer, 3)}
        </GridLayout>
      </Expander>
    );
  };

  renderActionKYC1VerificationVerdictSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerWaitingForVerificationVerdict(ci) || this.customerIsBeingUpdated(ci)) {
      return null;
    }

    return (
      <Expander title="KYC1 document verification" memoryKey={cn('kyc1-action')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> has been flagged during document
          verification due to{' '}
          {ci.verification_nv_verified ? (
            <strong className="font-italic">
              discrepancies between customer data and submitted document
            </strong>
          ) : (
            <strong className="font-italic">a problem reported by the KYC provider</strong>
          )}
          .
        </p>
        <p>
          Verification details are available{' '}
          <a href={customerVerificationProviderUrl(ci)} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <div className="mt-2">
          You can <strong className="font-italic">refuse</strong> the verification, sending customer
          back for another try. In addition, you can also{' '}
          <strong className="font-italic">lock</strong> customer from applying for KYC1 until
          further notice.
        </div>
        <GridLayout autoCols gap={1} unresponsive className="mt-2">
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.muted}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Refuse
          </IconButton>
          <IconButton
            icon={ICONS.lock}
            color={FLAVORS.danger}
            onClick={() => this.lockKYCForSelectedUsers(true)}
          >
            Refuse and lock
          </IconButton>
        </GridLayout>

        {ci.verification_nv_verified && (
          <div className="mt-2">
            Since verification has been successful on the provider side, you can also{' '}
            <strong className="font-italic">override</strong> the failed verification and let the
            KYC request continue to the next stage (screening).
            <br />
            <IconButton
              className="mt-2"
              block
              icon={ICONS.good}
              color={FLAVORS.warning}
              onClick={() => this.overrideDocumentVerificationForSelectedUser(false)}
            >
              Override
            </IconButton>
          </div>
        )}
      </Expander>
    );
  };

  renderActionScreeningReleaseSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerWaitingForScreeningRelease(ci) || this.customerIsBeingUpdated(ci)) {
      return null;
    }

    return (
      <Expander title="Screening release" memoryKey={cn('kyc1-action')}>
        <p>
          Screening for customer <UserLabel id={ci.user_id} email={ci.email} /> has stalled.{' '}
        </p>
        <p>
          This could happen due to provider error or other technical problems. Customer is probably
          not at fault.
        </p>
        <p>
          Click <strong className="font-italic">release</strong> to execute the screening now. If
          this state persists, please involve the techs.
        </p>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            icon={ICONS.good}
            color={FLAVORS.success}
            onClick={this.releaseScreeningForSelectedUser}
          >
            Release
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Refuse
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionScreeningVerdictSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerWaitingForScreeningVerdict(ci)) {
      return null;
    }

    return (
      <Expander title="KYC1 Screening" memoryKey={cn('kyc1-action')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> has been flagged during screening.
          There are {ci.screening_found_matches} found matches of potentially problematic people
          with the same name and year of birth.
        </p>
        <p>
          Examine{' '}
          <a href={customerScreeningProviderUrl(ci)} target="_blank" rel="noopener noreferrer">
            the details
          </a>{' '}
          and submit your verdict.
        </p>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            icon={ICONS.good}
            color={FLAVORS.success}
            onClick={this.grantKYCForSelectedUsers}
          >
            Grant KYC1
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={() => this.lockKYCForSelectedUsers(true)}
          >
            Refuse and lock
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionKYC2ImageSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerHasRequestedKYC2ImageVerification(ci)) {
      return null;
    }

    return (
      <Expander title="KYC2 Document" memoryKey={cn('kyc2-action')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> has requested KYC level 2
          verification through uploaded selfie with utility bill.
        </p>
        <p>
          <span className="mr-2">Uploaded images:</span>
          <ImagePopoutLink imageId={ci.kyc2_image_id}>Image</ImagePopoutLink>
        </p>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            icon={ICONS.good}
            color={FLAVORS.success}
            onClick={this.grantKYCForSelectedUsers}
          >
            Grant KYC2
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Refuse
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionKYC2LetterSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerHasRequestedKYC2LetterVerification(ci)) {
      return null;
    }

    const sent = !!ci.kyc2_letter_sent_at;

    return (
      <Expander title="KYC2 Letter" memoryKey={cn('kyc2-action')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> has requested KYC level 2
          verification through a letter code.
        </p>
        <p>
          The letter is currently marked as{' '}
          <strong>
            {sent ? (
              <IconLabel flavor={FLAVORS.success}>TAKEN</IconLabel>
            ) : (
              <IconLabel flavor={FLAVORS.danger}>NOT TAKEN</IconLabel>
            )}
          </strong>{' '}
          for processing.
        </p>
        {sent ? (
          <p>Customer is no longer able to change their request.</p>
        ) : (
          <p>
            Once you mark letter as taken for processing, customer will no longer be able to change
            their request. You should do so <em>before</em> you print out and send the letter.
          </p>
        )}
        <GridLayout cols="1fr 1fr" unresponsive>
          <IconButton
            className="mb-1"
            tag={Link}
            to={routes.kioskKYCLetter(ci.user_id)}
            target="_blank"
            icon={ICONS.print}
            color={FLAVORS.default}
          >
            Show letter
          </IconButton>
          <IconButton
            className="mb-1 ml-1"
            icon={sent ? ICONS.warn : ICONS.good}
            color={sent ? FLAVORS.warning : FLAVORS.primary}
            onClick={this.toggleKYC2LetterSentStateForSelectedUser}
          >
            {sent ? 'Mark not taken' : 'Mark as taken'}
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Refuse
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionKYC2InPersonSingle = (/** XcCustomerInfo */ customer) => {
    if (!customerHasScheduledKYC2InPersonVerification(customer)) {
      return null;
    }

    return (
      <Expander title="KYC2 In-person" memoryKey={cn('kyc2-action')}>
        <p>
          Customer <UserLabel id={customer.user_id} email={customer.email} /> has requested
          in-person KYC level 2 verification. Once they show up (or not), admin needs to either
          grant or refuse their request.
        </p>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            icon={ICONS.good}
            color={FLAVORS.success}
            onClick={this.grantKYCForSelectedUsers}
          >
            Grant KYC2
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Refuse
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionKYC3AuditSingle = (/** XcCustomerInfo */ ci) => {
    if (
      !this.isVQF ||
      !customerWaitingForMicropaymentAudit(ci) ||
      this.customerIsBeingUpdated(ci)
    ) {
      return null;
    }

    return (
      <Expander title="KYC3 micropayment audit" memoryKey={cn('kyc3-action')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> has sent us a KYC3 micropayment
          which is waiting on audit.
        </p>
        <IconButton
          icon={ICONS.expanded_view}
          color={FLAVORS.primary}
          onClick={() => this.performMicropaymentAuditForCustomer(ci)}
          block
        >
          Perform audit
        </IconButton>
      </Expander>
    );
  };

  renderActionKYC3AuditResultSingle = (/** XcCustomerInfo */ ci) => {
    if (
      !this.isVQF ||
      !customerWaitingForFailedMicropaymentAuditResponse(ci) ||
      this.customerIsBeingUpdated(ci)
    ) {
      return null;
    }

    return (
      <Expander title="KYC3 audit flagged" memoryKey={cn('kyc3-action')}>
        <p>
          Auditor has flagged KYC3 micropayment <strong>{ci.micropayment_reference_number}</strong>{' '}
          made by <UserLabel id={ci.user_id} email={ci.email} />.
        </p>
        <p>
          <span>Auditor's notes:</span>
          <br />
          <ConstrainedMultilineText className="bg-light text-primary font-italic p-2">
            {ci.audit_result_notes}
          </ConstrainedMultilineText>
        </p>
        <p>
          You can edit the{' '}
          <Link to={routes.vqfCustomersMicropaymentEdit(ci.kyc3_micropayment_id)}>
            micropayment
          </Link>{' '}
          or <Link to={routes.customersEdit(ci.user_id)}>customer record</Link> to satisfy the
          auditor's objections and send the case back for another audit. Or you can reject the KYC3
          micropayment outright, with a note to the customer.
        </p>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            icon={ICONS.redo}
            color={FLAVORS.primary}
            onClick={this.launchMicropaymentAuditForSelectedUser}
          >
            Launch another audit
          </IconButton>
          <IconButton
            icon={ICONS.bad}
            color={FLAVORS.danger}
            onClick={this.refuseKYCForSelectedUsers}
          >
            Reject
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderActionLockSingle = (/** XcCustomerInfo */ ci) => {
    if (customerIsKYCLocked(ci)) {
      return null;
    }

    return (
      <Expander title="Lock KYC" memoryKey={cn('kyc-lock')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> is currently{' '}
          {formatKYCLockVerb(false)} to submit KYC requests.
        </p>
        <div>
          <IconButton
            icon={ICONS.lock}
            color={FLAVORS.danger}
            onClick={() => this.lockKYCForSelectedUsers(false)}
          >
            Lock KYC
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderActionUnlockSingle = (/** XcCustomerInfo */ ci) => {
    if (!customerIsKYCLocked(ci)) {
      return null;
    }

    return (
      <Expander title="Unlock KYC" memoryKey={cn('kyc-unlock')}>
        <p>
          Customer <UserLabel id={ci.user_id} email={ci.email} /> is currently{' '}
          {formatKYCLockVerb(true)} from submitting KYC requests.
        </p>
        <div>
          <IconButton
            icon={ICONS.unlock}
            color={FLAVORS.success}
            onClick={() => this.unlockKYCForSelectedUsers()}
          >
            Unlock KYC
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderActionLockMany = (/** XcCustomerInfo[] */ cis) => {
    if (!cis.length || cis.some(ci => customerIsKYCLocked(ci))) {
      return null;
    }

    return (
      <Expander title="KYC lock" memoryKey={cn('kyc-lock')}>
        <p>
          {cis.length} selected customers are currently {formatKYCLockVerb(false)} to submit KYC
          requests.
        </p>
        <IconButton
          icon={ICONS.bad}
          color={FLAVORS.danger}
          onClick={() => this.lockKYCForSelectedUsers(false)}
        >
          Lock all
        </IconButton>
      </Expander>
    );
  };

  renderActionUnlockMany = (/** XcCustomerInfo[] */ cis) => {
    if (!cis.length || cis.some(ci => !customerIsKYCLocked(ci))) {
      return null;
    }

    return (
      <Expander title="KYC unlock" memoryKey={cn('kyc-unlock')}>
        <p>
          {cis.length} selected customers are currently {formatKYCLockVerb(true)} from submitting
          KYC requests.
        </p>
        <IconButton
          icon={ICONS.bad}
          color={FLAVORS.success}
          onClick={this.unlockKYCForSelectedUsers}
        >
          Unlock all
        </IconButton>
      </Expander>
    );
  };

  renderActionRefuseMany = (/** XcCustomerInfo[] */ cis) => {
    if (!cis.length || cis.some(ci => this.customerIsBeingUpdated(ci))) {
      return null;
    }

    const classified = customersClassifyKYCRequestStates(cis);
    if (classified.unclassified || classified.kyc3_waiting_for_micropayment_audit) {
      // Can't work with these
      return null;
    }

    const classifiedElements = Object.keys(classified).reduce((result, key) => {
      result[key] = (
        <BadgeList
          key={key}
          items={classified[key]}
          labelLookup={/** XcCustomerInfo */ ci => ci.user_id}
        />
      );
      return result;
    }, {});

    return (
      <Expander title="KYC mass refuse" memoryKey={cn('kyc-mass-refuse')}>
        <p>
          {cis.length} selected customers have active KYC requests. These requests can be classified
          as:
        </p>
        <ObjectInfo object={classifiedElements} />
        <p>This operation will mass refuse all {cis.length} KYC requests with the same message.</p>
        <IconButton
          icon={ICONS.bad}
          color={FLAVORS.danger}
          onClick={this.refuseKYCForSelectedUsers}
        >
          Refuse all
        </IconButton>
      </Expander>
    );
  };

  renderPhoneVerify = (/** XcCustomerInfo[] */ cis) => {
    if (
      this.isVQF ||
      !cis.length ||
      !this.container.auth.hasPermission(ADMIN_PERMISSIONS.security)
    ) {
      return null;
    }

    let eligibleVerify = 0;
    let eligibleUnverify = 0;
    const withoutPhone = [];
    for (const ci of cis) {
      if (ci.phone) {
        if (ci.phone_verified) {
          eligibleUnverify++;
        } else {
          eligibleVerify++;
        }
      } else {
        withoutPhone.push(ci);
      }
    }

    return (
      <Expander title="Phone verification" memoryKey={cn('phone-verification')}>
        {withoutPhone.length ? (
          <p>
            {withoutPhone.length > 1 ? (
              <>
                {`Customers ` + withoutPhone.map(ci => ci.user_id).join(', ')}{' '}
                <IconLabel flavor={FLAVORS.danger} className="mx-1 font-weight-bold">
                  DO NOT
                </IconLabel>
              </>
            ) : (
              <>
                {`Customer ${withoutPhone[0].user_id}`}{' '}
                <IconLabel flavor={FLAVORS.danger} className="mx-1 font-weight-bold">
                  DOES NOT
                </IconLabel>
              </>
            )}
            have a phone on record.
          </p>
        ) : (
          <div className="d-flex">
            <IconButton
              title="Mark phone number as having been verified"
              color={FLAVORS.danger}
              onClick={() => this.setPhoneVerifiedForSelectedUsers(true)}
              icon={ICONS.unlock}
              className="mr-1 flex-fill"
              disabled={eligibleVerify === 0}
            >
              Verify
            </IconButton>
            <IconButton
              title="Mark phone number as having NOT been verified, forcing customer to re-verify"
              color={FLAVORS.primary}
              onClick={() => this.setPhoneVerifiedForSelectedUsers(false)}
              icon={ICONS.lock}
              className="flex-fill"
              disabled={eligibleUnverify === 0}
            >
              Unverify
            </IconButton>
          </div>
        )}
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcCustomerInfo} */
    const ci = this.state.data.items[selectedIndex];

    const editButton = (
      <IconButton
        tag={Link}
        color={FLAVORS.primary}
        to={this.isVQF ? routes.vqfCustomersEdit(ci.user_id) : routes.customersEdit(ci.user_id)}
        icon={ICONS.edit}
        block
      >
        Edit record
      </IconButton>
    );

    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          {this.isVQF ? (
            <>
              <ExpanderGroup title="Customer">{editButton}</ExpanderGroup>
              <ExpanderGroup title="Micropayment">
                <GridLayout autoCols gap={1}>
                  <IconButton
                    tag={Link}
                    color={FLAVORS.success}
                    to={routes.VQF_CUSTOMERS_MICROPAYMENTS_NEW}
                    icon={ICONS.new}
                    onClick={e => this.handleAddMicropaymentClick(e, ci)}
                  >
                    Add
                  </IconButton>
                  <IconButton
                    tag={Link}
                    color={FLAVORS.primary}
                    to={routes.vqfCustomersMicropaymentEdit(ci.kyc3_micropayment_id)}
                    icon={ICONS.edit}
                    disabled={!ci.kyc3_micropayment_id}
                  >
                    Edit
                  </IconButton>
                </GridLayout>
              </ExpanderGroup>
            </>
          ) : (
            <>{editButton}</>
          )}
        </Expander>

        {this.renderActionKYC1VerificationVerdictSingle(ci)}
        {this.renderActionScreeningReleaseSingle(ci)}
        {this.renderActionScreeningVerdictSingle(ci)}

        {this.renderActionKYC2ImageSingle(ci)}
        {this.renderActionKYC2LetterSingle(ci)}
        {this.renderActionKYC2InPersonSingle(ci)}

        {this.renderActionKYC3AuditSingle(ci)}
        {this.renderActionKYC3AuditResultSingle(ci)}

        {this.renderPhoneVerify([ci])}

        <NestedExpandableObjectInfo
          title="Details"
          memoryKey={cn('details')}
          object={nestPrefixes(
            ci,
            ['sof', 'kyc1', 'kyc2', 'verification', 'screening', 'micropayment', 'audit'],
            'record'
          )}
          keyBlacklistLookup={
            this.isVQF
              ? null
              : {
                  sof: false,
                  micropayment: false,
                  audit: false,
                }
          }
          formatters={{
            micropayment: {
              documents: () => <MicropaymentDocumentList documents={ci.micropayment_documents} />,
            },
            sof: {
              origin: origin => SOF_ORIGIN_LABELS[origin],
              salary_range: range => SOF_SALARY_RANGE_LABELS[range],
            },
          }}
          sectionTitles={{ sof: 'Source of funds' }}
        />

        {this.renderActionLockSingle(ci)}
        {this.renderActionUnlockSingle(ci)}

        {this.renderActionResetKYCLevelSingle(ci)}
      </div>
    );
  };

  renderSidebarMany = selection => {
    const customers = selection.map(index => this.state.data.items[index]);
    return (
      <div>
        {this.renderActionLockMany(customers)}
        {this.renderActionUnlockMany(customers)}
        {this.renderActionRefuseMany(customers)}
        {this.renderPhoneVerify(customers)}
      </div>
    );
  };

  render() {
    const [columns, groups] = this.getDataTableColumnsAndGroups(
      this.kycTrack,
      this.state.criteria.view
    );

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaToggler
                    label="Show:"
                    options={[
                      new Toggler.Option(VIEWS.records, 'Records'),
                      new Toggler.Option(VIEWS.kyc1, 'KYC 1'),
                      new Toggler.Option(VIEWS.kyc2, 'KYC 2'),
                      this.isVQF && new Toggler.Option(VIEWS.kyc3, 'KYC 3'),
                    ].filter(Boolean)}
                    criteria={this.state.criteria}
                    field="view"
                  />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaNationalityPicker criteria={this.state.criteria} />
                  <CriteriaKYCAdminActionPicker criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getCustomersInfosCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={columns}
                groups={groups}
                renderArg={this.state.updatingIds}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <ResetKYCLevelModal
          data={this.state.resettingKYC}
          onConfirmed={this.confirmResetKYC}
          kycTrack={this.kycTrack}
        />

        <BoundConfirmationModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmRefuseKYC}
          title="Refuse KYC request"
          bottomText={null}
          typeSingular="customer"
          actionText="refuse KYC request for"
          buttonActionText="Submit refusal"
          actionKey="refusingKYC"
        >
          <div>
            <BoundFormInput
              state={this.boundState}
              name="refusingKYCReason"
              label="The following message will be shown as the reason for refusal"
              type="textarea"
              helpText="If you leave this empty, we will use the default message"
            />
          </div>
        </BoundConfirmationModal>

        <BoundConfirmationModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmLockKYC}
          title={this.state.lockingKYCActive ? 'Refuse and lock KYC' : 'Lock KYC'}
          bottomText={null}
          typeSingular="customer"
          actionText={
            this.state.lockingKYCActive
              ? 'refuse current and lock future KYC requests for'
              : 'lock KYC requests for'
          }
          actionFlavor={FLAVORS.danger}
          buttonActionText={this.state.lockingKYCActive ? 'Refuse and lock' : 'Lock'}
          actionKey="lockingKYC"
        >
          <div>
            <p>
              The affected{' '}
              {this.state.lockingKYC && this.state.lockingKYC.length > 1 ? 'customers' : 'customer'}{' '}
              will not be able to create new KYC requests until further notice. Their current KYC
              level or the ability to use the exchange will not be affected.
            </p>
            <BoundFormInput
              state={this.boundState}
              name="lockingKYCReason"
              label="The following message will be shown as the reason for locking."
              type="textarea"
              helpText={`If you leave this empty, they will just be shown "you are locked from making KYC requests", without further details`}
            />
          </div>
        </BoundConfirmationModal>

        <BoundConfirmationModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmUnlockKYC}
          title="Unlock KYC"
          bottomText={null}
          typeSingular="customer"
          actionText={'unlock KYC requests for'}
          actionFlavor={FLAVORS.success}
          buttonActionText={'Unlock'}
          actionKey="unlockingKYC"
        >
          <div>
            The affected{' '}
            {this.state.lockingKYC && this.state.lockingKYC.length > 1 ? 'customers' : 'customer'}{' '}
            will again be able to submit KYC requests.
          </div>
        </BoundConfirmationModal>

        <BoundConfirmationModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.executeOverrideDocumentVerification}
          title="Skip KYC1 document verification"
          bottomText={null}
          typeSingular="customer"
          actionText="skip KYC1 document verification for"
          buttonActionText="Skip verification"
          actionFlavor={FLAVORS.warning}
          actionKey="overridingDocumentVerification"
        >
          <p>
            If you skip verification, the uploaded document will be marked as valid and customer
            will be sent directly to screening.{' '}
          </p>
          <p>
            <strong className="font-italic">
              You should only do for customers you are certain are in good standing with the law.
            </strong>
          </p>
        </BoundConfirmationModal>

        {this.isVQF && (
          <BoundMicropaymentAuditModal
            state={this.boundState}
            callback={this.handleMicropaymentAuditCallback}
          />
        )}

        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmSetPhoneVerified}
          typeSingular="customer"
          actionText={`mark phone number as ${
            this.state.settingPhoneVerifiedValue ? 'verified' : 'unverified'
          } for`}
          buttonActionText={`Mark ${
            this.state.settingPhoneVerifiedValue ? 'verified' : 'unverified'
          }`}
          actionKey="settingPhoneVerifiedSelection"
        />
      </PageLayout>
    );
  }
}

export default withRouter(CustomersPage);
