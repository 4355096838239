import './Toggler.css';

import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup } from 'reactstrap';

import { classNamer, classes } from '../../../lib/tools';
import IconButton from './IconButton';

class TogglerOption {
  constructor(name, title) {
    this.name = name;
    this.title = title;
  }
}

const cn = classNamer('Toggler');

const Toggler = ({ options, selected, onChange, className }) => {
  const buttons = options.map(
    /** TogglerOption */ option => {
      const active = selected === option.name;
      return (
        <IconButton
          key={option.name}
          outline
          color={'primary'}
          className={classes(cn('btn'), active && 'active')}
          onClick={() => onChange(option.name)}
        >
          {option.title}
        </IconButton>
      );
    }
  );
  return <ButtonGroup className={classes(cn(), className)}>{buttons}</ButtonGroup>;
};

Toggler.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Toggler.Option = TogglerOption;

export default Toggler;
