import { lodash } from './tools';

class AssertionError extends Error {
  constructor(message) {
    super(message ? 'Assertion failed: ' + message : 'Assertion failed (no specifics)');
  }
}

function assert(value, message) {
  if (!value) {
    throw new AssertionError(message);
  }
}

function findInvalidSubsetKey(subsetOb, supersetOb) {
  assert(lodash.isObject(subsetOb), 'subset must be given');
  assert(lodash.isObject(supersetOb), 'superset must be object');

  for (const key in subsetOb) {
    if (subsetOb.hasOwnProperty(key) && !(key in supersetOb)) {
      return key;
    }
  }
}

export function assertKeysMatch(a, b, aName = 'a', bName = 'b') {
  if (arguments.length === 1) {
    const ob = a;
    const keys = Object.keys(a);
    aName = keys[0];
    bName = keys[1];
    a = ob[aName];
    b = ob[bName];
  }

  let key = findInvalidSubsetKey(a, b);
  if (key) {
    throw new AssertionError(
      `Keys mismatch. ${aName} defines key "${key}" that ${bName} doesn't define`
    );
  }

  key = findInvalidSubsetKey(b, a);
  if (key) {
    throw new AssertionError(
      `Keys mismatch. ${bName} defines key "${key}" that ${aName} doesn't define`
    );
  }
}
