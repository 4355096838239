import PropTypes from 'prop-types';
import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import BoundConfirmationModal from './BoundConfirmationModal';

const BoundDangerModal = props => {
  return <BoundConfirmationModal {...props} />;
};

// The same as BoundConfirmationModal
BoundDangerModal.propTypes = {
  state: PropTypes.instanceOf(BoundState).isRequired,
  formatItem: PropTypes.func.isRequired,
  title: PropTypes.string,
  actionKey: PropTypes.string,
  dataKey: PropTypes.string,
  typeSingular: PropTypes.string,
  typePlural: PropTypes.string,
  actionFlavor: PropTypes.string,
  actionText: PropTypes.string,
  buttonActionText: PropTypes.string,
  bottomText: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
};

BoundDangerModal.defaultProps = {
  actionFlavor: FLAVORS.danger,
  actionText: 'delete',
  buttonActionText: 'Delete',
  actionKey: 'deleting',
};

export default BoundDangerModal;
