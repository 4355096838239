import './HomePage.css';

import React from 'react';
import { Link } from 'react-router-dom';

import { AppIcon } from '../../lib/icons';
import { SECTIONS, SECTION_LIST } from '../../lib/navigation';
import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import GridLayout from '../layout/PageLayout';

const cn = classNamer('HomePage');

class HomePage extends ConnectedComponent {
  /**
   * @param {SiteSubSection} subsection
   */
  renderSubSectionLink(subsection) {
    if (subsection.permissions && !this.container.auth.hasPermission(subsection.permissions)) {
      return;
    }

    return (
      <Link
        className={classes(cn('link'), 'border border-secondary rounded bg-light text-primary p-3')}
        key={subsection.route}
        to={subsection.route}
      >
        <h5>{subsection.title}</h5>
        <h6>{subsection.description}</h6>
      </Link>
    );
  }

  renderSection(/** SiteSection */ section) {
    if (section.name === SECTIONS.home.name) {
      // Do not display self-section
      return null;
    }

    if (section.permissions && !this.container.auth.hasPermission(section.permissions)) {
      return null;
    }

    return (
      <div className="my-5" key={section.name}>
        <h4 className="ml-3 mb-3">
          <Link to={section.route}>
            <AppIcon icon={section.icon} /> {section.title}
          </Link>
        </h4>
        <div className={cn('section')}>
          {section.subsections.map(subsection => this.renderSubSectionLink(subsection))}
        </div>
      </div>
    );
  }

  render() {
    const sections = SECTION_LIST.map(s => this.renderSection(s)).filter(Boolean);

    return (
      <div className={classes(cn(), 'container')}>
        <GridLayout above="Xcalibra Admin">
          <div>
            <h4>Hi, {this.container.store.userDisplayName}</h4>
            <p>Below are the admin areas available to your account</p>

            <div className="pb-5 mt-5">{sections}</div>
          </div>
        </GridLayout>
      </div>
    );
  }
}

export default HomePage;
