export const routes = {
  HOME: '/',
  LOGIN: '/login',

  USERS: '/users',

  USERS_ACCOUNTS: '/users/accounts',
  USERS_NEW: '/users/accounts/new',
  USERS_EDIT: '/users/accounts/:id/edit',
  usersEdit: id => `/users/accounts/${id}/edit`,

  USERS_SESSIONS: '/users/sessions',

  USERS_MARKET_CONFIGS: '/users/market-configs',
  USERS_INSTRUMENT_CONFIGS: '/users/instrument-configs',

  CUSTOMERS: '/users/customers',
  CUSTOMERS_EDIT: '/users/customers/:id/edit',
  customersEdit: id => `/users/customers/${id}/edit`,

  VQF_CUSTOMERS: '/users/vqf-customers',
  VQF_CUSTOMERS_EDIT: '/users/vqf-customers/:id/edit',
  vqfCustomersEdit: id => `/users/vqf-customers/${id}/edit`,
  VQF_CUSTOMERS_MICROPAYMENTS_NEW: '/users/vqf-customers/micropayments/new',
  VQF_CUSTOMERS_MICROPAYMENTS_EDIT: '/users/vqf-customers/micropayments/:id/edit',
  vqfCustomersMicropaymentEdit: id => `/users/vqf-customers/micropayments/${id}/edit`,

  MICROPAYMENTS: '/users/micropayments',
  MICROPAYMENTS_NEW: '/users/micropayments/new',
  MICROPAYMENTS_EDIT: '/users/micropayments/:id/edit',
  micropaymentsEdit: id => `/users/micropayments/${id}/edit`,

  USERS_BOTS: '/users/bots',
  USERS_BOTS_NEW: '/users/bots/new',
  USERS_BOTS_EDIT: '/users/bots/:apiKey/edit',
  usersBotsEdit: apiKey => `/users/bots/${apiKey}/edit`,

  USERS_SECURITY_EVENTS: '/users/security-events',
  USERS_PHONE_VERIFICATIONS: '/users/phone-verifications',

  TROLLBOX: '/trollbox',
  TROLLBOX_USERS: '/trollbox/users',
  TROLLBOX_MESSAGES: '/trollbox/messages',
  TROLLBOX_PROFANITY: '/trollbox/profanity',

  TRADING: '/trading',
  TRADING_TRADES: '/trading/trades',
  TRADING_ORDERS: '/trading/orders',
  TRADING_GROWTH_TIMELINE: '/trading/growth-timeline',

  TRANSACTIONS: '/transactions',
  TRANSACTIONS_DEPOSITS: '/transactions/deposits',
  TRANSACTIONS_WITHDRAWALS: '/transactions/withdrawals',
  TRANSACTIONS_CARD_PAYMENTS: '/transactions/card-payments',
  TRANSACTIONS_TRANSFERS: '/transactions/transfers',
  TRANSACTIONS_TRANSFER_TIMELINE: '/transactions/transfer-timeline',

  MANAGED: '/managed',
  MANAGED_USERS: '/managed/users',
  MANAGED_WALLET: '/managed/wallet',
  MANAGED_BUY_ORDERS: '/managed/buy-orders',
  MANAGED_BUY_ORDERS_EXPANDED_ORDER: '/managed/buy-orders/:id',
  managedBuyOrdersExpandedOrder: id => `/managed/buy-orders/${id}`,
  MANAGED_SELL_ORDERS: '/managed/sell-orders',
  MANAGED_SELL_ORDERS_EXPANDED_ORDER: '/managed/sell-orders/:id',
  managedSellOrdersExpandedOrder: id => `/managed/sell-orders/${id}`,
  MANAGED_SELL_ORDERS_WITHDRAW: '/managed/sell-orders/:orderId/withdraw',
  managedSellOrdersWithdraw: orderId => `/managed/sell-orders/${orderId}/withdraw`,
  MANAGED_PARTNER_TRANSFERS: '/managed/partner/transfers',
  MANAGED_PARTNER_TRANSFERS_NEW: '/managed/partner/transfers/new/:orderId?',
  managedPartnerTransfersNew: orderId =>
    orderId ? `/managed/partner/transfers/new/${orderId}` : `/managed/partner/transfers/new`,
  MANAGED_PARTNER_TRANSFERS_EDIT: '/managed/partner/transfers/:id/edit',
  managedPartnerTransfersEdit: id => `/managed/partner/transfers/${id}/edit`,
  MANAGED_PARTNER_TRADES: '/managed/partner/trades',
  MANAGED_PARTNER_TRADES_NEW: '/managed/partner/trades/new/:orderId?',
  managedPartnerTradesNew: orderId =>
    orderId ? `/managed/partner/trades/new/${orderId}` : `/managed/partner/trades/new`,
  MANAGED_PARTNER_TRADES_EDIT: '/managed/partner/trades/:id/edit',
  managedPartnerTradesEdit: id => `/managed/partner/trades/${id}/edit`,
  MANAGED_BANK_TRANSFERS: '/managed/bank/transfers',
  MANAGED_BANK_TRANSFERS_NEW: '/managed/bank/transfers/new/:orderId?',
  MANAGED_BANK_TRANSFERS_EDIT: '/managed/bank/transfers/:id/edit',
  managedBankTransfersEdit: id => `/managed/bank/transfers/${id}/edit`,
  managedBankTransfersNew: orderId =>
    orderId ? `/managed/bank/transfers/new/${orderId}` : `/managed/bank/transfers/new`,

  SETTINGS: '/settings',
  SETTINGS_MARKET: '/settings/market',
  SETTINGS_INSTRUMENT: '/settings/instrument',
  SETTINGS_REGISTRATION: '/settings/registration',
  SETTINGS_MANAGED: '/settings/managed',
  SETTINGS_PRICE_ESTIMATOR: '/settings/price-estimator',

  INTERNALS: '/internals',
  INTERNALS_APP: '/internals/app',
  INTERNALS_EXCHANGE: '/internals/exchange',
  INTERNALS_MARKETS: '/internals/markets',
  INTERNALS_MAILER: '/internals/mailer',
  INTERNALS_MAILCHIMP: '/internals/mailchimp',
  INTERNALS_LOGGER: '/internals/logger',
  INTERNALS_WALLETS: '/internals/wallets',
  INTERNALS_ACTIVITY: '/internals/activity',
  INTERNALS_DOCUMENTS: '/internals/documents',
  INTERNALS_MAINTENANCE: '/internals/maintenance',

  DEV: '/dev',
  DEV_ICONS: '/dev/icons',

  KIOSK_KYC_LETTER: '/kiosk/kyc-letter/:id',
  kioskKYCLetter: id => `/kiosk/kyc-letter/${id}`,
};
