import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import SettingsInstrumentConfigsPage from './SettingsInstrumentConfigsPage';
import SettingsManagedConfigsPage from './SettingsManagedConfigsPage';
import SettingsMarketConfigsPage from './SettingsMarketConfigsPage';
import SettingsPriceEstimatorConfigsPage from './SettingsPriceEstimatorConfigsPage';
import SettingsRegistrationPage from './SettingsRegistrationPage';

class SettingsRouter extends ConnectedComponent {
  render() {
    return (
      <div className="SettingsRouter">
        <Breadcrumbed link={SECTIONS.settings}>
          <SubPager links={SECTIONS.settings.subsections} />
          <Switch>
            <Route
              path={routes.SETTINGS}
              render={() => <StickyRedirect routes={SECTIONS.settings.subsections} />}
              exact
            />
            <Route path={routes.SETTINGS_MARKET} component={SettingsMarketConfigsPage} />
            <Route path={routes.SETTINGS_INSTRUMENT} component={SettingsInstrumentConfigsPage} />
            <Route path={routes.SETTINGS_REGISTRATION} component={SettingsRegistrationPage} />
            <Route path={routes.SETTINGS_MANAGED} component={SettingsManagedConfigsPage} />
            <Route
              path={routes.SETTINGS_PRICE_ESTIMATOR}
              component={SettingsPriceEstimatorConfigsPage}
            />
            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default SettingsRouter;
