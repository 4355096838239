import PropTypes from 'prop-types';
import React from 'react';

import { ADMIN_PERMISSIONS, KYC_ADMIN_ACTIONS } from '../../../lib/backend';
import { FLAVORS, KYC_ADMIN_ACTION_NAMES } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import IconLabel from '../../widgets/presentational/IconLabel';

export default class KYCAdminActionLabel extends ConnectedComponent {
  static propTypes = {
    action: PropTypes.string,
    alert: PropTypes.bool,
  };

  render() {
    if (!this.props.action) {
      return null;
    }

    const canAct =
      // Officers can act on audits
      (this.container.auth.hasPermission(ADMIN_PERMISSIONS.kyc_officer) &&
        this.props.action === KYC_ADMIN_ACTIONS.kyc3_audit) ||
      // Associates can act on everything else
      this.container.auth.hasPermission(ADMIN_PERMISSIONS.kyc_associate);

    let icon, flavor;
    if (!KYC_ADMIN_ACTIONS[this.props.action]) {
      // Special handling for filters
      icon = ICONS.cogs;
      flavor = FLAVORS.info;
    } else if (canAct) {
      if (this.props.alert) {
        icon = ICONS.warn;
        flavor = FLAVORS.warning;
      } else {
        icon = ICONS.cog;
        flavor = FLAVORS.primary;
      }
    } else {
      icon = ICONS.cog;
      flavor = FLAVORS.muted;
    }

    return (
      <IconLabel icon={icon} flavor={flavor}>
        <strong>
          {KYC_ADMIN_ACTION_NAMES[this.props.action]} {this.props.children}
        </strong>
      </IconLabel>
    );
  }
}
