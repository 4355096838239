import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import BoundCheckbox from '../../widgets/bound/BoundCheckbox';
import BoundFormInput from '../../widgets/bound/BoundFormInput';

const BoundPriceEstimatorInstrumentConfig = props => {
  const isPartnerProvided =
    props.state.data[`settings_per_instrument_${props.instrument}_partner_provided`];
  const isPartnerCheckboxDisabled = !(
    props.state.data[`settings_per_instrument_${props.instrument}_partner_market_base`] &&
    props.state.data[`settings_per_instrument_${props.instrument}_local_market_base`]
  );
  const priceInstrument = isPartnerProvided
    ? props.state.data[`settings_per_instrument_${props.instrument}_partner_market_base`]
    : props.state.data[`settings_per_instrument_${props.instrument}_local_market_base`];
  return (
    <FormGroup className="m-2">
      <Label className="font-weight-bold">{props.instrument}</Label>
      <BoundFormInput
        nest
        className="w-75"
        state={props.state}
        label="Market depth limit"
        name={props.keys[`settings_per_instrument_${props.instrument}_market_depth_limit`]}
      />
      <BoundFormInput
        nest
        className="w-75"
        state={props.state}
        appendAddon={priceInstrument}
        label="Max price"
        name={props.keys[`settings_per_instrument_${props.instrument}_max_price`]}
      />
      <BoundCheckbox
        nest
        state={props.state}
        disabled={isPartnerCheckboxDisabled}
        label="Partner provided"
        name={props.keys[`settings_per_instrument_${props.instrument}_partner_provided`]}
      />
    </FormGroup>
  );
};

BoundPriceEstimatorInstrumentConfig.propTypes = {
  keys: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  instrument: PropTypes.string.isRequired,
};

export default BoundPriceEstimatorInstrumentConfig;
