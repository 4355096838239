import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

import { classes } from '../../../lib/tools';
import { BoundState } from '../../infrastructure/ConnectedComponent';

class BoundModalCapturedProps {
  constructor(sentinel, data) {
    this.sentinel = sentinel;
    this.data = data;
  }
}

class BoundModalRenderProps extends BoundModalCapturedProps {
  constructor(sentinel, data, isOpen, callback) {
    super(sentinel, data);
    this.isOpen = isOpen;
    this.callback = callback;
  }
}

/**
 * Relatively low-level Modal primitive that handles triggering modal based on bound state
 */
export default class BoundModal extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    /**
     * Bound state that will trigger the opening
     */
    state: PropTypes.instanceOf(BoundState).isRequired,

    /**
     * Sentinel is value which will trigger the modal being open. It can just be something like "isOpen" (true/false).
     * Or something more elaborate (eg. list of selected items). Modal will update
     */
    sentinelKey: PropTypes.string.isRequired,

    /**
     * Function to fetch data from state to be captured while modal is open. Useful in case where we want modal to
     * display some data even after it is gone from the bound state. Data will be given to the render proc.
     * If not provided, defaults to the same value as sentinel.
     * @type {function(state: BoundState, sentinel)}
     */
    captureData: PropTypes.func,

    /**
     * Once modal is closed, this will be called with result.
     * @type {function(result: *, props: BoundModalCapturedProps)}
     */
    callback: PropTypes.func,

    /**
     * What to return in callback if modal is cancelled using ESC key or similar. Defaults to "false".
     */
    cancelResult: PropTypes.any,

    /**
     * Children should be render func which is given captured data, sentinel and callback.
     * @type {function(BoundModalRenderProps)}
     */
    children: PropTypes.func.isRequired,

    // From Modal
    size: PropTypes.string,
  };

  static defaultProps = {
    cancelResult: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      capturedData: null,
      sentinelValue: (this.props.state && this.props.state[this.props.sentinelKey]) || null,
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentSentinelValue =
      (this.props.state && this.props.state[this.props.sentinelKey]) || null;
    if (prevState.sentinelValue !== currentSentinelValue) {
      // There was a change in value. Set opened state.
      const stateUpdate = {
        sentinelValue: currentSentinelValue,
        isOpen: !!currentSentinelValue,
      };
      if (stateUpdate.isOpen) {
        // If we are opening the dialog, grab data, so it is stable while dialog is open
        stateUpdate.capturedData = this.props.captureData
          ? this.props.captureData(this.props.state, currentSentinelValue)
          : currentSentinelValue;
      }

      this.setState(stateUpdate);
    }
  }

  /**
   * Callback function that will trigger closing of modal and calling own callback prop, if provided
   */
  callback = result => {
    if (!this.state.isOpen) {
      // Ignore
      return;
    }

    // Close, but keep the data, so we don't have funky jumps
    this.setState({
      isOpen: false,
    });

    // Call parent callback
    if (this.props.callback) {
      this.props.callback(
        result,
        new BoundModalCapturedProps(this.state.sentinelValue, this.state.capturedData)
      );
    }

    // Clear selection on the triggering component state
    this.props.state &&
      this.props.state.setState({
        [this.props.sentinelKey]: null,
      });
  };

  render() {
    const { className, children, size } = this.props;
    const { isOpen, sentinelValue, capturedData } = this.state;

    const content =
      typeof children === 'function'
        ? children(new BoundModalRenderProps(sentinelValue, capturedData, isOpen, this.callback))
        : children;

    return (
      <Modal
        isOpen={this.state.isOpen}
        toggle={() => this.callback(this.props.cancelResult)}
        className={classes('BoundModal', className)}
        size={size}
      >
        {content}
      </Modal>
    );
  }
}
