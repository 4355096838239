import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

/**
 * @param {{customer: XcCustomerInfo, updating: boolean}} props
 */
const CustomerKYC1Outcome = props => {
  const ci = props.customer;

  if (props.updating) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.wait}>
        PROCESSING
      </IconLabel>
    );
  }

  if (!ci.kyc1_request_id) {
    // No active request
    return null;
  }

  if (ci.kyc1_completed_at) {
    if (!ci.kyc1_granted) {
      // Failed
      return (
        <IconLabel flavor={false} wrap>
          {ci.kyc1_failure_message || <em>(no message)</em>}
        </IconLabel>
      );
    }

    // Granted
    return <IconLabel flavor={true}>GRANTED</IconLabel>;
  }

  if (ci.kyc1_processing_started_at) {
    return (
      <IconLabel flavor={FLAVORS.warning} icon={ICONS.warn}>
        PROCESSING
      </IconLabel>
    );
  }

  return (
    <IconLabel flavor={FLAVORS.muted} icon={ICONS.wait}>
      WAITING
    </IconLabel>
  );
};

export default CustomerKYC1Outcome;
