import './GridLayout.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('GridLayout');

const makeTemplate = index => {
  index = (index === true ? 1 : index) || 0;
  const result = [];
  for (let i = 0; i < index; i++) {
    result.push('auto');
  }
  result.push('1fr');
  return result.join(' ');
};

// Gap sizes matching bootstrap sizing classes
const GRID_GAPS = {
  1: '0.25rem',
  2: '0.5rem',
  3: '1rem',
  4: '1.5rem',
  5: '3rem',
};

const GridLayout = ({
  className,
  children,
  horizontal,
  vertical,
  autoCols = false,
  autoRows = false,
  cols,
  rows,
  gap,
  unresponsive,
}) => {
  className = classes(
    className,
    cn(),
    !unresponsive && cn('responsive'),
    autoCols && cn('auto-cols'),
    autoRows && cn('auto-rows')
  );

  if (!cols && horizontal) {
    cols = makeTemplate(horizontal);
  }
  if (!rows && vertical) {
    rows = makeTemplate(vertical);
  }

  const style = {
    gridTemplateRows: rows,
    gridTemplateColumns: cols,
    gridGap: GRID_GAPS[gap] || gap,
  };

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

GridLayout.propTypes = {
  className: PropTypes.string,
  gap: PropTypes.any,
  cols: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  rows: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  unresponsive: PropTypes.bool,
};

export default GridLayout;
