import React from 'react';

const RECAPTCHA_SCRIPT_URL = 'https://www.recaptcha.net/recaptcha/api.js';
const RECAPTCHA_SCRIPT_REGEX = /(http|https):\/\/(www)?.+\/recaptcha/;

class CaptchaNotReadyError extends Error {
  constructor() {
    super('Recaptcha is not ready for rendering yet.');
  }
}

class CaptchaNotRenderedError extends Error {
  constructor() {
    super('This Recaptcha instance has not rendered yet.');
  }
}

class Recaptcha extends React.PureComponent {
  container = React.createRef();

  static defaultProps = {
    id: '',
    theme: 'light',
    languageCode: '',
    type: 'invisible',
    inject: true,
    badgePosition: 'bottomright',
  };

  constructor(props) {
    super(props);

    this.state = {
      instanceId: null,
      ready: false,
      rendered: false,
      timer: null,
    };
  }

  get isInvisible() {
    return this.props.type === 'invisible';
  }

  isAvailable = () => Boolean(window && window.grecaptcha && window.grecaptcha.ready);

  inject = () => {
    const isScriptPresent = Array.from(document.scripts).reduce(
      (isPresent, script) => (isPresent ? isPresent : RECAPTCHA_SCRIPT_REGEX.test(script.src)),
      false
    );

    if (this.props.inject && !isScriptPresent) {
      const hlParam = this.props.languageCode ? `&hl=${this.props.languageCode}` : '';
      const src = `${RECAPTCHA_SCRIPT_URL}?render=explicit${hlParam}`;

      const script = document.createElement('script');

      script.async = true;
      script.defer = true;
      script.src = src;

      if (document.head) {
        document.head.appendChild(script);
      }
    }
  };

  prepare = () => {
    window.grecaptcha.ready(() => {
      this.setState({ ready: true }, () => {
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      });
    });
  };

  renderRecaptcha = (container, config) => {
    return window.grecaptcha.render(container, config);
  };

  resetRecaptcha = () => window.grecaptcha.reset(this.state.instanceId);

  executeRecaptcha = () => window.grecaptcha.execute(this.state.instanceId);

  stopTimer = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  };

  componentDidMount = () => {
    this.inject();

    if (this.isAvailable()) {
      return this.prepare();
    }

    const timer = setInterval(() => {
      if (this.isAvailable()) {
        this.prepare();
        this.stopTimer();
      }
    }, 500);

    this.setState({ timer });
  };

  componentWillUnmount = () => {
    this.stopTimer();
  };

  renderExplicitely = () => {
    return Promise.resolve().then(() => {
      if (this.state.rendered) {
        throw new Error('This Recaptcha instance has been already rendered.');
      }

      if (this.state.ready && this.container.current) {
        const instanceId = this.renderRecaptcha(this.container.current, {
          sitekey: this.props.siteKey,
          theme: this.props.theme,
          size: this.props.type,
          badge: this.isInvisible ? this.props.badgePosition : null,
          callback: this.props.onVerify,
          hl: this.isInvisible ? null : this.props.languageCode,
        });

        return this.setState(
          {
            instanceId,
            rendered: true,
          },
          () => {
            if (this.props.onRender) {
              return this.props.onRender();
            }
          }
        );
      }

      throw new CaptchaNotReadyError();
    });
  };

  reset = () => {
    return Promise.resolve().then(() => {
      if (this.state.rendered) {
        return this.resetRecaptcha();
      }

      throw new CaptchaNotRenderedError();
    });
  };

  execute = () => {
    return Promise.resolve().then(() => {
      if (!this.isInvisible) {
        throw new Error('Manual execution is only available to invisible type.');
      }

      if (this.state.rendered) {
        return this.executeRecaptcha();
      }

      throw new CaptchaNotRenderedError();
    });
  };

  render = () => {
    const container = (
      <div
        style={{ margin: '0 0 10px 0' }}
        id={this.props.id}
        className="g-recaptcha"
        ref={this.container}
      />
    );

    return this.props.children
      ? this.props.children({
          reset: this.reset,
          execute: this.execute,
          recaptchaComponent: container,
        })
      : container;
  };
}

export default Recaptcha;
