import React from 'react';

import { ROLES } from '../../../lib/backend';
import { ROLE_NAMES } from '../../../lib/consts';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classes } from '../../../lib/tools';

const UserRole = ({ role, iconOnly, children }) => {
  const className = classes(
    'text-nowrap',
    role === ROLES.superadmin && 'text-info',
    role !== ROLES.customer && 'font-weight-bold'
  );

  const name = iconOnly ? undefined : <span className="ml-1">{ROLE_NAMES[role]}</span>;

  return (
    <span className={className}>
      <AppIcon icon={ICONS[role]} />
      {name}
      {children}
    </span>
  );
};

export default UserRole;
