import { ROLES } from './backend';
import { SECOND } from './consts';
import { toastError } from './toasts';

/**
 * Provides login, logout and session renewal logic
 */
export class AuthManager {
  constructor(container) {
    /**
     * @type {Container}
     */
    this._container = container;
    this._logger = this._container.logger.prefixed('Auth');

    this._expiresAt = null;
    this._renewTimeout = null;
  }

  initialize() {
    return this._tryLogInFromLocalStorage().then(() => {
      this._container.store.subscribe(() => {
        this._updateRenewTimeout();
      });

      this._updateRenewTimeout();

      this._logger.log(`Initialized`);
    });
  }

  _updateRenewTimeout() {
    const session =
      (this._container.store.principal && this._container.store.principal.session) || null;

    const newExpiresAt = session ? new Date(session.expires_at).valueOf() : null;

    if (newExpiresAt === this._expiresAt) {
      return;
    }

    this._expiresAt = newExpiresAt;
    clearTimeout(this._renewTimeout);

    if (this._expiresAt) {
      const sessionDuration = Math.max(this._expiresAt - new Date().valueOf(), 10 * SECOND);
      const sleepDuration = Math.max(sessionDuration * 0.95, 1 * SECOND);
      this._renewTimeout = setTimeout(this._onRenewTimeout, sleepDuration);

      this._logger.log(`Token set to be renewed in ${sleepDuration / SECOND} seconds`);
    }
  }

  _onRenewTimeout = () => {
    this._logger.log('Renewing session...');
    this._expiresAt = undefined;

    this._container.client.postAuthRenew().then(
      session => {
        this._container.localStorage.setToken(session.access_token);
        this._container.http.token = session.access_token;
        this._container.store.update({
          principal: {
            session,
          },
        });
      },
      err => {
        this._logger.error(`Renew session failed: ${err.message}`);
      }
    );
  };

  logIn(email, password, tfa_token, captcha_token) {
    return this._container.client
      .postAuthLogin({ email, password, tfa_token, captcha_token })
      .then(principal => {
        this._container.localStorage.setToken(principal.session.access_token);
        this._container.http.token = principal.session.access_token;
        this._container.store.update({
          principal,
        });
      });
  }

  shouldAskCaptchaForLogin() {
    return this._container.client.getAuthLoginShouldAskCaptcha();
  }

  /**
   * Delete session from local storage and go back to Login page.
   * Also tries to notify backend that we have logged out, in the background
   */
  logOut(customLogoutMethodSpec = null) {
    const oldAccessToken = this.accessToken;

    this._container.http.token = null;
    this._container.localStorage.clearToken();
    this._container.store.update({
      principal: null,
    });

    if (oldAccessToken) {
      // Tell server that we have logged out and it can invalidate the token
      // We will use custom access token request style
      const logoutMethodSpec = customLogoutMethodSpec || this._container.client.putAuthLogoutSpec();
      this._container.http.customAuthRequest(oldAccessToken, logoutMethodSpec).catch(err => {
        // We don't care if we have already logged out
        if (err.name === 'SessionTerminatedError') {
          return null;
        }
        // But let's toast any other error
        toastError(err);
      });
    }
  }

  _tryLogInFromLocalStorage() {
    const savedToken = this._container.localStorage.getToken();
    if (!savedToken) {
      return Promise.resolve(this);
    }

    return this._container.http
      .customAuthRequest(savedToken, this._container.client.getAuthPrincipalSpec())
      .then(
        principal => {
          this._container.store.update({ principal });
          this._container.localStorage.setToken(principal.session.access_token);
          this._container.http.token = principal.session.access_token;
        },
        () => {
          this._container.localStorage.clearToken();
        }
      );
  }

  get accessToken() {
    return (
      (this._container.store.principal && this._container.store.principal.session.access_token) ||
      null
    );
  }

  get user() {
    return (this._container.store.principal && this._container.store.principal.user) || null;
  }

  get userId() {
    return (this.user && this.user.id) || null;
  }

  get role() {
    return (this.user && this.user.role) || null;
  }

  get userPermissions() {
    return (this.user && this.user.admin_permissions) || null;
  }

  get isSuperAdmin() {
    return this.role === ROLES.superadmin;
  }

  /**
   * Return true if current user has at least one of given permissions (or exactly the permission given)
   */
  hasPermission(permissions) {
    const user = this._container.store.principal && this._container.store.principal.user;
    if (!user) {
      return false;
    }

    if (user.role === ROLES.superadmin) {
      return true;
    }

    if (Array.isArray(permissions)) {
      for (const permission of permissions) {
        if (user.admin_permissions.includes(permission)) {
          return true;
        }
      }
    } else {
      if (user.admin_permissions.includes(permissions)) {
        return true;
      }
    }

    return false;
  }
}
