import { SECOND } from './consts';
import { lodash } from './tools';

/**
 * How often do we update user's activity. We don't want to bother the store all the time.
 * But also, needs to be something reasonable in terms of keep idle timeout at bay, though.
 */
export const USER_ACTIVITY_UPDATE_FREQUENCY = 15 * SECOND;

// Selectors
export const getLocalSeenAt = state => state.userLastSeenTs;

/**
 * Service whose job is to track user activity in browser and update state accordingly.
 */
export class UserActivityTracker {
  /**
   * @type {UserActivityTracker.prototype._notifyUserSeen}
   */
  _notifyUserLastSeenThrottled;

  constructor(container, document, userActivityUpdateFrequency = USER_ACTIVITY_UPDATE_FREQUENCY) {
    /**
     * @type {Container}
     */
    this._container = container;

    /**
     * @type {Document}
     */
    this._document = document;

    /**
     * @type {Logger}
     */
    this._logger = this._container.logger.prefixed('UserActivityTracker');

    this._notifyUserLastSeenThrottled = lodash.throttle(
      this._notifyUserSeen,
      userActivityUpdateFrequency
    );
  }

  start() {
    this._document.addEventListener('mousedown', this._onActivityEvent);
    this._document.addEventListener('mousemove', this._onActivityEvent);
    this._document.addEventListener('keydown', this._onActivityEvent);
    this._document.addEventListener('scroll', this._onActivityEvent);
    this._document.addEventListener('touchstart', this._onActivityEvent);
    this._document.addEventListener('visibilitychange', this._onBrowserVisibilityChangeEvent);

    // Presume user is active during initialization
    this._notifyUserLastSeenThrottled(Date.now(), 'app_initialization');
  }

  _onActivityEvent = e => {
    this._notifyUserLastSeenThrottled(Date.now(), e.type);
  };

  _onBrowserVisibilityChangeEvent = e => {
    if (!this._document.hidden) {
      this._notifyUserLastSeenThrottled(Date.now(), e.type);
    }
  };

  _notifyUserSeen = (ts, basedOnEventType) => {
    this._container.store.update({ userLastSeenTs: ts });
    this._logger.verbose(
      `Set user last seen to ${Date.now() - ts} ms ago based on event "${basedOnEventType}"`
    );
  };
}
