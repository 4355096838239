import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';

import ConnectedComponent from './ConnectedComponent';

export default class StickyRedirect extends ConnectedComponent {
  static propTypes = {
    /**
     * @type {string[]|LinkDefinition[]}
     */
    routes: PropTypes.array.isRequired,
  };

  render() {
    const routes = this.props.routes;
    const recentPaths = this.container.history.recent_pathnames;

    for (const recent of recentPaths) {
      for (const route of routes) {
        // Handle something like LinkDefinition objects
        const routeValue = route.route || route;
        if (routeValue === recent) {
          return <Redirect to={routeValue} />;
        }
      }
    }

    return <Redirect to={routes[0].route || routes[0]} />;
  }
}
