import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import IconButton from '../../widgets/interactive/IconButton';

const cn = classNamer('TrollboxPostMessageWidget');

export default class TrollboxPostMessageWidget extends ConnectedComponent {
  static propTypes = {
    state: PropTypes.object.isRequired,
    messageKey: PropTypes.string.isRequired,
    postMessage: PropTypes.func.isRequired,
  };

  render() {
    const { state, messageKey, postMessage } = this.props;

    return (
      <Expander title="Post message" memoryKey={cn('post')}>
        <Form>
          <div className={cn('form')}>
            <div className="mb-3">
              <Input
                className={cn('message-input')}
                type="textarea"
                value={state.message}
                onChange={e => state.setState({ [messageKey]: e.target.value })}
                placeholder="Message"
                title="Type your message here"
              />
            </div>
            <div className="d-flex mt-3">
              <IconButton
                title="Post message"
                onClick={postMessage}
                color={FLAVORS.primary}
                className="flex-fill ml-1"
                disabled={!state.message.length}
              >
                Post message
              </IconButton>
            </div>
          </div>
        </Form>
      </Expander>
    );
  }
}
