import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';

import { INSTRUMENT_CONFIG_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import ExpanderGroup from '../layout/ExpanderGroup';
import IconButton from '../widgets/interactive/IconButton';
import { setDisabled, setNumeric } from './config_tools';
import ConfigDisabledEditor from './ConfigDisabledEditor';
import ConfigValueEditor from './ConfigValueEditor';

// Undefined means "unchanged".
const EMPTY_STATE = {
  [INSTRUMENT_CONFIG_KEYS.disable_deposits]: undefined,
  [INSTRUMENT_CONFIG_KEYS.disable_deposits_reason]: undefined,
  [INSTRUMENT_CONFIG_KEYS.deposit_fee_abs]: undefined,
  [INSTRUMENT_CONFIG_KEYS.deposit_fee_pct]: undefined,

  [INSTRUMENT_CONFIG_KEYS.disable_withdrawals]: undefined,
  [INSTRUMENT_CONFIG_KEYS.disable_withdrawals_reason]: undefined,
  [INSTRUMENT_CONFIG_KEYS.withdrawal_fee_abs]: undefined,
  [INSTRUMENT_CONFIG_KEYS.withdrawal_fee_pct]: undefined,
};

export default class InstrumentConfigEditor extends ConnectedComponent {
  static propTypes = {
    data: PropTypes.object,
    withDisabling: PropTypes.bool,
    withFees: PropTypes.bool,
    callback: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...EMPTY_STATE,
    };
  }

  get isChanged() {
    for (const key in EMPTY_STATE) {
      if (this.state[key] !== undefined) {
        return true;
      }
    }
    return false;
  }

  updateData = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
  };

  onFormSubmit = e => {
    e.preventDefault();

    if (!this.isChanged) {
      // Nothing to do
      return;
    }

    const instrumentConfig = {};

    if (this.props.withDisabling) {
      setDisabled(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.disable_deposits);
      setDisabled(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.disable_withdrawals);
    }

    if (this.props.withFees) {
      setNumeric(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.deposit_fee_abs);
      setNumeric(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.deposit_fee_pct);
      setNumeric(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.withdrawal_fee_abs);
      setNumeric(instrumentConfig, this.state, INSTRUMENT_CONFIG_KEYS.withdrawal_fee_pct);
    }

    return this.props.callback(instrumentConfig);
  };

  render() {
    const { data, withDisabling, withFees } = this.props;

    return (
      <Form onSubmit={this.onFormSubmit}>
        <ExpanderGroup title="Deposits">
          {withDisabling && (
            <ConfigDisabledEditor
              state={this.boundState}
              data={data}
              value_key={INSTRUMENT_CONFIG_KEYS.disable_deposits}
              reason_key={INSTRUMENT_CONFIG_KEYS.disable_deposits_reason}
              title="Disable deposits"
            />
          )}
          {withFees && (
            <>
              <ConfigValueEditor
                state={this.boundState}
                label="Fee"
                data={data}
                keyName={INSTRUMENT_CONFIG_KEYS.deposit_fee_abs}
                labelWidth={60}
                title="Absolute fee for deposits, in target currency. Subtracted from deposited amount."
              />
              <ConfigValueEditor
                className="mt-2"
                state={this.boundState}
                label="Fee %"
                data={data}
                keyName={INSTRUMENT_CONFIG_KEYS.deposit_fee_pct}
                labelWidth={60}
                title="Percentage fee for deposits. Applied after absolute fee."
              />
            </>
          )}
        </ExpanderGroup>

        <ExpanderGroup title="Withdrawals">
          {withDisabling && (
            <ConfigDisabledEditor
              state={this.boundState}
              data={data}
              value_key={INSTRUMENT_CONFIG_KEYS.disable_withdrawals}
              reason_key={INSTRUMENT_CONFIG_KEYS.disable_withdrawals_reason}
              title="Enable withdrawals"
            />
          )}
          {withFees && (
            <>
              <ConfigValueEditor
                className="mt-2"
                state={this.boundState}
                data={data}
                label="Fee"
                labelWidth={60}
                keyName={INSTRUMENT_CONFIG_KEYS.withdrawal_fee_abs}
                title="Absolute fee for withdrawals, in target currency. Subtracted from the amount the customer will receive"
              />
              <ConfigValueEditor
                className="mt-2"
                state={this.boundState}
                label="Fee %"
                data={data}
                keyName={INSTRUMENT_CONFIG_KEYS.withdrawal_fee_pct}
                labelWidth={60}
                title="Percentage fee for withdrawals. Applied after absolute fee."
              />
            </>
          )}
        </ExpanderGroup>

        <div className="d-flex mt-2">
          <IconButton
            color="primary"
            icon={ICONS.save}
            className="flex-fill"
            disabled={!this.isChanged}
          >
            Set
          </IconButton>
          <IconButton
            color="secondary"
            icon={ICONS.revert}
            type="button"
            onClick={() => this.setState(EMPTY_STATE)}
            disabled={!this.isChanged}
            className="flex-fill ml-1"
          >
            Revert
          </IconButton>
        </div>
      </Form>
    );
  }
}
