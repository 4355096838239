import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from './IconLabel';

const MailchimpStatus = props => {
  /** @type {XcCustomer} */
  const customer = props.customer;

  if (customer.subscribed === true) {
    return <IconLabel flavor={true}>Subscribed</IconLabel>;
  }

  if (customer.subscribed === false) {
    return (
      <IconLabel icon={ICONS.warn} flavor={FLAVORS.warning}>
        Unsubscribed
      </IconLabel>
    );
  }

  return <IconLabel flavor={FLAVORS.danger}>No status</IconLabel>;
};

export default MailchimpStatus;
