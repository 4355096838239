import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { classes, lodash } from '../../../lib/tools';

const BadgeList = ({ className, items, labelLookup, flavor, pill }) => {
  let getLabel;
  if (lodash.isFunction(labelLookup)) {
    getLabel = labelLookup;
  } else if (lodash.isObject(labelLookup)) {
    getLabel = item => labelLookup[item];
  } else {
    getLabel = item => item;
  }

  const badges = items.map(item => {
    const label = getLabel(item);
    return (
      <Badge key={item} color={flavor || FLAVORS.secondary} className="mx-1" pill={pill}>
        {label}
      </Badge>
    );
  });

  return <span className={classes('BadgeList', className)}>{badges}</span>;
};

BadgeList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  labelLookup: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  flavor: PropTypes.string,
  pill: PropTypes.bool,
};

export default BadgeList;
