import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';

import { PAIR_CONFIG_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import ExpanderGroup from '../layout/ExpanderGroup';
import IconButton from '../widgets/interactive/IconButton';
import { setDisabled, setNumeric } from './config_tools';
import ConfigDisabledEditor from './ConfigDisabledEditor';
import ConfigValueEditor from './ConfigValueEditor';

// Undefined means "unchanged".
const EMPTY_STATE = {
  [PAIR_CONFIG_KEYS.disable_orders]: undefined,
  [PAIR_CONFIG_KEYS.disable_orders_reason]: undefined,
  [PAIR_CONFIG_KEYS.trade_fee]: undefined,
  [PAIR_CONFIG_KEYS.min_order]: undefined,
};

export default class PairConfigEditor extends ConnectedComponent {
  static propTypes = {
    data: PropTypes.object,
    withDisabling: PropTypes.bool,
    withFees: PropTypes.bool,
    withLimits: PropTypes.bool,
    callback: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...EMPTY_STATE,
    };
  }

  get isChanged() {
    for (const key in EMPTY_STATE) {
      if (this.state[key] !== undefined) {
        return true;
      }
    }
    return false;
  }

  onFormSubmit = e => {
    e.preventDefault();

    if (!this.isChanged) {
      // Nothing to do
      return;
    }

    const { callback, withDisabling, withFees, withLimits } = this.props;

    const pairConfig = {};

    if (withDisabling) {
      setDisabled(pairConfig, this.state, PAIR_CONFIG_KEYS.disable_orders);
    }

    if (withFees) {
      // We cast to number because this will be a multiplier
      setNumeric(pairConfig, this.state, PAIR_CONFIG_KEYS.trade_fee, Number);
    }

    if (withLimits) {
      // We don't cast to number, this will be quantity
      setNumeric(pairConfig, this.state, PAIR_CONFIG_KEYS.min_order);
    }

    return callback(pairConfig);
  };

  render() {
    const { data, withDisabling, withFees, withLimits } = this.props;

    return (
      <Form onSubmit={this.onFormSubmit}>
        <ExpanderGroup title="Trading">
          {withDisabling && (
            <ConfigDisabledEditor
              state={this.boundState}
              data={data}
              value_key={PAIR_CONFIG_KEYS.disable_orders}
              reason_key={PAIR_CONFIG_KEYS.disable_orders_reason}
              title="Enable trading"
            />
          )}

          {withFees && (
            <ConfigValueEditor
              state={this.boundState}
              data={data}
              label="Fee"
              labelWidth={50}
              keyName={PAIR_CONFIG_KEYS.trade_fee}
              title="Fee which will be applied to each trade. Presented as multiplier (eg. '0.01' equals 1% of the traded amount)"
            />
          )}

          {withLimits && (
            <ConfigValueEditor
              className="mt-2"
              state={this.boundState}
              data={data}
              label="Min"
              labelWidth={50}
              keyName={PAIR_CONFIG_KEYS.min_order}
              title="Minimal order size specified in the pair's quote currency (eg. in LTC/BTC, the value is in LTC)"
            />
          )}
        </ExpanderGroup>

        <div className="d-flex mt-2">
          <IconButton
            color="primary"
            icon={ICONS.save}
            className="flex-fill"
            disabled={!this.isChanged}
          >
            Set
          </IconButton>
          <IconButton
            color="secondary"
            icon={ICONS.revert}
            type="button"
            onClick={() => this.setState(EMPTY_STATE)}
            disabled={!this.isChanged}
            className="flex-fill ml-1"
          >
            Revert
          </IconButton>
        </div>
      </Form>
    );
  }
}
