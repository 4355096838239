import React from 'react';
import { Route, Switch } from 'react-router';

import { LinkDefinition } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import DevIconsPage from './DevIconsPage';

const SUB_PAGES = [new LinkDefinition(routes.DEV_ICONS, 'Icons')];

class DevRouter extends ConnectedComponent {
  render() {
    return (
      <div className="DevRouter">
        <Breadcrumbed link={routes.DEV} title="Dev playground">
          <SubPager links={SUB_PAGES} />
          <Switch>
            <Route path={routes.DEV} render={() => <StickyRedirect routes={SUB_PAGES} />} exact />
            <Route path={routes.DEV_ICONS} component={DevIconsPage} exact />
            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default DevRouter;
