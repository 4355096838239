import './MainMenuSubmenu.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('MainMenuSubmenu');

// TODO: We can probably merge this back into MainMenu proper

class MainMenuSubmenu extends React.Component {
  static propTypes = {
    toggler: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    items: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.func),
    ]).isRequired,
    right: PropTypes.bool,
    nav: PropTypes.bool,
    caret: PropTypes.bool,
    active: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  renderToggler() {
    if (!this.props.toggler) {
      return null;
    }

    return (
      <DropdownToggle
        className={classes(
          'd-flex',
          'align-items-center',
          this.state.isOpen && cn('open'),
          this.props.active && cn('active')
        )}
        tag="span"
        nav={this.props.nav}
        onClick={this.toggle}
      >
        {this.props.toggler}
      </DropdownToggle>
    );
  }

  renderItems() {
    if (!this.props.items) {
      return null;
    }

    if (!Array.isArray(this.props.items)) {
      return <DropdownItem tag="li">{this.props.items}</DropdownItem>;
    }

    return this.props.items.map(item => {
      return (
        <DropdownItem key={item.props.title} tag="li" onClick={this.toggle}>
          {item}
        </DropdownItem>
      );
    });
  }

  render() {
    return (
      <Dropdown
        className={classes(cn(), 'mx-1 d-flex text-white')}
        isOpen={this.state.isOpen}
        toggle={() => null}
        onMouseEnter={() => this.setState({ isOpen: true })}
        onMouseLeave={() => this.setState({ isOpen: false })}
      >
        {this.renderToggler()}
        <DropdownMenu right={this.props.right} tag="ul">
          {this.renderItems()}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

MainMenuSubmenu.defaultProps = {
  right: false,
  nav: false,
  caret: true,
};

export default MainMenuSubmenu;
