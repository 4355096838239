import PropTypes from 'prop-types';
import React from 'react';

import { classNamer, classes, lodash } from '../../lib/tools';
import GridLayout from './GridLayout';
import PageTitle from './PageTitle';

const cn = classNamer('PageLayout');

const PageLayout = ({ className, above, below, children }) => {
  className = classes(className, cn());

  if (lodash.isString(above)) {
    above = <PageTitle title={above} />;
  }

  return (
    <GridLayout className={className} vertical>
      <div>{above}</div>
      <div>{children}</div>
      <div>{below}</div>
    </GridLayout>
  );
};

PageLayout.propTypes = {
  className: PropTypes.string,
  above: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  below: PropTypes.element,
};

export default PageLayout;
