import './CriteriaUserPicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { classNamer, classes } from '../../../lib/tools';
import UserPicker from './UserPicker';

const cn = classNamer('CriteriaUserPicker');

const CriteriaUserPicker = ({
  criteria,
  className,
  label = 'For user:',
  userIdField = 'user_id',
  inputId = cn('id'),
  history,
  placeholder = 'All users',
  ...rest
}) => {
  const handleChange = userId => {
    const nextCriteria = { ...criteria };
    if (userId) {
      nextCriteria[userIdField] = userId;
    } else {
      delete nextCriteria[userIdField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <UserPicker
        selectedId={criteria[userIdField]}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

CriteriaUserPicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  userIdField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
};

export default withRouter(CriteriaUserPicker);
