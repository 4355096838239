import './CustomerVerificationStatus.css';

import React from 'react';

import {
  FLAVORS,
  KYC_VERIFICATION_DOCUMENT_STATUS_LABELS,
  KYC_VERIFICATION_IDENTITY_INVALID_REASON_LABELS,
  KYC_VERIFICATION_REJECT_REASON_LABELS,
} from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { classNamer } from '../../../lib/tools';
import BadgeList from '../../widgets/presentational/BadgeList';
import IconLabel from '../../widgets/presentational/IconLabel';

const cn = classNamer('CustomerVerificationStatus');

const renderFailList = failList => {
  return failList.length > 1 ? (
    <ul className={cn('fail-list')}>
      {failList.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  ) : (
    failList[0]
  );
};

/**
 * @param {{customer: XcCustomerInfo}} props
 */
const CustomerVerificationStatus = props => {
  const ci = props.customer;

  if (!ci.kyc1_verification_id) {
    // No verification, show empty
    return null;
  }

  if (
    !ci.verification_nv_scan_reference &&
    !ci.verification_swiftdil_id &&
    ci.verification_overridden_at
  ) {
    // NOTE: This is an old state. In SwiftDil days, admin was able to override verification without sending it to swiftdil at all.
    return (
      <IconLabel flavor={FLAVORS.muted} icon={ICONS.good}>
        OVERRIDDEN
      </IconLabel>
    );
  }

  if (!ci.verification_nv_scan_reference) {
    // NOTE: This is something else from SwiftDil days - no verification done at all, just document uploaded.
    return <span className="text-muted">Unverified</span>;
  }

  if (!ci.verification_completed_at && !ci.verification_submitted_at) {
    // Customer hasn't submitted yet
    return (
      <IconLabel icon={ICONS.wait} flavor={FLAVORS.muted}>
        Not submitted yet
      </IconLabel>
    );
  }

  if (!ci.verification_completed_at) {
    // We are still waiting for NetVerify to respond.
    return (
      <IconLabel icon={ICONS.wait} flavor={FLAVORS.muted}>
        Submitted, waiting
      </IconLabel>
    );
  }

  const failList = [];

  if (!ci.verification_nv_verified) {
    // NetVerify verification failed
    if (!ci.verification_document_verified) {
      // Document has failed verification. Show info.
      let info =
        KYC_VERIFICATION_DOCUMENT_STATUS_LABELS[ci.verification_document_status] ||
        ci.verification_document_status;
      if (ci.verification_reject_document_reason) {
        info += ` (${
          KYC_VERIFICATION_REJECT_REASON_LABELS[ci.verification_reject_document_reason] ||
          ci.verification_reject_document_reason
        })`;
      }
      failList.push(
        <IconLabel flavor={false}>
          <strong>{info}</strong>
        </IconLabel>
      );
    }

    if (ci.verification_identity_invalid_reason) {
      // Identity check has failed
      failList.push(
        <IconLabel flavor={false}>
          <strong>
            {
              KYC_VERIFICATION_IDENTITY_INVALID_REASON_LABELS[
                ci.verification_identity_invalid_reason
              ]
            }
          </strong>
        </IconLabel>
      );
    }

    if (!failList.length) {
      // This should never happen, but just in case
      console.error(
        `Verification is marked with verification_nv_verified as false, but there are no items in the error list`
      );
      failList.push(
        <IconLabel flavor={false}>
          <strong>NetVerify rejected</strong>
        </IconLabel>
      );
    }

    return renderFailList(failList);
  }

  if (ci.verification_verified && !ci.verification_overridden_at) {
    // We were verified without override. So everything was perfect.
    return <IconLabel flavor={true}>PASSED</IconLabel>;
  }

  // There is some overridable problem with verification. The rest of the render will determine what.

  let flavor, icon;
  if (ci.verification_overridden_at) {
    flavor = FLAVORS.success;
    icon = ICONS.warn;
  } else {
    if (ci.kyc1_completed_at) {
      flavor = FLAVORS.danger;
      icon = ICONS.bad;
    } else {
      flavor = FLAVORS.warning;
      icon = ICONS.warn;
    }
  }

  let inconsistentFieldsCorrected = false;

  if (ci.verification_inconsistent_fields && ci.verification_inconsistent_fields.length) {
    const fields =
      flavor === FLAVORS.success
        ? ci.verification_inconsistent_fields_after_override
        : ci.verification_inconsistent_fields;
    if (fields && fields.length) {
      // Render fields
      failList.push(
        <IconLabel flavor={flavor} wrap className={cn('labeled-badges')}>
          Inconsistent: <BadgeList items={fields} flavor={flavor} />
        </IconLabel>
      );
    }
    // All inconsistent fields were corrected
    inconsistentFieldsCorrected = true;
  }

  if (ci.verification_missing_fields && ci.verification_missing_fields.length) {
    failList.push(
      <IconLabel flavor={flavor} wrap className={cn('labeled-badges')}>
        Missing: <BadgeList items={ci.verification_missing_fields} flavor={flavor} />
      </IconLabel>
    );
  }

  if (ci.verification_underage) {
    failList.push(
      <IconLabel flavor={flavor} icon={icon}>
        Customer is underage
      </IconLabel>
    );
  }

  if (ci.verification_similarity_match === false) {
    // Definitive similarity negative
    failList.push(
      <IconLabel flavor={flavor} icon={icon}>
        <strong>Similarity mismatch</strong>
      </IconLabel>
    );
  } else if (!ci.verification_similarity_match) {
    // Couldn't determine similarity
    failList.push(
      <IconLabel flavor={flavor} icon={icon}>
        <strong>Similarity couldn't be determined</strong>
      </IconLabel>
    );
  }

  if (!failList.length && inconsistentFieldsCorrected) {
    // Render notice about corrected inconsistent fields
    failList.push(
      <IconLabel flavor={flavor} icon={icon}>
        <strong>Inconsistent fields corrected</strong>
      </IconLabel>
    );
  }

  if (!failList.length) {
    // Fall-back to at least something
    failList.push(
      <IconLabel flavor={flavor} icon={icon}>
        <strong>Unexpected value</strong>
      </IconLabel>
    );
  }

  return renderFailList(failList);
};

export default CustomerVerificationStatus;
