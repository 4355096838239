import './Breadcrumbs.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';

const cn = classNamer('Breadcrumbs');

class Breadcrumbs extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const crumbEls = [];
    const breadcrumbs = this.container.store.breadcrumbs;

    breadcrumbs.forEach((breadcrumb, index) => {
      if (index > 0) {
        crumbEls.push(
          <span key={'sep_' + index} className={classes(cn('separator'), 'text-muted mr-1')}>
            »
          </span>
        );
      }

      if (index === breadcrumbs.length - 1) {
        // Last crumb
        crumbEls.push(
          <span key={'crumb_' + index} className={cn('last', 'mr-1')}>
            {breadcrumb.title}
          </span>
        );
      } else {
        crumbEls.push(
          <Link key={'crumb_' + index} to={breadcrumb.link} className="mr-1">
            {breadcrumb.title}
          </Link>
        );
      }
    });

    return <div className={classes(cn(), this.props.className)}>{crumbEls}</div>;
  }
}

export default withRouter(Breadcrumbs);
