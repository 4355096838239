import PropTypes from 'prop-types';
import React from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { ICONS } from '../../lib/icons';
import { classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import IconButton from '../widgets/interactive/IconButton';

export default class ConfigValueEditor extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    label: PropTypes.any,
    labelWidth: PropTypes.number,
    state: PropTypes.object.isRequired,
    keyName: PropTypes.string.isRequired,
  };

  get value() {
    const key = this.props.keyName;
    return (
      (this.props.state[key] !== undefined
        ? this.props.state[key]
        : this.props.data && this.props.data[key]) || ''
    );
  }

  update(value) {
    this.props.state.setState({
      [this.props.keyName]: value,
    });
  }

  render() {
    const labelStyle = this.props.labelWidth
      ? {
          width: this.props.labelWidth + 'px',
        }
      : null;
    return (
      <InputGroup className={classes('ConfigValueEditor', this.props.className)}>
        <InputGroupAddon addonType="prepend">
          <span className="input-group-text" style={labelStyle}>
            {this.props.label}
          </span>
        </InputGroupAddon>
        <Input
          type="number"
          id={this.props.keyName}
          value={this.value}
          onChange={e => this.update(e.target.value)}
          placeholder="<Not set>"
          step={0.0001}
          title={this.props.title}
        />
        <InputGroupAddon addonType="append">
          <IconButton
            color="secondary"
            icon={ICONS.clear}
            title="Clear"
            onClick={() => this.update(null)}
          />
        </InputGroupAddon>
      </InputGroup>
    );
  }
}
