import PropTypes from 'prop-types';
import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('PageTitle');

const PageTitle = ({ title, subtitle = null, children }) => {
  const className = classes(cn(), 'mt-4', 'mb-4');
  return (
    <div className={className}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default PageTitle;
