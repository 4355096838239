import { library } from '@fortawesome/fontawesome-svg-core';
import * as defs from '@fortawesome/free-solid-svg-icons';

export const ICONS = {
  home: defs.faHome,
  users: defs.faUsers,
  bots: defs.faRobot,
  markets: defs.faHandshake,
  sessions: defs.faPlug,
  trollbox: defs.faCommentDots,
  kyc: defs.faIdCard,
  managed: defs.faFileInvoiceDollar,
  internals: defs.faCogs,
  trading: defs.faChartLine,
  transactions: defs.faExchangeAlt,
  settings: defs.faTools,

  login: defs.faUnlockAlt,
  logout: defs.faLock,
  user: defs.faUserCircle,
  login_action: defs.faKey,

  clear: defs.faBan,
  new: defs.faPlusSquare,
  edit: defs.faEdit,
  details: defs.faEye,
  delete: defs.faTrash,
  save: defs.faSave,
  revert: defs.faUndoAlt,
  upload: defs.faUpload,
  download: defs.faDownload,
  image: defs.faImage,
  eraser: defs.faEraser,
  wait: defs.faHourglass,
  print: defs.faPrint,
  cog: defs.faCog,
  cogs: defs.faCogs,
  lock: defs.faLock,
  unlock: defs.faLockOpen,
  expanded_view: defs.faSearchDollar,
  redo: defs.faRedo,

  check: defs.faCheck,
  backward: defs.faBackward,

  change_password: defs.faKey,
  terminate_session: defs.faMinusCircle,

  ban: defs.faBan,
  flag_message: defs.faHandPaper,

  ua_other: defs.faLaptop,
  ua_camera: defs.faCamera,
  ua_car: defs.faCar,
  ua_console: defs.faGamepad,
  ua_glasses: defs.faGlasses,
  ua_phone: defs.faMobileAlt,
  ua_player: defs.faHeadphonesAlt,
  ua_smartphone: defs.faMobileAlt,
  ua_smartspeaker: defs.faHeadphonesAlt,
  ua_tablet: defs.faTabletAlt,
  ua_tv: defs.faTv,
  ua_watch: defs.faStopwatch,

  expand_up: defs.faAngleDoubleUp,
  expand_down: defs.faAngleDoubleDown,

  first: defs.faFastBackward,
  previous: defs.faStepBackward,
  next: defs.faStepForward,
  last: defs.faFastForward,

  extra_good: defs.faCheckCircle,
  good: defs.faCheck,
  question: defs.faQuestionCircle,
  warn: defs.faExclamationTriangle,
  bad: defs.faTimes,

  play: defs.faPlayCircle,
  pause: defs.faPauseCircle,

  generate: defs.faDice,
  clipboard: defs.faClipboard,

  superadmin: defs.faUserNinja,
  admin: defs.faUserTie,
  customer: defs.faUser,

  upgrade: defs.faArrowUp,
  downgrade: defs.faArrowDown,
  reset: defs.faUndo,

  link: defs.faLink,

  back: defs.faArrowLeft,

  arrow_left: defs.faArrowLeft,
  arrow_right: defs.faArrowRight,

  loader: defs.faCircleNotch,

  file_any: defs.faFile,
  file_pdf: defs.faFilePdf,
  file_csv: defs.faFileCsv,
  file_word: defs.faFileWord,

  plus: defs.faPlus,
  minus: defs.faMinus,
};

/**
 * @type {string[]}
 */
export const ICON_LIST = [];

// Pack definitions to array and export only the class names as icons

const iconDefinitions = [];
for (const key in ICONS) {
  iconDefinitions.push(ICONS[key]);
  ICONS[key] = ICONS[key].iconName;
  ICON_LIST.push(ICONS[key]);
}

export function initIcons() {
  iconDefinitions.forEach(def => library.add(def));
}

export { FontAwesomeIcon as AppIcon } from '@fortawesome/react-fontawesome';
