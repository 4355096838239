import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FLAVORS, MANAGED_SELL_ORDER_KEYS, WITHDRAWAL_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import IconButton from '../widgets/interactive/IconButton';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import ManagedBankTransfersTable from './widgets/ManagedBankTransfersTable';
import ManagedOrderActionWidget from './widgets/ManagedOrderActionWidget';
import ManagedPartnerTradesTable from './widgets/ManagedPartnerTradesTable';
import ManagedPartnerTransfersTable from './widgets/ManagedPartnerTransfersTable';
import { ManagedSellOrderStatus } from './widgets/ManagedSellOrderStatus';

const orderId = props => props.match.params.id || null;

const COLUMNS = [
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.user_id, 'User ID'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.instrument, 'Instrument'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.quantity, 'Quantity'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.payout_currency, 'Payout currency'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.bank_name, 'Name'),
  new DataTableColumn(null, 'Account number', (/** XcManagedSellOrder */ order) => (
    <span>{order.bank_account_number}</span>
  )),
  new DataTableColumn(null, 'Swift', (/** XcManagedSellOrder */ order) => (
    <span>{order.swift}</span>
  )),
  new DataTableColumn(null, 'Status', (/** XcManagedSellOrder */ order) => (
    <ManagedSellOrderStatus order={order} />
  )),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.recipient_name, 'Name'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.recipient_address, 'Address'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.fee_pct, 'Percentage'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.fee_fixed, 'Fixed'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.fee_total, 'Total'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.failure_reason, 'Failure reason'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.created_at, 'Created at'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.updated_at, 'Updated at'),
  new DataTableColumn(MANAGED_SELL_ORDER_KEYS.completed_at, 'Completed at'),
];

const GROUPS = [
  new DataTableGroup('Bank', 5, 7),
  new DataTableGroup('Recipient', 9, 10),
  new DataTableGroup('Fee', 10, 12),
];

const WITHDRAWAL_COLUMNS = [
  new DataTableColumn(WITHDRAWAL_KEYS.id, 'ID'),
  new DataTableColumn(WITHDRAWAL_KEYS.quantity, 'Quantity'),
  new DataTableColumn(WITHDRAWAL_KEYS.target_address, 'Target address'),
  new DataTableColumn(WITHDRAWAL_KEYS.failure_code, 'Failure code'),
  new DataTableColumn(WITHDRAWAL_KEYS.created_at, 'Created at'),
  new DataTableColumn(WITHDRAWAL_KEYS.completed_at, 'Completed at'),
];

const cn = classNamer('ManagedSellOrdersExpandedOrderPage');

class ManagedSellOrdersExpandedOrderPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      order: null,
      withdrawals: {},
    };
  }

  get orderId() {
    return orderId(this.props);
  }

  componentDidUpdate(prevProps) {
    if (orderId(prevProps) !== this.orderId) {
      this.loadData();
    }
  }

  loadData() {
    return Promise.all([
      this.promiseOrToast(this.container.client.getBrokerOrdersSellOrderId(this.orderId)),
      this.promiseOrToast(this.container.client.getBrokerWithdrawal(this.orderId)),
      this.promiseOrToast(this.container.client.getBrokerPartnerTrades({ order_id: this.orderId })),
    ]).then(([order, withdrawals, trades]) => {
      this.setState({
        order,
        withdrawals,
        trades,
        tradeSelection: [],
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  executeAndReloadOrToast = (clientCall, notificationMessage) => {
    return this.promiseOrToast(clientCall).then(() => {
      toast.success(notificationMessage);
      this.loadData();
    });
  };

  /******************************************************/

  render() {
    const { order, withdrawals } = this.state;
    if (!order) {
      return null;
    }

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed title="View" link={routes.managedSellOrdersExpandedOrder(this.orderId)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <ManagedOrderActionWidget
              orderType="sell"
              data={{ items: [order] }}
              selection={[0]}
              loadData={() => this.loadData()}
              isExpandedView={true}
            />
            <div>
              <div>
                <h3 className="mb-0">Sell Order ({order.id})</h3>
                <IconButton
                  tag={Link}
                  to={routes.MANAGED_SELL_ORDERS}
                  icon={ICONS.back}
                  color={FLAVORS.link}
                  className="p-0"
                >
                  All orders
                </IconButton>
                <DataTable columns={COLUMNS} groups={GROUPS} data={[order]} criteria={{}} />
              </div>
              <div className="mt-3 p-2 bg-light">
                <h5 className="text-uppercase mb-3">Executed withdrawals</h5>
                <div className="d-flex">
                  <SelectionSidebar
                    selection={[]}
                    renderOne={() => {}}
                    renderMany={() => {}}
                    renderNone={() => (
                      <Expander title="Actions">
                        <div className="d-flex">
                          <IconButton
                            tag={Link}
                            to={routes.managedSellOrdersWithdraw(order.id)}
                            className="flex-fill"
                            color={FLAVORS.success}
                            disabled={!order.taken_at || !!order.failure_reason}
                          >
                            Withdraw
                          </IconButton>
                        </div>
                      </Expander>
                    )}
                  />
                  <DataTable
                    className="ml-3"
                    columns={WITHDRAWAL_COLUMNS}
                    data={withdrawals}
                    criteria={{}}
                  />
                </div>
              </div>
              <ManagedBankTransfersTable order={order} />
              <ManagedPartnerTransfersTable order={order} />
              <ManagedPartnerTradesTable order={order} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(ManagedSellOrdersExpandedOrderPage);
