import './CriteriaInstrumentPicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../lib/criterias';
import { classNamer, classes } from '../../lib/tools';
import InstrumentPicker from './InstrumentPicker';

const cn = classNamer('CriteriaInstrumentPicker');

const SINGLE_INSTRUMENT_FIELD = 'instrument';
const MULTI_INSTRUMENT_FIELD = 'instruments';

const CriteriaInstrumentPicker = ({
  criteria,
  className,
  label = 'For instrument:',
  instrumentField,
  inputId = cn('id'),
  history,
  placeholder = 'All instruments',
  isMulti,
  ...rest
}) => {
  instrumentField = instrumentField || (isMulti ? MULTI_INSTRUMENT_FIELD : SINGLE_INSTRUMENT_FIELD);

  const handleChange = instrument => {
    const nextCriteria = { ...criteria };
    if (instrument) {
      nextCriteria[instrumentField] = instrument;
    } else {
      delete nextCriteria[instrumentField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <InstrumentPicker
        selected={criteria[instrumentField]}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        isMulti={isMulti}
        {...rest}
      />
    </div>
  );
};

CriteriaInstrumentPicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  instrumentField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  instruments: PropTypes.arrayOf(PropTypes.string),
};

export default withRouter(CriteriaInstrumentPicker);
