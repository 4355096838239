import PropTypes from 'prop-types';
import React from 'react';

import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Select from '../widgets/interactive/Select';

export default class InstrumentPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    selected: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onChange: PropTypes.func,

    /**
     * Override instrument list. Defaults to all instruments for the exchange.
     */
    instruments: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    isClearable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  static getDerivedStateFromProps({ selected }) {
    return {
      selected: selected
        ? selected.includes(',')
          ? selected.split(',').map(instrument => ({ instrument }))
          : { instrument: selected }
        : null,
    };
  }

  getInstruments() {
    const instrumentsArray = this.props.instruments || this.container.settings.instrument_list;
    return instrumentsArray.map(instrument => ({ instrument }));
  }

  onChange = option => {
    if (this.props.onChange) {
      this.props.onChange(
        option
          ? Array.isArray(option)
            ? option.map(item => item.instrument)
            : option.instrument
          : null
      );
    }
  };

  render() {
    return (
      <Select
        options={this.getInstruments()}
        onChange={this.onChange}
        value={this.state.selected}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={instrument => <span>{instrument.instrument}</span>}
        getOptionValue={instrument => instrument.instrument}
        noOptionsMessage={() => 'No instruments'}
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
      />
    );
  }
}
