import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import { ADMIN_PERMISSIONS, GEO_LOCATION_PINNINGS, LANGUAGES, ROLES } from '../../lib/backend';
import { FLAVORS, LANGUAGE_NAMES, ROLE_NAMES, USER_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import PasswordGenerator from '../../lib/password_generator';
import { routes } from '../../lib/routes';
import { lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import BoundCheckbox from '../widgets/bound/BoundCheckbox';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import BoundFormSelect from '../widgets/bound/BoundFormSelect';
import EnumFieldset from '../widgets/interactive/EnumFieldset';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';

const userId = props => props.match.params.id || null;

const EDIT_PROPS = [
  USER_KEYS.email,
  USER_KEYS.nickname,
  USER_KEYS.role,
  USER_KEYS.admin_permissions,
  USER_KEYS.language,
  USER_KEYS.geo_location_pinning,
  USER_KEYS.enable_keep_alive,
  USER_KEYS.enable_order_confirmations,
  USER_KEYS.enable_order_notifications,
  USER_KEYS.enable_withdrawal_confirmations,
];

class UserEditPage extends ConnectedComponent {
  passwordInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      /** @type XcUser */
      data: {},

      waiting: !!userId(props),

      passwordGenerated: false,
      passwordCopied: false,
    };

    this.pg = new PasswordGenerator({ special: false, uppercase: true, digits: true });
  }

  get userId() {
    return userId(this.props);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (userId(prevProps) !== this.userId) {
      this.loadData();
    }
  }

  loadData() {
    if (this.isNew) {
      // default options
      this.setState({
        data: {
          [USER_KEYS.enable_keep_alive]: true,
          [USER_KEYS.enable_order_confirmations]: true,
          [USER_KEYS.enable_order_notifications]: true,
          [USER_KEYS.enable_withdrawal_confirmations]: true,
          [USER_KEYS.language]: LANGUAGES.en_us,
          [USER_KEYS.geo_location_pinning]: GEO_LOCATION_PINNINGS.off,
          [USER_KEYS.role]: ROLES.customer,
        },
      });
      return;
    }
    this.promiseOrSetError(this.container.client.getUser(this.userId)).then(data => {
      const initialData = lodash.pick(data, EDIT_PROPS);
      this.setState({ data: { ...initialData }, initData: { ...initialData } });
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ passwordCopied: false, passwordGenerated: false });

    const promise = this.isNew
      ? this.container.client.postUser(this.state.data)
      : this.container.client.putUser(
          this.userId,
          lodash.pickBy(this.state.data, (val, key) => this.state.initData[key] !== val)
        );
    return this.promiseOrSetError(promise).then(user => {
      const message = this.isNew ? `User ${user.id} created` : `User ${user.id} updated`;
      toast(message, {
        type: 'success',
      });

      this.container.history.pushWithQuery(routes.USERS);
    });
  };

  copyPassword = () => {
    this.passwordInputRef.current.select();
    document.execCommand('copy');
    this.setState({ passwordCopied: true });
  };

  generatePassword = () => {
    const password = this.pg.generate();
    this.setState({
      data: { ...this.state.data, password },
      passwordGenerated: true,
      passwordCopied: false,
    });
  };

  get isNew() {
    return !this.userId;
  }

  get isFormReady() {
    const { data } = this.state;
    return data.email && data.password && data.language && data.geo_location_pinning && data.role;
  }

  render() {
    const title = this.isNew ? `Create new user` : `Edit user ${this.userId}`;

    return (
      <GridLayout className="container" above={title}>
        <AjaxWrapper state={this.boundState}>
          <Breadcrumbed
            title={this.isNew ? 'Create' : 'Edit'}
            link={this.isNew ? routes.USERS_NEW : routes.usersEdit(this.userId)}
          />

          <ErrorBox state={this.boundState} />

          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Email"
                  name={USER_KEYS.email}
                  state={this.boundState}
                  nest
                  placeholder="something@user.com"
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput name="nickname" label="Nickname" state={this.boundState} nest />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <Bind state={this.boundState} nest>
                      <Input
                        innerRef={this.passwordInputRef}
                        type="text"
                        name="password"
                        id="password"
                      />
                    </Bind>
                    <InputGroupAddon addonType="append">
                      <IconButton
                        id="btn-gen"
                        color="success"
                        icon={ICONS.generate}
                        title="Generate"
                        onClick={() => this.generatePassword()}
                      />
                      <IconButton
                        id="btn-copy"
                        color="secondary"
                        icon={ICONS.clipboard}
                        title="Copy to clipboard"
                        onClick={() => this.copyPassword()}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                  {this.state.passwordGenerated && !this.state.passwordCopied && (
                    <FormText color={FLAVORS.warning}>
                      Please copy the generated password, you will not be able to see it after
                      saving
                    </FormText>
                  )}
                  {this.state.passwordCopied && (
                    <FormText color={FLAVORS.success}>Password was copied to clipboard</FormText>
                  )}
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <BoundFormSelect
                  name="language"
                  label="Language"
                  state={this.boundState}
                  nest
                  values={LANGUAGES}
                  labels={LANGUAGE_NAMES}
                />
              </div>
              <div className="col-md-6">
                <BoundFormSelect
                  name="geo_location_pinning"
                  label="Geo Location Pinning"
                  state={this.boundState}
                  nest
                  values={GEO_LOCATION_PINNINGS}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label>User settings:</Label>
                  <div className="mb-2">
                    <BoundCheckbox
                      state={this.boundState}
                      nest
                      name={USER_KEYS.enable_keep_alive}
                      label="Keep alive"
                    />
                    <BoundCheckbox
                      state={this.boundState}
                      nest
                      name={USER_KEYS.enable_order_confirmations}
                      label="Order confirmations"
                    />
                    <BoundCheckbox
                      state={this.boundState}
                      nest
                      name={USER_KEYS.enable_order_notifications}
                      label="Order notifications"
                    />
                    <BoundCheckbox
                      state={this.boundState}
                      nest
                      name={USER_KEYS.enable_withdrawal_confirmations}
                      label="Withdrawal confirmations"
                    />
                  </div>
                </FormGroup>
              </div>
            </div>

            <div className="row">
              {this.isNew && (
                <div className="col-auto">
                  <Bind state={this.boundState} name="role" nest>
                    <EnumFieldset
                      label="Role"
                      values={ROLES}
                      labels={ROLE_NAMES}
                      disabled={!this.container.auth.isSuperAdmin}
                    >
                      {!this.container.auth.isSuperAdmin && (
                        <FormText color={FLAVORS.warning}>
                          Only superadmins can create or modify admins
                        </FormText>
                      )}
                    </EnumFieldset>
                  </Bind>
                </div>
              )}
              {this.state.data.role === ROLES.admin && this.container.auth.isSuperAdmin && (
                <div className="col-auto">
                  <Bind state={this.boundState} name="admin_permissions" nest>
                    <EnumFieldset
                      label="Permissions"
                      values={ADMIN_PERMISSIONS}
                      multiple
                      disabled={this.state.data.role !== ROLES.admin}
                    />
                  </Bind>
                </div>
              )}
            </div>

            <IconButton
              color="success"
              className="mr-2"
              icon={ICONS.save}
              disabled={this.isNew && !this.isFormReady}
            >
              {this.isNew ? 'Create' : 'Save'}
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, routes.USERS_ACCOUNTS)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default UserEditPage;
