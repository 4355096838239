import './AjaxWrapper.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classNamer } from '../../lib/tools';
import ErrorBox from '../widgets/presentational/ErrorBox';
import Spinner from '../widgets/presentational/Spinner';
import { BoundState } from './ConnectedComponent';

const cn = classNamer('AjaxWrapper');

export default class AjaxWrapper extends React.Component {
  static propTypes = {
    state: PropTypes.instanceOf(BoundState),
  };

  render() {
    const { state, children } = this.props;

    if (state.waiting) {
      return (
        <div className={cn('waiting')}>
          <Spinner />
        </div>
      );
    }

    if (state.error && state.error.code >= 500) {
      return (
        <div className={cn('error')}>
          <ErrorBox
            size={ErrorBox.SIZES.large}
            error={state.error}
            onDismiss={() => {
              state.setState({
                error: null,
              });
            }}
          />
        </div>
      );

      // Let the boundary handle it?
      // throw error;
    }

    if (typeof children === 'function') {
      // Treat as render function
      return children();
    }

    return children;
  }
}
