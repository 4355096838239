import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { getBoundValue, setBoundValue } from '../../infrastructure/Bind';
import { BoundState, Component } from '../../infrastructure/ConnectedComponent';

export default class BoundDropdown extends Component {
  static propTypes = {
    addon: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['prepend', 'append'])]),
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    state: PropTypes.instanceOf(BoundState).isRequired,
    nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { state, name, nest } = this.props;
    const value = getBoundValue(state, name, nest);
    return (
      <Dropdown
        addonType={
          this.props.addon ? (this.props.addon === true ? 'append' : this.props.addon) : undefined
        }
        isOpen={this.state.isOpen}
        toggle={() =>
          this.setState({
            isOpen: !this.state.isOpen,
          })
        }
      >
        <DropdownToggle caret>{value}</DropdownToggle>
        <DropdownMenu>
          {this.props.items.map(item => (
            <DropdownItem
              key={item}
              active={item === value}
              onClick={() => {
                setBoundValue(state, name, item, nest);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
