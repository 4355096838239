import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';

import { ADMIN_PERMISSIONS, XcPairConfigPayload } from '../../lib/backend';
import { PAIR_CONFIG_KEYS, defaultPairConfig } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import ConfigDisabledIndicator from '../configs/ConfigDisabledIndicator';
import ConfigViewToggler from '../configs/ConfigViewToggler';
import PairConfigEditor from '../configs/PairConfigEditor';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import ExpanderAddOn from '../layout/ExpanderAddOn';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import EnumSelect from '../widgets/interactive/EnumSelect';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const BASE_CRITERIA = {
  view: ConfigViewToggler.VIEWS.effective,
};

const FIXED_COLUMNS = [new DataTableColumn('id', 'ID'), new DataTableColumn('email', 'Email')];

const makeDataTableConfig = (view, pairList) => {
  let index = FIXED_COLUMNS.length;
  const columns = [
    ...FIXED_COLUMNS,
    ConfigDisabledIndicator.dataTableColumn(
      `global.${view}`,
      PAIR_CONFIG_KEYS.disable_orders,
      'Status'
    ),
    new DataTableColumn(`global.${view}.${PAIR_CONFIG_KEYS.trade_fee}`, 'Fee', null, false),
  ];
  const groups = [new DataTableGroup('General', index, index + 1)];
  const highlight = [2, 3];
  index += 2;

  pairList.forEach(pair => {
    groups.push(new DataTableGroup(pair, index, index + 1));
    columns.push(
      ConfigDisabledIndicator.dataTableColumn(
        `individual.${pair}.${view}`,
        PAIR_CONFIG_KEYS.disable_orders,
        'Status'
      ),
      new DataTableColumn(
        `individual.${pair}.${view}.${PAIR_CONFIG_KEYS.trade_fee}`,
        'Fee',
        null,
        false
      )
    );
    index += 2;
  });

  return { columns, groups, highlight };
};

const cn = classNamer('UserMarketConfigsPage');

class UserMarketConfigsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],

      editPair: this.container.settings.pair_list[0],

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  getDataTableConfig = createSelector(
    state => state.criteria.view,
    () => this.container.settings.pair_list,
    makeDataTableConfig
  );

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria, BASE_CRITERIA)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getConfigPairsUsers(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  updateConfig = (pairs, userIds, value, successMessage) => {
    const payload = new XcPairConfigPayload({
      ...value,
      user_ids: userIds || null,
      pairs: pairs || null,
    });
    return this.promiseOrToast(this.container.client.putConfigPair(payload))
      .then(() => {
        toast.success(successMessage);
      })
      .finally(() => {
        this.loadData();
      });
  };

  get withFees() {
    return this.container.auth.hasPermission(ADMIN_PERMISSIONS.incentives);
  }

  get withDisabling() {
    return this.container.auth.hasPermission(ADMIN_PERMISSIONS.security);
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcUserConfigInfo} */
    const uci = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Set general" memoryKey={cn('edit-global-one')}>
          <PairConfigEditor
            data={uci.global.configured || defaultPairConfig}
            withFees={this.withFees}
            withDisabling={this.withDisabling}
            callback={value =>
              this.updateConfig(
                null,
                [uci.id],
                value,
                `Updated general pair config for "${uci.email}"`
              )
            }
          />
        </Expander>

        <Expander title="Set for pair" memoryKey={cn('edit-individual-one')}>
          <ExpanderAddOn>
            <Bind state={this.boundState}>
              <EnumSelect values={this.container.settings.pairs} name="editPair" id="editPair" />
            </Bind>
          </ExpanderAddOn>

          <PairConfigEditor
            data={uci.individual[this.state.editPair].configured || defaultPairConfig}
            withFees={this.withFees}
            withDisabling={this.withDisabling}
            callback={value =>
              this.updateConfig(
                [this.state.editPair],
                [uci.id],
                value,
                `Updated ${this.state.editPair} config for "${uci.email}"`
              )
            }
          />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = selection => {
    const userIds = selection.map(index => this.state.data.items[index].id);
    const count = userIds.length;
    return (
      <div>
        <Expander title={`Set general (${count} users)`} memoryKey={cn('edit-global-many')}>
          <PairConfigEditor
            data={null}
            withFees={this.withFees}
            withDisabling={this.withDisabling}
            callback={value =>
              this.updateConfig(
                null,
                userIds,
                value,
                `Updated general pair config for ${count} users`
              )
            }
          />
        </Expander>

        <Expander title={`Set for pair (${count} users)`} memoryKey={cn('edit-individual-many')}>
          <ExpanderAddOn>
            <Bind state={this.boundState}>
              <EnumSelect values={this.container.settings.pairs} name="editPair" id="editPair" />
            </Bind>
          </ExpanderAddOn>

          <PairConfigEditor
            data={null}
            withFees={this.withFees}
            withDisabling={this.withDisabling}
            callback={value =>
              this.updateConfig(
                [this.state.editPair],
                userIds,
                value,
                `Updated ${this.state.editPair} config for ${count} users`
              )
            }
          />
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_MARKET_CONFIGS)} />
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <ConfigViewToggler criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                {...this.getDataTableConfig(this.state)}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(UserMarketConfigsPage);
