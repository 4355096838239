import './SettingsRegistrationPage.css';

import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label } from 'reactstrap';

import { REGISTRATION_POLICY_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import BoundCheckbox from '../widgets/bound/BoundCheckbox';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import CountryCheckboxGroups from './widgets/CountryCheckboxGroups';

const cn = classNamer('SettingsRegistrationPage');

class SettingsRegistrationPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.promiseOrToast(this.container.client.getCustomersRegistrationPolicy()).then(data => {
      this.setState({ data });
    });
  }

  onCountryChange = country_blacklist =>
    this.setState({
      data: {
        ...this.state.data,
        country_blacklist,
      },
    });

  onSubmit = e => {
    e.preventDefault();

    const { cidr_whitelist } = this.state.data;

    const data = {
      ...this.state.data,
      cidr_whitelist:
        !cidr_whitelist || Array.isArray(cidr_whitelist)
          ? cidr_whitelist
          : cidr_whitelist.replace(/\s/g, '').split(','),
    };

    return this.promiseOrSetError(this.container.client.postCustomersRegistrationPolicy(data)).then(
      () => {
        this.setState({ error: false }, () =>
          toast('Updated registration policy', {
            type: 'success',
          })
        );
      }
    );
  };

  renderDisabledMessage = () => {
    if (!this.state.data || !this.state.data.disabled) {
      return null;
    }

    return (
      <BoundFormInput
        nest
        type="textarea"
        state={this.boundState}
        name={REGISTRATION_POLICY_KEYS.disabled_message}
        label="Disabled message:*"
        helpText="This message will be displayed to the customer when they try to register (required)"
      />
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')} above="Registration settings">
        <Breadcrumbed link={SECTIONS.settings.subsectionAtRoute(routes.SETTINGS_REGISTRATION)} />

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />
          {this.state.data && (
            <Form onSubmit={this.onSubmit}>
              <BoundCheckbox
                nest
                state={this.boundState}
                name={REGISTRATION_POLICY_KEYS.disabled}
                label="Disable registration"
              />
              {this.renderDisabledMessage()}
              <BoundFormInput
                nest
                type="textarea"
                state={this.boundState}
                name={REGISTRATION_POLICY_KEYS.cidr_whitelist}
                label="CIDR IP whitelist:"
                helpText="Comma separated CIDR IP addresses that are allowed to register (ex. 192.0.1.0/24, 192.0.2.0/24)"
              />
              <FormGroup>
                <Label>Banned countries:</Label>
                <CountryCheckboxGroups
                  blacklist={this.state.data && this.state.data.country_blacklist}
                  onChange={this.onCountryChange}
                />
              </FormGroup>
              <BoundCheckbox
                nest
                state={this.boundState}
                name={REGISTRATION_POLICY_KEYS.require_captcha}
                label="Require captcha"
              />
              <IconButton color="success" icon={ICONS.save}>
                Save
              </IconButton>
            </Form>
          )}
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(SettingsRegistrationPage);
