import './ConstrainedMultilineText.css';

import React from 'react';

import { classes } from '../../../lib/tools';

const ConstrainedMultilineText = ({ className, children }) => {
  return <div className={classes(className, 'ConstrainedMultilineText')}>{children}</div>;
};

export default ConstrainedMultilineText;
