import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../../lib/routes';
import ErrorBox from '../widgets/presentational/ErrorBox';

class NotFoundPage extends Component {
  render() {
    return (
      <div className="p-5 container">
        <ErrorBox code={404} title={'Page not found'} size={ErrorBox.SIZES.large}>
          <p className="text-center mt-4">
            To go back home, click <Link to={routes.HOME}>here</Link>
          </p>
        </ErrorBox>
      </div>
    );
  }
}

export default NotFoundPage;
