import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const ManagedBuyOrderStatus = props => {
  /** @type {XcManagedBuyOrder} */
  const order = props.order;

  if (order.cancelled_at) {
    return (
      <IconLabel flavor={FLAVORS.warning} icon={ICONS.warn}>
        Cancelled
      </IconLabel>
    );
  }

  if (order.completed_at) {
    if (order.failure_reason) {
      return <IconLabel flavor={FLAVORS.danger}>Failed</IconLabel>;
    }

    return (
      <IconLabel flavor={FLAVORS.success} icon={ICONS.good}>
        Fulfilled
      </IconLabel>
    );
  }

  if (order.taken_at && !order.suspended_at) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.play}>
        Executing
      </IconLabel>
    );
  }

  if (order.suspended_at) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.pause}>
        On hold
      </IconLabel>
    );
  }

  if (order.payment_received_at) {
    return (
      <IconLabel flavor={FLAVORS.info} icon={ICONS.pause}>
        Payment received
      </IconLabel>
    );
  }

  return (
    <IconLabel flavor={FLAVORS.info} icon={ICONS.pause}>
      Awaiting payment
    </IconLabel>
  );
};
export default ManagedBuyOrderStatus;
