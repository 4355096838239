import { LOGGER_COLORS } from './logger';
import { userDisplayName } from './selectors';
import { merge } from './tools';

/**
 * @typedef {Object} BreadcrumbDefinition
 * @property {string} title
 * @property {string} link
 * @property {string} id
 */

/** @type {WeakMap<Store, StorePrivate>} */
const STORE_PRIVATE = new WeakMap();

/**
 * NOTE: Unlike redux store, subscribers are called asynchronously
 *       (when updates happen, notifications happen at the end of the current run loop ).
 */
export class Store {
  /**
   * @param {Logger} logger
   */
  constructor(logger) {
    STORE_PRIVATE.set(
      this,
      /** @lends StorePrivate.prototype */ {
        version: 0,
        subscribers: [],
        /** @type {Logger} */
        logger: logger.prefixed('Store'),
        update: null,
      }
    );

    /** @type XcPrincipal */
    this.principal = undefined;

    /** @type BreadcrumbDefinition[] */
    this.breadcrumbs = [];

    /** @type number */
    this.userLastSeenTs = null;
  }

  _breadcrumbIdLeave(id) {
    return this.breadcrumbs.filter(def => {
      if (def.id === id) {
        STORE_PRIVATE.get(this).logger.log(`Breadcrumb LEAVE ${def.link} (${def.title})`);
        return false;
      }

      return true;
    });
  }

  /**
   * @param {BreadcrumbDefinition} def
   */
  breadcrumbEnter(def) {
    const breadcrumbs = this._breadcrumbIdLeave(def.id);
    STORE_PRIVATE.get(this).logger.log(`Breadcrumb ENTER ${def.link} (${def.title})`);
    this.update({
      breadcrumbs: breadcrumbs.concat(def),
    });
  }

  /**
   * Remove breadcrumb with given id from breadcrumb stack
   */
  breadcrumbLeave(id) {
    const breadcrumbs = this._breadcrumbIdLeave(id);
    if (breadcrumbs.length !== this.breadcrumbs.length) {
      this.update({
        breadcrumbs,
      });
    }
  }

  /**
   * Previous breadcrumb, useful for going "back" in navigation stack
   * @type {BreadcrumbDefinition}
   */
  get prevBreadcrumb() {
    return (
      this.breadcrumbs[this.breadcrumbs.length - 2] || this.breadcrumbs[this.breadcrumbs.length - 1]
    );
  }

  get userDisplayName() {
    return userDisplayName(this.principal && this.principal.user);
  }

  update(/** @type Store */ partial) {
    const priv = STORE_PRIVATE.get(this);

    if (!priv.update) {
      priv.update = {
        before: undefined,
        partials: undefined,
        timeout: setTimeout(() => this._onUpdated(), 0),
      };
    }

    if (priv.logger.enabled) {
      priv.update.partials = priv.update.partials || [];
      priv.update.partials.push(partial);
      if (!priv.update.before) {
        priv.update.before = Object.assign({}, this);
      }
    }

    merge(this, partial);
  }

  _onUpdated() {
    const priv = STORE_PRIVATE.get(this);
    if (!priv.update) {
      priv.logger.error(`Update data not available`);
      return;
    }

    priv.version++;

    if (priv.logger.enabled) {
      priv.logger.group(`Store v${priv.version}`, () => {
        priv.logger.log(LOGGER_COLORS.purple, `Before `, priv.update.before);
        priv.logger.log(LOGGER_COLORS.blue, `Partial`, ...priv.update.partials);
        priv.logger.log(LOGGER_COLORS.green, `After  `, { ...this });
      });
    }

    priv.update = null;

    for (const fn of priv.subscribers) {
      fn(priv.version);
    }
  }

  subscribe(fn) {
    STORE_PRIVATE.get(this).subscribers.push(fn);
  }
}
