import PropTypes from 'prop-types';
import React from 'react';

import { QUANTITY_SCALE_BY_INSTRUMENT } from '../../../lib/consts';
import NumericalValue from './NumericalValue';

const QuantityValue = ({ value, instrument, fixed }) => {
  const digits = QUANTITY_SCALE_BY_INSTRUMENT[instrument] || 8;
  return (
    <NumericalValue
      className={'QuantityValue'}
      value={value}
      digits={digits}
      suffix={instrument}
      fixed={fixed}
    />
  );
};

QuantityValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  instrument: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
};

export default QuantityValue;
