import './NestedExpandableObjectInfo.css';

import PropTypes from 'prop-types';
import React from 'react';

import { AppIcon, ICONS } from '../../../lib/icons';
import { beautifyKey, classNamer, classes, lodash } from '../../../lib/tools';
import Expander from '../../layout/Expander';
import ObjectInfo from './ObjectInfo';
import SmartFormatter from './SmartFormatter';

const cn = classNamer('NestedExpandableObjectInfo');

const NestedExpandableObjectInfo = ({
  className,
  object,
  beautifyKeys = true,
  keyWhitelistLookup,
  keyBlacklistLookup,
  formatters,
  nestDepth = 1,
  title,
  sectionTitles,
  memoryKey,
}) => {
  const items = [];
  for (const key of Object.keys(object)) {
    let value = object[key];

    if (lodash.isFunction(value)) {
      // Give caller a chance to do something custom
      value = value(object, key);
    }

    if (keyBlacklistLookup && keyBlacklistLookup[key] === false) {
      // Exclude this entire expander
      continue;
    }

    let content;
    if (React.isValidElement(value)) {
      // Allow passing react element directly
      content = value;
    } else if (lodash.isPlainObject(value)) {
      // Golden path
      content = (
        <ObjectInfo
          object={value}
          beautifyKeys={beautifyKeys}
          keyWhitelist={keyWhitelistLookup && keyWhitelistLookup[key]}
          keyBlacklist={keyBlacklistLookup && keyBlacklistLookup[key]}
          formatters={formatters && formatters[key]}
          nestDepth={nestDepth}
        />
      );
    } else {
      // Default to a smart format
      content = <SmartFormatter value={value} field={key} />;
    }

    const sectionTitle = (sectionTitles && sectionTitles[key]) || beautifyKey(key);

    items.push(
      <Expander
        key={key}
        memoryKey={memoryKey ? memoryKey + '$' + key : null}
        className={cn('item')}
        renderTitle={(open, onClick) => (
          <div className={classes(cn('title'), open && cn('title-open'))} onClick={onClick}>
            <AppIcon icon={open ? ICONS.minus : ICONS.plus} />
            {sectionTitle}
          </div>
        )}
      >
        {content}
      </Expander>
    );
  }
  return (
    <Expander title={title} memoryKey={memoryKey} className={classes(className, cn())}>
      {items}
    </Expander>
  );
};

NestedExpandableObjectInfo.propTypes = {
  memoryKey: PropTypes.string,
  title: PropTypes.string,

  className: PropTypes.string,
  object: PropTypes.object.isRequired,
  beautifyKeys: PropTypes.bool,
  keyWhitelistLookup: PropTypes.object,
  keyBlacklistLookup: PropTypes.object,
  formatters: PropTypes.object,
};

export default NestedExpandableObjectInfo;
