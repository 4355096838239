import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { classes } from '../../../lib/tools';
import Toggler from '../interactive/Toggler';

const CriteriaToggler = ({
  className,
  label,
  options,
  criteria,
  field,
  defaultValue,
  history,
  ...props
}) => {
  const selected = criteria[field] || defaultValue;
  className = classes(className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2">{label}</Label>
      <Toggler
        options={options}
        selected={selected}
        onChange={value => {
          history.push(
            Criteria.href(history, criteria, {
              [field]: value,
            })
          );
        }}
        {...props}
      />
    </div>
  );
};

CriteriaToggler.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  criteria: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

CriteriaToggler.Option = Toggler.Option;

export default withRouter(CriteriaToggler);
