import '../../../assets/world_flags_sprite/stylesheets/flags16.css';
import '../../../assets/world_flags_sprite/stylesheets/flags32.css';
import './CountryFlag.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../../lib/tools';

const CountryFlag = ({ isoCode, large = false, className }) => {
  if (!isoCode) {
    return null;
  }

  className = classes('CountryFlag', className, large ? 'f32' : 'f16');

  return (
    <span className={className}>
      <span className={classes('flag', isoCode.toLowerCase())} />
    </span>
  );
};

CountryFlag.propTypes = {
  isoCode: PropTypes.string,
  large: PropTypes.bool,
  className: PropTypes.string,
};

export default CountryFlag;
