import './ErrorBox.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'reactstrap';

import { classNamer, classes } from '../../../lib/tools';
import { BoundState } from '../../infrastructure/ConnectedComponent';

const cn = classNamer('ErrorBox');

class ErrorBox extends Component {
  static SIZES = {
    small: 'small',
    normal: 'normal',
    large: 'large',
  };

  static propTypes = {
    size: PropTypes.oneOf(Object.keys(ErrorBox.SIZES)),
    className: PropTypes.string,
    error: PropTypes.object,
    code: PropTypes.any,
    title: PropTypes.string,
    state: PropTypes.instanceOf(BoundState),
    info: PropTypes.string,
    onDismiss: PropTypes.func,
  };

  render() {
    const { state, size } = this.props;
    let { error, onDismiss, code, title, info, className } = this.props;

    if (state) {
      // Fill error and onDismiss from bound state
      error = error || state.error;
      onDismiss =
        onDismiss ||
        (() => {
          state.setState({
            error: null,
          });
        });
    }

    // Determine code and title
    code = code || (error && error.code) || 500;
    title = title || (error && error.message) || (error && String(error)) || null;

    className = classes(className, cn(), cn(size || ErrorBox.SIZES.normal));

    if (!info && error && error.errors && error.errors.length > 1) {
      info = (
        <ul>
          {error.errors.map((error, index) => (
            <li key={index}>{error.message || String(error)}</li>
          ))}
        </ul>
      );
    }

    return (
      <div className={className}>
        <Alert color="danger" toggle={onDismiss} isOpen={!!title}>
          <div className={cn('code')}>{code}</div>
          <div className={cn('title')}>{title}</div>
          <div className={cn('info')}>{info}</div>
          {this.props.children}
        </Alert>
      </div>
    );
  }
}

export default ErrorBox;
