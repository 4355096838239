import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import TransactionsCardPaymentsPage from './TransactionsCardPaymentsPage';
import TransactionsDepositsPage from './TransactionsDepositsPage';
import TransactionsTransfersPage from './TransactionsTransfersPage';
import TransactionsTransferTimelinePage from './TransactionsTransferTimelinePage';
import TransactionsWithdrawalsPage from './TransactionsWithdrawalsPage';

class TransactionsRouter extends ConnectedComponent {
  render() {
    return (
      <div className="TransactionsRouter">
        <Breadcrumbed link={SECTIONS.transactions}>
          <SubPager links={SECTIONS.transactions.subsections} />
          <Switch>
            <Route
              path={routes.TRANSACTIONS}
              render={() => <StickyRedirect routes={SECTIONS.transactions.subsections} />}
              exact
            />
            <Route path={routes.TRANSACTIONS_DEPOSITS} component={TransactionsDepositsPage} exact />
            <Route
              path={routes.TRANSACTIONS_WITHDRAWALS}
              component={TransactionsWithdrawalsPage}
              exact
            />
            <Route
              path={routes.TRANSACTIONS_CARD_PAYMENTS}
              component={TransactionsCardPaymentsPage}
              exact
            />
            <Route
              path={routes.TRANSACTIONS_TRANSFERS}
              component={TransactionsTransfersPage}
              exact
            />
            <Route
              path={routes.TRANSACTIONS_TRANSFER_TIMELINE}
              component={TransactionsTransferTimelinePage}
              exact
            />

            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default TransactionsRouter;
