import PropTypes from 'prop-types';
import React from 'react';

import { ROLES } from '../../../lib/backend';
import { ADMIN_PERMISSION_NAMES } from '../../../lib/consts';
import { AppIcon, ICONS } from '../../../lib/icons';
import BadgeList from '../../widgets/presentational/BadgeList';

const UserPermissionList = props => {
  /** @type {XcUser} */
  const user = props.user;

  if (user.role !== ROLES.admin) {
    return <span className="text-muted">N/A</span>;
  }

  if (!user.admin_permissions.length) {
    return (
      <span className="text-warning">
        <AppIcon icon={ICONS.warn} /> No permissions
      </span>
    );
  }

  return <BadgeList items={user.admin_permissions} labelLookup={ADMIN_PERMISSION_NAMES} />;
};

UserPermissionList.propTypes = {
  user: PropTypes.object,
};

export default UserPermissionList;
