import PropTypes from 'prop-types';
import React from 'react';

const WithdrawalLabel = ({ id }) => {
  return (
    <span>
      <strong>{id}</strong>
    </span>
  );
};

WithdrawalLabel.formatter = withdrawal => <WithdrawalLabel {...withdrawal} />;

WithdrawalLabel.propTypes = {
  id: PropTypes.any,
};

export default WithdrawalLabel;
