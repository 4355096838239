import PropTypes from 'prop-types';
import React from 'react';

import { KYC_ADMIN_ACTION_FILTERS } from '../../../lib/backend';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Select from '../../widgets/interactive/Select';
import KYCAdminActionLabel from './KYCAdminActionLabel';

const OPTIONS = Object.keys(KYC_ADMIN_ACTION_FILTERS);

export default class KYCAdminActionPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    selected: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    onChange: PropTypes.func,
    noOptionsMessage: PropTypes.func,
  };

  static defaultProps = {
    noOptionsMessage: () => 'There are no other admin actions',
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  static getDerivedStateFromProps({ selected }) {
    return {
      selected: selected ? (selected.includes(',') ? selected.split(',') : selected) : null,
    };
  }

  onChange = valueOrValues => {
    if (this.props.onChange) {
      this.props.onChange(valueOrValues || null);
    }
  };

  render() {
    return (
      <Select
        options={OPTIONS}
        onChange={this.onChange}
        value={this.state.selected}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={action => <KYCAdminActionLabel action={action} />}
        getOptionValue={action => action}
        noOptionsMessage={this.props.noOptionsMessage}
        isClearable
        // We know how many items, so let's not have scrollbars
        minMenuHeight={400}
        maxMenuHeight={500}
      />
    );
  }
}
