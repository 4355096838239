import React from 'react';

import { KYC_LEVEL2_METHODS } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';
import Time from '../../widgets/presentational/Time';

/**
 * @param {{customer: XcCustomerInfo}} props
 */
const CustomerKYCLetterStatus = props => {
  const ci = props.customer;

  if (!ci.kyc2_created_at) {
    // No need to render anything
    return null;
  }

  if (ci.kyc2_method !== KYC_LEVEL2_METHODS.letter_with_code) {
    // Not letter method
    return <span className="text-muted">N/A</span>;
  }

  if (ci.kyc2_letter_sent_at) {
    // Letter sent, show when
    return (
      <IconLabel flavor={true}>
        <Time value={ci.kyc2_letter_sent_at} />
      </IconLabel>
    );
  }

  if (ci.kyc2_completed_at) {
    // Letter was needed, but was never sent
    return (
      <IconLabel flavor={FLAVORS.muted} icon={ICONS.warn}>
        Never sent
      </IconLabel>
    );
  }

  // Letter needed, but not sent yet
  return (
    <IconLabel flavor={FLAVORS.warning} icon={ICONS.warn}>
      Not sent yet
    </IconLabel>
  );
};

export default CustomerKYCLetterStatus;
