import lodash from 'lodash';
import React from 'react';

import { CONTINENTS, COUNTRIES, US_STATES } from '../../../lib/countries';
import CheckboxGroup from '../../widgets/interactive/CheckboxGroup';

const CONTINENT_OPTIONS = Object.values(CONTINENTS);
const COUNTRY_OPTIONS = Object.values(COUNTRIES);
const US_STATE_OPTIONS = Object.values(US_STATES);

const CountryCheckboxGroups = ({ blacklist, onChange }) => {
  return CONTINENT_OPTIONS.map(continent => {
    const countries = COUNTRY_OPTIONS.filter(country => country.continent === continent.value);

    const checked =
      blacklist &&
      lodash.intersectionWith(blacklist, countries, (value, country) => value === country.value);

    const onCheck = values =>
      onChange(
        values.length > checked.length
          ? [...blacklist, ...lodash.difference(values, checked)]
          : lodash.difference(blacklist, lodash.difference(checked, values))
      );

    return (
      <CheckboxGroup
        key={continent.value}
        options={countries}
        checked={checked}
        onChange={values => onCheck(values)}
        label={continent.label}
      />
    );
  });
};

export const StatesCheckboxGroup = ({ blacklist, onChange }) => {
    const checked =
      blacklist &&
      lodash.intersectionWith(blacklist, US_STATE_OPTIONS, (value, state) => value === state.value);

    const onCheck = values =>
      onChange(
        values.length > checked.length
          ? [...blacklist, ...lodash.difference(values, checked)]
          : lodash.difference(blacklist, lodash.difference(checked, values))
      );

    return (
      <CheckboxGroup
        key={"USStates"}
        options={US_STATE_OPTIONS}
        checked={checked}
        onChange={values => onCheck(values)}
        label={"US States"}
      />
    );
};

export default React.memo(CountryCheckboxGroups);
