import PropTypes from 'prop-types';
import React from 'react';
import { Router } from 'react-router';
import { ToastContainer } from 'react-toastify';

import App from './App.js';
import ConnectedComponent from './infrastructure/ConnectedComponent';
import ErrorBoundary from './infrastructure/ErrorBoundary';

class Root extends ConnectedComponent {
  static propTypes = {
    container: PropTypes.object,
  };

  componentDidMount() {
    this.container.store.subscribe(() => {
      this.forceUpdate();
    });
  }

  render() {
    return (
      <ErrorBoundary>
        <Router history={this.props.container.history}>
          <App />
        </Router>
        <ToastContainer />
      </ErrorBoundary>
    );
  }
}

export default Root;
