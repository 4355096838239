import React from 'react';

import { FLAVORS, PHONE_VERIFICATION_TERMINATION_REASON_LABELS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const PhoneVerificationStatus = ({ completedAt, terminationReason }) => {
  if (!completedAt && !terminationReason) {
    // Still in progress
    return (
      <IconLabel flavor={FLAVORS.muted} icon={ICONS.wait}>
        ACTIVE
      </IconLabel>
    );
  }

  if (completedAt) {
    return <IconLabel flavor={true}>COMPLETED</IconLabel>;
  }

  return (
    <IconLabel flavor={false}>
      {PHONE_VERIFICATION_TERMINATION_REASON_LABELS[terminationReason]}
    </IconLabel>
  );
};

PhoneVerificationStatus.phoneVerificationInfoFormatter = (/** XcPhoneVerificationInfo */ pvi) => (
  <PhoneVerificationStatus
    completedAt={pvi.completed_at}
    terminationReason={pvi.termination_reason}
  />
);

export default PhoneVerificationStatus;
