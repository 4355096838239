import './ExternalResourceLink.css';

import PropTypes from 'prop-types';
import React from 'react';

import { AppIcon, ICONS } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('ExternalResourceLink');

const ExternalResourceLink = ({
  className,
  waiting,
  onClick,
  href,
  icon,
  children,
  wrap,
  ...rest
}) => {
  if (!icon && !children) {
    // If nothing is given, don't render anything at all
    return null;
  }

  const content = (
    <>
      {icon && <AppIcon icon={waiting ? ICONS.loader : icon} spin={waiting} fixedWidth={true} />}
      {children && <span>{children}</span>}
    </>
  );

  const finalClassName = classes(cn(), className, waiting && cn('waiting'), !wrap && cn('nowrap'));

  if (!onClick && !href) {
    // Unclickable variant
    return (
      <span className={finalClassName} {...rest}>
        {content}
      </span>
    );
  }

  // Clickable variant
  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick) {
          onClick(e);
        }
        if (href) {
          window.open(href, '_blank');
        }
      }}
      className={finalClassName}
      {...rest}
    >
      {content}
    </a>
  );
};

ExternalResourceLink.ICONS = ICONS;

ExternalResourceLink.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  waiting: PropTypes.bool,
};

export default ExternalResourceLink;
