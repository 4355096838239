import './Select.css';

import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('Select');

const Select = ({ async, className, width, ...rest }) => {
  className = classes(cn(), className);
  const SelectClass = async ? ReactSelectAsync : ReactSelect;
  const styles = width
    ? {
        container: () => ({
          minWidth: `${width}px`,
        }),
      }
    : undefined;

  return (
    <SelectClass
      style
      className={className}
      classNamePrefix={className}
      styles={styles}
      {...rest}
    />
  );
};

Select.propTypes = {
  width: PropTypes.number,
  async: PropTypes.bool,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  inputId: PropTypes.any,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  noOptionsMessage: PropTypes.any,
  isClearable: PropTypes.any,
};

export default Select;
