import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';
import Bind from '../../infrastructure/Bind';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';

const cn = classNamer('CriteriaFilter');

class CriteriaFilter extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    criteria: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: props.criteria.filter || '',
    };
  }

  setFilter(filter) {
    /** @type History */
    const history = this.props.history;

    history.push(
      Criteria.href(history, this.props.criteria, {
        filter,
      })
    );
  }

  onSubmit = e => {
    e.preventDefault();
    this.setFilter(this.state.filter);
  };

  onClear = () => {
    this.setFilter('');
  };

  render() {
    const className = classes(cn(), this.props.className);

    const clearBtn = this.props.criteria.filter && (
      <InputGroupAddon addonType="append">
        <Button color="danger" onClick={this.onClear}>
          <AppIcon icon={ICONS.clear} />
        </Button>
      </InputGroupAddon>
    );

    return (
      <Form className={className} onSubmit={this.onSubmit}>
        <InputGroup>
          <Bind state={this.boundState}>
            <Input name="filter" />
          </Bind>
          {clearBtn}
          <InputGroupAddon addonType="append">
            <Button color="primary" disabled={!this.state.filter}>
              Filter
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    );
  }
}

export default withRouter(CriteriaFilter);
