import PropTypes from 'prop-types';
import React from 'react';

const UserLabel = ({ id, email }) => {
  return (
    <span>
      <strong>{email}</strong> ({id})
    </span>
  );
};

UserLabel.formatter = user => <UserLabel {...user} />;
UserLabel.customerInfoFormatter = /** XcCustomerInfo */ ci => (
  <UserLabel id={ci.user_id} email={ci.email} />
);

UserLabel.propTypes = {
  id: PropTypes.any,
  email: PropTypes.string,
};

export default UserLabel;
