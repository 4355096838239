import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { KYC_TRACKS } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import UserLabel from './UserLabel';

export default class ResetKYCLevelModal extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    kycTrack: PropTypes.string.isRequired,
    onConfirmed: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  get isVQF() {
    return this.props.kycTrack === KYC_TRACKS.vqf;
  }

  get kycTrack() {
    return this.props.kycTrack.toUpperCase();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        isOpen: true,
      });
    }
  }

  cancel = () => {
    // Close, but keep the data, so we don't have funky jumps
    this.setState({
      isOpen: false,
    });
  };

  confirm = () => {
    this.cancel();
    this.props.onConfirmed(this.props.data);
  };

  render() {
    if (!this.props.data) {
      return null;
    }

    const { customer, targetLevel } = this.props.data;
    const currentLevel = this.isVQF ? customer.kyc_level_granted_vqf : customer.kyc_level_granted;

    const consequences = ['Any pending admin actions will be cleared.'];

    let actionLabel;
    if (currentLevel < targetLevel) {
      actionLabel = 'Upgrade to level ' + targetLevel;
      consequences.push(
        `Customer's ${this.kycTrack} KYC level will be upgraded to level ${targetLevel}`
      );
      if (targetLevel === 3) {
        consequences.push(`Any active KYC3 request will be closed and marked as granted.`);
      } else if (targetLevel >= 2) {
        consequences.push(`Any active KYC2 request will be closed and marked as granted.`);
      } else if (targetLevel >= 1) {
        consequences.push(`Any active KYC1 request will be closed and marked as granted.`);
      }
      if (this.isVQF) {
        consequences.push(`Customer will also be granted BASE KYC level 2`);
      }
    } else {
      if (currentLevel === targetLevel) {
        actionLabel = 'Reset to level ' + targetLevel;
        consequences.push(
          `Customer's ${this.kycTrack} KYC level will remain the same as they are now (level ${currentLevel})`
        );
      } else {
        actionLabel = 'Downgrade to level ' + targetLevel;
        consequences.push(
          `Customer's ${this.kycTrack} KYC level will be downgraded to level ${targetLevel}`
        );
      }

      if (targetLevel <= 1) {
        consequences.push(`Any active KYC2 request will be closed and marked as refused`);
      }
      if (targetLevel <= 0) {
        consequences.push(`Any active KYC1 request will be closed and marked as refused`);
      }
      if (!this.isVQF && customer.kyc_level_granted_vqf) {
        consequences.push(`Customer's VQF KYC level will be downgraded to 0`);
      }
    }

    return (
      <Modal isOpen={this.state.isOpen} toggle={this.cancel}>
        <ModalHeader toggle={this.cancel}>Reset KYC Level</ModalHeader>
        <ModalBody>
          <p>
            You are about to reset {this.kycTrack} KYC state of{' '}
            <UserLabel id={customer.user_id} email={customer.email} /> to level{' '}
            <strong>{targetLevel}</strong>
          </p>
          <p>This action will have the following consequences:</p>
          <ul>
            {consequences.map((str, index) => (
              <li key={index}>{str}</li>
            ))}
          </ul>
          <p className="text-danger">
            <strong>
              Changing KYC level without proper process is a dangerous operation and should be
              considered the last resort.
            </strong>
          </p>
          <p>Are you sure you wish to proceed?</p>
        </ModalBody>
        <ModalFooter>
          <Button className="mr-2" color={FLAVORS.danger} onClick={this.confirm}>
            {actionLabel}
          </Button>
          <Button color="secondary" onClick={this.cancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
