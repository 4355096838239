import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { ADMIN_PERMISSIONS, XcSetWithdrawalApprovedPayload } from '../../lib/backend';
import { FLAVORS, WITHDRAWAL_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaUserPicker from '../users/widgets/CriteriaUserPicker';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaInstrumentPicker from './CriteriaInstrumentPicker';
import WithdrawalLabel from './widgets/WithdrawalLabel';

const BASE_CRITERIA = {
  sort_field: WITHDRAWAL_KEYS.created_at,
  sort_direction: 'desc',
  user_id: undefined,
  instruments: undefined,
};

const COLUMNS = [
  new DataTableColumn(WITHDRAWAL_KEYS.id, 'ID'),
  new DataTableColumn(WITHDRAWAL_KEYS.transaction_id, 'Transaction ID'),

  new DataTableColumn(WITHDRAWAL_KEYS.user_id, 'User ID'),

  new DataTableColumn(WITHDRAWAL_KEYS.instrument, 'Instrument'),
  new DataTableColumn(WITHDRAWAL_KEYS.quantity, 'Quantity'),

  new DataTableColumn(WITHDRAWAL_KEYS.fee_abs, 'Fee abs'),
  new DataTableColumn(WITHDRAWAL_KEYS.fee_pct, 'Fee pct'),
  new DataTableColumn(WITHDRAWAL_KEYS.fee_total, 'Fee total'),

  new DataTableColumn(WITHDRAWAL_KEYS.created_at, 'Created at'),
  new DataTableColumn(WITHDRAWAL_KEYS.confirmed_at, 'Confirmed at'),
  new DataTableColumn(WITHDRAWAL_KEYS.approved_at, 'Approved at'),
  new DataTableColumn(WITHDRAWAL_KEYS.taken_at, 'Taken at'),
  new DataTableColumn(WITHDRAWAL_KEYS.completed_at, 'Completed at'),
  new DataTableColumn(WITHDRAWAL_KEYS.cancelled_at, 'Cancelled at'),
];

const cn = classNamer('TransactionsWithdrawalsPage');

class TransactionsWithdrawalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,

      selection: [],
      settingWithdrawalApprovedSelection: null,
      settingWithdrawalApprovedValue: null,
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getWithdrawals(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  setWithdrawalsApproved = approved => {
    this.setState({
      settingWithdrawalApprovedSelection: this.state.selection.filter(
        index =>
          (approved && !this.state.data.items[index].approved_at) ||
          (!approved && !!this.state.data.items[index].approved_at)
      ),
      settingWithdrawalApprovedValue: approved,
    });
  };

  confirmSetWithdrawalsApproved = selection => {
    const payload = new XcSetWithdrawalApprovedPayload({
      withdrawal_ids: selection.map(index => this.state.data.items[index].id),
      approved: this.state.settingWithdrawalApprovedValue,
    });

    return this.promiseOrToast(this.container.client.putWithdrawalsSetApproved(payload)).then(
      () => {
        const action = payload.approved ? 'approved' : 'not approved';
        toast.success(
          payload.withdrawal_ids.length > 1
            ? `${payload.withdrawal_ids.length} withdrawals marked as ${action}`
            : `Withdrawal ${payload.withdrawal_ids[0]} marked as ${action}`
        );
        this.loadData();
      }
    );
  };

  renderWithdrawalApproval = () => {
    if (!this.container.auth.hasPermission(ADMIN_PERMISSIONS.developer)) {
      return null;
    }

    let eligibleApprove = 0;
    let eligibleRevoke = 0;
    for (const index of this.state.selection) {
      if (this.state.data.items[index].approved_at) {
        eligibleRevoke++;
      } else {
        eligibleApprove++;
      }
    }

    return (
      <Expander title="Withdrawal approval" memoryKey={cn('withdrawal_approval')}>
        <div className="d-flex">
          <IconButton
            title="Mark withdrawal as approved"
            color={FLAVORS.primary}
            onClick={() => this.setWithdrawalsApproved(true)}
            icon={ICONS.check}
            className="mr-1 flex-fill"
            disabled={eligibleApprove === 0}
          >
            Approve
          </IconButton>
          <IconButton
            title="Mark withdrawal as not approved, waiting to be approved"
            color={FLAVORS.danger}
            onClick={() => this.setWithdrawalsApproved(false)}
            icon={ICONS.backward}
            className="flex-fill"
            disabled={eligibleRevoke === 0}
          >
            Revoke
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcWithdrawal} */
    const wd = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Selected" memoryKey={cn('selected-withdrawal')}>
          <ObjectInfo object={wd} />
        </Expander>
        {this.renderWithdrawalApproval()}
      </div>
    );
  };

  renderSidebarMany = () => {
    return this.renderWithdrawalApproval();
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed
          link={SECTIONS.transactions.subsectionAtRoute(routes.TRANSACTIONS_WITHDRAWALS)}
        />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                  <CriteriaInstrumentPicker criteria={this.state.criteria} isMulti />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getWithdrawalsCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
        <BoundDangerModal
          state={this.boundState}
          formatItem={WithdrawalLabel.formatter}
          onConfirmed={this.confirmSetWithdrawalsApproved}
          typeSingular="withdrawal"
          actionText={this.state.settingWithdrawalApprovedValue ? 'approve' : 'revoke'}
          buttonActionText={this.state.settingWithdrawalApprovedValue ? 'Approve' : 'Revoke'}
          actionKey="settingWithdrawalApprovedSelection"
        />
      </PageLayout>
    );
  }
}

export default withRouter(TransactionsWithdrawalsPage);
