import './TradingTradesPage.css';

import React from 'react';
import { withRouter } from 'react-router';

import { TRADE_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaUserPicker from '../users/widgets/CriteriaUserPicker';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import QuantityValue from '../widgets/presentational/QuantityValue';
import Time from '../widgets/presentational/Time';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaOrderTypePicker from './CriteriaOrderTypePicker';
import CriteriaPairPicker from './CriteriaPairPicker';

const BASE_CRITERIA = {
  sort_field: TRADE_INFO_KEYS.timestamp,
  sort_direction: 'desc',
  pair: undefined,
  user_id: undefined,
  type: undefined,
};

const COLUMNS = [
  new DataTableColumn(TRADE_INFO_KEYS.id, 'ID'),
  new DataTableColumn(TRADE_INFO_KEYS.pair, 'Pair'),

  new DataTableColumn(TRADE_INFO_KEYS.matcher_type, 'Matcher type'),
  new DataTableColumn(TRADE_INFO_KEYS.matcher_fee, 'Matcher fee'),
  new DataTableColumn(TRADE_INFO_KEYS.matcher_quantity, 'Matcher quantity'),

  new DataTableColumn(TRADE_INFO_KEYS.matchee_fee, 'Matchee fee'),
  new DataTableColumn(TRADE_INFO_KEYS.matchee_quantity, 'Matchee quantity'),

  new DataTableColumn(TRADE_INFO_KEYS.side, 'Side'),
  new DataTableColumn(TRADE_INFO_KEYS.price, 'Price'),
  new DataTableColumn(TRADE_INFO_KEYS.quantity, 'Quantity'),
  new DataTableColumn(TRADE_INFO_KEYS.timestamp, 'Timestamp', trade => (
    <Time format={Time.FORMATS.precise} value={trade.timestamp} />
  )),
  new DataTableColumn(TRADE_INFO_KEYS.revenue, 'Revenue'),
];

const AVERAGE_KEYS = [
  TRADE_INFO_KEYS.matcher_fee,
  TRADE_INFO_KEYS.matchee_fee,
  TRADE_INFO_KEYS.price,
];

const cn = classNamer('TradingTradesPage');

class TradingTradesPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      statistics: null,

      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    const { criteria } = this.state;
    const { getExchangeTrades, getExchangeStatistics } = this.container.client;

    this.promiseOrToast(
      Promise.all([getExchangeTrades(criteria), getExchangeStatistics(criteria)]).then(
        ([data, statistics]) => {
          this.setState({ data, statistics, selection: [] });
        }
      )
    );
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcTradeInfo} */
    const ti = this.state.data.items[selectedIndex];
    return (
      <Expander title="Selected - info" memoryKey={cn('selected-info')}>
        <ObjectInfo object={ti} />
      </Expander>
    );
  };

  renderSidebarMany = () => {
    const { data, selection, criteria } = this.state;
    const selected = data.items.filter((item, index) => selection.includes(index));
    const pairs = (criteria.pair && criteria.pair.split(',')) || [
      ...new Set(selected.map(item => item.pair)),
    ];

    return pairs.map(pair => {
      const [quote, base] = pair.split('_');
      const items = selected.filter(item => item.pair === pair);

      const sum = {
        sum_quantity: (
          <QuantityValue
            value={items.reduce((acc, item) => acc + Number(item.quantity), 0)}
            instrument={quote}
          />
        ),
        sum_volume: (
          <QuantityValue
            value={items.reduce((acc, item) => acc + Number(item.quantity) * Number(item.price), 0)}
            instrument={base}
          />
        ),
        sum_revenue: (
          <QuantityValue
            value={items.reduce((acc, item) => acc + Number(item.revenue), 0)}
            instrument={base}
          />
        ),
      };

      const avg = {};

      AVERAGE_KEYS.forEach(averageKey => {
        avg[`avg_${averageKey}`] = (
          <QuantityValue
            value={items.reduce((acc, item) => acc + Number(item[averageKey]), 0) / items.length}
            instrument={base}
          />
        );
      });

      return (
        <Expander
          key={pair}
          title={`Selected - ${items.length} ${pair}`}
          memoryKey={cn(`selected-${pair}`)}
        >
          <ObjectInfo object={sum} />
          <ObjectInfo object={avg} />
        </Expander>
      );
    });
  };

  renderStatistics = () => {
    const { statistics } = this.state;

    if (!statistics) {
      return null;
    }

    return statistics.map(statistic => {
      const { pair, count, ...rest } = statistic;
      const [quote, base] = pair.split('_');

      const stats = {
        sum_quantity: <QuantityValue value={rest.sum_quantity} instrument={quote} />,
        sum_volume: <QuantityValue value={rest.sum_volume} instrument={base} />,
        sum_revenue: <QuantityValue value={rest.sum_revenue} instrument={base} />,
        avg_matcher_fee: <QuantityValue value={rest.avg_matcher_fee} instrument={base} />,
        avg_matchee_fee: <QuantityValue value={rest.avg_matchee_fee} instrument={base} />,
        avg_price: <QuantityValue value={rest.avg_price} instrument={base} />,
      };

      return (
        <Expander key={pair} title={`All - ${count} ${pair}`} memoryKey={cn(`all-${pair}`)}>
          <ObjectInfo object={stats} />
        </Expander>
      );
    });
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.trading.subsectionAtRoute(routes.TRADING_TRADES)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <div>
              {this.renderStatistics()}
              <SelectionSidebar
                selection={this.state.selection}
                renderOne={this.renderSidebarOne}
                renderMany={this.renderSidebarMany}
              />
            </div>
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaOrderTypePicker criteria={this.state.criteria} />
                  <CriteriaPairPicker criteria={this.state.criteria} />
                  <CriteriaUserPicker criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getExchangeTradesCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TradingTradesPage);
