import './CriteriaPairPicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../lib/criterias';
import { classNamer, classes } from '../../lib/tools';
import PairPicker from './PairPicker';

const cn = classNamer('CriteriaPairPicker');

const CriteriaPairPicker = ({
  criteria,
  className,
  label = 'For pair:',
  pairField = 'pair',
  inputId = cn('id'),
  history,
  placeholder = 'All pairs',
  ...rest
}) => {
  const handleChange = pair => {
    const nextCriteria = { ...criteria };
    if (pair) {
      nextCriteria[pairField] = pair;
    } else {
      delete nextCriteria[pairField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <PairPicker
        selected={criteria[pairField]}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

CriteriaPairPicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  pairField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
};

export default withRouter(CriteriaPairPicker);
