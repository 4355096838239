import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { classes } from '../../../lib/tools';

function getFormattedDateFromProps({ date, dateFormat }) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return formatDate(date, dateFormat);
}

class DateInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    date: PropTypes.any,
    dateFormat: PropTypes.string,
    onChange: PropTypes.func,
    setCurrentLabel: PropTypes.string,
  };

  static defaultProps = {
    dateFormat: 'yyyy/MM/dd',
    setCurrentLabel: 'Today',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: getFormattedDateFromProps(props),
      invalid: false,
    };

    if (props.date) {
      this._lastValidDate = props.date;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date === this.props.date) {
      return;
    }

    if (
      this._lastValidDate &&
      this.props.date &&
      this.props.date.valueOf() === this._lastValidDate.valueOf()
    ) {
      return;
    }

    const value = getFormattedDateFromProps(this.props);
    if (value === this.state.value) {
      return;
    }

    this.setState({
      value,
      invalid: false,
    });
  }

  handleSetCurrentClick = () => {
    const now = new Date();
    this.setState({ value: new Date() });
    this.props.onChange(now);
  };

  onChange = e => {
    const value = e.target.value;
    if (!value) {
      return this.setState({ value, invalid: true });
    }

    const date = value ? parseDate(value, this.props.dateFormat, new Date(0)) : null;
    const invalid = isNaN(date.getTime());
    this.setState({
      value,
      invalid,
    });

    if (!invalid) {
      this._lastValidDate = date;
      this.props.onChange(date);
    }
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { className, date, onChange, dateFormat, setCurrentLabel, ...rest } = this.props;

    return (
      <InputGroup>
        <Input
          className={classes('DateInput', className)}
          type="text"
          value={this.state.value}
          onChange={this.onChange}
          invalid={this.state.invalid}
          {...rest}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={this.handleSetCurrentClick}>{setCurrentLabel}</Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default DateInput;
