import React from 'react';

import { KYC_ADMIN_ACTIONS } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

/**
 * @param {{record: XcCustomerInfo | XcKYCMicropaymentInfo}} props
 */
const MicropaymentAuditStatus = ({ record }) => {
  if (!record.audit_performed_at) {
    if (record.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc3_audit) {
      return (
        <IconLabel
          flavor={FLAVORS.info}
          icon={ICONS.wait}
          title={'Audit is pending for a compliance officir to perform'}
        >
          AUDIT PENDING
        </IconLabel>
      );
    }

    return (
      <IconLabel
        flavor={FLAVORS.muted}
        icon={ICONS.pause}
        title={'Audit has not been performed and it is not scheduled to be performed'}
      >
        STALLED
      </IconLabel>
    );
  }

  const flavor = !!record.audit_result;
  return record.audit_result_notes ? (
    <IconLabel flavor={flavor} wrap title={'Audit has failed, compliance officir has left notes'}>
      {record.audit_result_notes}
    </IconLabel>
  ) : (
    <IconLabel flavor={flavor} title={'Audit has passed'}>
      PASSED
    </IconLabel>
  );
};

MicropaymentAuditStatus.customerInfoFormatter = (/** XcCustomerInfo */ ci) =>
  ci.kyc3_micropayment_id && ci.micropayment_audit_id ? (
    <MicropaymentAuditStatus record={ci} />
  ) : null;

MicropaymentAuditStatus.kycMicropaymentInfoFormatter = (/** XcKYCMicropaymentInfo */ kmi) =>
  kmi.is_current ? <MicropaymentAuditStatus record={kmi} /> : null;

export default MicropaymentAuditStatus;
