import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FLAVORS, MANAGED_PARTNER_TRADE_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const COLUMNS = [
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.order_id, 'Order ID'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.buy_instrument, 'Instrument'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.buy_quantity, 'Quantity'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.sell_instrument, 'Instrument'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.sell_quantity, 'Quantity'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.partner_exchange, 'Exchange'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.trade_id, 'Trade ID'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.fee_total, 'Fee'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.executed_at, 'Executed at'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.created_at, 'Created at'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.updated_at, 'Updated at'),
];

const GROUPS = [
  new DataTableGroup('Buy', 2, 3),
  new DataTableGroup('Sell', 4, 5),
  new DataTableGroup('Partner', 6, 8),
];

const cn = classNamer('ManagedPartnerTradesPage');

class ManagedPartnerTradesPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],
      deleting: null,
      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBrokerPartnerTrades(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  deleteSelectedTrades = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const keys = selection.map(index => this.state.data.items[index].id);
    const promise = this.container.client.deleteBrokerPartnerTrades(keys);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        keys.length > 1 ? `${keys.length} partner trades deleted` : `Partner trade deleted`
      );
      this.loadData();
    });
  };

  renderPartnerTrade = trade => {
    if (!trade) {
      return null;
    }
    return (
      <span>
        <strong>{trade.id}</strong> (part of order {trade.order_id})
      </span>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcManagedPartnerTrade} */
    const trade = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title="Edit trade"
            color={FLAVORS.primary}
            to={routes.managedPartnerTradesEdit(trade.id)}
            icon={ICONS.edit}
            className="mr-2"
          >
            Edit
          </IconButton>
          <IconButton
            title="Delete trade"
            color={FLAVORS.danger}
            onClick={this.deleteSelectedTrades}
            icon={ICONS.delete}
            className="mr-2"
          >
            Delete
          </IconButton>
        </Expander>

        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo object={trade} />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = () => {
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            title="Delete trades"
            color="danger"
            onClick={this.deleteSelectedTrades}
            icon={ICONS.delete}
          >
            Delete
          </IconButton>
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <IconButton
                    tag={Link}
                    color="success"
                    icon={ICONS.new}
                    to={routes.managedPartnerTradesNew()}
                  >
                    Add trade
                  </IconButton>
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getBrokerPartnerTradesCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderPartnerTrade}
          onConfirmed={this.confirmDelete}
          typeSingular="partner trade"
        />
      </PageLayout>
    );
  }
}

export default withRouter(ManagedPartnerTradesPage);
