import './ObjectInfo.css';

import PropTypes from 'prop-types';
import React from 'react';

import { beautifyKey, classNamer, classes, lodash } from '../../../lib/tools';
import SmartFormatter from './SmartFormatter';

const cn = classNamer('ObjectInfo');

/**
 * @param {string[]|object} keyWhitelist
 * @param {string[]|object} keyBlacklist
 * @param {string} key
 */
export function shouldIncludeKey(keyWhitelist, keyBlacklist, key) {
  let whitelisted = true;
  if (keyWhitelist) {
    if (keyWhitelist.includes) {
      whitelisted = keyWhitelist.includes(key);
    } else {
      whitelisted = key in keyWhitelist;
    }
  }
  if (keyBlacklist) {
    if (keyBlacklist.includes) {
      whitelisted = whitelisted && !keyBlacklist.includes(key);
    } else {
      whitelisted = whitelisted && !(key in keyBlacklist);
    }
  }
  return whitelisted;
}

const ObjectInfo = ({
  className,
  object,
  beautifyKeys = true,
  keyWhitelist,
  keyBlacklist,
  formatters,
  nestDepth = 1,
}) => {
  const items = [];
  for (const key in object) {
    if (!object.hasOwnProperty(key)) {
      continue;
    }

    if (!shouldIncludeKey(keyWhitelist, keyBlacklist, key)) {
      continue;
    }

    const displayKey = beautifyKeys ? beautifyKey(key) : key;

    let value = object[key];
    if (formatters && formatters[key]) {
      value = formatters[key](value, key, object);
    }

    let valueEl;
    if (lodash.isFunction(value)) {
      valueEl = value(key, object);
    } else if (React.isValidElement(value)) {
      valueEl = value;
    } else if (lodash.isPlainObject(value) && nestDepth >= 1) {
      // Nest plain objects
      valueEl = <ObjectInfo beautifyKeys={beautifyKeys} object={value} nestDepth={nestDepth - 1} />;
    } else {
      valueEl = <SmartFormatter value={value} field={key} />;
    }

    items.push(
      <div className={cn('prop')} key={key}>
        <div className={cn('key')}>
          <span>{displayKey}</span>
        </div>
        <div className={cn('value')}>{valueEl}</div>
      </div>
    );
  }

  return <div className={classes(className, cn())}>{items}</div>;
};

ObjectInfo.propTypes = {
  className: PropTypes.string,
  beautifyKeys: PropTypes.bool,
  object: PropTypes.object,
  keyWhitelist: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  keyBlacklist: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  formatters: PropTypes.object,
};

export default ObjectInfo;
