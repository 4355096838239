import './MainMenu.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';

import logoSrc from '../../assets/xcalibra_black_on_green.png';
import { AppIcon, ICONS } from '../../lib/icons';
import { SECTION_LIST } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import MainMenuSubmenu from './MainMenuSubmenu';

const cn = classNamer('MainMenu');

class MainMenu extends ConnectedComponent {
  static propTypes = {
    section: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  logOut = () => {
    this.container.auth.logOut();
  };

  renderMenuLink = (route, title, icon, neededPermissions) => {
    if (neededPermissions && !this.container.auth.hasPermission(neededPermissions)) {
      return;
    }

    const isActive = this.props.section
      ? route === this.props.section
      : route === '/'
      ? this.props.location.pathname === '/'
      : this.props.location.pathname.indexOf(route) === 0;

    return (
      <NavItem className="btn p-0" active={isActive} key={route}>
        <NavLink tag={Link} to={route} title={title}>
          <AppIcon icon={icon} />
          <span className={classes('ml-1', cn('item-title'))}>{title}</span>
        </NavLink>
      </NavItem>
    );
  };

  renderMenuToggler = (route, title, icon, neededPermissions) => {
    if (neededPermissions && !this.container.auth.hasPermission(neededPermissions)) {
      return;
    }

    return (
      <NavLink className="p-0 d-flex align-items-center" tag={Link} to={route} title={title}>
        <AppIcon icon={icon} />
        <span className={classes('ml-1', cn('item-title'))}>{title}</span>
      </NavLink>
    );
  };

  renderSubMenuLink = (route, title, neededPermissions) => {
    if (neededPermissions && !this.container.auth.hasPermission(neededPermissions)) {
      return;
    }

    return (
      <Link className="d-flex" to={route} title={title}>
        <span className="ml-1">{title}</span>
      </Link>
    );
  };

  renderNavigation() {
    if (!this.container.store.principal) {
      return null;
    }

    const sectionLinks = SECTION_LIST.map(s => {
      if (!s.subsections.length) {
        // If there are no subsections, render just the link
        return this.renderMenuLink(s.route, s.title, s.icon, s.permissions);
      }

      if (s.permissions && !this.container.auth.hasPermission(s.permissions)) {
        return null;
      }

      const menuItems = [];
      for (const ss of s.subsections) {
        if (ss.permissions && !this.container.auth.hasPermission(ss.permissions)) {
          continue;
        }

        menuItems.push(
          <Link
            className={classes(
              'd-flex',
              ss.route === this.props.location.pathname && cn('active-submenu-item')
            )}
            to={ss.route}
            title={ss.title}
            key={ss.route}
          >
            <span className="ml-1">{ss.title}</span>
          </Link>
        );
      }

      if (!menuItems.length) {
        // Render just a menu link after all
        return this.renderMenuLink(s.route, s.title, s.icon, s.permissions);
      }

      return (
        <MainMenuSubmenu
          nav
          key={s.route}
          toggler={this.renderMenuToggler(s.route, s.title, s.icon, s.permissions)}
          items={menuItems}
          active={s.route === this.props.section}
        />
      );
    });

    return (
      <Nav className={cn('navigation')} navbar>
        {sectionLinks}
      </Nav>
    );
  }

  renderUserMenu() {
    if (!this.container.store.principal) {
      return (
        <Nav className={cn('user')} navbar>
          {this.renderMenuLink(routes.LOGIN, 'Login', ICONS.login)}
        </Nav>
      );
    }

    return (
      <Nav className={cn('user')} navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <AppIcon icon={ICONS.user} className="mr-2" /> {this.container.store.userDisplayName}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={this.logOut}>
              <AppIcon icon={ICONS.logout} className="mr-2" /> Log out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
  }

  render() {
    return (
      <div className={cn()}>
        <Navbar color="primary" dark={true} expand="md">
          <NavbarBrand tag={Link} to={routes.HOME}>
            <img src={logoSrc} alt="Logo" className="d-inline-block" style={{ height: '30px' }} />
            <h4 className="ml-2 d-inline-block mb-0 align-middle font-italic">Xadmin</h4>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {this.renderNavigation()}
            {this.renderUserMenu()}
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(MainMenu);
