import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import ActivityInternalsPage from './ActivityInternalsPage';
import AppInternalsPage from './AppInternalsPage';
import DocumentInternalsPage from './DocumentInternalsPage';
import ExchangeInternalsPage from './ExchangeInternalsPage';
import LoggerInternalsPage from './LoggerInternalsPage';
import MailchimpInternalsPage from './MailchimpInternalsPage';
import MailerInternalsPage from './MailerInternalsPage';
import MaintenanceInternalsPage from './MaintenanceInternalsPage';
import MarketInternalsPage from './MarketInternalsPage';
import WalletInternalsPage from './WalletInternalsPage';

class InternalsRouter extends ConnectedComponent {
  render() {
    return (
      <div className="InternalsRouter">
        <Breadcrumbed link={SECTIONS.internals}>
          <SubPager links={SECTIONS.internals.subsections} />
          <Switch>
            <Route
              path={routes.INTERNALS}
              render={() => <StickyRedirect routes={SECTIONS.internals.subsections} />}
              exact
            />
            <Route path={routes.INTERNALS_APP} component={AppInternalsPage} exact />
            <Route path={routes.INTERNALS_EXCHANGE} component={ExchangeInternalsPage} exact />
            <Route path={routes.INTERNALS_MARKETS} component={MarketInternalsPage} exact />
            <Route path={routes.INTERNALS_MAILER} component={MailerInternalsPage} exact />
            <Route path={routes.INTERNALS_MAILCHIMP} component={MailchimpInternalsPage} exact />
            <Route path={routes.INTERNALS_LOGGER} component={LoggerInternalsPage} exact />
            <Route path={routes.INTERNALS_WALLETS} component={WalletInternalsPage} exact />
            <Route path={routes.INTERNALS_ACTIVITY} component={ActivityInternalsPage} exact />
            <Route path={routes.INTERNALS_DOCUMENTS} component={DocumentInternalsPage} exact />
            <Route path={routes.INTERNALS_MAINTENANCE} component={MaintenanceInternalsPage} exact />
            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default InternalsRouter;
