import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../../lib/tools';

let _LAST_ID = 0;

export default class ThreeStateCheckbox extends React.Component {
  static propTypes = {
    id: PropTypes.any,
    checked: PropTypes.bool,
    inline: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.id = 'ThreeStateCheckbox' + ++_LAST_ID;

    this.updateInput();
  }

  componentDidUpdate() {
    this.updateInput();
  }

  updateInput = () => {
    if (this.input) {
      this.input.indeterminate = this.props.indeterminate === true || this.props.checked === null;
    }
  };

  handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const id = this.props.id || this.id;

    const className = classes(
      'ThreeStateCheckbox',
      this.props.className,
      'custom-checkbox',
      'custom-control',
      this.props.inline && 'custom-control-inline'
    );

    return (
      <div className={className}>
        <input
          type="checkbox"
          id={id}
          className="custom-control-input"
          ref={input => {
            this.input = input;
            this.updateInput();
          }}
          onChange={this.handleChange}
          checked={this.props.checked === true}
        />
        <label className="custom-control-label" htmlFor={id} title={this.props.title}>
          {this.props.children}
        </label>
      </div>
    );
  }
}
