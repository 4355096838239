import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const User2FAStatus = props => {
  const { enabled } = props;
  return enabled ? (
    <IconLabel icon={ICONS.lock} flavor={FLAVORS.success}>
      On
    </IconLabel>
  ) : (
    <IconLabel icon={ICONS.unlock} flavor={FLAVORS.warning}>
      Off
    </IconLabel>
  );
};

export default User2FAStatus;
