import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const PhoneNumberLink = ({ phone, verified }) => {
  if (!phone) {
    return null;
  }

  const telHref = 'tel:' + phone;

  if (typeof verified !== 'boolean') {
    return <a href={telHref}>{phone}</a>;
  }

  return verified ? (
    <a href={telHref} className="text-success">
      <IconLabel flavor={true} title={'Verified phone number'}>
        {phone}
      </IconLabel>
    </a>
  ) : (
    <a href={telHref} className="text-muted">
      <IconLabel flavor={FLAVORS.muted} icon={ICONS.warn} title={'Unverified phone number'}>
        {phone}
      </IconLabel>
    </a>
  );
};

export default PhoneNumberLink;
