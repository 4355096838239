import React from 'react';
import { toast } from 'react-toastify';

import { FLAVORS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import { Card } from '../widgets/presentational/Card';

const cn = classNamer('MaintenanceInternalsPage');

class MaintenanceInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      cancelResult: null,
      updateResult: null,
    };
  }

  updateCustomerReferenceNumbers = () => {
    return this.promiseOrToast(
      this.container.client.putAppMaintenanceUpdateMicropaymentReferenceNumber()
    ).then(data => {
      toast.success(`Updated ${data.updated} out of ${data.total} customer records`);
    });
  };

  cancelAllKYC0Orders() {
    return this.promiseOrToast(this.container.client.putExchangeCancelKyc0Order()).then(
      cancelResult => {
        this.setState({
          cancelResult,
        });
      }
    );
  }

  cancelUnverifiedPhoneOrders() {
    return this.promiseOrToast(this.container.client.putExchangeCancelUnverifiedPhoneOrder()).then(
      cancelResult => {
        this.setState({
          cancelResult,
        });
      }
    );
  }

  cancelUSCustomerOrders() {
    return this.promiseOrToast(this.container.client.putKycCancelBlacklistedUsCustomerOrder()).then(
      cancelResult => {
        this.setState({
          cancelResult,
        });
      }
    );
  }

  fillUSCustomerStates() {
    return this.promiseOrToast(this.container.client.putCustomersUsCustomersFillState()).then(
      updateResult => {
        this.setState({
          updateResult,
        });
      }
    );
  }

  resetUSCustomersKYC() {
    return this.promiseOrToast(this.container.client.putKycResetUsCustomer()).then(data => {
      toast.success(`Updated ${data.updated} out of ${data.total} customer records`);
    });
  }

  cancelAllRsdOrders() {
    return this.promiseOrToast(this.container.client.putExchangeCancelRsdOrder()).then(
      cancelResult => {
        this.setState({
          cancelResult,
        });
      }
    );
  }

  renderUpdateResult() {
    if (!this.state.updateResult) {
      return null;
    }

    const total = Object.keys(this.state.updateResult).length;

    return (
      <div>
        <hr />
        <div className="p-4">
          <h3>Update Results</h3>
          <h5>Below are the updated customer records</h5>
        </div>
        <div className="p-4 jumbotron jumbotron-fluid">
          <h5>
            Total customers: <strong>{total}</strong>
          </h5>
        </div>
        <div className="p-4">
          <h3>Raw data</h3>
          <pre>{JSON.stringify(this.state.updateResult, null, '  ')}</pre>
        </div>
      </div>
    );
  }

  renderCancelResult() {
    if (!this.state.cancelResult) {
      return null;
    }

    let total = 0;
    const byPair = {};

    const userEls = Object.keys(this.state.cancelResult).map(userId => {
      const items = this.state.cancelResult[userId];
      total += items.length;

      const rows = [];
      for (const item of items) {
        const [quote, base] = item.pair.split('_');
        byPair[item.pair] = byPair[item.pair] || {
          pair: item.pair,
          base: {
            instrument: base,
            buyQuantity: 0,
            sellQuantity: 0,
          },
          quote: {
            instrument: quote,
            buyQuantity: 0,
            sellQuantity: 0,
          },
          count: 0,
        };
        const quantityProp = item.side === 'buy' ? 'buyQuantity' : 'sellQuantity';
        byPair[item.pair].quote[quantityProp] += Number(item.quantity);
        byPair[item.pair].base[quantityProp] += Number(item.quantity) * Number(item.price);
        byPair[item.pair].count++;

        rows.push(
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.type}</td>
            <td>{item.side}</td>
            <td>{item.pair}</td>
            <td>{item.quantity}</td>
            <td>{item.price}</td>
            <td>{item.timestamp}</td>
          </tr>
        );
      }

      return (
        <div key={userId} className="py-2">
          <h4>{userId}</h4>
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Side</th>
                <th>Pair</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      );
    });

    return (
      <div>
        <hr />
        <div className="p-4">
          <h3>Results</h3>
          <h5>Below are the orders that were cancelled per user</h5>
        </div>
        <div className="p-4">{userEls}</div>
        <div className="p-4 jumbotron jumbotron-fluid">
          <h5>
            Total orders: <strong>{total}</strong>
          </h5>
          <h5>Totals by pair:</h5>
          <table className="table table-sm table-bordered table-primary mt-2">
            <thead>
              <tr>
                <th>Pair</th>
                <th>Count</th>
                <th>BUY quantity</th>
                <th>BUY total</th>
                <th>SELL quantity</th>
                <th>SELL total</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(byPair).map(pairStats => (
                <tr key={pairStats.pair}>
                  <td>{pairStats.pair}</td>
                  <td>{pairStats.count}</td>
                  <td>
                    {pairStats.quote.buyQuantity} {pairStats.quote.instrument}
                  </td>
                  <td>
                    {pairStats.base.buyQuantity} {pairStats.base.instrument}
                  </td>
                  <td>
                    {pairStats.quote.sellQuantity} {pairStats.quote.instrument}
                  </td>
                  <td>
                    {pairStats.base.sellQuantity} {pairStats.base.instrument}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="p-4">
          <h3>Raw data</h3>
          <pre>{JSON.stringify(this.state.cancelResult, null, '  ')}</pre>
        </div>
      </div>
    );
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_MAINTENANCE)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-3 row">
            <div className="col-lg-4 col-md-6">
              <Card
                title="Update customer reference numbers"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.updateCustomerReferenceNumbers()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  Set reference numbers for all customers. These reference numbers are idempotent,
                  so you can call this however many times you want. There will be no transaction
                  locking, so this is fairly safe to call.
                </p>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Cancel all KYC0 orders"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.cancelAllKYC0Orders()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  Cancel all orders held by customers with KYC level 0. This was introduced as part
                  of our KYC policy update. Previously KYC0 customers could trade but now we require
                  KYC1.
                </p>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Cancel unverified phone orders"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.cancelUnverifiedPhoneOrders()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>Cancel all orders held by customers with unverified phone numbers.</p>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Cancel all RSD orders"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.cancelAllRsdOrders()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  Cancel all RSD orders will clear the order book of all RSD pairs (BTC/RSD,
                  ETH/RSD, SFX/RSD and SFT/RSD).
                </p>
              </Card>
            </div>

            <div className="col-12 mb-2">
              <h3>US offboarding</h3>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Fill US customer residence state (Step 1)"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.fillUSCustomerStates()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  This action applies to all customers with KYC1, The United States of America as a
                  residence country, and without residence US state - because they were registered
                  prior to the US offboarding release. The system will fill the residence US state
                  based on the ZIP code customer provided during the KYC1 application.
                </p>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Cancel blacklisted state customer orders (Step 2)"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.cancelUSCustomerOrders()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  This action applies to all US customers the system has detected are coming from a
                  blacklisted state (based on the result from the previous action), or could not
                  recognize the state. All active orders made by this group of customers will be
                  canceled.
                </p>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6">
              <Card
                title="Reset blacklisted state customer KYC (Step 3)"
                footer={
                  <IconButton
                    color={FLAVORS.danger}
                    icon={ICONS.play}
                    onClick={() => this.resetUSCustomersKYC()}
                  >
                    Execute
                  </IconButton>
                }
              >
                <p>
                  This action applies to all US customers the system has detected are coming from a
                  blacklisted state (based on the result from the first action), or could not
                  recognize the state. Their KYC level will be reset to 0, hence they won't be able
                  to trade and deposit.
                </p>
              </Card>
            </div>
          </div>
          {this.renderCancelResult()}
          {this.renderUpdateResult()}
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default MaintenanceInternalsPage;
