import './MicropaymentEditPage.css';

import startOfToday from 'date-fns/startOfToday';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { KYC_TRACKS, XcKYCMicropaymentPayload } from '../../lib/backend';
import { FLAVORS, MICROPAYMENT_PAYLOAD_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { abbreviated, classNamer, classes, formatBytes, lodash, withQuery } from '../../lib/tools';
import { MicropaymentDocumentPopoutLink } from '../documents/DocumentPopoutLink';
import DocumentUploadButton from '../documents/DocumentUploadButton';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import BoundDateInput from '../widgets/bound/BoundDateInput';
import BoundDropdown from '../widgets/bound/BoundDropdown';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import IconArticle from '../widgets/presentational/IconArticle';
import Time from '../widgets/presentational/Time';
import CustomerPicker from './widgets/CustomerPicker';

const cn = classNamer('MicropaymentEditPage');

const SETTING_REFERENCE_DIRECTIONS = {
  from_user: 'from_user',
  to_user: 'to_user',
};

const getMicropaymentIdFromRoute = props => props.match.params.id || null;

class MicropaymentEditPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcKYCMicropaymentPayload */
      data: {
        document_ids: [],
      },

      /** @type XcKYCMicropaymentInfo */
      kmi: null,

      /** @type {Object.<string, XcDocument>} */
      documentLookup: {},

      waiting: true,

      /**
       * This will trigger spinner of Submit button while we are doing server-side reference check
       */
      validatingReference: false,

      /**
       * One of SETTING_REFERENCE_DIRECTIONS, this is truthy while we are filling in reference
       */
      settingReferenceDirection: null,

      /**
       * String to display in a modal regarding a mismatched reference number. String will be shown in dialog.
       */
      referenceWarning: null,
    };
  }

  get micropaymentId() {
    return getMicropaymentIdFromRoute(this.props);
  }

  get isNew() {
    return !this.micropaymentId;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (getMicropaymentIdFromRoute(prevProps) !== this.micropaymentId) {
      this.loadData();
    }
  }

  getDefaultPayload = () => {
    /** @type MicropaymentEditPageNavigationState */
    const navState = this.props.location.state;

    return new XcKYCMicropaymentPayload({
      reference_number: '',
      user_id: (navState && navState.prefill_user_id) || null,
      recipient_account: this.container.settings.micropayment_default_recipient_account,
      transaction_id: '',
      full_name: '',
      iban: '',
      swift: '',
      quantity: '',
      instrument: this.container.settings.micropayment_currencies[0] || null,
      notes: '',
      payment_date: startOfToday(),
      document_ids: [],
    });
  };

  loadData() {
    if (!this.micropaymentId) {
      // Just set the defaults
      this.setState({
        data: this.getDefaultPayload(),
        documentLookup: {},
        kmi: null,
        waiting: false,
      });
      return;
    }

    this.promiseOrSetError(this.container.client.getKycMicropaymentsInfo(this.micropaymentId)).then(
      kmi => {
        const data = {
          ...lodash.pick(kmi, Object.keys(MICROPAYMENT_PAYLOAD_KEYS)),
          document_ids: [],
        };
        const documentLookup = {};
        for (const document of kmi.documents) {
          data.document_ids.push(document.id);
          documentLookup[document.id] = document;
        }
        this.setState({ data, documentLookup, kmi });
      }
    );
  }

  setReferenceNumberFromUser = () => {
    const userId = this.state.data.user_id;
    if (!userId) {
      return;
    }

    this.setState({
      settingReferenceDirection: SETTING_REFERENCE_DIRECTIONS.from_user,
    });

    this.promiseOrToast(
      this.container.client
        .getKycMicropaymentsReferenceNumbersFromUser(userId)
        .finally(() => this.setState({ settingReferenceDirection: null })),
      true
    ).then(referenceNumber => {
      this.setNestedState({
        data: {
          reference_number: referenceNumber || '',
        },
        referenceWarning: null,
      });
    });
  };

  setUserFromReferenceNumber = () => {
    const referenceNumber = this.state.data.reference_number;
    if (!referenceNumber) {
      return;
    }

    this.setState({
      settingReferenceDirection: SETTING_REFERENCE_DIRECTIONS.to_user,
    });

    this.promiseOrToast(
      this.container.client
        .getKycMicropaymentsReferenceNumbersToUser(referenceNumber)
        .finally(() => this.setState({ settingReferenceDirection: null })),
      true
    ).then(userId => {
      this.setNestedState({
        data: {
          user_id: userId || null,
        },
        referenceWarning: null,
      });
      if (!userId) {
        toast.warn(`Reference number "${referenceNumber}" was not found among our customers`);
      }
    });
  };

  handleDocumentUploaded = (/** XcDocument*/ document) => {
    this.setNestedState({
      data: {
        document_ids: this.state.data.document_ids.concat(document.id),
      },
      documentLookup: {
        [document.id]: document,
      },
    });
  };

  deleteDocument = documentId => {
    this.setNestedState({
      data: {
        document_ids: this.state.data.document_ids.filter(id => id !== documentId),
      },
      documentLookup: {
        [documentId]: undefined,
      },
    });
  };

  validateReferenceNumber = () => {
    const userId = this.state.data.user_id;
    if (!userId) {
      // Backend will return validation error, let it through
      return Promise.resolve(true);
    }

    const referenceNumber = this.state.data.reference_number;

    if (!referenceNumber) {
      this.setState({
        referenceWarning: `Customer is set, but reference number is empty.`,
      });
      return Promise.resolve(false);
    }

    this.setState({
      validatingReference: true,
    });

    return this.promiseOrToast(
      this.container.client.getKycMicropaymentsReferenceNumbersFromUser(userId).finally(() => {
        this.setState({
          validatingReference: false,
        });
      }),
      true
    ).then(actualReferenceNumber => {
      if (actualReferenceNumber !== referenceNumber) {
        this.setState({
          referenceWarning: (
            <span>
              Entered reference number (<strong>{referenceNumber}</strong>) doesn't match the actual
              reference number assigned to <strong>{userId}</strong> (
              <strong>{actualReferenceNumber}</strong>).
            </span>
          ),
        });
        return false;
      }

      return true;
    });
  };

  doSubmit = () => {
    const payload = { ...this.state.data };

    return this.promiseOrSetError(
      this.isNew
        ? this.container.client.postKycMicropayment(payload)
        : this.container.client.putKycMicropayment(this.micropaymentId, payload)
    ).then(() => {
      toast.success(
        this.isNew
          ? `New micropayment by ${payload.user_id} has been added`
          : `Micropayment ${this.micropaymentId} by ${payload.user_id} has been updated`
      );
      this.container.history.pushWithQuery(this.container.store.prevBreadcrumb.link);
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    return this.validateReferenceNumber().then(proceed => {
      if (proceed) {
        return this.doSubmit();
      }
    });
  };

  closeReferenceWarningModal = () => {
    this.setState({
      referenceWarning: null,
    });
  };

  confirmSubmit = () => {
    this.closeReferenceWarningModal();
    return this.doSubmit();
  };

  renderDocumentRows = () => {
    if (!this.state.data.document_ids.length) {
      // A single "missing data" cell
      return (
        <tr>
          <td colSpan={100}>
            <h5 className="text-center font-italic m-1">There are no attached documents</h5>
          </td>
        </tr>
      );
    }

    return (
      <>
        {this.state.data.document_ids.map(documentId => {
          const document = this.state.documentLookup[documentId];
          return (
            <tr key={document.id}>
              <td>{abbreviated(document.id)}</td>
              <td>
                <MicropaymentDocumentPopoutLink document={document} disabled={this.isNew} />
              </td>
              <td className="text-nowrap">{formatBytes(document.size_in_bytes)}</td>
              <td className="text-nowrap">
                <Time value={document.created_at} format={Time.FORMATS.precise} />
              </td>
              <td className="text-right text-nowrap">
                <IconButton
                  color={FLAVORS.danger}
                  icon={ICONS.delete}
                  size="sm"
                  onClick={() => this.deleteDocument(documentId)}
                >
                  Delete
                </IconButton>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  render() {
    const title = this.isNew ? `Add new micropayment` : `Edit micropayment ${this.micropaymentId}`;
    return (
      <GridLayout className={classes(cn(), 'container', 'mb-4')} above={title}>
        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />

          <Breadcrumbed
            title={this.isNew ? 'Add' : 'Edit'}
            link={
              this.isNew ? routes.MICROPAYMENTS_NEW : routes.micropaymentsEdit(this.micropaymentId)
            }
          />

          <Form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Recipient account"
                  name={MICROPAYMENT_PAYLOAD_KEYS.recipient_account}
                  state={this.boundState}
                  nest
                  helpText={
                    'Our bank account where the micropayment was received. You should probably leave this as is.'
                  }
                />
              </div>
            </div>

            <div className={classes('row', cn('reference-and-customer'))}>
              <div className="col-md-6">
                <BoundFormInput
                  label="Reference number"
                  name={MICROPAYMENT_PAYLOAD_KEYS.reference_number}
                  state={this.boundState}
                  nest
                >
                  <p className="text-right mt-2">
                    <IconButton
                      icon={ICONS.arrow_right}
                      iconRight
                      onClick={this.setUserFromReferenceNumber}
                      loading={
                        this.state.settingReferenceDirection ===
                        SETTING_REFERENCE_DIRECTIONS.to_user
                      }
                      disabled={
                        !this.state.data.reference_number ||
                        this.state.settingReferenceDirection ===
                          SETTING_REFERENCE_DIRECTIONS.from_user
                      }
                    >
                      Find customer for reference number
                    </IconButton>
                  </p>
                </BoundFormInput>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label for={cn(MICROPAYMENT_PAYLOAD_KEYS.user_id)}>Customer</Label>
                  <CustomerPicker
                    inputId={cn(MICROPAYMENT_PAYLOAD_KEYS.user_id)}
                    selectedId={this.state.data.user_id}
                    onChange={id => this.setNestedState({ data: { user_id: id } })}
                    kycTrack={KYC_TRACKS.vqf}
                  />
                  <p className="mt-2">
                    <IconButton
                      icon={ICONS.arrow_left}
                      onClick={this.setReferenceNumberFromUser}
                      loading={
                        this.state.settingReferenceDirection ===
                        SETTING_REFERENCE_DIRECTIONS.from_user
                      }
                      disabled={
                        !this.state.data.user_id ||
                        this.state.settingReferenceDirection ===
                          SETTING_REFERENCE_DIRECTIONS.to_user
                      }
                    >
                      Find reference number for customer
                    </IconButton>
                  </p>
                </FormGroup>
              </div>
            </div>

            {this.state.kmi && this.state.kmi.user_id !== this.state.data.user_id && (
              <Alert color={FLAVORS.warning}>
                <IconArticle icon={ICONS.warn} flavor={FLAVORS.primary}>
                  <p>
                    It seems you are trying to change the owner of micropayment{' '}
                    {this.micropaymentId}. The consequences of this action are:
                  </p>
                  <ul>
                    <li>
                      Any currently active unfinished audit will be marked as{' '}
                      <strong>cancelled</strong>. All past audits will still reference the current
                      user (<strong>{this.state.kmi.user_id}</strong>) and will not be changed to
                      the new user (<strong>{this.state.data.user_id}</strong>).
                    </li>
                    <li>
                      Existing owner (<strong>{this.state.kmi.user_id}</strong>) will no longer see
                      this micropayment in their UI.
                    </li>
                    <li>
                      If the new owner (<strong>{this.state.data.user_id}</strong>) doesn't already
                      have an active micropayment, they will suddenly see this one in their UI.
                    </li>
                    <li>
                      System will automatically launch an audit for the new owner (
                      <strong>{this.state.data.user_id}</strong>) if they have all the prerequisites
                      (eg. KYC 2 level).
                    </li>
                  </ul>
                  <p className="mb-0">
                    Only proceed if you know what you're doing and are sure this is what you want to
                    happen.
                  </p>
                </IconArticle>
              </Alert>
            )}

            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Transaction ID"
                  name={MICROPAYMENT_PAYLOAD_KEYS.transaction_id}
                  state={this.boundState}
                  nest
                />
              </div>

              <div className="col-md-6">
                <BoundFormInput
                  label="Sender name"
                  name={MICROPAYMENT_PAYLOAD_KEYS.full_name}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="IBAN"
                  name={MICROPAYMENT_PAYLOAD_KEYS.iban}
                  state={this.boundState}
                  nest
                />
              </div>

              <div className="col-md-6">
                <BoundFormInput
                  label="Swift"
                  name={MICROPAYMENT_PAYLOAD_KEYS.swift}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label for={cn(MICROPAYMENT_PAYLOAD_KEYS.quantity)}>Paid quantity</Label>
                  <InputGroup>
                    <Bind state={this.boundState} nest>
                      <Input
                        type="text"
                        name={MICROPAYMENT_PAYLOAD_KEYS.quantity}
                        id={cn(MICROPAYMENT_PAYLOAD_KEYS.quantity)}
                      />
                    </Bind>
                    <BoundDropdown
                      addon={true}
                      state={this.boundState}
                      items={this.container.settings.micropayment_currencies}
                      name={MICROPAYMENT_PAYLOAD_KEYS.instrument}
                      nest
                    />
                  </InputGroup>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <BoundDateInput
                  label="Payment date"
                  name={MICROPAYMENT_PAYLOAD_KEYS.payment_date}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <BoundFormInput
                  label="Notes"
                  name={MICROPAYMENT_PAYLOAD_KEYS.notes}
                  state={this.boundState}
                  nest
                  type={'textarea'}
                  helpText={'These notes will be seen by KYC officer during audits'}
                />
              </div>
            </div>

            <div className={cn('documents')}>
              <Label>Documents</Label>
              <div className="mb-3">
                <DocumentUploadButton onUpload={this.handleDocumentUploaded}>
                  Attach document
                </DocumentUploadButton>
              </div>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th className="w-100">File name</th>
                    <th>Size</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{this.renderDocumentRows()}</tbody>
              </table>
            </div>

            <IconButton
              color="success"
              className="mr-2"
              icon={ICONS.save}
              loading={this.state.validatingReference}
            >
              Save
            </IconButton>
            <IconButton
              tag={Link}
              to={withQuery(this, this.container.store.prevBreadcrumb.link)}
              icon={ICONS.clear}
              loading={this.state.validatingReference}
            >
              Cancel
            </IconButton>
          </Form>

          <Modal isOpen={!!this.state.referenceWarning} toggle={this.closeReferenceWarningModal}>
            <ModalHeader toggle={this.closeReferenceWarningModal}>
              Reference number mismatch
            </ModalHeader>
            <ModalBody>
              <p>{this.state.referenceWarning}</p>
              <p>
                You should only do this in exceptional circumstances and if you're sure you know
                what you're doing.
              </p>
              <p>Do you wish to proceed with submission?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="mr-2"
                color={FLAVORS.warning}
                onClick={this.confirmSubmit}
                disabled={this.state.validatingReference || this.state.settingReferenceDirection}
              >
                Proceed
              </Button>
              <Button
                color="secondary"
                onClick={this.closeReferenceWarningModal}
                disabled={this.state.validatingReference || this.state.settingReferenceDirection}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default withRouter(MicropaymentEditPage);

export class MicropaymentEditPageNavigationState {
  constructor(prefillUserId) {
    this.prefill_user_id = prefillUserId;
  }
}
