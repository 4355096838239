import React from 'react';
import { toast } from 'react-toastify';

import { CUSTOMER_INFO_KEYS, FLAVORS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { customerName } from '../../lib/selectors';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import IconButton from '../widgets/interactive/IconButton';
import CountryLabel from '../widgets/presentational/CountryLabel';
import EmailVerifiedStatus from '../widgets/presentational/EmailVerifiedStatus';
import IconLabel from '../widgets/presentational/IconLabel';
import KYCLevelIcon from '../widgets/presentational/KYCLevelIcon';
import MailchimpStatus from '../widgets/presentational/MailchimpStatus';
import PhoneNumberLink from '../widgets/presentational/PhoneNumberLink';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const cn = classNamer('MailchimpInternalsPage');

const COLUMNS = [
  new DataTableColumn(CUSTOMER_INFO_KEYS.user_id, 'ID'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.email, 'Email'),
  new DataTableColumn(
    [CUSTOMER_INFO_KEYS.first_name, CUSTOMER_INFO_KEYS.middle_name, CUSTOMER_INFO_KEYS.last_name],
    'Name',
    ci => customerName(ci)
  ),
  new DataTableColumn(CUSTOMER_INFO_KEYS.nationality, 'Nationality', ci => (
    <CountryLabel isoCode={ci.nationality} label />
  )),
  new DataTableColumn(
    CUSTOMER_INFO_KEYS.kyc_level_granted,
    { tooltip: 'KYC Level', title: 'KYC' },
    (/** XcCustomerInfo */ ci) => (
      <div className="text-center h4">
        <KYCLevelIcon level={ci.kyc_level_granted} />
      </div>
    )
  ),
  new DataTableColumn(
    [CUSTOMER_INFO_KEYS.phone_verified, CUSTOMER_INFO_KEYS.phone],
    'Phone',
    ci => <PhoneNumberLink phone={ci.phone} verified={!!ci.phone_verified} />
  ),
  new DataTableColumn(
    [CUSTOMER_INFO_KEYS.email_verified_at, CUSTOMER_INFO_KEYS.email_verification_requested_at],
    'Email verification',
    ci => <EmailVerifiedStatus user={ci} />
  ),
  new DataTableColumn(CUSTOMER_INFO_KEYS.subscribed, 'Mailchimp status', ci => (
    <MailchimpStatus customer={ci} />
  )),
  new DataTableColumn(CUSTOMER_INFO_KEYS.suspended_at, 'Restriction', ci => {
    if (ci.suspended_at) {
      return (
        <IconLabel flavor={false} icon={ICONS.lock} className="font-weight-bold">
          Suspended
        </IconLabel>
      );
    }

    return null;
  }),
];

class MailchimpInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      info: null,
      data: null,

      selection: [],

      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.promiseOrToast(
      Promise.all([
        this.container.client.getMailchimpClientInfo(),
        this.container.client.getMailchimpMissingCustomers(this.state.criteria),
      ])
    ).then(([info, data]) => {
      this.setState({ info, data, selection: [] });
    });
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  onSetupFieldsClick = () => {
    return this.promiseOrToast(this.container.client.putMailchimpSetupField()).then(() => {
      toast.success('Mailchimp audience merge fields have been set up');
    });
  };

  onSyncAllClick = () => {
    return this.promiseOrToast(this.container.client.putMailchimpSyncCustomer()).then(members => {
      toast.success(`${members.length} customers have been synchronized with mailchimp`);
      this.loadData();
    });
  };

  onSyncMissingClick = () => {
    const criteria = { user_ids: this.state.data.items.map(item => item.user_id) };

    return this.promiseOrToast(this.container.client.putMailchimpSyncCustomer(criteria)).then(
      members => {
        toast.success(
          `All ${members.length} missing customers have been synchronized with mailchimp`
        );
        this.loadData();
      }
    );
  };

  onSyncSelectedClick = () => {
    const criteria = {
      user_ids: this.state.selection.map(index => this.state.data.items[index].user_id),
    };

    return this.promiseOrToast(this.container.client.putMailchimpSyncCustomer(criteria)).then(
      members => {
        toast.success(
          members.length > 1
            ? `${members.length} missing customers have been synchronized with mailchimp`
            : `Missing customer (${members[0].user_id}) has been synchronized with mailchimp`
        );
        this.loadData();
      }
    );
  };

  renderClientInformation = () => {
    const isEnabled = this.state.info && this.state.info.enabled;
    const accountName = this.state.info && this.state.info.account_name;

    return (
      <Expander title="Client information" memoryKey={cn('mailchimp-client-info')}>
        <p>
          Status:
          <IconLabel
            flavor={isEnabled ? FLAVORS.success : FLAVORS.danger}
            className="mx-1 font-weight-bold"
          >
            {isEnabled ? 'ENABLED' : 'DISABLED'}
          </IconLabel>
        </p>
        <p>
          Account: <span className="mx-1 font-weight-bold">{accountName}</span>{' '}
        </p>
      </Expander>
    );
  };

  renderGlobalActions = () => {
    const hasMissing = this.state.data && this.state.data.items && this.state.data.items.length;

    return (
      <Expander title="Global actions" memoryKey={cn('mailchimp-global-actions')}>
        <div className="d-flex mt-1">
          <IconButton
            className="flex-fill"
            type="button"
            color={FLAVORS.primary}
            onClick={() => this.onSyncAllClick()}
          >
            Sync all
          </IconButton>
        </div>
        <div className="d-flex mt-1">
          <IconButton
            className="flex-fill"
            type="button"
            color={FLAVORS.primary}
            onClick={() => this.onSyncMissingClick()}
            disabled={!hasMissing}
          >
            Sync missing
          </IconButton>
        </div>
        <div className="d-flex mt-1">
          <IconButton
            className="flex-fill"
            type="button"
            color={FLAVORS.secondary}
            onClick={() => this.onSetupFieldsClick()}
          >
            Setup fields
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderSidebarGlobal = () => {
    return (
      <>
        {this.renderClientInformation()}
        {this.renderGlobalActions()}
      </>
    );
  };

  renderSidebarSelected = () => {
    return (
      <div>
        {this.renderSidebarGlobal()}
        <Expander title="Selection actions" memoryKey={cn('mailchimp-selection-actions')}>
          <div className="d-flex">
            <IconButton
              className="flex-fill"
              type="button"
              color={FLAVORS.primary}
              onClick={() => this.onSyncSelectedClick()}
            >
              Sync selected
            </IconButton>
          </div>
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_WALLETS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderNone={this.renderSidebarGlobal}
              renderOne={this.renderSidebarSelected}
              renderMany={this.renderSidebarSelected}
            />
            <div>
              <div className="d-flex justify-content-end">
                <ToolBar>
                  <ToolBar.Strip>
                    <CriteriaFilter criteria={this.state.criteria} />
                    <CriteriaPageSize criteria={this.state.criteria} />
                  </ToolBar.Strip>
                </ToolBar>
              </div>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
                noDataPlaceholder="No missing customers"
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default MailchimpInternalsPage;
