import './assets/bootstrap_flatly.css';
import './assets/react_toastify.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from './components/Root';
import { initialize } from './container';

initialize().then(container => {
  ReactDOM.render(<Root container={container} />, document.getElementById('root'));
});
