import './CodeBlock.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classes } from '../../../lib/tools';

const CodeBlock = ({ className, children }) => {
  className = classes('CodeBlock', className);

  return (
    <pre className={className}>
      <code>{children}</code>
    </pre>
  );
};

CodeBlock.propTypes = {
  className: PropTypes.string,
};

export default CodeBlock;
