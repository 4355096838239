import './MarketInternalsPage.css';

import React from 'react';
import { withRouter } from 'react-router';

import { ADMIN_SOCKET_EVENTS, ADMIN_SOCKET_SECTORS } from '../../lib/backend';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';

const cn = classNamer('MarketInternalsPage');

class MarketInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * @type {XcExchangeInternals}
       */
      internals: null,
    };
  }

  loadData() {
    return this.promiseOrToast(this.container.client.getExchangeInternals()).then(internals => {
      // Cast data
      this.setState({ internals });
    });
  }

  componentDidMount() {
    this.loadData();

    this.container.socketManager.enterSector(ADMIN_SOCKET_SECTORS.internals);
    this.container.socketManager.onSectorEvent(
      ADMIN_SOCKET_SECTORS.internals,
      ADMIN_SOCKET_EVENTS.exchange_internals,
      internals => {
        this.setState({ internals });
      }
    );
  }

  snapshot(pair) {
    this.promiseOrToast(this.container.client.postExchangeSnapshot(pair)).then(() => {
      this.loadData();
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.container.socketManager.exitSector(ADMIN_SOCKET_SECTORS.internals);
    this.container.socketManager.offSectorEvent(
      ADMIN_SOCKET_SECTORS.internals,
      ADMIN_SOCKET_EVENTS.exchange_internals
    );
  }

  renderMarketColumn(title, data) {
    return (
      <div className={cn('market-col')}>
        <ObjectInfo
          object={{
            [title]: <ObjectInfo object={data} />,
          }}
        />
      </div>
    );
  }

  renderMarket(/** XcMarketInternals */ market) {
    const globalData = {
      State: (market.state || '').toUpperCase(),
      'Change count': market.change_count,
      Footprint: market.footprint,
    };

    const tradesData = {
      'Unsaved count': market.trades.unsaved_count,
      'Last trade': market.trades.last_trade,
    };

    return (
      <div className={cn('market')} key={market.pair}>
        <div className={cn('market-header')}>
          <h4>{market.pair}</h4>
          <div className={cn('market-controls')}>
            <IconButton onClick={() => this.snapshot(market.pair)}>Snapshot now</IconButton>
          </div>
        </div>
        <div className={cn('market-content')}>
          <div className={cn('market-col')}>
            <ObjectInfo object={globalData} />
          </div>
          {this.renderMarketColumn('Trades', tradesData)}
          {this.renderMarketColumn('Buy orders', market.buy_list)}
          {this.renderMarketColumn('Sell orders', market.sell_list)}
          {this.renderMarketColumn('Jobs', {
            ...market.jobs,
            processing: market.jobs.processing ? `Job ${market.jobs.processing}` : 'Idle',
          })}
          {this.renderMarketColumn('Snapshotting', market.snapshotting)}
        </div>
      </div>
    );
  }

  /**
   * @type {XcExchangeInternals}
   */
  renderContent(internals) {
    if (!internals) {
      return null;
    }

    return (
      <div className={cn('content')}>
        {Object.values(internals.markets).map(market => this.renderMarket(market))}
      </div>
    );
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_MARKETS)} />

        <AjaxWrapper state={this.boundState}>
          {this.renderContent(this.state.internals)}
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(MarketInternalsPage);
