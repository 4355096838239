import PropTypes from 'prop-types';
import React from 'react';

import { BoundState } from './ConnectedComponent';

const DEFAULT_STATE_PATH = 'data';

export default class Bind extends React.Component {
  static propTypes = {
    state: PropTypes.instanceOf(BoundState).isRequired,
    name: PropTypes.string,
    nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    getNewValue: PropTypes.func,
    onChangeProp: PropTypes.string,
    valueProp: PropTypes.string,
  };

  static defaultProps = {
    onChangeProp: 'onChange',
    valueProp: 'value',
  };

  render() {
    const child = React.Children.only(this.props.children);

    const name = this.props.name || child.props.name;
    if (!name) {
      throw new Error(
        `No "name" found for ${child}. Child must have a prop "name", or you must provide name yourself`
      );
    }

    const { state, nest, getNewValue, valueProp, onChangeProp } = this.props;

    const value = getBoundValue(state, name, nest);

    const userOnChange = child.props.onChange;
    const onChange = e => {
      // If this is a "native" event, get target.value. Otherwise,
      // treat the entire event as value (in case this is some custom component)
      const newValue = getNewValue
        ? getNewValue(e, value)
        : e && e.target && e.target.value !== undefined
        ? e.target.value
        : e;

      setBoundValue(state, name, newValue, nest);

      if (userOnChange) {
        userOnChange(e);
      }
    };

    return React.cloneElement(child, { [onChangeProp]: onChange, [valueProp]: value });
  }
}

/**
 * Determine path to use in order to access value from state. Eg. "data" or ""
 * Currently only supports single level of nesting.
 */
function getStatePath(nest) {
  return nest ? (nest === true ? DEFAULT_STATE_PATH : nest) : null;
}

/**
 * Helper to produce current bound value from the state
 * @param {BoundState} state
 * @param name
 * @param nest
 */
export function getBoundValue(state, name, nest = false) {
  const statePath = getStatePath(nest);

  let value;
  if (state) {
    if (statePath) {
      value = state[statePath] && state[statePath][name];
    } else {
      value = state[name];
    }
  }
  if (value === undefined || value === null) {
    value = '';
  }
  return value;
}

/**
 * Helper to set value on the bound state object
 * @param {BoundState} state
 * @param name
 * @param newValue
 * @param nest
 */
export function setBoundValue(state, name, newValue, nest = false) {
  const statePath = getStatePath(nest);

  if (statePath) {
    state.setState({
      [statePath]: {
        ...state[statePath],
        [name]: newValue,
      },
    });
  } else {
    state.setState({
      [name]: newValue,
    });
  }
}
