import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Form, FormGroup, Label } from 'reactstrap';

import { COUNTRY_CODES, GENDERS, KYC_TRACKS } from '../../lib/backend';
import { CUSTOMER_KEYS, FLAVORS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes, lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import BoundCountryPicker from '../widgets/bound/BoundCountryPicker';
import BoundDateInput from '../widgets/bound/BoundDateInput';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import EnumSelect from '../widgets/interactive/EnumSelect';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import IconLabel from '../widgets/presentational/IconLabel';
import SOFOriginPicker from './widgets/SOFOriginPicker';
import SOFSalaryRangePicker from './widgets/SOFSalaryRangePicker';

const cn = classNamer('CustomerEditPage');

const userId = props => props.match.params.id || null;

const EDIT_PROPS = [
  CUSTOMER_KEYS.nationality,

  CUSTOMER_KEYS.first_name,
  CUSTOMER_KEYS.middle_name,
  CUSTOMER_KEYS.last_name,

  CUSTOMER_KEYS.residence_address,
  CUSTOMER_KEYS.residence_city,
  CUSTOMER_KEYS.residence_postal_code,
  CUSTOMER_KEYS.residence_country,
  CUSTOMER_KEYS.residence_us_state,

  CUSTOMER_KEYS.gender,
  CUSTOMER_KEYS.dob,
  CUSTOMER_KEYS.phone,

  CUSTOMER_KEYS.sof_profession,
  CUSTOMER_KEYS.sof_salary_range,
  CUSTOMER_KEYS.sof_origin,
  CUSTOMER_KEYS.sof_origin_other,
  CUSTOMER_KEYS.sof_expected_volume,

  CUSTOMER_KEYS.internal_notes,
];

class CustomerEditPage extends ConnectedComponent {
  static propTypes = {
    kycTrack: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      /** @type XcCustomer */
      data: {},
      verifiedPhone: null,

      startedVerification: true,

      waiting: true,
    };
  }

  get userId() {
    return userId(this.props);
  }

  get kycTrack() {
    return this.props.kycTrack || KYC_TRACKS.base;
  }

  get isVQF() {
    return this.kycTrack === KYC_TRACKS.vqf;
  }

  get returnUrl() {
    return this.isVQF ? routes.VQF_CUSTOMERS : routes.CUSTOMERS;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (userId(prevProps) !== this.userId) {
      this.loadData();
    }
  }

  loadData() {
    if (!this.userId) {
      return;
    }
    this.promiseOrSetError(this.container.client.getCustomersCustomer(this.userId)).then(
      customer => {
        const startedVerification = !!(customer.swiftdil_id && customer.kyc1_requested_at);
        const data = lodash.pick(customer, EDIT_PROPS);
        this.setState({
          data,
          startedVerification,
          verifiedPhone: (customer.phone_verified && customer.phone) || null,
        });
      }
    );
  }

  onSubmit = e => {
    e.preventDefault();

    return this.promiseOrSetError(
      this.container.client.putCustomersCustomer(this.userId, this.state.data)
    ).then(data => {
      toast(`Customer data for user ${this.userId} has been updated`, {
        type: 'success',
      });

      this.container.history.pushWithQuery(this.returnUrl);
    });
  };

  render() {
    return (
      <GridLayout className={classes(cn(), 'container')} above={`Edit customer ${this.userId}`}>
        <Breadcrumbed
          title="Edit customer"
          link={
            this.isVQF ? routes.vqfCustomersEdit(this.userId) : routes.customersEdit(this.userId)
          }
        />
        <AjaxWrapper state={this.boundState}>
          {/* TODO: Remove SwiftDil shit */}
          {this.state.startedVerification && (
            <Alert color={FLAVORS.warning}>
              <IconLabel icon={ICONS.warn} flavor={FLAVORS.light} className="mr-1" />
              Certain fields are locked because verification has already been triggered at SwiftDil.
              To unlock them, you need to reset KYC state of this account.
            </Alert>
          )}

          <ErrorBox state={this.boundState} />

          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundCountryPicker
                  label="Nationality"
                  name={CUSTOMER_KEYS.nationality}
                  state={this.boundState}
                  nest
                  helpText="This will determine the KYC flow this user will have to use"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <BoundFormInput
                  label="First name"
                  name={CUSTOMER_KEYS.first_name}
                  state={this.boundState}
                  disabled={this.state.startedVerification}
                  nest
                />
              </div>
              <div className="col-md-4">
                <BoundFormInput
                  label="Middle name"
                  name={CUSTOMER_KEYS.middle_name}
                  state={this.boundState}
                  disabled={this.state.startedVerification}
                  nest
                />
              </div>
              <div className="col-md-4">
                <BoundFormInput
                  label="Last name"
                  name={CUSTOMER_KEYS.last_name}
                  state={this.boundState}
                  disabled={this.state.startedVerification}
                  nest
                />
              </div>
            </div>

            <BoundFormInput
              label="Address"
              type="textarea"
              name={CUSTOMER_KEYS.residence_address}
              state={this.boundState}
              rows={2}
              nest
            />

            <div className="row">
              <div className="col-md-5">
                <BoundFormInput
                  label="City"
                  name={CUSTOMER_KEYS.residence_city}
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-2">
                <BoundFormInput
                  label="Postal code"
                  name={CUSTOMER_KEYS.residence_postal_code}
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-5">
                <BoundCountryPicker
                  label="Country of residence"
                  name={CUSTOMER_KEYS.residence_country}
                  state={this.boundState}
                  nest
                />
              </div>
              {this.state.data[CUSTOMER_KEYS.residence_country] === COUNTRY_CODES.US && (
                <div className="col-md-5">
                  <BoundCountryPicker
                    label="State of the United States"
                    name={CUSTOMER_KEYS.residence_us_state}
                    state={this.boundState}
                    type="us-state"
                    nest
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-2">
                <FormGroup>
                  <Label for={CUSTOMER_KEYS.gender}>Gender</Label>
                  <Bind state={this.boundState} nest>
                    <EnumSelect
                      values={GENDERS}
                      name={CUSTOMER_KEYS.gender}
                      id={CUSTOMER_KEYS.gender}
                    />
                  </Bind>
                </FormGroup>
              </div>
              <div className="col-md-5">
                <BoundDateInput
                  label="Date of birth"
                  name={CUSTOMER_KEYS.dob}
                  state={this.boundState}
                  nest
                  disabled={this.state.startedVerification}
                />
              </div>
              <div className="col-md-5">
                <BoundFormInput
                  label="Phone"
                  name={CUSTOMER_KEYS.phone}
                  state={this.boundState}
                  nest
                  autoComplete={'none'}
                  helpText={
                    this.state.verifiedPhone &&
                    this.state.verifiedPhone !== this.state.data.phone && (
                      <div className="text-warning font-weight-bold">
                        Customer's current phone (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            this.setNestedState({ data: { phone: this.state.verifiedPhone } });
                          }}
                        >
                          {this.state.verifiedPhone}
                        </a>
                        ) is already verified. If you alter the phone number, it might lose the
                        verified status.
                      </div>
                    )
                  }
                />
              </div>
            </div>

            {this.isVQF && (
              <div className={'mt-3 mb-3 p-3 bg-light'}>
                <h6 className="text-uppercase mb-3 text-secondary">Source of funds</h6>

                <div className="row">
                  <div className="col-md-6">
                    <BoundFormInput
                      label="Profession"
                      name={CUSTOMER_KEYS.sof_profession}
                      state={this.boundState}
                      nest
                    />
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label htmlFor={CUSTOMER_KEYS.sof_salary_range}>Salary range</Label>
                      <SOFSalaryRangePicker
                        inputId={CUSTOMER_KEYS.sof_salary_range}
                        isSearchable={false}
                        onChange={value => {
                          this.setNestedState({
                            data: { [CUSTOMER_KEYS.sof_salary_range]: value },
                          });
                        }}
                        selected={this.state.data[CUSTOMER_KEYS.sof_salary_range]}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label htmlFor={CUSTOMER_KEYS.sof_origin}>Origin of funds</Label>
                      <SOFOriginPicker
                        inputId={CUSTOMER_KEYS.sof_origin}
                        isSearchable={false}
                        onChange={value => {
                          this.setNestedState({ data: { [CUSTOMER_KEYS.sof_origin]: value } });
                        }}
                        selected={this.state.data[CUSTOMER_KEYS.sof_origin]}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <BoundFormInput
                      type="textarea"
                      label="Origin of funds details:"
                      name={CUSTOMER_KEYS.sof_origin_other}
                      state={this.boundState}
                      rows={2}
                      nest
                      helpText={`This needs to be filled if you specify origin of funds as "Other"`}
                    />
                  </div>
                </div>
                <BoundFormInput
                  className={'mb-0'}
                  type="textarea"
                  label="Expected transaction volume and frequency"
                  name={CUSTOMER_KEYS.sof_expected_volume}
                  state={this.boundState}
                  rows={2}
                  nest
                  helpText={`For example: "2-5 transfers per month, up to 10K total" or "one-off 50K deposit"`}
                />
              </div>
            )}

            <BoundFormInput
              label="Internal notes"
              type="textarea"
              name={CUSTOMER_KEYS.internal_notes}
              state={this.boundState}
              rows={2}
              nest
            />

            <IconButton color="success" className="mr-2" icon={ICONS.save}>
              Save
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, this.returnUrl)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default CustomerEditPage;
