import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ADMIN_PERMISSIONS,
  SORT_DIRECTIONS,
  XcSetEmailVerifiedPayload,
  XcSetUsersSuspendedPayload,
} from '../../lib/backend';
import { FLAVORS, USER_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Expander from '../layout/Expander';
import GridLayout from '../layout/GridLayout';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import EmailVerifiedStatus from '../widgets/presentational/EmailVerifiedStatus';
import IconLabel from '../widgets/presentational/IconLabel';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import User2FAStatus from './widgets/User2FAStatus';
import UserLabel from './widgets/UserLabel';
import UserPermissionList from './widgets/UserPermissionList';
import UserRole from './widgets/UserRole';

const BASE_CRITERIA = {
  sort_field: USER_KEYS.updated_at,
  sort_direction: SORT_DIRECTIONS.desc,
};

const COLUMNS = [
  new DataTableColumn(USER_KEYS.id, 'ID'),
  new DataTableColumn(USER_KEYS.email, 'Email'),
  new DataTableColumn(USER_KEYS.nickname, 'Nickname'),
  new DataTableColumn(USER_KEYS.role, 'Role', user => <UserRole role={user.role} />),
  new DataTableColumn(null, 'Permissions', user => <UserPermissionList user={user} />),
  new DataTableColumn(USER_KEYS.language, 'Language'),
  new DataTableColumn(
    'email_verified_at,email_verification_requested_at',
    'Email verification',
    user => <EmailVerifiedStatus user={user} />
  ),
  new DataTableColumn(USER_KEYS.geo_location_pinning, 'Geo location pinning'),
  new DataTableColumn(USER_KEYS.tfa_enabled_at, '2FA', user => (
    <User2FAStatus enabled={user.tfa_enabled_at} />
  )),
  new DataTableColumn(USER_KEYS.created_at, 'Created'),
  new DataTableColumn(USER_KEYS.updated_at, 'Updated'),
  new DataTableColumn([USER_KEYS.suspended_at, USER_KEYS.deleted_at], 'Restriction', (
    /** XcUser */ user
  ) => {
    if (user.suspended_at) {
      return (
        <IconLabel flavor={false} icon={ICONS.lock} className="font-weight-bold">
          Suspended
        </IconLabel>
      );
    }
    return null;
  }),
];

const cn = classNamer('UserAccountsPage');

class UserAccountsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],
      deleting: null,
      disablingTfa: null,
      settingEmailVerifiedSelection: null,
      settingEmailVerifiedValue: null,
      settingSuspendedSelection: null,
      settingSuspendedValue: null,

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getUsers(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  deleteSelectedUsers = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    const promise =
      ids.length === 1
        ? this.container.client.deleteUser(ids[0])
        : this.container.client.deleteUsers({ user_ids: ids });

    return this.promiseOrToast(promise).then(() => {
      toast.success(ids.length > 1 ? `${ids.length} users deleted` : `User deleted`);
      this.loadData();
    });
  };

  disable2FASelectedUsers = () => {
    this.setState({
      disablingTfa: this.state.selection.filter(index =>
        Boolean(this.state.data.items[index].tfa_enabled_at)
      ),
    });
  };

  confirmDisable2FA = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    return this.promiseOrToast(this.container.client.put2faDisable(ids)).then(() => {
      toast.success(
        ids.length > 1 ? `2FA for ${ids.length} users disabled` : `2FA for user ${ids[0]} disabled`
      );
      this.loadData();
    });
  };

  setEmailVerifiedForSelectedUsers = verified => {
    this.setState({
      settingEmailVerifiedSelection: this.state.selection.filter(
        index =>
          (verified && !this.state.data.items[index].email_verified_at) ||
          (!verified && !!this.state.data.items[index].email_verified_at)
      ),
      settingEmailVerifiedValue: verified,
    });
  };

  confirmSetEmailVerified = selection => {
    const payload = new XcSetEmailVerifiedPayload({
      user_ids: selection.map(index => this.state.data.items[index].id),
      verified: this.state.settingEmailVerifiedValue,
    });

    return this.promiseOrToast(this.container.client.putEmailSetVerified(payload)).then(() => {
      const action = payload.verified ? 'verified' : 'unverified';
      toast.success(
        payload.user_ids.length > 1
          ? `${payload.user_ids.length} user emails marked as ${action}`
          : `User ${payload.user_ids[0]}'s email marked as ${action}`
      );
      this.loadData();
    });
  };

  setSuspendedForSelectedUsers = suspended => {
    this.setState({
      settingSuspendedSelection: this.state.selection.filter(
        index =>
          (suspended && !this.state.data.items[index].suspended_at) ||
          (!suspended && !!this.state.data.items[index].suspended_at)
      ),
      settingSuspendedValue: suspended,
    });
  };

  confirmSetSuspended = selection => {
    const payload = new XcSetUsersSuspendedPayload({
      user_ids: selection.map(index => this.state.data.items[index].id),
      suspend: this.state.settingSuspendedValue,
    });

    return this.promiseOrToast(this.container.client.putUsersSuspension(payload)).then(() => {
      const action = payload.verified ? 'suspended' : 'unsuspended';
      toast.success(
        payload.user_ids.length > 1
          ? `${payload.user_ids.length} users were ${action}`
          : `User ${payload.user_ids[0]} has been ${action}`
      );
      this.loadData();
    });
  };

  render2FA = () => {
    if (!this.container.auth.hasPermission(ADMIN_PERMISSIONS.security)) {
      return null;
    }

    const eligibleCount = this.state.selection.filter(
      index => !!this.state.data.items[index].tfa_enabled_at
    ).length;

    return (
      <Expander title="2FA" memoryKey={cn('2fa')}>
        <IconButton
          title="Disable 2FA"
          color={FLAVORS.danger}
          onClick={this.disable2FASelectedUsers}
          icon={ICONS.unlock}
          className="mr-2"
          disabled={eligibleCount === 0}
        >
          Disable 2FA
        </IconButton>
      </Expander>
    );
  };

  renderEmailVerification = () => {
    if (!this.container.auth.hasPermission(ADMIN_PERMISSIONS.security)) {
      return null;
    }

    let eligibleVerify = 0;
    let eligibleUnverify = 0;
    for (const index of this.state.selection) {
      if (this.state.data.items[index].email_verified_at) {
        eligibleUnverify++;
      } else {
        eligibleVerify++;
      }
    }

    return (
      <Expander title="Email verification" memoryKey={cn('email_verification')}>
        <div className="d-flex">
          <IconButton
            title="Mark email as having been verified"
            color={FLAVORS.danger}
            onClick={() => this.setEmailVerifiedForSelectedUsers(true)}
            icon={ICONS.unlock}
            className="mr-1 flex-fill"
            disabled={eligibleVerify === 0}
          >
            Verify
          </IconButton>
          <IconButton
            title="Mark email as having NOT been verified, forcing user to re-verify"
            color={FLAVORS.primary}
            onClick={() => this.setEmailVerifiedForSelectedUsers(false)}
            icon={ICONS.lock}
            className="flex-fill"
            disabled={eligibleUnverify === 0}
          >
            Unverify
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderSuspension = () => {
    if (!this.container.auth.hasPermission(ADMIN_PERMISSIONS.security)) {
      return null;
    }

    let eligibleSuspend = 0;
    let eligibleUnsuspend = 0;
    for (const index of this.state.selection) {
      if (this.state.data.items[index].suspended_at) {
        eligibleUnsuspend++;
      } else {
        eligibleSuspend++;
      }
    }

    return (
      <Expander title="Suspension" memoryKey={cn('suspension')}>
        <GridLayout autoCols gap={1} unresponsive>
          <IconButton
            title="Suspend users from participating in trading and logging in to admin panel"
            color={FLAVORS.danger}
            onClick={() => this.setSuspendedForSelectedUsers(true)}
            icon={ICONS.lock}
            disabled={eligibleSuspend === 0}
          >
            Suspend
          </IconButton>
          <IconButton
            title="Lift suspension, restoring the access to trading and admin panel"
            color={FLAVORS.primary}
            onClick={() => this.setSuspendedForSelectedUsers(false)}
            icon={ICONS.unlock}
            disabled={eligibleUnsuspend === 0}
          >
            Unsuspend
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcUser} */
    const user = this.state.data.items[selectedIndex];

    const canEdit =
      // You are a superadmin
      this.container.auth.isSuperAdmin ||
      // You are a security admin
      this.container.auth.hasPermission(ADMIN_PERMISSIONS.security);

    // Only superadmin can delete users
    const canDelete = this.container.auth.isSuperAdmin;

    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title={canEdit ? 'Edit user' : 'You are not allowed to edit this user'}
            color={FLAVORS.primary}
            to={routes.usersEdit(user.id)}
            icon={ICONS.edit}
            className="mr-2"
            disabled={!canEdit}
          >
            Edit user
          </IconButton>

          <IconButton
            title={canDelete ? 'Delete user' : 'You are not allowed to delete this user'}
            color={FLAVORS.danger}
            onClick={this.deleteSelectedUsers}
            icon={ICONS.delete}
            className="mr-2"
            disabled={!canDelete}
          >
            Delete
          </IconButton>
        </Expander>

        {this.render2FA()}
        {this.renderEmailVerification()}
        {this.renderSuspension()}

        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo object={user} />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = () => {
    // Only superadmin can delete users
    const canDelete = this.container.auth.isSuperAdmin;

    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            title={canDelete ? 'Delete users' : 'You are not allowed to delete users'}
            color="danger"
            onClick={this.deleteSelectedUsers}
            icon={ICONS.delete}
            disabled={!canDelete}
          >
            Delete
          </IconButton>
        </Expander>

        {this.render2FA()}
        {this.renderEmailVerification()}
        {this.renderSuspension()}
      </div>
    );
  };

  render() {
    const canCreate =
      // You are a superadmin
      this.container.auth.isSuperAdmin ||
      // You are a security admin
      this.container.auth.hasPermission(ADMIN_PERMISSIONS.security);

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <IconButton
                    title={canCreate ? 'Create user' : 'You are not allowed to create user'}
                    tag={Link}
                    color="success"
                    icon={ICONS.new}
                    to={routes.USERS_NEW}
                    disabled={!canCreate}
                  >
                    Create user
                  </IconButton>
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getUsersCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmDelete}
          typeSingular="user"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmDisable2FA}
          typeSingular="user"
          actionText="disable 2FA for"
          buttonActionText="Disable"
          actionKey="disablingTfa"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmSetEmailVerified}
          typeSingular="user"
          actionText={`mark email as ${
            this.state.settingEmailVerifiedValue ? 'verified' : 'unverified'
          } for`}
          buttonActionText={`Mark ${
            this.state.settingEmailVerifiedValue ? 'verified' : 'unverified'
          }`}
          actionKey="settingEmailVerifiedSelection"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmSetSuspended}
          typeSingular="user"
          actionText={this.state.settingSuspendedValue ? 'suspend' : 'unsuspend'}
          buttonActionText={this.state.settingSuspendedValue ? 'Suspend' : 'Unsuspend'}
          actionFlavor={this.state.settingSuspendedValue ? FLAVORS.danger : FLAVORS.primary}
          actionKey="settingSuspendedSelection"
        >
          {this.state.settingSuspendedValue ? (
            <div>
              <p>Suspension has the following consequences:</p>
              <h6 className="font-weight-bold">For customers:</h6>
              <ul>
                <li>All user's orders will be immediately cancelled</li>
                <li>
                  User will no longer be able to trade, receive deposits or request withdrawals
                </li>
                <li>
                  Any active KYC request will immediately be denied. User will not be able to
                  resubmit.
                </li>
                <li>
                  User will still be able to log in, change settings and participate in the trollbox
                </li>
              </ul>
              <h6 className="font-weight-bold">For admins:</h6>
              <ul>
                <li>User will be logged out of all active sessions</li>
                <li>User will no longer be able to log in to admin panel</li>
              </ul>
              <p>
                <em>Note that only superadmins can suspend other superadmins</em>
              </p>
            </div>
          ) : (
            <p>
              <strong>
                By lifting suspension, all previously locked privileges will be unlocked.
              </strong>
            </p>
          )}
        </BoundDangerModal>
      </PageLayout>
    );
  }
}

export default withRouter(UserAccountsPage);
