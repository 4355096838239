import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import TradingGrowthTimelinePage from './TradingGrowthTimelinePage';
import TradingTradesPage from './TradingTradesPage';

class TradingRouter extends ConnectedComponent {
  render() {
    return (
      <div className="TradingRouter">
        <Breadcrumbed link={SECTIONS.trading}>
          <SubPager links={SECTIONS.trading.subsections} />
          <Switch>
            <Route
              path={routes.TRADING}
              render={() => <StickyRedirect routes={SECTIONS.trading.subsections} />}
              exact
            />
            <Route path={routes.TRADING_TRADES} component={TradingTradesPage} exact />
            <Route
              path={routes.TRADING_GROWTH_TIMELINE}
              component={TradingGrowthTimelinePage}
              exact
            />
            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default TradingRouter;
