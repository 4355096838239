import './DevIconsPage.css';

import React from 'react';
import { withRouter } from 'react-router';

import { AppIcon, ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';

const cn = classNamer('DevIconsPage');

class DevIconsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={routes.DEV_ICONS} title="Icons" />
        <AjaxWrapper state={this.boundState}>
          <div className="m-3">
            <div className={cn('content')}>
              {Object.keys(ICONS).map(key => {
                return (
                  <div key={key} className={cn('icon')}>
                    <AppIcon icon={ICONS[key]} size={'100px'} />
                    <h5>{key}</h5>
                    <h6>
                      (
                      <a
                        href={`https://fontawesome.com/icons/${ICONS[key]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ICONS[key]}
                      </a>
                      )
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(DevIconsPage);
