export const CONTINENTS = {
  AF: { value: 'AF', label: 'Africa' },
  AS: { value: 'AS', label: 'Asia' },
  AN: { value: 'AN', label: 'Antarctica' },
  EU: { value: 'EU', label: 'Europe' },
  NA: { value: 'NA', label: 'North America' },
  OC: { value: 'OC', label: 'Oceania' },
  SA: { value: 'SA', label: 'South America' },
};

export const COUNTRIES = {
  AF: { value: 'AF', label: 'Afghanistan', continent: 'AS' },
  AX: { value: 'AX', label: 'Åland Islands', continent: 'EU' },
  AL: { value: 'AL', label: 'Albania', continent: 'EU' },
  DZ: { value: 'DZ', label: 'Algeria', continent: 'AF' },
  AS: { value: 'AS', label: 'American Samoa', continent: 'OC' },
  AD: { value: 'AD', label: 'Andorra', continent: 'EU' },
  AO: { value: 'AO', label: 'Angola', continent: 'AF' },
  AI: { value: 'AI', label: 'Anguilla', continent: 'NA' },
  AQ: { value: 'AQ', label: 'Antarctica', continent: 'AN' },
  AG: { value: 'AG', label: 'Antigua and Barbuda', continent: 'NA' },
  AR: { value: 'AR', label: 'Argentina', continent: 'SA' },
  AM: { value: 'AM', label: 'Armenia', continent: 'AS' },
  AW: { value: 'AW', label: 'Aruba', continent: 'SA' },
  AU: { value: 'AU', label: 'Australia', continent: 'OC' },
  AT: { value: 'AT', label: 'Austria', continent: 'EU' },
  AZ: { value: 'AZ', label: 'Azerbaijan', continent: 'AS' },
  BS: { value: 'BS', label: 'Bahamas', continent: 'NA' },
  BH: { value: 'BH', label: 'Bahrain', continent: 'AS' },
  BD: { value: 'BD', label: 'Bangladesh', continent: 'AS' },
  BB: { value: 'BB', label: 'Barbados', continent: 'NA' },
  BY: { value: 'BY', label: 'Belarus', continent: 'EU' },
  BE: { value: 'BE', label: 'Belgium', continent: 'EU' },
  BZ: { value: 'BZ', label: 'Belize', continent: 'NA' },
  BJ: { value: 'BJ', label: 'Benin', continent: 'AF' },
  BM: { value: 'BM', label: 'Bermuda', continent: 'NA' },
  BT: { value: 'BT', label: 'Bhutan', continent: 'AS' },
  BO: { value: 'BO', label: 'Bolivia (Plurinational State of)', continent: 'SA' },
  BQ: { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba', continent: 'SA' },
  BA: { value: 'BA', label: 'Bosnia and Herzegovina', continent: 'EU' },
  BW: { value: 'BW', label: 'Botswana', continent: 'AF' },
  BV: { value: 'BV', label: 'Bouvet Island', continent: 'AN' },
  BR: { value: 'BR', label: 'Brazil', continent: 'SA' },
  IO: { value: 'IO', label: 'British Indian Ocean Territory', continent: 'AS' },
  BN: { value: 'BN', label: 'Brunei Darussalam', continent: 'AS' },
  BG: { value: 'BG', label: 'Bulgaria', continent: 'EU' },
  BF: { value: 'BF', label: 'Burkina Faso', continent: 'AF' },
  BI: { value: 'BI', label: 'Burundi', continent: 'AF' },
  CV: { value: 'CV', label: 'Cabo Verde', continent: 'AF' },
  KH: { value: 'KH', label: 'Cambodia', continent: 'AS' },
  CM: { value: 'CM', label: 'Cameroon', continent: 'AF' },
  CA: { value: 'CA', label: 'Canada', continent: 'NA' },
  KY: { value: 'KY', label: 'Cayman Islands', continent: 'NA' },
  CF: { value: 'CF', label: 'Central African Republic', continent: 'AF' },
  TD: { value: 'TD', label: 'Chad', continent: 'AF' },
  CL: { value: 'CL', label: 'Chile', continent: 'SA' },
  CN: { value: 'CN', label: 'China', continent: 'AS' },
  CX: { value: 'CX', label: 'Christmas Island', continent: 'AS' },
  CC: { value: 'CC', label: 'Cocos (Keeling) Islands', continent: 'AS' },
  CO: { value: 'CO', label: 'Colombia', continent: 'SA' },
  KM: { value: 'KM', label: 'Comoros', continent: 'AF' },
  CG: { value: 'CG', label: 'Congo', continent: 'AF' },
  CD: { value: 'CD', label: 'Congo (Democratic Republic of the)', continent: 'AF' },
  CK: { value: 'CK', label: 'Cook Islands', continent: 'OC' },
  CR: { value: 'CR', label: 'Costa Rica', continent: 'NA' },
  CI: { value: 'CI', label: "Côte d'Ivoire", continent: 'AF' },
  HR: { value: 'HR', label: 'Croatia', continent: 'EU' },
  CU: { value: 'CU', label: 'Cuba', continent: 'NA' },
  CW: { value: 'CW', label: 'Curaçao', continent: 'SA' },
  CY: { value: 'CY', label: 'Cyprus', continent: 'EU' },
  CZ: { value: 'CZ', label: 'Czechia', continent: 'EU' },
  DK: { value: 'DK', label: 'Denmark', continent: 'EU' },
  DJ: { value: 'DJ', label: 'Djibouti', continent: 'AF' },
  DM: { value: 'DM', label: 'Dominica', continent: 'NA' },
  DO: { value: 'DO', label: 'Dominican Republic', continent: 'NA' },
  EC: { value: 'EC', label: 'Ecuador', continent: 'SA' },
  EG: { value: 'EG', label: 'Egypt', continent: 'AF' },
  SV: { value: 'SV', label: 'El Salvador', continent: 'NA' },
  GQ: { value: 'GQ', label: 'Equatorial Guinea', continent: 'AF' },
  ER: { value: 'ER', label: 'Eritrea', continent: 'AF' },
  EE: { value: 'EE', label: 'Estonia', continent: 'EU' },
  SZ: { value: 'SZ', label: 'Eswatini', continent: 'AF' },
  ET: { value: 'ET', label: 'Ethiopia', continent: 'AF' },
  FK: { value: 'FK', label: 'Falkland Islands (Malvinas)', continent: 'SA' },
  FO: { value: 'FO', label: 'Faroe Islands', continent: 'EU' },
  FJ: { value: 'FJ', label: 'Fiji', continent: 'OC' },
  FI: { value: 'FI', label: 'Finland', continent: 'EU' },
  FR: { value: 'FR', label: 'France', continent: 'EU' },
  GF: { value: 'GF', label: 'French Guiana', continent: 'SA' },
  PF: { value: 'PF', label: 'French Polynesia', continent: 'OC' },
  TF: { value: 'TF', label: 'French Southern Territories', continent: 'AN' },
  GA: { value: 'GA', label: 'Gabon', continent: 'AF' },
  GM: { value: 'GM', label: 'Gambia', continent: 'AF' },
  GE: { value: 'GE', label: 'Georgia', continent: 'AS' },
  DE: { value: 'DE', label: 'Germany', continent: 'EU' },
  GH: { value: 'GH', label: 'Ghana', continent: 'AF' },
  GI: { value: 'GI', label: 'Gibraltar', continent: 'EU' },
  GR: { value: 'GR', label: 'Greece', continent: 'EU' },
  GL: { value: 'GL', label: 'Greenland', continent: 'NA' },
  GD: { value: 'GD', label: 'Grenada', continent: 'NA' },
  GP: { value: 'GP', label: 'Guadeloupe', continent: 'NA' },
  GU: { value: 'GU', label: 'Guam', continent: 'OC' },
  GT: { value: 'GT', label: 'Guatemala', continent: 'NA' },
  GG: { value: 'GG', label: 'Guernsey', continent: 'EU' },
  GN: { value: 'GN', label: 'Guinea', continent: 'OC' },
  GW: { value: 'GW', label: 'Guinea-Bissau', continent: 'AF' },
  GY: { value: 'GY', label: 'Guyana', continent: 'SA' },
  HT: { value: 'HT', label: 'Haiti', continent: 'NA' },
  HM: { value: 'HM', label: 'Heard Island and McDonald Islands', continent: 'AN' },
  VA: { value: 'VA', label: 'Holy See', continent: 'EU' },
  HN: { value: 'HN', label: 'Honduras', continent: 'NA' },
  HK: { value: 'HK', label: 'Hong Kong', continent: 'AS' },
  HU: { value: 'HU', label: 'Hungary', continent: 'EU' },
  IS: { value: 'IS', label: 'Iceland', continent: 'EU' },
  IN: { value: 'IN', label: 'India', continent: 'AS' },
  ID: { value: 'ID', label: 'Indonesia', continent: 'AS' },
  IR: { value: 'IR', label: 'Iran (Islamic Republic of)', continent: 'AS' },
  IQ: { value: 'IQ', label: 'Iraq', continent: 'AS' },
  IE: { value: 'IE', label: 'Ireland', continent: 'EU' },
  IM: { value: 'IM', label: 'Isle of Man', continent: 'EU' },
  IL: { value: 'IL', label: 'Israel', continent: 'AS' },
  IT: { value: 'IT', label: 'Italy', continent: 'EU' },
  JM: { value: 'JM', label: 'Jamaica', continent: 'NA' },
  JP: { value: 'JP', label: 'Japan', continent: 'AS' },
  JE: { value: 'JE', label: 'Jersey', continent: 'EU' },
  JO: { value: 'JO', label: 'Jordan', continent: 'AS' },
  KZ: { value: 'KZ', label: 'Kazakhstan', continent: 'AS' },
  KE: { value: 'KE', label: 'Kenya', continent: 'AF' },
  KI: { value: 'KI', label: 'Kiribati', continent: 'OC' },
  KP: { value: 'KP', label: "Korea (Democratic People's Republic of)", continent: 'AS' },
  KR: { value: 'KR', label: 'Korea (Republic of)', continent: 'AS' },
  KW: { value: 'KW', label: 'Kuwait', continent: 'AS' },
  KG: { value: 'KG', label: 'Kyrgyzstan', continent: 'AS' },
  LA: { value: 'LA', label: "Lao People's Democratic Republic", continent: 'AS' },
  LV: { value: 'LV', label: 'Latvia', continent: 'EU' },
  LB: { value: 'LB', label: 'Lebanon', continent: 'AS' },
  LS: { value: 'LS', label: 'Lesotho', continent: 'AF' },
  LR: { value: 'LR', label: 'Liberia', continent: 'AF' },
  LY: { value: 'LY', label: 'Libya', continent: 'AF' },
  LI: { value: 'LI', label: 'Liechtenstein', continent: 'EU' },
  LT: { value: 'LT', label: 'Lithuania', continent: 'EU' },
  LU: { value: 'LU', label: 'Luxembourg', continent: 'EU' },
  MO: { value: 'MO', label: 'Macao', continent: 'AS' },
  MK: { value: 'MK', label: 'Macedonia (the former Yugoslav Republic of)', continent: 'EU' },
  MG: { value: 'MG', label: 'Madagascar', continent: 'AF' },
  MW: { value: 'MW', label: 'Malawi', continent: 'AF' },
  MY: { value: 'MY', label: 'Malaysia', continent: 'AS' },
  MV: { value: 'MV', label: 'Maldives', continent: 'AS' },
  ML: { value: 'ML', label: 'Mali', continent: 'AF' },
  MT: { value: 'MT', label: 'Malta', continent: 'EU' },
  MH: { value: 'MH', label: 'Marshall Islands', continent: 'OC' },
  MQ: { value: 'MQ', label: 'Martinique', continent: 'NA' },
  MR: { value: 'MR', label: 'Mauritania', continent: 'AF' },
  MU: { value: 'MU', label: 'Mauritius', continent: 'AF' },
  YT: { value: 'YT', label: 'Mayotte', continent: 'AF' },
  MX: { value: 'MX', label: 'Mexico', continent: 'NA' },
  FM: { value: 'FM', label: 'Micronesia (Federated States of)', continent: 'OC' },
  MD: { value: 'MD', label: 'Moldova (Republic of)', continent: 'EU' },
  MC: { value: 'MC', label: 'Monaco', continent: 'EU' },
  MN: { value: 'MN', label: 'Mongolia', continent: 'AS' },
  ME: { value: 'ME', label: 'Montenegro', continent: 'EU' },
  MS: { value: 'MS', label: 'Montserrat', continent: 'NA' },
  MA: { value: 'MA', label: 'Morocco', continent: 'AF' },
  MZ: { value: 'MZ', label: 'Mozambique', continent: 'AF' },
  MM: { value: 'MM', label: 'Myanmar', continent: 'AS' },
  NA: { value: 'NA', label: 'Namibia', continent: 'AF' },
  NR: { value: 'NR', label: 'Nauru', continent: 'OC' },
  NP: { value: 'NP', label: 'Nepal', continent: 'AS' },
  NL: { value: 'NL', label: 'Netherlands', continent: 'EU' },
  NC: { value: 'NC', label: 'New Caledonia', continent: 'OC' },
  NZ: { value: 'NZ', label: 'New Zealand', continent: 'OC' },
  NI: { value: 'NI', label: 'Nicaragua', continent: 'NA' },
  NE: { value: 'NE', label: 'Niger', continent: 'AF' },
  NG: { value: 'NG', label: 'Nigeria', continent: 'AF' },
  NU: { value: 'NU', label: 'Niue', continent: 'OC' },
  NF: { value: 'NF', label: 'Norfolk Island', continent: 'OC' },
  MP: { value: 'MP', label: 'Northern Mariana Islands', continent: 'OC' },
  NO: { value: 'NO', label: 'Norway', continent: 'EU' },
  OM: { value: 'OM', label: 'Oman', continent: 'AS' },
  PK: { value: 'PK', label: 'Pakistan', continent: 'AS' },
  PW: { value: 'PW', label: 'Palau', continent: 'OC' },
  PS: { value: 'PS', label: 'Palestine, State of', continent: 'AS' },
  PA: { value: 'PA', label: 'Panama', continent: 'NA' },
  PG: { value: 'PG', label: 'Papua New Guinea', continent: 'OC' },
  PY: { value: 'PY', label: 'Paraguay', continent: 'SA' },
  PE: { value: 'PE', label: 'Peru', continent: 'SA' },
  PH: { value: 'PH', label: 'Philippines', continent: 'AS' },
  PN: { value: 'PN', label: 'Pitcairn', continent: 'OC' },
  PL: { value: 'PL', label: 'Poland', continent: 'EU' },
  PT: { value: 'PT', label: 'Portugal', continent: 'EU' },
  PR: { value: 'PR', label: 'Puerto Rico', continent: 'NA' },
  QA: { value: 'QA', label: 'Qatar', continent: 'AS' },
  RE: { value: 'RE', label: 'Réunion', continent: 'AF' },
  RO: { value: 'RO', label: 'Romania', continent: 'EU' },
  RU: { value: 'RU', label: 'Russian Federation', continent: 'EU' },
  RW: { value: 'RW', label: 'Rwanda', continent: 'AF' },
  BL: { value: 'BL', label: 'Saint Barthélemy', continent: 'NA' },
  SH: { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', continent: 'AF' },
  KN: { value: 'KN', label: 'Saint Kitts and Nevis', continent: 'NA' },
  LC: { value: 'LC', label: 'Saint Lucia', continent: 'NA' },
  MF: { value: 'MF', label: 'Saint Martin (French part)', continent: 'NA' },
  PM: { value: 'PM', label: 'Saint Pierre and Miquelon', continent: 'NA' },
  VC: { value: 'VC', label: 'Saint Vincent and the Grenadines', continent: 'NA' },
  WS: { value: 'WS', label: 'Samoa', continent: 'OC' },
  SM: { value: 'SM', label: 'San Marino', continent: 'EU' },
  ST: { value: 'ST', label: 'Sao Tome and Principe', continent: 'AF' },
  SA: { value: 'SA', label: 'Saudi Arabia', continent: 'AS' },
  SN: { value: 'SN', label: 'Senegal', continent: 'AF' },
  RS: { value: 'RS', label: 'Serbia', continent: 'EU' },
  SC: { value: 'SC', label: 'Seychelles', continent: 'AF' },
  SL: { value: 'SL', label: 'Sierra Leone', continent: 'AF' },
  SG: { value: 'SG', label: 'Singapore', continent: 'AS' },
  SX: { value: 'SX', label: 'Sint Maarten (Dutch part)', continent: 'NA' },
  SK: { value: 'SK', label: 'Slovakia', continent: 'EU' },
  SI: { value: 'SI', label: 'Slovenia', continent: 'EU' },
  SB: { value: 'SB', label: 'Solomon Islands', continent: 'OC' },
  SO: { value: 'SO', label: 'Somalia', continent: 'AF' },
  ZA: { value: 'ZA', label: 'South Africa', continent: 'AF' },
  GS: { value: 'GS', label: 'South Georgia and the South Sandwich Islands', continent: 'AN' },
  SS: { value: 'SS', label: 'South Sudan', continent: 'AF' },
  ES: { value: 'ES', label: 'Spain', continent: 'EU' },
  LK: { value: 'LK', label: 'Sri Lanka', continent: 'AS' },
  SD: { value: 'SD', label: 'Sudan', continent: 'AF' },
  SR: { value: 'SR', label: 'Suriname', continent: 'SA' },
  SJ: { value: 'SJ', label: 'Svalbard and Jan Mayen', continent: 'EU' },
  SE: { value: 'SE', label: 'Sweden', continent: 'EU' },
  CH: { value: 'CH', label: 'Switzerland', continent: 'EU' },
  SY: { value: 'SY', label: 'Syrian Arab Republic', continent: 'AS' },
  TW: { value: 'TW', label: 'Taiwan, Province of China', continent: 'AS' },
  TJ: { value: 'TJ', label: 'Tajikistan', continent: 'AS' },
  TZ: { value: 'TZ', label: 'Tanzania, United Republic of', continent: 'AF' },
  TH: { value: 'TH', label: 'Thailand', continent: 'AS' },
  TL: { value: 'TL', label: 'Timor-Leste', continent: 'AS' },
  TG: { value: 'TG', label: 'Togo', continent: 'AF' },
  TK: { value: 'TK', label: 'Tokelau', continent: 'OC' },
  TO: { value: 'TO', label: 'Tonga', continent: 'OC' },
  TT: { value: 'TT', label: 'Trinidad and Tobago', continent: 'SA' },
  TN: { value: 'TN', label: 'Tunisia', continent: 'AF' },
  TR: { value: 'TR', label: 'Turkey', continent: 'AS' },
  TM: { value: 'TM', label: 'Turkmenistan', continent: 'AS' },
  TC: { value: 'TC', label: 'Turks and Caicos Islands', continent: 'NA' },
  TV: { value: 'TV', label: 'Tuvalu', continent: 'OC' },
  UG: { value: 'UG', label: 'Uganda', continent: 'AF' },
  UA: { value: 'UA', label: 'Ukraine', continent: 'EU' },
  AE: { value: 'AE', label: 'United Arab Emirates', continent: 'AS' },
  GB: {
    value: 'GB',
    label: 'United Kingdom of Great Britain and Northern Ireland',
    continent: 'EU',
  },
  US: { value: 'US', label: 'United States of America', continent: 'NA' },
  UM: { value: 'UM', label: 'United States Minor Outlying Islands', continent: 'NA' },
  UY: { value: 'UY', label: 'Uruguay', continent: 'SA' },
  UZ: { value: 'UZ', label: 'Uzbekistan', continent: 'AS' },
  VU: { value: 'VU', label: 'Vanuatu', continent: 'OC' },
  VE: { value: 'VE', label: 'Venezuela (Bolivarian Republic of)', continent: 'SA' },
  VN: { value: 'VN', label: 'Viet Nam', continent: 'AS' },
  VG: { value: 'VG', label: 'Virgin Islands (British)', continent: 'NA' },
  VI: { value: 'VI', label: 'Virgin Islands (U.S.)', continent: 'NA' },
  WF: { value: 'WF', label: 'Wallis and Futuna', continent: 'OC' },
  EH: { value: 'EH', label: 'Western Sahara', continent: 'AF' },
  YE: { value: 'YE', label: 'Yemen', continent: 'AS' },
  ZM: { value: 'ZM', label: 'Zambia', continent: 'AF' },
  ZW: { value: 'ZW', label: 'Zimbabwe', continent: 'AF' },
};

export const US_STATES = {
  AL: { label: 'Alabama', value: 'AL' },
  AK: { label: 'Alaska', value: 'AK' },
  AZ: { label: 'Arizona', value: 'AZ' },
  AR: { label: 'Arkansas', value: 'AR' },
  CA: { label: 'California', value: 'CA' },
  CO: { label: 'Colorado', value: 'CO' },
  CT: { label: 'Connecticut', value: 'CT' },
  DE: { label: 'Delaware', value: 'DE' },
  FL: { label: 'Florida', value: 'FL' },
  GA: { label: 'Georgia', value: 'GA' },
  HI: { label: 'Hawaii', value: 'HI' },
  ID: { label: 'Idaho', value: 'ID' },
  IL: { label: 'Illinois', value: 'IL' },
  IN: { label: 'Indiana', value: 'IN' },
  IA: { label: 'Iowa', value: 'IA' },
  KS: { label: 'Kansas', value: 'KS' },
  KY: { label: 'Kentucky', value: 'KY' },
  LA: { label: 'Louisiana', value: 'LA' },
  ME: { label: 'Maine', value: 'ME' },
  MD: { label: 'Maryland', value: 'MD' },
  MA: { label: 'Massachusetts', value: 'MA' },
  MI: { label: 'Michigan', value: 'MI' },
  MN: { label: 'Minnesota', value: 'MN' },
  MS: { label: 'Mississippi', value: 'MS' },
  MO: { label: 'Missouri', value: 'MO' },
  MT: { label: 'Montana', value: 'MT' },
  NE: { label: 'Nebraska', value: 'NE' },
  NV: { label: 'Nevada', value: 'NV' },
  NH: { label: 'New Hampshire', value: 'NH' },
  NJ: { label: 'New Jersey', value: 'NJ' },
  NM: { label: 'New Mexico', value: 'NM' },
  NY: { label: 'New York', value: 'NY' },
  NC: { label: 'North Carolina', value: 'NC' },
  ND: { label: 'North Dakota', value: 'ND' },
  OH: { label: 'Ohio', value: 'OH' },
  OK: { label: 'Oklahoma', value: 'OK' },
  OR: { label: 'Oregon', value: 'OR' },
  PA: { label: 'Pennsylvania', value: 'PA' },
  RI: { label: 'Rhode Island', value: 'RI' },
  SC: { label: 'South Carolina', value: 'SC' },
  SD: { label: 'South Dakota', value: 'SD' },
  TN: { label: 'Tennessee', value: 'TN' },
  TX: { label: 'Texas', value: 'TX' },
  UT: { label: 'Utah', value: 'UT' },
  VT: { label: 'Vermont', value: 'VT' },
  VA: { label: 'Virginia', value: 'VA' },
  WA: { label: 'Washington', value: 'WA' },
  WV: { label: 'West Virginia', value: 'WV' },
  WI: { label: 'Wisconsin', value: 'WI' },
  WY: { label: 'Wyoming', value: 'WY' },
};
