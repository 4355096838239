import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import Bind from '../../infrastructure/Bind';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import InstrumentPicker from '../interactive/InstrumentPicker';

const BoundCryptoPicker = ({ className, name, label, state, nest, helpText, ...rest }) => {
  return (
    <FormGroup className={className}>
      <Label for={name}>{label}</Label>
      <Bind state={state} name={name} nest={nest} valueProp="selected">
        <InstrumentPicker type="crypto" inputId={name} {...rest} />
      </Bind>
      {helpText && <span className="form-text text-muted">{helpText}</span>}
    </FormGroup>
  );
};

BoundCryptoPicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.any,
};

export default BoundCryptoPicker;
