import './KYCLetterPage.css';

import React from 'react';

import logo from '../../assets/xcalibra-logo.png';
import { KYC_TRACKS } from '../../lib/backend';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import ErrorBox from '../widgets/presentational/ErrorBox';

const userId = props => props.match.params.id || null;

class KYCLetterPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcCustomerInfo */
      data: {},

      error: null,

      waiting: !!userId(props),
    };
  }

  get userId() {
    return userId(this.props);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (userId(prevProps) !== this.userId) {
      this.loadData();
    }
  }

  loadData() {
    if (!this.userId) {
      return;
    }
    this.promiseOrSetError(
      this.container.client.getCustomersInfo(this.userId, KYC_TRACKS.base)
    ).then(
      /** XcCustomerInfo */ ci => {
        if (!ci.kyc2_code) {
          return this.setState({
            error: {
              code: 400,
              message: `This customer hasn't requested KYC 2 letter verification`,
            },
          });
        }
        this.setState({ data: ci });
      }
    );
  }

  renderLetter() {
    return (
      <div className="KYCLetterPage-content">
        <p className="text-center">Po&#353;tovani/a {this.state.data.first_name},</p>
        <p>
          {this.container.settings.product_name} tim se potrudio da sto kra&#263;e &#269;ekate na
          verifikaciju Va&#353;eg naloga, tako da Vam ovim putem dostavljamo pored obave&#353;tenja
          i jedinstveni kod za aktiviranje naloga.{' '}
        </p>
        <p className="mt-5 text-center">
          <strong>Va&#353; jedinstevni kod:</strong>
        </p>
        <div className="p-4 text-center" style={{ border: '3px solid black' }}>
          <h3 className="mb-0 text-monospace">{this.state.data.kyc2_code}</h3>
        </div>
        <p className="mt-5">
          Po li&#269;nom prijemu ovog obave&#353;tenja, potpisivanjem prijemnice, neophodno je samo
          da se ulogujete na Va&#353; nalog na <u>trade.xcalibra.com</u> i da Va&#353; jedinstveni
          kod ukucate na web aplikaciji, nakon &#269;ega &#263;e Vam nalog biti verifikovan.{' '}
        </p>
        <p>Hvala na poverenju,</p>
        <div className="mt-2 text-center">
          <img style={{ width: '40%' }} src={logo} alt="logo" />
          <p className="mt-2">{this.container.settings.product_url}</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="KYCLetterPage">
        <AjaxWrapper state={this.boundState}>
          <ErrorBox error={this.state.error} size={ErrorBox.SIZES.large} />
          {!this.state.error && this.renderLetter()}
        </AjaxWrapper>
      </div>
    );
  }
}

export default KYCLetterPage;
