import PropTypes from 'prop-types';
import React from 'react';

import { ORDER_TYPES } from '../../lib/backend.js';
import Select from '../widgets/interactive/Select';

// we convert the order types to an array and remove the last (stop) value since it will not be used
const ORDER_TYPE_VALUES = Object.values(ORDER_TYPES).slice(0, -1);

const OrderTypePicker = props => {
  const loadOptions = () => {
    return ORDER_TYPE_VALUES.map(type => ({ type }));
  };

  const loadValue = () => {
    return props.selected ? { type: props.selected } : null;
  };

  const onChange = option => {
    if (props.onChange) {
      props.onChange(option ? option.type : null);
    }
  };

  return (
    <Select
      options={loadOptions()}
      onChange={onChange}
      value={loadValue()}
      placeholder={props.placeholder}
      inputId={props.inputId}
      getOptionLabel={type => <span>{type.type}</span>}
      getOptionValue={type => type.type}
      noOptionsMessage={() => 'No types'}
      isClearable
    />
  );
};

OrderTypePicker.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
};

export default OrderTypePicker;
