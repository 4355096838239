import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('ToolBar');

const ToolBarStrip = ({ side, children }) => {
  if (children) {
    children = React.Children.map(children, child => {
      return <div className="col-auto my-1 mx-2">{child}</div>;
    });
  }

  return <div className={classes(cn('strip'), 'form-row align-items-center mb-2')}>{children}</div>;
};

const ToolBar = ({ className, children }) => {
  if (children && React.Children.toArray(children)[0].type !== ToolBarStrip) {
    children = <ToolBarStrip>{children}</ToolBarStrip>;
  }

  return (
    <div
      className={classes(cn(), className, 'form-row align-items-center justify-content-between')}
    >
      {children}
    </div>
  );
};

ToolBar.Strip = ToolBarStrip;

export default ToolBar;
