import './SelectionSidebar.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('SelectionSidebar');

const wrap = (rest, content) => {
  const className = classes(cn(), rest.className);
  return (
    <div {...rest} className={className}>
      <div className={cn('content')}>{content}</div>
    </div>
  );
};

const SelectionSidebar = ({ selection, renderOne, renderMany, renderNone, ...rest }) => {
  if (!selection || !selection.length) {
    const noneContent = renderNone ? (
      renderNone()
    ) : (
      <div className={cn('no-data')}>Nothing is selected</div>
    );
    return wrap(rest, noneContent);
  }

  if (selection.length === 1) {
    return wrap(rest, renderOne(selection[0]));
  }

  return wrap(rest, renderMany(selection));
};

SelectionSidebar.propTypes = {
  className: PropTypes.string,
  selection: PropTypes.array,
  renderNone: PropTypes.func,
  renderOne: PropTypes.func.isRequired,
  renderMany: PropTypes.func.isRequired,
};

export default SelectionSidebar;
