/**
 * @callback fetchMethod
 * @param {Request|string} input?
 * @param {RequestInit} init?
 * @returns Promise<Response>
 */

/**
 * Service that is in charge of making HTTP requests.
 */
export class HttpService {
  constructor(urlResolver, logger, fetch) {
    /**
     * @type {UrlResolver}
     */
    this._urlResolver = urlResolver;

    /**
     * Logger instance
     * @type {Logger}
     */
    this._logger = logger ? logger.prefixed('Http') : null;

    /**
     * Method that match browser's fetch api. Can be replaced with something else during tests.
     * @type {fetchMethod}
     */
    this._fetch = fetch;

    ['_urlResolver', '_fetch', '_logger'].forEach(key => {
      if (!this[key]) {
        throw new HttpError(`Http option "${key}" is mandatory`);
      }
    });

    this._token = null;
    this._lastRequestId = 0;
  }

  /**
   * Access token to be used for requests that are marked as auth: true
   */
  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  _logRequest(requestId, verb, url, result, data) {
    const direction = result ? '<<<' : '>>>';
    result = result ? `: ${result}` : '';
    const message = `${requestId} ${direction} ${verb} ${url}${result}`;
    if (data) {
      this._logger.log(message, data);
    } else {
      this._logger.log(message);
    }
  }

  /**
   * Execute ajax request. Returns a promise.
   * This should probably be wrapped by some client service, with a nicer API.
   * @param {XcXcalibraClientRequest & {access_token}} req
   */
  request = req => {
    const accessToken = req.access_token || this.token;
    if (req.auth && !accessToken) {
      return Promise.reject(
        new HttpError(`Endpoint ${req.endpoint} requires authentication, but no auth token is set`)
      );
    }

    const requestId = ++this._lastRequestId;

    const url = this._urlResolver.resolve(req.endpoint, req.query);

    /** @type RequestInit */
    const fetchOptions = {};
    fetchOptions.method = req.verb;
    fetchOptions.headers = {};

    if (req.auth) {
      fetchOptions.headers['authorization'] = `Bearer ${accessToken}`;
    }

    if (req.body) {
      if (req.content_type === 'multipart') {
        fetchOptions.body = req.body;
      } else {
        fetchOptions.body = JSON.stringify(req.body);
        fetchOptions.headers['content-type'] = 'application/json';
      }
    }

    this._logRequest(requestId, req.verb, url, null, req.body);

    return this._fetch(url, fetchOptions)
      .then(res => {
        const contentType = res.headers.get('content-type');
        const parsePromise =
          contentType && contentType.includes('json') ? res.json() : Promise.resolve();

        return parsePromise.then(data => {
          if (res.status >= 400) {
            throw new HttpRequestError(data || res.statusText, res.status);
          }

          this._logRequest(requestId, req.verb, url, res.status, data);

          return data;
        });
      })
      .catch(err => {
        if (err.message === 'Failed to fetch') {
          // Presume this is a CORS error
          err = new HttpRequestError('Server cannot be reached', 503);
        }
        if (!(err instanceof HttpError)) {
          err = new HttpError(err);
        }
        this._logRequest(requestId, req.verb, url, `${err.code} ${err.message}`, err);

        throw err;
      });
  };

  /**
   * Call request() while providing your own auth token.
   * Tip: you can generate opts by calling nameOfMyApiMethodSpec().
   */
  customAuthRequest(access_token, opts) {
    return this.request({
      ...opts,
      access_token,
    });
  }
}

/**
 * Standard error produced by XcalibraClient
 */
export class HttpError extends Error {
  constructor(source, code) {
    super(source.message || source);
    this.code = code || source.code || 500;

    for (const key in source) {
      if (source.hasOwnProperty(key) && key !== 'message' && key !== 'code') {
        this[key] = source[key];
      }
    }
  }
}

export class HttpRequestError extends HttpError {}
