import React from 'react';
import { withRouter } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import { Card } from '../widgets/presentational/Card';
import CodeBlock from '../widgets/presentational/CodeBlock';
import ObjectInfo from '../widgets/presentational/ObjectInfo';

const cn = classNamer('AppInternalsPage');

class AppInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * @type {XcMayanAppInternals}
       */
      internals: null,
    };
  }

  loadData() {
    return this.promiseOrToast(this.container.client.getAppInternals()).then(internals => {
      this.setState({ internals });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  renderAppState(/** XcMayanAppInternals */ internals) {
    if (!internals) {
      return;
    }

    return (
      <Card title="App state">
        <ObjectInfo object={internals.state} />
      </Card>
    );
  }

  renderPackageInfo(/** XcMayanAppInternals */ internals) {
    if (!internals) {
      return;
    }

    return (
      <Card title="Package">
        <ObjectInfo object={internals.package} />
      </Card>
    );
  }

  renderSettings(/** XcMayanAppInternals */ internals) {
    if (!internals) {
      return;
    }

    return (
      <Card title="Settings">
        <CodeBlock>{JSON.stringify(internals.settings, null, '  ')}</CodeBlock>
      </Card>
    );
  }

  render() {
    const { internals } = this.state;

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_APP)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-3">
            {this.renderAppState(internals)}
            {this.renderPackageInfo(internals)}
            {this.renderSettings(internals)}
          </div>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(AppInternalsPage);
