import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

/**
 * @param {{customer: XcCustomerInfo}} props
 */
const CustomerKYC2Outcome = props => {
  const ci = props.customer;

  if (!ci.kyc2_created_at) {
    return null;
  }

  if (ci.kyc2_completed_at) {
    if (ci.kyc2_failure_message) {
      return (
        <IconLabel flavor={false} wrap>
          {ci.kyc2_failure_message}
        </IconLabel>
      );
    }

    return <IconLabel flavor={true}>GRANTED</IconLabel>;
  }

  return (
    <IconLabel flavor={FLAVORS.warning} icon={ICONS.warn}>
      PENDING
    </IconLabel>
  );
};

export default CustomerKYC2Outcome;
