import React from 'react';
import { Route, Switch } from 'react-router';

import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import ManagedBankTransfersEditPage from './ManagedBankTransfersEditPage';
import ManagedBankTransfersPage from './ManagedBankTransfersPage';
import ManagedBuyOrdersExpandedOrderPage from './ManagedBuyOrdersExpandedOrderPage';
import ManagedBuyOrdersPage from './ManagedBuyOrdersPage';
import ManagedUsersPage from './ManagedManageAccessPage';
import ManagedPartnerTradesEditPage from './ManagedPartnerTradesEditPage';
import ManagedPartnerTradesPage from './ManagedPartnerTradesPage';
import ManagedPartnerTransfersEditPage from './ManagedPartnerTransfersEditPage';
import ManagedPartnerTransfersPage from './ManagedPartnerTransfersPage';
import ManagedSellOrdersExpandedOrderPage from './ManagedSellOrdersExpandedOrderPage';
import ManagedSellOrdersPage from './ManagedSellOrdersPage';
import ManagedSellOrderWithdrawalPage from './ManagedSellOrderWithdrawalPage';
import ManagedWalletPage from './ManagedWalletPage';

class ManagedRouter extends ConnectedComponent {
  render() {
    return (
      <div className="ManagedRouter">
        <Breadcrumbed link={SECTIONS.managed}>
          <SubPager links={SECTIONS.managed.subsections} />
          <Switch>
            <Route
              path={routes.MANAGED}
              render={() => <StickyRedirect routes={SECTIONS.managed.subsections} />}
              exact
            />

            <Route path={routes.MANAGED_USERS} component={ManagedUsersPage} exact />
            <Route path={routes.MANAGED_WALLET} component={ManagedWalletPage} exact />

            <Route
              path={routes.MANAGED_BUY_ORDERS}
              render={() => (
                <Breadcrumbed link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_BUY_ORDERS)}>
                  <Switch>
                    <Route
                      path={routes.MANAGED_BUY_ORDERS_EXPANDED_ORDER}
                      component={ManagedBuyOrdersExpandedOrderPage}
                    />
                    <Route component={ManagedBuyOrdersPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.MANAGED_SELL_ORDERS}
              render={() => (
                <Breadcrumbed link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_SELL_ORDERS)}>
                  <Switch>
                    <Route
                      path={routes.MANAGED_SELL_ORDERS_WITHDRAW}
                      component={ManagedSellOrderWithdrawalPage}
                    />
                    <Route
                      path={routes.MANAGED_SELL_ORDERS_EXPANDED_ORDER}
                      component={ManagedSellOrdersExpandedOrderPage}
                    />
                    <Route component={ManagedSellOrdersPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.MANAGED_BANK_TRANSFERS}
              render={() => (
                <Breadcrumbed
                  link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_BANK_TRANSFERS)}
                >
                  <Switch>
                    <Route
                      path={routes.MANAGED_BANK_TRANSFERS_EDIT}
                      component={ManagedBankTransfersEditPage}
                    />
                    <Route
                      path={routes.MANAGED_BANK_TRANSFERS_NEW}
                      component={ManagedBankTransfersEditPage}
                    />
                    <Route component={ManagedBankTransfersPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.MANAGED_PARTNER_TRANSFERS}
              render={() => (
                <Breadcrumbed
                  link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_PARTNER_TRANSFERS)}
                >
                  <Switch>
                    <Route
                      path={routes.MANAGED_PARTNER_TRANSFERS_EDIT}
                      component={ManagedPartnerTransfersEditPage}
                    />
                    <Route
                      path={routes.MANAGED_PARTNER_TRANSFERS_NEW}
                      component={ManagedPartnerTransfersEditPage}
                    />
                    <Route component={ManagedPartnerTransfersPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.MANAGED_PARTNER_TRADES}
              render={() => (
                <Breadcrumbed
                  link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_PARTNER_TRADES)}
                >
                  <Switch>
                    <Route
                      path={routes.MANAGED_PARTNER_TRADES_EDIT}
                      component={ManagedPartnerTradesEditPage}
                    />
                    <Route
                      path={routes.MANAGED_PARTNER_TRADES_NEW}
                      component={ManagedPartnerTradesEditPage}
                    />
                    <Route component={ManagedPartnerTradesPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default ManagedRouter;
