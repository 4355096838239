import { ADMIN_PERMISSIONS } from './backend';
import { ICONS } from './icons';
import { routes } from './routes';

// *********************************************************************************************************************

export class LinkDefinition {
  constructor(route, title, icon, permissions) {
    this.route = route;
    this.title = title;
    this.icon = icon;
    this.permissions = permissions;
  }
}

// *********************************************************************************************************************

export class SiteSection extends LinkDefinition {
  /**
   * @param route
   * @param title
   * @param icon
   * @param {SiteSubSection[]} subsections
   */
  constructor(route, title, icon, subsections = []) {
    // Section has a superset of all permissions from its children
    let permissionSet = new Set();
    for (const subsection of subsections) {
      if (subsection.permissions) {
        for (const permission of subsection.permissions) {
          permissionSet.add(permission);
        }
      } else {
        // If any child has "any" permission, then the parent must too
        permissionSet = null;
      }
    }

    super(
      route,
      title,
      icon,
      permissionSet && permissionSet.size > 0 ? Array.from(permissionSet) : null
    );
    this.name = undefined;

    /**
     * @type {SiteSubSection[]}
     */
    this.subsections = subsections;
  }

  /**
   * @return {SiteSubSection}
   */
  subsectionAtRoute(route) {
    const subsection = this.subsections.find(ss => ss.route === route);
    if (!subsection) {
      throw new Error(`No subsection at route ${route} within "${this.title}" section`);
    }
    return subsection;
  }
}

export class SiteSubSection extends LinkDefinition {
  constructor(route, title, description, permissions) {
    super(route, title, null, permissions);
    this.description = description;
  }
}

export const SECTIONS = {
  home: new SiteSection(routes.HOME, 'Home', ICONS.home),

  users: new SiteSection(routes.USERS, 'Users', ICONS.users, [
    new SiteSubSection(
      routes.USERS_ACCOUNTS,
      'Accounts',
      'Create, update, delete users. Security admin can also manage 2FA, email verification and suspensions.',
      [ADMIN_PERMISSIONS.users]
    ),
    new SiteSubSection(
      routes.CUSTOMERS,
      'Customers',
      'Customer records and KYC process management for Xcalibra.',
      [ADMIN_PERMISSIONS.kyc_associate]
    ),
    new SiteSubSection(
      routes.VQF_CUSTOMERS,
      'VQF Customers',
      'Customer records and KYC process management for VQF / Managed.',
      [ADMIN_PERMISSIONS.kyc_associate, ADMIN_PERMISSIONS.kyc_officer]
    ),
    new SiteSubSection(
      routes.MICROPAYMENTS,
      'Micropayments',
      'Add, edit and delete KYC3 micropayments. Includes currently inactive micropayments too.',
      [ADMIN_PERMISSIONS.kyc_associate, ADMIN_PERMISSIONS.kyc_officer]
    ),
    new SiteSubSection(
      routes.USERS_SESSIONS,
      'Sessions',
      'See active and past sessions. Security admin can force-terminate sessions.',
      [ADMIN_PERMISSIONS.security, ADMIN_PERMISSIONS.user_activity]
    ),
    new SiteSubSection(
      routes.USERS_MARKET_CONFIGS,
      'Market configs',
      'Enable or disable trading, and define trade fees for each user individually.',
      [ADMIN_PERMISSIONS.security, ADMIN_PERMISSIONS.incentives]
    ),
    new SiteSubSection(
      routes.USERS_INSTRUMENT_CONFIGS,
      'Instrument configs',
      'Enable or disable deposits and withdrawals for each user individually.',
      [ADMIN_PERMISSIONS.security]
    ),
    new SiteSubSection(routes.USERS_BOTS, 'Bots', `See, create, update and delete users' bots.`, [
      ADMIN_PERMISSIONS.security,
    ]),
    new SiteSubSection(
      routes.USERS_SECURITY_EVENTS,
      'Security events',
      `View users' security events.`,
      [ADMIN_PERMISSIONS.security, ADMIN_PERMISSIONS.user_activity]
    ),
    new SiteSubSection(
      routes.USERS_PHONE_VERIFICATIONS,
      'Phone verifications',
      `View users' phone verification requests and their outcomes.`,
      [ADMIN_PERMISSIONS.security]
    ),
  ]),

  trollbox: new SiteSection(routes.TROLLBOX, 'Trollbox', ICONS.trollbox, [
    new SiteSubSection(
      routes.TROLLBOX_USERS,
      'Users',
      `Examine users' trollbox status. Issue or remove bans. Approve nickname changes.`,
      [ADMIN_PERMISSIONS.trollbox]
    ),
    new SiteSubSection(
      routes.TROLLBOX_MESSAGES,
      'Messages',
      'Examine and flag trollbox messages. You can also issue user bans from here.',
      [ADMIN_PERMISSIONS.trollbox]
    ),
    new SiteSubSection(
      routes.TROLLBOX_PROFANITY,
      'Profanity',
      'Add or remove profanity words that will be censored in chat.',
      [ADMIN_PERMISSIONS.developer]
    ),
  ]),

  trading: new SiteSection(routes.TRADING, 'Trading', ICONS.trading, [
    new SiteSubSection(routes.TRADING_TRADES, 'Trades', 'View trades and trade statistics.', [
      ADMIN_PERMISSIONS.finances,
    ]),
    new SiteSubSection(
      routes.TRADING_GROWTH_TIMELINE,
      'Growth timeline',
      'Timeline of volume, revenue and user counts on the exchange',
      [ADMIN_PERMISSIONS.finances]
    ),
  ]),

  transactions: new SiteSection(routes.TRANSACTIONS, 'Transactions', ICONS.transactions, [
    new SiteSubSection(routes.TRANSACTIONS_DEPOSITS, 'Deposits', 'View deposits.', [
      ADMIN_PERMISSIONS.transactions,
    ]),
    new SiteSubSection(routes.TRANSACTIONS_WITHDRAWALS, 'Withdrawals', 'View withdrawals.', [
      ADMIN_PERMISSIONS.transactions,
    ]),
    new SiteSubSection(
      routes.TRANSACTIONS_CARD_PAYMENTS,
      'Card payments',
      'View simplex card payments.',
      [ADMIN_PERMISSIONS.transactions]
    ),
    new SiteSubSection(
      routes.TRANSACTIONS_TRANSFERS,
      'Transfers',
      'View internal transfers (stemming from deposits, withdrawals and other sources).',
      [ADMIN_PERMISSIONS.transactions]
    ),
    new SiteSubSection(
      routes.TRANSACTIONS_TRANSFER_TIMELINE,
      'Transfer timeline',
      'View timeline of deposits and withdrawals, and how they influenced revenue',
      []
    ),
  ]),

  managed: new SiteSection(routes.MANAGED, 'Managed', ICONS.managed, [
    new SiteSubSection(
      routes.MANAGED_USERS,
      'Users',
      'Allow or deny users access to Xcalibra Managed.',
      [ADMIN_PERMISSIONS.managed]
    ),
    new SiteSubSection(routes.MANAGED_WALLET, 'Wallet', 'View managed user wallets.', [
      ADMIN_PERMISSIONS.managed,
    ]),
    new SiteSubSection(
      routes.MANAGED_BUY_ORDERS,
      'Buy orders',
      'Main interface for handling Xcalibra Managed buy orders.',
      [ADMIN_PERMISSIONS.managed]
    ),
    new SiteSubSection(
      routes.MANAGED_SELL_ORDERS,
      'Sell orders',
      'Main interface for handling Xcalibra Managed sell orders.',
      [ADMIN_PERMISSIONS.managed]
    ),
    new SiteSubSection(
      routes.MANAGED_BANK_TRANSFERS,
      'Bank transfers',
      'Add, edit and delete bank transfers made during fulfillment of Xcalibra Managed orders.',
      [ADMIN_PERMISSIONS.managed]
    ),
    new SiteSubSection(
      routes.MANAGED_PARTNER_TRANSFERS,
      'Partner transfers',
      'Add, edit and delete transfers to and from partner exchanges done during fulfillment of Xcalibra Managed orders.',
      [ADMIN_PERMISSIONS.managed]
    ),
    new SiteSubSection(
      routes.MANAGED_PARTNER_TRADES,
      'Partner trades',
      'Add, edit and delete internal trades made on partner exchanges during fulfillment of Xcalibra Managed orders.',
      [ADMIN_PERMISSIONS.managed]
    ),
  ]),

  settings: new SiteSection(routes.SETTINGS, 'Settings', ICONS.settings, [
    new SiteSubSection(
      routes.SETTINGS_MARKET,
      'Market configs',
      'Enable/disable trading, set trade fee and minimal order size, per market or globally.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.SETTINGS_INSTRUMENT,
      'Instrument configs',
      'Enable, disable and set fee (absolute + percentage) for deposits and withdrawals, per instrument or globally.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.SETTINGS_REGISTRATION,
      'Registration',
      'Define registration policy. Which IP-s can register? Which countries? Is captcha required?',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.SETTINGS_MANAGED,
      'Managed',
      'Set which instruments are supported, payment/payout limits and buy/sell fee percentages.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.SETTINGS_PRICE_ESTIMATOR,
      'Price estimator',
      'Set market depth limit and maximum price increase percent per instrument. Also set if the instrument is partner provided.',
      [ADMIN_PERMISSIONS.developer]
    ),
  ]),

  internals: new SiteSection(routes.INTERNALS, 'Internals', ICONS.internals, [
    new SiteSubSection(
      routes.INTERNALS_APP,
      'App',
      'View app state, package information and currently loaded settings.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.INTERNALS_EXCHANGE,
      'Exchange',
      'Exchange status information. Also unlock trading and preform exchange audit.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.INTERNALS_MARKETS,
      'Markets',
      'Current state of order books. Manually trigger snapshotting.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(routes.INTERNALS_MAILER, 'Mailer', 'Send test emails.', [
      ADMIN_PERMISSIONS.developer,
    ]),
    new SiteSubSection(
      routes.INTERNALS_MAILCHIMP,
      'Mailchimp',
      'Setup mailchimp custom data fields and sync customer data with mailchimp. Also review all failed syncs.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.INTERNALS_LOGGER,
      'Logger',
      'See current logger status. Set global and per-service logger level while the app is running (the setting is lost after restart).',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(
      routes.INTERNALS_WALLETS,
      'Wallets',
      'Internal view into user wallets (primitive). Execute audits and manage snapshots.',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(routes.INTERNALS_DOCUMENTS, 'Documents', 'See all uploaded documents', [
      ADMIN_PERMISSIONS.developer,
    ]),
    new SiteSubSection(
      routes.INTERNALS_MAINTENANCE,
      'Maintenance',
      'Perform one-off migrations and other tasks',
      [ADMIN_PERMISSIONS.developer]
    ),
    new SiteSubSection(routes.INTERNALS_ACTIVITY, 'Activity', 'View admin activity.', [
      ADMIN_PERMISSIONS.developer,
    ]),
  ]),
};

/**
 * @type {SiteSection[]}
 */
export const SECTION_LIST = [];
for (const key in SECTIONS) {
  // Assign section name based on the key in lookup
  SECTIONS[key].name = key;
  SECTION_LIST.push(SECTIONS[key]);
}
