import './BoundConfirmationModal.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { classNamer, classes } from '../../../lib/tools';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import BoundModal from './BoundModal';

const cn = classNamer('BoundConfirmationModal');

/**
 * @param {BoundConfirmationModalProps} props
 * @param {Array<number>} selection List of selected indexes that has triggered the modal to be shown
 * @param {Array<*>} items List of object corresponding to selected indexes
 */
const renderActionDescription = (props, selection, items) => {
  if (!selection || !items) {
    return null;
  }

  if (selection.length === 1) {
    const item = items[selection[0]];
    if (!item) {
      // Something went wrong? Let's keep it safe
      return null;
    }

    const itemDescription = props.formatItem(item);
    return (
      <p>
        You are about to {props.actionText} {props.typeSingular} {itemDescription}
      </p>
    );
  }

  const actionItems = selection.map(index => {
    return <li key={index}>{props.formatItem(items[index])}</li>;
  });

  const typePlural = props.typePlural || props.typeSingular + 's';

  return (
    <div>
      You are about to {props.actionText}{' '}
      <strong>
        {selection.length} {typePlural}
      </strong>
      :<ul className={cn('action-list')}>{actionItems}</ul>
    </div>
  );
};

const BoundConfirmationModal = props => {
  return (
    <BoundModal
      state={props.state}
      sentinelKey={props.actionKey}
      className={classes(cn(), props.className)}
      callback={(result, { sentinel }) => {
        if (result) {
          // Call confirmed, with sentinel (which, in this case, is selected items)
          props.onConfirmed(sentinel);
        }
      }}
      captureData={state => (state[props.dataKey] && state[props.dataKey].items) || []}
    >
      {({ sentinel, data, callback }) => (
        <>
          <ModalHeader toggle={() => callback(false)}>{props.title}</ModalHeader>
          <ModalBody>
            <div>{renderActionDescription(props, sentinel, data)}</div>
            {props.children}
            {props.bottomText && <p className="mt-2">{props.bottomText}</p>}
          </ModalBody>
          <ModalFooter>
            <Button className="mr-2" color={props.actionFlavor} onClick={() => callback(true)}>
              {props.buttonActionText}
            </Button>
            <Button color="secondary" onClick={() => callback(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </BoundModal>
  );
};

BoundConfirmationModal.propTypes = /** @lends BoundConfirmationModalProps.prototype */ {
  className: PropTypes.string,
  state: PropTypes.instanceOf(BoundState).isRequired,
  formatItem: PropTypes.func.isRequired,
  title: PropTypes.string,
  actionKey: PropTypes.string,
  dataKey: PropTypes.string,
  typeSingular: PropTypes.string,
  typePlural: PropTypes.string,
  actionFlavor: PropTypes.string,
  actionText: PropTypes.string,
  buttonActionText: PropTypes.string,
  bottomText: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
};

BoundConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  dataKey: 'data',
  typeSingular: 'record',
  actionFlavor: FLAVORS.primary,
  actionText: 'change',
  buttonActionText: 'Proceed',
  bottomText: 'Do you wish to proceed?',
};

export default BoundConfirmationModal;
