import { createSelector } from 'reselect';

const ENV_PREFIX = 'REACT_APP_';
const keyToEnvKey = key => ENV_PREFIX + key.toUpperCase();

const ADDITIONAL_ENV_KEYS = ['NODE_ENV', 'PUBLIC_URL'];

// TODO: Maybe switch this to camelCase, to make it easier to deconstruct?

export class Settings {
  constructor() {
    this.node_env = 'development';
    this.backend_url = '';
    this.socket_url = '';
    this.pair_list = [];
    this.interface_criticality = 'green';
    this.product_name = 'xcalibra';
    this.product_url = 'xcalibra.com';
    this.recaptcha_site_key = '';
    this.micropayment_currencies = [];
    this.micropayment_default_recipient_account = '';
  }

  get isDev() {
    return this.node_env === 'development';
  }

  get isProd() {
    return this.node_env === 'production';
  }

  get socketUrl() {
    return this.socket_url || this.backend_url;
  }

  _pairsSelector = createSelector(
    pairList => pairList,
    pairList =>
      pairList.reduce((res, pair) => {
        res[pair] = pair;
        return res;
      }, {})
  );

  get pairs() {
    return this._pairsSelector(this.pair_list);
  }

  _instrumentsSelector = createSelector(
    pairList => pairList,
    pairList =>
      pairList.reduce((res, pair) => {
        const [quote, base] = pair.split('_');
        res[base] = base;
        res[quote] = quote;
        return res;
      }, {})
  );

  /**
   * @type {Object.<string, string>}
   */
  get instruments() {
    return this._instrumentsSelector(this.pair_list);
  }

  _instrumentListSelector = createSelector(
    instruments => instruments,
    instruments => Object.keys(instruments)
  );

  /**
   * @type {string[]}
   */
  get instrument_list() {
    return this._instrumentListSelector(this.instruments);
  }

  setFromEnv(env) {
    env = env || process.env;

    Object.keys(this).forEach(key => {
      if (key[0] === '_') {
        // De-privatize property name
        key = key.substring(1);
      }
      this._coerceAndSet(key, env[keyToEnvKey(key)]);
    });

    ADDITIONAL_ENV_KEYS.forEach(envKey => {
      this._coerceAndSet(envKey.toLowerCase(), env[envKey]);
    });

    this.assert();
  }

  _coerceAndSet(key, value) {
    if (value === undefined) {
      return;
    }

    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    } else if (typeof this[key] === 'number') {
      value = parseInt(value, 10);
    }

    if (Array.isArray(this[key])) {
      value = value.split(',');
    }

    this[key] = value;
  }

  assert() {
    if (!this.recaptcha_site_key) {
      throw new ENVNotSetError('recaptcha_site_key');
    }

    if (!this.backend_url) {
      throw new ENVNotSetError('backend_url');
    }

    if (!this.pair_list || !this.pair_list.length) {
      throw new ENVNotSetError('pair_list');
    }

    if (!this.micropayment_currencies || !this.micropayment_currencies.length) {
      throw new ENVNotSetError('micropayment_currencies');
    }
  }
}

class ENVNotSetError extends Error {
  constructor(key) {
    super(`Environmental variable "${keyToEnvKey(key)}" is not set`);
    this.key = key;
  }
}
