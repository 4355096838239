export const fromDisabledToReasonKey = disabledKey => disabledKey + '_reason';

export const setDisabled = (target, state, key) => {
  const reasonKey = fromDisabledToReasonKey(key);

  if (state[reasonKey] !== undefined) {
    target[reasonKey] = state[reasonKey] || null;
  }

  if (state[key] !== undefined) {
    // False turns into null, so we can fall back. We never want to see "true" in DB for this.
    const disabled = state[key] ? true : null;
    target[key] = disabled;

    if (disabled === null) {
      // When re-enabling, delete the reason message
      target[reasonKey] = null;
    }
  }
};

export const setNumeric = (target, state, key, cast) => {
  if (state[key] !== undefined) {
    target[key] = state[key] ? (cast ? cast(state[key]) : state[key]) : null;
  }
};
