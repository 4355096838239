import PropTypes from 'prop-types';
import React from 'react';

import { XcCustomerInfosCriteria } from '../../../lib/backend';
import { customerName } from '../../../lib/selectors';
import { toastError } from '../../../lib/toasts';
import { promiseDebouncer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Select from '../../widgets/interactive/Select';
import KYCLevelIcon from '../../widgets/presentational/KYCLevelIcon';

const SHOW_ITEMS = 100;

const getCustomerInfoLabel = (/** XcCustomerInfo */ ci) => {
  return (
    <span>
      <KYCLevelIcon level={ci.kyc_level_granted} className="mr-1" /> {customerName(ci)} (
      <strong>{ci.user_id}</strong>)
    </span>
  );
};

export default class CustomerPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    kycTrack: PropTypes.string.isRequired,
    selectedId: PropTypes.any,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      /**
       * @type {XcCustomerInfo}
       */
      selectedCustomerInfo: null,
    };
  }

  componentDidMount() {
    if (this.props.selectedId) {
      this.loadSelectedCustomer();
    }
  }

  loadSelectedCustomer() {
    this.promiseOrToast(
      this.container.client.getCustomersInfo(this.props.selectedId, this.props.kycTrack)
    ).then(selectedCustomerInfo => {
      this.setState({ selectedCustomerInfo });
    });
  }

  loadOptions = inputValue => {
    const criteria = new XcCustomerInfosCriteria({
      filter: inputValue,
      page_size: SHOW_ITEMS,
      page: 1,
      sort_direction: 'asc',
      sort_field: 'email',
      kyc_track: this.props.kycTrack,
    });

    return this.container.client.getCustomersInfos(criteria).then(
      data => {
        return data.items;
      },
      err => {
        toastError(err);
        throw err;
      }
    );
  };

  loadOptionsDebounced = promiseDebouncer(this.loadOptions, 1000);

  componentDidUpdate = prevProps => {
    if (this.props.selectedId !== prevProps.selectedId) {
      // We have changed selected id
      if (!this.props.selectedId && this.state.selectedCustomerInfo) {
        this.setState({
          // Deselect
          selectedCustomerInfo: null,
        });
      } else if (
        this.props.selectedId &&
        (!this.state.selectedCustomerInfo ||
          this.props.selectedId !== this.state.selectedCustomerInfo.user_id)
      ) {
        // Load the option we need
        this.loadSelectedCustomer();
      }
    }
  };

  onChange = selectedCustomerInfo => {
    this.setState({
      selectedCustomerInfo,
    });
    if (this.props.onChange) {
      this.props.onChange(selectedCustomerInfo ? selectedCustomerInfo.user_id : null);
    }
  };

  render() {
    return (
      <Select
        async
        loadOptions={this.loadOptionsDebounced}
        defaultOptions
        onChange={this.onChange}
        value={this.state.selectedCustomerInfo}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={getCustomerInfoLabel}
        getOptionValue={ci => ci.user_id}
        noOptionsMessage={() => 'No customers'}
        isClearable
      />
    );
  }
}
