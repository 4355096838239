import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import Bind from '../../infrastructure/Bind';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import DateInput from '../interactive/DateInput';

const BoundDateInput = ({
  className,
  name,
  label,
  state,
  nest,
  dateFormat,
  helpText = 'Format should be: YYYY/MM/DD',
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      <Label for={name}>{label}</Label>
      <Bind state={state} nest={nest} name={name} valueProp="date">
        <DateInput dateFormat={dateFormat} name={name} id={name} {...rest} />
      </Bind>
      {helpText && <span className="form-text text-muted">{helpText}</span>}
    </FormGroup>
  );
};

BoundDateInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.any,
  setCurrentLabel: PropTypes.string,
};

export default BoundDateInput;

// *********************************************************************************************************************

export const BoundDateWithTimeInput = props => {
  return (
    <BoundDateInput
      helpText={'Format should be: YYYY/MM/DD HH:mm:ss'}
      dateFormat="yyyy/MM/dd HH:mm:ss"
      setCurrentLabel={'Now'}
      {...props}
    />
  );
};

BoundDateWithTimeInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.any,
  setCurrentLabel: PropTypes.string,
};
