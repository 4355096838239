import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from './IconLabel';

const EmailVerifiedStatus = props => {
  /** @type {XcUser} */
  const user = props.user;

  if (user.email_verified_at) {
    return <IconLabel flavor={true}>Verified</IconLabel>;
  }

  if (user.email_verification_requested_at) {
    return (
      <IconLabel icon={ICONS.warn} flavor={FLAVORS.warning}>
        Unverified
      </IconLabel>
    );
  }

  return <IconLabel flavor={FLAVORS.danger}>No email sent</IconLabel>;
};

export default EmailVerifiedStatus;
