import React from 'react';

import { MicropaymentDocumentPopoutLink } from '../../documents/DocumentPopoutLink';

const MicropaymentDocumentList = ({ documents, fallback = null }) => {
  return (
    <div className={'MicropaymentDocumentList'}>
      {documents && documents.length
        ? documents.map(document => (
            <div key={document.id} className="my-1">
              <MicropaymentDocumentPopoutLink document={document} />
            </div>
          ))
        : fallback}
    </div>
  );
};

export default MicropaymentDocumentList;
