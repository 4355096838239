import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';

import { XcSetOrdersSuspendedPayload } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { routes } from '../../../lib/routes';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import GridLayout from '../../layout/GridLayout';
import SelectionSidebar from '../../layout/SelectionSidebar';
import BoundDangerModal from '../../widgets/bound/BoundDangerModal';
import IconButton from '../../widgets/interactive/IconButton';
import ThreeStateCheckbox from '../../widgets/interactive/ThreeStateCheckbox';
import ObjectInfo from '../../widgets/presentational/ObjectInfo';

const cn = classNamer('ManagedOrderActionWidget');

export default class ManagedOrderActionWidget extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      executing: null,

      finalizing: null,
      finalizeAsFailed: false,
      failureReason: '',

      settingSuspendedSelection: null,
      settingSuspendedValue: null,
      suspensionReason: '',

      // sell specific
      refunding: null,

      // buy specific
      paying: null,
    };
  }

  static propTypes = {
    orderType: PropTypes.oneOf(['buy', 'sell']).isRequired,
    data: PropTypes.object,
    isExpandedView: PropTypes.bool,
    selection: PropTypes.array.isRequired,
    loadData: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      data: nextProps.data,
    };
  }

  executeAndReloadOrToast = (clientCall, notificationMessage) => {
    return this.promiseOrToast(clientCall).then(() => {
      toast.success(notificationMessage);
      this.props.loadData();
    });
  };

  get isBuyOrderWidget() {
    return this.props.orderType === 'buy';
  }

  /***********************************************************************/
  setSuspendedForSelectedOrders = (suspended = true) => {
    this.setState({
      settingSuspendedSelection: this.props.selection.filter(index => {
        const order = this.props.data.items[index];
        if (order.cancelled_at || order.completed_at) {
          return false;
        }

        return suspended ? !order.suspended_at : !!order.suspended_at;
      }),
      settingSuspendedValue: suspended,
    });
  };

  confirmSetSuspended = selection => {
    const payload = new XcSetOrdersSuspendedPayload({
      order_ids: selection.map(index => this.props.data.items[index].id),
      suspend: this.state.settingSuspendedValue,
    });

    if (payload.suspend) {
      payload.suspension_reason = this.state.suspensionReason;
    }

    const promise = this.isBuyOrderWidget
      ? this.container.client.putBrokerOrdersBuySuspend(payload)
      : this.container.client.putBrokerOrdersSellSuspend(payload);
    const action = payload.suspend ? 'suspended' : 'unsuspended';
    return this.executeAndReloadOrToast(
      promise,
      payload.order_ids.length > 1
        ? `${payload.order_ids.length} orders were ${action}`
        : `Order has been ${action}`
    );
  };

  executeSelectedOrders = () => {
    this.setState({
      executing: this.props.selection.filter(index => {
        const order = this.props.data.items[index];
        return !(order.cancelled_at || order.completed_at || order.taken_at || order.suspended_at);
      }),
    });
  };

  confirmExecute = selection => {
    const ids = selection.map(index => this.props.data.items[index].id);
    const promise = this.isBuyOrderWidget
      ? this.container.client.putBrokerOrdersBuyTake(ids)
      : this.container.client.putBrokerOrdersSellTake(ids);

    return this.executeAndReloadOrToast(
      promise,
      ids.length > 1
        ? `${ids.length} orders taken into processing`
        : `Order has been taken into processing`
    );
  };

  finalizeSelectedOrders = () => {
    this.setState({
      finalizing: this.props.selection.filter(index => {
        const order = this.props.data.items[index];
        return !(order.cancelled_at || order.completed_at);
      }),
    });
  };

  confirmFinalize = selection => {
    const ids = selection.map(index => this.props.data.items[index].id);
    const actionKeyword = this.state.failureReason
      ? 'finalized as failed'
      : 'successfully finalized';
    const clientCall = this.isBuyOrderWidget
      ? this.container.client.putBrokerOrdersBuyFinalize
      : this.container.client.putBrokerOrdersSellFinalize;
    const payload = { order_ids: ids };
    if (this.state.finalizeAsFailed) {
      payload.failure_reason = this.state.failureReason;
    }

    const promise = clientCall(payload);
    return this.executeAndReloadOrToast(
      promise,
      ids.length > 1 ? `${ids.length} ${actionKeyword}` : `Order has been ${actionKeyword}`
    );
  };

  downloadReport = () => {
    const order = this.props.data.items[0];
    return this.promiseOrToast(this.container.client.getBrokerPrepareReport(order.id)).then(
      download => {
        const url = this.container.clientSpec.getBrokerDownloadReport(download.id).url;
        window.location.href = url;
      }
    );
  };

  generateReport = () => {
    const order = this.props.data.items[0];
    return this.promiseOrToast(this.container.client.putBrokerGenerateReport(order.id)).then(() => {
      toast.success('Report has been successfully generated');
    });
  };

  /***********************************************************************/
  // Buy orders

  renderBuyOrder = (/** XcManagedBuyOrder */ order) => (
    <span>
      for{' '}
      <strong>
        {order.quantity} {order.currency}
      </strong>{' '}
      (id: {order.id}) {order.user_email && `by user ${order.user_email}`}
    </span>
  );

  markPaymentReceivedSelectedOrders = () => {
    this.setState({
      paying: this.props.selection.filter(index => {
        const order = this.props.data.items[index];
        return !(order.cancelled_at || order.completed_at || order.payment_received_at);
      }),
    });
  };

  confirmPaymentReceived = selection => {
    const ids = selection.map(index => this.props.data.items[index].id);
    const promise = this.container.client.putBrokerOrdersBuyPay(ids);
    return this.executeAndReloadOrToast(
      promise,
      ids.length > 1 ? `${ids.length} orders marked as paid` : `Order has been marked as paid`
    );
  };

  renderActionWidgetForBuyOrder = (/** XcManagedBuyOrder */ order) => {
    if (order.cancelled_at || order.completed_at) {
      return null;
    }

    if (!order.payment_received_at && !order.taken_at) {
      return (
        <IconButton
          title="Mark as paid"
          color={FLAVORS.primary}
          onClick={this.markPaymentReceivedSelectedOrders}
          className="flex-fill"
        >
          Mark as paid
        </IconButton>
      );
    }

    if (!order.taken_at) {
      return (
        <IconButton
          title="Execute order"
          color={FLAVORS.primary}
          onClick={this.executeSelectedOrders}
          icon={ICONS.play}
          className="flex-fill"
        >
          Execute
        </IconButton>
      );
    }

    return (
      <IconButton
        className="flex-fill"
        onClick={this.finalizeSelectedOrders}
        color={FLAVORS.primary}
      >
        Finalize
      </IconButton>
    );
  };

  renderActionWidgetForManyBuyOrders = selection => {
    const orders = this.props.data.items;
    const executeDisabled = !selection.some(index => {
      const order = orders[index];
      return !order.taken_at && !order.cancelled_at && !order.completed_at;
    });
    const finalizeDisabled = !selection.some(
      index => !orders[index].cancelled_at && !orders[index].completed_at
    );
    const payDisabled = !selection.some(
      index => !orders[index].payment_received_at && !orders[index].taken_at
    );
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Pay orders"
              color={FLAVORS.primary}
              onClick={this.markPaymentReceivedSelectedOrders}
              className="flex-fill"
              disabled={payDisabled}
            >
              Mark as paid
            </IconButton>
            <IconButton
              title="Execute orders"
              color={FLAVORS.primary}
              onClick={this.executeSelectedOrders}
              className="flex-fill mt-2"
              disabled={executeDisabled}
            >
              Execute
            </IconButton>
            <IconButton
              title="Fulfill orders"
              color={FLAVORS.primary}
              onClick={this.finalizeSelectedOrders}
              className="flex-fill mt-2"
              disabled={finalizeDisabled}
            >
              Finalize
            </IconButton>
          </div>
        </Expander>
        {this.renderSuspension()}
      </div>
    );
  };

  renderActionWidgetForExpandedBuyOrder = (/** XcManagedBuyOrder */ order) => {
    if (order.cancelled_at || order.failure_reason) {
      return null;
    }

    if (!order.payment_received_at && !order.taken_at) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Mark as paid"
              color={FLAVORS.primary}
              onClick={this.markPaymentReceivedSelectedOrders}
              className="flex-fill"
            >
              Mark as paid
            </IconButton>
          </div>
        </Expander>
      );
    }

    if (!order.taken_at) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Execute order"
              color={FLAVORS.primary}
              onClick={this.executeSelectedOrders}
              icon={ICONS.play}
              className="flex-fill"
            >
              Execute
            </IconButton>
            <div className="hr-text my-2" data-content="OR" />
            <IconButton
              className="flex-fill"
              onClick={this.finalizeSelectedOrders}
              color={FLAVORS.primary}
            >
              Finalize
            </IconButton>
          </div>
        </Expander>
      );
    }

    if (order.completed_at) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Download report"
              color={FLAVORS.primary}
              onClick={this.downloadReport}
              icon={ICONS.download}
              className="flex-fill"
            >
              Download report
            </IconButton>
            <IconButton
              title="Generate report"
              color={FLAVORS.primary}
              onClick={this.generateReport}
              icon={ICONS.reset}
              className="flex-fill mt-2"
            >
              Regenerate report
            </IconButton>
          </div>
        </Expander>
      );
    }

    return (
      <Expander title="Actions" memoryKey={cn('actions')}>
        <div className="d-flex flex-column">
          <IconButton
            className="flex-fill"
            onClick={this.finalizeSelectedOrders}
            color={FLAVORS.primary}
          >
            Finalize
          </IconButton>
        </div>
      </Expander>
    );
  };

  /***********************************************************************/
  // Sell orders
  renderSellOrder = (/** XcManagedSellOrder */ order) => (
    <span>
      for{' '}
      <strong>
        {order.quantity} {order.instrument}
      </strong>{' '}
      (id: {order.id}) {order.user_email && `by user ${order.user_email}`}
    </span>
  );

  refundSelectedOrders = () => {
    this.setState({
      refunding: this.props.selection.filter(index => {
        /** @type {XcManagedSellOrder} */
        const order = this.state.data.items[index];
        return !(
          order.cancelled_at ||
          order.completed_at ||
          order.reverse_interchange_id ||
          !order.taken_at
        );
      }),
    });
  };

  refund = selection => {
    const ids = selection.map(index => this.props.data.items[index].id);
    return this.executeAndReloadOrToast(
      this.container.client.putBrokerOrdersSellRefund(ids),
      `${ids.length} sell ${ids.length > 1 ? 'orders' : 'order'} have been refunded`
    );
  };

  renderActionWidgetForSellOrder = (/** XcManagedSellOrder */ order) => {
    if (order.cancelled_at || order.completed_at) {
      return null;
    }

    if (!order.taken_at) {
      return (
        <>
          <IconButton
            title="Execute order"
            color={FLAVORS.primary}
            onClick={this.executeSelectedOrders}
            icon={ICONS.play}
            className="flex-fill"
            disabled={!!order.suspended_at}
          >
            Execute
          </IconButton>
          <div className="hr-text my-2" data-content="OR" />
          <IconButton
            className="flex-fill"
            onClick={this.finalizeSelectedOrders}
            color={FLAVORS.primary}
          >
            Finalize
          </IconButton>
        </>
      );
    }

    return (
      <>
        <IconButton
          className="flex-fill"
          onClick={this.refundSelectedOrders}
          color={FLAVORS.danger}
          disabled={!!order.reverse_interchange_id}
        >
          Refund
        </IconButton>
        <IconButton
          className="flex-fill mt-2"
          onClick={this.finalizeSelectedOrders}
          color={FLAVORS.primary}
        >
          Finalize
        </IconButton>
      </>
    );
  };

  renderActionWidgetForManySellOrders = selection => {
    const orders = this.props.data.items;
    const executeDisabled = !selection.some(index => {
      const order = orders[index];
      return !order.taken_at && !order.cancelled_at && !order.completed_at && !order.suspended_at;
    });
    const refundDisabled = !selection.some(
      index =>
        !orders[index].cancelled_at &&
        !orders[index].completed_at &&
        orders[index].taken_at &&
        !orders[index].reverse_interchange_id
    );
    const finalizeDisabled = !selection.some(
      index => !orders[index].cancelled_at && !orders[index].completed_at
    );
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Execute orders"
              color={FLAVORS.primary}
              onClick={this.executeSelectedOrders}
              icon={ICONS.play}
              className="flex-fill"
              disabled={executeDisabled}
            >
              Execute
            </IconButton>
            <IconButton
              className="flex-fill mt-2"
              onClick={this.refundSelectedOrders}
              color={FLAVORS.danger}
              icon={ICONS.reset}
              disabled={refundDisabled}
            >
              Refund
            </IconButton>
            <IconButton
              className="flex-fill mt-2"
              onClick={this.finalizeSelectedOrders}
              color={FLAVORS.primary}
              disabled={finalizeDisabled}
            >
              Finalize
            </IconButton>
          </div>
        </Expander>

        {this.renderSuspension()}
      </div>
    );
  };

  renderActionWidgetForExpandedSellOrder = (/** XcManagedSellOrder */ order) => {
    if (order.cancelled_at || order.failure_reason) {
      return null;
    }

    if (order.completed_at) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Download report"
              color={FLAVORS.primary}
              onClick={this.downloadReport}
              icon={ICONS.download}
              className="flex-fill"
            >
              Download report
            </IconButton>
            <IconButton
              title="Generate report"
              color={FLAVORS.primary}
              onClick={this.generateReport}
              icon={ICONS.reset}
              className="flex-fill mt-2"
            >
              Regenerate report
            </IconButton>
          </div>
        </Expander>
      );
    }

    if (!order.taken_at) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            <IconButton
              title="Execute order"
              color={FLAVORS.primary}
              onClick={this.executeSelectedOrders}
              icon={ICONS.play}
              className="flex-fill"
              disabled={!!order.suspended_at}
            >
              Execute
            </IconButton>
            <div className="hr-text my-2" data-content="OR" />
            <IconButton
              className="flex-fill"
              onClick={this.finalizeSelectedOrders}
              color={FLAVORS.primary}
            >
              Finalize
            </IconButton>
          </div>
        </Expander>
      );
    }

    return (
      <Expander title="Actions" memoryKey={cn('actions')}>
        <div className="d-flex flex-column">
          {!order.reverse_interchange_id && (
            <IconButton
              className="flex-fill"
              onClick={this.refundSelectedOrders}
              color={FLAVORS.danger}
            >
              Refund
            </IconButton>
          )}
          <IconButton
            className="flex-fill mt-2"
            onClick={this.finalizeSelectedOrders}
            color={order.reverse_interchange_id ? FLAVORS.danger : FLAVORS.primary}
            disabled={!order.reverse_interchange_id && order.suspended_at}
          >
            Finalize
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderWidgetForExpandedOrder = () => {
    const order = this.props.data.items[0];

    return (
      <div>
        {this.isBuyOrderWidget
          ? this.renderActionWidgetForExpandedBuyOrder(order)
          : this.renderActionWidgetForExpandedSellOrder(order)}
        {this.renderSuspension()}

        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo object={order} />
        </Expander>
      </div>
    );
  };

  /***********************************************************************/

  renderSuspension = () => {
    let eligibleSuspend = 0;
    let eligibleUnsuspend = 0;

    for (const index of this.props.selection) {
      const order = this.props.data.items[index];
      if (order.completed_at || order.cancelled_at) {
        continue;
      }

      if (order.suspended_at) {
        eligibleUnsuspend++;
      } else {
        eligibleSuspend++;
      }
    }

    return (
      <Expander title="Suspension" memoryKey={cn('suspension')}>
        <GridLayout autoCols unresponsive>
          <IconButton
            title="Customer(s) will be directed to contact customer support"
            color={FLAVORS.danger}
            onClick={() => this.setSuspendedForSelectedOrders(true)}
            icon={ICONS.lock}
            disabled={eligibleSuspend === 0}
          >
            Suspend
          </IconButton>
          <IconButton
            title="Unsuspend order(s)"
            color={FLAVORS.primary}
            onClick={() => this.setSuspendedForSelectedOrders(false)}
            icon={ICONS.unlock}
            disabled={eligibleUnsuspend === 0}
          >
            Unsuspend
          </IconButton>
        </GridLayout>
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    const order = this.props.data.items[selectedIndex];
    const expandedViewRoute = this.isBuyOrderWidget
      ? routes.managedBuyOrdersExpandedOrder(order.id)
      : routes.managedSellOrdersExpandedOrder(order.id);

    return (
      <div>
        {!order.completed_at && (
          <Expander title="Actions" memoryKey={cn('actions')}>
            <div className="d-flex flex-column">
              {this.isBuyOrderWidget
                ? this.renderActionWidgetForBuyOrder(order)
                : this.renderActionWidgetForSellOrder(order)}
            </div>
          </Expander>
        )}

        {this.renderSuspension()}

        <Expander title="more">
          <div className="d-flex">
            <IconButton
              tag={Link}
              to={expandedViewRoute}
              className="flex-fill"
              icon={ICONS.expanded_view}
              color={FLAVORS.info}
            >
              Expanded view
            </IconButton>
          </div>
        </Expander>

        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo object={order} />
        </Expander>
      </div>
    );
  };

  render() {
    if (this.props.isExpandedView && !this.props.data.items[0]) {
      return null;
    }

    const formatFn = this.isBuyOrderWidget ? this.renderBuyOrder : this.renderSellOrder;
    return (
      <>
        {this.props.isExpandedView ? (
          <SelectionSidebar
            renderOne={() => {}}
            renderMany={() => {}}
            renderNone={this.renderWidgetForExpandedOrder}
          />
        ) : (
          <SelectionSidebar
            selection={this.props.selection}
            renderOne={this.renderSidebarOne}
            renderMany={
              this.isBuyOrderWidget
                ? this.renderActionWidgetForManyBuyOrders
                : this.renderActionWidgetForManySellOrders
            }
          />
        )}

        <BoundDangerModal
          state={this.boundState}
          formatItem={formatFn}
          onConfirmed={this.confirmSetSuspended}
          typeSingular="order"
          actionText={this.state.settingSuspendedValue ? 'suspend' : 'unsuspend'}
          buttonActionText={this.state.settingSuspendedValue ? 'Suspend' : 'Unsuspend'}
          actionKey="settingSuspendedSelection"
          actionFlavor={this.state.settingSuspendedValue ? FLAVORS.danger : FLAVORS.primary}
        >
          {this.state.settingSuspendedValue && (
            <Input
              className="mb-2"
              type="textarea"
              value={this.state.suspensionReason}
              onChange={e => this.setState({ suspensionReason: e.target.value })}
              placeholder="You must state suspension reason"
              title="State why you are suspending this order"
            />
          )}
        </BoundDangerModal>
        <BoundDangerModal
          state={this.boundState}
          formatItem={formatFn}
          onConfirmed={this.confirmExecute}
          typeSingular={`${this.isBuyOrderWidget ? 'buy' : 'sell'} order`}
          actionText="execute"
          buttonActionText="Confirm"
          actionKey="executing"
          actionFlavor={FLAVORS.primary}
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={formatFn}
          onConfirmed={this.confirmFinalize}
          typeSingular="order"
          actionText="finalize"
          buttonActionText="Confirm"
          actionKey="finalizing"
          actionFlavor={FLAVORS.primary}
        >
          <div className="flex-fill mb-3">
            <ThreeStateCheckbox
              className="mb-1"
              checked={this.state.finalizeAsFailed}
              onChange={() => this.setState({ finalizeAsFailed: !this.state.finalizeAsFailed })}
            >
              Set failed
            </ThreeStateCheckbox>
            <Input
              type="textarea"
              value={this.state.failureReason}
              onChange={e => this.setState({ failureReason: e.target.value })}
              placeholder="Failure reason"
              title="State why you are unable to fully execute this order"
              readOnly={!this.state.finalizeAsFailed}
            />
          </div>
        </BoundDangerModal>
        {this.isBuyOrderWidget ? (
          <BoundDangerModal
            state={this.boundState}
            formatItem={formatFn}
            onConfirmed={this.confirmPaymentReceived}
            typeSingular="buy order"
            actionText="mark payment received for"
            buttonActionText="Confirm"
            actionKey="paying"
            actionFlavor={FLAVORS.primary}
          />
        ) : (
          <BoundDangerModal
            state={this.boundState}
            formatItem={formatFn}
            onConfirmed={this.refund}
            typeSingular="sell order"
            actionText="refund"
            buttonActionText="Confirm"
            actionKey="refunding"
            actionFlavor={FLAVORS.primary}
          />
        )}
      </>
    );
  }
}
