import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import Bind from '../../infrastructure/Bind';
import { BoundState } from '../../infrastructure/ConnectedComponent';

const BoundCheckbox = ({ className, name, label, state, nest, helpText, ...rest }) => {
  return (
    <FormGroup check className={className}>
      <Label check>
        <Bind
          state={state}
          nest={nest}
          getNewValue={(_, prevValue) => !prevValue}
          valueProp="checked"
        >
          <Input name={name} type="checkbox" {...rest} />
        </Bind>
        {label}
      </Label>
      {helpText && <span className="form-text text-muted">{helpText}</span>}
    </FormGroup>
  );
};

BoundCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.any,
};

export default BoundCheckbox;
