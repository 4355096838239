import PropTypes from 'prop-types';
import React from 'react';

import { COUNTRIES, US_STATES } from '../../../lib/countries';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import CountryLabel from '../presentational/CountryLabel';
import Select from './Select';

const COUNTRY_OPTIONS = Object.values(COUNTRIES);
const US_STATE_OPTIONS = Object.values(US_STATES);

const getCountryLabel = option => {
  return <CountryLabel label={option.label} isoCode={option.value} />;
};

const isCountryType = type => type === 'country';

const getOptions = type => {
  return isCountryType(type) ? COUNTRY_OPTIONS : US_STATE_OPTIONS;
}

const getOptionsSource = type => {
  return isCountryType(type) ? COUNTRIES : US_STATES;
}


const filterOption = (option, filter) => {
  filter = filter.toLowerCase();
  return (
    option &&
    (option.value.toLowerCase() === filter || option.data.label.toLowerCase().indexOf(filter) >= 0)
  );
};

export default class CountryPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    selected: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(["country", "us-state"])
  };

  static defaultProps = {
    type: 'country'
  }

  static getDerivedStateFromProps({ selected, type }) {
    const optionsSource = getOptionsSource(type);
    return {
      selected: selected
        ? selected.includes(',')
          ? selected.split(',').map(country => optionsSource[country])
          : optionsSource[selected]
        : null,
    };
  }

  onChange = option => {
    if (this.props.onChange) {
      this.props.onChange(
        option
          ? Array.isArray(option)
            ? option.map(country => country.value)
            : option.value
          : null
      );
    }
  };

  render() {
    return (
      <Select
        isMulti={this.props.isMulti}
        options={getOptions(this.props.type)}
        onChange={this.onChange}
        value={this.state.selected}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={isCountryType(this.props.type) ? getCountryLabel : undefined}
        filterOption={filterOption}
        isClearable={this.props.isClearable}
      />
    );
  }
}
