import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';

import Bind from '../../infrastructure/Bind';
import { BoundState } from '../../infrastructure/ConnectedComponent';

const BoundFormInput = ({
  className,
  name,
  type = 'text',
  label,
  state,
  nest,
  helpText,
  children,
  prependAddon,
  appendAddon,
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup>
        {prependAddon && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prependAddon}</InputGroupText>
          </InputGroupAddon>
        )}
        <Bind state={state} nest={nest}>
          <Input type={type} name={name} id={name} {...rest} />
        </Bind>
        {appendAddon && (
          <InputGroupAddon addonType="append">
            <InputGroupText>{appendAddon}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>

      {helpText && <span className="form-text text-muted">{helpText}</span>}
      {children}
    </FormGroup>
  );
};

BoundFormInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.any,
  prependAddon: PropTypes.any,
  appendAddon: PropTypes.any,
};

export default BoundFormInput;
