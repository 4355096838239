import './CriticalityIndicator.css';

import React from 'react';

import ConnectedComponent from '../../infrastructure/ConnectedComponent';

export default class CriticalityIndicator extends ConnectedComponent {
  render() {
    const criticality = this.container.settings.interface_criticality;
    return <div className={'CriticalityIndicator CriticalityIndicator-' + criticality} />;
  }
}
