import './CriteriaOrderTypePicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../lib/criterias';
import { classNamer, classes } from '../../lib/tools';
import OrderTypePicker from './OrderTypePicker';

const cn = classNamer('CriteriaOrderTypePicker');

const CriteriaOrderTypePicker = ({
  criteria,
  className,
  label = 'For type:',
  typeField = 'type',
  inputId = cn('id'),
  history,
  placeholder = 'All types',
  ...rest
}) => {
  const handleChange = type => {
    const nextCriteria = { ...criteria };
    if (type) {
      nextCriteria[typeField] = type;
    } else {
      delete nextCriteria[typeField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <OrderTypePicker
        selected={criteria[typeField]}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

CriteriaOrderTypePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  typeField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
};

export default withRouter(CriteriaOrderTypePicker);
