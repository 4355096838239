import './ConfigDisabledEditor.css';

import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import ThreeStateCheckbox from '../widgets/interactive/ThreeStateCheckbox';

const cn = classNamer('ConfigDisabledEditor');

const REASON_HEIGHT = '90px';

export default class ConfigDisabledEditor extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    state: PropTypes.object.isRequired,
    value_key: PropTypes.string.isRequired,
    reason_key: PropTypes.string.isRequired,
  };

  get disabled() {
    const key = this.props.value_key;
    const value =
      this.props.state[key] !== undefined
        ? this.props.state[key]
        : this.props.data
        ? this.props.data[key]
        : undefined;

    if (value === null) {
      // Defaults to not disabled
      return false;
    }
    if (value === undefined) {
      // Indeterminate state
      return null;
    }
    // Return true or false
    return value;
  }

  get disabledReason() {
    const key = this.props.reason_key;
    return (
      (this.props.state[key] !== undefined
        ? this.props.state[key]
        : this.props.data && this.props.data[key]) || ''
    );
  }

  update(key, value) {
    this.props.state.setState({
      [key]: value,
    });
  }

  render() {
    const disabled = this.disabled;
    const reasonStyle = {
      height: disabled ? REASON_HEIGHT : 0,
    };

    return (
      <FormGroup
        className={classes(cn(), disabled !== true && cn('enabled'), this.props.className)}
      >
        <div className={cn('holder')}>
          <ThreeStateCheckbox
            checked={this.disabled}
            onChange={() => this.update(this.props.value_key, !disabled)}
            title={this.props.title}
          >
            Disable
          </ThreeStateCheckbox>

          <div className={cn('reason')} style={reasonStyle}>
            <Input
              type="textarea"
              value={this.disabledReason}
              onChange={e => this.update(this.props.reason_key, e.target.value)}
              title="Enter a reason for disabling"
            />
            <div className={cn('reason-label')}>Reason</div>
          </div>
        </div>
      </FormGroup>
    );
  }
}
