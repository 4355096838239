import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import orderBy from 'lodash/orderBy';
import pad from 'lodash/pad';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import template from 'lodash/template';
import throttle from 'lodash/throttle';
import without from 'lodash/without';

export const lodash = {
  get,
  isString,
  isFunction,
  isArray,
  isObject,
  isPlainObject,
  isNumber,
  pad,
  range,
  template,
  pick,
  pickBy,
  without,
  orderBy,
  throttle,
  debounce,
};

/**
 * @typedef {Promise} CancellablePromise
 * @method cancel
 */

/**
 * Turn promise into a cancellable promise
 * @return {CancellablePromise}
 */
export function cancellable(promise) {
  if (promise.cancel) {
    // Already cancellable
    return promise;
  }

  let cancelled = false;

  const cancel = () => {
    cancelled = true;
  };

  const wrapPromise = promise => {
    if (cancelled) {
      // Just return a forever-waiting promise
      return new Promise(() => {});
    }

    if (!promise || typeof promise.then !== 'function') {
      return promise;
    }

    promise.cancel = cancel;

    ['then', 'catch', 'finally'].forEach(method => {
      const originalMethod = promise[method];
      promise[method] = function (resolve, reject) {
        resolve = wrapMethod(resolve);
        reject = wrapMethod(reject);
        return wrapPromise(originalMethod.call(this, resolve, reject));
      };
    });

    return promise;
  };

  const wrapMethod = fn => {
    if (!fn) {
      return fn;
    }

    return function () {
      if (!cancelled) {
        return fn.apply(this, arguments);
      }
    };
  };

  return wrapPromise(promise);
}

/**
 * Join all arguments into a className string
 */
export function classes() {
  const results = [];
  for (let i = 0; i < arguments.length; i++) {
    let arg = arguments[i];
    if (!arg) {
      continue;
    }
    if (isArray(arg)) {
      arg = classes.apply(null, arg);
    }
    results.push(String(arg));
  }
  return results.join(' ');
}

/**
 * @callback waiterPromiseFactory
 * @param onFulfilled
 * @param [onRejected]
 * @returns Promise
 */
/**
 * @callback waiter
 * @property {waiterPromiseFactory} then Call this function once timeout triggers
 * @property {waiterPromiseFactory} catch Attach onRejected handler (won't be used)
 * @returns {Promise}
 */
/**
 * Returns a promise-like thing that waits given number of ms, then resolves with the second argument.
 * It is auto-curried, so you can use it in both these ways:
 * @example
 *  Promise.resolve(5).then(wait(10)).then(x => assert(x === 5))
 * @example
 *  wait(10, 5).then(x => assert(x === 5));
 * @param {Number} ms
 * @param [arg]
 * @returns {waiter}
 */
export function wait(ms, arg = undefined) {
  // 0 = idle, 1 = waiting, 2 = done
  let state = 0;

  const thens = [];

  waiter.then = callback => {
    if (state === 0) {
      // Trigger the timeout now
      waiter();
    }

    const then = { callback };

    const promise = new Promise((resolve, reject) => {
      then.resolve = resolve;
      then.reject = reject;
    });

    thens.push(then);

    return promise;
  };

  waiter.catch = onRejected => this.then(undefined, onRejected);

  return waiter;

  function waiter(arg2 = undefined) {
    if (state === 2) {
      throw new Error(`Wait has ended`);
    }

    if (state === 1) {
      throw new Error(`Waiter has already been activated`);
    }

    // Activate the timeout
    state = 1;
    const result = arg !== undefined ? arg : arg2;
    setTimeout(() => {
      state = 2;
      for (const then of thens) {
        const callbackResponse = then.callback(result);

        if (callbackResponse && callbackResponse.then) {
          // Chain another promise
          callbackResponse.then(then.resolve, then.reject);
        } else {
          // Resolve immediately
          then.resolve(callbackResponse);
        }
      }
    }, ms);

    // Return a fake fn that just returns the result
    return waiter.then(x => x);
  }
}

/**
 * Returns a function that debounces promise. fn will be called at most once every "ms" milliseconds.
 * Caller will be given a promise which will resolve or reject with latest results.
 * @template T
 * @param {T} fn
 * @param ms
 * @return T
 */
export function promiseDebouncer(fn, ms) {
  let nextPromise = null;
  let nextArgs = null;
  let debouncingTimeout = null;

  return (...args) => {
    if (!nextPromise) {
      let promiseMethods = {};
      nextPromise = new Promise((resolve, reject) => {
        promiseMethods = { resolve, reject };
      });
      Object.assign(nextPromise, promiseMethods);
    }
    nextArgs = args;

    setTimeout(tryExec, 0);

    return nextPromise;

    function tryExec() {
      if (!nextPromise) {
        // Nothing to execute
        return;
      }

      if (debouncingTimeout) {
        // Currently debouncing, wait a bit
        return;
      }

      // We can execute now. First, set next debounce
      debouncingTimeout = setTimeout(() => {
        debouncingTimeout = null;
        tryExec();
      }, ms);

      // Then, run the code and resolve promise
      const currentPromise = nextPromise;
      const currentArgs = nextArgs;
      nextPromise = null;
      nextArgs = null;

      Promise.resolve()
        .then(() => fn(...currentArgs))
        .then(
          result => currentPromise.resolve(result),
          error => currentPromise.reject(error)
        );
    }
  };
}

/**
 * Merge source into target. Arrays are not merged, new array overwrites the old one.
 * Constructed properties are not deep-merged, just copied by reference.
 * Unsafe merge will allow adding additional properties, otherwise they are ignored.
 */
export function merge(target, source, unsafe = false) {
  if (!isObject(source)) {
    return target;
  }

  for (const key in source) {
    if (!(key in target) && !unsafe) {
      continue;
    }

    const sourceValue = source[key];
    const targetValue = target[key];

    if (targetValue !== undefined && sourceValue === undefined) {
      // Do not overwrite with undefineds
      continue;
    }

    if (isPlainObject(sourceValue) && isPlainObject(targetValue)) {
      // Merge deep. We allow adding properties, presuming theses are POJOs.
      merge(targetValue, sourceValue);
      continue;
    }

    // In all other cases, copy by reference
    target[key] = sourceValue;
  }

  return target;
}

/**
 * Returns a function that can be used to generate className-s.
 * eg. classNamer('MyComponent')('sub', 'component') -> 'MyComponent-sub-component'
 * @return function(...string)
 */
export function classNamer(baseName) {
  return (...slugs) => {
    return [baseName, ...slugs].join('-');
  };
}

/**
 * Capitalize first letter in a string (but don't touch other characters)
 */
export function capitalize(str) {
  if (!str) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
}

/**
 * Splits string into text-only segments, based on TitleCase, snakeCase and non-text characters.
 * Optionally apply the mapper function to each segment.
 * @param str
 * @param {function(string)} [mapper]
 * @return string[]
 */
function segmentString(str, mapper = undefined) {
  const segments = [];
  let buffer = null;
  let capitalBuffer = false;
  for (let index = str.length - 1; index >= -1; index--) {
    const char = str[index];
    const charCode = char ? char.charCodeAt(0) : -1;
    const isCapital = segmentString.isCapital(charCode);
    const isLowercase = !isCapital && segmentString.isLowerCase(charCode);
    const isNumber = !isCapital && !isLowercase && segmentString.isNumber(charCode);
    const isOther = !isCapital && !isLowercase && !isNumber;

    if (!buffer) {
      // Empty buffer, initialize it with current char
      if (!isOther) {
        buffer = [char];
        capitalBuffer = isCapital;
      }
      continue;
    }

    if (
      (capitalBuffer && (isCapital || isNumber)) ||
      (!capitalBuffer && (isLowercase || isNumber))
    ) {
      // Proceed filling the next segment
      buffer.unshift(char);
      continue;
    }

    if (!capitalBuffer && isCapital) {
      // Special case: for lower case buffers, add one capital character
      buffer.unshift(char);
    } else {
      // Otherwise, "rewind" the iteration, so that next loop will re-process the same char with new buffer
      index++;
    }

    // End current buffer, create segment
    let segment = buffer.join('');
    if (mapper) {
      segment = mapper(segment);
    }
    segments.unshift(segment);
    buffer = null;
  }

  return segments;
}
segmentString.isCapital = charCode => charCode >= 65 && charCode <= 90;
segmentString.isLowerCase = charCode => charCode >= 97 && charCode <= 122;
segmentString.isNumber = charCode => charCode >= 48 && charCode <= 57;

/**
 * Make an object key into a human readable string (eg. "some_key_value" => "Some key value")
 */
export function beautifyKey(key) {
  return segmentString(key, capitalize).join(' ');
}

/**
 * Generate a lookup for all keys that natively appear in a class.
 * Class must be able to be constructed with an empty constructor.
 * @param Ctr
 */
export function classKeys(Ctr) {
  const instance = new Ctr();
  const keys = {};

  for (const key in instance) {
    if (instance.hasOwnProperty(key)) {
      keys[key] = key;
    }
  }

  return keys;
}

/**
 * Generate a lookup for all keys that appear in an object.
 */
export function objectKeys(obj) {
  const keys = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      keys[key] = key;
    }
  }
  return keys;
}

/**
 * Create a back link that includes previous query string, if pathname matches.
 * Examples:
 *   /users?filter=test -> /users/edit -> /users?filter=test
 *   /users/edit -> /users
 * @param {ExtendedHistory|any} history
 * @param pathname
 */
export function withQuery(history, pathname) {
  if (history.props && history.props.history) {
    // Presume we were given a withHistory() component
    history = history.props.history;
  }

  if (history.prev && history.prev.pathname === pathname && history.prev.search) {
    pathname += history.prev.search;
  }

  return pathname;
}

/**
 * Abbreviate string to given size
 */
export function abbreviated(str, size = 15, suffix = null) {
  if (!str) {
    return str;
  }
  const cutLength = size - (suffix ? suffix.length : 0);
  if (str.length <= cutLength) {
    return str;
  }

  return str.slice(0, cutLength) + (suffix || '');
}

const DOT = '.'.charCodeAt(0);
const ZERO = '0'.charCodeAt(0);
const NINE = '9'.charCodeAt(0);

/**
 * Efficient function to check if given value is number presented as string
 * @param {string} str
 */
export function isNumberLikeString(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const length = str.length;
  let i = 0;
  if (str[0] === '-') {
    i++;
  }
  let ord;
  let dots = 0;
  let digits = 0;
  for (; i < length; i++) {
    ord = str.charCodeAt(i);
    if (ord === DOT) {
      dots++;
      if (dots > 1) {
        // Only one dot allowed
        return false;
      }
      if (digits === 0) {
        // At least one digit required before dot
        return false;
      }
    } else if (ord >= ZERO && ord <= NINE) {
      digits++;
    } else {
      // Anything else is disallowed
      return false;
    }
  }

  // Looks like a number
  return true;
}

/**
 * Function to check if given value is number with decimal places
 * @param {any} value
 */
export function isDecimalNumber(value) {
  return lodash.isNumber(value) && value % 1 !== 0;
}

// TODO: This would naturally be circular import from consts, PHPStorm has trouble with that + prettier
const BYTE = 1;
const KILOBYTE = 1000 * BYTE;
const MEGABYTE = 1000 * KILOBYTE;

/**
 * Returns a formatted bytes string, eg. 1.2 mb
 */
export function formatBytes(bytes) {
  return bytes < KILOBYTE
    ? `${bytes} bytes`
    : bytes < MEGABYTE
    ? `${Math.floor(bytes / 1000)} kb`
    : `${(bytes / (1000 * 1000)).toFixed(2)} mb`;
}

/**
 * Return a new object where all keys with certain prefixes are nested into own sub-objects.
 * Basically converts a flat object into a nested one.
 * @example
 *   const ob = {a: 'A', prefix_b: 'B'};
 *   console.log(nestPrefixes(ob, ['prefix'])); // { a: 'A', prefix: {b: 'B'} }
 *   console.log(ob); // {a: 'A'}
 * @param ob
 * @param {string|string[]} prefixes List of prefixes or single prefix
 * @param {string} unprefixed Key under which to aggregate other keys. Otherwise, leaves them unprefixed.
 * @param splitter
 */
export function nestPrefixes(ob, prefixes, unprefixed = null, splitter = '_') {
  if (!Array.isArray(prefixes)) {
    prefixes = [prefixes];
  }

  const result = {};
  const nested = {}; // Have a separate object, so that nested props go to the end

  if (unprefixed) {
    result[unprefixed] = {};
  }

  mainLoop: for (const key in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, key)) {
      for (const prefix of prefixes) {
        const prefixWithSplitter = prefix + splitter;
        if (key.startsWith(prefixWithSplitter)) {
          nested[prefix] = nested[prefix] || {};
          nested[prefix][key.slice(prefixWithSplitter.length)] = ob[key];
          continue mainLoop;
        }
      }

      if (unprefixed) {
        result[unprefixed][key] = ob[key];
      } else if (unprefixed !== false) {
        result[key] = ob[key];
      }
    }
  }

  Object.assign(result, nested);
  console.log(result);
  return result;
}

/**
 * Flatten a multidimensional object with key paths
 * @example
 *   console.log(flattenObject({ a: 1, b: { c: 2 }, x: { y: { z: 3 } } })) // { a: 1, b_c: 2, x_y_z: 3 }
 */
export const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}_` : '';
    if (
      typeof obj[key] === 'object' &&
      !lodash.isArray(obj[key]) &&
      obj[key] !== null &&
      Object.keys(obj[key]).length > 0
    ) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }
    return acc;
  }, {});
};
