import './ExchangeInternalsPage.css';

import React from 'react';
import { withRouter } from 'react-router';

import { ADMIN_SOCKET_EVENTS, ADMIN_SOCKET_SECTORS } from '../../lib/backend';
import { FLAVORS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import { Card, CardBody, CardFooter, CardTitle } from '../widgets/presentational/Card';
import IconLabel from '../widgets/presentational/IconLabel';
import ObjectInfo from '../widgets/presentational/ObjectInfo';

const cn = classNamer('ExchangeInternalsPage');

class ExchangeInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /**
       * @type {XcExchangeInternals}
       */
      internals: null,

      /**
       * @type {XcExchangeAudit}
       */
      audit: null,
    };
  }

  loadData() {
    return this.promiseOrToast(
      Promise.all([
        this.container.client.getExchangeInternals(),
        this.container.client.getExchangeAudit(),
      ])
    ).then(([internals, audit]) => {
      this.setState({ internals, audit });
    });
  }

  componentDidMount() {
    this.loadData();

    this.container.socketManager.enterSector(ADMIN_SOCKET_SECTORS.internals);
    this.container.socketManager.onSectorEvent(
      ADMIN_SOCKET_SECTORS.internals,
      ADMIN_SOCKET_EVENTS.exchange_internals,
      internals => {
        this.setState({ internals });
      }
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.container.socketManager.exitSector(ADMIN_SOCKET_SECTORS.internals);
    this.container.socketManager.offSectorEvent(
      ADMIN_SOCKET_SECTORS.internals,
      ADMIN_SOCKET_EVENTS.exchange_internals
    );
  }

  unlockExchange = () => {
    this.promiseOrToast(this.container.client.postExchangeUnlock()).then(() => {
      return this.loadData();
    });
  };

  performAudit = () => {
    this.promiseOrToast(this.container.client.putExchangeAudit()).then(audit => {
      this.setState({ audit });
    });
  };

  renderState(/** XcExchangeState */ state) {
    if (!state) {
      return null;
    }

    const status = state.lock ? (
      <IconLabel flavor={false} icon={ICONS.warn}>
        Exchange has been locked!
      </IconLabel>
    ) : (
      <IconLabel flavor={true}>Exchange is active</IconLabel>
    );

    return (
      <Card title="Status">
        <CardTitle>{status}</CardTitle>
        <CardBody>
          <ObjectInfo object={state} keyBlacklist={['lock']} />
        </CardBody>
      </Card>
    );
  }

  renderAuditResultInstrument(instrument, /** XcExchangeAuditInstrument */ auditInstrument) {
    const data = {
      [instrument]: <ObjectInfo object={auditInstrument} keyBlacklist={['instrument']} />,
    };

    return <ObjectInfo key={instrument} object={data} />;
  }

  renderAuditResult(/** XcExchangeAudit */ audit) {
    if (!audit) {
      return (
        <CardBody>
          <CardTitle>
            <IconLabel icon={ICONS.warn} flavor={FLAVORS.warning}>
              Audit hasn&apos;t been performed yet
            </IconLabel>
          </CardTitle>
        </CardBody>
      );
    }

    const result = audit.clear ? (
      <IconLabel flavor={true}>Audit was clear</IconLabel>
    ) : (
      <IconLabel flavor={false} icon={ICONS.warn}>
        Audit has failed!
      </IconLabel>
    );

    const timing = {
      Started: new Date(audit.started_at),
      Completed: new Date(audit.completed_at),
      'Audit duration':
        Math.ceil((new Date(audit.completed_at) - new Date(audit.started_at)) / 1000) + ' sec',
    };

    return (
      <CardBody>
        <CardTitle>{result}</CardTitle>
        <div className={cn('audit-result')}>
          <ObjectInfo key="timing" object={timing} />
          {Object.keys(audit.by_instrument).map(instrument =>
            this.renderAuditResultInstrument(instrument, audit.by_instrument[instrument])
          )}
        </div>
      </CardBody>
    );
  }

  renderAudit(/** XcExchangeAudit */ audit) {
    return (
      <Card title="Audit">
        {this.renderAuditResult(audit)}
        <CardFooter>
          <IconButton icon={ICONS.cog} color={FLAVORS.primary} onClick={this.performAudit}>
            Perform a new audit
          </IconButton>
        </CardFooter>
      </Card>
    );
  }

  renderLock(/** XcExchangeState */ state) {
    if (!state || !state.lock) {
      return null;
    }

    const footer = (
      <IconButton size="lg" color={FLAVORS.danger} onClick={this.unlockExchange}>
        Unlock
      </IconButton>
    );

    return (
      <Card title="Lock" flavor={FLAVORS.danger} footer={footer}>
        <CardTitle>Admin action is required to restart trading</CardTitle>
        <CardBody>
          <ObjectInfo object={state.lock} />
        </CardBody>
      </Card>
    );
  }

  render() {
    const { internals, audit } = this.state;

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_EXCHANGE)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-3">
            {this.renderState(internals && internals.state)}
            {this.renderLock(internals && internals.state)}
            {this.renderAudit(audit)}
          </div>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(ExchangeInternalsPage);
