import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const MicropaymentOutcome = ({ processedAt, auditId, auditResult, failureMessage }) => {
  if (!processedAt) {
    if (!auditId) {
      // Nothing is happening
      return null;
    }

    if (auditResult) {
      // Strange status...
      return (
        <IconLabel
          flavor={FLAVORS.muted}
          icon={ICONS.wait}
          title={`Audit has passed, but micropayment hasn't yet been marked as processed`}
        >
          Audit passed
        </IconLabel>
      );
    }

    if (auditResult === false) {
      return (
        <IconLabel
          flavor={FLAVORS.danger}
          icon={ICONS.wait}
          title={`Audit has failed, the micropayment is waiting on response from customer and customer support`}
        >
          Audit failed
        </IconLabel>
      );
    }

    // The only thing left...
    return (
      <IconLabel
        flavor={FLAVORS.primary}
        icon={ICONS.wait}
        title={`Micropayment is currently undergoing audit`}
      >
        Auditing
      </IconLabel>
    );
  }

  if (failureMessage) {
    return (
      <IconLabel flavor={false} title={`Micropayment has been rejected`}>
        {failureMessage}
      </IconLabel>
    );
  }

  return (
    <IconLabel flavor={true} title={`Micropayment has been accepted`}>
      APPROVED
    </IconLabel>
  );
};

MicropaymentOutcome.customerInfoFormatter = (/** XcCustomerInfo */ ci) => (
  <MicropaymentOutcome
    auditId={ci.micropayment_audit_id}
    auditResult={ci.audit_result}
    processedAt={ci.micropayment_processed_at}
    failureMessage={ci.micropayment_failure_message}
  />
);

MicropaymentOutcome.kycMicropaymentInfoFormatter = (/** XcKYCMicropaymentInfo */ kmi) => (
  <MicropaymentOutcome
    auditId={kmi.audit_id}
    auditResult={kmi.audit_result}
    processedAt={kmi.processed_at}
    failureMessage={kmi.failure_message}
  />
);

export default MicropaymentOutcome;
