import {
  ADMIN_PERMISSIONS,
  BOT_PERMISSIONS,
  CURRENCIES,
  INSTRUMENTS,
  KYC_ADMIN_ACTIONS,
  KYC_ADMIN_ACTION_FILTERS,
  KYC_LEVEL2_METHODS,
  LANGUAGES,
  PHONE_VERIFICATION_PURPOSES,
  PHONE_VERIFICATION_TERMINATION_REASONS,
  ROLES,
  SOF_ORIGINS,
  SOF_SALARY_RANGES,
  XcAdminActionInfo,
  XcAdminBotPayload,
  XcBot,
  XcBotInfo,
  XcCardPayment,
  XcCustomer,
  XcCustomerInfo,
  XcDeposit,
  XcDocument,
  XcEstimatorConfig,
  XcEstimatorConfigPerInstrument,
  XcEstimatorInstrumentConfig,
  XcGrowthTimelineReportItem,
  XcInstrumentConfig,
  XcInstrumentConfigPayload,
  XcKYCMicropaymentInfo,
  XcKYCMicropaymentPayload,
  XcLogCollectorState,
  XcManagedBankTransfer,
  XcManagedBuyOrder,
  XcManagedConfig,
  XcManagedDeposit,
  XcManagedPartnerTrade,
  XcManagedPartnerTransfer,
  XcManagedSellOrder,
  XcPairConfig,
  XcPairConfigPayload,
  XcPhoneVerificationInfo,
  XcRegistrationPolicy,
  XcSecurityEventInfo,
  XcSessionInfo,
  XcTradeInfo,
  XcTransfer,
  XcTransferTimelineReportItem,
  XcTrollboxMessageInfo,
  XcTrollboxProfanityConfig,
  XcUser,
  XcWalletInfo,
  XcWalletInfoInstrument,
  XcWithdrawal,
} from './backend';
import { classKeys, lodash } from './tools';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const MONTH = 31 * DAY;

export const CSS = {
  sidebar_width: '300px',
  sidebar_gap: '15px',
};

export const ALL_PERMISSIONS = Object.keys(ADMIN_PERMISSIONS);

export const ADMIN_PERMISSION_NAMES = {
  [ADMIN_PERMISSIONS.kyc_associate]: 'KYC Associate',
  [ADMIN_PERMISSIONS.kyc_officer]: 'KYC Officer',
  [ADMIN_PERMISSIONS.users]: 'Users',
  [ADMIN_PERMISSIONS.security]: 'Security',
  [ADMIN_PERMISSIONS.incentives]: 'Incentives',
  [ADMIN_PERMISSIONS.trollbox]: 'Trollbox',
  [ADMIN_PERMISSIONS.finances]: 'Finances',
  [ADMIN_PERMISSIONS.audit]: 'Audit',
  [ADMIN_PERMISSIONS.developer]: 'Developer',
  [ADMIN_PERMISSIONS.managed]: 'Managed',
  [ADMIN_PERMISSIONS.transactions]: 'Transactions',
  [ADMIN_PERMISSIONS.user_activity]: 'User Activity',
};

export const ROLE_NAMES = {
  [ROLES.superadmin]: 'Superadmin',
  [ROLES.admin]: 'Admin',
  [ROLES.customer]: 'Customer',
};

export const LANGUAGE_NAMES = {
  [LANGUAGES.en_us]: 'English',
  [LANGUAGES.sr_cyrl]: 'Serbian Cyrillic',
  [LANGUAGES.sr_latn]: 'Serbian Latin',
};

export const defaultPairConfig = new XcPairConfig({
  trade_fee: null,
  disable_orders: false,
});

export const defaultInstrumentConfig = new XcInstrumentConfig({
  deposit_fee_abs: null,
  deposit_fee_pct: null,
  disable_deposits: false,

  withdrawal_fee_abs: null,
  withdrawal_fee_pct: null,
  disable_withdrawals: false,
  min_withdrawal: null,
  max_withdrawal_kyc1: null,
  max_withdrawal_kyc2: null,
  min_order: null,
});

export const CRYPTO_INSTRUMENTS = lodash.pickBy(INSTRUMENTS, inst => !CURRENCIES[inst]);

/**
 * How many decimal places do we keep in quantities (for each instrument)
 */
export const QUANTITY_SCALE_BY_INSTRUMENT = {
  [INSTRUMENTS.BTC]: 8,
  [INSTRUMENTS.ETH]: 8,
  [INSTRUMENTS.EUR]: 2,
  [INSTRUMENTS.LTC]: 8,
  [INSTRUMENTS.RSD]: 2,
  [INSTRUMENTS.SFX]: 8,
  [INSTRUMENTS.SFT]: 0,
  [INSTRUMENTS.LLM]: 8,
  [INSTRUMENTS.USD]: 2,
  [INSTRUMENTS.CHF]: 2,
  [INSTRUMENTS.GBP]: 2,
  [INSTRUMENTS.RUB]: 2,
  [INSTRUMENTS.NOK]: 2,
  [INSTRUMENTS.DKK]: 2,
  [INSTRUMENTS.CZK]: 2,
  [INSTRUMENTS.PLN]: 2,
  [INSTRUMENTS.SEK]: 2,
  [INSTRUMENTS.AUD]: 2,
  [INSTRUMENTS.HUF]: 2,
  [INSTRUMENTS.CAD]: 2,
  [INSTRUMENTS.JPY]: 2,
  [INSTRUMENTS.NZD]: 2,
  [INSTRUMENTS.TRY]: 2,
  [INSTRUMENTS.ZAR]: 2,
  [INSTRUMENTS.ILS]: 2,
};

export const SOF_ORIGIN_LABELS = {
  [SOF_ORIGINS.salary]: 'Salary or savings',
  [SOF_ORIGINS.inheritance]: 'Inheritance',
  [SOF_ORIGINS.business]: 'Own business operations',
  [SOF_ORIGINS.other]: 'Other',
};

export const SOF_SALARY_RANGE_LABELS = {
  [SOF_SALARY_RANGES.sr_0_20]: 'Less than 20K',
  [SOF_SALARY_RANGES.sr_20_60]: '20K - 60K',
  [SOF_SALARY_RANGES.sr_60_100]: '60K - 100K',
  [SOF_SALARY_RANGES.sr_100_500]: '100K - 500K',
  [SOF_SALARY_RANGES.sr_500_plus]: 'Over 500K',
};

//**********************************************************************************************************************

export const BOT_PERMISSION_NAMES = {
  [BOT_PERMISSIONS.read]: 'Read',
  [BOT_PERMISSIONS.withdraw]: 'Withdraw',
  [BOT_PERMISSIONS.place_order]: 'Order',
  [BOT_PERMISSIONS.cancel_order]: 'Cancel',
};

//**********************************************************************************************************************

export const KYC_ADMIN_ACTION_NAMES = {
  [KYC_ADMIN_ACTION_FILTERS.any]: 'Any admin action',
  [KYC_ADMIN_ACTION_FILTERS.kyc1]: 'Any KYC1 action',
  [KYC_ADMIN_ACTION_FILTERS.kyc2]: 'Any KYC2 action',
  [KYC_ADMIN_ACTION_FILTERS.kyc3]: 'Any KYC3 action',
  [KYC_ADMIN_ACTIONS.kyc1_verification]: 'KYC1 verification',
  [KYC_ADMIN_ACTIONS.kyc1_screening_release]: 'KYC1 screening release',
  [KYC_ADMIN_ACTIONS.kyc1_screening_verdict]: 'KYC1 screening verdict',
  [KYC_ADMIN_ACTIONS.kyc2_upload]: 'KYC2 document',
  [KYC_ADMIN_ACTIONS.kyc2_letter]: 'KYC2 letter',
  [KYC_ADMIN_ACTIONS.kyc3_audit]: 'KYC3 audit',
  [KYC_ADMIN_ACTIONS.kyc3_audit_result]: 'KYC3 flagged',
};

export const KYC_LEVEL2_METHOD_NAMES = {
  [KYC_LEVEL2_METHODS.in_person_verification]: 'In person verification',
  [KYC_LEVEL2_METHODS.image_upload]: 'Utility bill',
  [KYC_LEVEL2_METHODS.letter_with_code]: 'Letter with code',
};

export const KYC_VERIFICATION_DOCUMENT_STATUS_LABELS = {
  APPROVED_VERIFIED: 'Approved',
  DENIED_FRAUD: 'Fraud',
  DENIED_UNSUPPORTED_ID_TYPE: 'Unsupported ID type',
  DENIED_UNSUPPORTED_ID_COUNTRY: 'Unsupported country',
  ERROR_NOT_READABLE_ID: 'Not readable ID',
  NO_ID_UPLOADED: 'ID not uploaded',
};

export const KYC_VERIFICATION_REJECT_REASON_LABELS = {
  /** Code: 100 */
  MANIPULATED_DOCUMENT: 'Manipulated document',
  /** Code: 105 */
  FRAUDSTER: 'Fraudster',
  /** Code: 106 */
  FAKE: 'Fake',
  /** Code: 107 */
  PHOTO_MISMATCH: 'Photo mismatch',
  /** Code: 108 */
  MRZ_CHECK_FAILED: 'MRZ check failed',
  /** Code: 109 */
  PUNCHED_DOCUMENT: 'Punched document',
  /** Code: 110 */
  CHIP_DATA_MANIPULATED: 'Chip data manipulated',
  /** Code: 111 */
  MISMATCH_PRINTED_BARCODE_DATA: 'Mismatch printed barcode data',

  // If status is ERROR_NOT_READABLE_ID:

  /** Code: 102 */
  PHOTOCOPY_BLACK_WHITE: 'Black & white photocopy',
  /** Code: 103 */
  PHOTOCOPY_COLOR: 'Color photocopy',
  /** Code: 104 */
  DIGITAL_COPY: 'Digital copy',
  /** Code: 200 */
  NOT_READABLE_DOCUMENT: 'Not readable document',
  /** Code: 201 */
  NO_DOCUMENT: 'No document',
  /** Code: 202 */
  SAMPLE_DOCUMENT: 'Sample document',
  /** Code: 206 */
  MISSING_BACK: 'Missing back',
  /** Code: 207 */
  WRONG_DOCUMENT_PAGE: 'Wrong document page',
  /** Code: 209 */
  MISSING_SIGNATURE: 'Missing signature',
  /** Code: 210 */
  CAMERA_BLACK_WHITE: 'Black & white camera',
  /** Code: 211 */
  DIFFERENT_PERSONS_SHOWN: 'Different persons shown',
  /** Code: 300 */
  MANUAL_REJECTION: 'Manual rejection',
};

export const KYC_VERIFICATION_IDENTITY_INVALID_REASON_LABELS = {
  SELFIE_CROPPED_FROM_ID: 'Selfie is cropped from ID',
  ENTIRE_ID_USED_AS_SELFIE: 'Entire ID was used as selfie',
  MULTIPLE_PEOPLE: 'Multiple people in picture',
  SELFIE_IS_SCREEN_PAPER_VIDEO: 'Selfie is a screen paper video',
  SELFIE_MANIPULATED: 'Selfie was manipulated',
  AGE_DIFFERENCE_TOO_BIG: 'Age difference is too big',
  NO_FACE_PRESENT: 'No face is present',
  FACE_NOT_FULLY_VISIBLE: 'Face is not fully visible',
  BAD_QUALITY: 'Bad quality',
  BLACK_AND_WHITE: 'Black and white',
  LIVENESS_FAILED: 'Liveness check has failed',
};

export const PHONE_VERIFICATION_TERMINATION_REASON_LABELS = {
  [PHONE_VERIFICATION_TERMINATION_REASONS.seeded]: 'Seeded as terminated',
  [PHONE_VERIFICATION_TERMINATION_REASONS.expired]: 'Expired',
  [PHONE_VERIFICATION_TERMINATION_REASONS.invalid_phone_number]: 'Invalid phone number',
  [PHONE_VERIFICATION_TERMINATION_REASONS.twilio_error]: 'Twilio error',
  [PHONE_VERIFICATION_TERMINATION_REASONS.admin_action]: 'Admin action',
};

export const PHONE_VERIFICATION_PURPOSE_LABELS = {
  [PHONE_VERIFICATION_PURPOSES.onboarding]: 'Onboarding',
  [PHONE_VERIFICATION_PURPOSES.vqf_signature]: 'VQF',
};

//**********************************************************************************************************************

export const MANAGED_ORDER_TYPE_PREFIX = {
  buy: 'MB_',
  sell: 'MS_',
};

export const MANAGED_SELL_ORDER_FAILURE_REASONS = {
  generic: 'generic',
};

//**********************************************************************************************************************

/** @type {XcUser} */
export const USER_KEYS = classKeys(XcUser);

/** @type {XcPairConfigPayload} */
export const PAIR_CONFIG_KEYS = classKeys(XcPairConfigPayload);

/** @type {XcInstrumentConfigPayload} */
export const INSTRUMENT_CONFIG_KEYS = classKeys(XcInstrumentConfigPayload);

/** @type {XcBot} */
export const BOT_KEYS = classKeys(XcBot);

/** @type {XcAdminBotPayload} */
export const BOT_PAYLOAD_KEYS = classKeys(XcAdminBotPayload);

/** @type {XcBotInfo} */
export const BOT_INFO_KEYS = classKeys(XcBotInfo);

/** @type {XcSessionInfo} */
export const SESSION_INFO_KEYS = classKeys(XcSessionInfo);

/** @type {XcTrollboxMessageInfo} */
export const TROLLBOX_MESSAGE_INFO_KEYS = classKeys(XcTrollboxMessageInfo);

/** @type {XcCustomer} */
export const CUSTOMER_KEYS = classKeys(XcCustomer);

/** @type {XcCustomerInfo} */
export const CUSTOMER_INFO_KEYS = classKeys(XcCustomerInfo);

/** @type {XcLogCollectorState} */
export const LOG_COLLECTOR_KEYS = classKeys(XcLogCollectorState);

/** @type {XcWalletInfo} */
export const WALLET_INFO_KEYS = classKeys(XcWalletInfo);

/** @type {XcWalletInfoInstrument} */
export const WALLET_INFO_INSTRUMENT_KEYS = classKeys(XcWalletInfoInstrument);

/** @type {XcTradeInfo} */
export const TRADE_INFO_KEYS = classKeys(XcTradeInfo);

/** @type {XcManagedBuyOrder} */
export const MANAGED_BUY_ORDER_KEYS = classKeys(XcManagedBuyOrder);

/** @type {XcManagedSellOrder} */
export const MANAGED_SELL_ORDER_KEYS = classKeys(XcManagedSellOrder);

/** @type {XcManagedPartnerTrade} */
export const MANAGED_PARTNER_TRADE_KEYS = classKeys(XcManagedPartnerTrade);

/** @type {XcManagedPartnerTransfer} */
export const MANAGED_PARTNER_TRANSFER_KEYS = classKeys(XcManagedPartnerTransfer);

/** @type {XcManagedBankTransfer} */
export const MANAGED_BANK_TRANSFER_KEYS = classKeys(XcManagedBankTransfer);

/** @type {XcManagedDeposit} */
export const MANAGED_DEPOSIT_KEYS = classKeys(XcManagedDeposit);

/** @type {XcRegistrationPolicy} */
export const REGISTRATION_POLICY_KEYS = classKeys(XcRegistrationPolicy);

/** @type {XcAdminActionInfo} */
export const ADMIN_ACTION_INFO_KEYS = classKeys(XcAdminActionInfo);

/** @type {XcWithdrawal} */
export const WITHDRAWAL_KEYS = classKeys(XcWithdrawal);

/** @type {XcTransfer} */
export const TRANSFER_KEYS = classKeys(XcTransfer);

/** @type {XcDeposit} */
export const DEPOSIT_KEYS = classKeys(XcDeposit);

/** @type {XcCardPayment} */
export const CARD_PAYMENT_KEYS = classKeys(XcCardPayment);

/** @type {XcSecurityEventInfo} */
export const SECURITY_EVENT_INFO_KEYS = classKeys(XcSecurityEventInfo);

/** @type {XcPhoneVerificationInfo} */
export const PHONE_VERIFICATION_INFO_KEYS = classKeys(XcPhoneVerificationInfo);

/** @type {Array} */
export const MANAGED_CONFIG_KEYS_LIST = Object.keys(classKeys(XcManagedConfig));

/** @type {Array} */
export const ESTIMATOR_CONFIG_KEYS_LIST = Object.keys(classKeys(XcEstimatorConfig));

/** @type {Array} */
export const ESTIMATOR_SETTINGS_PER_INSTRUMENT_KEYS_LIST = Object.keys(
  classKeys(XcEstimatorConfigPerInstrument)
);

/** @type {Array} */
export const ESTIMATOR_INSTRUMENT_SETTINGS_KEYS_LIST = Object.keys(
  classKeys(XcEstimatorInstrumentConfig)
);

/** @type {XcDocument} */
export const DOCUMENT_KEYS = classKeys(XcDocument);

/** @type {XcKYCMicropaymentInfo} */
export const MICROPAYMENT_INFO_KEYS = classKeys(XcKYCMicropaymentInfo);

/** @type {XcKYCMicropaymentPayload} */
export const MICROPAYMENT_PAYLOAD_KEYS = classKeys(XcKYCMicropaymentPayload);

/** @type {XcGrowthTimelineReportItem} */
export const GROWTH_TIMELINE_REPORT_ITEM = classKeys(XcGrowthTimelineReportItem);

/** @type {XcTransferTimelineReportItem} */
export const TRANSFER_TIMELINE_REPORT_ITEM = classKeys(XcTransferTimelineReportItem);

/** @type {XcTrollboxProfanityConfig} */
export const TROLLBOX_PROFANITY_CONFIG_KEYS = classKeys(XcTrollboxProfanityConfig);

// *********************************************************************************************************************

export const FLAVORS = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  light: 'light',
  dark: 'dark',
  muted: 'muted',
  link: 'link',
};

// *********************************************************************************************************************

// TODO KYC3

const DOCUMENT_MIME_TYPES = {
  pdf: 'pdf',
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
};

export const DOCUMENT_MIME_TYPE_VALUES = {
  [DOCUMENT_MIME_TYPES.pdf]: 'application/pdf',
  [DOCUMENT_MIME_TYPES.csv]: 'text/csv',
  [DOCUMENT_MIME_TYPES.doc]: 'application/msword',
  [DOCUMENT_MIME_TYPES.docx]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // WTF!?
};
