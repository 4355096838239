import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import ConnectedComponent from '../infrastructure/ConnectedComponent';

class Breadcrumbed extends ConnectedComponent {
  static propTypes = {
    /** @type {string|LinkDefinition} */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.uniqueId = Math.random().toString().slice(2);
    this.currentTitle = null;
    this.currentLink = null;

    this.breadcrumbEnter();
  }

  componentDidMount() {
    this.breadcrumbEnter();
  }

  componentDidUpdate(prevProps) {
    this.breadcrumbEnter();
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.container.store.breadcrumbLeave(this.uniqueId);
  }

  breadcrumbEnter() {
    let { title, link } = this.props;
    if (link && link.route) {
      // We were given a LinkDefinition. Extract from there.
      title = title || link.title;
      link = link.route;
    }

    if (title !== this.currentTitle && link !== this.currentLink) {
      this.currentTitle = title;
      this.currentLink = link;
      this.container.store.breadcrumbEnter({
        id: this.uniqueId,
        title,
        link,
      });
    }
  }

  render() {
    if (typeof this.props.children === 'function') {
      // Support render prop
      return this.props.children();
    }
    return this.props.children || null;
  }
}

export default withRouter(Breadcrumbed);
