import PropTypes from 'prop-types';
import React from 'react';

import { CURRENCIES, INSTRUMENTS } from '../../../lib/backend';
import { CRYPTO_INSTRUMENTS } from '../../../lib/consts';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Select from './Select';

class InstrumentPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['fiat', 'crypto']),
    options: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
  };

  get cryptoOptions() {
    return this.container.settings.instrument_list.filter(i => CRYPTO_INSTRUMENTS[i]);
  }

  loadOptions = () => {
    if (this.props.options) {
      return this.props.options.map(value => ({ value }));
    }

    let options;
    switch (this.props.type) {
      case 'fiat':
        options = CURRENCIES;
        break;
      case 'crypto':
        options = this.cryptoOptions;
        break;
      default:
        options = INSTRUMENTS;
        break;
    }

    return Object.values(options).map(value => ({ value }));
  };

  loadValue = () => {
    return this.props.selected
      ? Array.isArray(this.props.selected)
        ? this.props.selected.map(value => ({ value }))
        : { value: this.props.selected }
      : null;
  };

  onChange = selected => {
    if (this.props.onChange) {
      this.props.onChange(
        selected
          ? Array.isArray(selected)
            ? selected.map(option => option.value)
            : selected.value
          : null
      );
    }
  };

  render() {
    return (
      <Select
        options={this.loadOptions()}
        onChange={this.onChange}
        value={this.loadValue()}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={option => <span>{option.value}</span>}
        getOptionValue={option => option.value}
        noOptionsMessage={() => 'No currencies'}
        isMulti={this.props.isMulti}
        isClearable={this.props.isClearable}
      />
    );
  }
}

export default InstrumentPicker;
