/**
 * Service that is similar to HTTP, except it only provides URL details instead of making HTTP requests.
 */
export class BackendSpecProvider {
  constructor(baseUrl) {
    this._base_url = baseUrl;
  }

  provide = /** XcXcalibraClientRequest */ req => {
    req.url = this._base_url + req.endpoint;
    return req;
  };
}
