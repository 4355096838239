import './LoggerInternalsPage.css';

import React from 'react';
import { Label } from 'reactstrap';

import { LOG_LEVELS } from '../../lib/backend';
import { LOG_COLLECTOR_KEYS } from '../../lib/consts';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import EnumSelect from '../widgets/interactive/EnumSelect';
import { Card } from '../widgets/presentational/Card';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';

const cn = classNamer('LoggerInternalsPage');

class LoggerInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type {XcLoggerState} */
      data: null,
    };
  }

  loadData() {
    return this.promiseOrToast(this.container.client.getAppLogger()).then(data => {
      this.setState({ data });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  setGlobalLevel = level => {
    return this.promiseOrSetError(this.container.client.putAppLoggerLevel({ level })).then(data => {
      this.setState({ data });
    });
  };

  setCollectorLevel = (key, level) => {
    return this.promiseOrToast(
      this.container.client.putAppLoggerCollectorLevel(key, { level })
    ).then(data => {
      this.setState({ data });
    });
  };

  renderLogCollectors() {
    const columns = [
      new DataTableColumn(
        LOG_COLLECTOR_KEYS.tags,
        'Tags',
        lc => (lc.tags.length ? lc.tags.join(' > ') : '<NONE>'),
        false
      ),
      new DataTableColumn(
        LOG_COLLECTOR_KEYS.level,
        'Level',
        /** XcLogCollectorState */ lc => {
          return (
            <EnumSelect
              values={LOG_LEVELS}
              value={lc.level}
              onChange={level => this.setCollectorLevel(lc.key, level)}
              empty
              bsSize="sm"
            />
          );
        },
        false
      ),
    ];

    return (
      <Card title="Collectors" className={cn('collectors')}>
        <DataTable
          data={this.state.data.collectors.filter(x => !!x.key)}
          columns={columns}
          criteria={{}}
        />
      </Card>
    );
  }

  renderLogger() {
    const footer = (
      <Label for="global_level">
        Set global level:
        <EnumSelect
          values={LOG_LEVELS}
          value={this.state.data.level}
          onChange={level => this.setGlobalLevel(level)}
          id="global_level"
        />
      </Label>
    );

    return (
      <Card title="Logger" footer={footer}>
        <ObjectInfo object={this.state.data} keyBlacklist={['collectors']} />
      </Card>
    );
  }

  renderData() {
    if (!this.state.data) {
      return;
    }

    return (
      <>
        {this.renderLogger()}
        {this.renderLogCollectors()}
      </>
    );
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_LOGGER)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-3">{this.renderData()}</div>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default LoggerInternalsPage;
