import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { beautifyKey } from '../../../lib/tools';

const EnumFieldset = ({
  className,
  label,
  values,
  labels,
  value,
  multiple,
  disabled,
  onChange,
  children,
}) => {
  const items = [];
  if (multiple) {
    value = value || [];
  }
  for (const key in values) {
    const selected = multiple ? value.indexOf(key) >= 0 : value === key;
    const label = labels ? labels[key] : beautifyKey(key);
    items.push(
      <FormGroup check key={key}>
        <Label check>
          <Input
            className="mr-2"
            name={key}
            value={key}
            label={label}
            type={multiple ? 'checkbox' : 'radio'}
            checked={selected}
            disabled={disabled}
            onChange={() => {
              if (multiple) {
                const updated =
                  value.indexOf(key) >= 0 ? value.filter(x => x !== key) : value.concat(key);
                onChange(updated);
              } else {
                onChange(key);
              }
            }}
          />
          {label}
        </Label>
      </FormGroup>
    );
  }

  return (
    <FormGroup tag="fieldset">
      {label && <Label>{label}</Label>}
      {items}
      {children}
    </FormGroup>
  );
};

EnumFieldset.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  labels: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};

export default EnumFieldset;
