import './ExpanderGroup.css';

import PropTypes from 'prop-types';
import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('ExpanderGroup');

const ExpanderGroup = ({ title, children, className }) => {
  return (
    <fieldset className={classes(cn(), className)}>
      <legend>{title}</legend>

      <div className={cn('content')}>{children}</div>
    </fieldset>
  );
};

ExpanderGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default ExpanderGroup;
