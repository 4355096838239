import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import IconButton from '../../widgets/interactive/IconButton';

const cn = classNamer('ManagedActionWidget');

export default class ManagedActionWidget extends ConnectedComponent {
  static propTypes = {
    state: PropTypes.object.isRequired,
    managingKey: PropTypes.string.isRequired,
    unmanagingKey: PropTypes.string.isRequired,
  };

  /**
   * Filter selection, filtering only items with certain "managed" status (managed or not managed)
   */
  filterSelection = onlyWithManagedStatus => {
    return Object.values(
      this.props.state.selection.reduce((res, index) => {
        const item = this.props.state.data.items[index];

        const shouldInclude =
          (item.managed_registered_at && onlyWithManagedStatus) ||
          (!item.managed_registered_at && !onlyWithManagedStatus);
        if (shouldInclude) {
          const userId = item.user_id || item.id;
          if (!res[userId]) {
            res[userId] = index;
          }
        }

        return res;
      }, {})
    );
  };

  manageSelectedUsers = () => {
    this.props.state.setState({
      [this.props.managingKey]: this.filterSelection(false),
    });
  };

  unmanageSelectedUsers = () => {
    this.props.state.setState({
      [this.props.unmanagingKey]: this.filterSelection(true),
    });
  };

  render() {
    const { state } = this.props;
    const managedUsers = state.selection.filter(
      index => state.data.items[index].managed_registered_at
    );
    return (
      <Expander title="Actions" memoryKey={cn('actions')}>
        <Form>
          <div className={cn('form')}>
            <div className="d-flex">
              <IconButton
                title="Grant user access to managed service"
                onClick={this.manageSelectedUsers}
                color={FLAVORS.primary}
                className="flex-fill"
                icon={ICONS.unlock}
                disabled={!(state.selection.length - managedUsers.length)}
              >
                Allow
              </IconButton>
              <IconButton
                title="Remove user access to managed service"
                onClick={this.unmanageSelectedUsers}
                color={FLAVORS.danger}
                className="flex-fill ml-1"
                disabled={!managedUsers.length}
                icon={ICONS.lock}
              >
                Deny
              </IconButton>
            </div>
          </div>
        </Form>
      </Expander>
    );
  }
}
