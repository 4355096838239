import React from 'react';

import { FLAVORS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import IconLabel from '../widgets/presentational/IconLabel';

const UserBotStatus = props => {
  /** @type {XcBot} */
  const bot = props.bot;

  if (bot.suspended_at) {
    return (
      <IconLabel flavor={false} icon={ICONS.warn}>
        Suspended
      </IconLabel>
    );
  }

  if (bot.disabled_at) {
    return (
      <IconLabel flavor={FLAVORS.muted} icon={ICONS.pause}>
        Disabled
      </IconLabel>
    );
  }

  return (
    <IconLabel flavor={true} icon={ICONS.play}>
      Active
    </IconLabel>
  );
};
export default UserBotStatus;
