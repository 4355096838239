import './CustomerKYCRequestStatus.css';

import React from 'react';

import { KYC_LEVEL2_METHODS } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';
import IconLabel from '../../widgets/presentational/IconLabel';
import KYCAdminActionLabel from './KYCAdminActionLabel';

const cn = classNamer('CustomerKYCRequestStatus');

/**
 * @param {{customer: XcCustomerInfo, kycTrack: string, updating: boolean}} props
 */
const CustomerKYCRequestStatus = props => {
  const ci = props.customer;

  const otherTrack = ci.kyc_request_track !== props.kycTrack;

  const otherTrackLabel = otherTrack && <em> {' (' + ci.kyc_request_track + ')'}</em>;

  if (props.updating) {
    return wrap(
      false,
      <IconLabel flavor={FLAVORS.info} icon={ICONS.wait}>
        PROCESSING
      </IconLabel>
    );
  }

  if (ci.kyc_admin_action) {
    // Something is being waited on
    return wrap(
      true,
      <KYCAdminActionLabel action={ci.kyc_admin_action} alert>
        {otherTrackLabel}
      </KYCAdminActionLabel>
    );
  }

  // Is the customer locked?
  if (ci.kyc_locked_at) {
    return wrap(
      false,
      <IconLabel icon={ICONS.lock} flavor={FLAVORS.danger}>
        <strong>LOCKED</strong>
      </IconLabel>
    );
  }

  if (ci.kyc_level_requested === 2) {
    // Transitioning to KYC 2
    if (ci.kyc2_method === KYC_LEVEL2_METHODS.letter_with_code) {
      // If there is no action, then the letter has been sent
      return wrap(
        true,
        <IconLabel icon={ICONS.wait} flavor={FLAVORS.muted}>
          KYC2 letter taken {otherTrackLabel}
        </IconLabel>
      );
    }
    if (ci.kyc2_method === KYC_LEVEL2_METHODS.in_person_verification) {
      // Announced visit
      return wrap(
        true,
        <IconLabel icon={ICONS.wait} flavor={FLAVORS.muted}>
          KYC2 visit announced {otherTrackLabel}
        </IconLabel>
      );
    }
  }

  // Catch-all
  if (ci.kyc_level_requested) {
    return wrap(
      true,
      <IconLabel icon={ICONS.question} flavor={FLAVORS.muted}>
        Requesting {ci.kyc_level_requested} {otherTrackLabel}
      </IconLabel>
    );
  }

  // Otherwise, nothing is happening. Don't display anything

  return null;

  function wrap(trackBased, content) {
    return (
      <div className={classes(cn(), otherTrack && trackBased && cn('other-track'))}>{content}</div>
    );
  }
};

export default CustomerKYCRequestStatus;
