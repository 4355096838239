import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import IconLabel from '../../widgets/presentational/IconLabel';

const NicknameStatus = props => {
  /** @type {XcUser} */
  const user = props.user;

  if (user.requested_nickname && user.nickname_decline_reason) {
    return (
      <IconLabel icon={ICONS.ban} flavor={false}>
        {user.nickname_decline_reason}
      </IconLabel>
    );
  }

  if (user.requested_nickname && !user.nickname_decline_reason) {
    return (
      <IconLabel icon={ICONS.warn} flavor={FLAVORS.warning}>
        Pending
      </IconLabel>
    );
  }

  return <IconLabel flavor={true}>Approved</IconLabel>;
};

export default NicknameStatus;
