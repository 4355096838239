import './App.css';

import React from 'react';
import { Route, Switch, withRouter } from 'react-router';

import { routes } from '../lib/routes';
import DevRouter from './dev/DevRouter';
import AuthArea from './infrastructure/AuthArea';
import ConnectedComponent from './infrastructure/ConnectedComponent';
import { RouteNotFound } from './infrastructure/RouteNotFound';
import InternalsRouter from './internals/InternalsRouter';
import KYCLetterPage from './kiosk/KYCLetterPage';
import Breadcrumbed from './layout/Breadcrumbed';
import Breadcrumbs from './layout/Breadcrumbs';
import MainMenu from './layout/MainMenu';
import ManagedRouter from './managed/ManagedRouter';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import SettingsRouter from './settings/SettingsRouter';
import TradingRouter from './trading/TradingRouter';
import TransactionsRouter from './transactions/TransactionsRouter';
import TrollboxRouter from './trollbox/TrollboxRouter';
import UsersRouter from './users/UsersRouter';
import CriticalityIndicator from './widgets/presentational/CriticalityIndicator';

const AppSection = ({ isPublic = null, section = null, component: Component }) => {
  return (
    <AuthArea public={isPublic}>
      <div className="App">
        <CriticalityIndicator />
        <MainMenu section={section} />
        <Breadcrumbs />
        <Component section={section} />
      </div>
    </AuthArea>
  );
};

class App extends ConnectedComponent {
  routedSection(section, Component, exact = false, isPublic = false) {
    return (
      <Route
        path={section || undefined}
        exact={exact}
        render={() => {
          return <AppSection isPublic={isPublic} section={section} component={Component} />;
        }}
      />
    );
  }

  render() {
    /** @type Location */
    const location = this.props.location;

    if (location.state && location.state.routeNotFound) {
      // Somewhere, there was a route not found. Display the 404 page.
      return <AppSection component={NotFoundPage} />;
    }

    return (
      <Breadcrumbed title={'Home'} link={routes.HOME}>
        <Switch>
          {this.routedSection(routes.HOME, HomePage, true)}
          {this.routedSection(routes.USERS, UsersRouter)}
          {this.routedSection(routes.TROLLBOX, TrollboxRouter)}
          {this.routedSection(routes.TRADING, TradingRouter)}
          {this.routedSection(routes.TRANSACTIONS, TransactionsRouter)}
          {this.routedSection(routes.MANAGED, ManagedRouter)}
          {this.routedSection(routes.SETTINGS, SettingsRouter)}
          {this.routedSection(routes.INTERNALS, InternalsRouter)}
          {this.container.settings.isDev && this.routedSection(routes.DEV, DevRouter)}
          {this.routedSection(routes.LOGIN, LoginPage, true, true)}

          <Route path={routes.KIOSK_KYC_LETTER} component={KYCLetterPage} />

          <RouteNotFound />
        </Switch>
      </Breadcrumbed>
    );
  }
}

export default withRouter(App);
