import './ExpanderAddOn.css';

import React from 'react';

import { classNamer, classes } from '../../lib/tools';

const cn = classNamer('ExpanderAddOn');

const ExpanderAddOn = ({ className, children }) => {
  return <div className={classes(cn(), className)}>{children}</div>;
};

export default ExpanderAddOn;
