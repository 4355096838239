import './TrollboxProfanityPage.css';

import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';

import { TROLLBOX_ROOMS } from '../../lib/backend';
import { TROLLBOX_PROFANITY_CONFIG_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';

const cn = classNamer('TrollboxProfanityPage');

class TrollboxProfanityPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      room: TROLLBOX_ROOMS.en,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.promiseOrToast(this.container.client.getTrollboxProfanityConfig()).then(data => {
      this.setState({
        data: {
          en_whole_words: data.en_whole_words.join('\n'),
          en_partial_words: data.en_partial_words.join('\n'),
          sr_whole_words: data.sr_whole_words.join('\n'),
          sr_partial_words: data.sr_partial_words.join('\n'),
        },
      });
    });
  }

  parseList = value  => {
    return value.split('\n').map(word => word.trim()).filter(Boolean);
  }

  onSubmit = e => {
    e.preventDefault();

    const { en_whole_words, en_partial_words, sr_whole_words, sr_partial_words } = this.state.data;

    const data = {
      ...this.state.data,
      en_whole_words: this.parseList(en_whole_words),
      en_partial_words: this.parseList(en_partial_words),
      sr_whole_words: this.parseList(sr_whole_words),
      sr_partial_words: this.parseList(sr_partial_words),
    };

    return this.promiseOrSetError(this.container.client.putTrollboxProfanityConfig(data)).then(
      () => {
        this.setState({ error: false }, () =>
          toast('Updated trollbox profanity config', {
            type: 'success',
          })
        );
      }
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid', 'mb-4')} above="Profanity config">
        <Breadcrumbed link={SECTIONS.trollbox.subsectionAtRoute(routes.TROLLBOX_PROFANITY)} />

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />
          {this.state.data && (
            <Form onSubmit={this.onSubmit}>
              <div className="d-flex flex-wrap">
                <BoundFormInput
                  nest
                  className="mr-3"
                  type="textarea"
                  state={this.boundState}
                  name={TROLLBOX_PROFANITY_CONFIG_KEYS.en_whole_words}
                  label="English whole words blacklist:"
                  helpText="Whole words that will be censored in the english chat"
                />
                <BoundFormInput
                  nest
                  className="mr-3"
                  type="textarea"
                  state={this.boundState}
                  name={TROLLBOX_PROFANITY_CONFIG_KEYS.en_partial_words}
                  label="English partial words blacklist:"
                  helpText="Partial words that will be censored in the english chat"
                />
                <BoundFormInput
                  nest
                  className="mr-3"
                  type="textarea"
                  state={this.boundState}
                  name={TROLLBOX_PROFANITY_CONFIG_KEYS.sr_whole_words}
                  label="Serbian whole words blacklist:"
                  helpText="Whole words that will be censored in the serbian chat"
                />
                <BoundFormInput
                  nest
                  type="textarea"
                  state={this.boundState}
                  name={TROLLBOX_PROFANITY_CONFIG_KEYS.sr_partial_words}
                  label="Serbian partial words blacklist:"
                  helpText="Parital words that will be censored in the serbian chat"
                />
              </div>
              <IconButton color="success" icon={ICONS.save}>
                Save
              </IconButton>
            </Form>
          )}
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TrollboxProfanityPage);
