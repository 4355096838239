import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import formatDate from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React from 'react';

const DATE_FNS_OPTIONS = {
  addSuffix: true,
  includeSeconds: true,
};

const TIME_FORMATS = {
  human: 'human',
  date: 'date',
  precise: 'precise',
};

const Time = ({ value, format = TIME_FORMATS.human, utc = false }) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    value = parseISO(value);
  }

  const displayValue = utc ? utcToZonedTime(value, 'UTC') : value;

  let displayStr;

  if (format === TIME_FORMATS.human) {
    displayStr = formatDistanceStrict(displayValue, new Date(), DATE_FNS_OPTIONS);
  } else if (format === TIME_FORMATS.date) {
    displayStr = formatDate(displayValue, 'yyyy/MM/dd');
  } else {
    displayStr = formatDate(displayValue, 'yyyy/MM/dd HH:mm:ss');
  }

  return (
    <time dateTime={value} title={value.toISOString()}>
      {displayStr}
    </time>
  );
};

Time.FORMATS = TIME_FORMATS;

Time.formatter = date => <Time value={date} />;

Time.propTypes = {
  value: PropTypes.any,
  date: PropTypes.bool,
};

export default Time;
