import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Input, Label } from 'reactstrap';

import { Criteria, PAGE_SIZES } from '../../../lib/criterias';
import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('CriteriaPageSize');

class CriteriaPageSize extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    criteria: PropTypes.object.isRequired,
  };

  onChange = e => {
    /** @type History */
    const history = this.props.history;

    history.push(
      Criteria.href(history, this.props.criteria, {
        page_size: e.target.value,
      })
    );
  };

  render() {
    const className = classes(cn(), this.props.className, 'form-inline', 'always-form-inline');

    const id = `CriteriaPageSize_${this.props.criteria.identity}`;

    const currentSize = this.props.criteria.page_size;
    const options = PAGE_SIZES.map(size => {
      return (
        <option key={size} value={size}>
          {size}
        </option>
      );
    });

    return (
      <div className={className}>
        <Label for={id} className="mr-2">
          Page size
        </Label>
        <Input type="select" name="page_size" id={id} value={currentSize} onChange={this.onChange}>
          {options}
        </Input>
      </div>
    );
  }
}

export default withRouter(CriteriaPageSize);
