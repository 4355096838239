import React from 'react';
import { withRouter } from 'react-router';

import { CARD_PAYMENT_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaUserPicker from '../users/widgets/CriteriaUserPicker';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaInstrumentPicker from './CriteriaInstrumentPicker';

const BASE_CRITERIA = {
  sort_field: CARD_PAYMENT_KEYS.created_at,
  sort_direction: 'desc',
  user_id: undefined,
  instruments: undefined,
};

const COLUMNS = [
  new DataTableColumn(CARD_PAYMENT_KEYS.id, 'ID'),
  new DataTableColumn(CARD_PAYMENT_KEYS.payment_id, 'Payment ID'),
  new DataTableColumn(CARD_PAYMENT_KEYS.user_id, 'User ID'),

  new DataTableColumn(CARD_PAYMENT_KEYS.quote_id, 'Quote ID'),
  new DataTableColumn(CARD_PAYMENT_KEYS.order_id, 'Order ID'),

  new DataTableColumn(CARD_PAYMENT_KEYS.instrument, 'Instrument'),
  new DataTableColumn(CARD_PAYMENT_KEYS.quantity, 'Quantity'),

  new DataTableColumn(CARD_PAYMENT_KEYS.fiat_instrument, 'Fiat instrument'),
  new DataTableColumn(CARD_PAYMENT_KEYS.fiat_quantity, 'Fiat quantity'),

  new DataTableColumn(CARD_PAYMENT_KEYS.created_at, 'Created at'),
  new DataTableColumn(CARD_PAYMENT_KEYS.updated_at, 'Updated at'),
  new DataTableColumn(
    CARD_PAYMENT_KEYS.user_request_flow_completed_at,
    'User request flow completed at'
  ),
  new DataTableColumn(CARD_PAYMENT_KEYS.submitted_at, 'Submitted at'),
  new DataTableColumn(CARD_PAYMENT_KEYS.completed_at, 'Completed at'),
  new DataTableColumn(CARD_PAYMENT_KEYS.declined_at, 'Declined at'),
];

const cn = classNamer('TransactionsCardPaymentsPage');

class TransactionsCardPaymentsPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,

      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getCardPayments(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcCardPayment} */
    const cp = this.state.data.items[selectedIndex];
    return (
      <Expander title="Selected" memoryKey={cn('selected-card-payment')}>
        <ObjectInfo object={cp} />
      </Expander>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed
          link={SECTIONS.transactions.subsectionAtRoute(routes.TRANSACTIONS_CARD_PAYMENTS)}
        />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                  <CriteriaInstrumentPicker criteria={this.state.criteria} isMulti />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getCardPaymentsCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TransactionsCardPaymentsPage);
