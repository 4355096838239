import PropTypes from 'prop-types';
import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classes } from '../../../lib/tools';

const IconArticle = ({ className, icon, children, flavor }) => {
  if (flavor === true) {
    flavor = FLAVORS.success;
    icon = icon || ICONS.good;
  } else if (flavor === false) {
    flavor = FLAVORS.danger;
    icon = icon || ICONS.bad;
  }

  return (
    <article className={classes(className, FLAVORS[flavor] && 'text-' + flavor, 'd-flex flex-row')}>
      <div className="flex-grow-0 mr-3 h3">
        <AppIcon icon={icon} />
      </div>
      <div className="flex-grow-1">{children}</div>
    </article>
  );
};

IconArticle.propTypes = {
  className: PropTypes.any,
  icon: PropTypes.any.isRequired,
  flavor: PropTypes.any,
};

export default IconArticle;
