import React from 'react';
import { withRouter } from 'react-router';

import { SORT_DIRECTIONS } from '../../lib/backend';
import {
  FLAVORS,
  PHONE_VERIFICATION_INFO_KEYS,
  PHONE_VERIFICATION_PURPOSE_LABELS,
} from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { phoneVerificationCustomerName } from '../../lib/selectors';
import { abbreviated, classNamer, classes, nestPrefixes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import CountryLabel from '../widgets/presentational/CountryLabel';
import DeviceLabel from '../widgets/presentational/DeviceLabel';
import IconLabel from '../widgets/presentational/IconLabel';
import KYCLevelIcon from '../widgets/presentational/KYCLevelIcon';
import NestedExpandableObjectInfo from '../widgets/presentational/NestedExpandableObjectInfo';
import PhoneNumberLink from '../widgets/presentational/PhoneNumberLink';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaUserPicker from './widgets/CriteriaUserPicker';
import PhoneVerificationStatus from './widgets/PhoneVerificationStatus';

const BASE_CRITERIA = {
  sort_field: PHONE_VERIFICATION_INFO_KEYS.created_at,
  sort_direction: SORT_DIRECTIONS.desc,
  user_id: undefined,
};

const COLUMNS = [
  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.id, 'ID'),
  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.phone,
    'Phone',
    /** XcPhoneVerificationInfo */ pvi => <PhoneNumberLink phone={pvi.phone} />
  ),
  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.twilio_sid,
    'SID',
    /** XcPhoneVerificationInfo */ pvi => (
      <span className="text-monospace">{abbreviated(pvi.twilio_sid)}</span>
    )
  ),

  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.user_id, 'User ID'),
  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.u_email, 'Email'),
  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.u_kyc_level_granted,
    { tooltip: 'KYC Level', title: 'L' },
    /** XcPhoneVerificationInfo */ pvi => (
      <div className="text-center h5">
        <KYCLevelIcon level={pvi.u_kyc_level_granted} />
      </div>
    )
  ),
  new DataTableColumn(
    [
      PHONE_VERIFICATION_INFO_KEYS.c_first_name,
      PHONE_VERIFICATION_INFO_KEYS.c_middle_name,
      PHONE_VERIFICATION_INFO_KEYS.c_last_name,
    ],
    'Identity',
    /** XcPhoneVerificationInfo */ pvi => (
      <span>
        <CountryLabel isoCode={pvi.c_nationality} /> {phoneVerificationCustomerName(pvi)}
      </span>
    )
  ),
  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.c_phone_verified,
    'Verified?',
    /** XcPhoneVerificationInfo */ pvi =>
      pvi.c_phone_verified ? (
        pvi.c_phone_verification_id === pvi.id ? (
          <IconLabel
            flavor={FLAVORS.success}
            icon={ICONS.extra_good}
            title={'Verified by this verification'}
          />
        ) : (
          <IconLabel
            flavor={FLAVORS.success}
            icon={ICONS.good}
            title={'Verified, but not by this verification'}
          />
        )
      ) : (
        <IconLabel flavor={false} title={'Not verified'} />
      )
  ),

  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.gl_ip_address, 'IP'),
  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.gl_country_name,
    'Country',
    CountryLabel.phoneVerificationInfoFormatter
  ),
  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.gl_city_name, 'City'),

  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.ua_device_family,
    'Device',
    DeviceLabel.phoneVerificationInfoFormatter
  ),
  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.ua_os_family, 'OS'),
  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.ua_browser_family, 'Browser'),

  new DataTableColumn(
    PHONE_VERIFICATION_INFO_KEYS.purpose,
    'Purpose',
    /** XcPhoneVerificationInfo */ pvi => (
      <IconLabel>{PHONE_VERIFICATION_PURPOSE_LABELS[pvi.purpose]}</IconLabel>
    )
  ),

  new DataTableColumn(PHONE_VERIFICATION_INFO_KEYS.created_at, 'Created'),
  new DataTableColumn(
    [
      PHONE_VERIFICATION_INFO_KEYS.completed_at,
      PHONE_VERIFICATION_INFO_KEYS.termoinated_at,
      PHONE_VERIFICATION_INFO_KEYS.termination_reason,
    ],
    'Status',
    PhoneVerificationStatus.phoneVerificationInfoFormatter
  ),
];

const GROUPS = [
  new DataTableGroup('User', 3, 7),
  new DataTableGroup('Geo data', 8, 10),
  new DataTableGroup('User agent', 11, 13),
];

const cn = classNamer('PhoneVerificationsPage');

class PhoneVerificationsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate() {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getPhoneVerificationInfos(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcPhoneVerificationInfo} */
    const pvi = this.state.data.items[selectedIndex];

    return (
      <NestedExpandableObjectInfo
        title="Details"
        memoryKey={cn('details')}
        object={nestPrefixes(pvi, ['u', 'c', 'gl', 'ua'], 'phone verification')}
        sectionTitles={{
          u: 'User',
          c: 'Customer',
          gl: 'Geo data',
          ua: 'User agent',
        }}
      />
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_PHONE_VERIFICATIONS)} />
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getPhoneVerificationInfosCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(PhoneVerificationsPage);
