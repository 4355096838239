import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';

import { XcLinkManagedDepositsToBuyOrderPayload } from '../../lib/backend';
import { FLAVORS, MANAGED_DEPOSIT_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import IconLabel from '../widgets/presentational/IconLabel';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const COLUMNS = [
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.id, 'ID'),
  new DataTableColumn(null, 'Transaction ID', (/** XcManagedDeposit */ deposit) => (
    <span>{deposit.transaction_id}</span>
  )),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.instrument, 'Instrument'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.quantity, 'Quantity'),
  new DataTableColumn(null, 'Status', (/** XcManagedDeposit */ deposit) => {
    if (deposit.link_id) {
      return (
        <IconLabel flavor={FLAVORS.success} icon={ICONS.link}>
          Linked
        </IconLabel>
      );
    } else if (deposit.finalized_at) {
      if (!deposit.completed_at) {
        return (
          <IconLabel flavor={FLAVORS.warning} icon={ICONS.pause}>
            On hold
          </IconLabel>
        );
      }

      if (deposit.failure_reason) {
        return <IconLabel flavor={FLAVORS.danger}>Failed</IconLabel>;
      }

      return (
        <IconLabel flavor={FLAVORS.info} icon={ICONS.play}>
          Ready
        </IconLabel>
      );
    }

    return <IconLabel flavor={FLAVORS.info}>Incoming</IconLabel>;
  }),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.buy_order_id, 'Buy Order ID'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.interchange_id, 'Interchange ID'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.finalized_at, 'Finalized at'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.failure_reason, 'Failure reason'),
];

const cn = classNamer('ManagedWalletPage');

class ManagedWalletPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],
      linking: null,
      linkingOrderId: '',
      unlinking: null,
      criteria: Criteria.fromLocation(this.props.location),
      wallet: {},
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    return Promise.all([
      this.promiseOrToast(this.container.client.getBrokerDeposits(this.state.criteria)),
      this.promiseOrToast(this.container.client.getBrokerWallet()),
    ]).then(([data, wallet]) => {
      this.setState({ data, selection: [], wallet });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  linkSelectedDeposits = () => {
    this.setState({
      linking: this.state.selection.filter(index => {
        const deposit = this.state.data.items[index];
        return (
          !deposit.interchange_id &&
          !deposit.link_id &&
          deposit.finalized_at &&
          !deposit.failure_reason
        );
      }),
    });
  };

  confirmLink = selection => {
    const depositIds = selection.map(index => this.state.data.items[index].id);
    const payload = new XcLinkManagedDepositsToBuyOrderPayload({
      deposit_ids: depositIds,
      buy_order_id: this.state.linkingOrderId,
    });
    const promise = this.container.client.postBrokerDepositsLink(payload);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        depositIds.length > 1 ? `${depositIds.length} deposits attached` : `Deposit attached`
      );
      this.loadData();
    });
  };

  unlinkSelectedDeposits = () => {
    this.setState({
      unlinking: this.state.selection.filter(index => {
        const deposit = this.state.data.items[index];
        return deposit.link_id && !deposit.interchange_id;
      }),
    });
  };

  confirmUnlink = selection => {
    const linkIds = selection.map(index => this.state.data.items[index].link_id);

    const promise = this.container.client.deleteBrokerDepositsLink(linkIds);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        linkIds.length > 1 ? `${linkIds.length} deposits detached` : `Deposit detached`
      );
      this.loadData();
    });
  };

  renderSidebar = () => {
    let eligibleDetach = 0;
    let eligibleAttach = 0;
    for (const index of this.state.selection) {
      /** @type {XcManagedDeposit} */
      const deposit = this.state.data.items[index];
      if (deposit.interchange_id || deposit.failure_reason || !deposit.finalized_at) {
        continue;
      }

      if (deposit.link_id) {
        eligibleDetach++;
      } else {
        eligibleAttach++;
      }
    }

    return (
      <Expander title="Actions" memoryKey={cn('actions')}>
        <div className="d-flex">
          <IconButton
            className="flex-fill"
            title="Attach to buy order"
            color={FLAVORS.primary}
            onClick={this.linkSelectedDeposits}
            icon={ICONS.link}
            disabled={eligibleAttach === 0}
          >
            Attach
          </IconButton>
          <IconButton
            title="Detach from buy order"
            color={FLAVORS.danger}
            onClick={this.unlinkSelectedDeposits}
            icon={ICONS.reset}
            className="flex-fill ml-1"
            disabled={eligibleDetach === 0}
          >
            Detach
          </IconButton>
        </div>
      </Expander>
    );
  };

  renderSidebarOne = selectedIndex => {
    const deposit = this.state.data.items[selectedIndex];
    return (
      <div>
        {this.renderSidebar()}
        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo object={deposit} />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = () => {
    return <div>{this.renderSidebar()}</div>;
  };

  renderDeposit = deposit => {
    if (!deposit) {
      return null;
    }
    return (
      <span>
        <strong>{deposit.id}</strong>
      </span>
    );
  };

  render() {
    const availableInstruments = Object.keys(this.state.wallet).length
      ? Object.keys(this.state.wallet).filter(i => !!this.state.wallet[i])
      : [];
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_WALLET)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-4 d-flex flex-wrap">
            {availableInstruments.map(i => (
              <ObjectInfo
                className="w-auto ml-3"
                key={i}
                object={{
                  [i]: (
                    <ObjectInfo
                      object={this.state.wallet[i]}
                      keyBlacklist={['instrument']}
                      formatters={{
                        address: val => <span style={{ fontSize: '0.7rem' }}>{val}</span>,
                      }}
                    />
                  ),
                }}
              />
            ))}
            <hr />
          </div>
          <h3>Deposits</h3>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getBrokerDepositsCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderDeposit}
          onConfirmed={this.confirmLink}
          typeSingular="deposit"
          actionText="attach"
          buttonActionText="Confirm"
          actionKey="linking"
          actionFlavor={FLAVORS.primary}
        >
          <div className="flex-fill mb-3">
            <Input
              type="textarea"
              value={this.state.linkingOrderId}
              onChange={e => this.setState({ linkingOrderId: e.target.value })}
              placeholder="Order ID"
              title="Buy order ID to which you want to link this deposit"
            />
          </div>
        </BoundDangerModal>
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderDeposit}
          onConfirmed={this.confirmUnlink}
          typeSingular="deposit"
          actionText="detach"
          buttonActionText="Confirm"
          actionKey="unlinking"
          actionFlavor={FLAVORS.danger}
        />
      </PageLayout>
    );
  }
}

export default withRouter(ManagedWalletPage);
