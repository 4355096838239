import './Expander.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'reactstrap';

import { AppIcon, ICONS } from '../../lib/icons';
import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';

const cn = classNamer('Expander');

class Expander extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    memoryKey: PropTypes.string,
    danger: PropTypes.bool,
    renderTitle: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };

    if (props.memoryKey) {
      this.state = this.container.localStorage.getComponentMemory(
        cn(),
        props.memoryKey,
        this.state
      );
    }
  }

  toggleOpen = () => {
    this.setState(
      {
        open: !this.state.open,
      },
      () => {
        if (this.props.memoryKey) {
          this.container.localStorage.setComponentMemory(cn(), this.props.memoryKey, this.state);
        }
      }
    );
  };

  render() {
    const { className, title, children, danger, renderTitle } = this.props;

    const titleEl = renderTitle ? (
      renderTitle(this.state.open, this.toggleOpen)
    ) : (
      <div className={cn('title')} onClick={this.toggleOpen}>
        {title}
        <AppIcon icon={this.state.open ? ICONS.expand_up : ICONS.expand_down} />
      </div>
    );

    return (
      <div className={classes(className, cn(), danger && cn('danger'))}>
        {titleEl}
        <Collapse isOpen={this.state.open}>
          <div className={cn('content')}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default Expander;
