import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { CUSTOMER_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import UserLabel from '../users/widgets/UserLabel';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import ManagedActionWidget from './widgets/ManagedActionWidget';

const COLUMNS = [
  new DataTableColumn(CUSTOMER_INFO_KEYS.email, 'Email'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.first_name, 'First name'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.last_name, 'Last name'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.managed_registration_invited_at, 'Invited at'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.managed_registration_token, 'Token'),
  new DataTableColumn(CUSTOMER_INFO_KEYS.managed_registered_at, 'Registered at'),
  new DataTableColumn(
    CUSTOMER_INFO_KEYS.managed_registration_verification_id,
    'Registration SMS id'
  ),
];

const cn = classNamer('ManagedManageAccessPage');

class ManagedManageAccessPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],

      allowing: null,
      denying: null,

      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBrokerUsers(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  confirmAllowAccess = selection => {
    const ids = selection.map(index => this.state.data.items[index].user_id);
    const payload = {
      user_ids: ids,
      manage: true,
    };

    return this.promiseOrToast(this.container.client.putBrokerAccount(payload)).then(() => {
      toast.success(
        ids.length > 1 ? `Granted access for ${ids.length} users` : `Granted access for ${ids[0]}`
      );
      this.loadData();
    });
  };

  confirmDenyAccess = selection => {
    const ids = selection.map(index => this.state.data.items[index].user_id);
    const payload = {
      user_ids: ids,
      manage: false,
    };
    return this.promiseOrToast(this.container.client.putBrokerAccount(payload)).then(() => {
      toast.success(
        ids.length > 1
          ? `Removed access for ${ids.length} users`
          : `Removed access for user ${ids[0]}`
      );
      this.loadData();
    });
  };

  renderBanWidget = () => (
    <ManagedActionWidget state={this.boundState} managingKey="allowing" unmanagingKey="denying" />
  );

  renderSidebarOne = () => {
    return <div>{this.renderBanWidget()}</div>;
  };

  renderSidebarMany = () => {
    return <div>{this.renderBanWidget()}</div>;
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.managed.subsectionAtRoute(routes.MANAGED_USERS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmAllowAccess}
          typeSingular="user"
          actionText="grant access for"
          buttonActionText="Allow"
          actionKey="allowing"
          actionFlavor="primary"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.customerInfoFormatter}
          onConfirmed={this.confirmDenyAccess}
          typeSingular="user"
          actionText="remove access for"
          buttonActionText="Deny"
          actionKey="denying"
        />
      </PageLayout>
    );
  }
}

export default withRouter(ManagedManageAccessPage);
