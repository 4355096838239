import PropTypes from 'prop-types';
import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classes } from '../../../lib/tools';

const IconLabel = ({ className, icon, children, flavor, wrap, title }) => {
  if (flavor === true) {
    flavor = FLAVORS.success;
    icon = icon || ICONS.good;
  } else if (flavor === false) {
    flavor = FLAVORS.danger;
    icon = icon || ICONS.bad;
  }

  return (
    <span
      className={classes(className, FLAVORS[flavor] && 'text-' + flavor, !wrap && 'text-nowrap')}
      title={title}
    >
      {icon && <AppIcon icon={icon} />}
      {children && <span className={icon && 'ml-1'}>{children}</span>}
    </span>
  );
};

IconLabel.propTypes = {
  className: PropTypes.any,
  icon: PropTypes.any,
  flavor: PropTypes.any,
  wrap: PropTypes.bool,
};

export default IconLabel;
