import React from 'react';
import { createSelector } from 'reselect';

import { toastError } from '../../lib/toasts';
import { cancellable, lodash } from '../../lib/tools';

export class BoundState {
  constructor(target, state) {
    Object.assign(this, state);

    /** @type {ConnectedComponent} */
    Object.defineProperty(this, '_target', {
      enumerable: false,
      value: target,
    });
  }

  setState(updater, callback) {
    if (!this._target._unmounted) {
      this._target.setState(updater, callback);
    }
  }
}

// NOTE: This is a fix for WebStorm / PHPStorm completion
//       https://youtrack.jetbrains.com/issue/WEB-36860
//       TODO: Make it work for VSCode as well
export const Component = React['Component'];

/**
 * This base class has the following properties
 *   - It has container at this.container
 *   - It can track promises using this.promise()
 *   - It has a concept of "bound state", that can be passed to a component and they will be able to update it
 */
export default class ConnectedComponent extends Component {
  static registerContainer(container) {
    if (this._container) {
      throw new Error(`A container has already been registered`);
    }

    this._container = container;
  }

  constructor(props) {
    super(props);

    this.state = {
      waiting: 0,
    };

    if (!ConnectedComponent._container) {
      throw new Error(
        `No container has been registered with ConnectedComponent. Did you forget to call ConnectedComponent.registerContainer(container)?`
      );
    }

    /** @type {Container} */
    this.container = ConnectedComponent._container || null;

    /** @type {CancellablePromise[]} */
    this._promises = [];

    /** @type {CancellablePromise[]} */
    this._backgroundPromises = [];

    this._memoizedBoundState = createSelector(
      state => state,
      state => {
        return new BoundState(this, state);
      }
    );
  }

  /**
   * This state will have setState(), which you can use to change it.
   * It is mostly intended to be passed to components that can then use it to update it.
   * @type {BoundState}
   */
  get boundState() {
    return this._memoizedBoundState(this.state);
  }

  /**
   * Track this promise. Error will be caught and set on the local state.error field.
   * @template T
   * @param {Promise<T>} promise
   * @param background Background promises do not influence the waiting state
   * @return {Promise<T>}
   */
  promise(promise, background = false) {
    const promises = background ? this._backgroundPromises : this._promises;
    promise = cancellable(promise)
      .finally(() => {
        const index = promises.indexOf(promise);
        if (index >= 0) {
          promises.splice(index, 1);
        }

        this.setState({
          waiting: this._promises.length,
        });
      })
      .catch(error => {
        if (error.code === 401) {
          // We got logged out
          this.container.auth.logOut();
        }

        throw error;
      });

    promises.push(promise);
    this.setState({
      waiting: this._promises.length,
    });

    return promise;
  }

  /**
   * Track this promise. Error will be caught and set on the local state.error field.
   * @template T
   * @param {Promise<T>} promise
   * @param background Background promises do not influence the waiting state
   * @return {Promise<T>}
   */
  promiseOrSetError(promise, background = false) {
    return this.promise(promise, background).catch(error => {
      this.setState({
        error,
      });

      // Prevent the receiver's then from getting called ever
      return new Promise(() => {});
    });
  }

  /**
   * Track this promise. Error will be caught and shown as toast.
   * @template T
   * @param {Promise<T>} promise
   * @param background Background promises do not influence the waiting state
   * @return {Promise<T>}
   */
  promiseOrToast(promise, background = false) {
    return this.promise(promise, background).catch(error => {
      toastError(error);

      // Prevent the receiver's then from getting called ever
      return new Promise(() => {});
    });
  }

  /**
   * Helper to set shallow nested state. So, if given a patch like {a: {x: 1}, b: {y: 2}},
   * we will merge object at "state.a" with {x:1} and "state.b" with {y:2}.
   * Non-object properties are plastered the usual way
   * @param {object} patch
   */
  setNestedState(patch) {
    const payload = {};
    for (const key in patch) {
      if (Object.prototype.hasOwnProperty.call(patch, key)) {
        if (lodash.isObject(patch[key])) {
          payload[key] = { ...this.state[key], ...patch[key] };
        } else {
          payload[key] = patch[key];
        }
      }
    }

    return this.setState(payload);
  }

  componentWillUnmount() {
    this._unmounted = true;

    // Cancel all active promises
    ['_promises', '_backgroundPromises'].forEach(key => {
      this[key].forEach(promise => {
        promise.cancel();
      });
      delete this[key];
    });
  }
}
