import * as qs from 'query-string';

import { lodash, merge } from './tools';

const CRITERIA_IDENTITIES = new WeakMap();

const DEFAULT_PAGE_SIZE = '20';

export const PAGE_SIZES = ['20', '50', '100', '200', '1000'];

export class Criteria {
  constructor(source) {
    /**
     * Filter to sort on
     */
    this.sort_field = undefined;

    /**
     * @type {SORT_DIRECTIONS}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     */
    this.page_size = undefined;

    merge(this, source, true);

    if (this.page_size === undefined) {
      this.page_size = DEFAULT_PAGE_SIZE;
    }
  }

  equivalent(otherCriteria, transientFields = null) {
    return Criteria.equivalent(this, otherCriteria, transientFields);
  }

  get identity() {
    let identity = CRITERIA_IDENTITIES.get(this);
    if (!identity) {
      identity = JSON.stringify(this);
      CRITERIA_IDENTITIES.set(this, identity);
    }
    return identity;
  }

  /**
   * @param {Location} location
   * @param base Object with base values. Can add additional properties to criteria
   * @return Criteria
   */
  static fromLocation(location, base = undefined) {
    const result = new Criteria(base);
    merge(result, qs.parse(location.search));

    const identity = base ? JSON.stringify(base) + location.search : location.search;
    CRITERIA_IDENTITIES.set(result, identity);

    return result;
  }

  static equivalent(a, b, transientFields) {
    // If identities are the same, than the criterias are def equivalent
    const idA = CRITERIA_IDENTITIES.get(a);
    if (idA && CRITERIA_IDENTITIES.get(b) === idA) {
      return true;
    }

    let transient;
    if (transientFields) {
      if (transientFields.forEach) {
        transient = {};
        transientFields.forEach(key => (transient[key] = true));
      } else {
        transient = transientFields;
      }
    } else {
      transient = {};
    }

    for (const key in a) {
      if (!a.hasOwnProperty(key) || transient[key]) {
        continue;
      }
      if (a[key] !== b[key]) {
        return false;
      }
    }
    for (const key in b) {
      if (!b.hasOwnProperty(key) || transient[key]) {
        continue;
      }
      if (a[key] !== b[key]) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param {History} history
   * @param {...Criteria} criterias
   */
  static href(history, ...criterias) {
    const urlParams = new URLSearchParams();
    for (const criteria of criterias) {
      for (const key in criteria) {
        if (criteria.hasOwnProperty(key) && criteria[key] !== undefined) {
          urlParams.set(key, criteria[key]);
        }
      }
    }

    return history.createHref({
      ...history.location,
      search: '?' + urlParams.toString(),
    });
  }

  /**
   * Apply criteria in-memory on a list of records
   * @param {Criteria} criteria
   * @param data
   */
  static apply(criteria, data) {
    // NOTE: For now, we only support sorting for in-memory data processing

    if (criteria.sort_field) {
      const orders = [];
      const directions = [];
      criteria.sort_field.split(',').forEach(field => {
        orders.push(field);
        directions.push(criteria.sort_direction || 'asc');
      });
      data = lodash.orderBy(data, orders, directions);
    }

    return data;
  }
}
