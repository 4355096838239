import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Form } from 'reactstrap';

import { FIAT_INSTRUMENTS, INSTRUMENTS } from '../../lib/backend';
import { MANAGED_BANK_TRANSFER_KEYS, MANAGED_ORDER_TYPE_PREFIX } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import { BoundDateWithTimeInput } from '../widgets/bound/BoundDateInput';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import BoundFormSelect from '../widgets/bound/BoundFormSelect';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import IconLabel from '../widgets/presentational/IconLabel';

const orderId = props => props.match.params.orderId || null;
const transferId = props => props.match.params.id || null;

const EDIT_PROPS = [
  MANAGED_BANK_TRANSFER_KEYS.order_id,
  MANAGED_BANK_TRANSFER_KEYS.transaction_id,
  MANAGED_BANK_TRANSFER_KEYS.sending_bank_account_number,
  MANAGED_BANK_TRANSFER_KEYS.sending_bank_account_info,
  MANAGED_BANK_TRANSFER_KEYS.receiving_bank_account_number,
  MANAGED_BANK_TRANSFER_KEYS.receiving_bank_account_info,
  MANAGED_BANK_TRANSFER_KEYS.amount,
  MANAGED_BANK_TRANSFER_KEYS.currency,
  MANAGED_BANK_TRANSFER_KEYS.fee,
  MANAGED_BANK_TRANSFER_KEYS.executed_at,
];

class ManagedBankTransfersEditPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcManagedBankTransfer */
      data: {},

      waiting: !!transferId(props),
    };
  }

  get transferId() {
    return transferId(this.props);
  }

  get orderId() {
    return orderId(this.props);
  }

  get returnRoute() {
    if (this.orderId) {
      return this.orderId.startsWith(MANAGED_ORDER_TYPE_PREFIX.buy)
        ? routes.managedBuyOrdersExpandedOrder(this.orderId)
        : routes.managedSellOrdersExpandedOrder(this.orderId);
    }
    return routes.MANAGED_BANK_TRANSFERS;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (transferId(prevProps) !== this.transferId) {
      this.loadData();
    }
  }

  loadData() {
    if (this.isCreatingTransfer) {
      // default options
      this.setState({
        data: {
          [MANAGED_BANK_TRANSFER_KEYS.order_id]: this.orderId,
          [MANAGED_BANK_TRANSFER_KEYS.currency]: INSTRUMENTS.CHF,
        },
      });
      return;
    }
    this.promiseOrSetError(this.container.client.getBrokerBankTransfer(this.transferId)).then(
      data => {
        const initialData = lodash.pick(data, EDIT_PROPS);
        this.setState({ data: { ...initialData }, initData: { ...initialData } });
      }
    );
  }

  onSubmit = e => {
    e.preventDefault();

    const promise = this.isCreatingTransfer
      ? this.container.client.postBrokerBankTransfer(this.state.data)
      : this.container.client.putBrokerBankTransfer(
          this.transferId,
          lodash.pickBy(this.state.data, (val, key) => this.state.initData[key] !== val)
        );
    return this.promiseOrSetError(promise).then(transfer => {
      const message = this.isCreatingTransfer
        ? `Bank transfer ${transfer.id} added`
        : `Bank transfer ${transfer.id} updated`;
      toast(message, {
        type: 'success',
      });

      this.container.history.pushWithQuery(this.returnRoute);
    });
  };

  get isCreatingTransfer() {
    return !this.transferId;
  }

  get isFormReady() {
    const { data } = this.state;

    return (
      data.order_id &&
      data.transaction_id &&
      data.sending_bank_account_info &&
      data.sending_bank_account_number &&
      data.amount &&
      data.currency &&
      data.receiving_bank_account_info &&
      data.receiving_bank_account_number &&
      data.executed_at
    );
  }

  render() {
    const title = this.isCreatingTransfer
      ? `Add new bank transfer`
      : `Edit bank transfer ${this.transferId}`;

    return (
      <GridLayout className="container" above={title}>
        {this.isCreatingTransfer ? (
          <Breadcrumbed title="Add" link={routes.MANAGED_BANK_TRANSFERS_NEW} />
        ) : (
          <Breadcrumbed title="Edit" link={routes.managedBankTransfersEdit(this.transferId)} />
        )}

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />

          <Alert color="info">
            <IconLabel icon={ICONS.question}>
              For recording a fiat currency transfer between two bank accounts
            </IconLabel>
          </Alert>

          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Order ID (order for which this bank transfer was executed)"
                  name={MANAGED_BANK_TRANSFER_KEYS.order_id}
                  state={this.boundState}
                  disabled={!!this.orderId}
                  nest
                />
              </div>
            </div>

            <div>
              <h5 className="text-black-50">Bank</h5>
              <hr className="mt-1" />
            </div>

            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.sending_bank_account_info}
                  label="Sending account info"
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.sending_bank_account_number}
                  label="Sending account number"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormSelect
                  name={MANAGED_BANK_TRANSFER_KEYS.currency}
                  label="Currency"
                  state={this.boundState}
                  nest
                  values={FIAT_INSTRUMENTS}
                  labels={FIAT_INSTRUMENTS}
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  label="Amount"
                  name={MANAGED_BANK_TRANSFER_KEYS.amount}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.receiving_bank_account_info}
                  label="Receiving account info"
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.receiving_bank_account_number}
                  label="Receiving account number"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.transaction_id}
                  label="Transaction ID"
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_BANK_TRANSFER_KEYS.fee}
                  label="Fee (optional)"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundDateWithTimeInput
                  label="Executed at"
                  name={MANAGED_BANK_TRANSFER_KEYS.executed_at}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <IconButton
              color="success"
              className="mr-2"
              icon={ICONS.save}
              disabled={!this.isFormReady}
            >
              {this.isCreatingTransfer ? 'Add' : 'Save'}
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, this.returnRoute)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default ManagedBankTransfersEditPage;
