import PropTypes from 'prop-types';
import React from 'react';

import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Select from '../widgets/interactive/Select';

export default class PairPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    selected: PropTypes.string,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  static getDerivedStateFromProps({ selected }) {
    return {
      selected: selected
        ? selected.includes(',')
          ? selected.split(',').map(pair => ({ pair }))
          : { pair: selected }
        : null,
    };
  }

  loadOptions = () => {
    if (!this.container || !this.container.settings || !this.container.settings.pair_list) {
      return null;
    }

    return this.container.settings.pair_list.map(pair => ({ pair }));
  };

  onChange = option => {
    if (this.props.onChange) {
      this.props.onChange(
        option ? (Array.isArray(option) ? option.map(item => item.pair) : option.pair) : null
      );
    }
  };

  render() {
    return (
      <Select
        options={this.loadOptions()}
        onChange={this.onChange}
        value={this.state.selected}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={pair => <span>{pair.pair}</span>}
        getOptionValue={pair => pair.pair}
        noOptionsMessage={() => 'No pairs'}
        isClearable
        isMulti
      />
    );
  }
}
