import React from 'react';

import { FLAVORS } from '../../../lib/consts';
import BadgeList from '../../widgets/presentational/BadgeList';
import IconLabel from '../../widgets/presentational/IconLabel';

/**
 * @param {{customer: XcCustomerInfo}} props
 */
const CustomerKYCScreeningStatus = props => {
  const ci = props.customer;

  if (!ci.kyc1_created_at) {
    // KYC not even requested, display nothing
    return null;
  }

  if (!ci.kyc1_screening_id) {
    // KYC requested, but still no screening. Probably waiting on document.
    return <span className="text-muted">Not submitted yet</span>;
  }

  if (!ci.screening_found_scopes || !ci.screening_found_scopes.length) {
    // Nothing was flagged, so the screening was automatically cleared
    return <IconLabel flavor={true}>PASSED</IconLabel>;
  }

  const flavor = ci.screening_examined_at
    ? ci.screening_approved
      ? FLAVORS.success // Examined and approved
      : FLAVORS.danger // Examined and rejected
    : FLAVORS.warning; // Still not examined

  return (
    <IconLabel flavor={flavor}>
      <strong>
        {ci.screening_found_matches} found:{' '}
        <BadgeList items={ci.screening_found_scopes} flavor={flavor} />
      </strong>
    </IconLabel>
  );
};

export default CustomerKYCScreeningStatus;
