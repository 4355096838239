import './KYCLevelIcon.css';

import React from 'react';

import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('KYCLevelIcon');

const KYCLevelIcon = ({ level, className }) => {
  return (
    <span className={classes(className, cn(), cn('level-' + level))} title={'KYC Level ' + level}>
      <span>
        <span>{level}</span>
      </span>
    </span>
  );
};

export default KYCLevelIcon;
