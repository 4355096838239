// Auto-generated by generate-client-js. Do not alter manually!

// *********************************************************************************************************************

/**
 * @enum
 */
export const ADMIN_ACTION_INFO_SORT_FIELDS = {
  id: 'id',
  admin_id: 'admin_id',
  email: 'email',
  admin_permissions: 'admin_permissions',
  action: 'action',
  data: 'data',
  user_ids: 'user_ids',
  access_tokens: 'access_tokens',
  pairs: 'pairs',
  instruments: 'instruments',
  api_keys: 'api_keys',
  message_ids: 'message_ids',
  status_code: 'status_code',
  error_message: 'error_message',
  created_at: 'created_at',
};

/**
 * @enum
 */
export const ADMIN_PERMISSIONS = {
  kyc_associate: 'kyc_associate',
  kyc_officer: 'kyc_officer',
  users: 'users',
  security: 'security',
  incentives: 'incentives',
  trollbox: 'trollbox',
  finances: 'finances',
  audit: 'audit',
  developer: 'developer',
  managed: 'managed',
  transactions: 'transactions',
  user_activity: 'user_activity',
};

/**
 * @enum
 */
export const ADMIN_SOCKET_EVENTS = {
  broadcast_message: 'broadcast_message',
  broadcast_message_admin: 'broadcast_message_admin',
  market_depth: 'market_depth',
  price_summary_24h: 'price_summary_24h',
  public_trade: 'public_trade',
  user_trade: 'user_trade',
  order_added: 'order_added',
  order_cancelled: 'order_cancelled',
  order_filled: 'order_filled',
  order_partially_filled: 'order_partially_filled',
  order_triggered: 'order_triggered',
  wallet: 'wallet',
  delete_session: 'delete_session',
  deposit_incoming: 'deposit_incoming',
  deposit_completed: 'deposit_completed',
  withdrawal_completed: 'withdrawal_completed',
  withdrawal_confirmed: 'withdrawal_confirmed',
  kyc_request_stalled: 'kyc_request_stalled',
  kyc_request_resolved: 'kyc_request_resolved',
  card_payment_approved: 'card_payment_approved',
  card_payment_declined: 'card_payment_declined',
  exchange_internals: 'exchange_internals',
  user_suspended: 'user_suspended',
  nickname_resolved: 'nickname_resolved',
  external_rates_updated: 'external_rates_updated',
  market_rates_updated: 'market_rates_updated',
  managed_buy_order_updated: 'managed_buy_order_updated',
  managed_sell_order_updated: 'managed_sell_order_updated',
  execute_email_verification: 'execute_email_verification',
};

/**
 * @enum
 */
export const ADMIN_SOCKET_SECTORS = {
  trollbox: 'trollbox',
  trollbox_admin: 'trollbox_admin',
  pair: 'pair',
  internals: 'internals',
};

/**
 * @enum
 */
export const APP_STAGES = {
  invalid: 'invalid',
  created: 'created',
  ready: 'ready',
  running: 'running',
  disposed: 'disposed',
};

/**
 * @enum
 */
export const BACKEND_ERROR_NAMES = {
  AccessForbiddenError: 'AccessForbiddenError',
  AddressBankError: 'AddressBankError',
  AddressConflictError: 'AddressConflictError',
  AddressForUserNotFoundError: 'AddressForUserNotFoundError',
  AddressNotFoundError: 'AddressNotFoundError',
  AddressUnavailableDueToDisabledDepositsError: 'AddressUnavailableDueToDisabledDepositsError',
  AdminActionMiddlewareError: 'AdminActionMiddlewareError',
  AttemptLimitReachedError: 'AttemptLimitReachedError',
  BotAlreadyDeletedError: 'BotAlreadyDeletedError',
  BotAuthorizationFailedError: 'BotAuthorizationFailedError',
  BotNotFoundError: 'BotNotFoundError',
  BotOwnerSuspendedError: 'BotOwnerSuspendedError',
  CancelCompletedWithdrawalError: 'CancelCompletedWithdrawalError',
  CancelOrderRejectedError: 'CancelOrderRejectedError',
  CancelTakenWithdrawalError: 'CancelTakenWithdrawalError',
  CaptchaRequiredError: 'CaptchaRequiredError',
  CaptchaRequiredForRegistrationError: 'CaptchaRequiredForRegistrationError',
  CardPaymentDisabledForUserError: 'CardPaymentDisabledForUserError',
  CardPaymentManagerError: 'CardPaymentManagerError',
  ComplyAdvantageRequestError: 'ComplyAdvantageRequestError',
  ConsolidationAlreadyCompletedError: 'ConsolidationAlreadyCompletedError',
  ConsolidationManagerError: 'ConsolidationManagerError',
  ConsolidationNotFoundError: 'ConsolidationNotFoundError',
  CustomerManagerError: 'CustomerManagerError',
  CustomerNotFoundError: 'CustomerNotFoundError',
  CustomerRegistrationsAreDisabledError: 'CustomerRegistrationsAreDisabledError',
  CustomizedValidationError: 'CustomizedValidationError',
  DepositAddressNotIssuedError: 'DepositAddressNotIssuedError',
  DepositAlreadyAnnouncedError: 'DepositAlreadyAnnouncedError',
  DepositAlreadyFinalizedError: 'DepositAlreadyFinalizedError',
  DepositInstrumentMismatchError: 'DepositInstrumentMismatchError',
  DepositManagerError: 'DepositManagerError',
  DepositManagerOldError: 'DepositManagerOldError',
  DepositNotFoundError: 'DepositNotFoundError',
  DocumentDownloadKeyNotFoundError: 'DocumentDownloadKeyNotFoundError',
  DocumentMimeTypeUnsupportedError: 'DocumentMimeTypeUnsupportedError',
  DocumentNotFoundError: 'DocumentNotFoundError',
  EmailAlreadyVerifiedError: 'EmailAlreadyVerifiedError',
  FatalExchangeError: 'FatalExchangeError',
  FixerClientError: 'FixerClientError',
  FixerInternalError: 'FixerInternalError',
  InsufficientCustomerDataForKYCScreeningError: 'InsufficientCustomerDataForKYCScreeningError',
  InsufficientFundsForLimitOrderError: 'InsufficientFundsForLimitOrderError',
  InsufficientFundsForManagedPayoutAmountError: 'InsufficientFundsForManagedPayoutAmountError',
  InsufficientFundsForMarketOrderError: 'InsufficientFundsForMarketOrderError',
  InsufficientFundsForSellOrderError: 'InsufficientFundsForSellOrderError',
  InsufficientFundsForStopOrderError: 'InsufficientFundsForStopOrderError',
  InsufficientFundsForWithdrawalError: 'InsufficientFundsForWithdrawalError',
  InsufficientQuantityInMarketError: 'InsufficientQuantityInMarketError',
  InvalidAddressError: 'InvalidAddressError',
  InvalidCaptchaTokenError: 'InvalidCaptchaTokenError',
  InvalidConsolidationInstrumentError: 'InvalidConsolidationInstrumentError',
  InvalidConsolidationQuantityError: 'InvalidConsolidationQuantityError',
  InvalidCustomerStateForKYCVerificationError: 'InvalidCustomerStateForKYCVerificationError',
  InvalidDepositIdsError: 'InvalidDepositIdsError',
  InvalidDepositsStateForConsolidationError: 'InvalidDepositsStateForConsolidationError',
  InvalidEmailOrPasswordError: 'InvalidEmailOrPasswordError',
  InvalidEmailVerificationCodeError: 'InvalidEmailVerificationCodeError',
  InvalidEmailVerificationTokenError: 'InvalidEmailVerificationTokenError',
  InvalidExistingPasswordError: 'InvalidExistingPasswordError',
  InvalidInstrumentPairError: 'InvalidInstrumentPairError',
  InvalidKYC1RequestError: 'InvalidKYC1RequestError',
  InvalidKYC2RequestError: 'InvalidKYC2RequestError',
  InvalidKYC3StateError: 'InvalidKYC3StateError',
  InvalidKYCResolutionError: 'InvalidKYCResolutionError',
  InvalidManagedBuyOrderDistributionError: 'InvalidManagedBuyOrderDistributionError',
  InvalidManagedBuyOrderStateError: 'InvalidManagedBuyOrderStateError',
  InvalidManagedSellOrderStateError: 'InvalidManagedSellOrderStateError',
  InvalidPhoneVerificationCodeError: 'InvalidPhoneVerificationCodeError',
  InvalidSecondFactorTokenError: 'InvalidSecondFactorTokenError',
  InvalidSecurityTokenError: 'InvalidSecurityTokenError',
  InvalidStateForKYCScreeningError: 'InvalidStateForKYCScreeningError',
  InvalidStopPriceError: 'InvalidStopPriceError',
  InvalidWithdrawalConfirmationLinkError: 'InvalidWithdrawalConfirmationLinkError',
  InvalidWithdrawalRequestIdsError: 'InvalidWithdrawalRequestIdsError',
  InvalidWithdrawalStateError: 'InvalidWithdrawalStateError',
  KYC1RequestNotFoundError: 'KYC1RequestNotFoundError',
  KYC2RequestNotFoundError: 'KYC2RequestNotFoundError',
  KYCMicropaymentAuditNotFoundError: 'KYCMicropaymentAuditNotFoundError',
  KYCMicropaymentDocumentNotFoundError: 'KYCMicropaymentDocumentNotFoundError',
  KYCMicropaymentDuplicateError: 'KYCMicropaymentDuplicateError',
  KYCMicropaymentInfoNotFoundError: 'KYCMicropaymentInfoNotFoundError',
  KYCMicropaymentNotFoundError: 'KYCMicropaymentNotFoundError',
  KYCScreeningNotFoundError: 'KYCScreeningNotFoundError',
  KYCScreeningProviderError: 'KYCScreeningProviderError',
  KYCVerificationNotFoundError: 'KYCVerificationNotFoundError',
  KYCVerificationProviderError: 'KYCVerificationProviderError',
  ManagedBankTransferNotFoundError: 'ManagedBankTransferNotFoundError',
  ManagedBuyOrderMinPaymentQuantityError: 'ManagedBuyOrderMinPaymentQuantityError',
  ManagedDepositNotFoundError: 'ManagedDepositNotFoundError',
  ManagedDepositOrderLinkNotFoundError: 'ManagedDepositOrderLinkNotFoundError',
  ManagedOrderNotFoundError: 'ManagedOrderNotFoundError',
  ManagedPartnerTradeNotFoundError: 'ManagedPartnerTradeNotFoundError',
  ManagedPartnerTransferNotFoundError: 'ManagedPartnerTransferNotFoundError',
  ManagedSellOrderWithdrawalQuantityExceedsAvailableError:
    'ManagedSellOrderWithdrawalQuantityExceedsAvailableError',
  MaxCardPaymentQuantityError: 'MaxCardPaymentQuantityError',
  MaxWithdrawalQuantityError: 'MaxWithdrawalQuantityError',
  MinCardPaymentQuantityError: 'MinCardPaymentQuantityError',
  MinOrderQuantityError: 'MinOrderQuantityError',
  MinPayoutAmountPerOrderError: 'MinPayoutAmountPerOrderError',
  MinWithdrawalQuantityError: 'MinWithdrawalQuantityError',
  MismatchedDepositError: 'MismatchedDepositError',
  NetVerifyRequestError: 'NetVerifyRequestError',
  NicknameAlreadyTakenError: 'NicknameAlreadyTakenError',
  NicknameBlacklistedError: 'NicknameBlacklistedError',
  NicknameDeclinedError: 'NicknameDeclinedError',
  NotSupportedBech32AddressError: 'NotSupportedBech32AddressError',
  OrderAbortedError: 'OrderAbortedError',
  OrderNotFoundError: 'OrderNotFoundError',
  OrderRejectedError: 'OrderRejectedError',
  PasswordServiceError: 'PasswordServiceError',
  PhoneVerificationCodeHasExpiredError: 'PhoneVerificationCodeHasExpiredError',
  PhoneVerificationNotAvailableError: 'PhoneVerificationNotAvailableError',
  PhoneVerificationNotFoundError: 'PhoneVerificationNotFoundError',
  PhoneVerificationPhoneNotReachableError: 'PhoneVerificationPhoneNotReachableError',
  PhoneVerificationServiceError: 'PhoneVerificationServiceError',
  PhoneVerificationTerminatedError: 'PhoneVerificationTerminatedError',
  ReportDownloadNotFoundError: 'ReportDownloadNotFoundError',
  ResetPasswordRequestExpiredError: 'ResetPasswordRequestExpiredError',
  ResetPasswordRequestNotFoundError: 'ResetPasswordRequestNotFoundError',
  ResetPasswordTokenAlreadyUsedError: 'ResetPasswordTokenAlreadyUsedError',
  SecondFactorRequiredError: 'SecondFactorRequiredError',
  SessionManagerError: 'SessionManagerError',
  SessionNotFoundError: 'SessionNotFoundError',
  SessionTerminatedError: 'SessionTerminatedError',
  SimplexAmountTooHighError: 'SimplexAmountTooHighError',
  SimplexAmountTooLowError: 'SimplexAmountTooLowError',
  SimplexClientError: 'SimplexClientError',
  SimplexInternalError: 'SimplexInternalError',
  SimplexTimeoutError: 'SimplexTimeoutError',
  SnapshottingTimeoutError: 'SnapshottingTimeoutError',
  TFAAlreadyDisabledError: 'TFAAlreadyDisabledError',
  TFAAlreadyEnabledError: 'TFAAlreadyEnabledError',
  TooManyPhoneVerificationAttemptsError: 'TooManyPhoneVerificationAttemptsError',
  TradingDisabledError: 'TradingDisabledError',
  TrollboxError: 'TrollboxError',
  TrollboxPostingForbiddenError: 'TrollboxPostingForbiddenError',
  TrollboxRateLimitError: 'TrollboxRateLimitError',
  TrollboxRoomError: 'TrollboxRoomError',
  TrollboxUserPermanentlyBannedError: 'TrollboxUserPermanentlyBannedError',
  TrollboxUserTempBannedError: 'TrollboxUserTempBannedError',
  TwilioClientError: 'TwilioClientError',
  TwilioRequestError: 'TwilioRequestError',
  UnableToProvideAddressError: 'UnableToProvideAddressError',
  UnableToSnapshotError: 'UnableToSnapshotError',
  UnauthorizedBrokerRequestError: 'UnauthorizedBrokerRequestError',
  UnauthorizedLoginError: 'UnauthorizedLoginError',
  UnexpectedProcessingError: 'UnexpectedProcessingError',
  UnexpectedTwilioSMSMessageStatusError: 'UnexpectedTwilioSMSMessageStatusError',
  UnknownKYCTrackError: 'UnknownKYCTrackError',
  UnsupportedCurrencyError: 'UnsupportedCurrencyError',
  UnsupportedInstrumentError: 'UnsupportedInstrumentError',
  UserManagerError: 'UserManagerError',
  UserNotFoundError: 'UserNotFoundError',
  UserSuspendedError: 'UserSuspendedError',
  UserWithEmailAlreadyExistsError: 'UserWithEmailAlreadyExistsError',
  VerificationCodeExpiredError: 'VerificationCodeExpiredError',
  VerificationLinkExpiredError: 'VerificationLinkExpiredError',
  WalletManagerError: 'WalletManagerError',
  WalletsInUseError: 'WalletsInUseError',
  WalletsInvalidatedError: 'WalletsInvalidatedError',
  WithdrawalAlreadyConfirmedError: 'WithdrawalAlreadyConfirmedError',
  WithdrawalManagerError: 'WithdrawalManagerError',
  WithdrawalManagerErrorOld: 'WithdrawalManagerErrorOld',
  WithdrawalNotFoundError: 'WithdrawalNotFoundError',
  WithdrawalsDisabledError: 'WithdrawalsDisabledError',
};

/**
 * @enum
 */
export const BOT_INFO_SORT_FIELDS = {
  api_key: 'api_key',
  user_id: 'user_id',
  user_email: 'user_email',
  cidr: 'cidr',
  bot_permissions: 'bot_permissions',
  created_at: 'created_at',
  disabled_at: 'disabled_at',
  suspended_at: 'suspended_at',
  updated_at: 'updated_at',
  seen_at: 'seen_at',
  deleted_at: 'deleted_at',
  description: 'description',
};

/**
 * @enum
 */
export const BOT_PERMISSIONS = {
  read: 'read',
  place_order: 'place_order',
  cancel_order: 'cancel_order',
  withdraw: 'withdraw',
};

/**
 * @enum
 */
export const CARD_PAYMENT_SORT_FIELDS = {
  id: 'id',
  payment_id: 'payment_id',
  user_id: 'user_id',
  quote_id: 'quote_id',
  order_id: 'order_id',
  target_address: 'target_address',
  instrument: 'instrument',
  quantity: 'quantity',
  fiat_instrument: 'fiat_instrument',
  fiat_quantity: 'fiat_quantity',
  created_at: 'created_at',
  updated_at: 'updated_at',
  user_request_flow_completed_at: 'user_request_flow_completed_at',
  submitted_at: 'submitted_at',
  completed_at: 'completed_at',
  declined_at: 'declined_at',
};

/**
 * @enum
 */
export const COUNTRY_CODES = {
  AF: 'AF',
  AX: 'AX',
  AL: 'AL',
  DZ: 'DZ',
  AS: 'AS',
  AD: 'AD',
  AO: 'AO',
  AI: 'AI',
  AQ: 'AQ',
  AG: 'AG',
  AR: 'AR',
  AM: 'AM',
  AW: 'AW',
  AU: 'AU',
  AT: 'AT',
  AZ: 'AZ',
  BS: 'BS',
  BH: 'BH',
  BD: 'BD',
  BB: 'BB',
  BY: 'BY',
  BE: 'BE',
  BZ: 'BZ',
  BJ: 'BJ',
  BM: 'BM',
  BT: 'BT',
  BO: 'BO',
  BQ: 'BQ',
  BA: 'BA',
  BW: 'BW',
  BV: 'BV',
  BR: 'BR',
  IO: 'IO',
  BN: 'BN',
  BG: 'BG',
  BF: 'BF',
  BI: 'BI',
  CV: 'CV',
  KH: 'KH',
  CM: 'CM',
  CA: 'CA',
  KY: 'KY',
  CF: 'CF',
  TD: 'TD',
  CL: 'CL',
  CN: 'CN',
  CX: 'CX',
  CC: 'CC',
  CO: 'CO',
  KM: 'KM',
  CG: 'CG',
  CD: 'CD',
  CK: 'CK',
  CR: 'CR',
  CI: 'CI',
  HR: 'HR',
  CU: 'CU',
  CW: 'CW',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  EC: 'EC',
  EG: 'EG',
  SV: 'SV',
  GQ: 'GQ',
  ER: 'ER',
  EE: 'EE',
  SZ: 'SZ',
  ET: 'ET',
  FK: 'FK',
  FO: 'FO',
  FJ: 'FJ',
  FI: 'FI',
  FR: 'FR',
  GF: 'GF',
  PF: 'PF',
  TF: 'TF',
  GA: 'GA',
  GM: 'GM',
  GE: 'GE',
  DE: 'DE',
  GH: 'GH',
  GI: 'GI',
  GR: 'GR',
  GL: 'GL',
  GD: 'GD',
  GP: 'GP',
  GU: 'GU',
  GT: 'GT',
  GG: 'GG',
  GN: 'GN',
  GW: 'GW',
  GY: 'GY',
  HT: 'HT',
  HM: 'HM',
  VA: 'VA',
  HN: 'HN',
  HK: 'HK',
  HU: 'HU',
  IS: 'IS',
  IN: 'IN',
  ID: 'ID',
  IR: 'IR',
  IQ: 'IQ',
  IE: 'IE',
  IM: 'IM',
  IL: 'IL',
  IT: 'IT',
  JM: 'JM',
  JP: 'JP',
  JE: 'JE',
  JO: 'JO',
  KZ: 'KZ',
  KE: 'KE',
  KI: 'KI',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KG: 'KG',
  LA: 'LA',
  LV: 'LV',
  LB: 'LB',
  LS: 'LS',
  LR: 'LR',
  LY: 'LY',
  LI: 'LI',
  LT: 'LT',
  LU: 'LU',
  MO: 'MO',
  MK: 'MK',
  MG: 'MG',
  MW: 'MW',
  MY: 'MY',
  MV: 'MV',
  ML: 'ML',
  MT: 'MT',
  MH: 'MH',
  MQ: 'MQ',
  MR: 'MR',
  MU: 'MU',
  YT: 'YT',
  MX: 'MX',
  FM: 'FM',
  MD: 'MD',
  MC: 'MC',
  MN: 'MN',
  ME: 'ME',
  MS: 'MS',
  MA: 'MA',
  MZ: 'MZ',
  MM: 'MM',
  NA: 'NA',
  NR: 'NR',
  NP: 'NP',
  NL: 'NL',
  NC: 'NC',
  NZ: 'NZ',
  NI: 'NI',
  NE: 'NE',
  NG: 'NG',
  NU: 'NU',
  NF: 'NF',
  MP: 'MP',
  NO: 'NO',
  OM: 'OM',
  PK: 'PK',
  PW: 'PW',
  PS: 'PS',
  PA: 'PA',
  PG: 'PG',
  PY: 'PY',
  PE: 'PE',
  PH: 'PH',
  PN: 'PN',
  PL: 'PL',
  PT: 'PT',
  PR: 'PR',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RU: 'RU',
  RW: 'RW',
  BL: 'BL',
  SH: 'SH',
  KN: 'KN',
  LC: 'LC',
  MF: 'MF',
  PM: 'PM',
  VC: 'VC',
  WS: 'WS',
  SM: 'SM',
  ST: 'ST',
  SA: 'SA',
  SN: 'SN',
  RS: 'RS',
  SC: 'SC',
  SL: 'SL',
  SG: 'SG',
  SX: 'SX',
  SK: 'SK',
  SI: 'SI',
  SB: 'SB',
  SO: 'SO',
  ZA: 'ZA',
  GS: 'GS',
  SS: 'SS',
  ES: 'ES',
  LK: 'LK',
  SD: 'SD',
  SR: 'SR',
  SJ: 'SJ',
  SE: 'SE',
  CH: 'CH',
  SY: 'SY',
  TW: 'TW',
  TJ: 'TJ',
  TZ: 'TZ',
  TH: 'TH',
  TL: 'TL',
  TG: 'TG',
  TK: 'TK',
  TO: 'TO',
  TT: 'TT',
  TN: 'TN',
  TR: 'TR',
  TM: 'TM',
  TC: 'TC',
  TV: 'TV',
  UG: 'UG',
  UA: 'UA',
  AE: 'AE',
  GB: 'GB',
  US: 'US',
  UM: 'UM',
  UY: 'UY',
  UZ: 'UZ',
  VU: 'VU',
  VE: 'VE',
  VN: 'VN',
  VG: 'VG',
  VI: 'VI',
  WF: 'WF',
  EH: 'EH',
  YE: 'YE',
  ZM: 'ZM',
  ZW: 'ZW',
};

/**
 * @enum
 */
export const CURRENCIES = {
  RSD: 'RSD',
  USD: 'USD',
  EUR: 'EUR',
  CHF: 'CHF',
  GBP: 'GBP',
  RUB: 'RUB',
  DKK: 'DKK',
  NOK: 'NOK',
  CZK: 'CZK',
  PLN: 'PLN',
  SEK: 'SEK',
  AUD: 'AUD',
  HUF: 'HUF',
  CAD: 'CAD',
  JPY: 'JPY',
  NZD: 'NZD',
  TRY: 'TRY',
  ZAR: 'ZAR',
  ILS: 'ILS',
};

/**
 * @enum
 */
export const CUSTOMER_INFO_SORT_FIELDS = {
  user_id: 'user_id',
  kyc_level_requested: 'kyc_level_requested',
  loaded_kyc_request_track: 'loaded_kyc_request_track',
  email: 'email',
  email_verified_at: 'email_verified_at',
  email_verification_requested_at: 'email_verification_requested_at',
  kyc_level_granted: 'kyc_level_granted',
  kyc_level_granted_vqf: 'kyc_level_granted_vqf',
  swiftdil_id: 'swiftdil_id',
  first_name: 'first_name',
  middle_name: 'middle_name',
  last_name: 'last_name',
  dob: 'dob',
  gender: 'gender',
  nationality: 'nationality',
  residence_address: 'residence_address',
  residence_city: 'residence_city',
  residence_postal_code: 'residence_postal_code',
  residence_country: 'residence_country',
  residence_us_state: 'residence_us_state',
  phone: 'phone',
  phone_verified: 'phone_verified',
  internal_notes: 'internal_notes',
  phone_verification_id: 'phone_verification_id',
  created_at: 'created_at',
  updated_at: 'updated_at',
  suspended_at: 'suspended_at',
  kyc_admin_action: 'kyc_admin_action',
  kyc_locked_at: 'kyc_locked_at',
  kyc_lock_message: 'kyc_lock_message',
  kyc_request_track: 'kyc_request_track',
  kyc1_request_id: 'kyc1_request_id',
  kyc1_request_vqf_id: 'kyc1_request_vqf_id',
  kyc2_request_id: 'kyc2_request_id',
  kyc2_request_vqf_id: 'kyc2_request_vqf_id',
  kyc3_micropayment_id: 'kyc3_micropayment_id',
  managed_registration_invited_at: 'managed_registration_invited_at',
  managed_registration_token: 'managed_registration_token',
  managed_registered_at: 'managed_registered_at',
  managed_first_registered_at: 'managed_first_registered_at',
  kyc_micropayment_reference_number: 'kyc_micropayment_reference_number',
  subscribed: 'subscribed',
  reference_number_for_micropayments: 'reference_number_for_micropayments',
  managed_registration_verification_id: 'managed_registration_verification_id',
  sof_profession: 'sof_profession',
  sof_salary_range: 'sof_salary_range',
  sof_origin: 'sof_origin',
  sof_origin_other: 'sof_origin_other',
  sof_expected_volume: 'sof_expected_volume',
  kyc1_verification_id: 'kyc1_verification_id',
  kyc1_screening_id: 'kyc1_screening_id',
  kyc1_created_at: 'kyc1_created_at',
  kyc1_processing_started_at: 'kyc1_processing_started_at',
  kyc1_completed_at: 'kyc1_completed_at',
  kyc1_granted: 'kyc1_granted',
  kyc1_failure_message: 'kyc1_failure_message',
  kyc2_method: 'kyc2_method',
  kyc2_image_id: 'kyc2_image_id',
  kyc2_code: 'kyc2_code',
  kyc2_letter_sent_at: 'kyc2_letter_sent_at',
  kyc2_created_at: 'kyc2_created_at',
  kyc2_processing_started_at: 'kyc2_processing_started_at',
  kyc2_cancelled: 'kyc2_cancelled',
  kyc2_completed_at: 'kyc2_completed_at',
  kyc2_granted: 'kyc2_granted',
  kyc2_failure_message: 'kyc2_failure_message',
  verification_nv_scan_reference: 'verification_nv_scan_reference',
  verification_nv_redirect_url: 'verification_nv_redirect_url',
  verification_nv_verified: 'verification_nv_verified',
  verification_swiftdil_id: 'verification_swiftdil_id',
  verification_document_status: 'verification_document_status',
  verification_document_type: 'verification_document_type',
  verification_document_subtype: 'verification_document_subtype',
  verification_document_number: 'verification_document_number',
  verification_document_personal_number: 'verification_document_personal_number',
  verification_document_uploaded: 'verification_document_uploaded',
  verification_document_verified: 'verification_document_verified',
  verification_mrz_extracted: 'verification_mrz_extracted',
  verification_mrz_optional_data: 'verification_mrz_optional_data',
  verification_similarity_match: 'verification_similarity_match',
  verification_transaction_ip: 'verification_transaction_ip',
  verification_transaction_source: 'verification_transaction_source',
  verification_issuing_country: 'verification_issuing_country',
  verification_issuing_date: 'verification_issuing_date',
  verification_expiry_date: 'verification_expiry_date',
  verification_holder_first_name: 'verification_holder_first_name',
  verification_holder_last_name: 'verification_holder_last_name',
  verification_holder_fathers_name: 'verification_holder_fathers_name',
  verification_holder_mothers_name: 'verification_holder_mothers_name',
  verification_holder_dob: 'verification_holder_dob',
  verification_holder_gender: 'verification_holder_gender',
  verification_holder_birth_place: 'verification_holder_birth_place',
  verification_holder_address_street: 'verification_holder_address_street',
  verification_holder_address_city: 'verification_holder_address_city',
  verification_holder_address_postal_code: 'verification_holder_address_postal_code',
  verification_holder_address_country: 'verification_holder_address_country',
  verification_image_front_id: 'verification_image_front_id',
  verification_image_back_id: 'verification_image_back_id',
  verification_image_face_id: 'verification_image_face_id',
  verification_facemap_url: 'verification_facemap_url',
  verification_reject_document_reason: 'verification_reject_document_reason',
  verification_reject_document_details: 'verification_reject_document_details',
  verification_identity_invalid_reason: 'verification_identity_invalid_reason',
  verification_missing_fields: 'verification_missing_fields',
  verification_inconsistent_fields: 'verification_inconsistent_fields',
  verification_underage: 'verification_underage',
  verification_created_at: 'verification_created_at',
  verification_triggered_at: 'verification_triggered_at',
  verification_token_expires_at: 'verification_token_expires_at',
  verification_submitted_at: 'verification_submitted_at',
  verification_completed_at: 'verification_completed_at',
  verification_abandoned_at: 'verification_abandoned_at',
  verification_overridden_at: 'verification_overridden_at',
  verification_inconsistent_fields_after_override:
    'verification_inconsistent_fields_after_override',
  verification_verified: 'verification_verified',
  screening_swiftdil_id: 'screening_swiftdil_id',
  screening_ca_search_id: 'screening_ca_search_id',
  screening_ca_search_reference: 'screening_ca_search_reference',
  screening_ca_search_profile: 'screening_ca_search_profile',
  screening_executed_scopes: 'screening_executed_scopes',
  screening_found_matches: 'screening_found_matches',
  screening_found_scopes: 'screening_found_scopes',
  screening_executed_at: 'screening_executed_at',
  screening_examined_at: 'screening_examined_at',
  screening_approved: 'screening_approved',
  micropayment_reference_number: 'micropayment_reference_number',
  micropayment_recipient_account: 'micropayment_recipient_account',
  micropayment_transaction_id: 'micropayment_transaction_id',
  micropayment_full_name: 'micropayment_full_name',
  micropayment_iban: 'micropayment_iban',
  micropayment_swift: 'micropayment_swift',
  micropayment_quantity: 'micropayment_quantity',
  micropayment_instrument: 'micropayment_instrument',
  micropayment_notes: 'micropayment_notes',
  micropayment_payment_date: 'micropayment_payment_date',
  micropayment_audit_id: 'micropayment_audit_id',
  micropayment_created_at: 'micropayment_created_at',
  micropayment_updated_at: 'micropayment_updated_at',
  micropayment_processed_at: 'micropayment_processed_at',
  micropayment_approved: 'micropayment_approved',
  micropayment_failure_message: 'micropayment_failure_message',
  micropayment_refunded_at: 'micropayment_refunded_at',
  micropayment_deleted_at: 'micropayment_deleted_at',
  audit_requested_at: 'audit_requested_at',
  audit_performed_at: 'audit_performed_at',
  audit_performed_by_user_id: 'audit_performed_by_user_id',
  audit_result: 'audit_result',
  audit_result_notes: 'audit_result_notes',
  audit_cancelled_at: 'audit_cancelled_at',
  micropayment_documents: 'micropayment_documents',
};

/**
 * @enum
 */
export const DEPOSIT_FAILURE_CODES = {
  aborted: 'aborted',
  lost: 'lost',
  inconsistent: 'inconsistent',
  other: 'other',
};

/**
 * @enum
 */
export const DEPOSIT_SORT_FIELDS = {
  id: 'id',
  transaction_id: 'transaction_id',
  transaction_sub_id: 'transaction_sub_id',
  user_id: 'user_id',
  target_address: 'target_address',
  instrument: 'instrument',
  quantity: 'quantity',
  confirmations_received: 'confirmations_received',
  confirmations_required: 'confirmations_required',
  fee_abs: 'fee_abs',
  fee_pct: 'fee_pct',
  fee_total: 'fee_total',
  transfer_id: 'transfer_id',
  created_at: 'created_at',
  revealed_at: 'revealed_at',
  finalized_at: 'finalized_at',
  failure_code: 'failure_code',
  metadata: 'metadata',
  consolidation_id: 'consolidation_id',
  completed_at: 'completed_at',
};

/**
 * @enum
 */
export const DOCUMENT_MIME_TYPES = {
  pdf: 'pdf',
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
};

/**
 * @enum
 */
export const DOCUMENT_SORT_FIELDS = {
  id: 'id',
  directory: 'directory',
  original_name: 'original_name',
  mime_type: 'mime_type',
  size_in_bytes: 'size_in_bytes',
  metadata: 'metadata',
  created_at: 'created_at',
  deleted_at: 'deleted_at',
};

/**
 * @enum
 */
export const FIAT_INSTRUMENTS = {
  RSD: 'RSD',
  USD: 'USD',
  EUR: 'EUR',
  CHF: 'CHF',
  GBP: 'GBP',
  RUB: 'RUB',
  DKK: 'DKK',
  NOK: 'NOK',
  CZK: 'CZK',
  PLN: 'PLN',
  SEK: 'SEK',
  AUD: 'AUD',
  HUF: 'HUF',
  CAD: 'CAD',
  JPY: 'JPY',
  NZD: 'NZD',
  TRY: 'TRY',
  ZAR: 'ZAR',
  ILS: 'ILS',
};

/**
 * @enum
 */
export const GENDERS = {
  male: 'male',
  female: 'female',
  other: 'other',
};

/**
 * @enum
 */
export const GEO_LOCATION_PINNINGS = {
  strict: 'strict',
  loose: 'loose',
  off: 'off',
};

/**
 * @enum
 */
export const GROWTH_TIMELINE_REPORT_GROUPING_INTERVALS = {
  day: 'day',
};

/**
 * @enum
 */
export const GROWTH_TIMELINE_REPORT_ITEM_SORT_FIELDS = {
  timestamp: 'timestamp',
  volume: 'volume',
  accumulated_volume: 'accumulated_volume',
  revenue: 'revenue',
  accumulated_revenue: 'accumulated_revenue',
  new_users: 'new_users',
  total_users: 'total_users',
  participant_users: 'participant_users',
};

/**
 * @enum
 */
export const IMAGE_FIT_METHODS = {
  cover: 'cover',
  contain: 'contain',
  fill: 'fill',
  inside: 'inside',
  outside: 'outside',
};

/**
 * @enum
 */
export const IMAGE_FORMATS = {
  jpg: 'jpg',
  png: 'png',
};

/**
 * @enum
 */
export const INSTRUMENTS = {
  BTC: 'BTC',
  RSD: 'RSD',
  ETH: 'ETH',
  LTC: 'LTC',
  SFX: 'SFX',
  WSFX: 'WSFX',
  SFT: 'SFT',
  LLM: 'LLM',
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
  GBP: 'GBP',
  RUB: 'RUB',
  DKK: 'DKK',
  NOK: 'NOK',
  CZK: 'CZK',
  PLN: 'PLN',
  SEK: 'SEK',
  AUD: 'AUD',
  HUF: 'HUF',
  CAD: 'CAD',
  JPY: 'JPY',
  NZD: 'NZD',
  TRY: 'TRY',
  ZAR: 'ZAR',
  ILS: 'ILS',
};

/**
 * @enum
 */
export const KYC_ADMIN_ACTION_FILTERS = {
  any: 'any',
  kyc1: 'kyc1',
  kyc2: 'kyc2',
  kyc3: 'kyc3',
  kyc1_verification: 'kyc1_verification',
  kyc1_screening_release: 'kyc1_screening_release',
  kyc1_screening_verdict: 'kyc1_screening_verdict',
  kyc2_upload: 'kyc2_upload',
  kyc2_letter: 'kyc2_letter',
  kyc3_audit: 'kyc3_audit',
  kyc3_audit_result: 'kyc3_audit_result',
};

/**
 * @enum
 */
export const KYC_ADMIN_ACTIONS = {
  kyc1_verification: 'kyc1_verification',
  kyc1_screening_release: 'kyc1_screening_release',
  kyc1_screening_verdict: 'kyc1_screening_verdict',
  kyc2_upload: 'kyc2_upload',
  kyc2_letter: 'kyc2_letter',
  kyc3_audit: 'kyc3_audit',
  kyc3_audit_result: 'kyc3_audit_result',
};

/**
 * @enum
 */
export const KYC_LEVEL2_METHODS = {
  image_upload: 'image_upload',
  in_person_verification: 'in_person_verification',
  letter_with_code: 'letter_with_code',
};

/**
 * @enum
 */
export const KYC_MICROPAYMENT_INFO_SORT_FIELDS = {
  id: 'id',
  reference_number: 'reference_number',
  user_id: 'user_id',
  recipient_account: 'recipient_account',
  transaction_id: 'transaction_id',
  full_name: 'full_name',
  iban: 'iban',
  swift: 'swift',
  quantity: 'quantity',
  instrument: 'instrument',
  notes: 'notes',
  payment_date: 'payment_date',
  audit_id: 'audit_id',
  created_at: 'created_at',
  updated_at: 'updated_at',
  processed_at: 'processed_at',
  approved: 'approved',
  failure_message: 'failure_message',
  refunded_at: 'refunded_at',
  deleted_at: 'deleted_at',
  audit_requested_at: 'audit_requested_at',
  audit_performed_at: 'audit_performed_at',
  audit_performed_by_user_id: 'audit_performed_by_user_id',
  audit_result: 'audit_result',
  audit_result_notes: 'audit_result_notes',
  customer_first_name: 'customer_first_name',
  customer_middle_name: 'customer_middle_name',
  customer_last_name: 'customer_last_name',
  customer_nationality: 'customer_nationality',
  kyc_admin_action: 'kyc_admin_action',
  kyc_locked_at: 'kyc_locked_at',
  kyc_lock_message: 'kyc_lock_message',
  email: 'email',
  kyc_level_granted: 'kyc_level_granted',
  documents: 'documents',
  is_current: 'is_current',
};

/**
 * @enum
 */
export const KYC_TRACKS = {
  base: 'base',
  vqf: 'vqf',
};

/**
 * @enum
 */
export const LANGUAGES = {
  en_us: 'en_us',
  sr_cyrl: 'sr_cyrl',
  sr_latn: 'sr_latn',
};

/**
 * @enum
 */
export const LOG_LEVELS = {
  silent: 'silent',
  error: 'error',
  warn: 'warn',
  info: 'info',
  verbose: 'verbose',
  debug: 'debug',
  trace: 'trace',
};

/**
 * @enum
 */
export const MANAGED_DEPOSITS_CRITERIA_ONLIES = {
  reserved: 'reserved',
  unlinked: 'unlinked',
};

/**
 * @enum
 */
export const MANAGED_PARTNER_EXCHANGES = {
  bitstamp: 'bitstamp',
  binance: 'binance',
  bitfinex: 'bitfinex',
  kraken: 'kraken',
};

/**
 * @enum
 */
export const MANAGED_PARTNER_TRANSFER_CRITERIA_VIEWS = {
  all: 'all',
  deposits: 'deposits',
  withdrawals: 'withdrawals',
};

/**
 * @enum
 */
export const MANAGED_PARTNER_TRANSFER_TYPES = {
  deposit: 'deposit',
  withdrawal: 'withdrawal',
};

/**
 * @enum
 */
export const MARKET_INSTRUMENTS = {
  BTC: 'BTC',
  RSD: 'RSD',
  ETH: 'ETH',
  SFX: 'SFX',
  WSFX: 'WSFX',
  SFT: 'SFT',
};

/**
 * @enum
 */
export const NODE_ENVS = {
  development: 'development',
  production: 'production',
  test: 'test',
};

/**
 * @enum
 */
export const ORDER_INSTRUCTIONS = {
  gtc: 'gtc',
  fok: 'fok',
  ioc: 'ioc',
  aon: 'aon',
};

/**
 * @enum
 */
export const ORDER_SIDES = {
  buy: 'buy',
  sell: 'sell',
};

/**
 * @enum
 */
export const ORDER_TYPES = {
  limit: 'limit',
  market: 'market',
  stop: 'stop',
};

/**
 * @enum
 */
export const PAIRS = {
  BTC_RSD: 'BTC_RSD',
  SFX_RSD: 'SFX_RSD',
  SFT_RSD: 'SFT_RSD',
  ETH_RSD: 'ETH_RSD',
  LTC_RSD: 'LTC_RSD',
  LLM_RSD: 'LLM_RSD',
  SFX_BTC: 'SFX_BTC',
  SFT_BTC: 'SFT_BTC',
  ETH_BTC: 'ETH_BTC',
  LTC_BTC: 'LTC_BTC',
  LLM_BTC: 'LLM_BTC',
  SFT_SFX: 'SFT_SFX',
  ETH_SFX: 'ETH_SFX',
  WSFX_SFX: 'WSFX_SFX',
};

/**
 * @enum
 */
export const PHONE_VERIFICATION_INFO_SORT_FIELDS = {
  id: 'id',
  purpose: 'purpose',
  user_id: 'user_id',
  geo_location_id: 'geo_location_id',
  user_agent: 'user_agent',
  phone: 'phone',
  code: 'code',
  created_at: 'created_at',
  sms_sent_at: 'sms_sent_at',
  twilio_sid: 'twilio_sid',
  completed_at: 'completed_at',
  termoinated_at: 'termoinated_at',
  termination_reason: 'termination_reason',
  u_email: 'u_email',
  u_kyc_level_granted: 'u_kyc_level_granted',
  c_first_name: 'c_first_name',
  c_middle_name: 'c_middle_name',
  c_last_name: 'c_last_name',
  c_dob: 'c_dob',
  c_gender: 'c_gender',
  c_nationality: 'c_nationality',
  c_phone: 'c_phone',
  c_phone_verified: 'c_phone_verified',
  c_phone_verification_id: 'c_phone_verification_id',
  gl_ip_address: 'gl_ip_address',
  gl_has_geo_data: 'gl_has_geo_data',
  gl_country_id: 'gl_country_id',
  gl_country_iso_code: 'gl_country_iso_code',
  gl_country_name: 'gl_country_name',
  gl_state_id: 'gl_state_id',
  gl_state_iso_code: 'gl_state_iso_code',
  gl_state_name: 'gl_state_name',
  gl_city_id: 'gl_city_id',
  gl_city_name: 'gl_city_name',
  gl_latitude: 'gl_latitude',
  gl_longitude: 'gl_longitude',
  gl_accuracy_radius: 'gl_accuracy_radius',
  gl_time_zone: 'gl_time_zone',
  ua_browser_family: 'ua_browser_family',
  ua_browser_major: 'ua_browser_major',
  ua_browser_minor: 'ua_browser_minor',
  ua_browser_type: 'ua_browser_type',
  ua_os_family: 'ua_os_family',
  ua_os_major: 'ua_os_major',
  ua_os_minor: 'ua_os_minor',
  ua_device_family: 'ua_device_family',
  ua_device_brand: 'ua_device_brand',
  ua_device_model: 'ua_device_model',
  ua_device_type: 'ua_device_type',
};

/**
 * @enum
 */
export const PHONE_VERIFICATION_PURPOSES = {
  onboarding: 'onboarding',
  vqf_signature: 'vqf_signature',
};

/**
 * @enum
 */
export const PHONE_VERIFICATION_TERMINATION_REASONS = {
  seeded: 'seeded',
  expired: 'expired',
  twilio_error: 'twilio_error',
  invalid_phone_number: 'invalid_phone_number',
  admin_action: 'admin_action',
};

/**
 * @enum
 */
export const ROLES = {
  customer: 'customer',
  admin: 'admin',
  superadmin: 'superadmin',
};

/**
 * @enum
 */
export const SECURITY_EVENT_INFO_SORT_FIELDS = {
  email: 'email',
  id: 'id',
  event_type: 'event_type',
  user_id: 'user_id',
  security_token: 'security_token',
  additional_data: 'additional_data',
  timestamp: 'timestamp',
  ip_address: 'ip_address',
  has_geo_data: 'has_geo_data',
  country_iso_code: 'country_iso_code',
  country_name: 'country_name',
  city_name: 'city_name',
  latitude: 'latitude',
  longitude: 'longitude',
  accuracy_radius: 'accuracy_radius',
  time_zone: 'time_zone',
  user_agent: 'user_agent',
  browser_family: 'browser_family',
  browser_major: 'browser_major',
  browser_minor: 'browser_minor',
  browser_type: 'browser_type',
  os_family: 'os_family',
  os_major: 'os_major',
  os_minor: 'os_minor',
  device_family: 'device_family',
  device_brand: 'device_brand',
  device_model: 'device_model',
  device_type: 'device_type',
};

/**
 * @enum
 */
export const SECURITY_EVENT_TYPES = {
  login_user: 'login_user',
  register_customer: 'register_customer',
  delete_session: 'delete_session',
  force_log_out_user: 'force_log_out_user',
  request_password_reset: 'request_password_reset',
  execute_password_reset: 'execute_password_reset',
  request_email_verification: 'request_email_verification',
  execute_email_verification: 'execute_email_verification',
  failed_login_limit_reached: 'failed_login_limit_reached',
  tfa_login_timeout: 'tfa_login_timeout',
  tfa_enabled: 'tfa_enabled',
  tfa_disabled: 'tfa_disabled',
  email_changed: 'email_changed',
  password_changed: 'password_changed',
  user_profile_updated: 'user_profile_updated',
  bot_created: 'bot_created',
  bot_updated: 'bot_updated',
  bot_signature_secret_revealed: 'bot_signature_secret_revealed',
};

/**
 * @enum
 */
export const SESSION_INFO_CRITERIA_VIEWS = {
  all: 'all',
  active: 'active',
  terminated: 'terminated',
};

/**
 * @enum
 */
export const SESSION_INFO_SORT_FIELDS = {
  access_token: 'access_token',
  user_id: 'user_id',
  user_email: 'user_email',
  geo_location_validation_old: 'geo_location_validation_old',
  renewed_to_access_token: 'renewed_to_access_token',
  created_at: 'created_at',
  expires_at: 'expires_at',
  idle_timeout: 'idle_timeout',
  seen_at: 'seen_at',
  terminated_at: 'terminated_at',
  termination_reason: 'termination_reason',
  ip_address: 'ip_address',
  has_geo_data: 'has_geo_data',
  country_iso_code: 'country_iso_code',
  country_name: 'country_name',
  city_name: 'city_name',
  latitude: 'latitude',
  longitude: 'longitude',
  accuracy_radius: 'accuracy_radius',
  time_zone: 'time_zone',
  user_agent: 'user_agent',
  browser_family: 'browser_family',
  browser_major: 'browser_major',
  browser_minor: 'browser_minor',
  browser_type: 'browser_type',
  os_family: 'os_family',
  os_major: 'os_major',
  os_minor: 'os_minor',
  device_family: 'device_family',
  device_brand: 'device_brand',
  device_model: 'device_model',
  device_type: 'device_type',
};

/**
 * @enum
 */
export const SESSION_TERMINATION_REASONS = {
  renewed: 'renewed',
  expired: 'expired',
  idle_timeout: 'idle_timeout',
  location_check: 'location_check',
  ua_check: 'ua_check',
  renewal_conflict: 'renewal_conflict',
  logout: 'logout',
  deleted_by_owner: 'deleted_by_owner',
  deleted_by_admin: 'deleted_by_admin',
  password_change: 'password_change',
  user_suspended: 'user_suspended',
  unknown: 'unknown',
};

/**
 * @enum
 */
export const SOF_ORIGINS = {
  salary: 'salary',
  inheritance: 'inheritance',
  business: 'business',
  other: 'other',
};

/**
 * @enum
 */
export const SOF_SALARY_RANGES = {
  sr_0_20: 'sr_0_20',
  sr_20_60: 'sr_20_60',
  sr_60_100: 'sr_60_100',
  sr_100_500: 'sr_100_500',
  sr_500_plus: 'sr_500_plus',
};

/**
 * @enum
 */
export const SORT_DIRECTIONS = {
  asc: 'asc',
  desc: 'desc',
};

/**
 * @enum
 */
export const TRADE_INFO_SORT_FIELDS = {
  id: 'id',
  signature: 'signature',
  pair: 'pair',
  matcher_id: 'matcher_id',
  matcher_type: 'matcher_type',
  matcher_user_id: 'matcher_user_id',
  matcher_user_email: 'matcher_user_email',
  matcher_fee: 'matcher_fee',
  matcher_quantity: 'matcher_quantity',
  matchee_id: 'matchee_id',
  matchee_user_id: 'matchee_user_id',
  matchee_user_email: 'matchee_user_email',
  matchee_fee: 'matchee_fee',
  matchee_quantity: 'matchee_quantity',
  side: 'side',
  price: 'price',
  quantity: 'quantity',
  timestamp: 'timestamp',
  revenue: 'revenue',
};

/**
 * @enum
 */
export const TRANSFER_SORT_FIELDS = {
  id: 'id',
  user_id: 'user_id',
  instrument: 'instrument',
  quantity: 'quantity',
  transfer_source: 'transfer_source',
  deposit_id: 'deposit_id',
  withdrawal_id: 'withdrawal_id',
  created_at: 'created_at',
  rolled_back_at: 'rolled_back_at',
};

/**
 * @enum
 */
export const TRANSFER_TIMELINE_REPORT_CONVERSION_INSTRUMENTS = {
  BTC: 'BTC',
  RSD: 'RSD',
  ETH: 'ETH',
  LTC: 'LTC',
  SFX: 'SFX',
  WSFX: 'WSFX',
  SFT: 'SFT',
  LLM: 'LLM',
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
  GBP: 'GBP',
  RUB: 'RUB',
  DKK: 'DKK',
  NOK: 'NOK',
  CZK: 'CZK',
  PLN: 'PLN',
  SEK: 'SEK',
  AUD: 'AUD',
  HUF: 'HUF',
  CAD: 'CAD',
  JPY: 'JPY',
  NZD: 'NZD',
  TRY: 'TRY',
  ZAR: 'ZAR',
  ILS: 'ILS',
};

/**
 * @enum
 */
export const TRANSFER_TIMELINE_REPORT_GROUPING_INTERVALS = {
  day: 'day',
  month: 'month',
};

/**
 * @enum
 */
export const TRANSFER_TIMELINE_REPORT_ITEM_SORT_FIELDS = {
  timestamp: 'timestamp',
  deposit_count: 'deposit_count',
  deposit_users: 'deposit_users',
  deposit_volume: 'deposit_volume',
  deposit_revenue: 'deposit_revenue',
  withdrawal_count: 'withdrawal_count',
  withdrawal_users: 'withdrawal_users',
  withdrawal_volume: 'withdrawal_volume',
  withdrawal_revenue: 'withdrawal_revenue',
  volume: 'volume',
  revenue: 'revenue',
  accumulated_volume: 'accumulated_volume',
  accumulated_revenue: 'accumulated_revenue',
  new_users: 'new_users',
  total_users: 'total_users',
};

/**
 * @enum
 */
export const TROLLBOX_MESSAGE_FLAGS = {
  spam: 'spam',
  offensive: 'offensive',
};

/**
 * @enum
 */
export const TROLLBOX_MESSAGE_INFO_SORT_FIELDS = {
  id: 'id',
  room: 'room',
  user_id: 'user_id',
  nickname: 'nickname',
  message: 'message',
  original_message: 'original_message',
  timestamp: 'timestamp',
  flag: 'flag',
  role: 'role',
  trollbox_banned_at: 'trollbox_banned_at',
  trollbox_ban_expires_at: 'trollbox_ban_expires_at',
  trollbox_ban_reason: 'trollbox_ban_reason',
};

/**
 * @enum
 */
export const TROLLBOX_ROOMS = {
  en: 'en',
  sr: 'sr',
};

/**
 * @enum
 */
export const UA_BROWSER_TYPES = {
  app: 'app',
  bot: 'bot',
  feedreader: 'feedreader',
  headless: 'headless',
  mail: 'mail',
  mediaplayer: 'mediaplayer',
  podcast: 'podcast',
  proxy: 'proxy',
};

/**
 * @enum
 */
export const UA_DEVICE_TYPES = {
  camera: 'camera',
  car: 'car',
  console: 'console',
  glasses: 'glasses',
  phone: 'phone',
  player: 'player',
  smartphone: 'smartphone',
  smartspeaker: 'smartspeaker',
  tablet: 'tablet',
  tv: 'tv',
  watch: 'watch',
};

/**
 * @enum
 */
export const US_STATE_CODES = {
  AL: 'AL',
  AK: 'AK',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
  PR: 'PR',
};

/**
 * @enum
 */
export const USER_SORT_FIELDS = {
  id: 'id',
  seq_id: 'seq_id',
  role: 'role',
  admin_permissions: 'admin_permissions',
  admin_permissions_old: 'admin_permissions_old',
  email: 'email',
  nickname: 'nickname',
  language: 'language',
  enable_keep_alive: 'enable_keep_alive',
  enable_order_confirmations: 'enable_order_confirmations',
  enable_withdrawal_confirmations: 'enable_withdrawal_confirmations',
  enable_order_notifications: 'enable_order_notifications',
  password_hash: 'password_hash',
  geo_location_pinning: 'geo_location_pinning',
  ip_security_old: 'ip_security_old',
  email_verification_requested_at: 'email_verification_requested_at',
  email_verification_token: 'email_verification_token',
  email_verification_code: 'email_verification_code',
  email_verified_at: 'email_verified_at',
  tfa_enabled_at: 'tfa_enabled_at',
  tfa_secret_key: 'tfa_secret_key',
  kyc_level_granted: 'kyc_level_granted',
  kyc_level_granted_vqf: 'kyc_level_granted_vqf',
  kyc0_grandfathered_in: 'kyc0_grandfathered_in',
  trollbox_banned_at: 'trollbox_banned_at',
  trollbox_ban_expires_at: 'trollbox_ban_expires_at',
  trollbox_ban_reason: 'trollbox_ban_reason',
  created_at: 'created_at',
  updated_at: 'updated_at',
  suspended_at: 'suspended_at',
  deleted_at: 'deleted_at',
  old_email: 'old_email',
  nickname_updated_at: 'nickname_updated_at',
  requested_nickname: 'requested_nickname',
  nickname_decline_reason: 'nickname_decline_reason',
};

/**
 * @enum
 */
export const WALLET_INFO_SORT_FIELDS = {
  user_id: 'user_id',
  user_email: 'user_email',
  loaded: 'loaded',
  instruments: 'instruments',
  snapshotted_at: 'snapshotted_at',
  last_transfer_id: 'last_transfer_id',
  last_trade_id: 'last_trade_id',
  audited_at: 'audited_at',
  audit_clear: 'audit_clear',
};

/**
 * @enum
 */
export const WITHDRAWAL_SORT_FIELDS = {
  id: 'id',
  transaction_id: 'transaction_id',
  user_id: 'user_id',
  target_address: 'target_address',
  instrument: 'instrument',
  quantity: 'quantity',
  fee_abs: 'fee_abs',
  fee_pct: 'fee_pct',
  fee_total: 'fee_total',
  geo_location_id: 'geo_location_id',
  user_agent: 'user_agent',
  created_at: 'created_at',
  confirmed_at: 'confirmed_at',
  confirmation_token: 'confirmation_token',
  approved_at: 'approved_at',
  taken_at: 'taken_at',
  completed_at: 'completed_at',
  more_info: 'more_info',
  stuck_reason: 'stuck_reason',
  metadata: 'metadata',
  failure_code: 'failure_code',
  cancelled_at: 'cancelled_at',
  transfer_id: 'transfer_id',
};

// *********************************************************************************************************************

export class XcAdminActionInfo {
  constructor(/** XcAdminActionInfo */ source) {
    /**
     * Admin action ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * Admin ID
     */
    this.admin_id = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * List of admin permissions this user is given
     * @type {Array<ADMIN_PERMISSIONS>}
     */
    this.admin_permissions = undefined;

    /**
     * Admin action action
     */
    this.action = undefined;

    /**
     * Admin action action data
     */
    this.data = undefined;

    /**
     * Admin action user ids
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Admin action access tokens
     * @type {Array}
     */
    this.access_tokens = undefined;

    /**
     * Admin action pairs
     * @type {Array<PAIRS>}
     */
    this.pairs = undefined;

    /**
     * Admin action instruments
     * @type {Array<MARKET_INSTRUMENTS>}
     */
    this.instruments = undefined;

    /**
     * Admin action api keys
     * @type {Array<string>}
     */
    this.api_keys = undefined;

    /**
     * Admin action message ids
     * @type {Array<string>}
     */
    this.message_ids = undefined;

    /**
     * Admin action status code
     */
    this.status_code = undefined;

    /**
     * Admin action error message
     */
    this.error_message = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    Object.assign(this, source);
  }
}

export class XcAdminActionInfoCriteria {
  constructor(/** XcAdminActionInfoCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {ADMIN_ACTION_INFO_SORT_FIELDS|Array<ADMIN_ACTION_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Return only specific user-id
     * @type {string}
     */
    this.admin_id = undefined;

    Object.assign(this, source);
  }
}

export class XcAdminBotPayload {
  constructor(/** XcAdminBotPayload */ source) {
    /**
     * User who owns the bot
     */
    this.user_id = undefined;

    /**
     * List of permissions this bot is given.
     * @type {Array<BOT_PERMISSIONS>}
     */
    this.bot_permissions = undefined;

    /**
     * Optional IP address mask that can be used to limit origin of bot requests.
     * Eg. 192.168.0.0/16
     * @type {string}
     */
    this.cidr = undefined;

    /**
     * Whether the bot should be disabled
     * @type {boolean}
     */
    this.disabled = undefined;

    /**
     * Text describing what the bot does
     * @type {string}
     */
    this.description = undefined;

    /**
     * Identifier that uniquely identifies this bot
     * @type {string}
     */
    this.api_key = undefined;

    /**
     * Allows admin to set custom signature secret
     */
    this.signature_secret = undefined;

    /**
     * The same as disabled, except only settable by admins
     * @type {boolean}
     */
    this.suspended = undefined;

    Object.assign(this, source);
  }
}

/**
 * Schema to specify expected types from all emitted socket events available to admin API
 */
export class XcAdminSocketEvents {
  constructor(/** XcAdminSocketEvents */ source) {
    /**
     * @type {XcAdminSocketEventTypes}
     */
    this.types = undefined;

    /**
     * @type {ADMIN_SOCKET_EVENTS}
     */
    this.names = undefined;

    /**
     * @type {ADMIN_SOCKET_SECTORS}
     */
    this.sectors = undefined;

    Object.assign(this, source);
  }
}

export class XcAdminSocketEventTypes {
  constructor(/** XcAdminSocketEventTypes */ source) {
    /**
     * @type {XcPublicTrollboxMessage}
     */
    this.broadcast_message = undefined;

    /**
     * @type {XcTrollboxMessageInfo}
     */
    this.broadcast_message_admin = undefined;

    /**
     * @type {XcMarketDepth}
     */
    this.market_depth = undefined;

    /**
     * @type {XcPriceHistorySummary}
     */
    this.price_summary_24h = undefined;

    /**
     * @type {XcAnonymousTradeInfo}
     */
    this.public_trade = undefined;

    /**
     * @type {XcPublicTradeInfo}
     */
    this.user_trade = undefined;

    /**
     * @type {XcCensoredOrder}
     */
    this.order_added = undefined;

    /**
     * @type {XcCensoredOrder}
     */
    this.order_cancelled = undefined;

    /**
     * @type {XcCensoredOrderFilledEventData}
     */
    this.order_filled = undefined;

    /**
     * @type {XcCensoredOrderPartiallyFilledEventData}
     */
    this.order_partially_filled = undefined;

    /**
     * @type {XcCensoredOrder}
     */
    this.order_triggered = undefined;

    /**
     * @type {XcWallet}
     */
    this.wallet = undefined;

    /**
     * @type {XcSession}
     */
    this.delete_session = undefined;

    /**
     * @type {XcCensoredDeposit}
     */
    this.deposit_incoming = undefined;

    /**
     * @type {XcCensoredDeposit}
     */
    this.deposit_completed = undefined;

    /**
     * @type {XcCensoredWithdrawal}
     */
    this.withdrawal_completed = undefined;

    /**
     * @type {XcCensoredWithdrawal}
     */
    this.withdrawal_confirmed = undefined;

    /**
     * @type {XcPublicCustomerInfo}
     */
    this.kyc_request_stalled = undefined;

    /**
     * @type {XcCensoredKYCRequestResolvedEventData}
     */
    this.kyc_request_resolved = undefined;

    /**
     * @type {XcCensoredCardPayment}
     */
    this.card_payment_approved = undefined;

    /**
     * @type {XcCensoredCardPayment}
     */
    this.card_payment_declined = undefined;

    /**
     * @type {XcExchangeInternals}
     */
    this.exchange_internals = undefined;

    /**
     * @type {XcUser}
     */
    this.user_suspended = undefined;

    /**
     * @type {XcUser}
     */
    this.nickname_resolved = undefined;

    /**
     * @type {XcExternalRates}
     */
    this.external_rates_updated = undefined;

    /**
     * @type {XcMarketRates}
     */
    this.market_rates_updated = undefined;

    /**
     * @type {XcManagedBuyOrder}
     */
    this.managed_buy_order_updated = undefined;

    /**
     * @type {XcManagedSellOrder}
     */
    this.managed_sell_order_updated = undefined;

    /**
     * @type {XcUserEmailVerifiedEventData}
     */
    this.execute_email_verification = undefined;

    Object.assign(this, source);
  }
}

/**
 * Schema to specify type info about all the errors exposed to AdminUI consumers
 */
export class XcAdminuiErrorTypes {
  constructor(/** XcAdminuiErrorTypes */ source) {
    /**
     * @type {BACKEND_ERROR_NAMES}
     */
    this.error_names = undefined;

    Object.assign(this, source);
  }
}

export class XcAdminUISchemas {
  constructor(/** XcAdminUISchemas */ source) {
    /**
     * Schema to specify expected types from all emitted socket events available to admin API
     * @type {XcAdminSocketEvents}
     */
    this.socket_events = undefined;

    /**
     * Schema to specify type info about all the errors exposed to AdminUI consumers
     * @type {XcAdminuiErrorTypes}
     */
    this.errors = undefined;

    Object.assign(this, source);
  }
}

export class XcAnonymousTradeInfo {
  constructor(/** XcAnonymousTradeInfo */ source) {
    /**
     * Signature uniquely identifies each trade and is derived from the involved orders.
     * @type {string}
     */
    this.signature = undefined;

    /**
     * One of INSTRUMENT_PAIR-s
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * One of ORDER_SIDES, based on the matcher's SIDE
     */
    this.side = undefined;

    /**
     * Price at which the trade was made. To get the BASE instrument quantity, multiply price with quantity.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * Quantity of the traded QUOTE instrument
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Time when the trade was made. Taken from the matcher's timestamp
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcAuditWalletsPayload {
  constructor(/** XcAuditWalletsPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcBot {
  constructor(/** XcBot */ source) {
    /**
     * Identifier that uniquely identifies this bot
     * @type {string}
     */
    this.api_key = undefined;

    /**
     * User who owns the bot
     */
    this.user_id = undefined;

    /**
     * List of permissions this bot is given.
     * @type {Array<BOT_PERMISSIONS>}
     */
    this.bot_permissions = undefined;

    /**
     * Optional IP address mask that can be used to limit origin of bot requests.
     * Eg. 192.168.0.0/16
     * @type {string}
     */
    this.cidr = undefined;

    /**
     * Last nonce that was used by this bot. This must always be increasing.
     * @type {Number}
     */
    this.last_nonce = undefined;

    /**
     * When was bot record first created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * If bot is disabled, this will be the timestamp when. User can disable own bots.
     * @type {string}
     */
    this.disabled_at = undefined;

    /**
     * If bot is suspended, this will be the timestamp when. Only admins can suspend bots.
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * When was bot last updated by user (excludes seen_at)
     */
    this.updated_at = undefined;

    /**
     * When was bot last seen at
     * @type {string}
     */
    this.seen_at = undefined;

    /**
     * If bot is deleted, this will be set
     * @type {string}
     */
    this.deleted_at = undefined;

    /**
     * Text describing what the bot does
     * @type {string}
     */
    this.description = undefined;

    Object.assign(this, source);
  }
}

export class XcBotInfo {
  constructor(/** XcBotInfo */ source) {
    /**
     * Identifier that uniquely identifies this bot
     * @type {string}
     */
    this.api_key = undefined;

    /**
     * User who owns the bot
     */
    this.user_id = undefined;

    this.user_email = undefined;

    /**
     * Optional IP address mask that can be used to limit origin of bot requests.
     * Eg. 192.168.0.0/16
     * @type {string}
     */
    this.cidr = undefined;

    /**
     * List of permissions this bot is given.
     * @type {Array<BOT_PERMISSIONS>}
     */
    this.bot_permissions = undefined;

    /**
     * When was bot record first created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * If bot is disabled, this will be the timestamp when. User can disable own bots.
     * @type {string}
     */
    this.disabled_at = undefined;

    /**
     * If bot is suspended, this will be the timestamp when. Only admins can suspend bots.
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * When was bot last updated by user (excludes seen_at)
     */
    this.updated_at = undefined;

    /**
     * When was bot last seen at
     * @type {string}
     */
    this.seen_at = undefined;

    /**
     * If bot is deleted, this will be set
     * @type {string}
     */
    this.deleted_at = undefined;

    /**
     * Text describing what the bot does
     * @type {string}
     */
    this.description = undefined;

    Object.assign(this, source);
  }
}

export class XcBotInfosCriteria {
  constructor(/** XcBotInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {BOT_INFO_SORT_FIELDS|Array<BOT_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this user
     */
    this.user_id = undefined;

    Object.assign(this, source);
  }
}

export class XcBotsPayload {
  constructor(/** XcBotsPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.api_keys = undefined;

    Object.assign(this, source);
  }
}

export class XcCardPayment {
  constructor(/** XcCardPayment */ source) {
    this.id = undefined;

    /**
     * Corresponds with Simplex payment id
     */
    this.payment_id = undefined;

    /**
     * User who owns the payment
     */
    this.user_id = undefined;

    /**
     * ID of the simplex quote
     */
    this.quote_id = undefined;

    /**
     * ID of the simplex order
     */
    this.order_id = undefined;

    /**
     * Address to Simplex will make the payment. This will be customer's deposit address.
     */
    this.target_address = undefined;

    /**
     * Instrument which to receive. Eg. 'BTC'
     */
    this.instrument = undefined;

    /**
     * Quantity to receive
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fiat instrument to send. Eg. 'USD'
     */
    this.fiat_instrument = undefined;

    /**
     * Fiat quantity to send
     * @type {string}
     */
    this.fiat_quantity = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * Timestamp when user hits "success" callback URL
     * Differentiates submitted from incomplete requests, hence we can show payment record to user
     * @type {string}
     */
    this.user_request_flow_completed_at = undefined;

    /**
     * @type {string}
     */
    this.submitted_at = undefined;

    /**
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * @type {string}
     */
    this.declined_at = undefined;

    Object.assign(this, source);
  }
}

export class XcCardPaymentsCriteria {
  constructor(/** XcCardPaymentsCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {CARD_PAYMENT_SORT_FIELDS|Array<CARD_PAYMENT_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter for user id
     */
    this.user_id = undefined;

    /**
     * Filter for instruments
     * @type {Array<INSTRUMENTS>}
     */
    this.instruments = undefined;

    /**
     * Filter for fiat instrument
     */
    this.fiat_instrument = undefined;

    /**
     * Filter for whether a deposit has been completed. Leave empty to include both completed and declined.
     * @type {boolean}
     */
    this.completed = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredCardPayment {
  constructor(/** XcCensoredCardPayment */ source) {
    /**
     * Corresponds with Simplex payment id
     */
    this.payment_id = undefined;

    /**
     * ID of the simplex order
     */
    this.order_id = undefined;

    /**
     * Address to Simplex will make the payment. This will be customer's deposit address.
     */
    this.target_address = undefined;

    /**
     * Instrument which to receive. Eg. 'BTC'
     */
    this.instrument = undefined;

    /**
     * Quantity to receive
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fiat instrument to send. Eg. 'USD'
     */
    this.fiat_instrument = undefined;

    /**
     * Fiat quantity to send
     * @type {string}
     */
    this.fiat_quantity = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Timestamp when user hits "success" callback URL
     * Differentiates submitted from incomplete requests, hence we can show payment record to user
     * @type {string}
     */
    this.user_request_flow_completed_at = undefined;

    /**
     * @type {string}
     */
    this.submitted_at = undefined;

    /**
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * @type {string}
     */
    this.declined_at = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredDeposit {
  constructor(/** XcCensoredDeposit */ source) {
    this.id = undefined;

    /**
     * ID of the source transaction (on the blockchain, for example)
     */
    this.transaction_id = undefined;

    /**
     * In cases where one transaction can make several payments, bankers can use this value to make a distinction
     * between multiple payments (transaction_id and transaction_sub_id must be unique within the system).
     * For example, in BTC, this could be index of the output within transaction.
     * Here's a transaction with multiple outputs going to the same address:
     * https://www.blockchain.com/btc/tx/4199f709bfab79f54938e80e11dc75c21a95e3183f8e8994306f5ff272fde42a?show_adv=true
     */
    this.transaction_sub_id = undefined;

    /**
     * Address to which the deposit is coming. This is how we determine the user to credit the deposit with.
     */
    this.target_address = undefined;

    /**
     * The deposit instrument.
     */
    this.instrument = undefined;

    /**
     * Quantity, given as a decimal number
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * How many confirmations were received for a blockchain transaction.
     */
    this.confirmations_received = undefined;

    /**
     * How many blockchain confirmations are required for transaction to succeed.
     */
    this.confirmations_required = undefined;

    /**
     * Absolute fee that was applied (based on user's instrument config)
     * @type {string}
     */
    this.fee_abs = undefined;

    /**
     * Percent fee that was applied (based on user's instrument config).
     * If both percent and abs is present, percent is applied after abs.
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Date when deposit request was created.
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * This is set when deposit is finalized. This is set by the banker in charge of managing the given instrument.
     * If failure_code is not set and finalized_at is, the deposit was successful and user's wallet can be credited.
     * @type {string}
     */
    this.finalized_at = undefined;

    /**
     * In case deposit has failed for whatever reason, this should be set to an explanation message.
     * @type {DEPOSIT_FAILURE_CODES}
     */
    this.failure_code = undefined;

    /**
     * Date when payment of a deposit is finally completed. In most cases, this will be the same date as
     * finalized_at. But if deposits are disabled or there are other issues, completion might be delayed.
     * User's wallet is only credited once deposit is completed. If deposit has been finalized
     * but not completed, user interface will display this state as "ON HOLD".
     * @type {string}
     */
    this.completed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredKYCRequestResolvedEventData {
  constructor(/** XcCensoredKYCRequestResolvedEventData */ source) {
    /**
     * Level that was resolved. Null if no active request was found.
     */
    this.level = undefined;

    /**
     * Whether the request was granted or not
     */
    this.granted = undefined;

    /**
     * @type {XcPublicCustomerInfo}
     */
    this.customer = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredManagedBuyOrder {
  constructor(/** XcCensoredManagedBuyOrder */ source) {
    /**
     * Human-readable order ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * Customer is going to pay for order in this currency. EUR, USD or CHF
     * @type {CURRENCIES}
     */
    this.currency = undefined;

    /**
     * Instrument quantity to be payed
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Percent fee that was applied
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Fixed fee at the moment
     * @type {string}
     */
    this.fee_fixed = undefined;

    /**
     * Total fee that was taken off from the quantity
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Percentage distribution among crypto instruments for buying
     * @type {XcManagedBuyOrderDistribution}
     */
    this.distribution = undefined;

    this.estimated_gains = undefined;

    /**
     * Reference number serving as a unique payment identifier
     * @type {string}
     */
    this.payment_reference_number = undefined;

    /**
     * If this is set, it means the order has failed
     * @type {string}
     */
    this.failure_reason = undefined;

    /**
     * If this is set, it means the order has been suspended
     * @type {string}
     */
    this.suspension_reason = undefined;

    /**
     * Date when the order was put on hold by the service operator
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * Date when the order request was taken by the service operator
     * @type {string}
     */
    this.taken_at = undefined;

    /**
     * Date when the order request was submitted by the user
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when the payment has been received
     * @type {string}
     */
    this.payment_received_at = undefined;

    /**
     * Date when the order was updated
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * This is set to the date when the order has been completed
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * Set if the order has been cancelled by the user
     * @type {string}
     */
    this.cancelled_at = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredOrder {
  constructor(/** XcCensoredOrder */ source) {
    /**
     * Order ID. Auto-generated string.
     * @type {string}
     */
    this.id = undefined;

    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Instrument pair for this order
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * Order type. One of ORDER_TYPES.
     * @type {ORDER_TYPES}
     */
    this.type = undefined;

    /**
     * Side of the order book. One of ORDER_SIDES, buy or sell.
     * @type {ORDER_SIDES}
     */
    this.side = undefined;

    /**
     * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
     * Used to position limit and stop orders.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * The amount of QUOTE instrument user wants to buy or sell.
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fee that will be applied for this order, whether owner is buyer and seller.
     * It will be subtracted from the amount user will have to give or that they will receive
     * (whichever is the base instrument for this order)
     * @type {Number}
     */
    this.fee = undefined;

    /**
     * If this is cancellation request, this is the id to cancel. A lot of other parameters don't matter for these.
     */
    this.cancel_order_id = undefined;

    /**
     * ID of the original (stop) order that has triggered the creation of this order
     */
    this.triggered_by_id = undefined;

    /**
     * Date when this order was processed by the system
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredOrderFilledEventData {
  constructor(/** XcCensoredOrderFilledEventData */ source) {
    /**
     * @type {XcCensoredOrder}
     */
    this.order = undefined;

    /**
     * Whether filled order is a matcher or matchee. If matcher, it will be removed from order book.
     * @type {boolean}
     */
    this.is_matcher = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredOrderPartiallyFilledEventData {
  constructor(/** XcCensoredOrderPartiallyFilledEventData */ source) {
    /**
     * @type {XcCensoredOrder}
     */
    this.order = undefined;

    /**
     * @type {string}
     */
    this.remaining_quantity = undefined;

    /**
     * Whether partially filled order is a matcher or matchee
     * @type {boolean}
     */
    this.is_matcher = undefined;

    Object.assign(this, source);
  }
}

export class XcCensoredWithdrawal {
  constructor(/** XcCensoredWithdrawal */ source) {
    this.id = undefined;

    /**
     * ID of the transaction that was made (on the blockchain, for example)
     */
    this.transaction_id = undefined;

    /**
     * Address where the instrument was sent
     */
    this.target_address = undefined;

    /**
     * The withdrawal instrument.
     * @type {MARKET_INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * The withdrawal quantity, as a decimal
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Absolute fee that was applied (based on user's instrument config)
     * @type {string}
     */
    this.fee_abs = undefined;

    /**
     * Percent fee that was applied (based on user's instrument config)
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
     * This can be used as basis for calculating revenue.
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Date when the withdrawal request was submitted by user
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * This is set to the date when withdrawal has been confirmed. This happens when user
     * clicks "confirm" link in their email, or automatically if that option is disabled.
     * @type {string}
     */
    this.confirmed_at = undefined;

    /**
     * Date when the withdrawal request was taken by the withdrawal processor
     * @type {string}
     */
    this.taken_at = undefined;

    /**
     * Date when the withdrawal was completed, one way or the other
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * If this is set, it means the transaction is paused until customer provides more info
     * @type {string}
     */
    this.more_info = undefined;

    /**
     * Failure code. If this is set, it means the Withdrawal has failed.
     */
    this.failure_code = undefined;

    /**
     * Set if withdrawal has been cancelled by the user
     * @type {string}
     */
    this.cancelled_at = undefined;

    Object.assign(this, source);
  }
}

export class XcConfigInfo {
  constructor(/** XcConfigInfo */ source) {
    this.user_id = undefined;

    this.subject = undefined;

    this.effective = undefined;

    this.configured = undefined;

    Object.assign(this, source);
  }
}

export class XcCSVDownload {
  constructor(/** XcCSVDownload */ source) {
    /**
     * ID the client can call to download the generated CSV data
     */
    this.id = undefined;

    /**
     * Name of the generated file.
     */
    this.file_name = undefined;

    this.page = undefined;

    this.page_size = undefined;

    this.total_records = undefined;

    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcCustomer {
  constructor(/** XcCustomer */ source) {
    /**
     * NOTE: Customer and user share the same id
     */
    this.user_id = undefined;

    /**
     * External ID towards swiftdil. If this is set, it means there is a corresponding customer record at swiftdil
     */
    this.swiftdil_id = undefined;

    /**
     * First name
     */
    this.first_name = undefined;

    /**
     * Middle name
     */
    this.middle_name = undefined;

    /**
     * Last name
     */
    this.last_name = undefined;

    /**
     * Date of birth
     * @type {string}
     */
    this.dob = undefined;

    /**
     * One of GENDERS-s
     * @type {GENDERS}
     */
    this.gender = undefined;

    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.nationality = undefined;

    /**
     * Street address, eg. Maple Street 123
     */
    this.residence_address = undefined;

    /**
     * Name of the city
     */
    this.residence_city = undefined;

    /**
     * Postal code
     */
    this.residence_postal_code = undefined;

    /**
     * Country that corresponds with the address field
     * @type {COUNTRY_CODES}
     */
    this.residence_country = undefined;

    /**
     * US state
     * @type {US_STATE_CODES}
     */
    this.residence_us_state = undefined;

    /**
     * Mobile phone number
     * @type {string}
     */
    this.phone = undefined;

    /**
     * Whether phone has been verified or not
     * @type {boolean}
     */
    this.phone_verified = undefined;

    /**
     * Arbitrary notes attached to the customer. Not visible outside admin.
     */
    this.internal_notes = undefined;

    /**
     * If phone has been verified by the user (as opposed by admin fiat), this will be id of the verification record
     */
    this.phone_verification_id = undefined;

    /**
     * True if this customer existed when we introduced phone verification
     * @type {boolean}
     */
    this.phone_verification_grandfathered_in = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * One of KYC_ADMIN_ACTIONS, this will cause this customer to be flagged for admin action.
     * @type {KYC_ADMIN_ACTIONS}
     */
    this.kyc_admin_action = undefined;

    /**
     * If this is set, customer is locked from submitting further KYC requests. They will be shown status
     * @type {string}
     */
    this.kyc_locked_at = undefined;

    /**
     * Message to be shown to customer in case KYC is locked
     */
    this.kyc_lock_message = undefined;

    /**
     * Which KYC type is currently being requested. One of KYC_TYPE
     * @type {KYC_TRACKS}
     */
    this.kyc_request_track = undefined;

    /**
     * Active KYC1 request id
     */
    this.kyc1_request_id = undefined;

    /**
     * KYC1 request for VQF
     */
    this.kyc1_request_vqf_id = undefined;

    /**
     * Active KYC2 request id
     */
    this.kyc2_request_id = undefined;

    /**
     * KYC2 request for VQF
     */
    this.kyc2_request_vqf_id = undefined;

    /**
     * Active KYC3 micropayment id (serves as equivalent to KYC1 and KYC2 requests)
     */
    this.kyc3_micropayment_id = undefined;

    /**
     * Reference number to be used in KYC micropayments. This will only be set once conditions are met.
     */
    this.reference_number_for_micropayments = undefined;

    /**
     * This will be true if the customer is subscribed to the mailchimp mailing list
     */
    this.subscribed = undefined;

    /**
     * If this is set to a date, user has been invited to register for managed service at given date
     * @type {string}
     */
    this.managed_registration_invited_at = undefined;

    /**
     * Token used to register managed account
     */
    this.managed_registration_token = undefined;

    /**
     * If set to a date, user has access to managed service
     * @type {string}
     */
    this.managed_registered_at = undefined;

    /**
     * // This date is set only the first time the customer is registered.
     * It allow us to keep track of people who EVER had access to managed, even if they don't any more,
     * // for compliance purposes.
     * @type {string}
     */
    this.managed_first_registered_at = undefined;

    /**
     * If the user has registered by signing TOS, this will reference the
     * verification SMS message.
     */
    this.managed_registration_verification_id = undefined;

    /**
     * Source of funds: Customer's profession
     */
    this.sof_profession = undefined;

    /**
     * Source of funds: One of SOF_SALARY_RANGES
     * @type {SOF_SALARY_RANGES}
     */
    this.sof_salary_range = undefined;

    /**
     * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
     * @type {SOF_ORIGINS}
     */
    this.sof_origin = undefined;

    /**
     * Source of funds: In case sof_origin is "other", this is custom input
     */
    this.sof_origin_other = undefined;

    /**
     * Source of funds: Expected volume and frequency of payments
     */
    this.sof_expected_volume = undefined;

    Object.assign(this, source);
  }
}

export class XcCustomerDataPayload {
  constructor(/** XcCustomerDataPayload */ source) {
    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.nationality = undefined;

    /**
     * First name
     */
    this.first_name = undefined;

    /**
     * Middle name
     */
    this.middle_name = undefined;

    /**
     * Last name
     */
    this.last_name = undefined;

    /**
     * Street address, eg. Maple Street 123
     */
    this.residence_address = undefined;

    /**
     * Name of the city
     */
    this.residence_city = undefined;

    /**
     * Postal code
     */
    this.residence_postal_code = undefined;

    /**
     * Country that corresponds with the address field
     * @type {COUNTRY_CODES}
     */
    this.residence_country = undefined;

    /**
     * US state
     * @type {US_STATE_CODES}
     */
    this.residence_us_state = undefined;

    /**
     * One of GENDERS-s
     * @type {GENDERS}
     */
    this.gender = undefined;

    /**
     * Date of birth
     * @type {string}
     */
    this.dob = undefined;

    /**
     * Mobile phone number
     * @type {string}
     */
    this.phone = undefined;

    /**
     * Arbitrary notes attached to the customer. Not visible outside admin.
     */
    this.internal_notes = undefined;

    /**
     * Source of funds: Customer's profession
     */
    this.sof_profession = undefined;

    /**
     * Source of funds: One of SOF_SALARY_RANGES
     * @type {SOF_SALARY_RANGES}
     */
    this.sof_salary_range = undefined;

    /**
     * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
     * @type {SOF_ORIGINS}
     */
    this.sof_origin = undefined;

    /**
     * Source of funds: In case sof_origin is "other", this is custom input
     */
    this.sof_origin_other = undefined;

    /**
     * Source of funds: Expected volume and frequency of payments
     */
    this.sof_expected_volume = undefined;

    Object.assign(this, source);
  }
}

export class XcCustomerInfo {
  constructor(/** XcCustomerInfo */ source) {
    /**
     * NOTE: Customer and user share the same id
     */
    this.user_id = undefined;

    this.kyc_level_requested = undefined;

    /**
     * @type {KYC_TRACKS}
     */
    this.loaded_kyc_request_track = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * If this is set to a date, user's email is verified
     * @type {string}
     */
    this.email_verified_at = undefined;

    /**
     * If this is set to a date, user has requested email verification at given date
     * @type {string}
     */
    this.email_verification_requested_at = undefined;

    /**
     * The highest level of KYC verification user has passed
     * @type {Number}
     */
    this.kyc_level_granted = undefined;

    /**
     * The highest level of KYC verification user has passed for VQF (use with Managed)
     * @type {Number}
     */
    this.kyc_level_granted_vqf = undefined;

    /**
     * External ID towards swiftdil. If this is set, it means there is a corresponding customer record at swiftdil
     */
    this.swiftdil_id = undefined;

    /**
     * First name
     */
    this.first_name = undefined;

    /**
     * Middle name
     */
    this.middle_name = undefined;

    /**
     * Last name
     */
    this.last_name = undefined;

    /**
     * Date of birth
     * @type {string}
     */
    this.dob = undefined;

    /**
     * One of GENDERS-s
     * @type {GENDERS}
     */
    this.gender = undefined;

    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.nationality = undefined;

    /**
     * Street address, eg. Maple Street 123
     */
    this.residence_address = undefined;

    /**
     * Name of the city
     */
    this.residence_city = undefined;

    /**
     * Postal code
     */
    this.residence_postal_code = undefined;

    /**
     * Country that corresponds with the address field
     * @type {COUNTRY_CODES}
     */
    this.residence_country = undefined;

    /**
     * US state
     * @type {US_STATE_CODES}
     */
    this.residence_us_state = undefined;

    /**
     * Mobile phone number
     * @type {string}
     */
    this.phone = undefined;

    /**
     * Whether phone has been verified or not
     * @type {boolean}
     */
    this.phone_verified = undefined;

    /**
     * Arbitrary notes attached to the customer. Not visible outside admin.
     */
    this.internal_notes = undefined;

    /**
     * If phone has been verified by the user (as opposed by admin fiat), this will be id of the verification record
     */
    this.phone_verification_id = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * If this is set, user won't be able to log into the exchange or do anything else
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * One of KYC_ADMIN_ACTIONS, this will cause this customer to be flagged for admin action.
     * @type {KYC_ADMIN_ACTIONS}
     */
    this.kyc_admin_action = undefined;

    /**
     * If this is set, customer is locked from submitting further KYC requests. They will be shown status
     * @type {string}
     */
    this.kyc_locked_at = undefined;

    /**
     * Message to be shown to customer in case KYC is locked
     */
    this.kyc_lock_message = undefined;

    /**
     * Which KYC type is currently being requested. One of KYC_TYPE
     * @type {KYC_TRACKS}
     */
    this.kyc_request_track = undefined;

    /**
     * Active KYC1 request id
     */
    this.kyc1_request_id = undefined;

    /**
     * KYC1 request for VQF
     */
    this.kyc1_request_vqf_id = undefined;

    /**
     * Active KYC2 request id
     */
    this.kyc2_request_id = undefined;

    /**
     * KYC2 request for VQF
     */
    this.kyc2_request_vqf_id = undefined;

    /**
     * Active KYC3 micropayment id (serves as equivalent to KYC1 and KYC2 requests)
     */
    this.kyc3_micropayment_id = undefined;

    /**
     * If this is set to a date, user has been invited to register for managed service at given date
     * @type {string}
     */
    this.managed_registration_invited_at = undefined;

    /**
     * Token used to register managed account
     */
    this.managed_registration_token = undefined;

    /**
     * If set to a date, user has access to managed service
     * @type {string}
     */
    this.managed_registered_at = undefined;

    /**
     * // This date is set only the first time the customer is registered.
     * It allow us to keep track of people who EVER had access to managed, even if they don't any more,
     * // for compliance purposes.
     * @type {string}
     */
    this.managed_first_registered_at = undefined;

    this.kyc_micropayment_reference_number = undefined;

    /**
     * This will be true if the customer is subscribed to the mailchimp mailing list
     */
    this.subscribed = undefined;

    /**
     * Reference number to be used in KYC micropayments. This will only be set once conditions are met.
     */
    this.reference_number_for_micropayments = undefined;

    /**
     * If the user has registered by signing TOS, this will reference the
     * verification SMS message.
     */
    this.managed_registration_verification_id = undefined;

    /**
     * Source of funds: Customer's profession
     */
    this.sof_profession = undefined;

    /**
     * Source of funds: One of SOF_SALARY_RANGES
     * @type {SOF_SALARY_RANGES}
     */
    this.sof_salary_range = undefined;

    /**
     * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
     * @type {SOF_ORIGINS}
     */
    this.sof_origin = undefined;

    /**
     * Source of funds: In case sof_origin is "other", this is custom input
     */
    this.sof_origin_other = undefined;

    /**
     * Source of funds: Expected volume and frequency of payments
     */
    this.sof_expected_volume = undefined;

    /**
     * Verification record for this customer, or null if not done yet.
     */
    this.kyc1_verification_id = undefined;

    /**
     * Screening performed for the purpose of granting KYC 1
     */
    this.kyc1_screening_id = undefined;

    /**
     * Date when the request was first created
     * @type {string}
     */
    this.kyc1_created_at = undefined;

    /**
     * This will be set in case there is an issue with KYC journey which require admin intervention.
     * Customer will be shown the "We are processing your request" screen.
     * @type {string}
     */
    this.kyc1_processing_started_at = undefined;

    /**
     * Date when KYC request was completed, one way or another
     * @type {string}
     */
    this.kyc1_completed_at = undefined;

    /**
     * Whether KYC request was granted
     * @type {boolean}
     */
    this.kyc1_granted = undefined;

    /**
     * If KYC request was unsuccessful, this is the message that will be shown to customer.
     * Leave empty not to show anything.
     */
    this.kyc1_failure_message = undefined;

    /**
     * Method to use when validating KYC level 2
     * @type {KYC_LEVEL2_METHODS}
     */
    this.kyc2_method = undefined;

    /**
     * Image that user was uploaded for KYC verification level 2. This should be a selfie with utility bill.
     */
    this.kyc2_image_id = undefined;

    /**
     * If user has requested verification through a letter, this will be the code they will be sent.
     */
    this.kyc2_code = undefined;

    /**
     * Timestamp where KYC level 2 letter with code was sent. This should be the same as processing_started_at.
     * @type {string}
     */
    this.kyc2_letter_sent_at = undefined;

    /**
     * Date when the request was first created
     * @type {string}
     */
    this.kyc2_created_at = undefined;

    /**
     * Date when request processing was started. User will not be able to alter their request after this is set.
     * @type {string}
     */
    this.kyc2_processing_started_at = undefined;

    /**
     * This will be set to true if request was cancelled by the user
     * @type {boolean}
     */
    this.kyc2_cancelled = undefined;

    /**
     * Date when KYC request was completed, one way or another
     * @type {string}
     */
    this.kyc2_completed_at = undefined;

    /**
     * Whether KYC request was granted
     * @type {boolean}
     */
    this.kyc2_granted = undefined;

    /**
     * If KYC request was unsuccessful, this is the message that will be shown to customer.
     * Leave empty not to show anything.
     */
    this.kyc2_failure_message = undefined;

    /**
     * NetVerify (Jumio) scan reference. This is like an id in their system (guid)
     */
    this.verification_nv_scan_reference = undefined;

    /**
     * URL where we will direct customer to complete this verification
     */
    this.verification_nv_redirect_url = undefined;

    /**
     * Overall NetVerify result of this verification. If this is true, we can in general consider verification
     * successful as far as Jumio is concerned.
     * @type {boolean}
     */
    this.verification_nv_verified = undefined;

    /**
     * NOTE: Swiftdil was KYC provider before we switched to jumio. We will keep this ID so we can
     *       generate link for legacy verifications.
     */
    this.verification_swiftdil_id = undefined;

    /**
     * Matches NV_DOCUMENT_STATUSES. This is the main "resolution" status provided by NetVerify.
     */
    this.verification_document_status = undefined;

    /**
     * Matches NV_DOCUMENT_TYPES
     */
    this.verification_document_type = undefined;

    /**
     * Matches NV_DOCUMENT_SUBTYPES
     */
    this.verification_document_subtype = undefined;

    /**
     * Matches NV_ScanDocument.number
     */
    this.verification_document_number = undefined;

    /**
     * Matches NV_ScanDocument.personalNumber
     */
    this.verification_document_personal_number = undefined;

    /**
     * Whether customer has completed NetVerify journey. This will be false if document_status is not NO_ID_UPLOADED
     * @type {boolean}
     */
    this.verification_document_uploaded = undefined;

    /**
     * If this is true, it means jumio has verified the given document. Derived from document_status
     * @type {boolean}
     */
    this.verification_document_verified = undefined;

    /**
     * Maps to NV_ScanVerification.mrzCheck
     */
    this.verification_mrz_extracted = undefined;

    /**
     * Maps to NV_ScanDocument, optionalData1 and optionalData2
     */
    this.verification_mrz_optional_data = undefined;

    /**
     * The result of NetVerify's similarity check.
     *  true: They believe the selfie is the same as the picture on the ID document
     *  false: They believe that the selfie is NOT the same as the picture in the ID document
     *  null: They were not able to make the comparison,
     *        this could be due to a bad quality image or possibly large age gap between
     *        the selfie and the picture in the ID document
     * @type {boolean}
     */
    this.verification_similarity_match = undefined;

    /**
     * Maps to NV_ScanTransaction.clientIp
     */
    this.verification_transaction_ip = undefined;

    /**
     * Maps to NV_ScanTransaction.source (NV_TRANSACTION_SOURCES)
     */
    this.verification_transaction_source = undefined;

    /**
     * @type {COUNTRY_CODES}
     */
    this.verification_issuing_country = undefined;

    /**
     * Matches to NV_ScanDocument.issuingDate
     */
    this.verification_issuing_date = undefined;

    /**
     * Matches to NV_ScanDocument.expiry
     */
    this.verification_expiry_date = undefined;

    /**
     * Matches to NV_ScanDocument.firstName
     */
    this.verification_holder_first_name = undefined;

    /**
     * Matches to NV_ScanDocument.lastName
     */
    this.verification_holder_last_name = undefined;

    /**
     * Matches to NV_ScanDocument.fathersName
     */
    this.verification_holder_fathers_name = undefined;

    /**
     * Matches to NV_ScanDocument.mothersName
     */
    this.verification_holder_mothers_name = undefined;

    /**
     * Matches to NV_ScanDocument.dob
     */
    this.verification_holder_dob = undefined;

    /**
     * Matches to NV_ScanDocument.gender
     */
    this.verification_holder_gender = undefined;

    /**
     * Matches to NV_ScanDocument.placeOfBirth
     */
    this.verification_holder_birth_place = undefined;

    /**
     * Extracted from NV_ScanAddress
     */
    this.verification_holder_address_street = undefined;

    /**
     * Extracted from NV_ScanAddress
     */
    this.verification_holder_address_city = undefined;

    /**
     * Extracted from NV_ScanAddress
     */
    this.verification_holder_address_postal_code = undefined;

    /**
     * Extracted from NV_ScanAddress
     */
    this.verification_holder_address_country = undefined;

    /**
     * Uploaded document front image
     */
    this.verification_image_front_id = undefined;

    /**
     * Uploaded document back image
     */
    this.verification_image_back_id = undefined;

    /**
     * Uploaded selfie image
     */
    this.verification_image_face_id = undefined;

    /**
     * URL from which facemap (in binary format) can be downloaded
     */
    this.verification_facemap_url = undefined;

    /**
     * Corresponds to NV_ScanVerification.rejectReason.rejectReasonDescription
     */
    this.verification_reject_document_reason = undefined;

    /**
     * Corresponds to NV_ScanVerification.rejectReason.rejectReasonDetails
     */
    this.verification_reject_document_details = undefined;

    /**
     * If identity check isn't valid, this will be the reason why.
     * Corresponds to NV_ScanVerification.identityVerification.reason (NV_IDENTITY_FAIL_REASONS)
     */
    this.verification_identity_invalid_reason = undefined;

    /**
     * List of customer fields which we expected to see in verification data, but they were not extracted.
     * This is determined by our system, not NetVerify.
     * @type {Array<string>}
     */
    this.verification_missing_fields = undefined;

    /**
     * List of customer fields which were inconsistent with scanned data at the time of verification.
     * This is determined by our system, not NetVerify.
     * @type {Array<string>}
     */
    this.verification_inconsistent_fields = undefined;

    /**
     * If true, holder has been determined to be underage according to our definition, at the time of
     * verification
     * @type {boolean}
     */
    this.verification_underage = undefined;

    /**
     * Date when verification transaction was first created
     * @type {string}
     */
    this.verification_created_at = undefined;

    /**
     * Date when the verification was last triggered. Customer can request verification multiple times,
     * depending on the TTL of verification session.
     * @type {string}
     */
    this.verification_triggered_at = undefined;

    /**
     * Date when transaction token expires. The idea is to reuse transactions
     * up to a certain date.
     * @type {string}
     */
    this.verification_token_expires_at = undefined;

    /**
     * Date when customer has submitted the document. If this is set, UI should prevent customer from
     * triggering verification again, until this one is completed.
     * NOTE: Under rare circumstances, I expect this can be null even if verification has completed.
     *       This is because we must rely on xcalibra frontend to send notification when this happens.
     * @type {string}
     */
    this.verification_submitted_at = undefined;

    /**
     * Date when verification has been completed, one way or another.
     * @type {string}
     */
    this.verification_completed_at = undefined;

    /**
     * In case verification is close to expiration, we will create a new one and abandon this one.
     * It will still be updated when it is completed by Jumio, but it won't affect KYC status of customer.
     * @type {string}
     */
    this.verification_abandoned_at = undefined;

    /**
     * If there are some missing_fields or inconsistent_fields, admin can click a button and "override" the failed verification.
     * This will cause verified to be set to true.
     * @type {string}
     */
    this.verification_overridden_at = undefined;

    /**
     * This will have an updated list of inconsistent fields, at the time admin clicks "override".
     * @type {Array<string>}
     */
    this.verification_inconsistent_fields_after_override = undefined;

    /**
     * Final verification result. Combines nv_verified and our own checks. If this is true, we can
     * send the customer true over to the screening.
     * @type {boolean}
     */
    this.verification_verified = undefined;

    /**
     * NOTE: Swiftdil was KYC provider before we switched to jumio
     */
    this.screening_swiftdil_id = undefined;

    /**
     * ComplyAdvantage (jumio) search id. This is what we can use through API
     */
    this.screening_ca_search_id = undefined;

    /**
     * ComplyAdvantage (jumio) search reference. This is what we can use to generate a link to CA admin portal
     */
    this.screening_ca_search_reference = undefined;

    /**
     * Search profile that was used
     */
    this.screening_ca_search_profile = undefined;

    /**
     * List of scopes (alert types) that were included in the search profile
     * @type {Array<string>}
     */
    this.screening_executed_scopes = undefined;

    /**
     * Number of found matches. 0 here means we are 100% good.
     * @type {Number}
     */
    this.screening_found_matches = undefined;

    /**
     * Unified list of all found scopes (alert types), taken from all the matches and deduped
     * @type {Array<string>}
     */
    this.screening_found_scopes = undefined;

    /**
     * Date when screening was executed
     * @type {string}
     */
    this.screening_executed_at = undefined;

    /**
     * Date when human has examined found results and set "approved" to true or false.
     * @type {string}
     */
    this.screening_examined_at = undefined;

    /**
     * Whether customer has been approved. This is automatically set to true if there are no results.
     * @type {boolean}
     */
    this.screening_approved = undefined;

    /**
     * Reference number that was used to match payment with a particular user record.
     */
    this.micropayment_reference_number = undefined;

    /**
     * The (our) account which received the payment. A free-text containing bank name, account number and other info
     */
    this.micropayment_recipient_account = undefined;

    /**
     * Transaction id provided by the bank
     */
    this.micropayment_transaction_id = undefined;

    /**
     * Payer's full name. This must match their KYC data.
     */
    this.micropayment_full_name = undefined;

    /**
     * IBAN from the payment (eg. RS3466 7890 4567 4389)
     */
    this.micropayment_iban = undefined;

    /**
     * Swift number from the payment (eg. RA345BG)
     */
    this.micropayment_swift = undefined;

    /**
     * Quantity received
     * @type {string}
     */
    this.micropayment_quantity = undefined;

    /**
     * Fiat instrument received
     */
    this.micropayment_instrument = undefined;

    /**
     * Additional payment details, free text field
     */
    this.micropayment_notes = undefined;

    /**
     * Date of the payment, according to bank
     * @type {string}
     */
    this.micropayment_payment_date = undefined;

    /**
     * Reference to the current micropayment audit being performed.
     */
    this.micropayment_audit_id = undefined;

    /**
     * @type {string}
     */
    this.micropayment_created_at = undefined;

    /**
     * @type {string}
     */
    this.micropayment_updated_at = undefined;

    /**
     * Set when we finished examining this micropayment. It has even been approved or rejected (maybe due to audit)
     * @type {string}
     */
    this.micropayment_processed_at = undefined;

    /**
     * Whether this micropayment was approved as valid. Approving it can cause KYC level to be granted.
     * @type {boolean}
     */
    this.micropayment_approved = undefined;

    /**
     * If micropayment is not approved, this can contain a rejection message, to be shown to the customer.
     */
    this.micropayment_failure_message = undefined;

    /**
     * When we refund the customer, we will mark it here. TODO: What else do refunds need?
     * @type {string}
     */
    this.micropayment_refunded_at = undefined;

    /**
     * Soft-delete flag
     * @type {string}
     */
    this.micropayment_deleted_at = undefined;

    /**
     * When was audit requested. This is set automatically when admin enters a micropayment for the first time.
     * @type {string}
     */
    this.audit_requested_at = undefined;

    /**
     * When was the audit performed by the auditor
     * @type {string}
     */
    this.audit_performed_at = undefined;

    /**
     * Who performed the audit
     */
    this.audit_performed_by_user_id = undefined;

    /**
     * Did audit pass. Only set after it is performed.
     * @type {boolean}
     */
    this.audit_result = undefined;

    /**
     * Notes explaining why audit failed, or if it passed, some optional extra info.
     */
    this.audit_result_notes = undefined;

    /**
     * If audit was cancelled, this will be set to the date when. Audit can be cancelled when micropayment
     * is deleted or changes user.
     * @type {string}
     */
    this.audit_cancelled_at = undefined;

    /**
     * @type {Array<XcDocument>}
     */
    this.micropayment_documents = undefined;

    /**
     * @type {COUNTRY_CODES}
     */
    this.verification_vqf_issuing_country = undefined;

    Object.assign(this, source);
  }
}

export class XcCustomerInfosCriteria {
  constructor(/** XcCustomerInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {CUSTOMER_INFO_SORT_FIELDS|Array<CUSTOMER_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Which track to use when deciding what data to include. Required.
     * @type {KYC_TRACKS}
     */
    this.kyc_track = undefined;

    /**
     * Return only specific user-ids
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Return only specific nationalities
     * @type {Array<string>}
     */
    this.nationalities = undefined;

    /**
     * KYC admin action filter. Besides a direct KYC_ADMIN_ACTIONS value, it also support values from KYC_ADMIN_ACTION_FILTERS.
     * @type {KYC_ADMIN_ACTION_FILTERS}
     */
    this.admin_action = undefined;

    Object.assign(this, source);
  }
}

export class XcDeclineNicknamePayload {
  constructor(/** XcDeclineNicknamePayload */ source) {
    /**
     * List of user ids to target
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * @type {string}
     */
    this.reason = undefined;

    Object.assign(this, source);
  }
}

export class XcDeleteWalletSnapshotsPayload {
  constructor(/** XcDeleteWalletSnapshotsPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcDeposit {
  constructor(/** XcDeposit */ source) {
    this.id = undefined;

    /**
     * ID of the source transaction (on the blockchain, for example)
     */
    this.transaction_id = undefined;

    /**
     * In cases where one transaction can make several payments, bankers can use this value to make a distinction
     * between multiple payments (transaction_id and transaction_sub_id must be unique within the system).
     * For example, in BTC, this could be index of the output within transaction.
     * Here's a transaction with multiple outputs going to the same address:
     * https://www.blockchain.com/btc/tx/4199f709bfab79f54938e80e11dc75c21a95e3183f8e8994306f5ff272fde42a?show_adv=true
     */
    this.transaction_sub_id = undefined;

    /**
     * User who received the deposit.
     */
    this.user_id = undefined;

    /**
     * Address to which the deposit is coming. This is how we determine the user to credit the deposit with.
     */
    this.target_address = undefined;

    /**
     * The deposit instrument.
     */
    this.instrument = undefined;

    /**
     * Quantity, given as a decimal number
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * How many confirmations were received for a blockchain transaction.
     */
    this.confirmations_received = undefined;

    /**
     * How many blockchain confirmations are required for transaction to succeed.
     */
    this.confirmations_required = undefined;

    /**
     * Absolute fee that was applied (based on user's instrument config)
     * @type {string}
     */
    this.fee_abs = undefined;

    /**
     * Percent fee that was applied (based on user's instrument config).
     * If both percent and abs is present, percent is applied after abs.
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Transfer that was created as a consequence of this deposit. Only set if successful.
     */
    this.transfer_id = undefined;

    /**
     * Date when deposit request was created.
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when the deposit was revealed to the customer. If this is null, customer can't see the deposit.
     * In normal situation, this should be the same as created_at. Once revealed, deposit is never again hidden.
     * @type {string}
     */
    this.revealed_at = undefined;

    /**
     * This is set when deposit is finalized. This is set by the banker in charge of managing the given instrument.
     * If failure_code is not set and finalized_at is, the deposit was successful and user's wallet can be credited.
     * @type {string}
     */
    this.finalized_at = undefined;

    /**
     * In case deposit has failed for whatever reason, this should be set to an explanation message.
     * @type {DEPOSIT_FAILURE_CODES}
     */
    this.failure_code = undefined;

    /**
     * Instrument-specific deposit metadata. For example, blockchain block index.
     * This is maintained by the banker infrastructure.
     */
    this.metadata = undefined;

    /**
     * Reference to consolidation record for this deposit. If this is set, it means the entire quantity
     * of this deposit has been moved to cold storage.
     */
    this.consolidation_id = undefined;

    /**
     * Date when payment of a deposit is finally completed. In most cases, this will be the same date as
     * finalized_at. But if deposits are disabled or there are other issues, completion might be delayed.
     * User's wallet is only credited once deposit is completed. If deposit has been finalized
     * but not completed, user interface will display this state as "ON HOLD".
     * @type {string}
     */
    this.completed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcDepositsCriteria {
  constructor(/** XcDepositsCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {DEPOSIT_SORT_FIELDS|Array<DEPOSIT_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter for user id
     */
    this.user_id = undefined;

    /**
     * Filter for instruments
     * @type {Array<INSTRUMENTS>}
     */
    this.instruments = undefined;

    /**
     * Filter for whether a deposit has been revealed. Leave empty to include both hidden and revealed.
     * @type {boolean}
     */
    this.revealed = undefined;

    /**
     * Filter for whether a deposit has been finalized. Leave empty to include both finalized and incoming.
     * @type {boolean}
     */
    this.finalized = undefined;

    /**
     * Filter for whether a deposit has been completed. A deposit can be finalized, but not completed.
     * Leave empty to include both completed and not completed.
     * @type {boolean}
     */
    this.completed = undefined;

    /**
     * Filter for whether a deposit is consolidated or not. Leave empty to include all.
     * @type {boolean}
     */
    this.consolidated = undefined;

    Object.assign(this, source);
  }
}

export class XcDocument {
  constructor(/** XcDocument */ source) {
    /**
     * Auto-generated image id, uniquely identifying this image.
     * @type {string}
     */
    this.id = undefined;

    /**
     * Subdirectory inside the store where document is kept
     * @type {string}
     */
    this.directory = undefined;

    /**
     * Original document filename
     */
    this.original_name = undefined;

    /**
     * Mime type of document. It will (generally) be one of DOCUMENT_MIME_TYPES
     * @type {DOCUMENT_MIME_TYPES}
     */
    this.mime_type = undefined;

    /**
     * How many bytes does the document take
     * @type {Number}
     */
    this.size_in_bytes = undefined;

    /**
     * Optional additional data about document, for debugging purposes mostly
     */
    this.metadata = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.deleted_at = undefined;

    Object.assign(this, source);
  }
}

export class XcDocumentCriteria {
  constructor(/** XcDocumentCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {DOCUMENT_SORT_FIELDS|Array<DOCUMENT_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    Object.assign(this, source);
  }
}

export class XcDocumentDownload {
  constructor(/** XcDocumentDownload */ source) {
    /**
     * Key that can be used to download document without authorization
     */
    this.download_key = undefined;

    /**
     * Original (and suggested) file name of the downloading document
     */
    this.file_name = undefined;

    /**
     * Based on this, client can decide how to treat the download (eg. new tab or same)
     */
    this.mime_type = undefined;

    Object.assign(this, source);
  }
}

export class XcDocumentUploadBody {
  constructor(/** XcDocumentUploadBody */ source) {
    this.document_name = undefined;

    Object.assign(this, source);
  }
}

export class XcEstimatorConfig {
  constructor(/** XcEstimatorConfig */ source) {
    /**
     * Price estimator config per instrument
     * @type {XcEstimatorConfigPerInstrument}
     */
    this.config_per_instrument = undefined;

    Object.assign(this, source);
  }
}

/**
 * Price estimator config per instrument
 */
export class XcEstimatorConfigPerInstrument {
  constructor(/** XcEstimatorConfigPerInstrument */ source) {
    /**
     * @type {XcEstimatorInstrumentConfig}
     */
    this.SFT = undefined;

    /**
     * @type {XcEstimatorInstrumentConfig}
     */
    this.SFX = undefined;

    /**
     * @type {XcEstimatorInstrumentConfig}
     */
    this.BTC = undefined;

    /**
     * @type {XcEstimatorInstrumentConfig}
     */
    this.ETH = undefined;

    Object.assign(this, source);
  }
}

export class XcEstimatorInstrumentConfig {
  constructor(/** XcEstimatorInstrumentConfig */ source) {
    /**
     * Number of market depth levels to work with
     * @type {Number}
     */
    this.market_depth_limit = undefined;

    /**
     * @type {Number}
     */
    this.max_price = undefined;

    /**
     * @type {boolean}
     */
    this.partner_provided = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.partner_market_base = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.local_market_base = undefined;

    Object.assign(this, source);
  }
}

export class XcExchangeAudit {
  constructor(/** XcExchangeAudit */ source) {
    /**
     * Whether the numbers match or not
     * @type {boolean}
     */
    this.clear = undefined;

    /**
     * @type {Object.<string, XcExchangeAuditInstrument>}
     */
    this.by_instrument = undefined;

    this.started_at = undefined;

    this.completed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcExchangeAuditInstrument {
  constructor(/** XcExchangeAuditInstrument */ source) {
    this.instrument = undefined;

    /**
     * Whether the numbers match or not
     * @type {boolean}
     */
    this.clear = undefined;

    /**
     * Difference between deposits and withdraws. Total of this instrument that should be in the system.
     * @type {string}
     */
    this.total = undefined;

    /**
     * Total revenue earned from this instrument
     * @type {string}
     */
    this.revenue = undefined;

    /**
     * The amount that is *unused* in user wallets
     * @type {string}
     */
    this.in_wallets = undefined;

    /**
     * The amount that is in active orders
     * @type {string}
     */
    this.in_orders = undefined;

    /**
     * What's the difference between transfer input and wallet balances. We want this to be 0.
     * @type {string}
     */
    this.discrepancy = undefined;

    Object.assign(this, source);
  }
}

export class XcExchangeInternals {
  constructor(/** XcExchangeInternals */ source) {
    /**
     * @type {XcExchangeState}
     */
    this.state = undefined;

    /**
     * @type {Object.<string, XcMarketInternals>}
     */
    this.markets = undefined;

    /**
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcExchangeState {
  constructor(/** XcExchangeState */ source) {
    /**
     * When was trading last opened
     * @type {string}
     */
    this.started_at = undefined;

    /**
     * When was trading last closed CLEANLY. If this fails to update, system might be locked
     * @type {string}
     */
    this.stopped_at = undefined;

    /**
     * While this is set, trading will be locked
     * @type {XcExchangeStateLock}
     */
    this.lock = undefined;

    Object.assign(this, source);
  }
}

export class XcExchangeStateLock {
  constructor(/** XcExchangeStateLock */ source) {
    /**
     * When was the lock triggered
     * @type {string}
     */
    this.locked_at = undefined;

    /**
     * When were admins last notified at
     * @type {string}
     */
    this.last_notified_at = undefined;

    /**
     * Text message describing reason for locking
     */
    this.reason = undefined;

    /**
     * Error object that caused the locking
     */
    this.error = undefined;

    /**
     * Instrument pair that caused the locking
     */
    this.pair = undefined;

    Object.assign(this, source);
  }
}

export class XcExternalRates {
  constructor(/** XcExternalRates */ source) {
    /**
     * @type {Object.<string, Number>}
     */
    this.BTC = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.RSD = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.USD = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.EUR = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.CHF = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.GBP = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.RUB = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.DKK = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.NOK = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.CZK = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.PLN = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.SEK = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.AUD = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.HUF = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.CAD = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.JPY = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.NZD = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.TRY = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.ZAR = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.ILS = undefined;

    Object.assign(this, source);
  }
}

export class XcFinalizeManagedBuyOrderRequestsPayload {
  constructor(/** XcFinalizeManagedBuyOrderRequestsPayload */ source) {
    /**
     * List of orders to finalize
     * @type {Array<string>}
     */
    this.order_ids = undefined;

    /**
     * If this is set, it means the order has failed
     * @type {string}
     */
    this.failure_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcFinalizeManagedSellOrderRequestsPayload {
  constructor(/** XcFinalizeManagedSellOrderRequestsPayload */ source) {
    /**
     * List of orders to finalize
     * @type {Array<string>}
     */
    this.order_ids = undefined;

    /**
     * If this is set, it means the order has failed
     * @type {string}
     */
    this.failure_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcForceSetPhoneVerifiedPayload {
  constructor(/** XcForceSetPhoneVerifiedPayload */ source) {
    /**
     * List of user id-s to change the setting for
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * What to set: verified or not verified
     * @type {boolean}
     */
    this.verified = undefined;

    Object.assign(this, source);
  }
}

export class XcGrowthTimelineCriteria {
  constructor(/** XcGrowthTimelineCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {GROWTH_TIMELINE_REPORT_ITEM_SORT_FIELDS|Array<GROWTH_TIMELINE_REPORT_ITEM_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * At what granularity to produce the report. One of INTERVALS
     * @type {GROWTH_TIMELINE_REPORT_GROUPING_INTERVALS}
     */
    this.interval = undefined;

    /**
     * In which instrument to present the report
     */
    this.instrument = undefined;

    Object.assign(this, source);
  }
}

export class XcGrowthTimelineReportItem {
  constructor(/** XcGrowthTimelineReportItem */ source) {
    /**
     * For which period is this data
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * Total volume during this period
     * @type {string}
     */
    this.volume = undefined;

    /**
     * Accumulated volume up until this period
     * @type {string}
     */
    this.accumulated_volume = undefined;

    /**
     * Revenue for the exchange during this period
     * @type {string}
     */
    this.revenue = undefined;

    /**
     * Accumulated revenue up to this point
     * @type {string}
     */
    this.accumulated_revenue = undefined;

    /**
     * New users that have registered during this time
     * @type {Number}
     */
    this.new_users = undefined;

    /**
     * Total number of users at the platform at the time
     * @type {Number}
     */
    this.total_users = undefined;

    /**
     * Number of users who participated in the generated volume
     * @type {Number}
     */
    this.participant_users = undefined;

    Object.assign(this, source);
  }
}

export class XcImageVariant {
  constructor(/** XcImageVariant */ source) {
    /**
     * Image width limit, in pixels
     * @type {Number}
     */
    this.width = undefined;

    /**
     * Image height limit, in pixels
     * @type {Number}
     */
    this.height = undefined;

    /**
     * Which method to use for fitting image within dimensions.
     * @type {IMAGE_FIT_METHODS}
     */
    this.fit_method = undefined;

    /**
     * If true, image will not be enlarger over its natural dimensions
     * @type {boolean}
     */
    this.no_enlargement = undefined;

    /**
     * Format to use when generating the result image
     * @type {IMAGE_FORMATS}
     */
    this.format = undefined;

    /**
     * If format is JPEG, use this quality. Range 1-100, default 80
     * @type {Number}
     */
    this.jpeg_quality = undefined;

    /**
     * Rotate image by the given precise number. If this is not provided, image will be auto-rotated based on EXIF.
     * @type {Number}
     */
    this.rotate = undefined;

    Object.assign(this, source);
  }
}

export class XcInstrumentConfig {
  constructor(/** XcInstrumentConfig */ source) {
    this.id = undefined;

    /**
     * User to which this config applies. If null, applies to all users.
     */
    this.user_id = undefined;

    /**
     * When was this configuration last updated.
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * Instrument to which this config applies. If null, applies to all instruments.
     */
    this.instrument = undefined;

    /**
     * If true, deposits are disabled
     * @type {boolean}
     */
    this.disable_deposits = undefined;

    /**
     * Explanation why deposits are disabled. Typed in by the admin.
     */
    this.disable_deposits_reason = undefined;

    /**
     * Absolute fee for a deposit. The amount in target instrument which will be reduced from the balance.
     * @type {string}
     */
    this.deposit_fee_abs = undefined;

    /**
     * Percent fee for a deposit. Percent of the total balance which will be taken. Applied after absolute fee.
     * @type {Number}
     */
    this.deposit_fee_pct = undefined;

    /**
     * If true, withdrawals are disabled
     * @type {boolean}
     */
    this.disable_withdrawals = undefined;

    /**
     * Explanation why withdrawals are disabled. Typed in by the admin.
     */
    this.disable_withdrawals_reason = undefined;

    /**
     * Absolute fee for a withdrawal. The amount in target instrument which will be reduced from the balance.
     * @type {string}
     */
    this.withdrawal_fee_abs = undefined;

    /**
     * Percent fee for a withdrawal. Percent of the total balance which will be taken. Applied after absolute fee.
     * @type {Number}
     */
    this.withdrawal_fee_pct = undefined;

    Object.assign(this, source);
  }
}

export class XcInstrumentConfigPayload {
  constructor(/** XcInstrumentConfigPayload */ source) {
    /**
     * List of instruments to target.
     * @type {Array<string>}
     */
    this.instruments = undefined;

    /**
     * List of user ids to target
     * @type {Array}
     */
    this.user_ids = undefined;

    /**
     * If true, deposits are disabled
     * @type {boolean}
     */
    this.disable_deposits = undefined;

    /**
     * Explanation why deposits are disabled. Typed in by the admin.
     */
    this.disable_deposits_reason = undefined;

    /**
     * Absolute fee for a deposit. The amount in target instrument which will be reduced from the balance.
     * @type {string}
     */
    this.deposit_fee_abs = undefined;

    /**
     * Percent fee for a deposit. Percent of the total balance which will be taken. Applied after absolute fee.
     * @type {Number}
     */
    this.deposit_fee_pct = undefined;

    /**
     * If true, withdrawals are disabled
     * @type {boolean}
     */
    this.disable_withdrawals = undefined;

    /**
     * Explanation why withdrawals are disabled. Typed in by the admin.
     */
    this.disable_withdrawals_reason = undefined;

    /**
     * Absolute fee for a withdrawal. The amount in target instrument which will be reduced from the balance.
     * @type {string}
     */
    this.withdrawal_fee_abs = undefined;

    /**
     * Percent fee for a withdrawal. Percent of the total balance which will be taken. Applied after absolute fee.
     * @type {Number}
     */
    this.withdrawal_fee_pct = undefined;

    Object.assign(this, source);
  }
}

export class XcInstrumentUserConfigInfosCriteria {
  constructor(/** XcInstrumentUserConfigInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {USER_SORT_FIELDS|Array<USER_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Only include users from given list of ID-s
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcKYCMicropaymentAuditResultPayload {
  constructor(/** XcKYCMicropaymentAuditResultPayload */ source) {
    /**
     * Did audit pass. Only set after it is performed.
     * @type {boolean}
     */
    this.result = undefined;

    /**
     * Notes explaining why audit failed, or if it passed, some optional extra info.
     */
    this.result_notes = undefined;

    Object.assign(this, source);
  }
}

export class XcKYCMicropaymentInfo {
  constructor(/** XcKYCMicropaymentInfo */ source) {
    /**
     * User ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * Reference number that was used to match payment with a particular user record.
     */
    this.reference_number = undefined;

    /**
     * Associated user id
     */
    this.user_id = undefined;

    /**
     * The (our) account which received the payment. A free-text containing bank name, account number and other info
     */
    this.recipient_account = undefined;

    /**
     * Transaction id provided by the bank
     */
    this.transaction_id = undefined;

    /**
     * Payer's full name. This must match their KYC data.
     */
    this.full_name = undefined;

    /**
     * IBAN from the payment (eg. RS3466 7890 4567 4389)
     */
    this.iban = undefined;

    /**
     * Swift number from the payment (eg. RA345BG)
     */
    this.swift = undefined;

    /**
     * Quantity received
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fiat instrument received
     */
    this.instrument = undefined;

    /**
     * Additional payment details, free text field
     */
    this.notes = undefined;

    /**
     * Date of the payment, according to bank
     * @type {string}
     */
    this.payment_date = undefined;

    /**
     * Reference to the current micropayment audit being performed.
     */
    this.audit_id = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * Set when we finished examining this micropayment. It has even been approved or rejected (maybe due to audit)
     * @type {string}
     */
    this.processed_at = undefined;

    /**
     * Whether this micropayment was approved as valid. Approving it can cause KYC level to be granted.
     * @type {boolean}
     */
    this.approved = undefined;

    /**
     * If micropayment is not approved, this can contain a rejection message, to be shown to the customer.
     */
    this.failure_message = undefined;

    /**
     * When we refund the customer, we will mark it here. TODO: What else do refunds need?
     * @type {string}
     */
    this.refunded_at = undefined;

    /**
     * Soft-delete flag
     * @type {string}
     */
    this.deleted_at = undefined;

    /**
     * When was audit requested. This is set automatically when admin enters a micropayment for the first time.
     * @type {string}
     */
    this.audit_requested_at = undefined;

    /**
     * When was the audit performed by the auditor
     * @type {string}
     */
    this.audit_performed_at = undefined;

    /**
     * Who performed the audit
     */
    this.audit_performed_by_user_id = undefined;

    /**
     * Did audit pass. Only set after it is performed.
     * @type {boolean}
     */
    this.audit_result = undefined;

    /**
     * Notes explaining why audit failed, or if it passed, some optional extra info.
     */
    this.audit_result_notes = undefined;

    /**
     * First name
     */
    this.customer_first_name = undefined;

    /**
     * Middle name
     */
    this.customer_middle_name = undefined;

    /**
     * Last name
     */
    this.customer_last_name = undefined;

    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.customer_nationality = undefined;

    /**
     * One of KYC_ADMIN_ACTIONS, this will cause this customer to be flagged for admin action.
     * @type {KYC_ADMIN_ACTIONS}
     */
    this.kyc_admin_action = undefined;

    /**
     * If this is set, customer is locked from submitting further KYC requests. They will be shown status
     * @type {string}
     */
    this.kyc_locked_at = undefined;

    /**
     * Message to be shown to customer in case KYC is locked
     */
    this.kyc_lock_message = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * The highest level of KYC verification user has passed
     * @type {Number}
     */
    this.kyc_level_granted = undefined;

    /**
     * List of attached documents for this micropayment
     * @type {Array<XcDocument>}
     */
    this.documents = undefined;

    /**
     * Is this current micropayment for its customer
     * @type {boolean}
     */
    this.is_current = undefined;

    Object.assign(this, source);
  }
}

export class XcKYCMicropaymentInfosCriteria {
  constructor(/** XcKYCMicropaymentInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {KYC_MICROPAYMENT_INFO_SORT_FIELDS|Array<KYC_MICROPAYMENT_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Used to filter a specific micropayments
     * @type {Array<string>}
     */
    this.micropayment_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcKYCMicropaymentPayload {
  constructor(/** XcKYCMicropaymentPayload */ source) {
    /**
     * Associated user id
     */
    this.user_id = undefined;

    /**
     * Reference number that was used to match payment with a particular user record.
     */
    this.reference_number = undefined;

    /**
     * The (our) account which received the payment. A free-text containing bank name, account number and other info
     */
    this.recipient_account = undefined;

    /**
     * Transaction id provided by the bank
     */
    this.transaction_id = undefined;

    /**
     * Payer's full name. This must match their KYC data.
     */
    this.full_name = undefined;

    /**
     * IBAN from the payment (eg. RS3466 7890 4567 4389)
     */
    this.iban = undefined;

    /**
     * Swift number from the payment (eg. RA345BG)
     */
    this.swift = undefined;

    /**
     * Quantity received
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fiat instrument received
     */
    this.instrument = undefined;

    /**
     * Additional payment details, free text field
     */
    this.notes = undefined;

    /**
     * Date of the payment, according to bank
     * @type {string}
     */
    this.payment_date = undefined;

    /**
     * List of uploaded documents to be attached to this micropayment
     */
    this.document_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcLinkManagedDepositsToBuyOrderPayload {
  constructor(/** XcLinkManagedDepositsToBuyOrderPayload */ source) {
    this.deposit_ids = undefined;

    this.buy_order_id = undefined;

    Object.assign(this, source);
  }
}

export class XcLoadWalletsPayload {
  constructor(/** XcLoadWalletsPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * @type {boolean}
     */
    this.reload = undefined;

    Object.assign(this, source);
  }
}

export class XcLogCollectorLevelPayload {
  constructor(/** XcLogCollectorLevelPayload */ source) {
    /**
     * @type {LOG_LEVELS}
     */
    this.level = undefined;

    Object.assign(this, source);
  }
}

export class XcLogCollectorState {
  constructor(/** XcLogCollectorState */ source) {
    /**
     * Key uniquely identifying this state
     */
    this.key = undefined;

    /**
     * List of tags that belong to this collector
     * @type {Array<string>}
     */
    this.tags = undefined;

    /**
     * Level override just for this collector. If not set, we will log at main logger's level
     * @type {LOG_LEVELS}
     */
    this.level = undefined;

    Object.assign(this, source);
  }
}

export class XcLoggerLevelPayload {
  constructor(/** XcLoggerLevelPayload */ source) {
    /**
     * @type {LOG_LEVELS}
     */
    this.level = undefined;

    Object.assign(this, source);
  }
}

export class XcLoggerState {
  constructor(/** XcLoggerState */ source) {
    /**
     * Master switch, enabling/disabling logging
     * @type {boolean}
     */
    this.enabled = undefined;

    /**
     * One of LOGGER_OUTPUTS. Determined what will logger spew out.
     * @type {string}
     */
    this.output = undefined;

    /**
     * Is tracing enabled
     * @type {boolean}
     */
    this.tracing_enabled = undefined;

    /**
     * Logger level
     * @type {LOG_LEVELS}
     */
    this.level = undefined;

    /**
     * Whether timestamps are generated
     * @type {boolean}
     */
    this.timestamps = undefined;

    /**
     * All registered collectors
     * @type {Array<XcLogCollectorState>}
     */
    this.collectors = undefined;

    Object.assign(this, source);
  }
}

export class XcLogInPayload {
  constructor(/** XcLogInPayload */ source) {
    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * Password for the user.
     * @type {string}
     */
    this.password = undefined;

    /**
     * If user has enabled 2FA, they must provide a token from google authenticator here.
     * @type {string}
     */
    this.tfa_token = undefined;

    /**
     * Token obtained by solving captcha challenge
     * @type {string}
     */
    this.captcha_token = undefined;

    Object.assign(this, source);
  }
}

export class XcMailchimpClientInfo {
  constructor(/** XcMailchimpClientInfo */ source) {
    /**
     * Is this client enabled
     * @type {boolean}
     */
    this.enabled = undefined;

    /**
     * Name of the account that is used by this client
     * @type {string}
     */
    this.account_name = undefined;

    Object.assign(this, source);
  }
}

export class XcMailData {
  constructor(/** XcMailData */ source) {
    this.from = undefined;

    /**
     * Recipient address
     */
    this.to = undefined;

    /**
     * Subject line
     */
    this.subject = undefined;

    /**
     * Email html body
     */
    this.html = undefined;

    /**
     * Email text body
     */
    this.text = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedBankTransfer {
  constructor(/** XcManagedBankTransfer */ source) {
    this.id = undefined;

    /**
     * Order for which this transfer was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * @type {string}
     */
    this.transaction_id = undefined;

    /**
     * ID of the source transaction (on the blockchain)
     * @type {string}
     */
    this.sending_bank_account_number = undefined;

    /**
     * @type {string}
     */
    this.sending_bank_account_info = undefined;

    /**
     * Transaction reference ID at partner exchange
     * @type {string}
     */
    this.receiving_bank_account_number = undefined;

    /**
     * Name of the partner exchange
     * @type {string}
     */
    this.receiving_bank_account_info = undefined;

    /**
     * @type {FIAT_INSTRUMENTS}
     */
    this.currency = undefined;

    /**
     * @type {string}
     */
    this.amount = undefined;

    /**
     * Fee applied at partner exchange
     * @type {string}
     */
    this.fee = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    /**
     * Date when transaction was created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when transaction was updated
     * @type {string}
     */
    this.updated_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedBankTransferPayload {
  constructor(/** XcManagedBankTransferPayload */ source) {
    /**
     * Order for which this transfer was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * @type {string}
     */
    this.transaction_id = undefined;

    /**
     * ID of the source transaction (on the blockchain)
     * @type {string}
     */
    this.sending_bank_account_number = undefined;

    /**
     * @type {string}
     */
    this.sending_bank_account_info = undefined;

    /**
     * Transaction reference ID at partner exchange
     * @type {string}
     */
    this.receiving_bank_account_number = undefined;

    /**
     * Name of the partner exchange
     * @type {string}
     */
    this.receiving_bank_account_info = undefined;

    /**
     * @type {FIAT_INSTRUMENTS}
     */
    this.currency = undefined;

    /**
     * @type {string}
     */
    this.amount = undefined;

    /**
     * Fee applied at partner exchange
     * @type {string}
     */
    this.fee = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedBankTransfersCriteria {
  constructor(/** XcManagedBankTransfersCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this order
     */
    this.order_id = undefined;

    /**
     * Filter for currency
     * @type {string}
     */
    this.currency = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedBuyOrder {
  constructor(/** XcManagedBuyOrder */ source) {
    /**
     * Human-readable order ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * User who owns the order
     */
    this.user_id = undefined;

    this.user_email = undefined;

    /**
     * Customer is going to pay for order in this currency. EUR, USD or CHF
     * @type {CURRENCIES}
     */
    this.currency = undefined;

    /**
     * Instrument quantity to be payed
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Percent fee that was applied
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Fixed fee at the moment
     * @type {string}
     */
    this.fee_fixed = undefined;

    /**
     * Total fee that was taken off from the quantity
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Percentage distribution among crypto instruments for buying
     * @type {XcManagedBuyOrderDistribution}
     */
    this.distribution = undefined;

    this.estimated_gains = undefined;

    /**
     * Reference number serving as a unique payment identifier
     * @type {string}
     */
    this.payment_reference_number = undefined;

    /**
     * If this is set, it means the order has failed
     * @type {string}
     */
    this.failure_reason = undefined;

    /**
     * If this is set, it means the order has been suspended
     * @type {string}
     */
    this.suspension_reason = undefined;

    /**
     * Date when the order was put on hold by the service operator
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * Date when the order request was taken by the service operator
     * @type {string}
     */
    this.taken_at = undefined;

    /**
     * Date when the order request was submitted by the user
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when the payment has been received
     * @type {string}
     */
    this.payment_received_at = undefined;

    /**
     * Date when the order was updated
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * This is set to the date when the order has been completed
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * Set if the order has been cancelled by the user
     * @type {string}
     */
    this.cancelled_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedBuyOrderCriteria {
  constructor(/** XcManagedBuyOrderCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this user
     */
    this.user_id = undefined;

    /**
     * Filter for currency
     */
    this.currency = undefined;

    Object.assign(this, source);
  }
}

/**
 * Percentage distribution among crypto instruments for buying
 */
export class XcManagedBuyOrderDistribution {
  constructor(/** XcManagedBuyOrderDistribution */ source) {
    /**
     * @type {Number}
     */
    this.BTC = undefined;

    /**
     * @type {Number}
     */
    this.ETH = undefined;

    /**
     * @type {Number}
     */
    this.SFX = undefined;

    /**
     * @type {Number}
     */
    this.SFT = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedConfig {
  constructor(/** XcManagedConfig */ source) {
    /**
     * Crypto instruments tradable for fiat.
     * @type {Array<MARKET_INSTRUMENTS>}
     */
    this.supported_crypto = undefined;

    /**
     * Fiat instruments for which we supported buying/selling crypto.
     * @type {Array<FIAT_INSTRUMENTS>}
     */
    this.supported_fiat = undefined;

    /**
     * Minimal payment amount we will allow per order.
     * @type {string}
     */
    this.buy_order_min_payment_quantity = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.buy_order_payment_instrument = undefined;

    /**
     * Buy order fee expressed in percentage
     * @type {Number}
     */
    this.buy_order_fee_pct = undefined;

    /**
     * Fixed buy order fee expressed in fiat
     * @type {string}
     */
    this.buy_order_fee_fixed = undefined;

    /**
     * Minimal payout amount we will allow per order.
     * @type {string}
     */
    this.sell_order_min_payout_quantity = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.sell_order_payout_instrument = undefined;

    /**
     * Sell order fee expressed in percentage
     * @type {Number}
     */
    this.sell_order_fee_pct = undefined;

    /**
     * Fixed sell order fee expressed in fiat
     * @type {string}
     */
    this.sell_order_fee_fixed = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedDeposit {
  constructor(/** XcManagedDeposit */ source) {
    this.id = undefined;

    this.transaction_id = undefined;

    this.buy_order_id = undefined;

    this.link_id = undefined;

    this.interchange_id = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * @type {string}
     */
    this.quantity = undefined;

    this.finalized_at = undefined;

    this.completed_at = undefined;

    this.failure_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedDepositOrderLink {
  constructor(/** XcManagedDepositOrderLink */ source) {
    this.id = undefined;

    this.buy_order_id = undefined;

    this.deposit_id = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedDepositsCriteria {
  constructor(/** XcManagedDepositsCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    this.buy_order_id = undefined;

    /**
     * @type {MANAGED_DEPOSITS_CRITERIA_ONLIES}
     */
    this.only = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTrade {
  constructor(/** XcManagedPartnerTrade */ source) {
    this.id = undefined;

    /**
     * Order for which this trade was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * Trade ID at the partner exchange
     * @type {string}
     */
    this.trade_id = undefined;

    /**
     * Buy instrument
     * @type {INSTRUMENTS}
     */
    this.buy_instrument = undefined;

    /**
     * Buy quantity
     * @type {string}
     */
    this.buy_quantity = undefined;

    /**
     * Sell instrument
     * @type {INSTRUMENTS}
     */
    this.sell_instrument = undefined;

    /**
     * Sell quantity
     * @type {string}
     */
    this.sell_quantity = undefined;

    /**
     * Applied fee
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Name of a partner exchange were trade was executed
     * @type {MANAGED_PARTNER_EXCHANGES}
     */
    this.partner_exchange = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    /**
     * Date when the trade was reported
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when the the trade was updated
     * @type {string}
     */
    this.updated_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTradePayload {
  constructor(/** XcManagedPartnerTradePayload */ source) {
    /**
     * Order for which this trade was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * Trade ID at the partner exchange
     * @type {string}
     */
    this.trade_id = undefined;

    /**
     * Buy instrument
     * @type {INSTRUMENTS}
     */
    this.buy_instrument = undefined;

    /**
     * Buy quantity
     * @type {string}
     */
    this.buy_quantity = undefined;

    /**
     * Sell instrument
     * @type {INSTRUMENTS}
     */
    this.sell_instrument = undefined;

    /**
     * Sell quantity
     * @type {string}
     */
    this.sell_quantity = undefined;

    /**
     * Applied fee
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Name of a partner exchange were trade was executed
     * @type {MANAGED_PARTNER_EXCHANGES}
     */
    this.partner_exchange = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTradesCriteria {
  constructor(/** XcManagedPartnerTradesCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this order
     */
    this.order_id = undefined;

    /**
     * Filter for buy instrument
     * @type {string}
     */
    this.buy_instrument = undefined;

    /**
     * Filter for sell instrument
     * @type {string}
     */
    this.sell_instrument = undefined;

    /**
     * Filter the results to just trades from given exchange
     * @type {string}
     */
    this.partner_exchange = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTransfer {
  constructor(/** XcManagedPartnerTransfer */ source) {
    this.id = undefined;

    /**
     * Order for which this transfer was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * @type {MANAGED_PARTNER_TRANSFER_TYPES}
     */
    this.type = undefined;

    /**
     * ID of the source transaction (on the blockchain)
     * @type {string}
     */
    this.transaction_id = undefined;

    /**
     * @type {string}
     */
    this.target_address = undefined;

    /**
     * Transaction reference ID at partner exchange
     * @type {string}
     */
    this.partner_reference_id = undefined;

    /**
     * Name of the partner exchange
     * @type {MANAGED_PARTNER_EXCHANGES}
     */
    this.partner_exchange = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fee applied at partner exchange
     * @type {string}
     */
    this.fee = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    /**
     * Date when transaction was created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when transaction was updated
     * @type {string}
     */
    this.updated_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTransferPayload {
  constructor(/** XcManagedPartnerTransferPayload */ source) {
    /**
     * Order for which this transfer was executed
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * @type {MANAGED_PARTNER_TRANSFER_TYPES}
     */
    this.type = undefined;

    /**
     * ID of the source transaction (on the blockchain)
     * @type {string}
     */
    this.transaction_id = undefined;

    /**
     * @type {string}
     */
    this.target_address = undefined;

    /**
     * Transaction reference ID at partner exchange
     * @type {string}
     */
    this.partner_reference_id = undefined;

    /**
     * Name of the partner exchange
     * @type {MANAGED_PARTNER_EXCHANGES}
     */
    this.partner_exchange = undefined;

    /**
     * @type {INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fee applied at partner exchange
     * @type {string}
     */
    this.fee = undefined;

    /**
     * @type {string}
     */
    this.executed_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedPartnerTransfersCriteria {
  constructor(/** XcManagedPartnerTransfersCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this order
     */
    this.order_id = undefined;

    /**
     * Show deposits or withdrawals or both
     * @type {MANAGED_PARTNER_TRANSFER_CRITERIA_VIEWS}
     */
    this.view = undefined;

    /**
     * Filter for instrument
     * @type {string}
     */
    this.instrument = undefined;

    /**
     * Filter the results to just transfers from given exchange
     * @type {string}
     */
    this.partner_exchange = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedSellOrder {
  constructor(/** XcManagedSellOrder */ source) {
    /**
     * Human-readable order ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * User who owns the order
     */
    this.user_id = undefined;

    this.user_email = undefined;

    /**
     * The amount of instrument user want's to withdraw
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Crypto instrument user wants to withdraw
     * @type {MARKET_INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * Currency customer wishes to receive (USD, EUR or CHF)
     * @type {INSTRUMENTS}
     */
    this.payout_currency = undefined;

    /**
     * @type {string}
     */
    this.bank_name = undefined;

    /**
     * The account number where we will send money.
     * @type {string}
     */
    this.bank_account_number = undefined;

    /**
     * @type {string}
     */
    this.swift = undefined;

    /**
     * @type {string}
     */
    this.recipient_name = undefined;

    /**
     * @type {string}
     */
    this.recipient_address = undefined;

    /**
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * @type {string}
     */
    this.fee_fixed = undefined;

    /**
     * Total fee that was taken off from the quantity
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * If this is set, it means the order has failed
     * @type {string}
     */
    this.failure_reason = undefined;

    /**
     * Date when the order request was taken by the service operator
     * @type {string}
     */
    this.taken_at = undefined;

    this.interchange_id = undefined;

    this.reverse_interchange_id = undefined;

    /**
     * If this is set, it means the order has been suspended
     * @type {string}
     */
    this.suspension_reason = undefined;

    /**
     * Date when the order was put on hold by the service operator
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * Date when the order request was submitted by the user
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when the order was updated
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * This is set to the date when the order has been completed
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * Set if the order has been cancelled by the user
     * @type {string}
     */
    this.cancelled_at = undefined;

    /**
     * Set if the order has been refunded
     * @type {string}
     */
    this.refunded_at = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedSellOrderInfosCriteria {
  constructor(/** XcManagedSellOrderInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {string|Array<string>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this user
     */
    this.user_id = undefined;

    this.order_id = undefined;

    this.instrument = undefined;

    this.completed = undefined;

    /**
     * Filter for payout currency
     */
    this.payout_currency = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedSellOrderWithdrawalPayload {
  constructor(/** XcManagedSellOrderWithdrawalPayload */ source) {
    /**
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * @type {boolean}
     */
    this.auto_fill_quantity = undefined;

    /**
     * @type {string}
     */
    this.target_address = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedWallet {
  constructor(/** XcManagedWallet */ source) {
    /**
     * @type {XcManagedWalletForInstrument}
     */
    this.BTC = undefined;

    /**
     * @type {XcManagedWalletForInstrument}
     */
    this.ETH = undefined;

    /**
     * @type {XcManagedWalletForInstrument}
     */
    this.SFT = undefined;

    /**
     * @type {XcManagedWalletForInstrument}
     */
    this.SFX = undefined;

    Object.assign(this, source);
  }
}

export class XcManagedWalletForInstrument {
  constructor(/** XcManagedWalletForInstrument */ source) {
    /**
     * @type {string}
     */
    this.instrument = undefined;

    this.total = undefined;

    this.reserved = undefined;

    /**
     * @type {string}
     */
    this.available = undefined;

    /**
     * @type {string}
     */
    this.address = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketDepth {
  constructor(/** XcMarketDepth */ source) {
    /**
     * Pair for which this depth is
     */
    this.pair = undefined;

    /**
     * How many digits have we grouped
     */
    this.scale = undefined;

    /**
     * How many levels did we slice at
     */
    this.limit = undefined;

    /**
     * @type {XcMarketDepthSlice}
     */
    this.buy = undefined;

    /**
     * @type {XcMarketDepthSlice}
     */
    this.sell = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketDepthLevel {
  constructor(/** XcMarketDepthLevel */ source) {
    /**
     * Price at this level
     * @type {Number}
     */
    this.price = undefined;

    /**
     * Quantity of orders at this level
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Total value of the level in base instrument, price * quantity
     * @type {string}
     */
    this.total = undefined;

    /**
     * Total number of orders at this level
     * @type {Number}
     */
    this.count = undefined;

    /**
     * Sum of all the quantites at this level and below
     * @type {string}
     */
    this.quantity_depth = undefined;

    /**
     * Sum of all the totals at this level and below
     * @type {string}
     */
    this.total_depth = undefined;

    this._total = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketDepthSlice {
  constructor(/** XcMarketDepthSlice */ source) {
    /**
     * How many digits have we grouped
     */
    this.scale = undefined;

    /**
     * @type {Array<XcMarketDepthLevel>}
     */
    this.levels = undefined;

    /**
     * How many levels did we slice at
     */
    this.limit = undefined;

    /**
     * Total supply of quote instrument in the list (not just the slice). Corresponds to quantity depth of the last level.
     * @type {string}
     */
    this.quote_supply = undefined;

    /**
     * Total supply of base instrument in the list (not just the slice). Corresponds to total depth of the last level.
     * @type {string}
     */
    this.base_supply = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketInternals {
  constructor(/** XcMarketInternals */ source) {
    this.pair = undefined;

    this.state = undefined;

    this.change_count = undefined;

    this.footprint = undefined;

    this.trades = undefined;

    /**
     * @type {XcMarketInternalsOrderList}
     */
    this.buy_list = undefined;

    /**
     * @type {XcMarketInternalsOrderList}
     */
    this.sell_list = undefined;

    this.jobs = undefined;

    this.snapshotting = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketInternalsOrderList {
  constructor(/** XcMarketInternalsOrderList */ source) {
    this.count = undefined;

    this.newest = undefined;

    this.oldest = undefined;

    this.top = undefined;

    this.bottom = undefined;

    Object.assign(this, source);
  }
}

export class XcMarketRates {
  constructor(/** XcMarketRates */ source) {
    /**
     * @type {Object.<string, Number>}
     */
    this.BTC = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.ETH = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.SFX = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.SFT = undefined;

    /**
     * @type {Object.<string, Number>}
     */
    this.RSD = undefined;

    Object.assign(this, source);
  }
}

export class XcMayanAppInternals {
  constructor(/** XcMayanAppInternals */ source) {
    /**
     * @type {XcMayanAppPackageInfo}
     */
    this.package = undefined;

    /**
     * @type {XcMayanAppState}
     */
    this.state = undefined;

    /**
     * @type {XcMayanSettings}
     */
    this.settings = undefined;

    Object.assign(this, source);
  }
}

export class XcMayanAppPackageInfo {
  constructor(/** XcMayanAppPackageInfo */ source) {
    this.name = undefined;

    this.description = undefined;

    this.version = undefined;

    Object.assign(this, source);
  }
}

export class XcMayanAppState {
  constructor(/** XcMayanAppState */ source) {
    /**
     * Identifier uniquely identifying this app execution
     * @type {string}
     */
    this.instance_id = undefined;

    /**
     * Process ID of this app execution
     */
    this.pid = undefined;

    /**
     * Current stage of the app execution, one of MAYAN_APP_STAGES
     * @type {APP_STAGES}
     */
    this.stage = undefined;

    /**
     * Date when app was created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when app was initialized
     * @type {string}
     */
    this.initialized_at = undefined;

    /**
     * Date when app was started
     * @type {string}
     */
    this.started_at = undefined;

    Object.assign(this, source);
  }
}

export class XcMayanSettings {
  constructor(/** XcMayanSettings */ source) {
    /**
     * Arbitrary id that should be unique per each Mayan app deployment. In general, should be set by the CI.
     * Used for reporting purposes mostly.
     */
    this.artifact_id = undefined;

    /**
     * Additional setting files to load once all others are loaded
     */
    this.additional_settings_files = undefined;

    /**
     * Current node environment. Defaults to production
     * @type {NODE_ENVS}
     */
    this.node_env = undefined;

    /**
     * Prefix to be applied to each env key before it is loaded. For example, if env_prefix is "MY_PREFIX_",
     * then "MY_PREFIX_PORT" will be treated as "PORT" and "PORT" will be ignored. This is used to
     * prevent env pollution and allow multiple env environments to live in one system.
     */
    this.env_prefix = undefined;

    /**
     * Values that were loaded directly from ENV.
     */
    this.loaded_env = undefined;

    /**
     * Shared settings which will be injected into each service.
     * Apps should extend settings and define their own type for this.
     */
    this.shared = undefined;

    Object.assign(this, source);
  }
}

export class XcOrder {
  constructor(/** XcOrder */ source) {
    /**
     * Order ID. Auto-generated string.
     * @type {string}
     */
    this.id = undefined;

    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Instrument pair for this order
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * Order type. One of ORDER_TYPES.
     * @type {ORDER_TYPES}
     */
    this.type = undefined;

    /**
     * Side of the order book. One of ORDER_SIDES, buy or sell.
     * @type {ORDER_SIDES}
     */
    this.side = undefined;

    /**
     * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
     * Used to position limit and stop orders.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * The amount of QUOTE instrument user wants to buy or sell.
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fee that will be applied for this order, whether owner is buyer and seller.
     * It will be subtracted from the amount user will have to give or that they will receive
     * (whichever is the base instrument for this order)
     * @type {Number}
     */
    this.fee = undefined;

    /**
     * How much of BASE or QUOTE currency can we spend on filling this order.
     * This is determined based on current state of user's wallet when order is submitted
     * We must keep this here because we will need it when replaying orders
     * @type {string}
     */
    this.budget = undefined;

    /**
     * One of ORDER_INSTRUCTIONS
     * @type {ORDER_INSTRUCTIONS}
     */
    this.instruction = undefined;

    /**
     * Snapshot that this order was written into
     * @type {Number}
     */
    this.order_book_snapshot = undefined;

    /**
     * If this is cancellation request, this is the id to cancel. A lot of other parameters don't matter for these.
     */
    this.cancel_order_id = undefined;

    /**
     * ID of the original (stop) order that has triggered the creation of this order
     */
    this.triggered_by_id = undefined;

    /**
     * Date when this order was processed by the system
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcOrderBookSnapshot {
  constructor(/** XcOrderBookSnapshot */ source) {
    /**
     * Instrument pair for this order book
     * @type {string}
     */
    this.pair = undefined;

    /**
     * Snapshot number at which the order book is at
     */
    this.snapshot = undefined;

    /**
     * List of buy orders
     * @type {Array<XcOrder>}
     */
    this.buy_list = undefined;

    /**
     * List or sell orders
     * @type {Array<XcOrder>}
     */
    this.sell_list = undefined;

    /**
     * Date when this snapshot was taken
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcOrderBookSnapshottingReport {
  constructor(/** XcOrderBookSnapshottingReport */ source) {
    this.pair = undefined;

    /**
     * @type {XcOrderBookSnapshot}
     */
    this.order_book = undefined;

    /**
     * @type {Array<XcTrade>}
     */
    this.trades = undefined;

    this.aborted = undefined;

    Object.assign(this, source);
  }
}

export class XcPairConfig {
  constructor(/** XcPairConfig */ source) {
    this.id = undefined;

    /**
     * User to which this config applies. If null, applies to all users.
     */
    this.user_id = undefined;

    /**
     * When was this configuration last updated.
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * Pair to which this config applies. If null, applies to all pairs.
     */
    this.pair = undefined;

    /**
     * If true, given pair is disabled.
     * @type {boolean}
     */
    this.disable_orders = undefined;

    /**
     * Explanation why orders are disabled. Typed in by the admin.
     */
    this.disable_orders_reason = undefined;

    /**
     * Fee for to be taken out of trades. Presented as multiplier to be applied to either side of trade.
     * @type {Number}
     */
    this.trade_fee = undefined;

    /**
     * Minimal quantity of order. Presented in BASE instrument. If buying, converted to base instrument using converter.
     * @type {string}
     */
    this.min_order = undefined;

    Object.assign(this, source);
  }
}

export class XcPairConfigPayload {
  constructor(/** XcPairConfigPayload */ source) {
    /**
     * List of pairs to target.
     * @type {Array<string>}
     */
    this.pairs = undefined;

    /**
     * List of user ids to target
     * @type {Array}
     */
    this.user_ids = undefined;

    /**
     * If true, given pair is disabled.
     * @type {boolean}
     */
    this.disable_orders = undefined;

    /**
     * Explanation why orders are disabled. Typed in by the admin.
     */
    this.disable_orders_reason = undefined;

    /**
     * Fee for to be taken out of trades. Presented as multiplier to be applied to either side of trade.
     * @type {Number}
     */
    this.trade_fee = undefined;

    /**
     * Minimal quantity of order. Presented in BASE instrument. If buying, converted to base instrument using converter.
     * @type {string}
     */
    this.min_order = undefined;

    Object.assign(this, source);
  }
}

export class XcPairUserConfigInfosCriteria {
  constructor(/** XcPairUserConfigInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {USER_SORT_FIELDS|Array<USER_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Only include users from given list of ID-s
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcPhoneVerificationInfo {
  constructor(/** XcPhoneVerificationInfo */ source) {
    this.id = undefined;

    /**
     * Purpose for which is this verification.
     * One of PHONE_VERIFICATION_PURPOSES
     * @type {PHONE_VERIFICATION_PURPOSES}
     */
    this.purpose = undefined;

    /**
     * User for whom this verification is
     */
    this.user_id = undefined;

    /**
     * Reference to geo location used to request verification
     */
    this.geo_location_id = undefined;

    /**
     * Reference to user agent used to request verification
     */
    this.user_agent = undefined;

    /**
     * Phone number being verified
     * @type {string}
     */
    this.phone = undefined;

    /**
     * Code that must be inputted to verify. Can be null in case of an admin override.
     */
    this.code = undefined;

    /**
     * When was this verification sent
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * When was the SMS sent. Verification can't be considered valid until this is set.
     * @type {string}
     */
    this.sms_sent_at = undefined;

    /**
     * Unique ID of the SMS message at twilio. Set after SMS is sent.
     */
    this.twilio_sid = undefined;

    /**
     * When good code is submitted, this will be set to current date. Request with this set is considered successful.
     * @type {string}
     */
    this.completed_at = undefined;

    this.termoinated_at = undefined;

    /**
     * If terminated, this is the reason. One of PHONE_VERIFICATION_TERMINATION_REASONS.
     * @type {PHONE_VERIFICATION_TERMINATION_REASONS}
     */
    this.termination_reason = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.u_email = undefined;

    /**
     * The highest level of KYC verification user has passed
     * @type {Number}
     */
    this.u_kyc_level_granted = undefined;

    /**
     * First name
     */
    this.c_first_name = undefined;

    /**
     * Middle name
     */
    this.c_middle_name = undefined;

    /**
     * Last name
     */
    this.c_last_name = undefined;

    /**
     * Date of birth
     * @type {string}
     */
    this.c_dob = undefined;

    /**
     * One of GENDERS-s
     * @type {GENDERS}
     */
    this.c_gender = undefined;

    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.c_nationality = undefined;

    /**
     * Mobile phone number
     * @type {string}
     */
    this.c_phone = undefined;

    /**
     * Whether phone has been verified or not
     * @type {boolean}
     */
    this.c_phone_verified = undefined;

    /**
     * If phone has been verified by the user (as opposed by admin fiat), this will be id of the verification record
     */
    this.c_phone_verification_id = undefined;

    this.gl_ip_address = undefined;

    /**
     * True if IP address was found in the GeoIP database
     * @type {boolean}
     */
    this.gl_has_geo_data = undefined;

    this.gl_country_id = undefined;

    this.gl_country_iso_code = undefined;

    this.gl_country_name = undefined;

    this.gl_state_id = undefined;

    this.gl_state_iso_code = undefined;

    this.gl_state_name = undefined;

    this.gl_city_id = undefined;

    this.gl_city_name = undefined;

    this.gl_latitude = undefined;

    this.gl_longitude = undefined;

    this.gl_accuracy_radius = undefined;

    this.gl_time_zone = undefined;

    this.ua_browser_family = undefined;

    this.ua_browser_major = undefined;

    this.ua_browser_minor = undefined;

    /**
     * @type {UA_BROWSER_TYPES}
     */
    this.ua_browser_type = undefined;

    this.ua_os_family = undefined;

    this.ua_os_major = undefined;

    this.ua_os_minor = undefined;

    this.ua_device_family = undefined;

    this.ua_device_brand = undefined;

    this.ua_device_model = undefined;

    /**
     * @type {UA_DEVICE_TYPES}
     */
    this.ua_device_type = undefined;

    Object.assign(this, source);
  }
}

export class XcPhoneVerificationInfosCriteria {
  constructor(/** XcPhoneVerificationInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {PHONE_VERIFICATION_INFO_SORT_FIELDS|Array<PHONE_VERIFICATION_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Return requests by a particular user
     */
    this.user_id = undefined;

    Object.assign(this, source);
  }
}

export class XcPriceHistory {
  constructor(/** XcPriceHistory */ source) {
    /**
     * ID of the database record
     */
    this.id = undefined;

    /**
     * Instrument pair for which this record is
     */
    this.pair = undefined;

    /**
     * Price at the open of the time period
     * @type {Number}
     */
    this.open = undefined;

    /**
     * Highest price during the time period
     * @type {Number}
     */
    this.high = undefined;

    /**
     * Lowest price during the time period
     * @type {Number}
     */
    this.low = undefined;

    /**
     * Price at the close of the time period
     * @type {Number}
     */
    this.close = undefined;

    /**
     * Number of trades during the period
     * @type {Number}
     */
    this.count = undefined;

    /**
     * Trade volume during the period,
     * sum of the BASE quantity traded during this period
     * @type {string}
     */
    this.volume = undefined;

    /**
     * Sum of the QUOTE quantity traded during this period
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Date referring to the start of the history period
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcPriceHistorySummary {
  constructor(/** XcPriceHistorySummary */ source) {
    /**
     * If set, prices will be converted to given instrument at current exchange rate
     */
    this.conversion_instrument = undefined;

    /**
     * From date
     * @type {string}
     */
    this.from_timestamp = undefined;

    /**
     * To date
     * @type {string}
     */
    this.to_timestamp = undefined;

    /**
     * @type {Object.<string, XcPriceHistory>}
     */
    this.by_pair = undefined;

    Object.assign(this, source);
  }
}

export class XcPrincipal {
  constructor(/** XcPrincipal */ source) {
    /**
     * @type {XcSession}
     */
    this.session = undefined;

    /**
     * @type {XcUser}
     */
    this.user = undefined;

    Object.assign(this, source);
  }
}

export class XcPublicCustomerInfo {
  constructor(/** XcPublicCustomerInfo */ source) {
    /**
     * NOTE: Customer and user share the same id
     */
    this.user_id = undefined;

    /**
     * First name
     */
    this.first_name = undefined;

    /**
     * Middle name
     */
    this.middle_name = undefined;

    /**
     * Last name
     */
    this.last_name = undefined;

    /**
     * Date of birth
     * @type {string}
     */
    this.dob = undefined;

    /**
     * One of GENDERS-s
     * @type {GENDERS}
     */
    this.gender = undefined;

    /**
     * Nationality, 2 letter country code
     * @type {COUNTRY_CODES}
     */
    this.nationality = undefined;

    /**
     * Street address, eg. Maple Street 123
     */
    this.residence_address = undefined;

    /**
     * Name of the city
     */
    this.residence_city = undefined;

    /**
     * Postal code
     */
    this.residence_postal_code = undefined;

    /**
     * Country that corresponds with the address field
     * @type {COUNTRY_CODES}
     */
    this.residence_country = undefined;

    /**
     * US state
     * @type {US_STATE_CODES}
     */
    this.residence_us_state = undefined;

    /**
     * Mobile phone number
     * @type {string}
     */
    this.phone = undefined;

    /**
     * Whether phone has been verified or not
     * @type {boolean}
     */
    this.phone_verified = undefined;

    /**
     * Source of funds: Customer's profession
     */
    this.sof_profession = undefined;

    /**
     * Source of funds: One of SOF_SALARY_RANGES
     * @type {SOF_SALARY_RANGES}
     */
    this.sof_salary_range = undefined;

    /**
     * Source of funds: Origin of assets deposited. One of SOF_ORIGINS
     * @type {SOF_ORIGINS}
     */
    this.sof_origin = undefined;

    /**
     * Source of funds: In case sof_origin is "other", this is custom input
     */
    this.sof_origin_other = undefined;

    /**
     * Source of funds: Expected volume and frequency of payments
     */
    this.sof_expected_volume = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * Reference number to be used in KYC micropayments. This will only be set once conditions are met.
     */
    this.reference_number_for_micropayments = undefined;

    this.kyc_locked = undefined;

    /**
     * Message to be shown to customer in case KYC is locked
     */
    this.kyc_lock_message = undefined;

    /**
     * Currently granted KYC level. Matches the data from User.
     */
    this.kyc_level_granted = undefined;

    /**
     * Currently granted VQF KYC level. Matches the data from User.
     */
    this.kyc_level_granted_vqf = undefined;

    /**
     * Which KYC type is currently being requested. One of KYC_TYPE
     * @type {KYC_TRACKS}
     */
    this.kyc_request_track = undefined;

    /**
     * If user has requested a KYC level, this will hold the number of currently active request.
     */
    this.kyc_level_requested = undefined;

    /**
     * Unified flag telling frontend that KYC request is being processed and that it should not
     * allow customer to submit anything until this is resolved.
     */
    this.kyc_processing_started_at = undefined;

    /**
     * If last KYC request has failed, this might contain a failure message. If message is not set,
     * UI is free not to display anything, and just let the customer resubmit.
     */
    this.kyc_failure_message = undefined;

    /**
     * If this is true, verification has been submitted to the provider and now we are waiting for resolution.
     * If this is true, UI should show a spinner.
     * @type {boolean}
     */
    this.kyc1_verification_waiting = undefined;

    /**
     * Method to use when validating KYC level 2
     * @type {KYC_LEVEL2_METHODS}
     */
    this.kyc2_method = undefined;

    /**
     * If micropayment was received, we will include information about it here
     * @type {XcPublicCustomerMicropaymentInfo}
     */
    this.kyc3_micropayment = undefined;

    Object.assign(this, source);
  }
}

/**
 * If micropayment was received, we will include information about it here
 */
export class XcPublicCustomerMicropaymentInfo {
  constructor(/** XcPublicCustomerMicropaymentInfo */ source) {
    /**
     * Transaction id provided by the bank
     */
    this.transaction_id = undefined;

    /**
     * Quantity received
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Fiat instrument received
     */
    this.instrument = undefined;

    /**
     * Whether this micropayment was approved as valid. Approving it can cause KYC level to be granted.
     * @type {boolean}
     */
    this.approved = undefined;

    /**
     * If micropayment is not approved, this can contain a rejection message, to be shown to the customer.
     */
    this.failure_message = undefined;

    /**
     * When we refund the customer, we will mark it here. TODO: What else do refunds need?
     * @type {string}
     */
    this.refunded_at = undefined;

    Object.assign(this, source);
  }
}

export class XcPublicTradeInfo {
  constructor(/** XcPublicTradeInfo */ source) {
    /**
     * Signature uniquely identifies each trade and is derived from the involved orders.
     * @type {string}
     */
    this.signature = undefined;

    /**
     * Instrument pair for this order
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * Side of the order book. One of ORDER_SIDES, buy or sell.
     * @type {ORDER_SIDES}
     */
    this.side = undefined;

    /**
     * Price, representing the amount of BASE instrument for 1 of QUOTE instrument.
     * Used to position limit and stop orders.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * The amount of QUOTE instrument user wants to buy or sell.
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Date when this order was processed by the system
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Side of the user's order. For example, if user's order was in order book,
     * this will be reverse from the "side" property.
     * @type {ORDER_SIDES}
     */
    this.user_side = undefined;

    /**
     * Order ID. Auto-generated string.
     * @type {string}
     */
    this.order_id = undefined;

    /**
     * Order type. One of ORDER_TYPES.
     * @type {ORDER_TYPES}
     */
    this.order_type = undefined;

    /**
     * Fee of user's order, expressed as percent (0-100)
     */
    this.fee_percent = undefined;

    /**
     * Actual fee quantity that was taken, expressed in base currency
     * @type {string}
     */
    this.fee_quantity = undefined;

    /**
     * Total quantity gained or lost in the trade, expressed in base instrument
     * @type {string}
     */
    this.total = undefined;

    /**
     * Index at which trade was matched.
     */
    this.sort_index = undefined;

    Object.assign(this, source);
  }
}

export class XcPublicTrollboxMessage {
  constructor(/** XcPublicTrollboxMessage */ source) {
    /**
     * @type {string}
     */
    this.id = undefined;

    /**
     * @type {TROLLBOX_ROOMS}
     */
    this.room = undefined;

    /**
     * @type {string}
     */
    this.nickname = undefined;

    /**
     * @type {string}
     */
    this.message = undefined;

    /**
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * Type of a user. One of ROLES
     * @type {ROLES}
     */
    this.role = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForAdminActionInfo {
  constructor(/** XcQueryResultForAdminActionInfo */ source) {
    /**
     * @type {Array<XcAdminActionInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForBotInfo {
  constructor(/** XcQueryResultForBotInfo */ source) {
    /**
     * @type {Array<XcBotInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForCardPayment {
  constructor(/** XcQueryResultForCardPayment */ source) {
    /**
     * @type {Array<XcCardPayment>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForCensoredManagedBuyOrder {
  constructor(/** XcQueryResultForCensoredManagedBuyOrder */ source) {
    /**
     * @type {Array<XcCensoredManagedBuyOrder>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForCustomerInfo {
  constructor(/** XcQueryResultForCustomerInfo */ source) {
    /**
     * @type {Array<XcCustomerInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForDeposit {
  constructor(/** XcQueryResultForDeposit */ source) {
    /**
     * @type {Array<XcDeposit>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForDocument {
  constructor(/** XcQueryResultForDocument */ source) {
    /**
     * @type {Array<XcDocument>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForGrowthTimelineReportItem {
  constructor(/** XcQueryResultForGrowthTimelineReportItem */ source) {
    /**
     * @type {Array<XcGrowthTimelineReportItem>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForKYCMicropaymentInfo {
  constructor(/** XcQueryResultForKYCMicropaymentInfo */ source) {
    /**
     * @type {Array<XcKYCMicropaymentInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForManagedBankTransfer {
  constructor(/** XcQueryResultForManagedBankTransfer */ source) {
    /**
     * @type {Array<XcManagedBankTransfer>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForManagedDeposit {
  constructor(/** XcQueryResultForManagedDeposit */ source) {
    /**
     * @type {Array<XcManagedDeposit>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForManagedPartnerTrade {
  constructor(/** XcQueryResultForManagedPartnerTrade */ source) {
    /**
     * @type {Array<XcManagedPartnerTrade>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForManagedPartnerTransfer {
  constructor(/** XcQueryResultForManagedPartnerTransfer */ source) {
    /**
     * @type {Array<XcManagedPartnerTransfer>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForManagedSellOrder {
  constructor(/** XcQueryResultForManagedSellOrder */ source) {
    /**
     * @type {Array<XcManagedSellOrder>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForPhoneVerificationInfo {
  constructor(/** XcQueryResultForPhoneVerificationInfo */ source) {
    /**
     * @type {Array<XcPhoneVerificationInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForSecurityEventInfo {
  constructor(/** XcQueryResultForSecurityEventInfo */ source) {
    /**
     * @type {Array<XcSecurityEventInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForSessionInfo {
  constructor(/** XcQueryResultForSessionInfo */ source) {
    /**
     * @type {Array<XcSessionInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForTradeInfo {
  constructor(/** XcQueryResultForTradeInfo */ source) {
    /**
     * @type {Array<XcTradeInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForTransfer {
  constructor(/** XcQueryResultForTransfer */ source) {
    /**
     * @type {Array<XcTransfer>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForTransferTimelineReportItem {
  constructor(/** XcQueryResultForTransferTimelineReportItem */ source) {
    /**
     * @type {Array<XcTransferTimelineReportItem>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForTrollboxMessageInfo {
  constructor(/** XcQueryResultForTrollboxMessageInfo */ source) {
    /**
     * @type {Array<XcTrollboxMessageInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForUser {
  constructor(/** XcQueryResultForUser */ source) {
    /**
     * @type {Array<XcUser>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForWalletInfo {
  constructor(/** XcQueryResultForWalletInfo */ source) {
    /**
     * @type {Array<XcWalletInfo>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcQueryResultForWithdrawal {
  constructor(/** XcQueryResultForWithdrawal */ source) {
    /**
     * @type {Array<XcWithdrawal>}
     */
    this.items = undefined;

    /**
     * @type {Number}
     */
    this.page = undefined;

    /**
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * @type {Number}
     */
    this.total_records = undefined;

    /**
     * @type {Number}
     */
    this.total_pages = undefined;

    Object.assign(this, source);
  }
}

export class XcRegistrationPolicy {
  constructor(/** XcRegistrationPolicy */ source) {
    /**
     * Disable registration of new customers
     * @type {boolean}
     */
    this.disabled = undefined;

    /**
     * Reason for disabled registration
     */
    this.disabled_message = undefined;

    /**
     * List of IP-s from which registration is allowed. Useful during testing.
     * Set to empty to disable the feature. Set to empty list to disallow registrations altogether.
     */
    this.cidr_whitelist = undefined;

    /**
     * @type {Array<COUNTRY_CODES>}
     */
    this.country_blacklist = undefined;

    /**
     * Require captcha to be solved during registration
     * @type {boolean}
     */
    this.require_captcha = undefined;

    Object.assign(this, source);
  }
}

export class XcReportDownload {
  constructor(/** XcReportDownload */ source) {
    /**
     * ID the client can call to download the generated file
     */
    this.id = undefined;

    /**
     * Name of the generated file.
     */
    this.file_name = undefined;

    Object.assign(this, source);
  }
}

export class XcResetToKYCLevelPayload {
  constructor(/** XcResetToKYCLevelPayload */ source) {
    /**
     * List of user id-s
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * KYC level to set users to
     * @type {Number}
     */
    this.target_level = undefined;

    /**
     * Which track to reset (base or VQF)
     * Note that resetting base will automatically reset all other tracks as well.
     * @type {KYC_TRACKS}
     */
    this.target_track = undefined;

    Object.assign(this, source);
  }
}

export class XcResolveKYCRequestsPayload {
  constructor(/** XcResolveKYCRequestsPayload */ source) {
    /**
     * List of user id-s to resolve
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Whether to grant requests.
     * @type {boolean}
     */
    this.grant = undefined;

    /**
     * Optional message, to be set as failure reason. If not provided, system might auto-generate
     * message based on the state of each customer's request.
     */
    this.message = undefined;

    Object.assign(this, source);
  }
}

export class XcSecurityEventInfo {
  constructor(/** XcSecurityEventInfo */ source) {
    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * ID is a hash based on all other parameters
     * @type {string}
     */
    this.id = undefined;

    /**
     * String describing event type. Usually maps to one of EVENTS.
     * @type {SECURITY_EVENT_TYPES}
     */
    this.event_type = undefined;

    /**
     * User id to whom this event is related
     */
    this.user_id = undefined;

    /**
     * Security token that was used when generating this event (eg. access token or bot key)
     */
    this.security_token = undefined;

    /**
     * Free-form additional data that was attached
     */
    this.additional_data = undefined;

    /**
     * When was the event created
     * @type {string}
     */
    this.timestamp = undefined;

    this.ip_address = undefined;

    /**
     * True if IP address was found in the GeoIP database
     * @type {boolean}
     */
    this.has_geo_data = undefined;

    this.country_iso_code = undefined;

    this.country_name = undefined;

    this.city_name = undefined;

    this.latitude = undefined;

    this.longitude = undefined;

    this.accuracy_radius = undefined;

    this.time_zone = undefined;

    /**
     * Actual user agent string that was used to derive this info
     */
    this.user_agent = undefined;

    this.browser_family = undefined;

    this.browser_major = undefined;

    this.browser_minor = undefined;

    /**
     * @type {UA_BROWSER_TYPES}
     */
    this.browser_type = undefined;

    this.os_family = undefined;

    this.os_major = undefined;

    this.os_minor = undefined;

    this.device_family = undefined;

    this.device_brand = undefined;

    this.device_model = undefined;

    /**
     * @type {UA_DEVICE_TYPES}
     */
    this.device_type = undefined;

    Object.assign(this, source);
  }
}

export class XcSecurityEventInfosCriteria {
  constructor(/** XcSecurityEventInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {SECURITY_EVENT_INFO_SORT_FIELDS|Array<SECURITY_EVENT_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter the results to just this user
     */
    this.user_id = undefined;

    /**
     * Comma-separated list of event types to filter down to
     * @type {Array<SECURITY_EVENT_TYPES>}
     */
    this.event_types = undefined;

    Object.assign(this, source);
  }
}

export class XcSendMailReport {
  constructor(/** XcSendMailReport */ source) {
    this.message_id = undefined;

    this.envelope = undefined;

    this.accepted = undefined;

    this.pending = undefined;

    this.response = undefined;

    Object.assign(this, source);
  }
}

export class XcSession {
  constructor(/** XcSession */ source) {
    /**
     * Access token that uniquely identifies session
     */
    this.access_token = undefined;

    /**
     * User who owns this session
     */
    this.user_id = undefined;

    /**
     * Id of stored geo location info
     */
    this.geo_location_id = undefined;

    /**
     * What kind of geo location pinning we will use for this session.
     * One of GEO_LOCATION_PINNINGS.
     * @type {GEO_LOCATION_PINNINGS}
     */
    this.geo_location_pinning = undefined;

    /**
     * User agent that has created this session.
     */
    this.user_agent = undefined;

    /**
     * When was this session created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when this token will expire.
     * @type {string}
     */
    this.expires_at = undefined;

    /**
     * Number of milliseconds session can be "idle" (without HTTP requests), before user is force-logged out.
     * Client should observe this value and send keep alive requests if user has requested so.
     * @type {Number}
     */
    this.idle_timeout = undefined;

    /**
     * The last time the user with this session was seen at their client (eg. moving a mouse or performing some activity).
     * This will have ramifications regarding idle_timeout.
     * @type {string}
     */
    this.seen_at = undefined;

    /**
     * If session is terminated, this will be set to date when
     * @type {string}
     */
    this.terminated_at = undefined;

    /**
     * One of SESSION_TERMINATION_REASONS
     * @type {SESSION_TERMINATION_REASONS}
     */
    this.termination_reason = undefined;

    /**
     * Arbitrary additional details that are relevant for termination
     */
    this.termination_details = undefined;

    Object.assign(this, source);
  }
}

export class XcSessionInfo {
  constructor(/** XcSessionInfo */ source) {
    /**
     * Access token that uniquely identifies session
     */
    this.access_token = undefined;

    /**
     * User who owns this session
     */
    this.user_id = undefined;

    this.user_email = undefined;

    /**
     * When was this session created
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * Date when this token will expire.
     * @type {string}
     */
    this.expires_at = undefined;

    /**
     * Number of milliseconds session can be "idle" (without HTTP requests), before user is force-logged out.
     * Client should observe this value and send keep alive requests if user has requested so.
     * @type {Number}
     */
    this.idle_timeout = undefined;

    /**
     * The last time the user with this session was seen at their client (eg. moving a mouse or performing some activity).
     * This will have ramifications regarding idle_timeout.
     * @type {string}
     */
    this.seen_at = undefined;

    /**
     * If session is terminated, this will be set to date when
     * @type {string}
     */
    this.terminated_at = undefined;

    /**
     * One of SESSION_TERMINATION_REASONS
     * @type {SESSION_TERMINATION_REASONS}
     */
    this.termination_reason = undefined;

    this.ip_address = undefined;

    /**
     * True if IP address was found in the GeoIP database
     * @type {boolean}
     */
    this.has_geo_data = undefined;

    this.country_iso_code = undefined;

    this.country_name = undefined;

    this.city_name = undefined;

    this.latitude = undefined;

    this.longitude = undefined;

    this.accuracy_radius = undefined;

    this.time_zone = undefined;

    /**
     * Actual user agent string that was used to derive this info
     */
    this.user_agent = undefined;

    this.browser_family = undefined;

    this.browser_major = undefined;

    this.browser_minor = undefined;

    /**
     * @type {UA_BROWSER_TYPES}
     */
    this.browser_type = undefined;

    this.os_family = undefined;

    this.os_major = undefined;

    this.os_minor = undefined;

    this.device_family = undefined;

    this.device_brand = undefined;

    this.device_model = undefined;

    /**
     * @type {UA_DEVICE_TYPES}
     */
    this.device_type = undefined;

    Object.assign(this, source);
  }
}

export class XcSessionInfosCriteria {
  constructor(/** XcSessionInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {SESSION_INFO_SORT_FIELDS|Array<SESSION_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Show terminated or active or both
     * @type {SESSION_INFO_CRITERIA_VIEWS}
     */
    this.view = undefined;

    /**
     * Filter the results to just this user
     */
    this.user_id = undefined;

    Object.assign(this, source);
  }
}

export class XcSessionSeenPayload {
  constructor(/** XcSessionSeenPayload */ source) {
    /**
     * Access token that was seen
     */
    this.access_token = undefined;

    /**
     * How long ago was session last seen, in milliseconds
     * @type {Number}
     */
    this.seen_ago = undefined;

    Object.assign(this, source);
  }
}

export class XcSessionsPayload {
  constructor(/** XcSessionsPayload */ source) {
    /**
     * @type {Array}
     */
    this.access_tokens = undefined;

    Object.assign(this, source);
  }
}

export class XcSetCustomersManagedPayload {
  constructor(/** XcSetCustomersManagedPayload */ source) {
    /**
     * List of users to alter
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Action to take
     * @type {boolean}
     */
    this.manage = undefined;

    Object.assign(this, source);
  }
}

export class XcSetEmailVerifiedPayload {
  constructor(/** XcSetEmailVerifiedPayload */ source) {
    /**
     * List of user id-s to change the setting for
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * What to set: verified or not verified
     * @type {boolean}
     */
    this.verified = undefined;

    Object.assign(this, source);
  }
}

export class XcSetKYCLockedPayload {
  constructor(/** XcSetKYCLockedPayload */ source) {
    /**
     * List of user id-s to lock or unlock
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Whether to lock or unlock given customer KYC request abilities.
     * @type {boolean}
     */
    this.locked = undefined;

    /**
     * Optional message, to be set as the lock message. If there are any pending KYC requests, it will be set for them as well.
     */
    this.message = undefined;

    Object.assign(this, source);
  }
}

export class XcSetOrdersSuspendedPayload {
  constructor(/** XcSetOrdersSuspendedPayload */ source) {
    /**
     * List of orders to alter
     * @type {Array<string>}
     */
    this.order_ids = undefined;

    /**
     * Action to take
     * @type {boolean}
     */
    this.suspend = undefined;

    /**
     * @type {string}
     */
    this.suspension_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcSetUsersSuspendedPayload {
  constructor(/** XcSetUsersSuspendedPayload */ source) {
    /**
     * List of users to alter
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * Action to take
     * @type {boolean}
     */
    this.suspend = undefined;

    Object.assign(this, source);
  }
}

export class XcSetWithdrawalApprovedPayload {
  constructor(/** XcSetWithdrawalApprovedPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.withdrawal_ids = undefined;

    /**
     * @type {boolean}
     */
    this.approved = undefined;

    Object.assign(this, source);
  }
}

export class XcSignatureSecretCriteria {
  constructor(/** XcSignatureSecretCriteria */ source) {
    /**
     * @type {string}
     */
    this.tfa_token = undefined;

    Object.assign(this, source);
  }
}

export class XcTrade {
  constructor(/** XcTrade */ source) {
    /**
     * Database-generated order ID
     * @type {Number}
     */
    this.id = undefined;

    /**
     * Index at which this trades was matched in a list of matched trades
     */
    this.matchee_list_index = undefined;

    /**
     * Signature uniquely identifies each trade and is derived from the involved orders.
     * @type {string}
     */
    this.signature = undefined;

    /**
     * One of INSTRUMENT_PAIR-s
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * Base instrument
     * @type {string}
     */
    this.base = undefined;

    /**
     * Quote instrument
     * @type {string}
     */
    this.quote = undefined;

    /**
     * Order ID of the matcher
     */
    this.matcher_id = undefined;

    /**
     * Order type of the matcher
     */
    this.matcher_type = undefined;

    /**
     * User who owns the matcher order
     */
    this.matcher_user_id = undefined;

    /**
     * Fee applied to the matcher
     */
    this.matcher_fee = undefined;

    /**
     * Original quantity of the matcher before the trade was made.
     * @type {string}
     */
    this.matcher_quantity = undefined;

    /**
     * Matchee order id
     */
    this.matchee_id = undefined;

    /**
     * User who owns the matchee order
     */
    this.matchee_user_id = undefined;

    /**
     * Fee applied to matchee
     */
    this.matchee_fee = undefined;

    /**
     * Original quantity of the matchee before the trade was made.
     * @type {string}
     */
    this.matchee_quantity = undefined;

    /**
     * One of ORDER_SIDES, based on the matcher's SIDE
     */
    this.side = undefined;

    /**
     * Price at which the trade was made. To get the BASE instrument quantity, multiply price with quantity.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * Quantity of the traded QUOTE instrument
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * How much BASE quantity was gained from this trade
     * @type {string}
     */
    this.revenue = undefined;

    /**
     * Order book snapshot with which this order was saved to DB
     */
    this.order_book_snapshot = undefined;

    /**
     * Time when the trade was made. Taken from the matcher's timestamp
     * @type {string}
     */
    this.timestamp = undefined;

    Object.assign(this, source);
  }
}

export class XcTradeInfo {
  constructor(/** XcTradeInfo */ source) {
    /**
     * Database-generated order ID
     * @type {Number}
     */
    this.id = undefined;

    /**
     * Signature uniquely identifies each trade and is derived from the involved orders.
     * @type {string}
     */
    this.signature = undefined;

    /**
     * One of INSTRUMENT_PAIR-s
     * @type {PAIRS}
     */
    this.pair = undefined;

    /**
     * Order ID of the matcher
     */
    this.matcher_id = undefined;

    /**
     * Order type of the matcher
     */
    this.matcher_type = undefined;

    /**
     * User who owns the matcher order
     */
    this.matcher_user_id = undefined;

    this.matcher_user_email = undefined;

    /**
     * Fee applied to the matcher
     */
    this.matcher_fee = undefined;

    /**
     * Original quantity of the matcher before the trade was made.
     * @type {string}
     */
    this.matcher_quantity = undefined;

    /**
     * Matchee order id
     */
    this.matchee_id = undefined;

    /**
     * User who owns the matchee order
     */
    this.matchee_user_id = undefined;

    this.matchee_user_email = undefined;

    /**
     * Fee applied to matchee
     */
    this.matchee_fee = undefined;

    /**
     * Original quantity of the matchee before the trade was made.
     * @type {string}
     */
    this.matchee_quantity = undefined;

    /**
     * One of ORDER_SIDES, based on the matcher's SIDE
     */
    this.side = undefined;

    /**
     * Price at which the trade was made. To get the BASE instrument quantity, multiply price with quantity.
     * @type {Number}
     */
    this.price = undefined;

    /**
     * Quantity of the traded QUOTE instrument
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Time when the trade was made. Taken from the matcher's timestamp
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * How much BASE quantity was gained from this trade
     * @type {string}
     */
    this.revenue = undefined;

    Object.assign(this, source);
  }
}

export class XcTradeInfosCriteria {
  constructor(/** XcTradeInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {TRADE_INFO_SORT_FIELDS|Array<TRADE_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter by the instrument pair
     * @type {Array<PAIRS>}
     */
    this.pair = undefined;

    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Filter by matcher order type
     * @type {string}
     */
    this.type = undefined;

    Object.assign(this, source);
  }
}

export class XcTradesStatistics {
  constructor(/** XcTradesStatistics */ source) {
    this.pair = undefined;

    this.count = undefined;

    this.sum_quantity = undefined;

    this.sum_volume = undefined;

    this.sum_revenue = undefined;

    this.avg_matcher_fee = undefined;

    this.avg_matchee_fee = undefined;

    this.avg_price = undefined;

    Object.assign(this, source);
  }
}

export class XcTransfer {
  constructor(/** XcTransfer */ source) {
    this.id = undefined;

    /**
     * User who owns the transfer
     */
    this.user_id = undefined;

    /**
     * Instrument of this transfer
     */
    this.instrument = undefined;

    /**
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * What is the source of this transfer. One of TRANSFER_SOURCES. Informational mostly.
     */
    this.transfer_source = undefined;

    /**
     * Deposit that has caused the transfer, if feasible.
     */
    this.deposit_id = undefined;

    /**
     * Withdrawal that has caused the transfer to happen
     */
    this.withdrawal_id = undefined;

    /**
     * Timestamp of the transfer
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * In case we ever roll back some transfers (disaster recovery), they will be marked through this flag.
     * System will treat these transfers as if they don't exist.
     * @type {string}
     */
    this.rolled_back_at = undefined;

    Object.assign(this, source);
  }
}

export class XcTransfersCriteria {
  constructor(/** XcTransfersCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {TRANSFER_SORT_FIELDS|Array<TRANSFER_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter by user id
     */
    this.user_id = undefined;

    /**
     * Limit transfers to given instruments
     * @type {Array<INSTRUMENTS>}
     */
    this.instruments = undefined;

    /**
     * Only return user transfers after given ID
     */
    this.after_id = undefined;

    /**
     * Filter by transfer source
     */
    this.transfer_source = undefined;

    Object.assign(this, source);
  }
}

export class XcTransferTimelineCriteria {
  constructor(/** XcTransferTimelineCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {TRANSFER_TIMELINE_REPORT_ITEM_SORT_FIELDS|Array<TRANSFER_TIMELINE_REPORT_ITEM_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * At what granularity to produce the report. One of INTERVALS
     * @type {TRANSFER_TIMELINE_REPORT_GROUPING_INTERVALS}
     */
    this.interval = undefined;

    /**
     * Which instruments to include in report. Defaults to all.
     * @type {Array<INSTRUMENTS>}
     */
    this.instruments = undefined;

    /**
     * In which instrument to present the report
     * @type {TRANSFER_TIMELINE_REPORT_CONVERSION_INSTRUMENTS}
     */
    this.conversion_instrument = undefined;

    /**
     * Include only users with given nationalities
     * @type {Array<string>}
     */
    this.nationalities = undefined;

    /**
     * Which minimal KYC level to require when counting users
     */
    this.kyc_level = undefined;

    Object.assign(this, source);
  }
}

export class XcTransferTimelineReportItem {
  constructor(/** XcTransferTimelineReportItem */ source) {
    /**
     * For which period is this data
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * Total number of deposits
     */
    this.deposit_count = undefined;

    /**
     * How many different users are doing deposits
     */
    this.deposit_users = undefined;

    /**
     * Deposited volume
     * @type {string}
     */
    this.deposit_volume = undefined;

    /**
     * Revenue generated from deposits through fees
     * @type {string}
     */
    this.deposit_revenue = undefined;

    /**
     * Total number of withdrawals
     */
    this.withdrawal_count = undefined;

    /**
     * How many different users are doing withdrawals
     */
    this.withdrawal_users = undefined;

    /**
     * Deposited volume
     * @type {string}
     */
    this.withdrawal_volume = undefined;

    /**
     * Revenue generated from withdrawals through fees
     * @type {string}
     */
    this.withdrawal_revenue = undefined;

    /**
     * Difference between deposited and withdrawn
     * @type {string}
     */
    this.volume = undefined;

    /**
     * Revenue generated from deposits and withdrawals
     * @type {string}
     */
    this.revenue = undefined;

    /**
     * Summed up volume
     * @type {string}
     */
    this.accumulated_volume = undefined;

    /**
     * Summed up revenue
     * @type {string}
     */
    this.accumulated_revenue = undefined;

    /**
     * New users that have registered during this time
     * @type {Number}
     */
    this.new_users = undefined;

    /**
     * Total number of users at the platform at the time
     * @type {Number}
     */
    this.total_users = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxBanPayload {
  constructor(/** XcTrollboxBanPayload */ source) {
    /**
     * List of user ids to target
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    /**
     * @type {string}
     */
    this.expires_at = undefined;

    /**
     * @type {string}
     */
    this.reason = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxFlagMessagesPayload {
  constructor(/** XcTrollboxFlagMessagesPayload */ source) {
    /**
     * Flag messages from given list of ID-s
     */
    this.message_ids = undefined;

    /**
     * @type {TROLLBOX_MESSAGE_FLAGS}
     */
    this.flag = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxMessage {
  constructor(/** XcTrollboxMessage */ source) {
    /**
     * @type {string}
     */
    this.id = undefined;

    /**
     * @type {TROLLBOX_ROOMS}
     */
    this.room = undefined;

    this.user_id = undefined;

    /**
     * @type {string}
     */
    this.nickname = undefined;

    /**
     * @type {string}
     */
    this.message = undefined;

    /**
     * @type {string}
     */
    this.original_message = undefined;

    /**
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * Message flag, one of TROLLBOX_MESSAGE_FLAGS
     * @type {TROLLBOX_MESSAGE_FLAGS}
     */
    this.flag = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxMessageInfo {
  constructor(/** XcTrollboxMessageInfo */ source) {
    /**
     * @type {string}
     */
    this.id = undefined;

    /**
     * @type {TROLLBOX_ROOMS}
     */
    this.room = undefined;

    this.user_id = undefined;

    /**
     * Nickname to be used in chat
     * @type {string}
     */
    this.nickname = undefined;

    /**
     * @type {string}
     */
    this.message = undefined;

    /**
     * @type {string}
     */
    this.original_message = undefined;

    /**
     * @type {string}
     */
    this.timestamp = undefined;

    /**
     * Message flag, one of TROLLBOX_MESSAGE_FLAGS
     * @type {TROLLBOX_MESSAGE_FLAGS}
     */
    this.flag = undefined;

    /**
     * Type of a user. One of ROLES
     * @type {ROLES}
     */
    this.role = undefined;

    /**
     * If this is set to a date, user was banned at given date
     * @type {string}
     */
    this.trollbox_banned_at = undefined;

    /**
     * If this is set to a date, user ban expires at given date
     * @type {string}
     */
    this.trollbox_ban_expires_at = undefined;

    /**
     * The reason why user is banned
     * @type {string}
     */
    this.trollbox_ban_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxMessageInfosCriteria {
  constructor(/** XcTrollboxMessageInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {TROLLBOX_MESSAGE_INFO_SORT_FIELDS|Array<TROLLBOX_MESSAGE_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Only include users from given list of ID-s
     */
    this.user_ids = undefined;

    /**
     * Only history for the given room
     * @type {TROLLBOX_ROOMS}
     */
    this.room = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxMessagePayload {
  constructor(/** XcTrollboxMessagePayload */ source) {
    /**
     * Message content
     * @type {string}
     */
    this.message = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxMessagesPayload {
  constructor(/** XcTrollboxMessagesPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.message_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcTrollboxProfanityConfig {
  constructor(/** XcTrollboxProfanityConfig */ source) {
    /**
     * Whole words that will be censored in the english chat
     * @type {Array<string>}
     */
    this.en_whole_words = undefined;

    /**
     * Partial words that will be censored in the english chat
     * @type {Array<string>}
     */
    this.en_partial_words = undefined;

    /**
     * Whole words that will be censored in the serbian chat
     * @type {Array<string>}
     */
    this.sr_whole_words = undefined;

    /**
     * Partial words that will be censored in the serbian chat
     * @type {Array<string>}
     */
    this.sr_partial_words = undefined;

    Object.assign(this, source);
  }
}

export class XcUser {
  constructor(/** XcUser */ source) {
    /**
     * User ID
     * @type {string}
     */
    this.id = undefined;

    /**
     * Type of a user. One of ROLES
     * @type {ROLES}
     */
    this.role = undefined;

    /**
     * List of admin permissions this user is given
     * @type {Array<ADMIN_PERMISSIONS>}
     */
    this.admin_permissions = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * Nickname to be used in chat
     * @type {string}
     */
    this.nickname = undefined;

    /**
     * User's chosen language. Defaults to EN.
     * @type {LANGUAGES}
     */
    this.language = undefined;

    /**
     * Keep user's session alive as long as their tab is active, even if they go away from the keyboard.
     * @type {boolean}
     */
    this.enable_keep_alive = undefined;

    /**
     * User's order confirmation settings
     * @type {boolean}
     */
    this.enable_order_confirmations = undefined;

    /**
     * Enable withdrawal confirmations
     * @type {boolean}
     */
    this.enable_withdrawal_confirmations = undefined;

    /**
     * User's order notification settings
     * @type {boolean}
     */
    this.enable_order_notifications = undefined;

    /**
     * User's geo location pinning settings
     * @type {GEO_LOCATION_PINNINGS}
     */
    this.geo_location_pinning = undefined;

    /**
     * If this is set to a date, user has requested email verification at given date
     * @type {string}
     */
    this.email_verification_requested_at = undefined;

    /**
     * If this is set to a date, user's email is verified
     * @type {string}
     */
    this.email_verified_at = undefined;

    /**
     * If this is set, it means user has enabled 2FA
     * @type {string}
     */
    this.tfa_enabled_at = undefined;

    /**
     * The highest level of KYC verification user has passed
     * @type {Number}
     */
    this.kyc_level_granted = undefined;

    /**
     * The highest level of KYC verification user has passed for VQF (use with Managed)
     * @type {Number}
     */
    this.kyc_level_granted_vqf = undefined;

    /**
     * This customer has had KYC0 back when that was a thing.
     * We no longer have any special functionality based on this,
     * but we are keeping this flag for now, for historical purposes.
     */
    this.kyc0_grandfathered_in = undefined;

    /**
     * If this is set to a date, user was banned at given date
     * @type {string}
     */
    this.trollbox_banned_at = undefined;

    /**
     * If this is set to a date, user ban expires at given date
     * @type {string}
     */
    this.trollbox_ban_expires_at = undefined;

    /**
     * The reason why user is banned
     * @type {string}
     */
    this.trollbox_ban_reason = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * If this is set, user won't be able to log into the exchange or do anything else
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * NOTE: This is not worked out at all
     * @type {string}
     */
    this.deleted_at = undefined;

    /**
     * Previous email address, before it was changed to current one
     * @type {string}
     */
    this.old_email = undefined;

    /**
     * @type {string}
     */
    this.nickname_updated_at = undefined;

    /**
     * Nickname that the user requested to be changed to
     */
    this.requested_nickname = undefined;

    /**
     * Message to send/display to the user in case a nickname has been declined
     */
    this.nickname_decline_reason = undefined;

    Object.assign(this, source);
  }
}

export class XcUserConfigInfo {
  constructor(/** XcUserConfigInfo */ source) {
    this.id = undefined;

    this.email = undefined;

    /**
     * @type {XcConfigInfo}
     */
    this.global = undefined;

    /**
     * @type {Object.<string, XcConfigInfo>}
     */
    this.individual = undefined;

    Object.assign(this, source);
  }
}

export class XcUserEmailVerifiedEventData {
  constructor(/** XcUserEmailVerifiedEventData */ source) {
    /**
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * @type {string}
     */
    this.email_verified_at = undefined;

    Object.assign(this, source);
  }
}

export class XcUserPayload {
  constructor(/** XcUserPayload */ source) {
    /**
     * Type of a user. One of ROLES
     * @type {ROLES}
     */
    this.role = undefined;

    /**
     * List of admin permissions this user is given
     * @type {Array<ADMIN_PERMISSIONS>}
     */
    this.admin_permissions = undefined;

    /**
     * Email address, uniquely identifies user in the system
     * @type {string}
     */
    this.email = undefined;

    /**
     * Nickname to be used in chat
     * @type {string}
     */
    this.nickname = undefined;

    /**
     * User's chosen language. Defaults to EN.
     * @type {LANGUAGES}
     */
    this.language = undefined;

    /**
     * Keep user's session alive as long as their tab is active, even if they go away from the keyboard.
     * @type {boolean}
     */
    this.enable_keep_alive = undefined;

    /**
     * User's order confirmation settings
     * @type {boolean}
     */
    this.enable_order_confirmations = undefined;

    /**
     * Enable withdrawal confirmations
     * @type {boolean}
     */
    this.enable_withdrawal_confirmations = undefined;

    /**
     * User's order notification settings
     * @type {boolean}
     */
    this.enable_order_notifications = undefined;

    /**
     * User's geo location pinning settings
     * @type {GEO_LOCATION_PINNINGS}
     */
    this.geo_location_pinning = undefined;

    /**
     * If this is set to a date, user has requested email verification at given date
     * @type {string}
     */
    this.email_verification_requested_at = undefined;

    /**
     * If this is set to a date, user's email is verified
     * @type {string}
     */
    this.email_verified_at = undefined;

    /**
     * If this is set, it means user has enabled 2FA
     * @type {string}
     */
    this.tfa_enabled_at = undefined;

    /**
     * The highest level of KYC verification user has passed
     * @type {Number}
     */
    this.kyc_level_granted = undefined;

    /**
     * The highest level of KYC verification user has passed for VQF (use with Managed)
     * @type {Number}
     */
    this.kyc_level_granted_vqf = undefined;

    /**
     * This customer has had KYC0 back when that was a thing.
     * We no longer have any special functionality based on this,
     * but we are keeping this flag for now, for historical purposes.
     */
    this.kyc0_grandfathered_in = undefined;

    /**
     * If this is set to a date, user was banned at given date
     * @type {string}
     */
    this.trollbox_banned_at = undefined;

    /**
     * If this is set to a date, user ban expires at given date
     * @type {string}
     */
    this.trollbox_ban_expires_at = undefined;

    /**
     * The reason why user is banned
     * @type {string}
     */
    this.trollbox_ban_reason = undefined;

    /**
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * @type {string}
     */
    this.updated_at = undefined;

    /**
     * If this is set, user won't be able to log into the exchange or do anything else
     * @type {string}
     */
    this.suspended_at = undefined;

    /**
     * NOTE: This is not worked out at all
     * @type {string}
     */
    this.deleted_at = undefined;

    /**
     * Previous email address, before it was changed to current one
     * @type {string}
     */
    this.old_email = undefined;

    /**
     * @type {string}
     */
    this.nickname_updated_at = undefined;

    /**
     * Nickname that the user requested to be changed to
     */
    this.requested_nickname = undefined;

    /**
     * Message to send/display to the user in case a nickname has been declined
     */
    this.nickname_decline_reason = undefined;

    /**
     * If provided, this password will be set for the user
     * @type {string}
     */
    this.password = undefined;

    Object.assign(this, source);
  }
}

export class XcUsersCriteria {
  constructor(/** XcUsersCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {USER_SORT_FIELDS|Array<USER_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Only include users from given list of ID-s
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcUsersPayload {
  constructor(/** XcUsersPayload */ source) {
    /**
     * @type {Array<string>}
     */
    this.user_ids = undefined;

    Object.assign(this, source);
  }
}

export class XcWallet {
  constructor(/** XcWallet */ source) {
    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Lookup of instruments and wallet amounts
     * @type {Object.<string, XcWalletInstrument>}
     */
    this.instruments = undefined;

    Object.assign(this, source);
  }
}

export class XcWalletInfo {
  constructor(/** XcWalletInfo */ source) {
    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    this.user_email = undefined;

    /**
     * Whether wallet is loaded in memory
     * @type {boolean}
     */
    this.loaded = undefined;

    /**
     * @type {Object.<string, XcWalletInfoInstrument>}
     */
    this.instruments = undefined;

    /**
     * When was the wallet last snapshotted
     */
    this.snapshotted_at = undefined;

    this.last_transfer_id = undefined;

    this.last_trade_id = undefined;

    /**
     * Date when the wallet was last audited
     * @type {string}
     */
    this.audited_at = undefined;

    /**
     * Whether the last audit was clear
     * @type {boolean}
     */
    this.audit_clear = undefined;

    Object.assign(this, source);
  }
}

export class XcWalletInfoInstrument {
  constructor(/** XcWalletInfoInstrument */ source) {
    /**
     * Instrument
     * @type {MARKET_INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * Total quantity in the wallet for this instrument
     * @type {string}
     */
    this.total = undefined;

    /**
     * The amount tied in orders, withdrawals and other actions
     * @type {string}
     */
    this.reserved = undefined;

    this.available = undefined;

    /**
     * What quantity was expected, based on loading everything from scratch
     * @type {string}
     */
    this.expected = undefined;

    /**
     * Difference between expected and actual
     * @type {string}
     */
    this.discrepancy = undefined;

    /**
     * Whether the numbers match or not
     * @type {boolean}
     */
    this.clear = undefined;

    Object.assign(this, source);
  }
}

export class XcWalletInfosCriteria {
  constructor(/** XcWalletInfosCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {WALLET_INFO_SORT_FIELDS|Array<WALLET_INFO_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    Object.assign(this, source);
  }
}

export class XcWalletInstrument {
  constructor(/** XcWalletInstrument */ source) {
    /**
     * User ID
     * @type {string}
     */
    this.user_id = undefined;

    /**
     * Instrument
     * @type {MARKET_INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * Total quantity in the wallet for this instrument
     * @type {string}
     */
    this.total = undefined;

    /**
     * The amount tied in orders, withdrawals and other actions
     * @type {string}
     */
    this.reserved = undefined;

    Object.assign(this, source);
  }
}

export class XcWithdrawal {
  constructor(/** XcWithdrawal */ source) {
    this.id = undefined;

    /**
     * ID of the transaction that was made (on the blockchain, for example)
     */
    this.transaction_id = undefined;

    /**
     * User who withdrew assets.
     */
    this.user_id = undefined;

    /**
     * Address where the instrument was sent
     */
    this.target_address = undefined;

    /**
     * The withdrawal instrument.
     * @type {MARKET_INSTRUMENTS}
     */
    this.instrument = undefined;

    /**
     * The withdrawal quantity, as a decimal
     * @type {string}
     */
    this.quantity = undefined;

    /**
     * Absolute fee that was applied (based on user's instrument config)
     * @type {string}
     */
    this.fee_abs = undefined;

    /**
     * Percent fee that was applied (based on user's instrument config)
     * @type {Number}
     */
    this.fee_pct = undefined;

    /**
     * Total fee that was taken off from the quantity. fee_abs + (quantity - fee_abs) * (fee_pct / 100)
     * This can be used as basis for calculating revenue.
     * @type {string}
     */
    this.fee_total = undefined;

    /**
     * Date when the withdrawal request was submitted by user
     * @type {string}
     */
    this.created_at = undefined;

    /**
     * This is set to the date when withdrawal has been confirmed. This happens when user
     * clicks "confirm" link in their email, or automatically if that option is disabled.
     * @type {string}
     */
    this.confirmed_at = undefined;

    /**
     * Date when the withdrawal was approved for payment,
     * whether automatically or through admin action.
     * @type {string}
     */
    this.approved_at = undefined;

    /**
     * Date when the withdrawal request was taken by the withdrawal processor
     * @type {string}
     */
    this.taken_at = undefined;

    /**
     * Date when the withdrawal was completed, one way or the other
     * @type {string}
     */
    this.completed_at = undefined;

    /**
     * If this is set, it means the transaction is paused until customer provides more info
     * @type {string}
     */
    this.more_info = undefined;

    /**
     * Bankers should set this if withdrawal gets stuck for whatever reason.
     * Admins can examine this message (along with metadata) and manually resolve withdrawal.
     * @type {string}
     */
    this.stuck_reason = undefined;

    /**
     * Instrument-specific withdrawal metadata. For example, blockchain block index.
     * This is maintained by the banker infrastructure.
     */
    this.metadata = undefined;

    /**
     * Failure code. If this is set, it means the Withdrawal has failed.
     */
    this.failure_code = undefined;

    /**
     * Set if withdrawal has been cancelled by the user
     * @type {string}
     */
    this.cancelled_at = undefined;

    /**
     * Transfer that was created as a consequence of this withdrawal. Only set if successful.
     */
    this.transfer_id = undefined;

    Object.assign(this, source);
  }
}

export class XcWithdrawalsCriteria {
  constructor(/** XcWithdrawalsCriteria */ source) {
    /**
     * Filter to sort on. Can be comma-separated list of fields, to sort on multiple fields.
     * @type {WITHDRAWAL_SORT_FIELDS|Array<WITHDRAWAL_SORT_FIELDS>}
     */
    this.sort_field = undefined;

    /**
     * One of SORT_DIRECTION-s. Defaults to "asc". All sort_fields will be sorted in the same direction.
     * @type {SORT_DIRECTIONS|Array<SORT_DIRECTIONS>}
     */
    this.sort_direction = undefined;

    /**
     * General search filter. Business logic dictates where will this be applied
     */
    this.filter = undefined;

    /**
     * Page to take. This is enabled in conjunction with page_size
     * @type {Number}
     */
    this.page = undefined;

    /**
     * If this is set, the results will be limited to given page size
     * @type {Number}
     */
    this.page_size = undefined;

    /**
     * Set to true to include soft-deleted records
     * @type {boolean}
     */
    this.include_deleted = undefined;

    /**
     * Filter only this user id
     */
    this.user_id = undefined;

    /**
     * Only for these instruments
     * @type {Array<INSTRUMENTS>}
     */
    this.instruments = undefined;

    Object.assign(this, source);
  }
}

// *********************************************************************************************************************

const METHOD_NAMES = [
  'deleteAuthSessions',
  'deleteAuthSessionsSpec',
  'deleteBot',
  'deleteBots',
  'deleteBotSpec',
  'deleteBotsSpec',
  'deleteBrokerBankTransfers',
  'deleteBrokerBankTransfersSpec',
  'deleteBrokerDepositsLink',
  'deleteBrokerDepositsLinkSpec',
  'deleteBrokerPartnerTrades',
  'deleteBrokerPartnerTradesSpec',
  'deleteBrokerPartnerTransfers',
  'deleteBrokerPartnerTransfersSpec',
  'deleteKycMicropayment',
  'deleteKycMicropaymentSpec',
  'deleteUser',
  'deleteUsers',
  'deleteUserSpec',
  'deleteUsersSpec',
  'deleteWalletsSnapshots',
  'deleteWalletsSnapshotsSpec',
  'getAppAdminActions',
  'getAppAdminActionsCsv',
  'getAppAdminActionsCsvSpec',
  'getAppAdminActionsSpec',
  'getAppInternals',
  'getAppInternalsSpec',
  'getAppLogger',
  'getAppLoggerSpec',
  'getAuthLoginShouldAskCaptcha',
  'getAuthLoginShouldAskCaptchaSpec',
  'getAuthPrincipal',
  'getAuthPrincipalSpec',
  'getAuthSessions',
  'getAuthSessionsCsv',
  'getAuthSessionsCsvSpec',
  'getAuthSessionsSpec',
  'getBot',
  'getBots',
  'getBotsCsv',
  'getBotsCsvSpec',
  'getBotSignatureSecret',
  'getBotSignatureSecretSpec',
  'getBotSpec',
  'getBotsSpec',
  'getBrokerBankTransfer',
  'getBrokerBankTransfers',
  'getBrokerBankTransfersCsv',
  'getBrokerBankTransfersCsvSpec',
  'getBrokerBankTransferSpec',
  'getBrokerBankTransfersSpec',
  'getBrokerConfig',
  'getBrokerConfigSpec',
  'getBrokerDeposits',
  'getBrokerDepositsCsv',
  'getBrokerDepositsCsvSpec',
  'getBrokerDepositsSpec',
  'getBrokerDownloadReport',
  'getBrokerDownloadReportSpec',
  'getBrokerOrdersBuy',
  'getBrokerOrdersBuyCsv',
  'getBrokerOrdersBuyCsvSpec',
  'getBrokerOrdersBuyOrderId',
  'getBrokerOrdersBuyOrderIdSpec',
  'getBrokerOrdersBuySpec',
  'getBrokerOrdersSell',
  'getBrokerOrdersSellCsv',
  'getBrokerOrdersSellCsvSpec',
  'getBrokerOrdersSellOrderId',
  'getBrokerOrdersSellOrderIdSpec',
  'getBrokerOrdersSellSpec',
  'getBrokerPartnerTrade',
  'getBrokerPartnerTrades',
  'getBrokerPartnerTradesCsv',
  'getBrokerPartnerTradesCsvSpec',
  'getBrokerPartnerTradeSpec',
  'getBrokerPartnerTradesSpec',
  'getBrokerPartnerTransfer',
  'getBrokerPartnerTransfers',
  'getBrokerPartnerTransfersCsv',
  'getBrokerPartnerTransfersCsvSpec',
  'getBrokerPartnerTransferSpec',
  'getBrokerPartnerTransfersSpec',
  'getBrokerPrepareReport',
  'getBrokerPrepareReportSpec',
  'getBrokerPriceEstimatorConfig',
  'getBrokerPriceEstimatorConfigSpec',
  'getBrokerUsers',
  'getBrokerUsersSpec',
  'getBrokerWallet',
  'getBrokerWalletSpec',
  'getBrokerWithdrawal',
  'getBrokerWithdrawalSpec',
  'getCardPayments',
  'getCardPaymentsCsv',
  'getCardPaymentsCsvSpec',
  'getCardPaymentsSpec',
  'getConfigInstrumentsGlobal',
  'getConfigInstrumentsGlobalSpec',
  'getConfigInstrumentsMarket',
  'getConfigInstrumentsMarketSpec',
  'getConfigInstrumentsUsers',
  'getConfigInstrumentsUsersSpec',
  'getConfigPairsGlobal',
  'getConfigPairsGlobalSpec',
  'getConfigPairsMarket',
  'getConfigPairsMarketSpec',
  'getConfigPairsUsers',
  'getConfigPairsUsersSpec',
  'getCsvDownload',
  'getCsvDownloadSpec',
  'getCustomersCustomer',
  'getCustomersCustomerSpec',
  'getCustomersInfo',
  'getCustomersInfos',
  'getCustomersInfosCsv',
  'getCustomersInfosCsvSpec',
  'getCustomersInfoSpec',
  'getCustomersInfosSpec',
  'getCustomersRegistrationPolicy',
  'getCustomersRegistrationPolicySpec',
  'getDeposits',
  'getDepositsCsv',
  'getDepositsCsvSpec',
  'getDepositsSpec',
  'getDocumentDownload',
  'getDocumentDownloadSpec',
  'getDocuments',
  'getDocumentsCsv',
  'getDocumentsCsvSpec',
  'getDocumentsDownload',
  'getDocumentsDownloadSpec',
  'getDocumentsSpec',
  'getExchangeAudit',
  'getExchangeAuditSpec',
  'getExchangeInternals',
  'getExchangeInternalsSpec',
  'getExchangeState',
  'getExchangeStateSpec',
  'getExchangeStatistics',
  'getExchangeStatisticsSpec',
  'getExchangeTrades',
  'getExchangeTradesCsv',
  'getExchangeTradesCsvSpec',
  'getExchangeTradesSpec',
  'getImageData',
  'getImageDataSpec',
  'getKycMicropaymentsDocumentDownload',
  'getKycMicropaymentsDocumentDownloadSpec',
  'getKycMicropaymentsInfo',
  'getKycMicropaymentsInfos',
  'getKycMicropaymentsInfosCsv',
  'getKycMicropaymentsInfosCsvSpec',
  'getKycMicropaymentsInfoSpec',
  'getKycMicropaymentsInfosSpec',
  'getKycMicropaymentsReferenceNumbersFromUser',
  'getKycMicropaymentsReferenceNumbersFromUserSpec',
  'getKycMicropaymentsReferenceNumbersToUser',
  'getKycMicropaymentsReferenceNumbersToUserSpec',
  'getMailchimpClientInfo',
  'getMailchimpClientInfoSpec',
  'getMailchimpMissingCustomers',
  'getMailchimpMissingCustomersSpec',
  'getPhoneVerificationInfos',
  'getPhoneVerificationInfosCsv',
  'getPhoneVerificationInfosCsvSpec',
  'getPhoneVerificationInfosSpec',
  'getReportsGrowthTimeline',
  'getReportsGrowthTimelineCsv',
  'getReportsGrowthTimelineCsvSpec',
  'getReportsGrowthTimelineSpec',
  'getReportsTransferTimeline',
  'getReportsTransferTimelineCsv',
  'getReportsTransferTimelineCsvSpec',
  'getReportsTransferTimelineSpec',
  'getSchemas',
  'getSchemasSpec',
  'getSecurityEventsInfos',
  'getSecurityEventsInfosCsv',
  'getSecurityEventsInfosCsvSpec',
  'getSecurityEventsInfosSpec',
  'getTransfers',
  'getTransfersCsv',
  'getTransfersCsvSpec',
  'getTransfersSpec',
  'getTrollbox',
  'getTrollboxProfanityConfig',
  'getTrollboxProfanityConfigSpec',
  'getTrollboxSpec',
  'getUser',
  'getUsers',
  'getUsersCsv',
  'getUsersCsvSpec',
  'getUserSpec',
  'getUsersSpec',
  'getWallets',
  'getWalletsSpec',
  'getWithdrawals',
  'getWithdrawalsCsv',
  'getWithdrawalsCsvSpec',
  'getWithdrawalsSpec',
  'postAppSendMail',
  'postAppSendMailSpec',
  'postAuthLogin',
  'postAuthLoginSpec',
  'postAuthRenew',
  'postAuthRenewSpec',
  'postBot',
  'postBotSpec',
  'postBrokerBankTransfer',
  'postBrokerBankTransferSpec',
  'postBrokerDepositsLink',
  'postBrokerDepositsLinkSpec',
  'postBrokerPartnerTrade',
  'postBrokerPartnerTradeSpec',
  'postBrokerPartnerTransfer',
  'postBrokerPartnerTransferSpec',
  'postBrokerWithdrawal',
  'postBrokerWithdrawalSpec',
  'postCustomersRegistrationPolicy',
  'postCustomersRegistrationPolicySpec',
  'postDocument',
  'postDocumentSpec',
  'postExchangeSnapshot',
  'postExchangeSnapshotSpec',
  'postExchangeUnlock',
  'postExchangeUnlockSpec',
  'postKycMicropayment',
  'postKycMicropaymentSpec',
  'postTrollbox',
  'postTrollboxSpec',
  'postUser',
  'postUserSpec',
  'put2faDisable',
  'put2faDisableSpec',
  'putAppLoggerCollectorLevel',
  'putAppLoggerCollectorLevelSpec',
  'putAppLoggerLevel',
  'putAppLoggerLevelSpec',
  'putAppMaintenanceUpdateMicropaymentReferenceNumber',
  'putAppMaintenanceUpdateMicropaymentReferenceNumberSpec',
  'putAuthLogout',
  'putAuthLogoutSpec',
  'putAuthNotifyIdleTimeout',
  'putAuthNotifyIdleTimeoutSpec',
  'putAuthNotifySeen',
  'putAuthNotifySeenSpec',
  'putAuthSessionsForceLogOut',
  'putAuthSessionsForceLogOutSpec',
  'putBot',
  'putBotSpec',
  'putBrokerAccount',
  'putBrokerAccountSpec',
  'putBrokerBankTransfer',
  'putBrokerBankTransferSpec',
  'putBrokerConfig',
  'putBrokerConfigSpec',
  'putBrokerGenerateReport',
  'putBrokerGenerateReportSpec',
  'putBrokerOrdersBuyCancel',
  'putBrokerOrdersBuyCancelSpec',
  'putBrokerOrdersBuyFinalize',
  'putBrokerOrdersBuyFinalizeSpec',
  'putBrokerOrdersBuyPay',
  'putBrokerOrdersBuyPaySpec',
  'putBrokerOrdersBuySuspend',
  'putBrokerOrdersBuySuspendSpec',
  'putBrokerOrdersBuyTake',
  'putBrokerOrdersBuyTakeSpec',
  'putBrokerOrdersSellFinalize',
  'putBrokerOrdersSellFinalizeSpec',
  'putBrokerOrdersSellRefund',
  'putBrokerOrdersSellRefundSpec',
  'putBrokerOrdersSellSuspend',
  'putBrokerOrdersSellSuspendSpec',
  'putBrokerOrdersSellTake',
  'putBrokerOrdersSellTakeSpec',
  'putBrokerPartnerTrade',
  'putBrokerPartnerTradeSpec',
  'putBrokerPartnerTransfer',
  'putBrokerPartnerTransferSpec',
  'putBrokerPriceEstimatorConfig',
  'putBrokerPriceEstimatorConfigSpec',
  'putConfigInstrument',
  'putConfigInstrumentSpec',
  'putConfigPair',
  'putConfigPairSpec',
  'putCustomersCustomer',
  'putCustomersCustomerSpec',
  'putCustomersUsCustomersFillState',
  'putCustomersUsCustomersFillStateSpec',
  'putEmailSetVerified',
  'putEmailSetVerifiedSpec',
  'putExchangeAudit',
  'putExchangeAuditSpec',
  'putExchangeCancelKyc0Order',
  'putExchangeCancelKyc0OrderSpec',
  'putExchangeCancelRsdOrder',
  'putExchangeCancelRsdOrderSpec',
  'putExchangeCancelUnverifiedPhoneOrder',
  'putExchangeCancelUnverifiedPhoneOrderSpec',
  'putKycCancelBlacklistedUsCustomerOrder',
  'putKycCancelBlacklistedUsCustomerOrderSpec',
  'putKycLevel1OverrideVerification',
  'putKycLevel1OverrideVerificationSpec',
  'putKycLevel1ReleaseScreening',
  'putKycLevel1ReleaseScreeningSpec',
  'putKycLevel3AuditResult',
  'putKycLevel3AuditResultSpec',
  'putKycLevel3LaunchAudit',
  'putKycLevel3LaunchAuditSpec',
  'putKycMicropayment',
  'putKycMicropaymentSpec',
  'putKycResetToLevel',
  'putKycResetToLevelSpec',
  'putKycResetUsCustomer',
  'putKycResetUsCustomerSpec',
  'putKycResolve',
  'putKycResolveSpec',
  'putKycSetLocked',
  'putKycSetLockedSpec',
  'putMailchimpSetupField',
  'putMailchimpSetupFieldSpec',
  'putMailchimpSyncCustomer',
  'putMailchimpSyncCustomerSpec',
  'putNicknameApprove',
  'putNicknameApproveSpec',
  'putNicknameDecline',
  'putNicknameDeclineSpec',
  'putPhoneVerificationForceVerify',
  'putPhoneVerificationForceVerifySpec',
  'putTrollboxBan',
  'putTrollboxBanSpec',
  'putTrollboxFlag',
  'putTrollboxFlagSpec',
  'putTrollboxProfanityConfig',
  'putTrollboxProfanityConfigSpec',
  'putTrollboxRemoveBan',
  'putTrollboxRemoveBanSpec',
  'putTrollboxRemoveFlag',
  'putTrollboxRemoveFlagSpec',
  'putUser',
  'putUserSpec',
  'putUsersSuspension',
  'putUsersSuspensionSpec',
  'putWalletsAudit',
  'putWalletsAuditSpec',
  'putWalletsLoad',
  'putWalletsLoadSpec',
  'putWithdrawalsSetApproved',
  'putWithdrawalsSetApprovedSpec',
];

/**
 * Definition of the request objects we will be generating. Implementer's "makeRequest" function should know how to handle these.
 */
export class XcXcalibraClientRequest {
  constructor(/** XcXcalibraClientRequest */ source) {
    /**
     * HTTP verb to use (eg. get, post...)
     * @type {string}
     */
    this.verb = undefined;

    /**
     * HTTP endpoint without url part and query. For example, '/api/v1/a/b/c'
     * @type {string}
     */
    this.endpoint = undefined;

    /**
     * Whether this endpoint requires authentication.
     * @type {boolean}
     */
    this.auth = undefined;

    /**
     * Name of the function on the client that will trigger this request.
     * @type {string}
     */
    this.method = undefined;

    /**
     * Object with query data, if feasible.
     */
    this.query = undefined;

    /**
     * Object with body data, if feasible.
     */
    this.body = undefined;

    /**
     * Headers to be added to the request
     */
    this.headers = undefined;

    /**
     * Request content type. Usually "json", can also be "multipart"
     * @type {string}
     */
    this.content_type = undefined;

    /**
     * If true, response will be binary data, instead of JSON
     * @type {boolean}
     */
    this.raw_response = undefined;

    /**
     * Internal router that will be used.
     * @type {string}
     */
    this.router = undefined;

    Object.assign(this, source);
  }
}

/**
 * Main client class. This is what you'll want to create to use the client. You should provide the function
 * which will actually execute requests.
 */
export class XcalibraClient {
  /**
   * @param {function(XcXcalibraClientRequest)} makeRequest Your function which will execute requests.
   * @param {boolean} bindMethods If true, methods will be bound to this client instance
   */
  constructor(makeRequest, bindMethods = true) {
    this._makeRequest = makeRequest;

    if (typeof this._makeRequest !== 'function') {
      throw new TypeError(
        `You must instantiate XcalibraClient with a function to handle the actual requests`
      );
    }

    if (bindMethods) {
      METHOD_NAMES.forEach(name => {
        this[name] = this[name].bind(this);
      });
    }
  }

  /**
   * Terminate given list of session id-s
   * @param {XcSessionsPayload} payload
   */
  deleteAuthSessions(payload) {
    const req = this.deleteAuthSessionsSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Terminate given list of session id-s
   * @param {XcSessionsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteAuthSessionsSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/auth/sessions`,
      auth: true,
      method: 'deleteAuthSessions',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Soft-delete bot
   * @param apiKey
   * @returns {Promise<XcBot>}
   */
  deleteBot(apiKey) {
    const req = this.deleteBotSpec(apiKey);

    return this._makeRequest(req);
  }

  /**
   * Soft-delete one or more bots. Target tokens should be provided in body.
   * @param {XcBotsPayload} payload
   */
  deleteBots(payload) {
    const req = this.deleteBotsSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Soft-delete bot
   * @param apiKey
   * @returns {XcXcalibraClientRequest}
   */
  deleteBotSpec(apiKey) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/bots/${apiKey}`,
      auth: true,
      method: 'deleteBot',
      router: 'admin',
    };
    return req;
  }

  /**
   * Soft-delete one or more bots. Target tokens should be provided in body.
   * @param {XcBotsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteBotsSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/bots`,
      auth: true,
      method: 'deleteBots',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Delete bank transfers
   * @param {Array} payload
   */
  deleteBrokerBankTransfers(payload) {
    const req = this.deleteBrokerBankTransfersSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete bank transfers
   * @param {Array} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteBrokerBankTransfersSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/broker/bank/transfers`,
      auth: true,
      method: 'deleteBrokerBankTransfers',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Delete link between deposit and managed buy order
   * @param {Array} payload
   */
  deleteBrokerDepositsLink(payload) {
    const req = this.deleteBrokerDepositsLinkSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete link between deposit and managed buy order
   * @param {Array} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteBrokerDepositsLinkSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/broker/deposits/link`,
      auth: true,
      method: 'deleteBrokerDepositsLink',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Delete one or more partner trades. Target ids should be provided in body.
   * @param {Array} payload
   */
  deleteBrokerPartnerTrades(payload) {
    const req = this.deleteBrokerPartnerTradesSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete one or more partner trades. Target ids should be provided in body.
   * @param {Array} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteBrokerPartnerTradesSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/broker/partner/trades`,
      auth: true,
      method: 'deleteBrokerPartnerTrades',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Delete partner exchange transfers
   * @param {Array} payload
   */
  deleteBrokerPartnerTransfers(payload) {
    const req = this.deleteBrokerPartnerTransfersSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete partner exchange transfers
   * @param {Array} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteBrokerPartnerTransfersSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/broker/partner/transfers`,
      auth: true,
      method: 'deleteBrokerPartnerTransfers',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Soft-delete micropayment. Any existing audit will be cancelled, micropayment will be deactivated for its owner.
   * @param id
   */
  deleteKycMicropayment(id) {
    const req = this.deleteKycMicropaymentSpec(id);

    return this._makeRequest(req);
  }

  /**
   * Soft-delete micropayment. Any existing audit will be cancelled, micropayment will be deactivated for its owner.
   * @param id
   * @returns {XcXcalibraClientRequest}
   */
  deleteKycMicropaymentSpec(id) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/kyc/micropayments/${id}`,
      auth: true,
      method: 'deleteKycMicropayment',
      router: 'admin',
    };
    return req;
  }

  /**
   * Delete user
   * @param {string} userId
   * @returns {Promise<XcUser>}
   */
  deleteUser(userId) {
    const req = this.deleteUserSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * Delete multiple users. The body should be an array of user id-s to delete. User must be allowed to delete all user-s, otherwise none will be deleted.
   * @param {XcUsersPayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  deleteUsers(payload) {
    const req = this.deleteUsersSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete user
   * @param {string} userId
   * @returns {XcXcalibraClientRequest}
   */
  deleteUserSpec(userId) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/users/${userId}`,
      auth: true,
      method: 'deleteUser',
      router: 'admin',
    };
    return req;
  }

  /**
   * Delete multiple users. The body should be an array of user id-s to delete. User must be allowed to delete all user-s, otherwise none will be deleted.
   * @param {XcUsersPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteUsersSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/users`,
      auth: true,
      method: 'deleteUsers',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Delete wallet snapshots for given list of user id-s. If not provided, delete all snapshots
   * @param {XcDeleteWalletSnapshotsPayload} payload
   */
  deleteWalletsSnapshots(payload) {
    const req = this.deleteWalletsSnapshotsSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Delete wallet snapshots for given list of user id-s. If not provided, delete all snapshots
   * @param {XcDeleteWalletSnapshotsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  deleteWalletsSnapshotsSpec(payload) {
    const req = {
      verb: 'delete',
      endpoint: `/api/admin/v1/wallets/snapshots`,
      auth: true,
      method: 'deleteWalletsSnapshots',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Query admin actions
   * @param {XcAdminActionInfoCriteria} criteria
   * @returns {Promise<XcQueryResultForAdminActionInfo>}
   */
  getAppAdminActions(criteria) {
    const req = this.getAppAdminActionsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query admin actions (CSV)
   * @param {XcAdminActionInfoCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getAppAdminActionsCsv(criteria) {
    const req = this.getAppAdminActionsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query admin actions (CSV)
   * @param {XcAdminActionInfoCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getAppAdminActionsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/app/admin-actions.csv`,
      auth: true,
      method: 'getAppAdminActionsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query admin actions
   * @param {XcAdminActionInfoCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getAppAdminActionsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/app/admin-actions`,
      auth: true,
      method: 'getAppAdminActions',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get application internals (settings, version, etc.)
   * @returns {Promise<XcMayanAppInternals>}
   */
  getAppInternals() {
    const req = this.getAppInternalsSpec();

    return this._makeRequest(req);
  }

  /**
   * Get application internals (settings, version, etc.)
   * @returns {XcXcalibraClientRequest}
   */
  getAppInternalsSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/app/internals`,
      auth: true,
      method: 'getAppInternals',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get logger state
   * @returns {Promise<XcLoggerState>}
   */
  getAppLogger() {
    const req = this.getAppLoggerSpec();

    return this._makeRequest(req);
  }

  /**
   * Get logger state
   * @returns {XcXcalibraClientRequest}
   */
  getAppLoggerSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/app/logger`,
      auth: true,
      method: 'getAppLogger',
      router: 'admin',
    };
    return req;
  }

  /**
   * Check if client making login request should be challenged with captcha
   */
  getAuthLoginShouldAskCaptcha() {
    const req = this.getAuthLoginShouldAskCaptchaSpec();

    return this._makeRequest(req);
  }

  /**
   * Check if client making login request should be challenged with captcha
   * @returns {XcXcalibraClientRequest}
   */
  getAuthLoginShouldAskCaptchaSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/auth/login/should-ask-captcha`,
      auth: false,
      method: 'getAuthLoginShouldAskCaptcha',
      router: 'admin',
    };
    return req;
  }

  /**
   * Retrieve user's current principal based on their access token
   * @returns {Promise<XcPrincipal>}
   */
  getAuthPrincipal() {
    const req = this.getAuthPrincipalSpec();

    return this._makeRequest(req);
  }

  /**
   * Retrieve user's current principal based on their access token
   * @returns {XcXcalibraClientRequest}
   */
  getAuthPrincipalSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/auth/principal`,
      auth: true,
      method: 'getAuthPrincipal',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query sessions
   * @param {XcSessionInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForSessionInfo>}
   */
  getAuthSessions(criteria) {
    const req = this.getAuthSessionsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query sessions (CSV)
   * @param {XcSessionInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getAuthSessionsCsv(criteria) {
    const req = this.getAuthSessionsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query sessions (CSV)
   * @param {XcSessionInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getAuthSessionsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/auth/sessions.csv`,
      auth: true,
      method: 'getAuthSessionsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query sessions
   * @param {XcSessionInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getAuthSessionsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/auth/sessions`,
      auth: true,
      method: 'getAuthSessions',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get single bot record
   * @param apiKey
   * @returns {Promise<XcBot>}
   */
  getBot(apiKey) {
    const req = this.getBotSpec(apiKey);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bots
   * @param {XcBotInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForBotInfo>}
   */
  getBots(criteria) {
    const req = this.getBotsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bots (CSV)
   * @param {XcBotInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBotsCsv(criteria) {
    const req = this.getBotsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bots (CSV)
   * @param {XcBotInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBotsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/bots.csv`,
      auth: true,
      method: 'getBotsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get signature secret for given bot. This will create a security event in some circumstances.
   * @param apiKey
   * @param {XcSignatureSecretCriteria} criteria
   */
  getBotSignatureSecret(apiKey, criteria) {
    const req = this.getBotSignatureSecretSpec(apiKey, criteria);

    return this._makeRequest(req);
  }

  /**
   * Get signature secret for given bot. This will create a security event in some circumstances.
   * @param apiKey
   * @param {XcSignatureSecretCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBotSignatureSecretSpec(apiKey, criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/bots/${apiKey}/signature-secret`,
      auth: true,
      method: 'getBotSignatureSecret',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get single bot record
   * @param apiKey
   * @returns {XcXcalibraClientRequest}
   */
  getBotSpec(apiKey) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/bots/${apiKey}`,
      auth: true,
      method: 'getBot',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of bots
   * @param {XcBotInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBotsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/bots`,
      auth: true,
      method: 'getBots',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get bank transfer
   * @param transferId
   * @returns {Promise<XcManagedBankTransfer>}
   */
  getBrokerBankTransfer(transferId) {
    const req = this.getBrokerBankTransferSpec(transferId);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bank transfers
   * @param {XcManagedBankTransfersCriteria} criteria
   * @returns {Promise<XcQueryResultForManagedBankTransfer>}
   */
  getBrokerBankTransfers(criteria) {
    const req = this.getBrokerBankTransfersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bank transfers (CSV)
   * @param {XcManagedBankTransfersCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerBankTransfersCsv(criteria) {
    const req = this.getBrokerBankTransfersCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of bank transfers (CSV)
   * @param {XcManagedBankTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerBankTransfersCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/bank/transfers.csv`,
      auth: true,
      method: 'getBrokerBankTransfersCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get bank transfer
   * @param transferId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerBankTransferSpec(transferId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/bank/transfers/${transferId}`,
      auth: true,
      method: 'getBrokerBankTransfer',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of bank transfers
   * @param {XcManagedBankTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerBankTransfersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/bank/transfers`,
      auth: true,
      method: 'getBrokerBankTransfers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get broker confguration
   * @returns {Promise<XcManagedConfig>}
   */
  getBrokerConfig() {
    const req = this.getBrokerConfigSpec();

    return this._makeRequest(req);
  }

  /**
   * Get broker confguration
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerConfigSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/config`,
      auth: true,
      method: 'getBrokerConfig',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query managed deposits
   * @param {XcManagedDepositsCriteria} criteria
   * @returns {Promise<XcQueryResultForManagedDeposit>}
   */
  getBrokerDeposits(criteria) {
    const req = this.getBrokerDepositsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query managed deposits (CSV)
   * @param {XcManagedDepositsCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerDepositsCsv(criteria) {
    const req = this.getBrokerDepositsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query managed deposits (CSV)
   * @param {XcManagedDepositsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerDepositsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/deposits.csv`,
      auth: true,
      method: 'getBrokerDepositsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query managed deposits
   * @param {XcManagedDepositsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerDepositsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/deposits`,
      auth: true,
      method: 'getBrokerDeposits',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get report for finalized order
   * @param downloadId
   * @returns {Promise<string>}
   */
  getBrokerDownloadReport(downloadId) {
    const req = this.getBrokerDownloadReportSpec(downloadId);

    return this._makeRequest(req);
  }

  /**
   * Get report for finalized order
   * @param downloadId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerDownloadReportSpec(downloadId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/download-report/${downloadId}`,
      auth: false,
      method: 'getBrokerDownloadReport',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of buy orders
   * @param {XcManagedBuyOrderCriteria} criteria
   * @returns {Promise<XcQueryResultForCensoredManagedBuyOrder>}
   */
  getBrokerOrdersBuy(criteria) {
    const req = this.getBrokerOrdersBuySpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of buy orders (CSV)
   * @param {XcManagedBuyOrderCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerOrdersBuyCsv(criteria) {
    const req = this.getBrokerOrdersBuyCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of buy orders (CSV)
   * @param {XcManagedBuyOrderCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersBuyCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/buy.csv`,
      auth: true,
      method: 'getBrokerOrdersBuyCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get buy order
   * @param orderId
   * @returns {Promise<XcManagedBuyOrder>}
   */
  getBrokerOrdersBuyOrderId(orderId) {
    const req = this.getBrokerOrdersBuyOrderIdSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Get buy order
   * @param orderId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersBuyOrderIdSpec(orderId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/buy/${orderId}`,
      auth: true,
      method: 'getBrokerOrdersBuyOrderId',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of buy orders
   * @param {XcManagedBuyOrderCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersBuySpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/buy`,
      auth: true,
      method: 'getBrokerOrdersBuy',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get paginated list of sell orders
   * @param {XcManagedSellOrderInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForManagedSellOrder>}
   */
  getBrokerOrdersSell(criteria) {
    const req = this.getBrokerOrdersSellSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of sell orders (CSV)
   * @param {XcManagedSellOrderInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerOrdersSellCsv(criteria) {
    const req = this.getBrokerOrdersSellCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of sell orders (CSV)
   * @param {XcManagedSellOrderInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersSellCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/sell.csv`,
      auth: true,
      method: 'getBrokerOrdersSellCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get sell order
   * @param orderId
   * @returns {Promise<XcManagedSellOrder>}
   */
  getBrokerOrdersSellOrderId(orderId) {
    const req = this.getBrokerOrdersSellOrderIdSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Get sell order
   * @param orderId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersSellOrderIdSpec(orderId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/sell/${orderId}`,
      auth: true,
      method: 'getBrokerOrdersSellOrderId',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of sell orders
   * @param {XcManagedSellOrderInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerOrdersSellSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/orders/sell`,
      auth: true,
      method: 'getBrokerOrdersSell',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get partner trade
   * @param tradeId
   * @returns {Promise<XcManagedPartnerTrade>}
   */
  getBrokerPartnerTrade(tradeId) {
    const req = this.getBrokerPartnerTradeSpec(tradeId);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner trades
   * @param {XcManagedPartnerTradesCriteria} criteria
   * @returns {Promise<XcQueryResultForManagedPartnerTrade>}
   */
  getBrokerPartnerTrades(criteria) {
    const req = this.getBrokerPartnerTradesSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner trades (CSV)
   * @param {XcManagedPartnerTradesCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerPartnerTradesCsv(criteria) {
    const req = this.getBrokerPartnerTradesCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner trades (CSV)
   * @param {XcManagedPartnerTradesCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTradesCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/trades.csv`,
      auth: true,
      method: 'getBrokerPartnerTradesCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get partner trade
   * @param tradeId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTradeSpec(tradeId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/trades/${tradeId}`,
      auth: true,
      method: 'getBrokerPartnerTrade',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of partner trades
   * @param {XcManagedPartnerTradesCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTradesSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/trades`,
      auth: true,
      method: 'getBrokerPartnerTrades',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get partner transfer
   * @param transferId
   * @returns {Promise<XcManagedPartnerTransfer>}
   */
  getBrokerPartnerTransfer(transferId) {
    const req = this.getBrokerPartnerTransferSpec(transferId);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner transfers
   * @param {XcManagedPartnerTransfersCriteria} criteria
   * @returns {Promise<XcQueryResultForManagedPartnerTransfer>}
   */
  getBrokerPartnerTransfers(criteria) {
    const req = this.getBrokerPartnerTransfersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner transfers (CSV)
   * @param {XcManagedPartnerTransfersCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getBrokerPartnerTransfersCsv(criteria) {
    const req = this.getBrokerPartnerTransfersCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get paginated list of partner transfers (CSV)
   * @param {XcManagedPartnerTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTransfersCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/transfers.csv`,
      auth: true,
      method: 'getBrokerPartnerTransfersCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get partner transfer
   * @param transferId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTransferSpec(transferId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/transfers/${transferId}`,
      auth: true,
      method: 'getBrokerPartnerTransfer',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get paginated list of partner transfers
   * @param {XcManagedPartnerTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPartnerTransfersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/partner/transfers`,
      auth: true,
      method: 'getBrokerPartnerTransfers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Prepare report for download
   * @param orderId
   * @returns {Promise<XcReportDownload>}
   */
  getBrokerPrepareReport(orderId) {
    const req = this.getBrokerPrepareReportSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Prepare report for download
   * @param orderId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPrepareReportSpec(orderId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/prepare-report/${orderId}`,
      auth: true,
      method: 'getBrokerPrepareReport',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get price estimator confguration
   * @returns {Promise<XcEstimatorConfig>}
   */
  getBrokerPriceEstimatorConfig() {
    const req = this.getBrokerPriceEstimatorConfigSpec();

    return this._makeRequest(req);
  }

  /**
   * Get price estimator confguration
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerPriceEstimatorConfigSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/price-estimator/config`,
      auth: true,
      method: 'getBrokerPriceEstimatorConfig',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query users
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForCustomerInfo>}
   */
  getBrokerUsers(criteria) {
    const req = this.getBrokerUsersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query users
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerUsersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/users`,
      auth: true,
      method: 'getBrokerUsers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get wallet with deposit addresses
   * @returns {Promise<XcManagedWallet>}
   */
  getBrokerWallet() {
    const req = this.getBrokerWalletSpec();

    return this._makeRequest(req);
  }

  /**
   * Get wallet with deposit addresses
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerWalletSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/wallet`,
      auth: true,
      method: 'getBrokerWallet',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query withdrawals for sell order
   * @param orderId
   * @returns {Promise<XcQueryResultForWithdrawal>}
   */
  getBrokerWithdrawal(orderId) {
    const req = this.getBrokerWithdrawalSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Query withdrawals for sell order
   * @param orderId
   * @returns {XcXcalibraClientRequest}
   */
  getBrokerWithdrawalSpec(orderId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/broker/withdrawals/${orderId}`,
      auth: true,
      method: 'getBrokerWithdrawal',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query card payments
   * @param {XcCardPaymentsCriteria} criteria
   * @returns {Promise<XcQueryResultForCardPayment>}
   */
  getCardPayments(criteria) {
    const req = this.getCardPaymentsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for card payments
   * @param {XcCardPaymentsCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getCardPaymentsCsv(criteria) {
    const req = this.getCardPaymentsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for card payments
   * @param {XcCardPaymentsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getCardPaymentsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/card-payments.csv`,
      auth: true,
      method: 'getCardPaymentsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query card payments
   * @param {XcCardPaymentsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getCardPaymentsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/card-payments`,
      auth: true,
      method: 'getCardPayments',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get global instrument config
   * @returns {Promise<XcInstrumentConfig>}
   */
  getConfigInstrumentsGlobal() {
    const req = this.getConfigInstrumentsGlobalSpec();

    return this._makeRequest(req);
  }

  /**
   * Get global instrument config
   * @returns {XcXcalibraClientRequest}
   */
  getConfigInstrumentsGlobalSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/instruments/global`,
      auth: true,
      method: 'getConfigInstrumentsGlobal',
      router: 'admin',
    };
    return req;
  }

  /**
   * List market-level config infos for all instruments
   * @returns {Promise<Array<XcConfigInfo>>}
   */
  getConfigInstrumentsMarket() {
    const req = this.getConfigInstrumentsMarketSpec();

    return this._makeRequest(req);
  }

  /**
   * List market-level config infos for all instruments
   * @returns {XcXcalibraClientRequest}
   */
  getConfigInstrumentsMarketSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/instruments/market`,
      auth: true,
      method: 'getConfigInstrumentsMarket',
      router: 'admin',
    };
    return req;
  }

  /**
   * List user-level config infos for all instruments
   * @param {XcInstrumentUserConfigInfosCriteria} criteria
   * @returns {Promise<Array<XcUserConfigInfo>>}
   */
  getConfigInstrumentsUsers(criteria) {
    const req = this.getConfigInstrumentsUsersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * List user-level config infos for all instruments
   * @param {XcInstrumentUserConfigInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getConfigInstrumentsUsersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/instruments/users`,
      auth: true,
      method: 'getConfigInstrumentsUsers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get global pair config
   * @returns {Promise<XcPairConfig>}
   */
  getConfigPairsGlobal() {
    const req = this.getConfigPairsGlobalSpec();

    return this._makeRequest(req);
  }

  /**
   * Get global pair config
   * @returns {XcXcalibraClientRequest}
   */
  getConfigPairsGlobalSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/pairs/global`,
      auth: true,
      method: 'getConfigPairsGlobal',
      router: 'admin',
    };
    return req;
  }

  /**
   * List market-level config infos for all pairs
   * @returns {Promise<Array<XcConfigInfo>>}
   */
  getConfigPairsMarket() {
    const req = this.getConfigPairsMarketSpec();

    return this._makeRequest(req);
  }

  /**
   * List market-level config infos for all pairs
   * @returns {XcXcalibraClientRequest}
   */
  getConfigPairsMarketSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/pairs/market`,
      auth: true,
      method: 'getConfigPairsMarket',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query user user-level config infos for all pairs
   * @param {XcPairUserConfigInfosCriteria} criteria
   * @returns {Promise<Array<XcUserConfigInfo>>}
   */
  getConfigPairsUsers(criteria) {
    const req = this.getConfigPairsUsersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user user-level config infos for all pairs
   * @param {XcPairUserConfigInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getConfigPairsUsersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/config/pairs/users`,
      auth: true,
      method: 'getConfigPairsUsers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Download previously generated CSV
   * @param downloadId
   * @returns {Promise<string>}
   */
  getCsvDownload(downloadId) {
    const req = this.getCsvDownloadSpec(downloadId);

    return this._makeRequest(req);
  }

  /**
   * Download previously generated CSV
   * @param downloadId
   * @returns {XcXcalibraClientRequest}
   */
  getCsvDownloadSpec(downloadId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/csv/download/${downloadId}`,
      auth: false,
      method: 'getCsvDownload',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get customer data for specific user.
   * @param userId
   * @returns {Promise<XcCustomer>}
   */
  getCustomersCustomer(userId) {
    const req = this.getCustomersCustomerSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * Get customer data for specific user.
   * @param userId
   * @returns {XcXcalibraClientRequest}
   */
  getCustomersCustomerSpec(userId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/customers/customer/${userId}`,
      auth: true,
      method: 'getCustomersCustomer',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get customer info for specific user, at specific KYC track.
   * @param userId
   * @param {KYC_TRACKS} kycTrack
   * @returns {Promise<XcCustomerInfo>}
   */
  getCustomersInfo(userId, kycTrack) {
    const req = this.getCustomersInfoSpec(userId, kycTrack);

    return this._makeRequest(req);
  }

  /**
   * Query customer infos with pagination.
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForCustomerInfo>}
   */
  getCustomersInfos(criteria) {
    const req = this.getCustomersInfosSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query customer infos with pagination. (CSV)
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getCustomersInfosCsv(criteria) {
    const req = this.getCustomersInfosCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query customer infos with pagination. (CSV)
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getCustomersInfosCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/customers/infos.csv`,
      auth: true,
      method: 'getCustomersInfosCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get customer info for specific user, at specific KYC track.
   * @param userId
   * @param {KYC_TRACKS} kycTrack
   * @returns {XcXcalibraClientRequest}
   */
  getCustomersInfoSpec(userId, kycTrack) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/customers/infos/${userId}/${kycTrack}`,
      auth: true,
      method: 'getCustomersInfo',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query customer infos with pagination.
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getCustomersInfosSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/customers/infos`,
      auth: true,
      method: 'getCustomersInfos',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Retrieve customer registration policy
   * @returns {Promise<XcRegistrationPolicy>}
   */
  getCustomersRegistrationPolicy() {
    const req = this.getCustomersRegistrationPolicySpec();

    return this._makeRequest(req);
  }

  /**
   * Retrieve customer registration policy
   * @returns {XcXcalibraClientRequest}
   */
  getCustomersRegistrationPolicySpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/customers/registration-policy`,
      auth: true,
      method: 'getCustomersRegistrationPolicy',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query deposits
   * @param {XcDepositsCriteria} criteria
   * @returns {Promise<XcQueryResultForDeposit>}
   */
  getDeposits(criteria) {
    const req = this.getDepositsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for deposits
   * @param {XcDepositsCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getDepositsCsv(criteria) {
    const req = this.getDepositsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for deposits
   * @param {XcDepositsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getDepositsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/deposits.csv`,
      auth: true,
      method: 'getDepositsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query deposits
   * @param {XcDepositsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getDepositsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/deposits`,
      auth: true,
      method: 'getDeposits',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Initiate document download. Returns a key that can be used to trigger download using an unauthenticated request.
   * @param documentId
   * @returns {Promise<XcDocumentDownload>}
   */
  getDocumentDownload(documentId) {
    const req = this.getDocumentDownloadSpec(documentId);

    return this._makeRequest(req);
  }

  /**
   * Initiate document download. Returns a key that can be used to trigger download using an unauthenticated request.
   * @param documentId
   * @returns {XcXcalibraClientRequest}
   */
  getDocumentDownloadSpec(documentId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/documents/${documentId}/download`,
      auth: true,
      method: 'getDocumentDownload',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query uploaded documents
   * @param {XcDocumentCriteria} criteria
   * @returns {Promise<XcQueryResultForDocument>}
   */
  getDocuments(criteria) {
    const req = this.getDocumentsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query uploaded documents (CSV)
   * @param {XcDocumentCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getDocumentsCsv(criteria) {
    const req = this.getDocumentsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query uploaded documents (CSV)
   * @param {XcDocumentCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getDocumentsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/documents.csv`,
      auth: true,
      method: 'getDocumentsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Consume previously obtained download key. Clients can use displayName argument to force browser to use a certain name for the document. You can add anything you want there, it has no relation to the backend functionality.
   * @param downloadKey
   * @param displayName
   * @returns {Promise<*>}
   */
  getDocumentsDownload(downloadKey, displayName) {
    const req = this.getDocumentsDownloadSpec(downloadKey, displayName);

    return this._makeRequest(req);
  }

  /**
   * Consume previously obtained download key. Clients can use displayName argument to force browser to use a certain name for the document. You can add anything you want there, it has no relation to the backend functionality.
   * @param downloadKey
   * @param displayName
   * @returns {XcXcalibraClientRequest}
   */
  getDocumentsDownloadSpec(downloadKey, displayName) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/documents/downloads/${downloadKey}/${displayName}`,
      auth: false,
      method: 'getDocumentsDownload',
      router: 'admin',
      raw_response: true,
    };
    return req;
  }

  /**
   * Query uploaded documents
   * @param {XcDocumentCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getDocumentsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/documents`,
      auth: true,
      method: 'getDocuments',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get last executed exchange audit result or null
   * @returns {Promise<XcExchangeAudit>}
   */
  getExchangeAudit() {
    const req = this.getExchangeAuditSpec();

    return this._makeRequest(req);
  }

  /**
   * Get last executed exchange audit result or null
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeAuditSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/audit`,
      auth: true,
      method: 'getExchangeAudit',
      router: 'admin',
    };
    return req;
  }

  /**
   * Exchange internals, including internals for each loaded market
   * @returns {Promise<XcExchangeInternals>}
   */
  getExchangeInternals() {
    const req = this.getExchangeInternalsSpec();

    return this._makeRequest(req);
  }

  /**
   * Exchange internals, including internals for each loaded market
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeInternalsSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/internals`,
      auth: true,
      method: 'getExchangeInternals',
      router: 'admin',
    };
    return req;
  }

  /**
   * Current state of the exchange state
   * @returns {Promise<XcExchangeState>}
   */
  getExchangeState() {
    const req = this.getExchangeStateSpec();

    return this._makeRequest(req);
  }

  /**
   * Current state of the exchange state
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeStateSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/state`,
      auth: true,
      method: 'getExchangeState',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query trades statistics
   * @param {XcTradeInfosCriteria} criteria
   * @returns {Promise<XcTradesStatistics>}
   */
  getExchangeStatistics(criteria) {
    const req = this.getExchangeStatisticsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query trades statistics
   * @param {XcTradeInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeStatisticsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/statistics`,
      auth: true,
      method: 'getExchangeStatistics',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query user's trades
   * @param {XcTradeInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForTradeInfo>}
   */
  getExchangeTrades(criteria) {
    const req = this.getExchangeTradesSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user's trades (CSV)
   * @param {XcTradeInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getExchangeTradesCsv(criteria) {
    const req = this.getExchangeTradesCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user's trades (CSV)
   * @param {XcTradeInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeTradesCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/trades.csv`,
      auth: true,
      method: 'getExchangeTradesCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query user's trades
   * @param {XcTradeInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getExchangeTradesSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/exchange/trades`,
      auth: true,
      method: 'getExchangeTrades',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get image data with applied optional post-processing
   * @param imageId
   * @param {XcImageVariant} criteria
   * @returns {Promise<*>}
   */
  getImageData(imageId, criteria) {
    const req = this.getImageDataSpec(imageId, criteria);

    return this._makeRequest(req);
  }

  /**
   * Get image data with applied optional post-processing
   * @param imageId
   * @param {XcImageVariant} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getImageDataSpec(imageId, criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/images/${imageId}/data`,
      auth: false,
      method: 'getImageData',
      router: 'admin',
      query: criteria,
      raw_response: true,
    };
    return req;
  }

  /**
   * Prepare download of a particular micropayment document. You can only call this if you have access rights over the corresponding micropayment.
   * @param documentId
   * @returns {Promise<XcDocumentDownload>}
   */
  getKycMicropaymentsDocumentDownload(documentId) {
    const req = this.getKycMicropaymentsDocumentDownloadSpec(documentId);

    return this._makeRequest(req);
  }

  /**
   * Prepare download of a particular micropayment document. You can only call this if you have access rights over the corresponding micropayment.
   * @param documentId
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsDocumentDownloadSpec(documentId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/documents/${documentId}/download`,
      auth: true,
      method: 'getKycMicropaymentsDocumentDownload',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get specific micropayment info record
   * @param id
   * @returns {Promise<XcKYCMicropaymentInfo>}
   */
  getKycMicropaymentsInfo(id) {
    const req = this.getKycMicropaymentsInfoSpec(id);

    return this._makeRequest(req);
  }

  /**
   * Query micropayment info records
   * @param {XcKYCMicropaymentInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForKYCMicropaymentInfo>}
   */
  getKycMicropaymentsInfos(criteria) {
    const req = this.getKycMicropaymentsInfosSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query micropayment info records (CSV)
   * @param {XcKYCMicropaymentInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getKycMicropaymentsInfosCsv(criteria) {
    const req = this.getKycMicropaymentsInfosCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query micropayment info records (CSV)
   * @param {XcKYCMicropaymentInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsInfosCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/infos.csv`,
      auth: true,
      method: 'getKycMicropaymentsInfosCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get specific micropayment info record
   * @param id
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsInfoSpec(id) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/infos/${id}`,
      auth: true,
      method: 'getKycMicropaymentsInfo',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query micropayment info records
   * @param {XcKYCMicropaymentInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsInfosSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/infos`,
      auth: true,
      method: 'getKycMicropaymentsInfos',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get reference number based on given user id
   * @param userId
   * @returns {Promise<string>}
   */
  getKycMicropaymentsReferenceNumbersFromUser(userId) {
    const req = this.getKycMicropaymentsReferenceNumbersFromUserSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * Get reference number based on given user id
   * @param userId
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsReferenceNumbersFromUserSpec(userId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/reference-numbers/from-user/${userId}`,
      auth: true,
      method: 'getKycMicropaymentsReferenceNumbersFromUser',
      router: 'admin',
    };
    return req;
  }

  /**
   * Get user id based on given reference number
   * @param referenceNumber
   * @returns {Promise<string>}
   */
  getKycMicropaymentsReferenceNumbersToUser(referenceNumber) {
    const req = this.getKycMicropaymentsReferenceNumbersToUserSpec(referenceNumber);

    return this._makeRequest(req);
  }

  /**
   * Get user id based on given reference number
   * @param referenceNumber
   * @returns {XcXcalibraClientRequest}
   */
  getKycMicropaymentsReferenceNumbersToUserSpec(referenceNumber) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/kyc/micropayments/reference-numbers/to-user/${referenceNumber}`,
      auth: true,
      method: 'getKycMicropaymentsReferenceNumbersToUser',
      router: 'admin',
    };
    return req;
  }

  /**
   * Information about the mailchimp client
   * @returns {Promise<XcMailchimpClientInfo>}
   */
  getMailchimpClientInfo() {
    const req = this.getMailchimpClientInfoSpec();

    return this._makeRequest(req);
  }

  /**
   * Information about the mailchimp client
   * @returns {XcXcalibraClientRequest}
   */
  getMailchimpClientInfoSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/mailchimp/client-info`,
      auth: true,
      method: 'getMailchimpClientInfo',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query missing mailchimp customer infos with pagination
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForCustomerInfo>}
   */
  getMailchimpMissingCustomers(criteria) {
    const req = this.getMailchimpMissingCustomersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query missing mailchimp customer infos with pagination
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getMailchimpMissingCustomersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/mailchimp/missing-customers`,
      auth: true,
      method: 'getMailchimpMissingCustomers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query phone verification infos
   * @param {XcPhoneVerificationInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForPhoneVerificationInfo>}
   */
  getPhoneVerificationInfos(criteria) {
    const req = this.getPhoneVerificationInfosSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query phone verification infos (CSV)
   * @param {XcPhoneVerificationInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getPhoneVerificationInfosCsv(criteria) {
    const req = this.getPhoneVerificationInfosCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query phone verification infos (CSV)
   * @param {XcPhoneVerificationInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getPhoneVerificationInfosCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/phone-verification/infos.csv`,
      auth: true,
      method: 'getPhoneVerificationInfosCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query phone verification infos
   * @param {XcPhoneVerificationInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getPhoneVerificationInfosSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/phone-verification/infos`,
      auth: true,
      method: 'getPhoneVerificationInfos',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Shows volume, revenue and user counts per unit of time (day)
   * @param {XcGrowthTimelineCriteria} criteria
   * @returns {Promise<XcQueryResultForGrowthTimelineReportItem>}
   */
  getReportsGrowthTimeline(criteria) {
    const req = this.getReportsGrowthTimelineSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Shows volume, revenue and user counts per unit of time (day) (CSV)
   * @param {XcGrowthTimelineCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getReportsGrowthTimelineCsv(criteria) {
    const req = this.getReportsGrowthTimelineCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Shows volume, revenue and user counts per unit of time (day) (CSV)
   * @param {XcGrowthTimelineCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getReportsGrowthTimelineCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/reports/growth-timeline.csv`,
      auth: true,
      method: 'getReportsGrowthTimelineCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Shows volume, revenue and user counts per unit of time (day)
   * @param {XcGrowthTimelineCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getReportsGrowthTimelineSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/reports/growth-timeline`,
      auth: true,
      method: 'getReportsGrowthTimeline',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Shows deposits, withdrawals and user counts per unit of time (day)
   * @param {XcTransferTimelineCriteria} criteria
   * @returns {Promise<XcQueryResultForTransferTimelineReportItem>}
   */
  getReportsTransferTimeline(criteria) {
    const req = this.getReportsTransferTimelineSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Shows deposits, withdrawals and user counts per unit of time (day) (CSV)
   * @param {XcTransferTimelineCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getReportsTransferTimelineCsv(criteria) {
    const req = this.getReportsTransferTimelineCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Shows deposits, withdrawals and user counts per unit of time (day) (CSV)
   * @param {XcTransferTimelineCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getReportsTransferTimelineCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/reports/transfer-timeline.csv`,
      auth: true,
      method: 'getReportsTransferTimelineCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Shows deposits, withdrawals and user counts per unit of time (day)
   * @param {XcTransferTimelineCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getReportsTransferTimelineSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/reports/transfer-timeline`,
      auth: true,
      method: 'getReportsTransferTimeline',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Internal method to provide schemas for different backend things for use on AdminUI. The request doesn't actually return anything.
   * @returns {Promise<XcAdminUISchemas>}
   */
  getSchemas() {
    const req = this.getSchemasSpec();

    return this._makeRequest(req);
  }

  /**
   * Internal method to provide schemas for different backend things for use on AdminUI. The request doesn't actually return anything.
   * @returns {XcXcalibraClientRequest}
   */
  getSchemasSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/schemas`,
      auth: true,
      method: 'getSchemas',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query security events
   * @param {XcSecurityEventInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForSecurityEventInfo>}
   */
  getSecurityEventsInfos(criteria) {
    const req = this.getSecurityEventsInfosSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query security events (CSV)
   * @param {XcSecurityEventInfosCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getSecurityEventsInfosCsv(criteria) {
    const req = this.getSecurityEventsInfosCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query security events (CSV)
   * @param {XcSecurityEventInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getSecurityEventsInfosCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/security-events/infos.csv`,
      auth: true,
      method: 'getSecurityEventsInfosCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query security events
   * @param {XcSecurityEventInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getSecurityEventsInfosSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/security-events/infos`,
      auth: true,
      method: 'getSecurityEventsInfos',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query transfers
   * @param {XcTransfersCriteria} criteria
   * @returns {Promise<XcQueryResultForTransfer>}
   */
  getTransfers(criteria) {
    const req = this.getTransfersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for transfers
   * @param {XcTransfersCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getTransfersCsv(criteria) {
    const req = this.getTransfersCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for transfers
   * @param {XcTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getTransfersCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/transfers.csv`,
      auth: true,
      method: 'getTransfersCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query transfers
   * @param {XcTransfersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getTransfersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/transfers`,
      auth: true,
      method: 'getTransfers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query messages from trollbox history. This might not include the most recent few seconds.
   * @param {XcTrollboxMessageInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForTrollboxMessageInfo>}
   */
  getTrollbox(criteria) {
    const req = this.getTrollboxSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Get trollbox profanity configuration.
   * @returns {Promise<XcTrollboxProfanityConfig>}
   */
  getTrollboxProfanityConfig() {
    const req = this.getTrollboxProfanityConfigSpec();

    return this._makeRequest(req);
  }

  /**
   * Get trollbox profanity configuration.
   * @returns {XcXcalibraClientRequest}
   */
  getTrollboxProfanityConfigSpec() {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/trollbox/profanity-config`,
      auth: true,
      method: 'getTrollboxProfanityConfig',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query messages from trollbox history. This might not include the most recent few seconds.
   * @param {XcTrollboxMessageInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getTrollboxSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/trollbox`,
      auth: true,
      method: 'getTrollbox',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get user record
   * @param {string} userId
   * @returns {Promise<XcUser>}
   */
  getUser(userId) {
    const req = this.getUserSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * Query users
   * @param {XcUsersCriteria} criteria
   * @returns {Promise<XcQueryResultForUser>}
   */
  getUsers(criteria) {
    const req = this.getUsersSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query users (CSV)
   * @param {XcUsersCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getUsersCsv(criteria) {
    const req = this.getUsersCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query users (CSV)
   * @param {XcUsersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getUsersCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/users.csv`,
      auth: true,
      method: 'getUsersCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Get user record
   * @param {string} userId
   * @returns {XcXcalibraClientRequest}
   */
  getUserSpec(userId) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/users/${userId}`,
      auth: true,
      method: 'getUser',
      router: 'admin',
    };
    return req;
  }

  /**
   * Query users
   * @param {XcUsersCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getUsersSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/users`,
      auth: true,
      method: 'getUsers',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query user wallets. Only data for loaded wallets will be available
   * @param {XcWalletInfosCriteria} criteria
   * @returns {Promise<XcQueryResultForWalletInfo>}
   */
  getWallets(criteria) {
    const req = this.getWalletsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Query user wallets. Only data for loaded wallets will be available
   * @param {XcWalletInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getWalletsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/wallets`,
      auth: true,
      method: 'getWallets',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query withdrawals
   * @param {XcWithdrawalsCriteria} criteria
   * @returns {Promise<XcQueryResultForWithdrawal>}
   */
  getWithdrawals(criteria) {
    const req = this.getWithdrawalsSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for withdrawals
   * @param {XcWithdrawalsCriteria} criteria
   * @returns {Promise<XcCSVDownload>}
   */
  getWithdrawalsCsv(criteria) {
    const req = this.getWithdrawalsCsvSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Generate CSV report for withdrawals
   * @param {XcWithdrawalsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getWithdrawalsCsvSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/withdrawals.csv`,
      auth: true,
      method: 'getWithdrawalsCsv',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Query withdrawals
   * @param {XcWithdrawalsCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  getWithdrawalsSpec(criteria) {
    const req = {
      verb: 'get',
      endpoint: `/api/admin/v1/withdrawals`,
      auth: true,
      method: 'getWithdrawals',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Send custom email using system's Mailer
   * @param {XcMailData} payload
   * @returns {Promise<XcSendMailReport>}
   */
  postAppSendMail(payload) {
    const req = this.postAppSendMailSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Send custom email using system's Mailer
   * @param {XcMailData} payload
   * @returns {XcXcalibraClientRequest}
   */
  postAppSendMailSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/app/send-mail`,
      auth: true,
      method: 'postAppSendMail',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Login using provided email and password. Only available for admin-s.
   * @param {XcLogInPayload} payload
   * @returns {Promise<XcPrincipal>}
   */
  postAuthLogin(payload) {
    const req = this.postAuthLoginSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Login using provided email and password. Only available for admin-s.
   * @param {XcLogInPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postAuthLoginSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/auth/login`,
      auth: false,
      method: 'postAuthLogin',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Renew session. Frontend should call this before current session token expires, so it can get a new one.
   * @returns {Promise<XcSession>}
   */
  postAuthRenew() {
    const req = this.postAuthRenewSpec();

    return this._makeRequest(req);
  }

  /**
   * Renew session. Frontend should call this before current session token expires, so it can get a new one.
   * @returns {XcXcalibraClientRequest}
   */
  postAuthRenewSpec() {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/auth/renew`,
      auth: true,
      method: 'postAuthRenew',
      router: 'admin',
    };
    return req;
  }

  /**
   * Create a new bot. API key and secret are automatically generated
   * @param {XcAdminBotPayload} payload
   * @returns {Promise<XcBot>}
   */
  postBot(payload) {
    const req = this.postBotSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a new bot. API key and secret are automatically generated
   * @param {XcAdminBotPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBotSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/bots`,
      auth: true,
      method: 'postBot',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Insert bank transfer.
   * @param {XcManagedBankTransferPayload} payload
   * @returns {Promise<XcManagedBankTransfer>}
   */
  postBrokerBankTransfer(payload) {
    const req = this.postBrokerBankTransferSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Insert bank transfer.
   * @param {XcManagedBankTransferPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBrokerBankTransferSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/broker/bank/transfers`,
      auth: true,
      method: 'postBrokerBankTransfer',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Link deposit to managed buy order
   * @param {XcLinkManagedDepositsToBuyOrderPayload} payload
   * @returns {Promise<Array<XcManagedDepositOrderLink>>}
   */
  postBrokerDepositsLink(payload) {
    const req = this.postBrokerDepositsLinkSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Link deposit to managed buy order
   * @param {XcLinkManagedDepositsToBuyOrderPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBrokerDepositsLinkSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/broker/deposits/link`,
      auth: true,
      method: 'postBrokerDepositsLink',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Insert trade executed on partner exchange
   * @param {XcManagedPartnerTradePayload} payload
   * @returns {Promise<XcManagedPartnerTrade>}
   */
  postBrokerPartnerTrade(payload) {
    const req = this.postBrokerPartnerTradeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Insert trade executed on partner exchange
   * @param {XcManagedPartnerTradePayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBrokerPartnerTradeSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/broker/partner/trades`,
      auth: true,
      method: 'postBrokerPartnerTrade',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Insert partner exchange transfer. Either deposit or withdrawal.
   * @param {XcManagedPartnerTransferPayload} payload
   * @returns {Promise<XcManagedPartnerTransfer>}
   */
  postBrokerPartnerTransfer(payload) {
    const req = this.postBrokerPartnerTransferSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Insert partner exchange transfer. Either deposit or withdrawal.
   * @param {XcManagedPartnerTransferPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBrokerPartnerTransferSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/broker/partner/transfers`,
      auth: true,
      method: 'postBrokerPartnerTransfer',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Request withdrawal for sell order
   * @param {XcManagedSellOrderWithdrawalPayload} payload
   * @returns {Promise<XcWithdrawal>}
   */
  postBrokerWithdrawal(payload) {
    const req = this.postBrokerWithdrawalSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Request withdrawal for sell order
   * @param {XcManagedSellOrderWithdrawalPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postBrokerWithdrawalSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/broker/withdrawals`,
      auth: true,
      method: 'postBrokerWithdrawal',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set customer registration policy
   * @param {XcRegistrationPolicy} payload
   * @returns {Promise<XcRegistrationPolicy>}
   */
  postCustomersRegistrationPolicy(payload) {
    const req = this.postCustomersRegistrationPolicySpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set customer registration policy
   * @param {XcRegistrationPolicy} payload
   * @returns {XcXcalibraClientRequest}
   */
  postCustomersRegistrationPolicySpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/customers/registration-policy`,
      auth: true,
      method: 'postCustomersRegistrationPolicy',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Upload a document. Returns info about the document that was uploaded
   * @param {XcDocumentUploadBody} payload
   * @returns {Promise<XcDocument>}
   */
  postDocument(payload) {
    const req = this.postDocumentSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Upload a document. Returns info about the document that was uploaded
   * @param {XcDocumentUploadBody} payload
   * @returns {XcXcalibraClientRequest}
   */
  postDocumentSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/documents`,
      auth: true,
      method: 'postDocument',
      router: 'admin',
      content_type: 'multipart',
      body: payload,
    };
    return req;
  }

  /**
   * Trigger snapshotting for the given market. Returns a report with the snapshot result. Note that snapshotting will involve pausing the order processing for a little while.
   * @param {PAIRS} pair
   * @returns {Promise<XcOrderBookSnapshottingReport>}
   */
  postExchangeSnapshot(pair) {
    const req = this.postExchangeSnapshotSpec(pair);

    return this._makeRequest(req);
  }

  /**
   * Trigger snapshotting for the given market. Returns a report with the snapshot result. Note that snapshotting will involve pausing the order processing for a little while.
   * @param {PAIRS} pair
   * @returns {XcXcalibraClientRequest}
   */
  postExchangeSnapshotSpec(pair) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/exchange/${pair}/snapshot`,
      auth: true,
      method: 'postExchangeSnapshot',
      router: 'admin',
    };
    return req;
  }

  /**
   * Unlock trading for exchanges
   */
  postExchangeUnlock() {
    const req = this.postExchangeUnlockSpec();

    return this._makeRequest(req);
  }

  /**
   * Unlock trading for exchanges
   * @returns {XcXcalibraClientRequest}
   */
  postExchangeUnlockSpec() {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/exchange/unlock`,
      auth: true,
      method: 'postExchangeUnlock',
      router: 'admin',
    };
    return req;
  }

  /**
   * Submit a new micropayment. If there is any existing incomplete micropayment or audit, it will be marked as finished. This will immediately create an admin task for compliance people to audit
   * @param {XcKYCMicropaymentPayload} payload
   */
  postKycMicropayment(payload) {
    const req = this.postKycMicropaymentSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Submit a new micropayment. If there is any existing incomplete micropayment or audit, it will be marked as finished. This will immediately create an admin task for compliance people to audit
   * @param {XcKYCMicropaymentPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postKycMicropaymentSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/kyc/micropayments`,
      auth: true,
      method: 'postKycMicropayment',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Submit trollbox message. Frontend should call this to send new messages.
   * @param {TROLLBOX_ROOMS} room
   * @param {XcTrollboxMessagePayload} payload
   */
  postTrollbox(room, payload) {
    const req = this.postTrollboxSpec(room, payload);

    return this._makeRequest(req);
  }

  /**
   * Submit trollbox message. Frontend should call this to send new messages.
   * @param {TROLLBOX_ROOMS} room
   * @param {XcTrollboxMessagePayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postTrollboxSpec(room, payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/trollbox/${room}`,
      auth: true,
      method: 'postTrollbox',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Create a new user
   * @param {XcUserPayload} payload
   * @returns {Promise<XcUser>}
   */
  postUser(payload) {
    const req = this.postUserSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Create a new user
   * @param {XcUserPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  postUserSpec(payload) {
    const req = {
      verb: 'post',
      endpoint: `/api/admin/v1/users`,
      auth: true,
      method: 'postUser',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Disable 2FA for a provided list of user id-s.
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcUser>>}
   */
  put2faDisable(payload) {
    const req = this.put2faDisableSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Disable 2FA for a provided list of user id-s.
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  put2faDisableSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/2fa/disable`,
      auth: true,
      method: 'put2faDisable',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set logging level for an individual collector. Set to null to fall back to global level
   * @param key
   * @param {XcLogCollectorLevelPayload} payload
   * @returns {Promise<XcLoggerState>}
   */
  putAppLoggerCollectorLevel(key, payload) {
    const req = this.putAppLoggerCollectorLevelSpec(key, payload);

    return this._makeRequest(req);
  }

  /**
   * Set logging level for an individual collector. Set to null to fall back to global level
   * @param key
   * @param {XcLogCollectorLevelPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putAppLoggerCollectorLevelSpec(key, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/app/logger/collectors/${key}/level`,
      auth: true,
      method: 'putAppLoggerCollectorLevel',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set global logging level
   * @param {XcLoggerLevelPayload} payload
   * @returns {Promise<XcLoggerState>}
   */
  putAppLoggerLevel(payload) {
    const req = this.putAppLoggerLevelSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set global logging level
   * @param {XcLoggerLevelPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putAppLoggerLevelSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/app/logger/level`,
      auth: true,
      method: 'putAppLoggerLevel',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * This is an admin action to set reference numbers for all customers. These reference numbers are idempotent, so you can call this however many times you want
   */
  putAppMaintenanceUpdateMicropaymentReferenceNumber() {
    const req = this.putAppMaintenanceUpdateMicropaymentReferenceNumberSpec();

    return this._makeRequest(req);
  }

  /**
   * This is an admin action to set reference numbers for all customers. These reference numbers are idempotent, so you can call this however many times you want
   * @returns {XcXcalibraClientRequest}
   */
  putAppMaintenanceUpdateMicropaymentReferenceNumberSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/app/maintenance/update-micropayment-reference-numbers`,
      auth: true,
      method: 'putAppMaintenanceUpdateMicropaymentReferenceNumber',
      router: 'admin',
    };
    return req;
  }

  /**
   * User-initiated logout action. Access token will be terminated with the "logout" reason.
   */
  putAuthLogout() {
    const req = this.putAuthLogoutSpec();

    return this._makeRequest(req);
  }

  /**
   * User-initiated logout action. Access token will be terminated with the "logout" reason.
   * @returns {XcXcalibraClientRequest}
   */
  putAuthLogoutSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/auth/logout`,
      auth: true,
      method: 'putAuthLogout',
      router: 'admin',
    };
    return req;
  }

  /**
   * Clients should call this when idle timeout expires. This is informational only, and will only speed up what the backend will do anyway.
   */
  putAuthNotifyIdleTimeout() {
    const req = this.putAuthNotifyIdleTimeoutSpec();

    return this._makeRequest(req);
  }

  /**
   * Clients should call this when idle timeout expires. This is informational only, and will only speed up what the backend will do anyway.
   * @returns {XcXcalibraClientRequest}
   */
  putAuthNotifyIdleTimeoutSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/auth/notify-idle-timeout`,
      auth: true,
      method: 'putAuthNotifyIdleTimeout',
      router: 'admin',
    };
    return req;
  }

  /**
   * Tell the server when was the holder of access token in the header last seen. By "seen", we mean any activity in the app, eg. moving a mouse. Time is submitted in "X ms ago" format.
   * @param {XcSessionSeenPayload} payload
   * @returns {Promise<XcSession>}
   */
  putAuthNotifySeen(payload) {
    const req = this.putAuthNotifySeenSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Tell the server when was the holder of access token in the header last seen. By "seen", we mean any activity in the app, eg. moving a mouse. Time is submitted in "X ms ago" format.
   * @param {XcSessionSeenPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putAuthNotifySeenSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/auth/notify-seen`,
      auth: true,
      method: 'putAuthNotifySeen',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Terminate sessions for given list of user id-s.
   * @param {XcUsersPayload} payload
   */
  putAuthSessionsForceLogOut(payload) {
    const req = this.putAuthSessionsForceLogOutSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Terminate sessions for given list of user id-s.
   * @param {XcUsersPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putAuthSessionsForceLogOutSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/auth/sessions/force-log-out`,
      auth: true,
      method: 'putAuthSessionsForceLogOut',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update bot
   * @param apiKey
   * @param {XcAdminBotPayload} payload
   * @returns {Promise<XcBot>}
   */
  putBot(apiKey, payload) {
    const req = this.putBotSpec(apiKey, payload);

    return this._makeRequest(req);
  }

  /**
   * Update bot
   * @param apiKey
   * @param {XcAdminBotPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBotSpec(apiKey, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/bots/${apiKey}`,
      auth: true,
      method: 'putBot',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Alter managed status of multiple customers. Manage or un-manage.
   * @param {XcSetCustomersManagedPayload} payload
   * @returns {Promise<Array<XcCustomer>>}
   */
  putBrokerAccount(payload) {
    const req = this.putBrokerAccountSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Alter managed status of multiple customers. Manage or un-manage.
   * @param {XcSetCustomersManagedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerAccountSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/accounts`,
      auth: true,
      method: 'putBrokerAccount',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update bank transfer
   * @param transferId
   * @param {XcManagedBankTransferPayload} payload
   * @returns {Promise<XcManagedBankTransfer>}
   */
  putBrokerBankTransfer(transferId, payload) {
    const req = this.putBrokerBankTransferSpec(transferId, payload);

    return this._makeRequest(req);
  }

  /**
   * Update bank transfer
   * @param transferId
   * @param {XcManagedBankTransferPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerBankTransferSpec(transferId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/bank/transfers/${transferId}`,
      auth: true,
      method: 'putBrokerBankTransfer',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set broker confguration
   * @param {XcManagedConfig} payload
   * @returns {Promise<XcManagedConfig>}
   */
  putBrokerConfig(payload) {
    const req = this.putBrokerConfigSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set broker confguration
   * @param {XcManagedConfig} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerConfigSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/config`,
      auth: true,
      method: 'putBrokerConfig',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Generate report for finalized order
   * @param orderId
   */
  putBrokerGenerateReport(orderId) {
    const req = this.putBrokerGenerateReportSpec(orderId);

    return this._makeRequest(req);
  }

  /**
   * Generate report for finalized order
   * @param orderId
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerGenerateReportSpec(orderId) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/generate-report/${orderId}`,
      auth: true,
      method: 'putBrokerGenerateReport',
      router: 'admin',
    };
    return req;
  }

  /**
   * Cancel list of managed buy orders
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcManagedBuyOrder>>}
   */
  putBrokerOrdersBuyCancel(payload) {
    const req = this.putBrokerOrdersBuyCancelSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Cancel list of managed buy orders
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersBuyCancelSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/buy/cancel`,
      auth: true,
      method: 'putBrokerOrdersBuyCancel',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Finalize list of managed buy orders. Use failure_reason to indicate order has failed. This will be shown to users in interface.
   * @param {XcFinalizeManagedBuyOrderRequestsPayload} payload
   * @returns {Promise<Array<XcManagedBuyOrder>>}
   */
  putBrokerOrdersBuyFinalize(payload) {
    const req = this.putBrokerOrdersBuyFinalizeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Finalize list of managed buy orders. Use failure_reason to indicate order has failed. This will be shown to users in interface.
   * @param {XcFinalizeManagedBuyOrderRequestsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersBuyFinalizeSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/buy/finalize`,
      auth: true,
      method: 'putBrokerOrdersBuyFinalize',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Mark list of managed buy orders as paid.
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcManagedBuyOrder>>}
   */
  putBrokerOrdersBuyPay(payload) {
    const req = this.putBrokerOrdersBuyPaySpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Mark list of managed buy orders as paid.
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersBuyPaySpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/buy/pay`,
      auth: true,
      method: 'putBrokerOrdersBuyPay',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Suspend list of managed buy orders.
   * @param {XcSetOrdersSuspendedPayload} payload
   * @returns {Promise<Array<XcManagedBuyOrder>>}
   */
  putBrokerOrdersBuySuspend(payload) {
    const req = this.putBrokerOrdersBuySuspendSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Suspend list of managed buy orders.
   * @param {XcSetOrdersSuspendedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersBuySuspendSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/buy/suspend`,
      auth: true,
      method: 'putBrokerOrdersBuySuspend',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Take list of managed buy orders for processing.
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcManagedBuyOrder>>}
   */
  putBrokerOrdersBuyTake(payload) {
    const req = this.putBrokerOrdersBuyTakeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Take list of managed buy orders for processing.
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersBuyTakeSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/buy/take`,
      auth: true,
      method: 'putBrokerOrdersBuyTake',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Finalize list of sell orders. Use failure_reason to indicate order has failed. This will be shown to users in interface.
   * @param {XcFinalizeManagedSellOrderRequestsPayload} payload
   * @returns {Promise<Array<XcManagedSellOrder>>}
   */
  putBrokerOrdersSellFinalize(payload) {
    const req = this.putBrokerOrdersSellFinalizeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Finalize list of sell orders. Use failure_reason to indicate order has failed. This will be shown to users in interface.
   * @param {XcFinalizeManagedSellOrderRequestsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersSellFinalizeSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/sell/finalize`,
      auth: true,
      method: 'putBrokerOrdersSellFinalize',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Refund list of managed sell orders
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcManagedSellOrder>>}
   */
  putBrokerOrdersSellRefund(payload) {
    const req = this.putBrokerOrdersSellRefundSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Refund list of managed sell orders
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersSellRefundSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/sell/refund`,
      auth: true,
      method: 'putBrokerOrdersSellRefund',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Suspend list of sell orders.
   * @param {XcSetOrdersSuspendedPayload} payload
   * @returns {Promise<Array<XcManagedSellOrder>>}
   */
  putBrokerOrdersSellSuspend(payload) {
    const req = this.putBrokerOrdersSellSuspendSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Suspend list of sell orders.
   * @param {XcSetOrdersSuspendedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersSellSuspendSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/sell/suspend`,
      auth: true,
      method: 'putBrokerOrdersSellSuspend',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Take list of managed sell orders.
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcManagedSellOrder>>}
   */
  putBrokerOrdersSellTake(payload) {
    const req = this.putBrokerOrdersSellTakeSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Take list of managed sell orders.
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerOrdersSellTakeSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/orders/sell/take`,
      auth: true,
      method: 'putBrokerOrdersSellTake',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update partner trade
   * @param tradeId
   * @param {XcManagedPartnerTradePayload} payload
   * @returns {Promise<XcManagedPartnerTrade>}
   */
  putBrokerPartnerTrade(tradeId, payload) {
    const req = this.putBrokerPartnerTradeSpec(tradeId, payload);

    return this._makeRequest(req);
  }

  /**
   * Update partner trade
   * @param tradeId
   * @param {XcManagedPartnerTradePayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerPartnerTradeSpec(tradeId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/partner/trades/${tradeId}`,
      auth: true,
      method: 'putBrokerPartnerTrade',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update partner transfer
   * @param transferId
   * @param {XcManagedPartnerTransferPayload} payload
   * @returns {Promise<XcManagedPartnerTransfer>}
   */
  putBrokerPartnerTransfer(transferId, payload) {
    const req = this.putBrokerPartnerTransferSpec(transferId, payload);

    return this._makeRequest(req);
  }

  /**
   * Update partner transfer
   * @param transferId
   * @param {XcManagedPartnerTransferPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerPartnerTransferSpec(transferId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/partner/transfers/${transferId}`,
      auth: true,
      method: 'putBrokerPartnerTransfer',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set price estimator confguration
   * @param {XcEstimatorConfig} payload
   * @returns {Promise<XcEstimatorConfig>}
   */
  putBrokerPriceEstimatorConfig(payload) {
    const req = this.putBrokerPriceEstimatorConfigSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set price estimator confguration
   * @param {XcEstimatorConfig} payload
   * @returns {XcXcalibraClientRequest}
   */
  putBrokerPriceEstimatorConfigSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/broker/price-estimator/config`,
      auth: true,
      method: 'putBrokerPriceEstimatorConfig',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set instrument config
   * @param {XcInstrumentConfigPayload} payload
   * @returns {Promise<Array<XcInstrumentConfig>>}
   */
  putConfigInstrument(payload) {
    const req = this.putConfigInstrumentSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set instrument config
   * @param {XcInstrumentConfigPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putConfigInstrumentSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/config/instruments`,
      auth: true,
      method: 'putConfigInstrument',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * @param {XcPairConfigPayload} payload
   * @returns {Promise<Array<XcPairConfig>>}
   */
  putConfigPair(payload) {
    const req = this.putConfigPairSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * @param {XcPairConfigPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putConfigPairSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/config/pairs`,
      auth: true,
      method: 'putConfigPair',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update customer data. Admin uses this to alter customer records. Certain alterations can cause downstream effects, eg. invalidating certain verifications.
   * @param userId
   * @param {XcCustomerDataPayload} payload
   * @returns {Promise<XcCustomer>}
   */
  putCustomersCustomer(userId, payload) {
    const req = this.putCustomersCustomerSpec(userId, payload);

    return this._makeRequest(req);
  }

  /**
   * Update customer data. Admin uses this to alter customer records. Certain alterations can cause downstream effects, eg. invalidating certain verifications.
   * @param userId
   * @param {XcCustomerDataPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putCustomersCustomerSpec(userId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/customers/customer/${userId}`,
      auth: true,
      method: 'putCustomersCustomer',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * A temporary endpoint to trigger US customer state fill
   */
  putCustomersUsCustomersFillState() {
    const req = this.putCustomersUsCustomersFillStateSpec();

    return this._makeRequest(req);
  }

  /**
   * A temporary endpoint to trigger US customer state fill
   * @returns {XcXcalibraClientRequest}
   */
  putCustomersUsCustomersFillStateSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/customers/us-customers/fill-state`,
      auth: true,
      method: 'putCustomersUsCustomersFillState',
      router: 'admin',
    };
    return req;
  }

  /**
   * Mark emails as verified or not verified for given list of users
   * @param {XcSetEmailVerifiedPayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putEmailSetVerified(payload) {
    const req = this.putEmailSetVerifiedSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Mark emails as verified or not verified for given list of users
   * @param {XcSetEmailVerifiedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putEmailSetVerifiedSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/email/set-verified`,
      auth: true,
      method: 'putEmailSetVerified',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Execute an exchange audit and return the results. Note that this might be performance intensive operation
   * @returns {Promise<XcExchangeAudit>}
   */
  putExchangeAudit() {
    const req = this.putExchangeAuditSpec();

    return this._makeRequest(req);
  }

  /**
   * Execute an exchange audit and return the results. Note that this might be performance intensive operation
   * @returns {XcXcalibraClientRequest}
   */
  putExchangeAuditSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/exchange/audit`,
      auth: true,
      method: 'putExchangeAudit',
      router: 'admin',
    };
    return req;
  }

  /**
   * A temporary endpoint to trigger KYC0 order cleanup
   */
  putExchangeCancelKyc0Order() {
    const req = this.putExchangeCancelKyc0OrderSpec();

    return this._makeRequest(req);
  }

  /**
   * A temporary endpoint to trigger KYC0 order cleanup
   * @returns {XcXcalibraClientRequest}
   */
  putExchangeCancelKyc0OrderSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/exchange/cancel-kyc0-orders`,
      auth: true,
      method: 'putExchangeCancelKyc0Order',
      router: 'admin',
    };
    return req;
  }

  /**
   * A temporary endpoint to trigger RSD order cleanup
   */
  putExchangeCancelRsdOrder() {
    const req = this.putExchangeCancelRsdOrderSpec();

    return this._makeRequest(req);
  }

  /**
   * A temporary endpoint to trigger RSD order cleanup
   * @returns {XcXcalibraClientRequest}
   */
  putExchangeCancelRsdOrderSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/exchange/cancel-rsd-orders`,
      auth: true,
      method: 'putExchangeCancelRsdOrder',
      router: 'admin',
    };
    return req;
  }

  /**
   * A temporary endpoint to trigger unverified phone number order cleanup
   */
  putExchangeCancelUnverifiedPhoneOrder() {
    const req = this.putExchangeCancelUnverifiedPhoneOrderSpec();

    return this._makeRequest(req);
  }

  /**
   * A temporary endpoint to trigger unverified phone number order cleanup
   * @returns {XcXcalibraClientRequest}
   */
  putExchangeCancelUnverifiedPhoneOrderSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/exchange/cancel-unverified-phone-orders`,
      auth: true,
      method: 'putExchangeCancelUnverifiedPhoneOrder',
      router: 'admin',
    };
    return req;
  }

  /**
   * A temporary endpoint to trigger blacklisted US customer order cleanup
   */
  putKycCancelBlacklistedUsCustomerOrder() {
    const req = this.putKycCancelBlacklistedUsCustomerOrderSpec();

    return this._makeRequest(req);
  }

  /**
   * A temporary endpoint to trigger blacklisted US customer order cleanup
   * @returns {XcXcalibraClientRequest}
   */
  putKycCancelBlacklistedUsCustomerOrderSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/cancel-blacklisted-us-customer-orders`,
      auth: true,
      method: 'putKycCancelBlacklistedUsCustomerOrder',
      router: 'admin',
    };
    return req;
  }

  /**
   * If verification has passed on NetVerify but was flagged due to inconsistencies on our side, admin can call this to override failed verification and proceed to screening.
   * @param userId
   */
  putKycLevel1OverrideVerification(userId) {
    const req = this.putKycLevel1OverrideVerificationSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * If verification has passed on NetVerify but was flagged due to inconsistencies on our side, admin can call this to override failed verification and proceed to screening.
   * @param userId
   * @returns {XcXcalibraClientRequest}
   */
  putKycLevel1OverrideVerificationSpec(userId) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/level1/override-verification/${userId}`,
      auth: true,
      method: 'putKycLevel1OverrideVerification',
      router: 'admin',
    };
    return req;
  }

  /**
   * If user is in a kyc1_screening_release state, admin can call this to execute screening flow. If screening passes, user will be granted KYC1.
   * @param userId
   */
  putKycLevel1ReleaseScreening(userId) {
    const req = this.putKycLevel1ReleaseScreeningSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * If user is in a kyc1_screening_release state, admin can call this to execute screening flow. If screening passes, user will be granted KYC1.
   * @param userId
   * @returns {XcXcalibraClientRequest}
   */
  putKycLevel1ReleaseScreeningSpec(userId) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/level1/release-screening/${userId}`,
      auth: true,
      method: 'putKycLevel1ReleaseScreening',
      router: 'admin',
    };
    return req;
  }

  /**
   * Submit result for the currently active KYC3 micropayment audit
   * @param userId
   * @param {XcKYCMicropaymentAuditResultPayload} payload
   */
  putKycLevel3AuditResult(userId, payload) {
    const req = this.putKycLevel3AuditResultSpec(userId, payload);

    return this._makeRequest(req);
  }

  /**
   * Submit result for the currently active KYC3 micropayment audit
   * @param userId
   * @param {XcKYCMicropaymentAuditResultPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putKycLevel3AuditResultSpec(userId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/level3/audit-result/${userId}`,
      auth: true,
      method: 'putKycLevel3AuditResult',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Launch a new micropayment audit for given user (given all prerequisites are met)
   * @param userId
   */
  putKycLevel3LaunchAudit(userId) {
    const req = this.putKycLevel3LaunchAuditSpec(userId);

    return this._makeRequest(req);
  }

  /**
   * Launch a new micropayment audit for given user (given all prerequisites are met)
   * @param userId
   * @returns {XcXcalibraClientRequest}
   */
  putKycLevel3LaunchAuditSpec(userId) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/level3/launch-audit/${userId}`,
      auth: true,
      method: 'putKycLevel3LaunchAudit',
      router: 'admin',
    };
    return req;
  }

  /**
   * Update micropayment record, including the documents
   * @param id
   * @param {XcKYCMicropaymentPayload} payload
   */
  putKycMicropayment(id, payload) {
    const req = this.putKycMicropaymentSpec(id, payload);

    return this._makeRequest(req);
  }

  /**
   * Update micropayment record, including the documents
   * @param id
   * @param {XcKYCMicropaymentPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putKycMicropaymentSpec(id, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/micropayments/${id}`,
      auth: true,
      method: 'putKycMicropayment',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Reset KYC state of the submitted list of customers to the given KYC level. If downgrading, all associated data with given level will be cleaned. When upgrading, only open requests will be cleared, other data will remain.
   * @param {XcResetToKYCLevelPayload} payload
   */
  putKycResetToLevel(payload) {
    const req = this.putKycResetToLevelSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Reset KYC state of the submitted list of customers to the given KYC level. If downgrading, all associated data with given level will be cleaned. When upgrading, only open requests will be cleared, other data will remain.
   * @param {XcResetToKYCLevelPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putKycResetToLevelSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/reset-to-level`,
      auth: true,
      method: 'putKycResetToLevel',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Reset KYC level for US customers from forbidden states
   */
  putKycResetUsCustomer() {
    const req = this.putKycResetUsCustomerSpec();

    return this._makeRequest(req);
  }

  /**
   * Reset KYC level for US customers from forbidden states
   * @returns {XcXcalibraClientRequest}
   */
  putKycResetUsCustomerSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/reset-us-customers`,
      auth: true,
      method: 'putKycResetUsCustomer',
      router: 'admin',
    };
    return req;
  }

  /**
   * Resolve in-flight KYC 1 or 2 requests for list of users by either granting them or rejecting them. If message is not provided, system will derive one based on current KYC request state
   * @param {XcResolveKYCRequestsPayload} payload
   */
  putKycResolve(payload) {
    const req = this.putKycResolveSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Resolve in-flight KYC 1 or 2 requests for list of users by either granting them or rejecting them. If message is not provided, system will derive one based on current KYC request state
   * @param {XcResolveKYCRequestsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putKycResolveSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/resolve`,
      auth: true,
      method: 'putKycResolve',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set KYC locked status for given list of customers, with optional message. Any active KYC requests will be failed
   * @param {XcSetKYCLockedPayload} payload
   */
  putKycSetLocked(payload) {
    const req = this.putKycSetLockedSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set KYC locked status for given list of customers, with optional message. Any active KYC requests will be failed
   * @param {XcSetKYCLockedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putKycSetLockedSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/kyc/set-locked`,
      auth: true,
      method: 'putKycSetLocked',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Setup all mailchimp audience merge fields
   */
  putMailchimpSetupField() {
    const req = this.putMailchimpSetupFieldSpec();

    return this._makeRequest(req);
  }

  /**
   * Setup all mailchimp audience merge fields
   * @returns {XcXcalibraClientRequest}
   */
  putMailchimpSetupFieldSpec() {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/mailchimp/setup-fields`,
      auth: true,
      method: 'putMailchimpSetupField',
      router: 'admin',
    };
    return req;
  }

  /**
   * Sync all xcalibra customers with mailchimp
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {Promise<Array<XcCustomerInfo>>}
   */
  putMailchimpSyncCustomer(criteria) {
    const req = this.putMailchimpSyncCustomerSpec(criteria);

    return this._makeRequest(req);
  }

  /**
   * Sync all xcalibra customers with mailchimp
   * @param {XcCustomerInfosCriteria} criteria
   * @returns {XcXcalibraClientRequest}
   */
  putMailchimpSyncCustomerSpec(criteria) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/mailchimp/sync-customers`,
      auth: true,
      method: 'putMailchimpSyncCustomer',
      router: 'admin',
      query: criteria,
    };
    return req;
  }

  /**
   * Approve user nickname change
   * @param {Array<string>} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putNicknameApprove(payload) {
    const req = this.putNicknameApproveSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Approve user nickname change
   * @param {Array<string>} payload
   * @returns {XcXcalibraClientRequest}
   */
  putNicknameApproveSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/nickname/approve`,
      auth: true,
      method: 'putNicknameApprove',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Decline user nickname change
   * @param {XcDeclineNicknamePayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putNicknameDecline(payload) {
    const req = this.putNicknameDeclineSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Decline user nickname change
   * @param {XcDeclineNicknamePayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putNicknameDeclineSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/nickname/decline`,
      auth: true,
      method: 'putNicknameDecline',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Force phone number verification for given list of users
   * @param {XcForceSetPhoneVerifiedPayload} payload
   */
  putPhoneVerificationForceVerify(payload) {
    const req = this.putPhoneVerificationForceVerifySpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Force phone number verification for given list of users
   * @param {XcForceSetPhoneVerifiedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putPhoneVerificationForceVerifySpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/phone-verification/force-verify`,
      auth: true,
      method: 'putPhoneVerificationForceVerify',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Ban all supplied users, with the same given reason
   * @param {XcTrollboxBanPayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putTrollboxBan(payload) {
    const req = this.putTrollboxBanSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Ban all supplied users, with the same given reason
   * @param {XcTrollboxBanPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putTrollboxBanSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/trollbox/ban`,
      auth: true,
      method: 'putTrollboxBan',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Flag trollbox messages
   * @param {XcTrollboxFlagMessagesPayload} payload
   * @returns {Promise<Array<XcTrollboxMessage>>}
   */
  putTrollboxFlag(payload) {
    const req = this.putTrollboxFlagSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Flag trollbox messages
   * @param {XcTrollboxFlagMessagesPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putTrollboxFlagSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/trollbox/flag`,
      auth: true,
      method: 'putTrollboxFlag',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Set trollbox profanity confguration
   * @param {XcTrollboxProfanityConfig} payload
   * @returns {Promise<XcTrollboxProfanityConfig>}
   */
  putTrollboxProfanityConfig(payload) {
    const req = this.putTrollboxProfanityConfigSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Set trollbox profanity confguration
   * @param {XcTrollboxProfanityConfig} payload
   * @returns {XcXcalibraClientRequest}
   */
  putTrollboxProfanityConfigSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/trollbox/profanity-config`,
      auth: true,
      method: 'putTrollboxProfanityConfig',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Remove ban from all supplied users
   * @param {XcUsersPayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putTrollboxRemoveBan(payload) {
    const req = this.putTrollboxRemoveBanSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Remove ban from all supplied users
   * @param {XcUsersPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putTrollboxRemoveBanSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/trollbox/remove-ban`,
      auth: true,
      method: 'putTrollboxRemoveBan',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Remove flag from a trollbox message
   * @param {XcTrollboxMessagesPayload} payload
   * @returns {Promise<Array<XcTrollboxMessage>>}
   */
  putTrollboxRemoveFlag(payload) {
    const req = this.putTrollboxRemoveFlagSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Remove flag from a trollbox message
   * @param {XcTrollboxMessagesPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putTrollboxRemoveFlagSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/trollbox/remove-flag`,
      auth: true,
      method: 'putTrollboxRemoveFlag',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Update an existing user
   * @param {string} userId
   * @param {XcUserPayload} payload
   * @returns {Promise<XcUser>}
   */
  putUser(userId, payload) {
    const req = this.putUserSpec(userId, payload);

    return this._makeRequest(req);
  }

  /**
   * Update an existing user
   * @param {string} userId
   * @param {XcUserPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putUserSpec(userId, payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/users/${userId}`,
      auth: true,
      method: 'putUser',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Alter suspensions of multiple users. Suspend or unsuspend.
   * @param {XcSetUsersSuspendedPayload} payload
   * @returns {Promise<Array<XcUser>>}
   */
  putUsersSuspension(payload) {
    const req = this.putUsersSuspensionSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Alter suspensions of multiple users. Suspend or unsuspend.
   * @param {XcSetUsersSuspendedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putUsersSuspensionSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/users/suspensions`,
      auth: true,
      method: 'putUsersSuspension',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Perform wallet audits against the given list of user id-s. If not provided, audit all wallets
   * @param {XcAuditWalletsPayload} payload
   */
  putWalletsAudit(payload) {
    const req = this.putWalletsAuditSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Perform wallet audits against the given list of user id-s. If not provided, audit all wallets
   * @param {XcAuditWalletsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putWalletsAuditSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/wallets/audit`,
      auth: true,
      method: 'putWalletsAudit',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Load wallets into memory. Operates on a list of user id-s or all users. Optionally can reload wallets that are already loaded.
   * @param {XcLoadWalletsPayload} payload
   */
  putWalletsLoad(payload) {
    const req = this.putWalletsLoadSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Load wallets into memory. Operates on a list of user id-s or all users. Optionally can reload wallets that are already loaded.
   * @param {XcLoadWalletsPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putWalletsLoadSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/wallets/load`,
      auth: true,
      method: 'putWalletsLoad',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }

  /**
   * Mark withdrawals as approved or not approved
   * @param {XcSetWithdrawalApprovedPayload} payload
   * @returns {Promise<Array<XcWithdrawal>>}
   */
  putWithdrawalsSetApproved(payload) {
    const req = this.putWithdrawalsSetApprovedSpec(payload);

    return this._makeRequest(req);
  }

  /**
   * Mark withdrawals as approved or not approved
   * @param {XcSetWithdrawalApprovedPayload} payload
   * @returns {XcXcalibraClientRequest}
   */
  putWithdrawalsSetApprovedSpec(payload) {
    const req = {
      verb: 'put',
      endpoint: `/api/admin/v1/withdrawals/set-approved`,
      auth: true,
      method: 'putWithdrawalsSetApproved',
      router: 'admin',
      content_type: 'json',
      body: payload,
    };
    return req;
  }
}
