import './TrollboxNicknameWidget.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import IconButton from '../../widgets/interactive/IconButton';

const cn = classNamer('TrollboxNicknameWidget');

export default class TrollboxNicknameWidget extends ConnectedComponent {
  static propTypes = {
    state: PropTypes.object.isRequired,
    approvingKey: PropTypes.string.isRequired,
    decliningKey: PropTypes.string.isRequired,
    reasonKey: PropTypes.string.isRequired,
  };

  /**
   * Filter selection, filtering only items with requested nickname
   */
  filterSelection = () => {
    return Object.values(
      this.props.state.selection.reduce((res, index) => {
        const item = this.props.state.data.items[index];

        if (item.requested_nickname) {
          const userId = item.user_id || item.id;
          if (!res[userId]) {
            res[userId] = index;
          }
        }

        return res;
      }, {})
    );
  };

  approveSelectedUsers = () => {
    this.props.state.setState({
      [this.props.approvingKey]: this.filterSelection(),
    });
  };

  declineSelectedUsers = () => {
    this.props.state.setState({
      [this.props.decliningKey]: this.filterSelection(),
    });
  };

  render() {
    const { state, reasonKey } = this.props;
    return (
      <Expander title="Approve nickname" memoryKey={cn('nickname')}>
        <Form>
          <div className={cn('form')}>
            <div className="mb-3">
              <Input
                className={cn('reason-input')}
                type="textarea"
                value={state.declineReason}
                onChange={e => state.setState({ [reasonKey]: e.target.value })}
                placeholder="Reason"
                title="State why you are declining nickname change"
              />
            </div>
            <div className="d-flex mt-3">
              <IconButton
                title="Approve"
                onClick={this.approveSelectedUsers}
                color={FLAVORS.success}
                className="flex-fill ml-1"
              >
                Approve
              </IconButton>
              <IconButton
                title="Decline"
                onClick={this.declineSelectedUsers}
                color={FLAVORS.danger}
                className="flex-fill ml-1"
                disabled={!state.declineReason}
              >
                Decline
              </IconButton>
            </div>
          </div>
        </Form>
      </Expander>
    );
  }
}
