import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';

const cn = classNamer('DownloadButton');

class DownloadReportButton extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    getCSV: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  download = getDownload => {
    this.promiseOrToast(getDownload()).then(
      /** XcCSVDownload */ download => {
        const url = this.container.clientSpec.getCsvDownload(download.id).url;
        window.location.href = url;
      }
    );
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const className = classes(cn(), this.props.className);

    return (
      <div className={className}>
        <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggle}>
          <DropdownToggle caret color={FLAVORS.secondary}>
            <AppIcon icon={ICONS.download} className="mr-1" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => this.download(this.props.getCSV)}>CSV</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default withRouter(DownloadReportButton);
