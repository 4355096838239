import React from 'react';
import { Route, Switch } from 'react-router';

import { KYC_TRACKS } from '../../lib/backend';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import { RouteNotFound } from '../infrastructure/RouteNotFound';
import StickyRedirect from '../infrastructure/StickyRedirect';
import Breadcrumbed from '../layout/Breadcrumbed';
import SubPager from '../layout/SubPager';
import CustomerEditPage from './CustomerEditPage';
import CustomersPage from './CustomersPage';
import MicropaymentEditPage from './MicropaymentEditPage';
import MicropaymentsPage from './MicropaymentsPage';
import PhoneVerificationsPage from './PhoneVerificationsPage';
import UserAccountsPage from './UserAccountsPage';
import UserBotEditPage from './UserBotEditPage';
import UserBotsPage from './UserBotsPage';
import UserEditPage from './UserEditPage';
import UserInstrumentConfigsPage from './UserInstrumentConfigsPage';
import UserMarketConfigsPage from './UserMarketConfigsPage';
import UserSecurityEventsPage from './UserSecurityEventsPage';
import UserSessionsPage from './UserSessionsPage';

class UsersRouter extends ConnectedComponent {
  render() {
    return (
      <div className="UsersRouter">
        <Breadcrumbed link={SECTIONS.users}>
          <SubPager links={SECTIONS.users.subsections} />
          <Switch>
            <Route
              path={routes.USERS}
              render={() => <StickyRedirect routes={SECTIONS.users.subsections} />}
              exact
            />

            <Route
              path={routes.USERS_ACCOUNTS}
              render={() => (
                <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_ACCOUNTS)}>
                  <Switch>
                    <Route path={routes.USERS_NEW} component={UserEditPage} />
                    <Route path={routes.USERS_EDIT} component={UserEditPage} />
                    <Route component={UserAccountsPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.CUSTOMERS}
              render={() => (
                <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.CUSTOMERS)}>
                  <Switch>
                    <Route
                      path={routes.CUSTOMERS_EDIT}
                      render={routeProps => (
                        <CustomerEditPage {...routeProps} kycTrack={KYC_TRACKS.base} />
                      )}
                    />
                    <Route
                      render={() => (
                        <CustomersPage kycTrack={KYC_TRACKS.base} key={KYC_TRACKS.base} />
                      )}
                    />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.VQF_CUSTOMERS}
              render={() => (
                <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.VQF_CUSTOMERS)}>
                  <Switch>
                    <Route
                      path={routes.VQF_CUSTOMERS_MICROPAYMENTS_NEW}
                      component={MicropaymentEditPage}
                    />
                    <Route
                      path={routes.VQF_CUSTOMERS_MICROPAYMENTS_EDIT}
                      component={MicropaymentEditPage}
                    />
                    <Route
                      path={routes.VQF_CUSTOMERS_EDIT}
                      render={routeProps => (
                        <CustomerEditPage {...routeProps} kycTrack={KYC_TRACKS.vqf} />
                      )}
                    />
                    <Route
                      render={() => (
                        <CustomersPage kycTrack={KYC_TRACKS.vqf} key={KYC_TRACKS.vqf} />
                      )}
                    />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route
              path={routes.MICROPAYMENTS}
              render={() => (
                <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.MICROPAYMENTS)}>
                  <Switch>
                    <Route path={routes.MICROPAYMENTS_NEW} component={MicropaymentEditPage} />
                    <Route path={routes.MICROPAYMENTS_EDIT} component={MicropaymentEditPage} />
                    <Route component={MicropaymentsPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route path={routes.USERS_SESSIONS} component={UserSessionsPage} exact />
            <Route path={routes.USERS_MARKET_CONFIGS} component={UserMarketConfigsPage} exact />
            <Route
              path={routes.USERS_INSTRUMENT_CONFIGS}
              component={UserInstrumentConfigsPage}
              exact
            />

            <Route
              path={routes.USERS_BOTS}
              render={() => (
                <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_BOTS)}>
                  <Switch>
                    <Route path={routes.USERS_BOTS_EDIT} component={UserBotEditPage} />
                    <Route path={routes.USERS_BOTS_NEW} component={UserBotEditPage} />
                    <Route component={UserBotsPage} />
                  </Switch>
                </Breadcrumbed>
              )}
            />

            <Route path={routes.USERS_SECURITY_EVENTS} component={UserSecurityEventsPage} />

            <Route path={routes.USERS_PHONE_VERIFICATIONS} component={PhoneVerificationsPage} />

            <RouteNotFound />
          </Switch>
        </Breadcrumbed>
      </div>
    );
  }
}

export default UsersRouter;
