import './SettingsManagedConfigsPage.css';

import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label } from 'reactstrap';

import { MANAGED_CONFIG_KEYS_LIST } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes, flattenObject, nestPrefixes, objectKeys } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import BoundCryptoPicker from '../widgets/bound/BoundCryptoPicker';
import BoundFiatPicker from '../widgets/bound/BoundFiatPicker';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';

const cn = classNamer('SettingsManagedConfigsPage');

class SettingsManagedConfigsPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      nested: null,
      error: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBrokerConfig()).then(data => {
      this.setState({ data: flattenObject(data), nested: data });
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const data = nestPrefixes(this.state.data, MANAGED_CONFIG_KEYS_LIST);
    return this.promiseOrSetError(this.container.client.putBrokerConfig(data)).then(() => {
      this.setState({ error: false }, () =>
        toast('Updated managed config', {
          type: 'success',
        })
      );
    });
  };

  render() {
    const keys = objectKeys(this.state.data);
    return (
      <PageLayout className={classes(cn(), 'container-fluid', 'px-5')} above="Managed settings">
        <Breadcrumbed link={SECTIONS.settings.subsectionAtRoute(routes.SETTINGS_MANAGED)} />

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />
          {this.state.data && (
            <Form onSubmit={this.onSubmit}>
              <BoundCryptoPicker
                nest
                isMulti
                label="Supported crypto"
                state={this.boundState}
                name={keys.supported_crypto}
                helpText={`Client can buy/sell listed cryptocurrencies.`}
              />

              <BoundFiatPicker
                nest
                isMulti
                label="Supported fiat"
                state={this.boundState}
                name={keys.supported_fiat}
                helpText={`Client can buy/sell crypto in exchange for listed currencies.`}
              />

              <div>
                <h5 className="text-black-50">Buy orders</h5>
                <hr className="mt-1" />
              </div>
              <FormGroup className="pl-2">
                <div>
                  <Label for={keys.payment_limit_min_per_order}>Minimum per order</Label>
                  <div className="d-flex">
                    <BoundFormInput
                      nest
                      className="w-50"
                      state={this.boundState}
                      name={keys.buy_order_min_payment_quantity}
                    />
                    <BoundFiatPicker
                      nest
                      className="w-25 ml-3"
                      state={this.boundState}
                      name={keys.buy_order_payment_instrument}
                    />
                  </div>
                </div>
                <FormGroup>
                  <Label for={keys.buy_order_fee_pct}>Fee</Label>
                  <div className="w-100 d-flex">
                    <div className="d-inline w-50">
                      <BoundFormInput
                        nest
                        appendAddon="%"
                        state={this.boundState}
                        name={keys.buy_order_fee_pct}
                      />
                      <div className="hr-text my-2" data-content="OR" />
                      <BoundFormInput
                        nest
                        appendAddon={this.state.data[keys.buy_order_payment_instrument]}
                        state={this.boundState}
                        name={keys.buy_order_fee_fixed}
                      />
                    </div>
                    <div className="d-inline w-50 px-3 text-secondary">
                      We will compare fiat amount based on percentage fee to fixed fee value and
                      take higher value as a platform fee.
                    </div>
                  </div>
                </FormGroup>
              </FormGroup>
              <div>
                <h5 className="text-black-50">Sell orders</h5>
                <hr className="mt-1" />
              </div>
              <FormGroup className="pl-2">
                <div>
                  <Label for={keys.sell_order_min_payout_quantity}>
                    Minimum fiat value of instrument quantity
                  </Label>
                  <div className="d-flex">
                    <BoundFormInput
                      nest
                      className="w-50"
                      state={this.boundState}
                      name={keys.sell_order_min_payout_quantity}
                    />
                    <BoundFiatPicker
                      nest
                      className="w-25 ml-3"
                      state={this.boundState}
                      name={keys.sell_order_payout_instrument}
                    />
                  </div>
                  <span className="text-secondary">
                    E.g. if configured 20,000 USD, and 1 BTC = 10,000 USD, then client must be
                    willing to sell >= 2 BTC
                  </span>
                </div>
                <FormGroup className="mt-2">
                  <Label for={keys.sell_order_fee_pct}>Fee</Label>
                  <div className="w-100 d-flex">
                    <div className="d-inline w-50">
                      <BoundFormInput
                        nest
                        appendAddon="%"
                        className=""
                        state={this.boundState}
                        name={keys.sell_order_fee_pct}
                      />
                      <div className="hr-text my-2" data-content="OR" />
                      <BoundFormInput
                        nest
                        appendAddon={this.state.data[keys.sell_order_payout_instrument]}
                        className=""
                        state={this.boundState}
                        name={keys.sell_order_fee_fixed}
                      />
                    </div>
                    <div className="d-inline w-50 px-3 text-secondary">
                      We will compare instrument quantity based on percentage fee to quantity based
                      on fixed fee value and take larger quantity as a platform fee.
                    </div>
                  </div>
                </FormGroup>
              </FormGroup>

              <IconButton color="success" icon={ICONS.save}>
                Save
              </IconButton>
            </Form>
          )}
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(SettingsManagedConfigsPage);
