import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import PageTitle from '../layout/PageTitle';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import Recaptcha from './Recaptcha';

class LoginPage extends ConnectedComponent {
  captcha = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: '',
      },
      disableButton: false,
      renderCaptcha: false,
      tfaToken: '',
      captchaRendered: false,
      captchaLoaded: false,
      captchaToken: null,
    };
  }

  setCaptcha() {
    return this.promiseOrSetError(
      this.container.auth.shouldAskCaptchaForLogin().then(shouldAskCaptcha => {
        if (this.mounted && shouldAskCaptcha) {
          this.setState({ renderCaptcha: true, disableButton: true });
        }
      })
    );
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.state.renderCaptcha) {
      this.setState({ disableButton: false });
    }

    this.setCaptcha();
  }

  onFormSubmit = e => {
    e.preventDefault();
    return this.promiseOrSetError(
      this.container.auth
        .logIn(
          this.state.data.email,
          this.state.data.password,
          this.state.tfaToken,
          this.state.captchaToken
        )
        .catch(errorResponse => {
          if (this.mounted) {
            switch (errorResponse.name) {
              case 'CaptchaRequiredError':
                this.setState({ renderCaptcha: true, disableButton: true });
                throw errorResponse;

              case 'CaptchaInvalidToken':
                this.setState({ captchaToken: null });
                throw errorResponse;

              case 'SecondFactorRequiredError':
                this.setState({ showTfaForm: true });
                throw errorResponse;

              default:
                this.setState({ renderCaptcha: false, captchaToken: null });
                throw errorResponse;
            }
          }
        })
    );
  };

  renderCaptcha = () => {
    if (this.captcha) {
      return this.captcha.current.renderExplicitely().then(() => {
        this.setState({ captchaRendered: true });
      });
    }

    return null;
  };

  shouldDisableButton() {
    return this.state.disableButton || !this.state.data.email || !this.state.data.password;
  }

  render() {
    return (
      <div className="container">
        <Breadcrumbed link={routes.LOGIN} title="Login" />
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <GridLayout above={<PageTitle title="Login" />}>
              <AjaxWrapper state={this.boundState}>
                <ErrorBox state={this.boundState} />
                <Form onSubmit={this.onFormSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Bind state={this.boundState} nest>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="something@xcalibra.com"
                      />
                    </Bind>
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Bind state={this.boundState} nest>
                      <Input type="password" name="password" id="password" />
                    </Bind>
                  </FormGroup>
                  {this.state.renderCaptcha && (
                    <Recaptcha
                      ref={this.captcha}
                      siteKey={this.container.settings.recaptcha_site_key}
                      type="checkbox"
                      onLoad={() => {
                        this.setState({ captchaLoaded: true }, () => this.renderCaptcha());
                      }}
                      onVerify={token => {
                        this.setState({ captchaToken: token, disableButton: false });
                      }}
                    />
                  )}
                  <IconButton
                    color="primary"
                    icon={ICONS.login_action}
                    disabled={this.shouldDisableButton()}
                  >
                    Log in
                  </IconButton>
                </Form>
              </AjaxWrapper>
            </GridLayout>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
