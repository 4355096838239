import './Card.css';

import PropTypes from 'prop-types';
import React from 'react';
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Card as RCard,
} from 'reactstrap';

import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('Card');

export const Card = ({ className, flavor, title, footer, children }) => {
  let needWrapper = true;
  React.Children.forEach(children, child => {
    if (child.type === CardHeader || child.type === CardFooter) {
      needWrapper = false;
    }
  });
  if (needWrapper) {
    children = <CardBody>{children}</CardBody>;
  }

  return (
    <div className={classes(cn(), flavor && cn(flavor), className)}>
      <RCard color={flavor} outline>
        {title && <CardHeader>{title}</CardHeader>}
        {children}
        {footer && <CardFooter>{footer}</CardFooter>}
      </RCard>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  flavor: PropTypes.string,
  footer: PropTypes.any,
};

export { CardTitle, CardHeader, CardSubtitle, CardText, CardBody, CardFooter };
