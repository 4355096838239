import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import { BOT_PERMISSIONS } from '../../lib/backend';
import { BOT_KEYS, BOT_PAYLOAD_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { botDisplayName } from '../../lib/selectors';
import { withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import BoundCheckbox from '../widgets/bound/BoundCheckbox';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import EnumFieldset from '../widgets/interactive/EnumFieldset';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import UserPicker from './widgets/UserPicker';

const botApiKey = props => props.match.params.apiKey || null;

class UserBotEditPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type {XcAdminBotPayload} */
      data: {
        bot_permissions: Object.keys(BOT_PERMISSIONS),
        disabled: false,
        suspended: false,
        description: null,
      },

      /** @type {XcBot} */
      readOnlyData: null,

      waiting: !!botApiKey(props),
    };
  }

  get apiKey() {
    return botApiKey(this.props);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (botApiKey(prevProps) !== this.apiKey) {
      this.loadData();
    }
  }

  loadData() {
    if (!this.apiKey) {
      return;
    }

    this.promiseOrSetError(
      Promise.all([
        this.container.client.getBot(this.apiKey),
        this.container.auth.isSuperAdmin
          ? this.container.client.getBotSignatureSecret(this.apiKey)
          : null,
      ])
    ).then(([/** XcBot */ bot, signature_secret]) => {
      /** @type XcAdminBotPayload */
      const data = {
        cidr: bot.cidr,
        user_id: bot.user_id,
        bot_permissions: bot.bot_permissions,
        disabled: !!bot.disabled_at,
        suspended: !!bot.suspended_at,
        description: bot.description,
      };

      const readOnlyData = {
        signature_secret,
        api_key: bot.api_key,
      };

      this.setState({ data, readOnlyData });
    });
  }

  update = (prop, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [prop]: value,
      },
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const promise = this.apiKey
      ? this.container.client.putBot(this.apiKey, this.state.data)
      : this.container.client.postBot(this.state.data);

    return this.promiseOrSetError(promise).then(bot => {
      toast.success(
        this.apiKey
          ? `Bot "${botDisplayName(this.apiKey)}" updated`
          : `Bot "${botDisplayName(bot)}" created`
      );

      this.props.history.pushWithQuery(routes.USERS_BOTS);
    });
  };

  render() {
    const title = this.apiKey ? `Edit "${botDisplayName(this.apiKey)}"` : `Create new bot`;
    return (
      <GridLayout className="container" above={title}>
        <Breadcrumbed title="Edit" link={routes.usersBotsEdit(this.apiKey)} />
        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />

          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label for="user_id">User who owns the bot</Label>
              <UserPicker
                inputId="user_id"
                selectedId={this.state.data.user_id}
                onChange={id => this.update('user_id', id)}
              />
            </FormGroup>

            {this.state.readOnlyData && (
              <div>
                <FormGroup>
                  <Label for={BOT_KEYS.api_key}>API key</Label>
                  <Input type="text" readOnly value={this.state.readOnlyData.api_key} />
                </FormGroup>

                {this.container.auth.isSuperAdmin && (
                  <FormGroup>
                    <Label for={BOT_KEYS.api_key}>Signature secret</Label>
                    <Input type="text" readOnly value={this.state.readOnlyData.signature_secret} />
                  </FormGroup>
                )}
              </div>
            )}

            <BoundFormInput
              label="CIDR filter"
              name={BOT_PAYLOAD_KEYS.cidr}
              state={this.boundState}
              nest
              placeholder="0.0.0.0/0"
            />

            <BoundFormInput
              type="textarea"
              label="Description"
              name={BOT_PAYLOAD_KEYS.description}
              state={this.boundState}
              nest
            />

            <Bind state={this.boundState} name={BOT_PAYLOAD_KEYS.bot_permissions} nest>
              <EnumFieldset label="Permissions" values={BOT_PERMISSIONS} multiple />
            </Bind>

            <FormGroup>
              <Label>Master switches</Label>
              <div className="mb-2">
                <BoundCheckbox
                  state={this.boundState}
                  nest
                  name={BOT_PAYLOAD_KEYS.disabled}
                  label="Disabled"
                  helpText="The bot will not be able to make any requests. This is customer-controlled state, the owner will be able to reverse this"
                />
              </div>

              <div className="mb-4">
                <BoundCheckbox
                  state={this.boundState}
                  nest
                  name={BOT_PAYLOAD_KEYS.suspended}
                  label="Suspended"
                  helpText="This acts the same as disabling the bot, except only admins can change it"
                />
              </div>
            </FormGroup>

            <IconButton color="success" className="mr-2" icon={ICONS.save}>
              Save
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, routes.USERS_BOTS)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default withRouter(UserBotEditPage);
