import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Form, FormGroup, Label } from 'reactstrap';

import {
  ESTIMATOR_CONFIG_KEYS_LIST,
  ESTIMATOR_SETTINGS_PER_INSTRUMENT_KEYS_LIST,
} from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes, flattenObject, nestPrefixes, objectKeys } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import BoundPriceEstimatorInstrumentConfig from './widgets/BoundPriceEstimatorInstrumentConfig';

const cn = classNamer('SettingsPriceEstimatorConfigsPage');

class SettingsPriceEstimatorConfigsPage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      nested: null,
      error: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBrokerPriceEstimatorConfig()).then(data => {
      this.setState({ data: flattenObject(data), nested: data });
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const data = {
      settings_per_instrument: nestPrefixes(
        nestPrefixes(this.state.data, ESTIMATOR_CONFIG_KEYS_LIST).settings_per_instrument,
        ESTIMATOR_SETTINGS_PER_INSTRUMENT_KEYS_LIST
      ),
    };

    this.promiseOrSetError(this.container.client.putBrokerPriceEstimatorConfig(data)).then(() => {
      this.setState({ error: false }, () =>
        toast('Updated price estimator config', {
          type: 'success',
        })
      );
    });
  };

  renderSettingsPerInstrument = keys => {
    if (!this.state.nested || !this.state.nested.settings_per_instrument) {
      return null;
    }

    return Object.keys(this.state.nested.settings_per_instrument).map(instrument => {
      return (
        <BoundPriceEstimatorInstrumentConfig
          key={instrument}
          keys={keys}
          state={this.boundState}
          instrument={instrument}
        />
      );
    });
  };

  render() {
    const keys = objectKeys(this.state.data);

    return (
      <PageLayout
        className={classes(cn(), 'container-fluid', 'px-5')}
        above="Price estimator settings"
      >
        <Breadcrumbed link={SECTIONS.settings.subsectionAtRoute(routes.SETTINGS_PRICE_ESTIMATOR)} />

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />
          <div className="mb-4">
            <div>
              <span className="font-weight-bold">Market depth limit</span> -{' '}
              <span>take configured number of order book levels or full order book</span>
            </div>

            <div>
              <span className="font-weight-bold">Max price</span> -{' '}
              <span>stop and fill remaining quantities at this price</span>
            </div>
            <div>
              <span className="font-weight-bold">Partner provided</span> -{' '}
              <span>{`take partner exchange order book`}</span>
            </div>
          </div>
          <Form onSubmit={this.onSubmit}>
            <FormGroup className="d-flex flex-column align-items-start">
              <Label className="font-weight-bold">Settings per instrument</Label>
              <FormGroup className="d-flex flex-wrap">
                {this.renderSettingsPerInstrument(keys)}
              </FormGroup>
            </FormGroup>

            <IconButton color="success" icon={ICONS.save}>
              Save
            </IconButton>
          </Form>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(SettingsPriceEstimatorConfigsPage);
