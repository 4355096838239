import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { HOUR, USER_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import UserLabel from '../users/widgets/UserLabel';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import NicknameStatus from './widgets/NicknameStatus';
import TrollboxBanWidget from './widgets/TrollboxBanWidget';
import TrollboxNicknameWidget from './widgets/TrollboxNicknameWidget';

const COLUMNS = [
  new DataTableColumn(USER_KEYS.id, 'ID'),
  new DataTableColumn(USER_KEYS.email, 'Email'),
  new DataTableColumn(USER_KEYS.language, 'Language'),
  new DataTableColumn(USER_KEYS.trollbox_banned_at, 'Banned'),
  new DataTableColumn(USER_KEYS.trollbox_ban_expires_at, 'Expires'),
  new DataTableColumn(USER_KEYS.trollbox_ban_reason, 'Reason'),
  new DataTableColumn(USER_KEYS.created_at, 'Registered'),
  new DataTableColumn(USER_KEYS.nickname, 'Current'),
  new DataTableColumn(USER_KEYS.requested_nickname, 'Requested'),
  new DataTableColumn(USER_KEYS.nickname_updated_at, 'Changed'),
  new DataTableColumn(
    [USER_KEYS.nickname_decline_reason, USER_KEYS.requested_nickname],
    'Change status',
    user => <NicknameStatus user={user} />
  ),
];

const GROUPS = [new DataTableGroup('Nickname', 7, 10)];

const cn = classNamer('TrollboxUsersPage');

class TrollboxUsersPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],

      banning: null,
      banReason: '',
      duration: '',
      removingBan: null,

      approving: null,
      declining: null,
      declineReason: '',

      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getUsers(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  confirmBan = selection => {
    const { banReason, duration } = this.state;
    const ids = selection.map(index => this.state.data.items[index].id);
    const payload = {
      user_ids: ids,
      reason: banReason,
    };

    if (duration) {
      payload.expires_at = new Date(Date.now() + duration * HOUR).toISOString();
    }

    return this.promiseOrToast(this.container.client.putTrollboxBan(payload)).then(() => {
      toast.success(ids.length > 1 ? `Banned ${ids.length} users` : `Banned user ${ids[0]}`);
      this.setState({ banReason: '', duration: '' });
      this.loadData();
    });
  };

  confirmRemoveBan = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    return this.promiseOrToast(this.container.client.putTrollboxRemoveBan({ user_ids: ids })).then(
      () => {
        toast.success(
          ids.length > 1 ? `Removed ban for ${ids.length} users` : `Removed ban for user ${ids[0]}`
        );
        this.loadData();
      }
    );
  };

  confirmApproveNickname = selection => {
    const ids = selection.map(index => this.state.data.items[index].id);
    return this.promiseOrToast(this.container.client.putNicknameApprove(ids)).then(() => {
      toast.success(
        ids.length > 1
          ? `Approved nicknames for ${ids.length} users`
          : `Approved nickname for user ${ids[0]}`
      );
      this.loadData();
    });
  };

  confirmDeclineNickname = selection => {
    const { declineReason } = this.state;
    const ids = selection.map(index => this.state.data.items[index].id);
    const payload = {
      user_ids: ids,
      reason: declineReason,
    };

    return this.promiseOrToast(this.container.client.putNicknameDecline(payload)).then(() => {
      toast.success(
        ids.length > 1
          ? `Declined nicknames for ${ids.length} users`
          : `Declined nickname for user ${ids[0]}`
      );
      this.setState({ declineReason: '' });
      this.loadData();
    });
  };

  renderWidgets = selection => {
    const renderNicknameWidget = selection.some(i => this.state.data.items[i].requested_nickname);

    return (
      <>
        <TrollboxBanWidget
          state={this.boundState}
          banningKey="banning"
          removingBanKey="removingBan"
          reasonKey="banReason"
          durationKey="duration"
        />
        {renderNicknameWidget && (
          <TrollboxNicknameWidget
            state={this.boundState}
            approvingKey="approving"
            decliningKey="declining"
            reasonKey="declineReason"
          />
        )}
      </>
    );
  };

  renderSidebarOne = selectedIndex => {
    return <div>{this.renderWidgets([selectedIndex])}</div>;
  };

  renderSidebarMany = selection => {
    return <div>{this.renderWidgets(selection)}</div>;
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.trollbox.subsectionAtRoute(routes.TROLLBOX_USERS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmBan}
          typeSingular="user"
          actionText="put ban for"
          buttonActionText="Ban"
          actionKey="banning"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmRemoveBan}
          typeSingular="user"
          actionText="remove ban for"
          buttonActionText="Remove"
          actionKey="removingBan"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmApproveNickname}
          typeSingular="user"
          actionText="approve nickname for"
          buttonActionText="Approve"
          actionKey="approving"
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={UserLabel.formatter}
          onConfirmed={this.confirmDeclineNickname}
          typeSingular="user"
          actionText="decline nickname for"
          buttonActionText="Decline"
          actionKey="declining"
        />
      </PageLayout>
    );
  }
}

export default withRouter(TrollboxUsersPage);
