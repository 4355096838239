import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import Bind from '../../infrastructure/Bind';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import EnumSelect from '../interactive/EnumSelect';

const BoundFormSelect = ({ name, label, labels, state, nest, values }) => {
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Bind state={state} nest={nest}>
        <EnumSelect values={values} labels={labels} name={name} id={name} />
      </Bind>
    </FormGroup>
  );
};

BoundFormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labels: PropTypes.object,
  state: PropTypes.instanceOf(BoundState).isRequired,
  nest: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  values: PropTypes.object,
};

export default BoundFormSelect;
