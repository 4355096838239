import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';

import { XcPairConfigPayload } from '../../lib/backend';
import { PAIR_CONFIG_KEYS, defaultPairConfig } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import ConfigDisabledIndicator from '../configs/ConfigDisabledIndicator';
import ConfigViewToggler from '../configs/ConfigViewToggler';
import PairConfigEditor from '../configs/PairConfigEditor';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';

const cn = classNamer('SettingsMarketConfigsPage');

const BASE_CRITERIA = {
  view: ConfigViewToggler.VIEWS.effective,
};

const GROUPS = [new DataTableGroup('Trading', 1, 3)];

const FIXED_COLUMNS = [new DataTableColumn('subject', 'Pair')];

const makeColumns = view => {
  return FIXED_COLUMNS.concat([
    ConfigDisabledIndicator.dataTableColumn(view, PAIR_CONFIG_KEYS.disable_orders, 'Status', true),
    new DataTableColumn(`${view}.${PAIR_CONFIG_KEYS.trade_fee}`, 'Fee'),
    new DataTableColumn(`${view}.${PAIR_CONFIG_KEYS.min_order}`, 'Min'),
  ]);
};

class SettingsMarketConfigsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcConfigInfo[] */
      data: null,

      selection: [],

      globalConf: null,

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  _getProcessedData = createSelector(
    state => state.criteria,
    state => state.data,
    (criteria, data) => Criteria.apply(criteria, data)
  );

  /**
   * @type {XcConfigInfo[]}
   */
  get processedData() {
    return this._getProcessedData(this.state);
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria, BASE_CRITERIA)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    return this.promiseOrToast(
      Promise.all([
        this.container.client.getConfigPairsGlobal(),
        this.container.client.getConfigPairsMarket(),
      ])
    ).then(([globalConf, data]) => {
      this.setState({ data, globalConf, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  updateConfig = (pairs, value, successMessage) => {
    const payload = new XcPairConfigPayload({
      ...value,
      user_ids: null,
      pairs,
    });

    return this.promiseOrToast(this.container.client.putConfigPair(payload))
      .then(() => {
        toast.success(successMessage);
      })
      .finally(() => {
        this.loadData();
      });
  };

  renderGlobalSettings() {
    return (
      <Expander title="Global" memoryKey={cn('global')}>
        <PairConfigEditor
          data={this.state.globalConf || defaultPairConfig}
          withFees
          withDisabling
          withLimits
          callback={value => this.updateConfig(null, value, 'Global config updated')}
        />
      </Expander>
    );
  }

  renderSidebarNone = () => {
    return this.renderGlobalSettings();
  };

  renderSidebarOne = selectedIndex => {
    const config = this.processedData[selectedIndex];
    return (
      <div>
        {this.renderGlobalSettings()}

        <Expander title={`Edit "${config.subject}"`} memoryKey={cn('edit-single')}>
          <PairConfigEditor
            data={config.configured || defaultPairConfig}
            withFees
            withDisabling
            withLimits
            callback={value =>
              this.updateConfig([config.subject], value, `Config for ${config.subject} updated`)
            }
          />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = selection => {
    const pairs = selection.map(index => this.processedData[index].subject);
    return (
      <div>
        {this.renderGlobalSettings()}
        <Expander title={`Edit ${selection.length} configs`} memoryKey={cn('edit-many')}>
          <PairConfigEditor
            data={null}
            withFees
            withDisabling
            withLimits
            callback={value =>
              this.updateConfig(pairs, value, `Updated config for ${selection.length} pairs`)
            }
          />
        </Expander>
      </div>
    );
  };

  render() {
    const columns = makeColumns(this.state.criteria.view);
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.settings.subsectionAtRoute(routes.SETTINGS_MARKET)} />
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderNone={this.renderSidebarNone}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <ConfigViewToggler criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={columns}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.processedData}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(SettingsMarketConfigsPage);
