import './CriteriaKYCAdminActionPicker.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { classNamer, classes } from '../../../lib/tools';
import KYCAdminActionPicker from './KYCAdminActionPicker';

const cn = classNamer('CriteriaKYCAdminActionPicker');

const CriteriaKYCAdminActionPicker = ({
  criteria,
  className,
  label = 'Action needed:',
  targetField = 'admin_action',
  inputId = cn('id'),
  history,
  placeholder = '',
  ...rest
}) => {
  const handleChange = actionOrActions => {
    const nextCriteria = { ...criteria };
    if (actionOrActions && actionOrActions.length) {
      nextCriteria[targetField] = actionOrActions.join
        ? actionOrActions.join(',')
        : actionOrActions;
    } else {
      delete nextCriteria[targetField];
    }
    history.push(Criteria.href(history, nextCriteria));
  };

  className = classes(cn(), className, 'form-inline', 'always-form-inline');

  const value = criteria[targetField] ? criteria[targetField].split(',') : null;

  return (
    <div className={className}>
      <Label className="mr-2" for={inputId}>
        {label}
      </Label>
      <KYCAdminActionPicker
        isMulti
        selected={value}
        onChange={handleChange}
        inputId={inputId}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

CriteriaKYCAdminActionPicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  targetField: PropTypes.string,
  criteria: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
};

export default withRouter(CriteriaKYCAdminActionPicker);
