import './BoundMicropaymentAuditModal.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { XcKYCMicropaymentAuditResultPayload } from '../../../lib/backend';
import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import {
  customerAddress,
  customerName,
  customerScreeningProviderUrl,
  customerVerificationProviderUrl,
} from '../../../lib/selectors';
import { classNamer } from '../../../lib/tools';
import { BoundState } from '../../infrastructure/ConnectedComponent';
import ExpanderGroup from '../../layout/ExpanderGroup';
import GridLayout from '../../layout/GridLayout';
import BoundModal from '../../widgets/bound/BoundModal';
import ConstrainedMultilineText from '../../widgets/presentational/ConstrainedMultilineText';
import CountryLabel from '../../widgets/presentational/CountryLabel';
import ExternalResourceLink from '../../widgets/presentational/ExternalResourceLink';
import IconLabel from '../../widgets/presentational/IconLabel';
import ImagePopoutLink from '../../widgets/presentational/ImagePopoutLink';
import ObjectInfo from '../../widgets/presentational/ObjectInfo';
import PhoneNumberLink from '../../widgets/presentational/PhoneNumberLink';
import Time from '../../widgets/presentational/Time';
import MicropaymentDocumentList from './MicropaymentDocumentList';

const cn = classNamer('BoundMicropaymentAuditModal');

export default class BoundMicropaymentAuditModal extends React.Component {
  static propTypes = {
    state: PropTypes.instanceOf(BoundState).isRequired,
    /** @type {function(XcKYCMicropaymentAuditResultPayload)} */
    callback: PropTypes.func,
    sentinelKey: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      notes: null,
      notesValidationError: null,
    };
  }

  /**
   * @param {BoundModalRenderProps} renderProps
   * @param result
   */
  makeCallback(renderProps, result) {
    return () => {
      if (result === false && !this.state.notes) {
        this.setState({
          notesValidationError:
            'If you reject a micropayment, you must provide an explanation why.',
        });
        return;
      }

      this.setState({
        notesValidationError: null,
      });

      renderProps.callback(
        result === null
          ? null
          : new XcKYCMicropaymentAuditResultPayload({
              result,
              result_notes: this.state.notes,
            })
      );
    };
  }

  handleNotesChange = e => {
    this.setState({
      notes: e.target.value,
      notesValidationError: null,
    });
  };

  render() {
    const { state, sentinelKey = 'auditingCustomer', callback } = this.props;
    return (
      <BoundModal
        className={cn()}
        state={state}
        sentinelKey={sentinelKey}
        callback={callback}
        cancelResult={null}
        size={'lg'}
      >
        {renderProps => {
          /** @type {XcCustomerInfo} */
          const ci = renderProps.data;
          if (!ci) {
            return null;
          }

          const verificationProviderUrl = customerVerificationProviderUrl(ci);
          const screeningUrl = customerScreeningProviderUrl(ci);

          return (
            <>
              <ModalHeader toggle={this.makeCallback(renderProps, null)}>
                KYC3 micropayment audit {ci.kyc3_micropayment_id}-{ci.micropayment_audit_id}
              </ModalHeader>
              <ModalBody>
                <GridLayout autoCols className={cn('content')}>
                  <ExpanderGroup title="Micropayment">
                    <ObjectInfo
                      object={{
                        'Transaction id': (
                          <span className="text-monospace">{ci.micropayment_transaction_id}</span>
                        ),
                        'Payment name': ci.micropayment_full_name,
                        IBAN: <span className="text-monospace">{ci.micropayment_iban}</span>,
                        Swift: <span className="text-monospace">{ci.micropayment_swift}</span>,
                        'Payment notes': (
                          <ConstrainedMultilineText>
                            {ci.micropayment_notes}
                          </ConstrainedMultilineText>
                        ),
                        'Payment documentation': (
                          <MicropaymentDocumentList
                            documents={ci.micropayment_documents}
                            fallback={<em>There are no attached documents</em>}
                          />
                        ),
                      }}
                    />
                  </ExpanderGroup>
                  <ExpanderGroup title="Customer">
                    <ObjectInfo
                      object={{
                        Name: customerName(ci),
                        Nationality: <CountryLabel isoCode={ci.nationality} label />,
                        Address: customerAddress(ci, true),
                        'Birth date': <Time value={ci.dob} format={Time.FORMATS.date} utc />,
                        Phone: (
                          <PhoneNumberLink phone={ci.phone} verified={!!ci.phone_verification_id} />
                        ),
                        Links: (
                          <div className="mt-1">
                            {verificationProviderUrl && (
                              <ExternalResourceLink
                                icon={ICONS.link}
                                href={verificationProviderUrl}
                              >
                                Verification
                              </ExternalResourceLink>
                            )}
                            {screeningUrl && (
                              <ExternalResourceLink icon={ICONS.link} href={screeningUrl}>
                                Screening
                              </ExternalResourceLink>
                            )}
                          </div>
                        ),
                        Documentation: (
                          <div>
                            {[
                              [ci.verification_image_front_id, 'ID document front'],
                              [ci.verification_image_back_id, 'ID document back'],
                              [ci.verification_image_face_id, 'Face'],
                              [ci.kyc2_image_id, 'Utility bill'],
                            ].map(
                              ([imageId, title], index) =>
                                imageId && (
                                  <div className="my-1" key={index}>
                                    <ImagePopoutLink imageId={imageId}>{title}</ImagePopoutLink>
                                  </div>
                                )
                            )}
                          </div>
                        ),
                      }}
                    />
                  </ExpanderGroup>
                </GridLayout>
                <div className={cn('auditor-notes')}>
                  <FormGroup>
                    <Label for={cn('notes_input')}>Audit notes:</Label>
                    <Input
                      type="textarea"
                      id={cn('notes_input')}
                      onChange={this.handleNotesChange}
                      invalid={!!this.state.notesValidationError}
                      value={this.state.notes}
                    />
                    <div className="form-text ml-1">
                      {this.state.notesValidationError ? (
                        <IconLabel flavor={false} icon={ICONS.warn}>
                          {this.state.notesValidationError}
                        </IconLabel>
                      ) : (
                        <IconLabel flavor={FLAVORS.muted} wrap={true}>
                          These remarks will be visible to customer support agents, as they are
                          communicating with the customer.
                        </IconLabel>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <GridLayout autoCols gap={1} unresponsive>
                  <Button color={FLAVORS.success} onClick={this.makeCallback(renderProps, true)}>
                    Approve
                  </Button>
                  <Button color={FLAVORS.danger} onClick={this.makeCallback(renderProps, false)}>
                    Reject
                  </Button>
                  <Button color={FLAVORS.secondary} onClick={this.makeCallback(renderProps, null)}>
                    Cancel
                  </Button>
                </GridLayout>
              </ModalFooter>
            </>
          );
        }}
      </BoundModal>
    );
  }
}
