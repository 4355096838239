import PropTypes from 'prop-types';
import React from 'react';

import { AppIcon, ICONS } from '../../../lib/icons';

const DeviceLabel = ({ type, family, brand }) => {
  if (!family) {
    return null;
  }

  return (
    <span className="text-nowrap">
      <AppIcon icon={ICONS['ua_' + type] || ICONS.ua_other} />
      <span className="ml-2">
        {brand} {family}
      </span>
    </span>
  );
};

DeviceLabel.sessionInfoFormatter = (/** XcSessionInfo */ si) => (
  <DeviceLabel type={si.device_type} family={si.device_family} brand={si.device_brand} />
);

DeviceLabel.securityEventInfoFormatter = (/** XcSecurityEventInfo */ sei) => (
  <DeviceLabel type={sei.device_type} family={sei.device_family} brand={sei.device_brand} />
);

DeviceLabel.phoneVerificationInfoFormatter = (/** XcPhoneVerificationInfo */ pvi) => (
  <DeviceLabel
    type={pvi.ua_device_type}
    family={pvi.ua_device_family}
    brand={pvi.ua_device_brand}
  />
);

DeviceLabel.propTypes = {
  type: PropTypes.any,
  family: PropTypes.any,
  brand: PropTypes.any,
};

export default DeviceLabel;
