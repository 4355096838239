import React from 'react';
import { withRouter } from 'react-router';

import { INSTRUMENTS } from '../../lib/backend';
import { GROWTH_TIMELINE_REPORT_ITEM } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaInstrumentPicker from '../transactions/CriteriaInstrumentPicker';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import QuantityValue from '../widgets/presentational/QuantityValue';
import Time from '../widgets/presentational/Time';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const CONVERSION_INSTRUMENTS = [INSTRUMENTS.USD, INSTRUMENTS.EUR, INSTRUMENTS.BTC, INSTRUMENTS.RSD];

const BASE_CRITERIA = {
  sort_field: GROWTH_TIMELINE_REPORT_ITEM.timestamp,
  sort_direction: 'desc',
  instrument: INSTRUMENTS.USD,
  page_size: 200,
};

const quantityRenderer = key => (/** XcGrowthTimelineReportItem */ item, _, instrument) => (
  <div className="text-right">
    <QuantityValue value={item[key]} instrument={instrument} fixed />
  </div>
);

const COLUMNS = [
  new DataTableColumn(GROWTH_TIMELINE_REPORT_ITEM.timestamp, 'Timestamp', item => (
    <Time format={Time.FORMATS.date} value={item.timestamp} />
  )),
  new DataTableColumn(
    GROWTH_TIMELINE_REPORT_ITEM.volume,
    'New',
    quantityRenderer(GROWTH_TIMELINE_REPORT_ITEM.volume)
  ),
  new DataTableColumn(
    GROWTH_TIMELINE_REPORT_ITEM.accumulated_volume,
    'Total',
    quantityRenderer(GROWTH_TIMELINE_REPORT_ITEM.accumulated_volume)
  ),
  new DataTableColumn(
    GROWTH_TIMELINE_REPORT_ITEM.revenue,
    'New',
    quantityRenderer(GROWTH_TIMELINE_REPORT_ITEM.revenue)
  ),
  new DataTableColumn(
    GROWTH_TIMELINE_REPORT_ITEM.accumulated_revenue,
    'Total',
    quantityRenderer(GROWTH_TIMELINE_REPORT_ITEM.accumulated_revenue)
  ),
  new DataTableColumn(GROWTH_TIMELINE_REPORT_ITEM.participant_users, 'Participating'),
  new DataTableColumn(GROWTH_TIMELINE_REPORT_ITEM.new_users, 'New'),
  new DataTableColumn(GROWTH_TIMELINE_REPORT_ITEM.total_users, 'Total'),
];

const GROUPS = [
  new DataTableGroup('Volume', 1, 2),
  new DataTableGroup('Revenue', 3, 4),
  new DataTableGroup('Users', 5, 7),
];

const cn = classNamer('TradingGrowthTimelinePage');

class TradingGrowthTimelinePage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate() {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    return this.promiseOrToast(
      this.container.client.getReportsGrowthTimeline(this.state.criteria)
    ).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcGrowthTimelineReportItem} */
    const item = this.state.data.items[selectedIndex];
    return (
      <Expander title="Selected" memoryKey={cn('selected-info')}>
        <ObjectInfo object={item} />
      </Expander>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.trading.subsectionAtRoute(routes.TRADING_GROWTH_TIMELINE)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <div>
              <SelectionSidebar
                selection={this.state.selection}
                renderOne={this.renderSidebarOne}
                renderMany={() => {}}
              />
            </div>
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaInstrumentPicker
                    criteria={this.state.criteria}
                    label={'Converted to:'}
                    instruments={CONVERSION_INSTRUMENTS}
                    isClearable={false}
                  />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getReportsGrowthTimelineCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                renderArg={this.state.criteria.instrument}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TradingGrowthTimelinePage);
