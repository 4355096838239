import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';

import { routes } from '../../lib/routes';
import ConnectedComponent from './ConnectedComponent';

export default class AuthArea extends ConnectedComponent {
  static propTypes = {
    public: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
  };

  render() {
    const loggedIn = !!this.container.store.principal;

    if (this.props.public === true && loggedIn) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: this.container.history.location },
          }}
        />
      );
    }

    if (this.props.public === false && !loggedIn) {
      return (
        <Redirect
          to={{
            pathname: routes.LOGIN,
            state: { from: this.container.history.location },
          }}
        />
      );
    }

    return this.props.children;
  }
}
