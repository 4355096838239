import PropTypes from 'prop-types';
import React from 'react';

import { DOCUMENT_MIME_TYPE_VALUES, FLAVORS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import IconButton from '../widgets/interactive/IconButton';

// https://stackoverflow.com/questions/4328947/limit-file-format-when-using-input-type-file
const UPLOAD_ACCEPT = Object.keys(DOCUMENT_MIME_TYPE_VALUES)
  .map(type => '.' + type)
  .concat(Object.values(DOCUMENT_MIME_TYPE_VALUES))
  .join(',');

export default class DocumentUploadButton extends ConnectedComponent {
  static propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    onUpload: PropTypes.func.isRequired,
  };

  static defaultProps = {
    icon: ICONS.upload,
    color: FLAVORS.secondary,
  };

  uploadRef = React.createRef();

  handleUploadClick = () => {
    if (this.uploadRef.current) {
      // NOTE: This makes it trigger event even if the same file is selected again
      this.uploadRef.current.value = null;
      this.uploadRef.current.click();
    }
  };

  handleUploadFileChange = () => {
    if (!this.uploadRef.current) {
      return;
    }
    const file = this.uploadRef.current.files[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('document_file', file);
    return this.promiseOrToast(this.container.client.postDocument(formData)).then(document => {
      this.props.onUpload(document);
    });
  };

  render() {
    return (
      <IconButton color={this.props.color} icon={this.props.icon} onClick={this.handleUploadClick}>
        <input
          className="d-none"
          type="file"
          ref={this.uploadRef}
          onChange={this.handleUploadFileChange}
          accept={UPLOAD_ACCEPT}
        />
        {this.props.children}
      </IconButton>
    );
  }
}
