import crypto from 'crypto';

const STRING_POOLS = {
  lowercase: 'abcdefghijkmnopqrstuvwxyz',
  uppercase: 'ABCDEFGHJKLMNPQRSTUVWXYZ',
  digits: '123456789',
  special: '!@#$%^&*',
};

const RULES = {
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  digits: /[1-9]/,
  special: /[!@#$%^&*]/,
};

class PasswordGeneratorOptions {
  constructor(source) {
    this.length = 10;
    this.uppercase = false;
    this.digits = false;
    this.special = false;

    Object.assign(this, source);
  }
}

export default class PasswordGenerator {
  constructor(/** @type {PasswordGeneratorOptions} */ options) {
    this.options = new PasswordGeneratorOptions(options);
  }

  /**
   * Generates a random number
   * 0 (inclusive) and max (exclusive)
   */
  randomNumber(max) {
    let rand = crypto.randomBytes(1)[0];
    while (rand >= 256 - (256 % max)) {
      rand = crypto.randomBytes(1)[0];
    }
    return rand % max;
  }

  /**
   * Generates unique password
   */
  generate() {
    let password = '';
    let pool = STRING_POOLS.lowercase;

    if (this.options.uppercase) {
      pool += STRING_POOLS.uppercase;
    }

    if (this.options.digits) {
      pool += STRING_POOLS.digits;
    }

    if (this.options.special) {
      pool += STRING_POOLS.special;
    }

    for (let i = 0; i < this.options.length; i++) {
      password += pool[this.randomNumber(pool.length)];
    }

    // check if each rule applies to password
    const isValid = Object.keys(RULES).reduce((res, rule) => {
      if (!res) {
        return false;
      }

      if (!this[rule]) {
        return res;
      }

      return RULES[rule].test(password);
    }, true);

    if (!isValid) {
      return this.generate();
    }

    return password;
  }
}
