import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SORT_DIRECTIONS } from '../../lib/backend';
import { FLAVORS, MICROPAYMENT_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { micropaymentCustomerName } from '../../lib/selectors';
import { classNamer, classes, nestPrefixes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import ConstrainedMultilineText from '../widgets/presentational/ConstrainedMultilineText';
import CountryLabel from '../widgets/presentational/CountryLabel';
import KYCLevelIcon from '../widgets/presentational/KYCLevelIcon';
import NestedExpandableObjectInfo from '../widgets/presentational/NestedExpandableObjectInfo';
import QuantityValue from '../widgets/presentational/QuantityValue';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import MicropaymentAuditStatus from './widgets/MicropaymentAuditStatus';
import MicropaymentDocumentList from './widgets/MicropaymentDocumentList';
import MicropaymentOutcome from './widgets/MicropaymentOutcome';

const BASE_CRITERIA = {
  sort_field: MICROPAYMENT_INFO_KEYS.updated_at,
  sort_direction: SORT_DIRECTIONS.desc,
};

const COLUMNS = [
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.id, 'ID'),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.is_current, 'Current?'),

  new DataTableColumn(
    MICROPAYMENT_INFO_KEYS.transaction_id,
    'Transaction ID',
    /** XcKYCMicropaymentInfo */ kmi => <span className="text-monospace">{kmi.transaction_id}</span>
  ),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.reference_number, 'Reference'),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.full_name, 'Full name'),
  new DataTableColumn(
    MICROPAYMENT_INFO_KEYS.iban,
    'IBAN',
    /** XcKYCMicropaymentInfo */ kmi => <span className="text-monospace">{kmi.iban}</span>
  ),
  new DataTableColumn(
    MICROPAYMENT_INFO_KEYS.swift,
    'Swift',
    /** XcKYCMicropaymentInfo */ kmi => <span className="text-monospace">{kmi.swift}</span>
  ),
  new DataTableColumn(
    [MICROPAYMENT_INFO_KEYS.instrument, MICROPAYMENT_INFO_KEYS.quantity],
    'Amount',
    /** XcKYCMicropaymentInfo */ kmi => (
      <QuantityValue value={kmi.quantity} instrument={kmi.instrument} />
    )
  ),
  new DataTableColumn(
    MICROPAYMENT_INFO_KEYS.notes,
    'Notes',
    /** XcKYCMicropaymentInfo */ kmi => (
      <ConstrainedMultilineText>{kmi.notes}</ConstrainedMultilineText>
    )
  ),

  new DataTableColumn(MICROPAYMENT_INFO_KEYS.user_id, 'User id'),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.email, 'Email'),
  new DataTableColumn(
    MICROPAYMENT_INFO_KEYS.kyc_level_granted,
    { tooltip: 'KYC Level', title: 'L' },
    /** XcKYCMicropaymentInfo */ kmi => (
      <div className="text-center h5">
        <KYCLevelIcon level={kmi.kyc_level_granted} />
      </div>
    )
  ),
  new DataTableColumn(
    [
      MICROPAYMENT_INFO_KEYS.customer_first_name,
      MICROPAYMENT_INFO_KEYS.customer_middle_name,
      MICROPAYMENT_INFO_KEYS.customer_last_name,
    ],
    'Identity',
    /** XcKYCMicropaymentInfo */ kmi => (
      <span>
        <CountryLabel isoCode={kmi.customer_nationality} /> {micropaymentCustomerName(kmi)}
      </span>
    )
  ),

  new DataTableColumn(MICROPAYMENT_INFO_KEYS.audit_performed_at, 'Date'),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.audit_performed_by_user_id, 'Auditor'),
  new DataTableColumn(
    [MICROPAYMENT_INFO_KEYS.audit_result, MICROPAYMENT_INFO_KEYS.audit_result_notes],
    'Status',
    MicropaymentAuditStatus.kycMicropaymentInfoFormatter
  ),

  new DataTableColumn(MICROPAYMENT_INFO_KEYS.created_at, 'Created'),
  new DataTableColumn(MICROPAYMENT_INFO_KEYS.updated_at, 'Updated'),
  new DataTableColumn(
    [MICROPAYMENT_INFO_KEYS.processed_at, MICROPAYMENT_INFO_KEYS],
    'Outcome',
    MicropaymentOutcome.kycMicropaymentInfoFormatter
  ),
];

const GROUPS = [
  new DataTableGroup('Micropayment', 2, 8),
  new DataTableGroup('Customer', 9, 12),
  new DataTableGroup('Audit', 13, 15),
];

const cn = classNamer('MicropaymentsPage');

class MicropaymentsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],
      deleting: null,

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getKycMicropaymentsInfos(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  deleteSelectedMicropayment = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const id = this.state.data.items[selection[0]].id;
    return this.promiseOrToast(this.container.client.deleteKycMicropayment(id)).then(() => {
      toast.success(`Micropayment ${id} was deleted`);
      this.loadData();
    });
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcKYCMicropaymentInfo} */
    const kmi = this.state.data.items[selectedIndex];

    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title={'Edit micropayment'}
            color={FLAVORS.primary}
            to={routes.micropaymentsEdit(kmi.id)}
            icon={ICONS.edit}
            className="mr-2"
          >
            Edit
          </IconButton>

          <IconButton
            title={'Delete micropayment'}
            color={FLAVORS.danger}
            onClick={this.deleteSelectedMicropayment}
            icon={ICONS.delete}
            className="mr-2"
          >
            Delete
          </IconButton>
        </Expander>

        <NestedExpandableObjectInfo
          title="Details"
          memoryKey={cn('details')}
          object={{
            ...nestPrefixes(kmi, ['audit', 'customer'], 'micropayment'),
            documents: (
              <MicropaymentDocumentList
                documents={kmi.documents}
                fallback={
                  <div className="text-center">
                    <em>There are no attached documents</em>
                  </div>
                }
              />
            ),
          }}
          baseMemoryKey={cn('details')}
          keyBlacklistLookup={{ micropayment: ['documents'] }}
        />
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <IconButton
                    tag={Link}
                    color="success"
                    icon={ICONS.new}
                    to={routes.MICROPAYMENTS_NEW}
                  >
                    Add micropayment
                  </IconButton>
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getKycMicropaymentsInfosCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={
            /** XcKYCMicropaymentInfo */ kmi => `micropayment ${kmi.id} by ${kmi.user_id}`
          }
          onConfirmed={this.confirmDelete}
          typeSingular="micropayment"
        >
          <p>
            Any active audit for this micropayment will be cancelled, and micropayment removed as
            the active micropayment for its user.
          </p>
        </BoundDangerModal>
      </PageLayout>
    );
  }
}

export default withRouter(MicropaymentsPage);
