import './MailerInternalsPage.css';

import React from 'react';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import Bind from '../infrastructure/Bind';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import { Card } from '../widgets/presentational/Card';
import ErrorBox from '../widgets/presentational/ErrorBox';

const cn = classNamer('MailerInternalsPage');

class MailerInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  onSendMailSubmit = e => {
    e.preventDefault();

    return this.promiseOrSetError(this.container.client.postAppSendMail(this.state.data)).then(
      result => {
        toast.success(result.response);
      }
    );
  };

  renderSendMail() {
    return (
      <Card title="Send test mail">
        <ErrorBox state={this.boundState} />
        <Form onSubmit={this.onSendMailSubmit} className={cn('send-form')}>
          <FormGroup>
            <Label for="to">To</Label>
            <Bind state={this.boundState} nest>
              <Input type="email" name="to" id="to" placeholder="something@example.com" />
            </Bind>
          </FormGroup>

          <FormGroup>
            <Label for="subject">Subject</Label>
            <Bind state={this.boundState} nest>
              <Input type="text" name="subject" id="subject" placeholder="" />
            </Bind>
          </FormGroup>

          <FormGroup>
            <Label for="html">Html</Label>
            <Bind state={this.boundState} nest>
              <Input type="textarea" name="html" id="html" placeholder="" />
            </Bind>
          </FormGroup>

          <FormGroup>
            <Label for="html">Text</Label>
            <Bind state={this.boundState} nest>
              <Input type="textarea" name="text" id="text" placeholder="" />
            </Bind>
          </FormGroup>

          <IconButton color="primary" icon={ICONS.good}>
            Send
          </IconButton>
        </Form>
      </Card>
    );
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_MAILER)} />

        <AjaxWrapper state={this.boundState}>
          <div className="my-3">{this.renderSendMail()}</div>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default MailerInternalsPage;
