import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';

import { beautifyKey } from '../../../lib/tools';

const EnumSelect = ({ values, labels, value, empty, onChange, ...rest }) => {
  const options = [];

  if (empty !== undefined) {
    options.push(
      <option value={null} key="null">
        {empty === true ? '' : ''}
      </option>
    );
  }

  for (const key in values) {
    const label = labels ? labels[key] : beautifyKey(key);
    options.push(
      <option value={key} key={key}>
        {label}
      </option>
    );
  }

  const customOnChange =
    onChange &&
    (e => {
      let value = e.target.value;
      if (empty !== undefined && value === '') {
        // Cast to null
        value = null;
      }
      onChange(value);
    });

  return (
    <Input type="select" value={value || ''} onChange={customOnChange} {...rest}>
      {options}
    </Input>
  );
};

EnumSelect.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object.isRequired,
  labels: PropTypes.object,
  value: PropTypes.any,
  name: PropTypes.string,
  empty: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
};

export default EnumSelect;
