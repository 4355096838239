import { lodash } from './tools';

export const LOGGER_COLORS = {
  black: 'black',
  gray: 'gray',
  pale: 'pale',
  blue: 'blue',
  purple: 'purple',
  green: 'green',
};

export const LOGGER_COLOR_VALUES = {
  black: '#000000',
  gray: '#727272',
  pale: '#86a4b3',
  blue: '#5c6ac8',
  purple: '#8b69a6',
  green: '#4e9c4e',
};

function timestampTag() {
  const time = new Date();
  const hr = lodash.pad(time.getHours().toString(), 2);
  const min = lodash.pad(time.getMinutes().toString(), 2);
  const sec = lodash.pad(time.getSeconds().toString(), 2);
  const ms = lodash.pad(time.getMilliseconds().toString(), 3);
  return `[${hr}:${min}:${sec}.${ms}]`;
}

function colorTag(name) {
  return `color: ${LOGGER_COLOR_VALUES[name]}`;
}

export class Logger {
  constructor(enabled, nativeConsole) {
    this.enabled = enabled;
    this._nativeConsole = nativeConsole;
    this.prefix = null;
  }

  _logUsingMethod(method, parts) {
    const text = ['%c', timestampTag(), '%c'];
    const colorTags = [colorTag(LOGGER_COLORS.gray), colorTag(LOGGER_COLORS.black)];

    if (this.prefix) {
      text.push('%c', `[${this.prefix}]`, '%c');
      colorTags.push(colorTag(LOGGER_COLORS.pale), colorTag(LOGGER_COLORS.black));
    }

    const objects = [];
    for (const part of parts) {
      if (lodash.isString(part)) {
        if (LOGGER_COLORS[part]) {
          text.push('%c');
          colorTags.push(colorTag(part));
        } else {
          text.push(part);
        }
      } else {
        objects.push(part);
      }
    }

    this._nativeConsole[method](text.join(' '), ...colorTags, ...objects);
  }

  log(...parts) {
    if (!this.enabled) {
      return;
    }

    this._logUsingMethod('log', parts);
  }

  error(...parts) {
    if (!this.enabled) {
      return;
    }

    this._logUsingMethod('error', parts);
  }

  verbose(...parts) {
    if (!this.enabled) {
      return;
    }

    this._logUsingMethod('debug', parts);
  }

  /**
   * Log a group. Last argument must be a function, to be executed inside the group
   */
  group(...parts) {
    const fn = parts.pop();
    this._logUsingMethod('groupCollapsed', parts);
    fn(this);
    this._nativeConsole.groupEnd();
  }

  /**
   * Create a prefixed child of this logger
   */
  prefixed(prefix) {
    const child = new Logger(this.enabled, this._nativeConsole);
    child.prefix = prefix;
    return child;
  }
}
