import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FLAVORS, MANAGED_PARTNER_TRADE_KEYS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { routes } from '../../../lib/routes';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import SelectionSidebar from '../../layout/SelectionSidebar';
import BoundDangerModal from '../../widgets/bound/BoundDangerModal';
import IconButton from '../../widgets/interactive/IconButton';
import DataTable, { DataTableColumn, DataTableGroup } from '../../widgets/tables/DataTable';

const TRADE_COLUMNS = [
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.buy_instrument, 'Instrument'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.buy_quantity, 'Quantity'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.sell_instrument, 'Instrument'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.sell_quantity, 'Quantity'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.partner_exchange, 'Exchange'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.trade_id, 'Reference ID'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.fee_total, 'Fee'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.executed_at, 'Executed at'),
  new DataTableColumn(MANAGED_PARTNER_TRADE_KEYS.created_at, 'Created at'),
];

const TRADE_GROUPS = [
  new DataTableGroup('Buy', 1, 2),
  new DataTableGroup('Sell', 3, 4),
  new DataTableGroup('Partner', 5, 8),
];

const cn = classNamer('ManagedPartnerTradesTable');

export default class ManagedPartnerTradesTable extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      trades: {},
      selection: [],
      deleting: [],
    };
  }
  static propTypes = {
    order: PropTypes.object.isRequired,
  };

  deleteSelected = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const keys = selection.map(index => this.state.trades.items[index].id);
    const promise = this.container.client.deleteBrokerPartnerTrades(keys);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        keys.length > 1 ? `${keys.length} partner trades deleted` : `Partner trade deleted`
      );
      this.loadData();
    });
  };

  renderPartnerTrade = trade => {
    if (!trade) {
      return null;
    }
    return (
      <span>
        <strong>{trade.id}</strong> (part of order {trade.order_id})
      </span>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcManagedPartnerTrade} */
    const trade = this.state.trades.items[selectedIndex];
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title="Edit trade"
            color={FLAVORS.primary}
            to={routes.managedPartnerTradesEdit(trade.id)}
            icon={ICONS.edit}
            className="mr-2"
          >
            Edit
          </IconButton>
          <IconButton
            title="Delete trade"
            color={FLAVORS.danger}
            onClick={this.deleteSelected}
            icon={ICONS.delete}
            className="mr-2"
          >
            Delete
          </IconButton>
        </Expander>
      </div>
    );
  };

  renderSidebarMany = () => {
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            title="Delete trades"
            color="danger"
            onClick={this.deleteSelected}
            icon={ICONS.delete}
          >
            Delete
          </IconButton>
        </Expander>
      </div>
    );
  };

  loadData() {
    return this.promiseOrToast(
      this.container.client.getBrokerPartnerTrades({ order_id: this.props.order.id })
    ).then(trades => {
      this.setState({
        trades,
        selection: [],
        deleting: null,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { selection, trades } = this.state;
    const { order } = this.props;
    return (
      <>
        <div className="mt-3 p-2 bg-light">
          <h5 className="text-uppercase">Attached partner trades</h5>
          <div className="d-flex">
            <SelectionSidebar
              className="mt-3"
              selection={selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
              renderNone={() => (
                <Expander title="Actions">
                  <div className="d-flex">
                    <IconButton
                      tag={Link}
                      to={routes.managedPartnerTradesNew(order.id)}
                      className="flex-fill"
                      color={FLAVORS.success}
                      icon={ICONS.new}
                      disabled={!!(order.cancelled_at || order.failure_reason || !order.taken_at)}
                    >
                      Add trade
                    </IconButton>
                  </div>
                </Expander>
              )}
            />
            <DataTable
              className="ml-3"
              columns={TRADE_COLUMNS}
              groups={TRADE_GROUPS}
              data={trades}
              criteria={{}}
              selection={selection}
              onSelectionChanged={selection => this.setState({ selection })}
            />
          </div>
        </div>
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderPartnerTrade}
          onConfirmed={this.confirmDelete}
          typeSingular="partner trade"
          actionKey="deleting"
          dataKey="trades"
        />
      </>
    );
  }
}
