import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FLAVORS, MANAGED_PARTNER_TRANSFER_KEYS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { routes } from '../../../lib/routes';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import SelectionSidebar from '../../layout/SelectionSidebar';
import BoundDangerModal from '../../widgets/bound/BoundDangerModal';
import IconButton from '../../widgets/interactive/IconButton';
import DataTable, { DataTableColumn, DataTableGroup } from '../../widgets/tables/DataTable';

const TRANSFER_COLUMNS = [
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.type, 'Type'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.transaction_id, 'Transaction ID'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.target_address, 'Target address'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.instrument, 'Instrument'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.quantity, 'Quantity'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.partner_exchange, 'Exchange'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.partner_reference_id, 'Reference ID'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.fee, 'Fee'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.executed_at, 'Executed at'),
  new DataTableColumn(MANAGED_PARTNER_TRANSFER_KEYS.created_at, 'Created at'),
];

const TRANSFER_GROUPS = [new DataTableGroup('Partner', 6, 9)];

const cn = classNamer('ManagedPartnerTransfersTable');

export default class ManagedPartnerTransfersTable extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      transfers: {},
      selection: [],
      deleting: [],
    };
  }
  static propTypes = {
    order: PropTypes.object.isRequired,
  };

  deleteSelected = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const transfers = this.state.transfers;
    const keys = selection.map(index => transfers.items[index].id);
    const promise = this.container.client.deleteBrokerPartnerTransfers(keys);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        keys.length > 1 ? `${keys.length} partner transfers deleted` : `Partner transfer deleted`
      );

      return this.loadData();
    });
  };

  renderPartnerTransfer = transfer => {
    if (!transfer) {
      return null;
    }
    return (
      <span>
        <strong>{transfer.id}</strong> (part of order {transfer.order_id})
      </span>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcManagedPartnerTransfer} */
    const transfer = this.state.transfers.items[selectedIndex];

    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title="Edit transfer"
            color={FLAVORS.primary}
            to={routes.managedPartnerTransfersEdit(transfer.id)}
            icon={ICONS.edit}
            className="mr-2"
          >
            Edit
          </IconButton>
          <IconButton
            title="Delete transfer"
            color={FLAVORS.danger}
            onClick={this.deleteSelected}
            icon={ICONS.delete}
            className="mr-2"
          >
            Delete
          </IconButton>
        </Expander>
      </div>
    );
  };

  renderSidebarMany = () => {
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            title="Delete transfers"
            color="danger"
            onClick={this.deleteSelected}
            icon={ICONS.delete}
          >
            Delete
          </IconButton>
        </Expander>
      </div>
    );
  };

  loadData() {
    return this.promiseOrToast(
      this.container.client.getBrokerPartnerTransfers({ order_id: this.props.order.id })
    ).then(transfers => {
      this.setState({
        transfers,
        selection: [],
        deleting: null,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { selection, transfers } = this.state;
    const { order } = this.props;
    return (
      <>
        <div className="mt-3 p-2 bg-light">
          <h5 className="text-uppercase">Attached partner transfers</h5>
          <div className="d-flex">
            <SelectionSidebar
              className="mt-3"
              selection={selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
              renderNone={() => (
                <Expander title="Actions">
                  <div className="d-flex">
                    <IconButton
                      tag={Link}
                      to={routes.managedPartnerTransfersNew(order.id)}
                      className="flex-fill"
                      color={FLAVORS.success}
                      icon={ICONS.new}
                      disabled={!!(order.cancelled_at || order.failure_reason || !order.taken_at)}
                    >
                      Add transfer
                    </IconButton>
                  </div>
                </Expander>
              )}
            />
            <DataTable
              className="ml-3"
              columns={TRANSFER_COLUMNS}
              groups={TRANSFER_GROUPS}
              data={transfers}
              criteria={{}}
              selection={selection}
              onSelectionChanged={selection => this.setState({ selection })}
            />
          </div>
        </div>
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderPartnerTransfer}
          onConfirmed={this.confirmDelete}
          typeSingular="partner transfer"
          actionKey="deleting"
          dataKey="transfers"
        />
      </>
    );
  }
}
