import './Spinner.css';

import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ size }) => {
  const style = size ? { width: size, height: size } : null;
  return (
    <div className="Spinner" style={style}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
};

export default Spinner;
