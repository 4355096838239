import './SubPager.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { classNamer, classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';

const cn = classNamer('SubPager');

class SubPager extends ConnectedComponent {
  render() {
    let { className } = this.props;
    const { links, children } = this.props;
    className = classes(className, cn());

    const tabs = links.map((/** LinkDefinition */ link) => {
      if (link.permissions && !this.container.auth.hasPermission(link.permissions)) {
        return null;
      }

      const pathname = this.container.history.location.pathname;
      const isActive = link.route === '/' ? pathname === '/' : pathname.indexOf(link.route) === 0;

      return (
        <div className={classes(cn('tab'), isActive && cn('tab-active'))} key={link.route}>
          <Link to={link.route}>
            <span>{link.title}</span>
          </Link>
        </div>
      );
    });

    return (
      <div className={className}>
        {tabs}
        {children}
      </div>
    );
  }
}

SubPager.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array.isRequired,
};

export default SubPager;
