import React from 'react';
import { withRouter } from 'react-router';

import { SORT_DIRECTIONS } from '../../lib/backend';
import { SECURITY_EVENT_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import ExpanderGroup from '../layout/ExpanderGroup';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import CountryLabel from '../widgets/presentational/CountryLabel';
import DeviceLabel from '../widgets/presentational/DeviceLabel';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaUserPicker from './widgets/CriteriaUserPicker';

const BASE_CRITERIA = {
  sort_field: SECURITY_EVENT_INFO_KEYS.timestamp,
  sort_direction: SORT_DIRECTIONS.desc,
  user_id: undefined,
};

const COLUMNS = [
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.id, 'ID'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.event_type, 'Event Type'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.user_id, 'User ID'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.email, 'Email'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.ip_address, 'IP'),
  new DataTableColumn(
    SECURITY_EVENT_INFO_KEYS.country_name,
    'Country',
    CountryLabel.sessionInfoFormatter
  ),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.city_name, 'City'),
  new DataTableColumn(
    SECURITY_EVENT_INFO_KEYS.device_family,
    'Device',
    DeviceLabel.securityEventInfoFormatter
  ),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.os_family, 'OS'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.browser_family, 'Browser'),
  new DataTableColumn(SECURITY_EVENT_INFO_KEYS.timestamp, 'Timestamp'),
];

const GROUPS = [
  new DataTableGroup('User', 2, 3),
  new DataTableGroup('Geo Data', 4, 6),
  new DataTableGroup('User Agent', 7, 9),
];

const cn = classNamer('UserSecurityEventsPage');

class UserSecurityEventsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getSecurityEventsInfos(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcSecurityEventInfo} */
    const sei = this.state.data.items[selectedIndex];
    const additionalDataGroups = [];

    for (const groupName of ['User', 'Customer', 'Payload']) {
      const groupData = sei.additional_data && sei.additional_data[groupName.toLowerCase()];
      if (!groupData || !Object.keys(groupData).length) {
        // If no data, don't show the group
        continue;
      }

      additionalDataGroups.push(
        <ExpanderGroup key={groupName} title={groupName}>
          <ObjectInfo object={groupData} />
        </ExpanderGroup>
      );
    }

    const otherAdditionalData =
      sei.additional_data &&
      Object.keys(sei.additional_data)
        .filter(key => !['user', 'customer', 'payload'].includes(key))
        .reduce((obj, key) => {
          obj[key] = sei.additional_data[key];
          return obj;
        }, {});

    if (otherAdditionalData && Object.keys(otherAdditionalData).length) {
      if (!additionalDataGroups.length) {
        additionalDataGroups.push(<ObjectInfo object={otherAdditionalData} key="Other" />);
      } else {
        additionalDataGroups.push(
          <ExpanderGroup key={'Other'} title={'Other'}>
            <ObjectInfo object={otherAdditionalData} />
          </ExpanderGroup>
        );
      }
    }

    return (
      <>
        <Expander title="Additional data" memoryKey={cn('additional-data')}>
          {additionalDataGroups.length ? (
            additionalDataGroups
          ) : (
            <h5 className="text-muted">No additional data</h5>
          )}
        </Expander>
        <Expander title="Action info" memoryKey={cn('details')}>
          <ObjectInfo object={{ ...sei, additional_data: undefined }} />
        </Expander>
      </>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_SECURITY_EVENTS)} />
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getSecurityEventsInfosCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(UserSecurityEventsPage);
