import './ActivityInternalsPage.css';

import React from 'react';

import { SORT_DIRECTIONS } from '../../lib/backend';
import { ADMIN_ACTION_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import ExpanderGroup from '../layout/ExpanderGroup';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import Time from '../widgets/presentational/Time';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const cn = classNamer('ActivityInternalsPage');

const BASE_CRITERIA = {
  sort_field: ADMIN_ACTION_INFO_KEYS.created_at,
  sort_direction: SORT_DIRECTIONS.desc,
};

const COLUMNS = [
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.id, 'ID'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.admin_id, 'Admin ID'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.email, 'Email'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.admin_permissions, 'Permissions', action => (
    <span>{action.admin_permissions.join(', ')}</span>
  )),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.action, 'Action'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.user_ids, 'User Ids'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.pairs, 'Pairs'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.instruments, 'Instruments'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.status_code, 'Status Code', action => (
    <span style={{ color: action.status_code === 200 ? 'green' : 'red' }}>
      {action.status_code}
    </span>
  )),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.error_message, 'Error Message'),
  new DataTableColumn(ADMIN_ACTION_INFO_KEYS.created_at, 'Timestamp', action => (
    <Time format={Time.FORMATS.precise} value={action.created_at} />
  )),
];

const GROUPS = [new DataTableGroup('Admin info', 1, 3)];

class ActivityInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getAppAdminActions(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcAdminActionInfo} */
    const aai = this.state.data.items[selectedIndex];

    const additionalDataGroups = [];
    for (const groupName of ['Params', 'Query', 'Body']) {
      const groupData = aai.data && aai.data[groupName.toLowerCase()];
      if (!groupData || !Object.keys(groupData).length) {
        // If no data, don't show the group
        continue;
      }

      additionalDataGroups.push(
        <ExpanderGroup key={groupName} title={groupName}>
          <ObjectInfo object={groupData} />
        </ExpanderGroup>
      );
    }

    return (
      <>
        <Expander title="Additional data" memoryKey={cn('additional-data')}>
          {additionalDataGroups.length ? (
            additionalDataGroups
          ) : (
            <h5 className="text-muted">No additional data</h5>
          )}
        </Expander>
        <Expander title="Action info" memoryKey={cn('details')}>
          <ObjectInfo object={{ ...aai, data: undefined }} />
        </Expander>
      </>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_ACTIVITY)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default ActivityInternalsPage;
