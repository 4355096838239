import { toast } from 'react-toastify';

import { MINUTE, SECOND } from './consts';
import { toastError } from './toasts';
import { USER_ACTIVITY_UPDATE_FREQUENCY, getLocalSeenAt } from './user_activity_tracking';

// NOTE: Sigh. This was copied from react app, so it uses react/redux pattern.
// TODO: Either make a real store, or move 100% to services. This gimpy store we have is just confusing.

const getSessionSeenAt = state =>
  state.principal && state.principal.session && state.principal.session.seen_at
    ? new Date(state.principal.session.seen_at).valueOf()
    : null;

const getSessionIdleTimeout = state =>
  (state.principal && state.principal.session && state.principal.session.idle_timeout) || null;

const getSession = state => (state.principal && state.principal.session) || null;

export class SessionIdlenessTracker {
  /**
   * State reference used for determining state change
   */
  _oldState = null;

  /**
   * Let's say our critical period is 6 times as long as update frequency, to be safe (1.5 min).
   */
  _criticalPeriodDuration = 6 * USER_ACTIVITY_UPDATE_FREQUENCY;

  /**
   * How long to wait at most between updating seen_at at the server. If server sets some super-long seen at,
   * we don't want it to be super-stale at the server.
   */
  _maxIntervalBetweenUpdates = 5 * MINUTE;

  /**
   * Do not update seen_at more frequently than this
   */
  _minIntervalBetweenUpdates = 1 * SECOND;

  /**
   * Active timeout id that we are waiting to trigger
   */
  _activeTimeout = null;

  /**
   * Last backend update payload we have sent
   */
  _lastReportedSeenAt = null;

  constructor(container) {
    /**
     * @type {Container}
     */
    this._container = container;

    /**
     * @type {Logger}
     */
    this._logger = this._container.logger.prefixed('SessionIdlenessTracker');

    // Initialize old state reference
    this._oldState = { ...this._container.store };
  }

  start() {
    this._container.store.subscribe(this._updateIfStoreChanged);
  }

  _updateIfStoreChanged = () => {
    // TODO: Hack, fix

    const newState = this._container.store;

    if (
      getSessionSeenAt(newState) !== getSessionSeenAt(this._oldState) ||
      getSessionIdleTimeout(newState) !== getSessionIdleTimeout(this._oldState) ||
      getLocalSeenAt(newState) !== getLocalSeenAt(this._oldState)
    ) {
      this._doUpdate(
        getSessionSeenAt(newState),
        getSessionIdleTimeout(newState),
        getLocalSeenAt(newState)
      );
    }

    // Create new old state reference
    this._oldState = { ...newState };
  };

  _updateNow = () => {
    const state = this._container.store;
    this._doUpdate(getSessionSeenAt(state), getSessionIdleTimeout(state), getLocalSeenAt(state));
  };

  _now() {
    return Date.now();
  }

  /**
   * Notify server about when was user last seen. Returns true if the request succeeds.
   */
  _reportUserSeenAtAction = (seenAt, now = null) => {
    const session = getSession(this._container.store);
    if (!session) {
      return Promise.resolve(false);
    }

    now = now || Date.now();

    this._logger.verbose(`Proactively update session seen_at to ${new Date(seenAt).toISOString()}`);
    this._container.store.update({
      principal: {
        session: { ...session, seen_at: new Date(seenAt).toISOString() },
      },
    });

    return this._container.client
      .putAuthNotifySeen({
        access_token: session.access_token,
        seen_ago: now - seenAt,
      })
      .then(
        updatedSession => {
          this._container.store.update({
            principal: {
              session: updatedSession,
            },
          });
          this._logger.verbose(
            `Update session after seen_at notification (new seen_at: ${updatedSession.seen_at})`
          );
          return true;
        },
        err => {
          this._container.store.update({
            principal: {
              session: { ...session, seen_at: session.seen_at },
            },
          });
          this._logger.verbose(
            `Revert session seen_at to ${session.seen_at} after a failed seen_at update request`,
            err
          );

          toastError(err);
          return false;
        }
      );
  };

  /**
   * Main update method. It will be called memoized, so every call is guaranteed to have different parameters given.
   */
  _doUpdate = (sessionSeenAt, sessionIdleTimeout, localSeenAt) => {
    // There are fundamentally 3 phases here.
    //
    //                1                    2                   3
    //     >--------------------*--------------------*-------------------->  time
    //      2-5 min left        ^ 1.5 min left        ^ expiration
    //      (renew whenever)      (critical period,    (logout)
    //                             renew ASAP)

    const now = this._now();
    const msUntilExpiration = sessionSeenAt + sessionIdleTimeout - now;

    this._logger.verbose(`Updating based on:`, {
      sessionSeenAt: new Date(sessionSeenAt).toISOString(),
      userSeenAt: new Date(localSeenAt).toISOString(),
      sessionIdleTimeout,
      msUntilExpiration,
    });

    clearTimeout(this._activeTimeout);
    this._activeTimeout = null;

    if (!sessionIdleTimeout || !sessionSeenAt) {
      // Not logged in or "keep alive" disabled. Easy case, nothing to do.

      this._logger.verbose(
        `Phase 0: No idle timeout needed due to lack of session or "keep session alive" being set`
      );
      return;
    }

    if (msUntilExpiration > this._criticalPeriodDuration) {
      // We are in phase 1. Plenty of time left.

      // If session is due for an update, let's do that now
      const msUntilSessionIsDueForAnUpdate = sessionSeenAt + this._maxIntervalBetweenUpdates - now;
      if (msUntilSessionIsDueForAnUpdate <= 0 && localSeenAt !== sessionSeenAt) {
        this._reportUserSeenAt(localSeenAt);
      }

      // Wake up when critical period starts or when session is due for an update, whichever comes first
      const msUntilCriticalPeriod = msUntilExpiration - this._criticalPeriodDuration;
      this._activeTimeout = setTimeout(
        this._updateNow,
        msUntilSessionIsDueForAnUpdate > 0
          ? Math.min(msUntilCriticalPeriod, msUntilSessionIsDueForAnUpdate)
          : msUntilCriticalPeriod
      );

      this._logger.verbose(`Phase 1: Will enter critical period in ${msUntilCriticalPeriod}ms`);
      return;
    }

    if (msUntilExpiration > 0) {
      // We are in phase 2, the critical period. User will be logged out after critical period expires

      // Any time we receive a different seenAt now, we should notify the server.
      if (localSeenAt !== sessionSeenAt) {
        this._reportUserSeenAt(localSeenAt);
      }

      // We should also schedule the timeout to show the logout User
      this._activeTimeout = setTimeout(this._updateNow, msUntilExpiration);

      this._logger.verbose(
        `Phase 2: In critical period. User will be kicked out in ${msUntilExpiration}ms`
      );
      return;
    }

    // Phase 3. Session should be expired now.
    this._logoutDueToIdleTimeout();

    this._logger.verbose(`Phase 3: User was logged out, idle timeout reported`);
  };

  _reportUserSeenAt = seenAt => {
    if (this._lastReportedSeenAt) {
      if (this._lastReportedSeenAt >= seenAt) {
        // We can drop this one, it's either the same or stale
        return;
      }

      if (seenAt - this._lastReportedSeenAt <= this._minIntervalBetweenUpdates) {
        // No need to send this one, too short time
        return;
      }
    }

    this._lastReportedSeenAt = seenAt;
    this._reportUserSeenAtAction(seenAt, this._now());
  };

  _logoutDueToIdleTimeout = () => {
    toast.info('You have been logged out due to inactivity');
    this._container.auth.logOut(this._container.client.putAuthNotifyIdleTimeoutSpec());
  };
}
