import React from 'react';
import { withRouter } from 'react-router';

import { MANAGED_BUY_ORDER_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import PageLayout from '../layout/PageLayout';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaUserPicker from '../users/widgets/CriteriaUserPicker';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import BadgeList from '../widgets/presentational/BadgeList';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import ManagedBuyOrderStatus from './widgets/ManagedBuyOrderStatus';
import ManagedOrderActionWidget from './widgets/ManagedOrderActionWidget';

const BASE_CRITERIA = {
  user_id: undefined,
};

const COLUMNS = [
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.user_email, 'User email'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.quantity, 'Quantity'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.currency, 'Currency'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.payment_reference_number, 'Reference number'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.payment_received_at, 'Received at'),
  new DataTableColumn(null, 'Distribution', (/** XcManagedBuyOrder */ order) => {
    const badgeLabels = [];
    for (const instrument in order.distribution) {
      badgeLabels.push(`${instrument} ${order.distribution[instrument]}%`);
    }
    return <BadgeList items={badgeLabels} />;
  }),
  new DataTableColumn(null, 'Status', (/** XcManagedBuyOrder */ order) => (
    <ManagedBuyOrderStatus order={order} />
  )),

  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_pct, 'Percentage'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_fixed, 'Fixed'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_total, 'Total'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.failure_reason, 'Failure reason'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.created_at, 'Created at'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.updated_at, 'Updated at'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.completed_at, 'Completed at'),
];

const GROUPS = [new DataTableGroup('Payment', 2, 5), new DataTableGroup('Fee', 8, 10)];

const cn = classNamer('ManagedBuyOrdersPage');

class ManagedBuyOrdersPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      selection: [],

      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBrokerOrdersBuy(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <ManagedOrderActionWidget
              orderType="buy"
              data={this.state.data}
              selection={this.state.selection}
              loadData={() => this.loadData()}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() => this.container.client.getBrokerOrdersBuyCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(ManagedBuyOrdersPage);
