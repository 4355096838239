class SectorInfo {
  constructor() {
    this.sector = undefined;
    this.value = undefined;
    this.namespace = undefined;

    this.socket = undefined;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();

      // Because socket.io IS SUCH A WONDERFUL LIBRARY
      // we have to "help it out" clean up, like wiping toddler's butt
      // https://stackoverflow.com/a/28172886/2405595
      delete this.socket.io.nsps[this.socket.nsp];

      this.socket = null;
    }
  }
}

export class SocketManager {
  constructor(url, store, logger, createSocket) {
    // Make sure URL doesn't have trailing /
    if (url[url.length - 1] === '/') {
      url = url.slice(0, url.length - 1);
    }
    this._url = url;

    /** @type {Store} */
    this._store = store;

    /** @type {Logger} */
    this._logger = logger.prefixed('SocketManager');

    this._createSocket = createSocket;

    this._enabled = false;

    /** @type {XcSession} */
    this._session = undefined;

    this._handlers = {};

    /** @type {Object.<string, SectorInfo>} */
    this._sectors = {};

    // This will create default connection
    this.enterSector('');

    // This will try to reconnect when backend opens up a new namespace
    this.onEvent('_namespace', () => this._updateSockets());
  }

  start() {
    this._enabled = true;

    this._store.subscribe(() => {
      this._updateSession();
    });

    this._updateSession();

    this._logger.log(`Started`);
  }

  _updateSession() {
    const session = this._store.principal && this._store.principal.session;
    if (session === this._session) {
      return;
    }

    this._session = session;
    if (session) {
      this._logger.log(`New token: ${session.access_token}`);
    } else {
      this._logger.log(`Logged out`);
    }

    for (const sector in this._sectors) {
      const sectorInfo = this._sectors[sector];

      // Why the hell are we doing this, you might ask?
      // Because SocketIO suuuuuuuuuuuuuuuuuuucks
      // Once you set token, it doesn't update it. So you have to dig in and change it yourself
      // on the underlying Manager instance
      if (session && sectorInfo.socket && sectorInfo.socket.io.opts) {
        sectorInfo.socket.io.opts.query = 'token=' + (session.access_token || '');
      }

      sectorInfo.disconnect();
    }

    this._updateSockets();
  }

  _updateSockets() {
    for (const sector in this._sectors) {
      const sectorInfo = this._sectors[sector];
      const namespace = generateNamespace(sectorInfo.sector, sectorInfo.value);
      if (!this._enabled || (namespace !== sectorInfo.namespace && sectorInfo.socket)) {
        this._logger.log(`Socket disconnected for ${sectorInfo.namespace}`);
        sectorInfo.disconnect();
        sectorInfo.namespace = null;
      }

      if (this._enabled && !sectorInfo.socket) {
        const token = (this._session && this._session.access_token) || '';
        this._logger.log(`Socket opened for ${namespace} (token = ${token})`);

        sectorInfo.namespace = namespace;
        sectorInfo.socket = this._createSocket(this._url + namespace, {
          query: 'token=' + token,
        });

        sectorInfo.socket.on('message', data => {
          this._handleIncomingMessage(sectorInfo, data.name, data.payload);
        });

        sectorInfo.socket.on('error', error => {
          if (error === 'Invalid namespace') {
            sectorInfo.disconnect();
          } else {
            this._logger.error(error);
          }
        });
      }
    }
  }

  /**
   * @param {SectorInfo} sectorInfo
   * @param name
   * @param payload
   */
  _handleIncomingMessage(sectorInfo, name, payload) {
    const handlerKey = generateHandlerKey(sectorInfo.sector, name);
    if (this._handlers[handlerKey]) {
      this._handlers[handlerKey](payload);
    }
  }

  /**
   * Set value for a given sector. So within this sector, this client
   * will receive only the messages sent under given value.
   * In the background, this will (re)create namespace for this sector.
   */
  enterSector(sector, value = null) {
    let sectorInfo = this._sectors[sector];
    if (!sectorInfo) {
      sectorInfo = this._sectors[sector] = new SectorInfo();
      sectorInfo.sector = sector;
      sectorInfo.namespace = generateNamespace(sector, value);
    }
    sectorInfo.value = value || null;
    this._updateSockets();
  }

  /**
   * Exit a sector entirely, stopping to recieve any events from it. Sector is deleted from internal structures.
   */
  exitSector(sector) {
    const sectorInfo = this._sectors[sector];
    if (!sectorInfo) {
      // Nothing to do
      return;
    }

    sectorInfo.disconnect();
    delete this._sectors[sector];
    this._updateSockets();
  }

  /**
   * Attach handler to normal event
   * (untargeted broadcast or targeted to this client)
   */
  onEvent(name, handler) {
    return this.onSectorEvent('', name, handler);
  }

  /**
   * Attach handler to receive events for specific sector
   */
  onSectorEvent(sector, name, handler) {
    const handlerKey = generateHandlerKey(sector, name);
    this._handlers[handlerKey] = handler;
  }

  /**
   * Unsubscribe from normal event
   */
  offEvent(name) {
    return this.offSectorEvent('', name);
  }

  /**
   * Unsubscribe from sector event
   */
  offSectorEvent(sector, name) {
    const handlerKey = generateHandlerKey(sector, name);
    delete this._handlers[handlerKey];
  }
}

function generateHandlerKey(sector, name) {
  return `${sector}:${name}`;
}

function generateNamespace(sector, value) {
  return sector ? (value ? `/${sector}/${value}` : `/${sector}`) : '/';
}
