import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import { XcManagedSellOrderWithdrawalPayload } from '../../lib/backend';
import { FLAVORS, MANAGED_SELL_ORDER_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import PageLayout from '../layout/PageLayout';
import IconButton from '../widgets/interactive/IconButton';
import ThreeStateCheckbox from '../widgets/interactive/ThreeStateCheckbox';
import ErrorBox from '../widgets/presentational/ErrorBox';
import ObjectInfo from '../widgets/presentational/ObjectInfo';

const orderId = props => props.match.params.orderId || null;

const ORDER_PROPS = [MANAGED_SELL_ORDER_KEYS.instrument, MANAGED_SELL_ORDER_KEYS.quantity];

class ManagedSellOrderWithdrawalPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcManagedSellOrder */
      order: {},
      withdrawals: { items: [] },

      quantity: 0,
      autoFillQuantity: false,
    };
  }

  get orderId() {
    return orderId(this.props);
  }

  get returnRoute() {
    return routes.managedSellOrdersExpandedOrder(this.orderId);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (orderId(prevProps) !== this.orderId) {
      this.loadData();
    }
  }

  loadData() {
    return Promise.all([
      this.promiseOrToast(this.container.client.getBrokerOrdersSellOrderId(this.orderId)),
      this.promiseOrToast(this.container.client.getBrokerWithdrawal(this.orderId)),
    ]).then(([order, withdrawals]) => {
      this.setState({ order, withdrawals });
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const promise = this.container.client.postBrokerWithdrawal(
      new XcManagedSellOrderWithdrawalPayload({
        order_id: this.orderId,
        quantity: this.state.quantity,
        auto_fill_quantity: this.state.autoFillQuantity,
        target_address: this.state.targetAddress,
      })
    );
    return this.promiseOrSetError(promise).then(withdrawal => {
      const message = `Withdrawal ${withdrawal.id} added`;
      toast.success(message);

      this.container.history.pushWithQuery(routes.managedSellOrdersExpandedOrder(this.orderId));
    });
  };

  get isFormReady() {
    return (this.state.quantity || this.state.autoFillQuantity) && this.state.targetAddress;
  }

  renderObjectColumn = (title, data) => (
    <div className="mr-3 w-25">
      <ObjectInfo object={{ [title]: <ObjectInfo object={data} /> }} />
    </div>
  );

  render() {
    const withdrawnQuantity = this.state.withdrawals.items.length
      ? this.state.withdrawals.items.reduce((res, w) => {
          res += parseFloat(w.quantity);
          return res;
        }, 0)
      : 0;
    const leftover = parseFloat(this.state.order.quantity) - withdrawnQuantity;
    const stats = {};
    stats.withdrawn = `${withdrawnQuantity} ${this.state.order.instrument}`;
    stats.left = `${leftover} ${this.state.order.instrument}`;

    return (
      <PageLayout className="container">
        <Breadcrumbed title="Withdraw" link={routes.managedSellOrdersWithdraw(this.orderId)} />

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />
          <div className="mb-3 mt-4">
            <h3 className="mb-0">Withdraw for order {this.state.order.id}</h3>
            <IconButton
              tag={Link}
              to={routes.managedSellOrdersExpandedOrder(this.state.order.id)}
              icon={ICONS.back}
              color={FLAVORS.link}
              className="p-0"
            >
              Order
            </IconButton>
          </div>

          <div className="d-flex flex-row flex-wrap align-content-start mb-1">
            {this.renderObjectColumn('Sell Order', lodash.pick(this.state.order, ORDER_PROPS))}
            {this.renderObjectColumn('Stats', stats)}
          </div>

          <Form className="mb-4 p-2 bg-light col-md-6" onSubmit={this.onSubmit}>
            <div className="row mb-3">
              <div className="col-md-12">
                <Label for="blah">Quantity</Label>
                <InputGroup>
                  <Input
                    type="number"
                    value={this.state.autoFillQuantity ? leftover : this.state.quantity}
                    onChange={e => this.setState({ quantity: e.target.value })}
                    title="Specify the amount you want to withdraw"
                    readOnly={this.state.autoFillQuantity}
                  />
                  <InputGroupAddon addonType="append">
                    {this.state.order.instrument}
                  </InputGroupAddon>
                </InputGroup>
                <ThreeStateCheckbox
                  className="mt-2 mb-1"
                  checked={this.state.autoFillQuantity}
                  onChange={() => this.setState({ autoFillQuantity: !this.state.autoFillQuantity })}
                >
                  Withdraw all remaining quantity
                </ThreeStateCheckbox>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Label for="blah">Target address</Label>
                  <Input
                    type="text"
                    value={this.state.targetAddress}
                    onChange={e => this.setState({ targetAddress: e.target.value })}
                  />
                </div>

                <IconButton
                  color="success"
                  className="mr-2"
                  icon={ICONS.save}
                  disabled={!this.isFormReady}
                >
                  Withdraw
                </IconButton>
                <IconButton tag={Link} to={withQuery(this, this.returnRoute)} icon={ICONS.clear}>
                  Cancel
                </IconButton>
              </div>
            </div>
          </Form>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default ManagedSellOrderWithdrawalPage;
