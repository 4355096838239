import PropTypes from 'prop-types';
import React from 'react';

import { DOCUMENT_MIME_TYPE_VALUES } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { classes } from '../../lib/tools';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import ExternalResourceLink from '../widgets/presentational/ExternalResourceLink';

const MIME_TYPE_TO_ICON = {
  [DOCUMENT_MIME_TYPE_VALUES.pdf]: ICONS.file_pdf,
  [DOCUMENT_MIME_TYPE_VALUES.csv]: ICONS.file_csv,
  [DOCUMENT_MIME_TYPE_VALUES.doc]: ICONS.file_word,
  [DOCUMENT_MIME_TYPE_VALUES.docx]: ICONS.file_word,
};

export default class DocumentPopoutLink extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    document: PropTypes.object.isRequired,

    /**
     * Initiate document download promise. Must produce XcDocumentDownload,
     * probably by calling one of the server endpoints.
     * We need this to be configurable because different endpoints for different documents will check
     * different access rights.
     * @type {function(XcDocument, XcalibraClient):Promise<XcDocumentDownload>}
     */
    initDownload: PropTypes.func,

    /**
     * Whether to force download of the document, instead of opening it in new window
     */
    forceDownload: PropTypes.bool,
  };

  handleClick = e => {
    e.preventDefault();

    if (this.state.waiting) {
      // Don't trigger multiple downloads
      return;
    }

    return this.promiseOrToast(
      this.props.initDownload(this.props.document, this.container.client).then(dd => {
        const downloadUrl = this.container.clientSpec.getDocumentsDownload(
          dd.download_key,
          dd.file_name
        ).url;
        window.open(downloadUrl, '_blank');
      })
    );
  };

  render() {
    return (
      <ExternalResourceLink
        icon={MIME_TYPE_TO_ICON[this.props.document.mime_type] || ICONS.file_any}
        className={classes('DocumentPopoutLink', this.props.className)}
        waiting={!!this.state.waiting}
        onClick={this.props.initDownload && this.handleClick}
        wrap
      >
        {this.props.document.original_name}
      </ExternalResourceLink>
    );
  }
}

export const MicropaymentDocumentPopoutLink = ({ document, disabled, ...rest }) => (
  <DocumentPopoutLink
    {...rest}
    document={document}
    initDownload={
      !disabled &&
      ((_, /** XcalibraClient */ client) => client.getKycMicropaymentsDocumentDownload(document.id))
    }
  />
);

MicropaymentDocumentPopoutLink.propTypes = {
  className: PropTypes.string,
  document: PropTypes.object.isRequired,
  forceDownload: PropTypes.bool,
};
