import React from 'react';
import { toast } from 'react-toastify';

import { SORT_DIRECTIONS } from '../../lib/backend';
import { DOCUMENT_KEYS, FLAVORS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { abbreviated, classNamer, classes } from '../../lib/tools';
import DocumentUploadButton from '../documents/DocumentUploadButton';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const cn = classNamer('DocumentInternalsPage');

const BASE_CRITERIA = {
  sort_field: DOCUMENT_KEYS.created_at,
  sort_direction: SORT_DIRECTIONS.desc,
};

const COLUMNS = [
  new DataTableColumn(DOCUMENT_KEYS.id, 'ID', document => abbreviated(document.id)),
  new DataTableColumn(DOCUMENT_KEYS.directory, 'Directory'),
  new DataTableColumn(DOCUMENT_KEYS.original_name, 'Original name'),
  new DataTableColumn(DOCUMENT_KEYS.mime_type, 'MIME'),
  new DataTableColumn(DOCUMENT_KEYS.size_in_bytes, 'Size (b)'),
  new DataTableColumn(DOCUMENT_KEYS.created_at, 'Created'),
  new DataTableColumn(DOCUMENT_KEYS.deleted_at, 'Deleted'),
];

class DocumentInternalsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,

      selection: [],

      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  uploadRef = React.createRef();

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getDocuments(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  handleDocumentUploaded = (/** XcDocument*/ document) => {
    toast.success(
      `Document "${document.original_name}" (${document.size_in_bytes} bytes) was uploaded as ${document.id}`
    );
    this.loadData();
  };

  handleDownloadClick = () => {
    /** @type {XcDocument} */
    const document = this.state.data.items[this.state.selection[0]];
    if (!document) {
      return;
    }

    return this.promiseOrToast(
      this.container.client.getDocumentDownload(document.id).then(dd => {
        const downloadUrl = this.container.clientSpec.getDocumentsDownload(
          dd.download_key,
          dd.file_name
        ).url;
        window.open(downloadUrl, '_blank');
      }),
      true
    );
  };

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcDocument} */
    const document = this.state.data.items[selectedIndex];

    return (
      <>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            title={'Download document'}
            color={FLAVORS.primary}
            icon={ICONS.download}
            className="mr-2"
            onClick={this.handleDownloadClick}
          >
            Download
          </IconButton>
        </Expander>

        <Expander title="Details" memoryKey={cn('details')}>
          <ObjectInfo
            object={document}
            formatters={{
              metadata: metadata => {
                if (typeof metadata === 'object') {
                  return <ObjectInfo object={metadata} />;
                }
                return metadata;
              },
            }}
          />
        </Expander>
      </>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.internals.subsectionAtRoute(routes.INTERNALS_DOCUMENTS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={() => null}
            />
            <div>
              <ToolBar>
                <ToolBar>
                  <ToolBar.Strip>
                    <DocumentUploadButton onUpload={this.handleDocumentUploaded}>
                      Upload document
                    </DocumentUploadButton>
                  </ToolBar.Strip>
                  <ToolBar.Strip>
                    <CriteriaFilter criteria={this.state.criteria} />
                    <CriteriaPageSize criteria={this.state.criteria} />
                    <DownloadButton
                      getCSV={() => this.container.client.getDocumentsCsv(this.state.criteria)}
                    />
                  </ToolBar.Strip>
                </ToolBar>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default DocumentInternalsPage;
