import './DataTable.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';

import { SORT_DIRECTIONS } from '../../../lib/backend';
import { Criteria } from '../../../lib/criterias';
import { classNamer, classes, lodash } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import ThreeStateCheckbox from '../interactive/ThreeStateCheckbox';
import SmartFormatter from '../presentational/SmartFormatter';

const cn = classNamer('DataTable');

// Efficient enum for group edges
const EDGES_NONE = 0;
const EDGES_LEFT = 1;
const EDGES_RIGHT = 2;

export class DataTableColumn {
  /**
   * @param field
   * @param {string|{title: string, tooltip: string}} title
   * @param {function} render
   * @param {boolean} sortable
   */
  constructor(field, title, render = undefined, sortable = undefined) {
    this.field = field;
    this.title = title;
    this.render = render;
    this.sortable = sortable !== false;

    if (Array.isArray(this.field)) {
      this.field = this.field.join(',');
    }
  }
}

export class DataTableGroup {
  constructor(title, startIndex, endIndex) {
    this.title = title;
    this.start_index = startIndex;
    this.end_index = endIndex;
  }
}

const isInput = el => {
  const tagName = el.tagName.toUpperCase();
  return tagName === 'LABEL' || tagName === 'INPUT';
};

class DataTable extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,

    // List of DataTableColumn-s
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,

    // Optional arg that will be provided to column render() functions
    renderArg: PropTypes.any,

    // List of DataTableGroup-s
    groups: PropTypes.arrayOf(PropTypes.object),

    // Another list of DataTableGroup-s, that will be optionally rendered beneath the first groups
    subgroups: PropTypes.arrayOf(PropTypes.object),

    // List of columns to highlight
    highlight: PropTypes.arrayOf(PropTypes.number),

    // Criteria that will be modified when user clicks on sorting pips
    criteria: PropTypes.object.isRequired,

    // Data to be displayed in table. List of objects.
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    // What to display if there is no data
    noDataPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    // Property or function to get key of rows
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    // Current selection, array of row indexes
    selection: PropTypes.array,

    // This will be called when selection changes, with new array of indexes
    onSelectionChanged: PropTypes.func,
  };

  static defaultProps = {
    rowKey: 'id',
  };

  /** @type {Array} */
  get items() {
    return this.props.data ? (this.props.data.items ? this.props.data.items : this.props.data) : [];
  }

  get useSelection() {
    return !!this.props.selection;
  }

  get columnEdges() {
    return createSelector();
  }

  rowKeyGetter = createSelector(
    props => props.rowKey,
    rowKey => {
      if (!rowKey) {
        // Without rowKey, return row index
        return (_, index) => index;
      }

      if (typeof rowKey === 'string') {
        // In this case, rowKey is something like "id"
        return (item, index) => lodash.get(item, rowKey, 'ix_' + index);
      }

      // Treat as custom function
      return rowKey;
    }
  );

  getSelectionLookup = createSelector(
    props => props.selection || [],
    selection => {
      const result = new Set();
      selection.forEach(key => result.add(key));
      return result;
    }
  );

  getColumnEdges = createSelector(
    props => props.groups,
    props => props.columns.length,
    (groups, columnCount) => {
      if (!groups) {
        // If we don't have groups, return a filler array
        return Array(columnCount).map(() => EDGES_NONE);
      }

      const result = [];
      for (let colIndex = 0; colIndex < columnCount; colIndex++) {
        let value = EDGES_NONE;
        for (const group of groups) {
          if (group.start_index === colIndex) {
            value += EDGES_LEFT;
          }
          if (group.end_index === colIndex) {
            value += EDGES_RIGHT;
          }
        }
        result[colIndex] = value;
      }
      return result;
    }
  );

  getHighlightIndexes = createSelector(
    props => props.highlight,
    highlight => {
      return !highlight
        ? {}
        : highlight.reduce((hash, index) => {
            hash[index] = index;
            return hash;
          }, {});
    }
  );

  isHighlighted = index => {
    return !!this.getHighlightIndexes(this.props)[index];
  };

  setAllSelected(value) {
    const newSelection = value ? lodash.range(this.items.length) : [];
    this.props.onSelectionChanged(newSelection);
  }

  setItemSelected(rowIndex, value) {
    const newSelection = value
      ? this.props.selection.concat(rowIndex)
      : this.props.selection.filter(s => s !== rowIndex);

    this.props.onSelectionChanged(newSelection);
  }

  /**
   * @param {DataTableColumn} column
   * @param columnIndex
   */
  renderHeader(column, columnIndex) {
    const edge = this.getColumnEdges(this.props)[columnIndex];

    let className = classes(
      cn('th-index-' + columnIndex),
      column.field && cn('th-field-' + column.field),
      (edge & EDGES_LEFT) === EDGES_LEFT && cn('group-th-start'),
      (edge & EDGES_RIGHT) === EDGES_RIGHT && cn('group-th-end'),
      this.getHighlightIndexes(this.props)[columnIndex] && cn('highlight')
    );

    let title = column.title;
    let tooltip = title;
    if (lodash.isObject(title)) {
      tooltip = title.tooltip;
      title = title.title;
    }

    if (!column.field || !title || !column.sortable) {
      return (
        <th key={column.field || columnIndex} className={classes(className, cn('th-unsortable'))}>
          {title}
        </th>
      );
    }

    /** @type {Criteria} */
    const criteria = this.props.criteria;

    let pip;
    let targetDir;
    if (criteria.sort_field === column.field) {
      className = classes(className, cn('th-current'));
      if (criteria.sort_direction === SORT_DIRECTIONS.desc) {
        pip = '⬇';
      } else {
        pip = '⬆';
        targetDir = SORT_DIRECTIONS.desc;
      }
    } else {
      pip = '⬍';
    }

    const headerHref = Criteria.href(this.props.history, criteria, {
      sort_field: column.field,
      sort_direction: targetDir || 'asc',
    });

    return (
      <th key={column.field || columnIndex} className={className}>
        <Link to={headerHref} title={tooltip}>
          {title}
          <span className={cn('sorting-pip')}>{pip}</span>
        </Link>
      </th>
    );
  }

  renderHeaders() {
    const cols = [];

    if (this.useSelection) {
      const total = this.items.length;
      const selected = this.props.selection.length;
      cols.push(
        <th key="__selector__" className={cn('selector')}>
          <ThreeStateCheckbox
            inline
            checked={total > 0 && selected === total}
            indeterminate={selected > 0 && selected < total}
            onChange={() => this.setAllSelected(selected === 0)}
          />
        </th>
      );
    }

    this.props.columns.forEach((column, index) => cols.push(this.renderHeader(column, index)));

    return <tr>{cols}</tr>;
  }

  renderGroupHeaders(groups) {
    if (!groups) {
      return null;
    }

    const cols = [];
    let key = 0;
    // If we are adding a checkbox header, we need to start early.
    let index = this.useSelection ? -1 : 0;

    groups.forEach((/** DataTableGroup */ group) => {
      if (group.start_index > index) {
        // Fill in the gap
        const gapSpan = group.start_index - index;
        cols.push(<th key={++key} colSpan={gapSpan} className={cn('group-th-empty')} />);
      }
      const groupSpan = group.end_index - group.start_index + 1;

      let highlighted = false;
      for (let i = group.start_index; i <= group.end_index; i++) {
        if (this.isHighlighted(i)) {
          highlighted = true;
          break;
        }
      }

      cols.push(
        <th
          key={++key}
          colSpan={groupSpan}
          className={classes(cn('group-th'), highlighted && cn('highlight'))}
        >
          {group.title}
        </th>
      );
      index = group.end_index + 1;
    });

    if (index < this.props.columns.length) {
      // Fill in the remaining gap
      const gapSpan = this.props.columns.length - index;
      cols.push(<th key={++key} colSpan={gapSpan} className={cn('group-th-empty')} />);
    }

    return <tr className={cn('group-headers')}>{cols}</tr>;
  }

  onRowClick(rowIndex, selected, /** MouseEvent */ e) {
    if (isInput(e.target)) {
      // Clicks on checkboxes are handled by checkboxes themselves
      return;
    }

    if (e.ctrlKey && this.props.selection) {
      // The equivalent to clicking on checkboxes
      return this.setItemSelected(rowIndex, !selected);
    }

    if (!e.shiftKey || !this.props.selection || !this.props.selection.length) {
      // Single selection
      return this.props.onSelectionChanged([rowIndex]);
    }

    // Range selection
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    for (const index of this.props.selection) {
      if (index < min) {
        min = index;
      }
      if (index > max) {
        max = index;
      }
    }

    if (rowIndex > max) {
      // Select from min to index
      return this.props.onSelectionChanged(lodash.range(min, rowIndex + 1));
    }

    if (rowIndex < min) {
      // Select from index to max
      return this.props.onSelectionChanged(lodash.range(rowIndex, max + 1));
    }

    // Add clicked item
    return this.setItemSelected(rowIndex, false);
  }

  renderRow = (item, rowIndex) => {
    const rowKey = this.rowKeyGetter(this.props.rowKey)(item, rowIndex);
    const selected = this.useSelection ? this.getSelectionLookup(this.props).has(rowIndex) : false;

    const cols = [];

    if (this.useSelection) {
      cols.push(
        <td key="__selector__" className={cn('selector')}>
          <ThreeStateCheckbox
            inline
            checked={selected}
            onChange={() => {
              this.setItemSelected(rowIndex, !selected, true);
            }}
          />
        </td>
      );
    }

    const edges = this.getColumnEdges(this.props);

    this.props.columns.forEach((/** DataTableColumn */ column, colIndex) => {
      const edge = edges[colIndex];

      const className = classes(
        cn('td-index-' + colIndex),
        column.field && cn('td-field-' + column.field),
        (edge & EDGES_LEFT) === EDGES_LEFT && cn('group-td-start'),
        (edge & EDGES_RIGHT) === EDGES_RIGHT && cn('group-td-end'),
        this.isHighlighted(colIndex) && cn('highlight')
      );

      const content = column.render ? (
        column.render.length > 1 ? (
          // Only get the extra values if function is gonna use them
          column.render(item, lodash.get(item, column.field), this.props.renderArg)
        ) : (
          column.render(item)
        )
      ) : (
        <span>
          <SmartFormatter value={lodash.get(item, column.field)} field={column.field} />
        </span>
      );

      cols.push(
        <td className={className} key={colIndex}>
          {content}
        </td>
      );
    });

    const className = selected ? cn('row-selected') : null;

    const onRowClick = this.useSelection ? e => this.onRowClick(rowIndex, selected, e) : undefined;

    const selectionKiller = this.useSelection
      ? e => {
          if (e.shiftKey) {
            this.container.selection.empty();
          }
        }
      : undefined;

    return (
      <tr
        key={rowKey}
        className={className}
        onClick={onRowClick}
        onMouseDownCapture={selectionKiller}
      >
        {cols}
      </tr>
    );
  };

  renderRows() {
    if (!this.items.length) {
      const noData = this.props.noDataPlaceholder || 'No data';
      const noDataReason = this.props.criteria.filter && (
        <p className={cn('no-data-reason')}>
          Filter: &quot;
          <em>{this.props.criteria.filter}</em>
          &quot;
        </p>
      );

      return (
        <tr>
          <td colSpan="100" className={cn('no-data')}>
            {noData}
            {noDataReason}
          </td>
        </tr>
      );
    }

    const rows = this.items.map(this.renderRow);

    return <>{rows}</>;
  }

  render() {
    const className = classes(
      cn(),
      this.props.className,
      this.useSelection && cn('selectable'),
      'table table-sm table-bordered'
    );

    return (
      <table className={className}>
        <thead>
          {this.renderGroupHeaders(this.props.groups)}
          {this.renderGroupHeaders(this.props.subgroups)}
          {this.renderHeaders()}
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }
}

export default withRouter(DataTable);
