import { lodash } from './tools';

const LOCAL_STORAGE_KEYS = {
  token: 'token',
};

const componentMemoryKey = (tpl => (componentClass, memoryKey) => {
  const data = {
    name: componentClass.name || componentClass,
    key: memoryKey,
  };
  return tpl(data);
})(lodash.template('component_memory_<%= name %>_<%= key %>'));

export class LocalStorage {
  constructor(nativeLocalStorage) {
    /** @type Storage */
    this._nativeLocalStorage = nativeLocalStorage;
  }

  getToken() {
    return this._nativeLocalStorage.getItem(LOCAL_STORAGE_KEYS.token);
  }

  setToken(value) {
    this._nativeLocalStorage.setItem(LOCAL_STORAGE_KEYS.token, value);
  }

  clearToken() {
    this._nativeLocalStorage.removeItem(LOCAL_STORAGE_KEYS.token);
  }

  setComponentMemory(componentClass, memoryKey, data) {
    return this._nativeLocalStorage.setItem(
      componentMemoryKey(componentClass, memoryKey),
      JSON.stringify(data)
    );
  }

  getComponentMemory(componentClass, memoryKey, fallback = undefined) {
    const raw = this._nativeLocalStorage.getItem(componentMemoryKey(componentClass, memoryKey));
    if (raw === null) {
      return fallback;
    }

    try {
      return JSON.parse(raw);
    } catch (_) {
      return fallback;
    }
  }
}
