import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { XcLinkManagedDepositsToBuyOrderPayload } from '../../lib/backend';
import { FLAVORS, MANAGED_BUY_ORDER_KEYS, MANAGED_DEPOSIT_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import IconButton from '../widgets/interactive/IconButton';
import Select from '../widgets/interactive/Select';
import BadgeList from '../widgets/presentational/BadgeList';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import ManagedBankTransfersTable from './widgets/ManagedBankTransfersTable';
import ManagedBuyOrderStatus from './widgets/ManagedBuyOrderStatus';
import ManagedOrderActionWidget from './widgets/ManagedOrderActionWidget';
import ManagedPartnerTradesTable from './widgets/ManagedPartnerTradesTable';
import ManagedPartnerTransfersTable from './widgets/ManagedPartnerTransfersTable';

const orderId = props => props.match.params.id || null;

const COLUMNS = [
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.user_id, 'User ID'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.quantity, 'Quantity'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.currency, 'Currency'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.payment_reference_number, 'Reference number'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.payment_received_at, 'Received at'),
  new DataTableColumn(null, 'Distribution', (/** XcManagedBuyOrder */ order) => {
    const badgeLabels = [];
    for (const instrument in order.distribution) {
      badgeLabels.push(`${instrument} ${order.distribution[instrument]}%`);
    }
    return <BadgeList items={badgeLabels} />;
  }),
  new DataTableColumn(null, 'Status', (/** XcManagedBuyOrder */ order) => (
    <ManagedBuyOrderStatus order={order} />
  )),

  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_pct, 'Percentage'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_fixed, 'Fixed'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.fee_total, 'Total'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.failure_reason, 'Failure reason'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.created_at, 'Created at'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.updated_at, 'Updated at'),
  new DataTableColumn(MANAGED_BUY_ORDER_KEYS.completed_at, 'Completed at'),
];

const GROUPS = [new DataTableGroup('Payment', 2, 5), new DataTableGroup('Fee', 8, 10)];

const DEPOSIT_COLUMNS = [
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.id, 'ID'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.transaction_id, 'Transaction ID'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.instrument, 'Instrument'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.quantity, 'Quantity'),
  new DataTableColumn(MANAGED_DEPOSIT_KEYS.interchange_id, 'Interchange ID'),
];

const cn = classNamer('ManagedBuyOrdersExpandedOrderPage');

class ManagedBuyOrdersExpandedOrderPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** XcManagedBuyOrder */
      order: null,
      deposits: [],
      availableDeposits: [],

      depositSelection: [],
      widgetSelectedDeposit: null,
      linkingDeposit: null,
      unlinkingDeposits: null,
    };
  }

  get orderId() {
    return orderId(this.props);
  }

  componentDidUpdate(prevProps) {
    if (orderId(prevProps) !== this.orderId) {
      this.loadData();
    }
  }

  loadData() {
    return Promise.all([
      this.promiseOrToast(this.container.client.getBrokerOrdersBuyOrderId(this.orderId)),
      this.promiseOrToast(this.container.client.getBrokerDeposits({ buy_order_id: this.orderId })),
      this.promiseOrToast(this.container.client.getBrokerDeposits({ only: 'unlinked' })),
    ]).then(([order, deposits, availableDeposits]) => {
      this.setState({
        order,
        deposits,
        availableDeposits,
        depositSelection: [],
        unlinkingDeposits: null,
        linkingDeposit: null,
        widgetSelectedDeposit: null,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  /*********************************************************/

  renderDeposit = (/** XcManagedDeposit */ deposit) => {
    if (!deposit) {
      return null;
    }
    return (
      <>
        <br />
        <br />
        ID: <strong>{deposit.id}</strong>
        <br />
        Transaction ID: <strong>{deposit.transaction_id}</strong>
        <br />
        Quantity:{' '}
        <strong>
          {deposit.quantity} {deposit.instrument}
        </strong>
      </>
    );
  };

  unlinkSelectedDeposits = () => {
    this.setState({
      unlinkingDeposits: this.state.depositSelection.filter(index => {
        const deposit = this.state.deposits.items[index];
        return deposit.link_id && !deposit.interchange_id;
      }),
    });
  };

  linkSelectedDeposit = () => {
    this.setState({
      linkingDeposit: [
        this.state.availableDeposits.items.indexOf(this.state.widgetSelectedDeposit),
      ],
    });
  };

  confirmUnlink = selection => {
    const linkIds = selection.map(index => this.state.deposits.items[index].link_id);

    const promise = this.container.client.deleteBrokerDepositsLink(linkIds);

    return this.promiseOrToast(promise).then(() => {
      toast.success(
        linkIds.length > 1 ? `${linkIds.length} deposits detached` : `Deposit detached`
      );
      this.loadData();
    });
  };

  confirmLink = () => {
    const payload = new XcLinkManagedDepositsToBuyOrderPayload({
      deposit_ids: [this.state.widgetSelectedDeposit.id],
      buy_order_id: this.orderId,
    });
    const promise = this.container.client.postBrokerDepositsLink(payload);

    return this.promiseOrToast(promise).then(() => {
      toast.success(`Deposit linked`);
      this.loadData();
    });
  };

  onDepositSelect = selectedDeposit => {
    this.setState({
      widgetSelectedDeposit: selectedDeposit,
    });
  };

  renderLinkDeposit = () => {
    const getDepositLabel = (/** XcManagedDeposit */ deposit) => {
      return (
        <span className="ml-2">
          {deposit.id} {deposit.transaction_id} ({deposit.quantity} {deposit.instrument})
        </span>
      );
    };

    return (
      <Select
        options={this.state.availableDeposits.items}
        isSearchable={false}
        placeholder={'Select available deposit'}
        onChange={this.onDepositSelect}
        value={this.state.widgetSelectedDeposit}
        getOptionLabel={getDepositLabel}
        getOptionValue={deposit => deposit.id}
        noOptionsMessage={() => `There's no available deposit`}
        className="mt-2"
        isClearable
      />
    );
  };

  renderDepositsSidebar = () => {
    if (this.state.order.completed_at) {
      return <Expander title="Actions" memoryKey={cn('actions')} />;
    }

    if (!this.state.depositSelection.length) {
      return (
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex flex-column">
            You can link deposit to order here. Once order is being successfully finalized, funds
            from all linked deposits will be transferred to customer.
            {this.renderLinkDeposit()}
            <IconButton
              title="Link deposit"
              color={FLAVORS.success}
              onClick={this.linkSelectedDeposit}
              icon={ICONS.link}
              className="flex-fill mt-2"
              disabled={!this.state.widgetSelectedDeposit}
            >
              Link deposit
            </IconButton>
          </div>
        </Expander>
      );
    }

    let eligibleDetach = 0;
    for (const index of this.state.depositSelection) {
      /** @type {XcManagedDeposit} */
      const deposit = this.state.deposits.items[index];
      if (deposit.interchange_id || !deposit.link_id) {
        continue;
      }

      eligibleDetach++;
    }
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <div className="d-flex">
            <IconButton
              title="Unlink from order"
              color={FLAVORS.danger}
              onClick={this.unlinkSelectedDeposits}
              icon={ICONS.reset}
              className="flex-fill"
              disabled={eligibleDetach === 0}
            >
              Unlink
            </IconButton>
          </div>
        </Expander>
      </div>
    );
  };

  /*********************************************************/

  render() {
    const { order, deposits, depositSelection } = this.state;
    if (!order) {
      return null;
    }

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed title="View" link={routes.managedBuyOrdersExpandedOrder(this.orderId)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <ManagedOrderActionWidget
              orderType="buy"
              data={{ items: [order] }}
              selection={[0]}
              loadData={() => this.loadData()}
              isExpandedView={true}
            />
            <div>
              <div>
                <h3 className="mb-0">Buy Order ({order.id})</h3>
                <IconButton
                  tag={Link}
                  to={routes.MANAGED_BUY_ORDERS}
                  icon={ICONS.back}
                  color={FLAVORS.link}
                  className="p-0"
                >
                  All orders
                </IconButton>
                <DataTable columns={COLUMNS} groups={GROUPS} data={[order]} criteria={{}} />
              </div>
              <div className="mt-3 p-2 bg-light">
                <h5 className="text-uppercase mb-3">Linked deposits</h5>
                <div className="d-flex">
                  <SelectionSidebar
                    selection={depositSelection}
                    renderOne={this.renderDepositsSidebar}
                    renderMany={this.renderDepositsSidebar}
                    renderNone={this.renderDepositsSidebar}
                  />
                  <DataTable
                    className="ml-3"
                    columns={DEPOSIT_COLUMNS}
                    data={deposits}
                    criteria={{}}
                    selection={depositSelection}
                    onSelectionChanged={selection => this.setState({ depositSelection: selection })}
                  />
                </div>
              </div>
              <ManagedBankTransfersTable order={order} />
              <ManagedPartnerTransfersTable order={order} />
              <ManagedPartnerTradesTable order={order} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderDeposit}
          onConfirmed={this.confirmUnlink}
          typeSingular="deposit"
          buttonActionText="Confirm"
          actionKey="unlinkingDeposits"
          actionText="unlink"
          dataKey="deposits"
          actionFlavor={FLAVORS.danger}
        />
        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderDeposit}
          onConfirmed={this.confirmLink}
          typeSingular="deposit"
          buttonActionText="Confirm"
          actionKey="linkingDeposit"
          actionText="link"
          dataKey="availableDeposits"
          actionFlavor={FLAVORS.primary}
        />
      </PageLayout>
    );
  }
}

export default withRouter(ManagedBuyOrdersExpandedOrderPage);
