import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { ADMIN_PERMISSIONS, SESSION_INFO_CRITERIA_VIEWS } from '../../lib/backend';
import { SESSION_INFO_KEYS } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { sessionDisplayName } from '../../lib/selectors';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import CriteriaToggler from '../widgets/criteria/CriteriaToggler';
import DownloadReportButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import Toggler from '../widgets/interactive/Toggler';
import CountryLabel from '../widgets/presentational/CountryLabel';
import DeviceLabel from '../widgets/presentational/DeviceLabel';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import CriteriaUserPicker from './widgets/CriteriaUserPicker';
import UserLabel from './widgets/UserLabel';

const BASE_CRITERIA = {
  view: SESSION_INFO_CRITERIA_VIEWS.active,
  user_id: undefined,
};

const VIEW_OPTIONS = [
  new Toggler.Option(SESSION_INFO_CRITERIA_VIEWS.all, 'All'),
  new Toggler.Option(SESSION_INFO_CRITERIA_VIEWS.active, 'Active'),
  new Toggler.Option(SESSION_INFO_CRITERIA_VIEWS.terminated, 'Terminated'),
];

const COLUMNS = [
  new DataTableColumn(SESSION_INFO_KEYS.access_token, 'Token', s => (
    <span className="text-monospace">{sessionDisplayName(s)}</span>
  )),
  new DataTableColumn(SESSION_INFO_KEYS.user_email, 'User', si => (
    <UserLabel email={si.user_email} id={si.user_id} />
  )),
  new DataTableColumn(SESSION_INFO_KEYS.ip_address, 'IP address', si => (
    <span className="text-monospace">{si.ip_address}</span>
  )),
  new DataTableColumn(
    SESSION_INFO_KEYS.country_iso_code,
    'Country',
    CountryLabel.sessionInfoFormatter
  ),
  new DataTableColumn(SESSION_INFO_KEYS.city_name, 'City'),
  new DataTableColumn(SESSION_INFO_KEYS.device_family, 'Device', DeviceLabel.sessionInfoFormatter),
  new DataTableColumn(SESSION_INFO_KEYS.os_family, 'OS'),
  new DataTableColumn(SESSION_INFO_KEYS.browser_family, 'Browser'),
  new DataTableColumn(SESSION_INFO_KEYS.created_at, 'Created'),
  new DataTableColumn(SESSION_INFO_KEYS.seen_at, 'Last seen'),
  new DataTableColumn(SESSION_INFO_KEYS.terminated_at, 'Terminated'),
  new DataTableColumn(SESSION_INFO_KEYS.termination_reason, 'Terminate reason'),
];

const GROUPS = [new DataTableGroup('From IP', 2, 4), new DataTableGroup('From User Agent', 5, 7)];

const cn = classNamer('UserSessionsPage');

class UserSessionsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcQueryResultForSessionInfo */
      data: null,
      selection: [],
      terminating: null,
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    return this.promiseOrToast(this.container.client.getAuthSessions(this.state.criteria)).then(
      data => {
        this.setState({ data, selection: [] });
      }
    );
  }

  componentDidMount() {
    this.loadData();
  }

  formatSession = /** XcSessionInfo */ sessionInfo => {
    if (!sessionInfo) {
      return null;
    }
    return (
      <span>
        <strong>{sessionDisplayName(sessionInfo)}</strong> (by U{sessionInfo.user_id})
      </span>
    );
  };

  terminateSelectedSessions = () => {
    this.setState({
      terminating: this.state.selection.filter(
        index => !this.state.data.items[index].terminated_at
      ),
    });
  };

  confirmTerminate = selection => {
    const access_tokens = selection.map(index => this.state.data.items[index].access_token);
    return this.promiseOrToast(this.container.client.deleteAuthSessions({ access_tokens })).then(
      () => {
        toast.success(
          access_tokens.length > 1
            ? `${access_tokens.length} sessions terminated`
            : `Session terminated`
        );
        this.loadData();
      }
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcSessionInfo} */
    const si = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Security" memoryKey={cn('security')}>
          <IconButton
            title="Terminate session"
            color="danger"
            onClick={this.terminateSelectedSessions}
            icon={ICONS.terminate_session}
            className="mr-2"
            disabled={!!si.terminated_at}
          >
            Terminate session
          </IconButton>
        </Expander>

        <Expander title="Details" memoryKey={cn('details', 'one')}>
          <ObjectInfo object={si} />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = selection => {
    const terminateDisabled = !selection.some(index => !this.state.data.items[index].terminated_at);
    return (
      <div>
        <Expander title="Security" memoryKey={cn('security', 'many')}>
          <IconButton
            className="ml-1"
            title="Terminate"
            color="danger"
            onClick={this.terminateSelectedSessions}
            icon={ICONS.terminate_session}
            disabled={terminateDisabled}
          >
            Terminate selected sessions
          </IconButton>
        </Expander>
      </div>
    );
  };

  render() {
    const columns = this.container.auth.userPermissions.includes(ADMIN_PERMISSIONS.user_activity)
      ? this.state.criteria.view !== SESSION_INFO_CRITERIA_VIEWS.active
        ? COLUMNS.slice(1)
        : COLUMNS.slice(1, -2)
      : this.state.criteria.view !== SESSION_INFO_CRITERIA_VIEWS.active
      ? COLUMNS
      : COLUMNS.slice(0, -2);

    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed link={SECTIONS.users.subsectionAtRoute(routes.USERS_SESSIONS)} />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaToggler
                    label="Show:"
                    options={VIEW_OPTIONS}
                    criteria={this.state.criteria}
                    field="view"
                  />
                  <CriteriaUserPicker criteria={this.state.criteria} />
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadReportButton
                    getCSV={() => this.container.client.getAuthSessionsCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={columns}
                groups={GROUPS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={this.formatSession}
          onConfirmed={this.confirmTerminate}
          typeSingular="session"
          typePlural="sessions"
          actionText="terminate "
          buttonActionText="Terminate"
          actionKey="terminating"
        />
      </PageLayout>
    );
  }
}

export default withRouter(UserSessionsPage);
