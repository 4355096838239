import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BOT_INFO_KEYS, BOT_PERMISSION_NAMES } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { botDisplayName } from '../../lib/selectors';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import BoundDangerModal from '../widgets/bound/BoundDangerModal';
import CriteriaFilter from '../widgets/criteria/CriteriaFilter';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadReportButton from '../widgets/interactive/DownloadReportButton';
import IconButton from '../widgets/interactive/IconButton';
import BadgeList from '../widgets/presentational/BadgeList';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import DataTable, { DataTableColumn } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';
import UserBotStatus from './UserBotStatus';
import CriteriaUserPicker from './widgets/CriteriaUserPicker';

const BASE_CRITERIA = {
  user_id: undefined,
};

const COLUMNS = [
  new DataTableColumn(BOT_INFO_KEYS.api_key, 'API key', botDisplayName),
  new DataTableColumn(BOT_INFO_KEYS.user_email, 'User email'),
  new DataTableColumn(BOT_INFO_KEYS.cidr, 'CIDR'),
  new DataTableColumn(null, 'Permissions', (/** XcBot */ bot) => (
    <BadgeList items={bot.bot_permissions} labelLookup={BOT_PERMISSION_NAMES} />
  )),
  new DataTableColumn(BOT_INFO_KEYS.disabled_at, 'Status', bot => <UserBotStatus bot={bot} />),
  new DataTableColumn(BOT_INFO_KEYS.created_at, 'Created'),
  new DataTableColumn(BOT_INFO_KEYS.seen_at, 'Seen'),
  new DataTableColumn(BOT_INFO_KEYS.updated_at, 'Updated'),
  new DataTableColumn(BOT_INFO_KEYS.deleted_at, 'Deleted'),
  new DataTableColumn(BOT_INFO_KEYS.description, 'Description'),
];

const cn = classNamer('UserBotsPage');

class UserBotsPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selection: [],
      deleting: null,
      criteria: Criteria.fromLocation(this.props.location),
    };
  }

  componentDidUpdate(prevProps) {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    const oldCriteria = this.state.criteria;
    if (criteria.identity !== oldCriteria.identity) {
      this.setState({ criteria }, () => {
        if (!Criteria.equivalent(criteria, oldCriteria)) {
          this.loadData();
        }
      });
    }
  }

  loadData() {
    this.promiseOrToast(this.container.client.getBots(this.state.criteria)).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  deleteSelected = () => {
    this.setState({
      deleting: this.state.selection.slice(),
    });
  };

  confirmDelete = selection => {
    const keys = selection.map(index => this.state.data.items[index].api_key);
    const promise =
      keys.length === 1
        ? this.container.client.deleteBot(keys[0])
        : this.container.client.deleteBots({ api_keys: keys });

    return this.promiseOrToast(promise).then(() => {
      toast.success(keys.length > 1 ? `${keys.length} bots deleted` : `Bot deleted`);
      this.loadData();
    });
  };

  renderBot = bot => {
    if (!bot) {
      return null;
    }
    return (
      <span>
        <strong>{botDisplayName(bot)}</strong> (owned by U{bot.user_id})
      </span>
    );
  };

  renderSidebarOne = selectedIndex => {
    /** @type {XcBot} */
    const bot = this.state.data.items[selectedIndex];
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('actions')}>
          <IconButton
            tag={Link}
            title="Edit bot"
            color="primary"
            to={routes.usersBotsEdit(bot.api_key)}
            icon={ICONS.edit}
            className="mr-2"
          >
            Edit
          </IconButton>
          <IconButton
            title="Delete bot"
            color="danger"
            onClick={this.deleteSelected}
            icon={ICONS.delete}
            className="mr-2"
          >
            Delete
          </IconButton>
        </Expander>

        <Expander title="Details" memoryKey={cn('details', 'one')}>
          <ObjectInfo object={bot} />
        </Expander>
      </div>
    );
  };

  renderSidebarMany = selection => {
    return (
      <div>
        <Expander title="Actions" memoryKey={cn('details', 'many')}>
          <IconButton
            className="ml-1"
            title="Delete bots"
            color="danger"
            onClick={this.deleteSelected}
            icon={ICONS.delete}
          >
            Delete {selection.length} bots
          </IconButton>
        </Expander>
      </div>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <SelectionSidebar
              selection={this.state.selection}
              renderOne={this.renderSidebarOne}
              renderMany={this.renderSidebarMany}
            />
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <IconButton
                    tag={Link}
                    color="success"
                    icon={ICONS.new}
                    to={routes.USERS_BOTS_NEW}
                  >
                    Create bot
                  </IconButton>
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaUserPicker criteria={this.state.criteria} />
                  <CriteriaFilter criteria={this.state.criteria} />
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadReportButton
                    getCSV={() => this.container.client.getBotsCsv(this.state.criteria)}
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                criteria={this.state.criteria}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>

        <BoundDangerModal
          state={this.boundState}
          formatItem={this.renderBot}
          onConfirmed={this.confirmDelete}
          typeSingular="bot"
        />
      </PageLayout>
    );
  }
}

export default withRouter(UserBotsPage);
