import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Form } from 'reactstrap';

import {
  FIAT_INSTRUMENTS,
  MANAGED_PARTNER_EXCHANGES,
  MANAGED_PARTNER_TRANSFER_TYPES,
  MARKET_INSTRUMENTS,
} from '../../lib/backend';
import { MANAGED_ORDER_TYPE_PREFIX, MANAGED_PARTNER_TRANSFER_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import BoundCheckbox from '../widgets/bound/BoundCheckbox';
import { BoundDateWithTimeInput } from '../widgets/bound/BoundDateInput';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import BoundFormSelect from '../widgets/bound/BoundFormSelect';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';
import IconLabel from '../widgets/presentational/IconLabel';

const CRYPTO_INSTRUMENTS = lodash.pickBy(MARKET_INSTRUMENTS, i => !FIAT_INSTRUMENTS[i]);

const orderId = props => props.match.params.orderId || null;
const transferId = props => props.match.params.id || null;

const AUTOFILL_STATE_KEY = 'autofill';

const EDIT_PROPS = [
  MANAGED_PARTNER_TRANSFER_KEYS.order_id,
  MANAGED_PARTNER_TRANSFER_KEYS.type,
  MANAGED_PARTNER_TRANSFER_KEYS.transaction_id,
  MANAGED_PARTNER_TRANSFER_KEYS.target_address,
  MANAGED_PARTNER_TRANSFER_KEYS.partner_reference_id,
  MANAGED_PARTNER_TRANSFER_KEYS.partner_exchange,
  MANAGED_PARTNER_TRANSFER_KEYS.instrument,
  MANAGED_PARTNER_TRANSFER_KEYS.quantity,
  MANAGED_PARTNER_TRANSFER_KEYS.fee,
  MANAGED_PARTNER_TRANSFER_KEYS.executed_at,
];

class ManagedPartnerTransfersEditPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcManagedPartnerTransfer */
      data: {},
      wallet: {},

      [AUTOFILL_STATE_KEY]: false,

      waiting: !!transferId(props),
    };
  }

  get transferId() {
    return transferId(this.props);
  }

  get orderId() {
    return orderId(this.props);
  }

  get returnRoute() {
    if (this.orderId) {
      return this.orderId.startsWith(MANAGED_ORDER_TYPE_PREFIX.buy)
        ? routes.managedBuyOrdersExpandedOrder(this.orderId)
        : routes.managedSellOrdersExpandedOrder(this.orderId);
    }
    return routes.MANAGED_PARTNER_TRANSFERS;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (transferId(prevProps) !== this.transferId) {
      this.loadData();
    }

    if (
      this.state.autofill &&
      (!this.isAddressAvailable ||
        this.state.data[MANAGED_PARTNER_TRANSFER_KEYS.target_address] !== this.walletAddress)
    ) {
      this.setState({ autofill: false });
    }
  }

  loadData() {
    return this.promiseOrToast(this.container.client.getBrokerWallet()).then(wallet => {
      this.setState({ wallet });

      if (this.isCreatingTransfer) {
        // default options
        this.setState({
          data: {
            [MANAGED_PARTNER_TRANSFER_KEYS.order_id]: this.orderId,
            [MANAGED_PARTNER_TRANSFER_KEYS.partner_exchange]: MANAGED_PARTNER_EXCHANGES.bitstamp,
            [MANAGED_PARTNER_TRANSFER_KEYS.instrument]: MARKET_INSTRUMENTS.BTC,
            [MANAGED_PARTNER_TRANSFER_KEYS.type]: MANAGED_PARTNER_TRANSFER_TYPES.deposit,
          },
        });
        return;
      }
      this.promiseOrSetError(this.container.client.getBrokerPartnerTransfer(this.transferId)).then(
        data => {
          const initialData = lodash.pick(data, EDIT_PROPS);
          this.setState({ data: { ...initialData }, initData: { ...initialData } });
        }
      );
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const promise = this.isCreatingTransfer
      ? this.container.client.postBrokerPartnerTransfer(this.state.data)
      : this.container.client.putBrokerPartnerTransfer(
          this.transferId,
          lodash.pickBy(this.state.data, (val, key) => this.state.initData[key] !== val)
        );
    return this.promiseOrSetError(promise).then(transfer => {
      const message = this.isCreatingTransfer
        ? `Transfer ${transfer.id} added`
        : `Transfer ${transfer.id} updated`;
      toast(message, {
        type: 'success',
      });

      this.container.history.pushWithQuery(this.returnRoute);
    });
  };

  get isCreatingTransfer() {
    return !this.transferId;
  }

  get isFormReady() {
    const { data } = this.state;

    return (
      data.order_id &&
      data.transaction_id &&
      data.type &&
      data.target_address &&
      data.instrument &&
      data.quantity &&
      data.partner_exchange &&
      data.executed_at &&
      data.partner_reference_id
    );
  }

  get isAddressAvailable() {
    return (
      this.state.data.type === MANAGED_PARTNER_TRANSFER_TYPES.withdrawal &&
      !!this.state.wallet[this.state.data.instrument]
    );
  }

  get walletAddress() {
    return this.state.wallet[this.state.data.instrument].address;
  }

  handleAutoFill = () => {
    this.setState({
      autofill: !this.state.autofill,
      data: {
        ...this.state.data,
        [MANAGED_PARTNER_TRANSFER_KEYS.target_address]: !this.state.autofill
          ? this.walletAddress
          : '',
      },
    });
  };

  render() {
    const title = this.isCreatingTransfer
      ? `Add new partner transfer`
      : `Edit partner transfer ${this.transferId}`;

    return (
      <GridLayout className="container" above={title}>
        {this.isCreatingTransfer ? (
          <Breadcrumbed title="Add" link={routes.MANAGED_PARTNER_TRANSFERS_NEW} />
        ) : (
          <Breadcrumbed title="Edit" link={routes.managedPartnerTransfersEdit(this.transferId)} />
        )}

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />

          <Alert color="info">
            <IconLabel icon={ICONS.question}>
              For recording a cryptocurrency transfer to/from partner exchange (a.k.a.
              deposit/withdrawal)
            </IconLabel>
          </Alert>
          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Order ID (order for which this transfer was executed)"
                  name={MANAGED_PARTNER_TRANSFER_KEYS.order_id}
                  state={this.boundState}
                  disabled={!!this.orderId}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRANSFER_KEYS.transaction_id}
                  label="Transaction ID (as it appears on the blockchain)"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div>
              <h5 className="text-black-50">Transfer</h5>
              <hr className="mt-1" />
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRANSFER_KEYS.instrument}
                  label="Instrument"
                  state={this.boundState}
                  nest
                  values={CRYPTO_INSTRUMENTS}
                  labels={CRYPTO_INSTRUMENTS}
                />
              </div>
              <div className="col-md-6">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRANSFER_KEYS.type}
                  label="Type"
                  state={this.boundState}
                  nest
                  values={MANAGED_PARTNER_TRANSFER_TYPES}
                  labels={MANAGED_PARTNER_TRANSFER_TYPES}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Quantity"
                  name={MANAGED_PARTNER_TRANSFER_KEYS.quantity}
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRANSFER_KEYS.target_address}
                  label="Target address"
                  state={this.boundState}
                  nest
                />
                {this.isAddressAvailable && (
                  <BoundCheckbox
                    state={this.boundState}
                    name={AUTOFILL_STATE_KEY}
                    label="Auto-fill with instrument's managed wallet address"
                    onClick={() => this.handleAutoFill()}
                  />
                )}
              </div>
            </div>
            <div>
              <h5 className="text-black-50">Partner</h5>
              <hr className="mt-1" />
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRANSFER_KEYS.partner_exchange}
                  label="Exchange"
                  state={this.boundState}
                  nest
                  values={MANAGED_PARTNER_EXCHANGES}
                  labels={MANAGED_PARTNER_EXCHANGES}
                />
              </div>
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRANSFER_KEYS.fee}
                  label="Fee (optional)"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRANSFER_KEYS.partner_reference_id}
                  label="Transfer ID"
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-6">
                <BoundDateWithTimeInput
                  label="Executed at"
                  name={MANAGED_PARTNER_TRANSFER_KEYS.executed_at}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <IconButton
              color="success"
              className="mr-2"
              icon={ICONS.save}
              disabled={!this.isFormReady}
            >
              {this.isCreatingTransfer ? 'Add' : 'Save'}
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, this.returnRoute)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default ManagedPartnerTransfersEditPage;
