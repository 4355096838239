/**
 * @typedef {History} ExtendedHistory
 * @property {Location} prev
 * @property {string[]} recent_pathnames
 * @property {function(string, any?)} pushWithQuery
 */

import { createBrowserHistory } from 'history';

import { withQuery } from './tools';

const RECENT_SIZE = 50;

/**
 * Create a history that remembers its previous location
 * @return ExtendedHistory
 */
export function createExtendedHistory() {
  const history = createBrowserHistory();

  history.recent_pathnames = [history.location.pathname];
  history.prev = null;
  let prev = history.location;

  history.listen(location => {
    history.prev = prev;
    prev = location;

    // Maintain list of recent pathnames
    const newRecent = [location.pathname];
    for (let i = 0; i < RECENT_SIZE && i < history.recent_pathnames.length; i++) {
      const pathname = history.recent_pathnames[i];
      if (pathname !== location.pathname) {
        newRecent.push(pathname);
      }
    }
    history.recent_pathnames = newRecent;
  });

  /**
   * Go to route, while appending current query string
   */
  history.pushWithQuery = (path, state) => {
    path = withQuery(history, path);
    history.push(path, state);
  };

  return history;
}
