import React from 'react';
import { withRouter } from 'react-router';
import { Label } from 'reactstrap';

import { INSTRUMENTS } from '../../lib/backend';
import { TRANSFER_TIMELINE_REPORT_ITEM } from '../../lib/consts';
import { Criteria } from '../../lib/criterias';
import { SECTIONS } from '../../lib/navigation';
import { routes } from '../../lib/routes';
import { classNamer, classes } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import Expander from '../layout/Expander';
import PageLayout from '../layout/PageLayout';
import SelectionSidebar from '../layout/SelectionSidebar';
import SidebarLayout from '../layout/SidebarLayout';
import ToolBar from '../layout/ToolBar';
import CriteriaInstrumentPicker from '../transactions/CriteriaInstrumentPicker';
import CriteriaPageSize from '../widgets/criteria/CriteriaPageSize';
import DownloadButton from '../widgets/interactive/DownloadReportButton';
import Select from '../widgets/interactive/Select';
import ObjectInfo from '../widgets/presentational/ObjectInfo';
import QuantityValue from '../widgets/presentational/QuantityValue';
import Time from '../widgets/presentational/Time';
import DataTable, { DataTableColumn, DataTableGroup } from '../widgets/tables/DataTable';
import Pagination from '../widgets/tables/Pagination';

const CONVERSION_INSTRUMENTS = [INSTRUMENTS.USD, INSTRUMENTS.EUR, INSTRUMENTS.BTC, INSTRUMENTS.RSD];
const TRANSFER_INSTRUMENTS = [
  INSTRUMENTS.BTC,
  INSTRUMENTS.SFX,
  INSTRUMENTS.SFT,
  INSTRUMENTS.ETH,
  INSTRUMENTS.RSD,
];
const KYC_LEVEL_OPTIONS = ['0', '1', '2', '3'].map(level => ({ level }));

const BASE_CRITERIA = {
  sort_field: TRANSFER_TIMELINE_REPORT_ITEM.timestamp,
  sort_direction: 'desc',
  page_size: 200,
  instruments: undefined,
  conversion_instrument: INSTRUMENTS.USD,
  kyc_level: '1',
};

const quantityRenderer = key => (/** XcTransferTimelineReportItem */ item, _, instrument) => (
  <div className="text-right">
    <QuantityValue value={item[key]} instrument={instrument} fixed />
  </div>
);

const COLUMNS = [
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.timestamp, 'Timestamp', item => (
    <Time format={Time.FORMATS.date} value={item.timestamp} />
  )),
  // Deposits
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.deposit_count, 'Count'),
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.deposit_users, 'Users'),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.deposit_volume,
    'Volume',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.deposit_volume)
  ),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.deposit_revenue,
    'Revenue',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.deposit_revenue)
  ),
  // Withdrawals
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_count, 'Count'),
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_users, 'Users'),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_volume,
    'Volume',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_volume)
  ),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_revenue,
    'Revenue',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.withdrawal_revenue)
  ),
  // Volume
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.volume,
    'New',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.volume)
  ),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.accumulated_volume,
    'Total',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.accumulated_volume)
  ),
  // Revenue
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.revenue,
    'New',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.revenue)
  ),
  new DataTableColumn(
    TRANSFER_TIMELINE_REPORT_ITEM.accumulated_revenue,
    'Total',
    quantityRenderer(TRANSFER_TIMELINE_REPORT_ITEM.accumulated_revenue)
  ),
  // Users
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.new_users, 'New'),
  new DataTableColumn(TRANSFER_TIMELINE_REPORT_ITEM.total_users, 'Total'),
];

const GROUPS = [
  new DataTableGroup('Deposits', 1, 4),
  new DataTableGroup('Withdrawals', 5, 8),
  new DataTableGroup('Volume', 9, 10),
  new DataTableGroup('Revenue', 11, 12),
  new DataTableGroup('Users', 13, 14),
];

const cn = classNamer('TransactionsTransferTimelinePage');

class TransactionsTransferTimelinePage extends ConnectedComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selection: [],
      criteria: Criteria.fromLocation(this.props.location, BASE_CRITERIA),
    };
  }

  componentDidUpdate() {
    const criteria = Criteria.fromLocation(this.props.location, BASE_CRITERIA);
    if (criteria.identity !== this.state.criteria.identity) {
      this.setState({ criteria }, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    return this.promiseOrToast(
      this.container.client.getReportsTransferTimeline(this.state.criteria)
    ).then(data => {
      this.setState({ data, selection: [] });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  renderSidebarOne = selectedIndex => {
    /** @type {XcTransferTimelineReportItem} */
    const item = this.state.data.items[selectedIndex];
    return (
      <Expander title="Selected" memoryKey={cn('selected-info')}>
        <ObjectInfo object={item} />
      </Expander>
    );
  };

  render() {
    return (
      <PageLayout className={classes(cn(), 'container-fluid')}>
        <Breadcrumbed
          link={SECTIONS.transactions.subsectionAtRoute(routes.TRANSACTIONS_TRANSFER_TIMELINE)}
        />

        <AjaxWrapper state={this.boundState}>
          <SidebarLayout className="mt-3">
            <div>
              <SelectionSidebar
                selection={this.state.selection}
                renderOne={this.renderSidebarOne}
                renderMany={() => {}}
              />
            </div>
            <div>
              <ToolBar>
                <ToolBar.Strip>
                  <CriteriaInstrumentPicker
                    criteria={this.state.criteria}
                    label={'Converted to:'}
                    instruments={CONVERSION_INSTRUMENTS}
                    isClearable={false}
                    isMulti={false}
                    instrumentField={'conversion_instrument'}
                  />
                  <CriteriaInstrumentPicker
                    criteria={this.state.criteria}
                    label={'For instruments:'}
                    instruments={TRANSFER_INSTRUMENTS}
                    isClearable={true}
                    isMulti={true}
                  />
                  <div className="form-inline always-form-inline">
                    <Label className="mr-2" for={cn('kyc_level_criteria')}>
                      Min KYC level:
                    </Label>
                    <Select
                      width={150}
                      inputId={cn('kyc_level_criteria')}
                      options={KYC_LEVEL_OPTIONS}
                      getOptionLabel={o => <span>KYC {o.level}</span>}
                      getOptionValue={o => o.level}
                      onChange={o => {
                        this.props.history.push(
                          Criteria.href(this.props.history, {
                            ...this.state.criteria,
                            kyc_level: o.level,
                          })
                        );
                      }}
                      value={KYC_LEVEL_OPTIONS.find(o => o.level === this.state.criteria.kyc_level)}
                      placeholder=""
                      isClearable={false}
                      isMulti={false}
                    />
                  </div>
                </ToolBar.Strip>
                <ToolBar.Strip>
                  <CriteriaPageSize criteria={this.state.criteria} />
                  <DownloadButton
                    getCSV={() =>
                      this.container.client.getReportsTransferTimelineCsv(this.state.criteria)
                    }
                  />
                </ToolBar.Strip>
              </ToolBar>

              <DataTable
                columns={COLUMNS}
                groups={GROUPS}
                criteria={this.state.criteria}
                renderArg={this.state.criteria.conversion_instrument}
                data={this.state.data}
                selection={this.state.selection}
                onSelectionChanged={selection => this.setState({ selection })}
              />

              <Pagination data={this.state.data} criteria={this.state.criteria} />
            </div>
          </SidebarLayout>
        </AjaxWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TransactionsTransferTimelinePage);
