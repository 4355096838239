import PropTypes from 'prop-types';
import React from 'react';

import { XcUsersCriteria } from '../../../lib/backend';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Select from '../../widgets/interactive/Select';
import UserRole from './UserRole';

const SHOW_ITEMS = 100;

const getUserLabel = user => {
  return (
    <UserRole role={user.role} iconOnly>
      <span className="ml-2">
        {user.email} (<strong>{user.id}</strong>)
      </span>
    </UserRole>
  );
};

export default class UserPicker extends ConnectedComponent {
  static propTypes = {
    className: PropTypes.string,
    selectedId: PropTypes.any,
    placeholder: PropTypes.string,
    inputId: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedUser: null,
    };
  }

  componentDidMount() {
    if (this.props.selectedId) {
      this.loadSelectedUser();
    }
  }

  loadSelectedUser() {
    this.promiseOrToast(this.container.client.getUser(this.props.selectedId)).then(selectedUser => {
      this.setState({ selectedUser });
    });
  }

  loadOptions = inputValue => {
    const criteria = new XcUsersCriteria({
      filter: inputValue,
      page_size: SHOW_ITEMS,
      page: 1,
      sort_direction: 'asc',
      sort_field: 'email',
    });

    return this.container.client.getUsers(criteria).then(data => {
      return data.items;
    });
  };

  componentDidUpdate = prevProps => {
    if (this.props.selectedId !== prevProps.selectedId) {
      // We have changed selected id
      if (!this.props.selectedId && this.state.selectedUser) {
        this.setState({
          // Deselect
          selectedUser: null,
        });
      } else if (
        this.props.selectedId &&
        (!this.state.selectedUser || this.props.selectedId !== this.state.selectedUser.id)
      ) {
        // Load the option we need
        this.loadSelectedUser();
      }
    }
  };

  onChange = selectedUser => {
    this.setState({
      selectedUser,
    });
    if (this.props.onChange) {
      this.props.onChange(selectedUser ? selectedUser.id : null);
    }
  };

  render() {
    return (
      <Select
        async
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={this.onChange}
        value={this.state.selectedUser}
        placeholder={this.props.placeholder}
        inputId={this.props.inputId}
        getOptionLabel={getUserLabel}
        getOptionValue={user => user.id}
        noOptionsMessage={() => 'No users'}
        isClearable
      />
    );
  }
}
