import './TrollboxBanWidget.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { FLAVORS } from '../../../lib/consts';
import { ICONS } from '../../../lib/icons';
import { classNamer } from '../../../lib/tools';
import ConnectedComponent from '../../infrastructure/ConnectedComponent';
import Expander from '../../layout/Expander';
import IconButton from '../../widgets/interactive/IconButton';

const cn = classNamer('TrollboxBanWidget');

export default class TrollboxBanWidget extends ConnectedComponent {
  static propTypes = {
    state: PropTypes.object.isRequired,
    banningKey: PropTypes.string.isRequired,
    removingBanKey: PropTypes.string.isRequired,
    reasonKey: PropTypes.string.isRequired,
    durationKey: PropTypes.string.isRequired,
  };

  /**
   * Filter selection, filtering only items with certain "banned" status (banned or not banned)
   */
  filterSelection = onlyWithBannedStatus => {
    return Object.values(
      this.props.state.selection.reduce((res, index) => {
        const item = this.props.state.data.items[index];

        const shouldInclude =
          (item.trollbox_banned_at && onlyWithBannedStatus) ||
          (!item.trollbox_banned_at && !onlyWithBannedStatus);
        if (shouldInclude) {
          const userId = item.user_id || item.id;
          if (!res[userId]) {
            res[userId] = index;
          }
        }

        return res;
      }, {})
    );
  };

  putBanSelectedUsers = () => {
    this.props.state.setState({
      [this.props.banningKey]: this.filterSelection(false),
    });
  };

  removeBanSelectedUsers = () => {
    this.props.state.setState({
      [this.props.removingBanKey]: this.filterSelection(true),
    });
  };

  render() {
    const { state, reasonKey, durationKey } = this.props;
    const bannedUsers = state.selection.filter(index => state.data.items[index].trollbox_banned_at);
    return (
      <Expander title="Ban user" memoryKey={cn('ban')}>
        <Form>
          <div className={cn('form')}>
            <div className="mb-3">
              <Input
                className={cn('reason-input')}
                type="textarea"
                value={state.reason}
                onChange={e => state.setState({ [reasonKey]: e.target.value })}
                placeholder="Reason"
                title="State why you are banning user"
              />
            </div>
            <InputGroup>
              <Input
                type="number"
                value={state.duration}
                onChange={e => {
                  state.setState({ [durationKey]: e.target.value });
                }}
                min={1}
                placeholder="Duration"
                title="How long user won't have access trollbox"
              />
              <InputGroupAddon addonType="append">hours</InputGroupAddon>
            </InputGroup>
            <div className="d-flex mt-3">
              <IconButton
                title="Ban"
                onClick={this.putBanSelectedUsers}
                color={FLAVORS.danger}
                className="flex-fill ml-1"
                icon={ICONS.ban}
                disabled={!(state.selection.length - bannedUsers.length)}
              >
                Ban
              </IconButton>
              <IconButton
                title="Remove ban"
                onClick={this.removeBanSelectedUsers}
                color={FLAVORS.primary}
                className="flex-fill ml-1"
                disabled={!bannedUsers.length}
              >
                Remove ban
              </IconButton>
            </div>
          </div>
        </Form>
      </Expander>
    );
  }
}
