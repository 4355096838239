import PropTypes from 'prop-types';
import React from 'react';

import { isDecimalNumber, isNumberLikeString, lodash } from '../../../lib/tools';
import CodeBlock from './CodeBlock';
import IconLabel from './IconLabel';
import NumericalValue from './NumericalValue';
import Time from './Time';

const isDateString = value => {
  if (!value || typeof value !== 'string') {
    return false;
  }

  // Eg. 2019-02-20T10:40:46.131Z
  return value.length === 24 && value[10] === 'T' && value[23] === 'Z';
};

/**
 * Component that will try to format generic data the best it can
 */
const SmartFormatter = ({ value, field }) => {
  if (value instanceof Date || isDateString(value)) {
    // Guess this is date
    return <Time value={value} />;
  }

  if (Array.isArray(value)) {
    // Minimal array handling
    return value.join(', ');
  }

  if (lodash.isObject(value)) {
    // Display objects as JSON, if we can
    try {
      value = <CodeBlock>{JSON.stringify(value, null, '  ')}</CodeBlock>;
    } catch (_) {
      value = <pre>&lt;object&gt;</pre>;
    }
  }

  if (value === true) {
    return <IconLabel flavor={true} />;
  }
  if (value === false) {
    return <IconLabel flavor={false} />;
  }

  if (isNumberLikeString(value) || isDecimalNumber(value)) {
    // Treat this as number display
    return <NumericalValue value={value} />;
  }

  return value !== undefined ? value : null;
};

SmartFormatter.propTypes = {
  value: PropTypes.any,
  field: PropTypes.any,
};

export default SmartFormatter;
