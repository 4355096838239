import './Pagination.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Pagination as PaginationBS, PaginationItem, PaginationLink } from 'reactstrap';

import { Criteria } from '../../../lib/criterias';
import { AppIcon, ICONS } from '../../../lib/icons';
import { classNamer, classes } from '../../../lib/tools';

const cn = classNamer('Pagination');

class Pagination extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    criteria: PropTypes.object.isRequired,
    data: PropTypes.object,
    maxPages: PropTypes.number,
  };

  static defaultProps = {
    maxPages: 11,
  };

  pageHref(page) {
    return Criteria.href(this.props.history, this.props.criteria, {
      page,
    });
  }

  render() {
    const className = classes(cn(), this.props.className);

    if (!this.props.data) {
      return null;
    }

    /** @type Criteria */
    const criteria = this.props.criteria;
    const totalPages = this.props.data.total_pages || 1;
    const currentPage = Math.max((criteria.page !== undefined && Number(criteria.page)) || 0, 1);

    const midPages = [];

    let from = currentPage;
    let to = currentPage;
    const maxPages = Math.min(this.props.maxPages || 10, totalPages);
    while (to - from + 1 < maxPages) {
      if (from > 1) {
        from--;
      }
      if (to < totalPages && to - from + 1 < maxPages) {
        to++;
      }
    }

    for (let page = from; page <= to; page++) {
      midPages.push(
        <PaginationItem key={page} active={currentPage === page}>
          <PaginationLink tag={Link} to={this.pageHref(page)}>
            {(page === from && from > 1) || (page === to && to < totalPages) ? '...' : page}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return (
      <PaginationBS className={className}>
        <PaginationItem active={currentPage === 1} disabled={currentPage === 1}>
          <PaginationLink tag={Link} to={this.pageHref(1)}>
            <AppIcon icon={ICONS.first} />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem active={currentPage === 1} disabled={currentPage === 1}>
          <PaginationLink tag={Link} previous to={this.pageHref(Math.max(currentPage - 1, 1))}>
            <AppIcon icon={ICONS.previous} />
          </PaginationLink>
        </PaginationItem>
        {midPages}
        <PaginationItem active={currentPage === totalPages} disabled={currentPage >= totalPages}>
          <PaginationLink tag={Link} to={this.pageHref(Math.min(currentPage + 1, totalPages))}>
            <AppIcon icon={ICONS.next} />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem active={currentPage === totalPages} disabled={currentPage >= totalPages}>
          <PaginationLink tag={Link} to={this.pageHref(totalPages)}>
            <AppIcon icon={ICONS.last} />
          </PaginationLink>
        </PaginationItem>
      </PaginationBS>
    );
  }
}

export default withRouter(Pagination);
