import { assertKeysMatch } from './assertions';
import { KYC_ADMIN_ACTIONS, KYC_LEVEL2_METHODS } from './backend';
import { COUNTRIES } from './countries';
import { abbreviated } from './tools';

// ***
// XcUser
// ***

export const userDisplayName = /** XcUser */ user => {
  return user ? user.email : '';
};

// ***
// XcSession
// ***

export const sessionDisplayName = (/** XcSession */ sessionOrAccessToken) =>
  abbreviated(
    (sessionOrAccessToken && sessionOrAccessToken.access_token) || sessionOrAccessToken,
    30
  );

// ***
// XcBot
// ***

export const botDisplayName = (/** XcBot */ botOrApiKey) =>
  abbreviated((botOrApiKey && botOrApiKey.api_key) || botOrApiKey, 20);

// ***
// XcCustomerInfo
// ***

export const customerName = (/** XcCustomerInfo */ ci) =>
  [ci.first_name, ci.middle_name, ci.last_name].filter(Boolean).join(' ');

export const customerAddress = (/** XcCustomerInfo */ ci, withCountry = false) =>
  ci.residence_address
    ? [
        ci.residence_address + ', ',
        ci.residence_postal_code,
        ci.residence_city,
        withCountry &&
          COUNTRIES[ci.residence_country] &&
          ', ' + COUNTRIES[ci.residence_country].label,
      ]
        .filter(Boolean)
        .join(' ')
    : '';

// NOTE: Jumio doesn't have the concept of customer record
export const customerProviderUrl = (/** XcCustomerInfo */ ci) =>
  ci.swiftdil_id ? `https://portal.swiftdil.com/customers/${ci.swiftdil_id}` : null;

export const customerVerificationProviderUrl = (/** XcCustomerInfo */ ci) => {
  if (ci.swiftdil_id && ci.verification_swiftdil_id) {
    return `https://portal.swiftdil.com/customers/${ci.swiftdil_id}/verifications/${ci.verification_swiftdil_id}`;
  }
  if (ci.verification_nv_scan_reference) {
    return `https://customer-portal.lon.netverify.com/#/verifications/${ci.verification_nv_scan_reference}?transactionReference=${ci.verification_nv_scan_reference}`;
  }
  return null;
};

export const customerScreeningProviderUrl = (/** XcCustomerInfo */ ci) => {
  if (ci.swiftdil_id && ci.screening_swiftdil_id) {
    return `https://portal.swiftdil.com/customers/${ci.swiftdil_id}/screenings/${ci.screening_swiftdil_id}/matches`;
  }
  if (ci.screening_ca_search_reference) {
    return `https://app.complyadvantage.com/#/case-management/search/${ci.screening_ca_search_reference}`;
  }
  return null;
};

export const customerIsKYCLocked = (/** XcCustomerInfo */ ci) => !!ci.kyc_locked_at;

export const customerWaitingForVerificationVerdict = (/** XcCustomerInfo */ ci) =>
  ci.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc1_verification;

export const customerWaitingForScreeningRelease = (/** XcCustomerInfo */ ci) =>
  ci.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc1_screening_release;

export const customerWaitingForScreeningVerdict = (/** XcCustomerInfo */ ci) =>
  ci.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc1_screening_verdict;

const customerRequestingKYC2 = (/** XcCustomerInfo */ ci) =>
  !!(ci.kyc2_created_at && !ci.kyc2_completed_at);

export const customerHasRequestedKYC2ImageVerification = (/** XcCustomerInfo */ ci) =>
  customerRequestingKYC2(ci) && ci.kyc2_method === KYC_LEVEL2_METHODS.image_upload;

export const customerHasRequestedKYC2LetterVerification = (/** XcCustomerInfo */ ci) =>
  customerRequestingKYC2(ci) && ci.kyc2_method === KYC_LEVEL2_METHODS.letter_with_code;

export const customerHasScheduledKYC2InPersonVerification = (/** XcCustomerInfo */ ci) =>
  customerRequestingKYC2(ci) && ci.kyc2_method === KYC_LEVEL2_METHODS.in_person_verification;

export const customerWaitingForMicropaymentAudit = (/** XcCustomerInfo */ ci) =>
  ci.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc3_audit;

export const customerWaitingForFailedMicropaymentAuditResponse = (/** XcCustomerInfo */ ci) =>
  ci.kyc_admin_action === KYC_ADMIN_ACTIONS.kyc3_audit_result;

// TODO: Can this be expanded and used in components like CustomerKYCRequestStatus?
/** @type CustomerKYCRequestState */
export const CUSTOMER_KYC_REQUEST_STATES = /** @lends CustomerKYCRequestState.prototype */ {
  kyc1_verification_verdict: 'kyc1_verification_verdict',
  kyc1_waiting_screening_release: 'kyc1_waiting_screening_release',
  kyc1_waiting_screening_verdict: 'kyc1_waiting_screening_verdict',
  kyc2_waiting_image_verification: 'kyc2_waiting_image_verification',
  kyc2_waiting_letter_verification: 'kyc2_waiting_letter_verification',
  kyc2_has_scheduled_in_person_verification: 'kyc2_has_scheduled_in_person_verification',
  kyc3_waiting_for_micropayment_audit: 'kyc3_waiting_for_micropayment_audit',
  kyc3_waiting_for_failed_micropayment_audit_response:
    'kyc3_waiting_for_failed_micropayment_audit_response',
};

/**
 * @typedef { CUSTOMER_KYC_REQUEST_STATES} CustomerKYCRequestState
 */

const CUSTOMER_KYC_REQUEST_STATE_SELECTORS = {
  kyc1_verification_verdict: customerWaitingForVerificationVerdict,
  kyc1_waiting_screening_release: customerWaitingForScreeningRelease,
  kyc1_waiting_screening_verdict: customerWaitingForScreeningVerdict,
  kyc2_waiting_image_verification: customerHasRequestedKYC2ImageVerification,
  kyc2_waiting_letter_verification: customerHasRequestedKYC2LetterVerification,
  kyc2_has_scheduled_in_person_verification: customerHasScheduledKYC2InPersonVerification,
  kyc3_waiting_for_micropayment_audit: customerWaitingForMicropaymentAudit,
  kyc3_waiting_for_failed_micropayment_audit_response: customerWaitingForFailedMicropaymentAuditResponse,
};
assertKeysMatch({ CUSTOMER_KYC_REQUEST_STATES, CUSTOMER_KYC_REQUEST_STATE_SELECTORS });

export const customerDetermineKYCRequestState = (/** XcCustomerInfo */ ci) => {
  for (const state in CUSTOMER_KYC_REQUEST_STATE_SELECTORS) {
    if (CUSTOMER_KYC_REQUEST_STATE_SELECTORS[state](ci)) {
      return state;
    }
  }
  return null;
};

/**
 * @return {CustomerKYCRequestState & {unclassified: XcCustomerInfo[]}}
 */
export const customersClassifyKYCRequestStates = (/** XcCustomerInfo[] */ cis) => {
  const result = {};
  for (const ci of cis) {
    let state = customerDetermineKYCRequestState(ci);
    if (!state) {
      state = 'unclassified';
    }
    result[state] = (result[state] || []).concat(ci);
  }
  return result;
};

// ***
// XcKYCMicropaymentInfo
// ***

export const micropaymentCustomerName = (/** XcKYCMicropaymentInfo */ kmi) =>
  [kmi.customer_first_name, kmi.customer_middle_name, kmi.customer_last_name]
    .filter(Boolean)
    .join(' ');

// ***
// XcPhoneVerificationInfo
// ***

export const phoneVerificationCustomerName = (/** XcPhoneVerificationInfo */ pvi) =>
  [pvi.c_first_name, pvi.c_middle_name, pvi.c_last_name].filter(Boolean).join(' ');
