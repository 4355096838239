import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';

import { INSTRUMENTS, MANAGED_PARTNER_EXCHANGES } from '../../lib/backend';
import { MANAGED_ORDER_TYPE_PREFIX, MANAGED_PARTNER_TRADE_KEYS } from '../../lib/consts';
import { ICONS } from '../../lib/icons';
import { routes } from '../../lib/routes';
import { lodash, withQuery } from '../../lib/tools';
import AjaxWrapper from '../infrastructure/AjaxWrapper';
import ConnectedComponent from '../infrastructure/ConnectedComponent';
import Breadcrumbed from '../layout/Breadcrumbed';
import GridLayout from '../layout/PageLayout';
import { BoundDateWithTimeInput } from '../widgets/bound/BoundDateInput';
import BoundFormInput from '../widgets/bound/BoundFormInput';
import BoundFormSelect from '../widgets/bound/BoundFormSelect';
import IconButton from '../widgets/interactive/IconButton';
import ErrorBox from '../widgets/presentational/ErrorBox';

const orderId = props => props.match.params.orderId || null;
const tradeId = props => props.match.params.id || null;

const EDIT_PROPS = [
  MANAGED_PARTNER_TRADE_KEYS.order_id,
  MANAGED_PARTNER_TRADE_KEYS.trade_id,
  MANAGED_PARTNER_TRADE_KEYS.partner_exchange,
  MANAGED_PARTNER_TRADE_KEYS.buy_instrument,
  MANAGED_PARTNER_TRADE_KEYS.sell_instrument,
  MANAGED_PARTNER_TRADE_KEYS.buy_quantity,
  MANAGED_PARTNER_TRADE_KEYS.sell_quantity,
  MANAGED_PARTNER_TRADE_KEYS.fee_total,
  MANAGED_PARTNER_TRADE_KEYS.executed_at,
];

class ManagedPartnerTradesEditPage extends ConnectedComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type XcManagedPartnerTrade */
      data: {
        partner_exchange: MANAGED_PARTNER_EXCHANGES.binance,
        buy_instrument: INSTRUMENTS.BTC,
        sell_instrument: INSTRUMENTS.USD,
      },
      order: {},

      waiting: !!tradeId(props),
    };
  }

  get tradeId() {
    return tradeId(this.props);
  }

  get isCreatingTrade() {
    return !this.tradeId;
  }

  get orderId() {
    return orderId(this.props);
  }

  get returnRoute() {
    if (this.orderId) {
      return this.orderId.startsWith(MANAGED_ORDER_TYPE_PREFIX.buy)
        ? routes.managedBuyOrdersExpandedOrder(this.orderId)
        : routes.managedSellOrdersExpandedOrder(this.orderId);
    }
    return routes.MANAGED_PARTNER_TRADES;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (tradeId(prevProps) !== this.tradeId || orderId(prevProps) !== this.orderId) {
      this.loadData();
    }
  }

  loadData() {
    if (this.isCreatingTrade) {
      if (!this.orderId) {
        return null;
      }

      const promise = this.orderId.startsWith(MANAGED_ORDER_TYPE_PREFIX.buy)
        ? this.container.client.getBrokerOrdersBuyOrderId(this.orderId)
        : this.container.client.getBrokerOrdersSellOrderId(this.orderId);

      // fill some fields based on order data
      return this.promiseOrToast(promise).then(order => {
        return this.setState({
          data: {
            [MANAGED_PARTNER_TRADE_KEYS.order_id]: this.orderId,
            [MANAGED_PARTNER_TRADE_KEYS.partner_exchange]: MANAGED_PARTNER_EXCHANGES.bitstamp,
            [MANAGED_PARTNER_TRADE_KEYS.buy_instrument]: order.instrument || INSTRUMENTS.BTC,
            [MANAGED_PARTNER_TRADE_KEYS.sell_instrument]:
              order.payout_currency || order.currency || INSTRUMENTS.USD,
          },
        });
      });
    }
    this.promiseOrSetError(this.container.client.getBrokerPartnerTrade(this.tradeId)).then(data => {
      const initialData = lodash.pick(data, EDIT_PROPS);
      this.setState({ data: { ...initialData }, initData: { ...initialData } });
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const promise = this.isCreatingTrade
      ? this.container.client.postBrokerPartnerTrade(this.state.data)
      : this.container.client.putBrokerPartnerTrade(
          this.tradeId,
          lodash.pickBy(this.state.data, (val, key) => this.state.initData[key] !== val)
        );
    return this.promiseOrSetError(promise).then(trade => {
      const message = this.isCreatingTrade
        ? `Trade ${trade.id} added`
        : `Trade ${trade.id} updated`;
      toast(message, {
        type: 'success',
      });

      this.container.history.pushWithQuery(this.returnRoute);
    });
  };

  get isFormReady() {
    const { data } = this.state;

    return (
      data.order_id &&
      data.trade_id &&
      data.buy_instrument &&
      data.sell_instrument &&
      data.buy_quantity &&
      data.sell_quantity &&
      data.executed_at &&
      data.partner_exchange
    );
  }

  render() {
    const title = this.isCreatingTrade ? `Add new trade` : `Edit trade ${this.tradeId}`;

    return (
      <GridLayout className="container" above={title}>
        {this.isCreatingTrade ? (
          <Breadcrumbed title="Add" link={routes.MANAGED_PARTNER_TRADES_NEW} />
        ) : (
          <Breadcrumbed title="Edit" link={routes.managedPartnerTradesEdit(this.tradeId)} />
        )}

        <AjaxWrapper state={this.boundState}>
          <ErrorBox state={this.boundState} />

          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  label="Order ID (order for which this trade was executed)"
                  name={MANAGED_PARTNER_TRADE_KEYS.order_id}
                  state={this.boundState}
                  disabled={!!this.orderId}
                  nest
                />
              </div>
            </div>
            <div>
              <h5 className="text-black-50">Trade</h5>
              <hr className="mt-1" />
            </div>
            <div className="row">
              <div className="col-md-3">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRADE_KEYS.buy_instrument}
                  label="Buy instrument"
                  state={this.boundState}
                  nest
                  values={INSTRUMENTS}
                  labels={INSTRUMENTS}
                />
              </div>
              <div className="col-md-3">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRADE_KEYS.sell_instrument}
                  label="Sell instrument"
                  state={this.boundState}
                  nest
                  values={INSTRUMENTS}
                  labels={INSTRUMENTS}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <BoundFormInput
                  label="Buy quantity"
                  name={MANAGED_PARTNER_TRADE_KEYS.buy_quantity}
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-3">
                <BoundFormInput
                  label="Sell quantity"
                  name={MANAGED_PARTNER_TRADE_KEYS.sell_quantity}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div>
              <h5 className="text-black-50">Partner</h5>
              <hr className="mt-1" />
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormSelect
                  name={MANAGED_PARTNER_TRADE_KEYS.partner_exchange}
                  label="Exchange"
                  state={this.boundState}
                  nest
                  values={MANAGED_PARTNER_EXCHANGES}
                  labels={MANAGED_PARTNER_EXCHANGES}
                />
              </div>
              <div className="col-md-3">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRADE_KEYS.fee_total}
                  label="Fee (optional)"
                  state={this.boundState}
                  nest
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BoundFormInput
                  name={MANAGED_PARTNER_TRADE_KEYS.trade_id}
                  label="Trade ID"
                  state={this.boundState}
                  nest
                />
              </div>
              <div className="col-md-4">
                <BoundDateWithTimeInput
                  label="Executed at"
                  name={MANAGED_PARTNER_TRADE_KEYS.executed_at}
                  state={this.boundState}
                  nest
                />
              </div>
            </div>

            <IconButton
              color="success"
              className="mr-2"
              icon={ICONS.save}
              disabled={!this.isFormReady}
            >
              {this.isCreatingTrade ? 'Add' : 'Save'}
            </IconButton>
            <IconButton tag={Link} to={withQuery(this, this.returnRoute)} icon={ICONS.clear}>
              Cancel
            </IconButton>
          </Form>
        </AjaxWrapper>
      </GridLayout>
    );
  }
}

export default ManagedPartnerTradesEditPage;
