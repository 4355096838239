import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const Checkbox = ({ className, checked, value, label, onChange }) => {
  return (
    <FormGroup check inline className={className}>
      <Label check>
        <Input
          type="checkbox"
          value={value}
          checked={checked}
          onChange={e => onChange(e.target.value)}
        />
        {label}
      </Label>
    </FormGroup>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
};

export default Checkbox;
