import * as socketIO from 'socket.io-client';

import ConnectedComponent from './components/infrastructure/ConnectedComponent';
import { AuthManager } from './lib/auth';
import { XcalibraClient } from './lib/backend';
import { createExtendedHistory } from './lib/history';
import { HttpService } from './lib/http';
import { initIcons } from './lib/icons';
import { LocalStorage } from './lib/local_storage';
import { Logger } from './lib/logger';
import { SessionIdlenessTracker } from './lib/session_idle_timeout';
import { Settings } from './lib/settings';
import { SocketManager } from './lib/socket_manager';
import { BackendSpecProvider } from './lib/spec_provider';
import { Store } from './lib/store';
import { UrlResolver } from './lib/url_resolver';
import { UserActivityTracker } from './lib/user_activity_tracking';

export class Container {
  _createServices() {
    if (!this.settings) {
      this.settings = new Settings();
      this.settings.setFromEnv();
    }

    if (!this.logger) {
      this.logger = new Logger(this.settings.isDev, console);
    }

    if (!this.localStorage) {
      this.localStorage = new LocalStorage(window.localStorage);
    }

    if (!this.history) {
      /** @type {ExtendedHistory} */
      this.history = createExtendedHistory();
    }

    if (!this.urlResolver) {
      this.urlResolver = new UrlResolver(this.settings.backend_url);
    }

    if (!this.http) {
      /** @type {HttpService} */
      this.http = new HttpService(this.urlResolver, this.logger, window.fetch.bind(window));
    }

    if (!this.backendSpecProvider) {
      /** @type {BackendSpecProvider} */
      this.backendSpecProvider = new BackendSpecProvider(this.settings.backend_url);
    }

    if (!this.clientSpec) {
      /** @type {XcalibraClient} */
      this.clientSpec = new XcalibraClient(this.backendSpecProvider.provide);
    }

    if (!this.client) {
      /** @type {XcalibraClient} */
      this.client = new XcalibraClient(this.http.request);
    }

    if (!this.store) {
      this.store = new Store(this.logger);
    }

    if (!this.auth) {
      /** @type {AuthManager} */
      this.auth = new AuthManager(this);
    }

    if (!this.getSelection) {
      this.getSelection = window.getSelection.bind(window);
    }

    if (!this.socketManager) {
      this.socketManager = new SocketManager(
        this.settings.socketUrl,
        this.store,
        this.logger,
        socketIO
      );
    }

    if (!this.sessionIdlenessTracker) {
      /** @type {SessionIdlenessTracker} */
      this.sessionIdlenessTracker = new SessionIdlenessTracker(this);
    }

    if (!this.userActivityTracker) {
      /** @type {UserActivityTracker} */
      this.userActivityTracker = new UserActivityTracker(this, window.document);
    }
  }

  /**
   * @type {Selection}
   */
  get selection() {
    return this.getSelection();
  }

  initialize() {
    this._createServices();

    return this.auth.initialize().then(() => {
      this.socketManager.start();
      // Start background services
      this.userActivityTracker.start();
      this.sessionIdlenessTracker.start();
    });
  }
}

export function initialize() {
  initIcons();

  const container = new Container();

  ConnectedComponent.registerContainer(container);

  return container.initialize().then(() => container);
}
