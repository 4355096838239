import React from 'react';

import { FLAVORS } from '../../lib/consts';
import { classes, lodash } from '../../lib/tools';
import IconLabel from '../widgets/presentational/IconLabel';
import { DataTableColumn } from '../widgets/tables/DataTable';
import { fromDisabledToReasonKey } from './config_tools';

const keyPath = (path, key) => path + '.' + key;

const ConfigDisabledIndicator = ({ item, path, disabledKey }) => {
  const disabled = lodash.get(item, keyPath(path, disabledKey));

  let txt;
  if (disabled) {
    const txtContent = lodash.get(item, keyPath(path, fromDisabledToReasonKey(disabledKey)));
    const txtFlavor = txtContent ? FLAVORS.danger : FLAVORS.muted;
    txt = (
      <span className={classes('ml-1', 'text-' + txtFlavor)}>
        {txtContent || '(No reason given)'}
      </span>
    );
  }

  return (
    <div className="ConfigDisabledIndicator">
      <IconLabel flavor={!disabled} />
      {txt}
    </div>
  );
};

ConfigDisabledIndicator.dataTableColumn = (path, disabledKey, title, sortable = false) => {
  return new DataTableColumn(sortable ? keyPath(path, disabledKey) : null, title, item => (
    <ConfigDisabledIndicator item={item} path={path} disabledKey={disabledKey} />
  ));
};

export default ConfigDisabledIndicator;
