import './CheckboxGroup.css';

import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

import { classNamer } from '../../../lib/tools';
import Checkbox from './Checkbox';

const cn = classNamer('CheckboxGroup');

export default class CheckboxGroup extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    checked: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]),
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      checkAll: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      checked: state.checked || props.checked,
      checkAll: state.checked
        ? state.checked.length === props.options.length
        : props.checked
        ? props.checked.length === props.options.length
        : state.checkAll,
    };
  }

  onCheck = value => {
    this.setState(
      prevState => ({
        checked: prevState.checked
          ? prevState.checked.includes(value)
            ? prevState.checked.filter(item => item !== value)
            : [...prevState.checked, value]
          : [value],
      }),
      () =>
        this.setState({ checkAll: this.state.checked.length === this.props.options.length }, () =>
          this.props.onChange(this.state.checked)
        )
    );
  };

  onCheckAll = () => {
    this.setState(
      prevState => ({
        checked: prevState.checkAll ? [] : this.props.options.map(({ value }) => value),
        checkAll: !prevState.checkAll,
      }),
      () => this.props.onChange(this.state.checked)
    );
  };

  renderSubGroup = () => {
    if (!this.props.options) {
      return null;
    }

    return this.props.options.map(({ value, label }) => {
      const checked = !!this.state.checked && this.state.checked.includes(value);
      return (
        <Checkbox
          key={value}
          label={label}
          value={value}
          checked={checked}
          onChange={this.onCheck}
        />
      );
    });
  };

  render() {
    return (
      <FormGroup className={cn()}>
        <Label>{this.props.label}</Label>
        <Checkbox label="Select all" checked={this.state.checkAll} onChange={this.onCheckAll} />
        <FormGroup className={cn('sub')}>{this.renderSubGroup()}</FormGroup>
      </FormGroup>
    );
  }
}
