import './SidebarLayout.css';

import PropTypes from 'prop-types';
import React from 'react';

import { CSS } from '../../lib/consts';
import { classNamer, classes } from '../../lib/tools';
import GridLayout from './GridLayout';

const cn = classNamer('SidebarLayout');

const SidebarLayout = ({ className, children }) => {
  className = classes(className, cn());

  return (
    <GridLayout className={className} cols="auto auto 1fr" gap={CSS.sidebar_gap}>
      {children}
    </GridLayout>
  );
};

SidebarLayout.propTypes = {
  className: PropTypes.string,
};

export default SidebarLayout;
